import PropTypes from 'prop-types';
import React from 'react';


import { useTranslation } from 'react-i18next';

import colors from 'theme/_colors.scss';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import Tippy from '@tippy.js/react';
import { Switch, GeneralInput } from 'sharedComponents';

import { alertValidation } from './helpers';

const AlertConfigRow = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedKpi: {
            config,
            measurementTypeID,
            checkOperationStatusForEventGeneration },
        setAlert,
        setAlertNotification
    } = props;

    return <div className='alert-alarm-dialog-row'>
        <div className='notification-switch'>
            <Switch
                onChange={(value) => setAlertNotification(measurementTypeID, value)}
                checked={config.notificationStatuses.alertNotificationEnabled}
                className=''
            />
            <span>{translate('ABB.Powertrain.Frontend.alertEvent')}</span>
        </div>

        <div className='alert-alarm-input-group-wrapper'>
            <div style={{ color: colors.orangePeel }} className='alert-alarm-icon d-flex align-items-start'>!</div>
            <div className='alert-alarm-info d-flex align-items-center'>
                <Tippy
                    content={
                        <>
                            <div>{translate('ABB.Powertrain.Frontend.alertInfo')}</div>
                            {checkOperationStatusForEventGeneration && <div>{translate('ABB.Powertrain.Frontend.assetUnderOperationInfoText')}</div>}
                        </>
                    }
                    interactive={true}
                    interactiveBorder={10}
                    animation='scale'
                    theme='light-border'
                    trigger='click'
                    maxWidth={450}
                    placement='left-start'
                    offset='-30,0'>
                    <div className='info-icon'>
                        <IconInfo width={'14px'} height={'14px'} />
                    </div>
                </Tippy>
            </div>

            <div className='alert-alarm-input-wrapper d-flex align-items-center'>
                <div className='alert-alarm-input d-flex'>
                    <span className='aa-input-label'>{translate('ABB.Powertrain.Frontend.alertFrom')}</span>
                    <GeneralInput
                        id={'alert-input'}
                        numeric={true}
                        clearable={false}
                        isRequired={true}
                        value={config.alert}
                        validationFunction={(value) => alertValidation(value, config)}
                        isOutOfRange={(value) => { return value > config.max || value < config.min; }}
                        onChange={(value) => setAlert(measurementTypeID, value)}
                    />
                </div>
            </div>
        </div>
    </div>;
};

AlertConfigRow.propTypes = {
    setAlert: PropTypes.func,
};

export default AlertConfigRow;
