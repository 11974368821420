import { instantReportTypes, unitTypesForReport } from '../../../Reports/constants';
import { generateReport } from '../../../Reports/ReportGenerator/actions';
import { ASSET_TYPES, oneYearInDays } from 'helpers/constants';
import { routes } from 'routes';


export const navigateToReportingPage = (_, history, asset) => {
    history.push({ pathname: routes.Reports, search: new URLSearchParams({ assetID: asset.componentID, reportingPeriod: oneYearInDays }).toString() });
};

export const generateInstantReport = (dispatch, history, asset) => {
    const reportType = asset?.componentType?.typeID === ASSET_TYPES.MOTOR ? instantReportTypes[0] : instantReportTypes[1];
    dispatch(generateReport(reportType, asset, unitTypesForReport[0]));
    navigateToReportingPage(undefined, history, asset);
};
