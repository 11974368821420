import { forIn, isNil, round, transform } from 'lodash';

export const formatText = (templateUrl, placeholders) => {
	let s = templateUrl;
	forIn(placeholders, (value, propertyName) => {
		const re = new RegExp(`{${propertyName}}`, 'gm');
		s = s.replace(re, encodeURIComponent(value));
	});
	return s;
};

export const lowerCaseFirst = (str) => {
	return str.charAt(0).toLowerCase() + str.slice(1);
};

export const setMaxPrecision = (value, maxPrecision) => {
	return value && !isNil(maxPrecision) && typeof value === 'number' ? round(value, maxPrecision) : value;
};

export const basePathWithoutSlash = () => `${process.env.SPABasePath?.replace(/^\/+/g, '')}`;

export const capabilityListToDictionary = (capabilityList) => {
	return transform(capabilityList, (acc, cp) => {
		acc[cp.componentID] = cp;
	}, {});
};
