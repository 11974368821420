export const actionTypes = {
	SET_SELECTED_MEASUREMENT_TYPE_ID: 'alertAlarmConfig/SET_SELECTED_MEASUREMENT_TYPE_ID',
	SET_SELECTED_TAB: 'alertAlarmConfig/SET_SELECTED_TAB',
	CLONE_TREND_KPIS: 'alertAlarmConfig/CLONE_TREND_KPIS',
	CLONE_HEALTH_STATUSES: 'alertAlarmConfig/CLONE_HEALTH_STATUSES',
	SET_ALERT_VALUE: 'alertAlarmConfig/SET_ALERT_VALUE',
	SET_ALARM_VALUE: 'alertAlarmConfig/SET_ALARM_VALUE',
	SET_ALERT_NOTIFICATION: 'alertAlarmConfig/SET_ALERT_NOTIFICATION',
	SET_ALARM_NOTIFICATION: 'alertAlarmConfig/SET_ALARM_NOTIFICATION',
	SET_OFFSET_VALUE: 'alertAlarmConfig/SET_OFFSET_VALUE',
	GET_CALCULATED_THRESHOLDS: 'alertAlarmConfig/GET_CALCULATED_THRESHOLDS',
	GET_DEFAULT_HEALTH_STATUSES: 'alertAlarmConfig/GET_DEFAULT_HEALTH_STATUSES',
	RESET_HEALTH_STATUSES: 'alertAlarmConfig/RESET_HEALTH_STATUSES',
	SET_PARAM_EDIT_STATE: 'alertAlarmConfig/SET_PARAM_EDIT_STATE',
	SAVE_OFFSET: 'alertAlarmConfig/SAVE_OFFSET',
	SAVE_ALERT_ALARM: 'alertAlarmConfig/SAVE_ALERT_ALARM',
	CLEAR_STORE: 'alertAlarmConfig/CLEAR_STORE',
	UPDATE_HEALTH_STATUS_EMAIL_NOTIFICATION: 'alertAlarmConfig/UPDATE_HEALTH_STATUS_EMAIL_NOTIFICATION',
	GET_HEALTH_STATUS_EMAIL_NOTIFICATION: 'alertAlarmConfig/GET_HEALTH_STATUS_EMAIL_NOTIFICATION',
	DELETE_HEALTH_STATUS_EMAIL_NOTIFICATION: 'alertAlarmConfig/DELETE_HEALTH_STATUS_EMAIL_NOTIFICATION',
	SET_EMAIL_NOTIFICATION_PAYLOAD: 'alertAlarmConfig/SET_EMAIL_NOTIFICATION_PAYLOAD',
};

export const TAB_SECTIONS = {
	HealthParameters: 'healthParameters',
	OperatingParameters: 'operatingParameters',
};

export const NOTIFICATION_CHANNEL_ID = {
	email: 1
};

export const NOTIFICATION_TYPE_ID = {
	alert: 2,
	alarm: 3
};
