import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconAssetGroup = ({ width = '16px', height = '16px', viewBox = '0 0 16 16', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M4.5 11.4999H2.16667V13.8333H4.5V11.4999ZM1 10.3333V14.9999H5.66667V10.3333H1Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M13.833 11.4999H11.4997V13.8333H13.833V11.4999ZM10.333 10.3333V14.9999H14.9997V10.3333H10.333Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M13.833 2.16667H11.4997V4.5H13.833V2.16667ZM10.333 1V5.66667H14.9997V1H10.333Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M4.5 2.16667H2.16667V4.5H4.5V2.16667ZM1 1V5.66667H5.66667V1H1Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M2.55501 8.77772V7.22217H3.72168V8.77772H2.55501Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M2.55501 10.3332V9.55542H3.72168V10.3332H2.55501Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M2.55501 10.3332V9.55542H3.72168V10.3332H2.55501Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M12.6663 8.77772V7.22217H13.833V8.77772H12.6663Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M6.44477 3.33317L5.66699 3.33317L5.66699 2.1665L6.44477 2.1665L6.44477 3.33317Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M8.77821 3.33317L7.22266 3.33317L7.22266 2.1665L8.77821 2.1665L8.77821 3.33317Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M10.3334 3.33317L9.55566 3.33317L9.55566 2.1665L10.3334 2.1665L10.3334 3.33317Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M6.44477 13.4445L5.66699 13.4445L5.66699 12.2778L6.44477 12.2778L6.44477 13.4445Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M8.77821 13.4445L7.22266 13.4445L7.22266 12.2778L8.77821 12.2778L8.77821 13.4445Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M10.3334 13.4445L9.55566 13.4445L9.55566 12.2778L10.3334 12.2778L10.3334 13.4445Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M12.6663 10.3332V9.55542H13.833V10.3332H12.6663Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M12.6663 6.44453V5.66675H13.833V6.44453H12.6663Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M2.55501 6.44453V5.66675H3.72168V6.44453H2.55501Z' fill={color} />
    </svg>
        ;
};

IconAssetGroup.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};

export default IconAssetGroup;
