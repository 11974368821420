
import filter from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

import localStorageHelper from 'helpers/localStorageHelper';
import { saveConfiguration } from '../persistentStateHelper';

const save = (storeAPI, stateProps) => {
	forEach(stateProps, (stateProp) => {
		const state = get(storeAPI.getState(), stateProp);
		localStorageHelper.setItemForUser(stateProp, state);
	});
};

const remove = (stateProps) => {
	forEach(stateProps, (stateProp) => {
		localStorageHelper.removeItemForUser(stateProp);
	});
};

export const saveToLocalStorageMiddleware = storeAPI => next => action => {
	const result = next(action);

	const foundSaveConfig = filter(saveConfiguration, { action: action.type });
	forEach(foundSaveConfig, (config) => {
		if (config.remove) {
			remove(config.stateProps);
		}
		else {
			save(storeAPI, config.stateProps);
		}
	});

	return result;
};
