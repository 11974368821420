import React from 'react';
import GraphComponent from './GraphComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from '../actions';


const GraphContainer = (props) => {
    return (
        <GraphComponent {...props} />
    );
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const GraphContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphContainer);

export default GraphContainerConn;
