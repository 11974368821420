import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';


class ConfiguredNotifications {

    getBroadcastedMessages() {
        const endpoint = formatEndpoint(endpoints.notification.getBroadcastedMessages, {});
        return RestManager.get(endpoint);
    }

    removeBroadcastedMessageForUser(broadcastMessageID, userID) {
        const endpoint = formatEndpoint(endpoints.notification.deleteUserBroadcastMessage, { broadcastMessageID, userID });
        return RestManager.delete(endpoint);
    }
}

export default new ConfiguredNotifications();
