import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconAmbient = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M29.2065 8.21L24.4965 12.91L20.4965 8.91L17.2065 12.21L15.7865 10.79L20.4965 6.09L24.4965
		10.09L27.7865 6.79L29.2065 8.21ZM12.9965 10V18.14C12.0539 18.3834 11.2323 18.9622 10.6859 19.768C10.1395
		20.5738 9.90573 21.5512 10.0284 22.517C10.1511 23.4828 10.6218 24.3707 11.3523 25.0143C12.0828 25.6579 13.023
		26.013 13.9965 26.013C14.9701 26.013 15.9103 25.6579 16.6408 25.0143C17.3713 24.3707 17.842 23.4828 17.9646 22.517C18.0873
		21.5512 17.8535 20.5738 17.3071 19.768C16.7607 18.9622 15.9392 18.3834 14.9965 18.14V10H12.9965ZM17.9965 16.26V14H15.9965V17.3L16.8565
		17.9C17.7294 18.5087 18.3853 19.38 18.7289 20.3872C19.0724 21.3944 19.0856 22.485 18.7666 23.5002C18.4475 24.5154 17.8129 25.4024
		16.955 26.0321C16.0971 26.6617 15.0607 27.0013 13.9965 27.0013C12.9324 27.0013 11.8959 26.6617 11.038 26.0321C10.1801 25.4024
		9.54552 24.5154 9.2265 23.5002C8.90747 22.485 8.92068 21.3944 9.2642 20.3872C9.60772 19.38 10.2636 18.5087 11.1365
		17.9L11.9965 17.3V5H15.9965V7H17.9965V4C17.9965 3.73478 17.8912 3.48043 17.7036 3.29289C17.5161 3.10536 17.2617
		3 16.9965 3H10.9965C10.7313 3 10.477 3.10536 10.2894 3.29289C10.1019 3.48043 9.99653 3.73478 9.99653 4V16.26C8.87322
		17.0432 8.00429 18.1386 7.49734 19.4107C6.9904 20.6828 6.8677 22.0757 7.14444 23.4168C7.42118 24.7579 8.0852 25.9884
		9.05429 26.9559C10.0234 27.9234 11.255 28.5854 12.5965 28.86C13.057 28.9555 13.5262 29.0025 13.9965 29C15.4855 28.9986
		16.9353 28.5224 18.1352 27.6407C19.3351 26.7589 20.2225 25.5175 20.6685 24.0969C21.1146 22.6762 21.096 21.1504 20.6155
		19.741C20.1349 18.3317 19.2175 17.1123 17.9965 16.26Z' fill={color} />
	</svg>;
};

IconAmbient.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconAmbient;
