import { takeLatest, call, put } from 'redux-saga/effects';
import { ConditionIndicesApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import {
    getAssetKPITrendChartListAction,
} from './actions';
import { getConditionStatus } from './helpers';
import { setAssetConditionInsightStatus } from '../actions';

function* getAssetConditionInsightsSaga({ assetID, startDate, endDate }) {
    yield put(getAssetKPITrendChartListAction(apiCallStatus.LOADING));
    try {
        const response = yield call(
            ConditionIndicesApi.getAssetConditionInsights,
            assetID, startDate, endDate
        );
        yield put(getAssetKPITrendChartListAction(apiCallStatus.SUCCESS, {
            assetID: response?.assetId,
            assetConditionStatus: response?.currentOverallCondition,
            trendChartKPIS: response?.KPIs

        }));
        yield put(setAssetConditionInsightStatus({
            isConditionInsightAvailable: true,
            conditionStatus: getConditionStatus(response?.currentOverallCondition),
            assetId: response?.assetId
        }));
    } catch (err) {
        yield put(getAssetKPITrendChartListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* assetConditionalInsightsWatchers() {
    yield takeLatest(actionTypes.LOAD_ASSET_KPI_TREND_LIST, getAssetConditionInsightsSaga);
}
