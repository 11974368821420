import React from 'react';
import PropTypes from 'prop-types';

import { storeConsent } from './helpers';
import { GeneralDialog } from 'sharedComponents';
import { loginUrls } from './constants';


const CookieConsentModal = ({ show, close }) => {
	const approveHandler = () => {
		storeConsent();
		close();
	};

	const renderCookieConsentContent = () =>
		<div className='cookie-consent-content'>
			Our website uses cookies which are necessary for running the website for providing you request.
			For more information please read our <a className='learn-more-link'
				href={loginUrls.privacyPolicyCookies}
				rel='noreferrer'
				target='_blank'>
				Cookie Information
			</a>.
			<br />
			<br />
			<b>Analytics</b>
			<br />
			We collect statistics in order to understand how our visitor interact with the website and how we can improve it.
			The cookies collect information in a way that does not directly identify anyone.
			<br />
			<br />
			<b>Preferences</b>
			<br />
			We store choices you have made so that they are remembered when you visit our website again in order to provide
			you a more personalization experience.
		</div>;

	return <GeneralDialog
		modalClassName='cookie-consent-modal'
		show={show}
		title='Cookie Consent'
		acceptButtonProps={{
			text: 'I Acknowledge',
			onClick: approveHandler
		}}
		persistent
		id='cookie-consent-modal'
	>
		{renderCookieConsentContent()}
	</GeneralDialog>;
};

CookieConsentModal.propTypes = {
	close: PropTypes.func,
	show: PropTypes.any
};

export default CookieConsentModal;
