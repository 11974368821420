export const paginateCommentList = (comments = [], startIndex, endIndex) => {
    const commentList = [];

    comments?.map((item, index) => {
        if (index >= startIndex && index <= endIndex) {
            commentList.push(item);
        }
    });
    return commentList;
};
