import { actionTypes } from './constants';
import { actionTypes as downloadMeasurementsActionTypes } from './../../../../Header/DownloadMeasurements/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { ComponentApi } from 'api';


const trendMeasurementHealthAction = getApiActionCreator(actionTypes.GET_ASSET_MEASUREMENT_HEALTH);

export const getHealthStatuses = (asset) => {
    return (dispatch, getState) => {
        dispatch(trendMeasurementHealthAction(apiCallStatus.LOADING));
        return ComponentApi.getHealthStatuses(asset)
            .then((data) => {
                const selectedAssetID = getState().powertrainAssetDetails.selectedAsset.componentID;

                if (selectedAssetID === asset.componentID) {
                    dispatch(trendMeasurementHealthAction(apiCallStatus.SUCCESS, data));
                }
            })
            .catch((err) => {
                dispatch(trendMeasurementHealthAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setMeasurementDialogParams = (measurementDownloadDialogParams) => {
    return (dispatch) => {
        dispatch({ type: downloadMeasurementsActionTypes.SET_MEASUREMENT_DIALOG_PARAMS, measurementDownloadDialogParams });
    };
};
