import { createSelector } from 'reselect';
import { getEnvironmentStageSelector } from 'helpers/selectors';

const getUserManager = ({ portal }) => {
    return portal.userManager;
};

export const getModel = createSelector([
    getEnvironmentStageSelector,
    getUserManager
], (
    environmentStage,
    userManager
) => {

    return {
        environmentStage,
        userManager
    };
});
