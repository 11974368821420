//import { apiCallStatus } from 'helpers/constants';
// eslint-disable-next-line import/no-unused-modules
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
//import { ConditionIndicesApi } from 'api';

export const getAssetKPITrendChartListAction = getApiActionCreator(actionTypes.GET_ASSET_KPI_TREND_LIST);

// eslint-disable-next-line import/no-unused-modules
export const getAssetConditionIndicesRequest = (assetID, startDate, endDate) => ({
    type: actionTypes.LOAD_ASSET_KPI_TREND_LIST,
    assetID,
    startDate,
    endDate
});

// eslint-disable-next-line import/no-unused-modules
/*export const getAssetKPITrendChartList = (assetID, startDate, endDate) => {
    return (dispatch) => {
        dispatch(getAssetKPITrendChartListAction(apiCallStatus.LOADING));
        ConditionIndicesApi?.getAssetCondtionInsights(assetID, startDate, endDate)
            .then(details => {
                dispatch(getAssetKPITrendChartListAction(apiCallStatus.SUCCESS, { ...details }));
            }, (err) => {
                dispatch(getAssetKPITrendChartListAction(apiCallStatus.ERROR, null, err));
            });

    };
};*/
