import * as constants from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { ComponentApi } from 'api';


const loadCapabilitiesForAssetsAction = getApiActionCreator(constants.LOAD_CAPABILITIES);

const setSelectedEventAction = (selectedEvent) => {
	return {
		type: constants.SET_SELECTED_EVENT,
		selectedEvent
	};
};

export const setIsExapnded = (isExpanded) => {
	return {
		type: constants.SET_IS_EXPAND,
		isExpanded
	};
};

const clearAssistancePageStoreAction = () => {
	return {
		type: constants.CLEAR_ASSISTANCE_PAGE,
	};
};

export const loadCapabilitiesForAssets = (assets) => {
	return (dispatch) => {
		dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.LOADING, []));
		ComponentApi.getAssetsCapabilities(assets).then((capabilities) => {
			dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.SUCCESS, capabilities));
		}).catch((err) => {
			dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.ERROR, [], err));
		});
	};
};

export const setSelectedEvent = (selectedEvent) => {
	return (dispatch) => {
		dispatch(clearAssistancePageStoreAction());
		dispatch(setSelectedEventAction(selectedEvent));
	};
};

export const setSelectedAssets = (selectedAssets) => {
	return {
		type: constants.SET_SELECTED_ASSETS,
		selectedAssets
	};
};

export const clearStore = () => {
	return (dispatch) => {
		dispatch({ type: constants.CLEAR_STORE });
	};
};
