import * as actionTypes from './constants';
import { NotificationSettingsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';


const getAssetMuteSettingsAction = getApiActionCreator(actionTypes.GET_ASSET_MUTE_SETTINGS);
const updateAssetMuteSettingsAction = getApiActionCreator(actionTypes.SET_UPDATE_MUTE_SETTINGS_LOADING);

export const getAssetMuteSettings = (assetID) => {
    return dispatch => {
        dispatch(getAssetMuteSettingsAction(apiCallStatus.LOADING, null));
        NotificationSettingsApi.getAssetMuteSettings(assetID).then((settings) => {
            dispatch(getAssetMuteSettingsAction(apiCallStatus.SUCCESS, settings));
        }).catch((err) => {
            dispatch(getAssetMuteSettingsAction(apiCallStatus.ERROR, null, err));
        });
    };
};

export const muteAsset = (muteSettings, close) => {
    return dispatch => {
        dispatch(updateAssetMuteSettingsAction(apiCallStatus.LOADING, null));
        NotificationSettingsApi.muteAsset(muteSettings)
            .then(() => {
                updateAssetMuteSettingsAction(apiCallStatus.SUCCESS, null);
                close();
            }).catch((err) => {
                dispatch(updateAssetMuteSettingsAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
