import { apiCallStatus, dataSources } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';
import { map } from 'lodash';
import { issueTypeIds } from './constants';

export const formatAssets = (assets) => {
    const formattedArray = map(assets, (asset) => {
        return {
            id: asset.componentID,
            label: asset.componentName,
            value: asset.componentID,
            orgId: asset.site.organizationID,
            dataSourceType: asset.site.dataSourceType,
            assetName: asset.componentName,
            assetSerialNumber: asset.serialNumber,
            organizationName: asset.site.siteName
        };
    });

    return [{
        label: translate('ABB.Powertrain.Frontend.notReatedToAsset'),
        value: 0,
        id: 0,
        bold: true
    }, ...formattedArray];
};

export const formatIssueTypes = (issueTypes) => {
    return map(issueTypes, (issueType) => {
        return {
            id: issueType.issueTypeID,
            label: translate(issueType.issueTypeKey) || issueType.issueTypeName,
            value: issueType.issueTypeID,
            subIssue: issueType.subIssue
        };
    });
};

export const formatSubIssueTypes = (category) => {
    return map(category.subIssue, (subIssue) => {
        return {
            id: subIssue.item1,
            label: translate(subIssue.item3) || subIssue.item2,
            value: subIssue.item1
        };
    });
};

export const getSelectedAssetFromURL = () => {
    const urlString = window.location.href;
    const assetIDString = urlString.replace(new RegExp('^.*assetId='), '');

    return assetIDString ? assetIDString : null;
};

export const checkAffectedAssetContext = (
    assets,
    selectedAssetId,
    formattedAssets,
    affectedAssetSetter
) => {
    const isAssetContext = assets.find(asset => asset.componentID === selectedAssetId);
    const formattedAssetMatch = formattedAssets.find(asset => asset.id === selectedAssetId);

    isAssetContext ? affectedAssetSetter(formattedAssetMatch) : affectedAssetSetter(
        formattedAssets.find(asset => asset.id === 0)
    );
};

export const isProblemTypeVisible = (problemCategory) => {
    return problemCategory?.id === issueTypeIds.Measurement || problemCategory?.id === issueTypeIds.Collection;
};

export const isReportDisabled = (model, asset, description, category, type) => {
    return model.reportIssueLoadingStatus === apiCallStatus.LOADING ||
        !asset ||
        !description ||
        !category ||
        isProblemTypeVisible(category) && !type;
};

export const isCancelDisabled = (model) => {
    return model.reportIssueLoadingStatus === apiCallStatus.LOADING;
};

export const formatIssueObjectData = (userId, asset, description, category, type) => {
    if (asset) {
        const issueObject = {
            userID: userId,
            timestamp: new Date().toUTCString(),
            assetID: asset.dataSourceType === dataSources.SMARTSENSOR ? parseInt(asset.id) : undefined,
            organizationID: parseInt(asset.orgId) || undefined,
            comment: description,
            issueType: category?.id,
            subIssueType: type ? type?.id : 0,
            dataSourceType: asset.dataSourceType,
            assetName: asset.assetName,
            assetSerialNumber: asset.assetSerialNumber,
            organizationName: asset.organizationName
        };

        return issueObject;
    }
};
