import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class powertrainActions {
    getPowertrains(params) {
        const endpoint = formatEndpoint(endpoints.powertrain.getPowertrains);
        return RestManagerWithoutBaseUrl.post(endpoint, params);
    }

    getAvailableAssets(params) {
        const endpoint = formatEndpoint(endpoints.system.getAssetList);
        return RestManagerWithoutBaseUrl.post(endpoint, params);
    }

    deletePowertrain(id) {
        const endpoint = formatEndpoint(endpoints.powertrain.deletePowertrain, { id });
        return RestManagerWithoutBaseUrl.delete(endpoint);
    }

    addPowertrain(powertrain) {
        const endpoint = formatEndpoint(endpoints.powertrain.addPowertrain);
        return RestManagerWithoutBaseUrl.post(endpoint, powertrain);
    }

    getPowertrain(id) {
        const endpoint = formatEndpoint(endpoints.powertrain.getPowertrain, { id });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    updatePowertrainAssets(id, assets) {
        const endpoint = formatEndpoint(endpoints.powertrain.addPowertrainAssets, { id });
        return RestManagerWithoutBaseUrl.put(endpoint, assets);
    }

    updatePowertrain(powertrain) {
        const endpoint = formatEndpoint(endpoints.powertrain.updatePowertrain);
        return RestManagerWithoutBaseUrl.put(endpoint, powertrain);
    }
}

export default new powertrainActions();
