import { filter, map, forEach, cloneDeep } from 'lodash';
import { isWholeNumber, roundNumber } from 'helpers/numberHelper';
import { propertyType } from 'helpers/constants';
import { getMountingIcon } from 'helpers/icons';
import { translate } from 'helpers/translateHelper';
import { toLocaleDateTimeString, convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { sensorMountingDetails, sectionLabels, sensorMountingDetailsSection } from './constants';


export const formatAssetInfoData = (assetInfo) => {
    if (!assetInfo) {
        return [];
    }
    const assetInfoItems = [
        { label: translate('ABB.Powertrain.Frontend.assetIdentifierLabel'), value: assetInfo.assetIdentifier },
        { label: translate('ABB.Powertrain.Frontend.assetNameLabel'), value: assetInfo.assetName },
        { label: translate('ABB.Powertrain.Frontend.assetDetailsAssetTypeLabel'), value: assetInfo?.assetType?.assetTypeName },
        { label: translate('ABB.Powertrain.Frontend.siteName'), value: assetInfo.siteName },
        { label: translate('ABB.Powertrain.Frontend.organizationNameLabel'), value: assetInfo.organizationName },
        { label: translate('ABB.Powertrain.Frontend.serialNumberLabel'), value: assetInfo.serialNumber },
        { label: translate('ABB.Powertrain.Frontend.subscriptionLabel'), value: assetInfo.subscriptionCode },
        { label: translate('ABB.Powertrain.Frontend.subscriptionExpirationLabel'), value: assetInfo?.subscriptionEndDate !== '-' ? convertUtcToLocalDateTime(assetInfo.subscriptionEndDate, true) : '-' },
        { label: translate('ABB.Powertrain.Frontend.descriptionLabel'), value: assetInfo.description },
        { label: translate('ABB.Powertrain.Frontend.countryNameLabel'), value: assetInfo.countryName },
        { label: translate('ABB.Powertrain.Frontend.Externalreferenceid'), value: assetInfo.externalAssetReferenceId }
    ];

    return assetInfoItems;
};

const formatDecimalPropertyType = ({ propertyValue, propertyPrecision, propertyDefaultUnit }) => {
    let formattedNominalProperty = propertyValue ? parseFloat(propertyValue) : undefined;

    if (!isNaN(formattedNominalProperty)) {
        formattedNominalProperty = isWholeNumber(formattedNominalProperty) ? formattedNominalProperty :
            roundNumber(formattedNominalProperty, propertyPrecision)
                .toFixed(propertyPrecision);

        formattedNominalProperty = propertyDefaultUnit
            ? formattedNominalProperty + ' ' + propertyDefaultUnit
            : formattedNominalProperty.toString();
    }

    return formattedNominalProperty;
};

const formatBoolean = (value) => {
    switch (value) {
        case '1': return 'Enabled';
        case '0': return 'Disabled';
        default: return '-';
    }
};

const formatNominalPropertyValue = (nominalProperty) => {
    const { propertyDataType, propertyValue } = nominalProperty;

    if (!propertyValue) {
        return null;
    }

    switch (propertyDataType) {
        case propertyType.Decimal:
        case propertyType.Double:
            return formatDecimalPropertyType(nominalProperty);

        case propertyType.DateTime:
            return toLocaleDateTimeString(propertyValue, { showTimePortion: false });

        case propertyType.Epochtime: {
            return toLocaleDateTimeString(new Date(propertyValue * 1000), { showTimePortion: false });
        }
        case propertyType.Boolean:
            return formatBoolean(propertyValue);

        default:
            return propertyValue;
    }
};

const formatNominalProperties = (nominalProperties) => {
    const filteredAndOrderedNominalProperties = cloneDeep(nominalProperties);
    return map(filteredAndOrderedNominalProperties, (nominalProperty) => {
        return { label: nominalProperty?.propertyName, value: formatNominalPropertyValue(nominalProperty) };
    });
};

const formatSensorMountingProperties = (nominalProperties) => {
    const filteredAndOrderedSensorMountingProperties = filter(nominalProperties, nominalProperty => {
        return sensorMountingDetails.includes(nominalProperty.propertyKey);
    });

    const mountingPropertyPack = {};
    const formattedSensorMountingProperties = map(filteredAndOrderedSensorMountingProperties, (sensorProperty) => {
        if (sensorProperty.propertyValue) {
            mountingPropertyPack[sensorProperty.propertyKey] = sensorProperty.propertyValue;
        }

        return { label: translate(`ABB.Powertrain.Frontend.${sensorProperty.propertyKey}Label`), value: translate(`ABB.Powertrain.Frontend.${sensorProperty.propertyValue}Label`) };
    });

    if (Object.keys(mountingPropertyPack).length) {
        formattedSensorMountingProperties
            .push({
                label: '',
                value: getMountingIcon(mountingPropertyPack)
            });

        return formattedSensorMountingProperties;
    }

    return [];
};


export const formatSections = (sections) => {
    const sortedSections = cloneDeep(sections);
    forEach(sortedSections, (section) => {
        section.label = sectionLabels[section.sectionKey] || section.sectionName;
        if (section.sectionKey === sensorMountingDetailsSection) {
            section.data = formatSensorMountingProperties(section.data);
        }
        else {
            section.data = formatNominalProperties(section.data);
        }
    });
    return sortedSections;
};

// eslint-disable-next-line import/no-unused-modules
export const getOrgNameFromId = (orgList, orgID) => {
    const orgInfo = orgList?.filter((orgData) => orgData?.organizationId?.toString() === orgID);
    return orgInfo && orgInfo?.length > 0 && orgInfo[0]?.name
        ? orgInfo[0]?.name
        : '';
};
