import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconAutomaticLubricator = ({ width = 48, height = 48, color = colors.black }) => {
	return <svg id='IconAutomaticLubricator' width={width} height={height} viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
		<path d='M32.1,43.7h0V14.4h0a.91.91,0,0,0,.2-1.8H27.1v-.2a1.47,1.47,0,0,0-1.5-1.5H24.3V8.6h1.1a.47.47,0,0,0,.5-.5V4.3a.68.68,0,0,0-.7-.7H21.9a.65.65,0,0,0-.6.6h0V8a.68.68,0,0,0,.7.7h1.1V11H21.7a1.54,1.54,0,0,0-1.5,1.5h0a.35.35,0,0,0,.1.2H15.4a.89.89,0,0,0-.8,1,.86.86,0,0,0,.8.8h0V43.8h0a.91.91,0,0,0-.2,1.8H32.1a.89.89,0,0,0,.8-1A.82.82,0,0,0,32.1,43.7Z' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
		<g>
			<path d='M23.7,31.6l-.1.1c0,.1-.1.1-.1.2h0a2.66,2.66,0,0,0-.3,1.2,2.45,2.45,0,0,0,4.9.3v-.3a2.66,2.66,0,0,0-.3-1.2h0l-1-1.7' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='bevel' strokeWidth='2' />
			<path d='M23.6,31.6l2-3.3,1.1,1.8' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
		</g>
		<g id='pictogram-2'>
			<g>
				<circle cx='23.7' cy='26.9' r='3.6' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='19.8' y1='31' x2='21.5' y2='29.3' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='26' y1='24.5' x2='27.7' y2='22.7' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='21.4' y1='24.6' x2='19.6' y2='22.9' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='23.7' y1='23.6' x2='23.6' y2='21.1' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='29.5' y1='26.8' x2='27' y2='26.8' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
				<line x1='20.4' y1='27' x2='17.9' y2='27' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
			</g>
		</g>
	</svg>;
};

IconAutomaticLubricator.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconAutomaticLubricator;
