export const TREND_SELECT_HOURS_BEFORE_EVENT = 38;
export const TREND_SELECT_HOURS_AFTER_EVENT = 10;

export const actionTypes = {
    GET_TREND_DATA: 'eventDetails/assistance/GET_TREND_DATA',
    GET_TREND_KPIS: 'eventDetails/trendSignal/GET_TREND_KPIS',
    ADD_SELECTED_TREND_KPI_DATA: 'eventDetails/assistance/ADD_SELECTED_TREND_KPI_DATA',
    RELOAD_ALL_SELECTED_TREND_KPI_DATA: 'eventDetails/assistance/RELOAD_ALL_SELECTED_TREND_KPI_DATA',
    REMOVE_SELECTED_TREND_KPI_DATA: 'eventDetails/assistance/REMOVE_SELECTED_TREND_KPI_DATA',
    CLEAR_TREND_SIGNAL_STORE: 'eventDetails/selfFaultEvaluation/CLEAR_TREND_SIGNAL_STORE',
    SET_ZOOMED: 'eventDetails/selfFaultEvaluation/SET_ZOOMED',
    REMOVE_SELECTED_TREND_LEGEND: 'eventDetails/assistance/REMOVE_SELECTED_TREND_LEGEND'
};

export const dateKind = {
    UTC: 0,
    Local: 1
};
