
const makeActionCreator = (type, ...argNames) => {
    return (...args) => {
        const action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
};

export const getActionWithDefaultData = (action, initialState) => {
    const data = action.data ? action.data : initialState.data;
    return { ...action, data };
};

export const getApiActionCreator = (type) => {
    return makeActionCreator(type, 'loadingStatus', 'data', 'error');
};
