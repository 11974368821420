import { createSelector } from 'reselect';
import { getSensorFeature } from '../helpers';
import { sensorFeatureKeys } from '../../../constants';

const getLoadingStatuses = ({ operationalParameters, sensorProperties }) => {
    return [operationalParameters.collectRawDataLoadingStatus,
    sensorProperties.sensorProperties.loadingStatus,
    sensorProperties.sensorFeatures.loadingStatus];
};

const getRawDataCollectionRequestFeature = ({ sensorProperties }) => {
    return getSensorFeature(sensorProperties.sensorFeatures?.data, sensorFeatureKeys.RawDataCollectionRequest);
};

const getRawDataCollectionResponseMessageFeature = ({ sensorProperties }) => {
    return getSensorFeature(sensorProperties.sensorFeatures?.data, sensorFeatureKeys.RawDataCollectionResponseMessage);
};

export const getModel = createSelector([
    getLoadingStatuses,
    getRawDataCollectionRequestFeature,
    getRawDataCollectionResponseMessageFeature
],
    (
        loadingStatuses,
        rawDataCollectionRequest,
        rawDataCollectionResponseMessage,
    ) => {
        return {
            loadingStatuses,
            rawDataCollectionRequest,
            rawDataCollectionResponseMessage,
        };
    });
