import { filter, find, map, orderBy } from 'lodash';
import some from 'lodash/some';

import { translate, getTranslatedKPIName } from 'helpers/translateHelper';
import { MAX_LENGTH_USER_LIMIT_NAME } from './constants';

const comparisonTypes = {
    greaterThan: 'greaterThan',
    lessThan: 'lessThan'
};

const limitTypes = [
    { limitType: 'greaterThan' },
    { limitType: 'lessThan' }
];

const checkLimitType = (limitType) => {
    return some(limitTypes, (e) => e.limitType === limitType);
};

const formatKPIList = (kpiList) => {
    if (Array.isArray(kpiList)) {
        return orderBy(map(kpiList, (kpi) => {
            return {
                ...kpi,
                value: kpi.measurementTypeIdentifier,
                label: getTranslatedKPIName(kpi)
            };
        }), 'displayName', 'asc');
    }
};

export const generateKPIs = (trendKpis, additionalKpis) => {
    let formattedStandardKPIs = filter(formatKPIList(trendKpis), ['isPlottableToTrendChart', true]);
    const formattedAdditionalKPIs = filter(formatKPIList(additionalKpis), ['isPlottableToTrendChart', true]);

    if (additionalKpis.length) {
        formattedStandardKPIs = [...formattedStandardKPIs, {
            displayName: '',
            identifier: 'separatorID',
            isDisabled: true
        }, ...formattedAdditionalKPIs];
    }

    return formattedStandardKPIs;
};

export const generateComparisonTypes = () => {
    return [
        {
            label: translate('ABB.Powertrain.Frontend.greaterThan'),
            value: comparisonTypes.greaterThan,
        },
        {
            label: translate('ABB.Powertrain.Frontend.lessThan'),
            value: comparisonTypes.lessThan,
        },
    ];
};

export const getDefaultUserLimits = (visualAids) => {
    const defaultUserLimits = [];
    if (Array.isArray(visualAids)) {
        visualAids.forEach((visualAid) => {
            if (visualAid && visualAid.type === 'default') {
                defaultUserLimits.push({
                    kpiIdentifier: visualAid.signalCode,
                    kpiName: visualAid.signalName,
                    parameterId: visualAid.parameterId,
                    userLimitName: visualAid.label,
                    comparisonTypeID: comparisonTypes[visualAid.limitType],
                    comparisonTypeName: visualAid.limitType,
                    userLimitValue: visualAid.value,
                    enableEmailAlert: visualAid.isEnabledForEmailAlert,
                    unit: visualAid.unit,
                });
            }
        });
    }
    return defaultUserLimits;
};

export const getCustomUserLimits = (visualAids, additionalKpis) => {
    const customUserLimits = [];
    if (Array.isArray(visualAids)) {
        visualAids.forEach((visualAid) => {
            if (visualAid && visualAid.type === 'custom' && checkLimitType(visualAid.limitType)) {
                const matchedAdditionalKpi = find(additionalKpis, (kpi) => kpi.measurementTypeIdentifier === visualAid.signalCode);
                const appendCodeToTranslation = matchedAdditionalKpi?.appendCodeToTranslation;

                customUserLimits.push({
                    kpiIdentifier: visualAid.signalCode,
                    kpiName: appendCodeToTranslation ?
                        `${visualAid.signalCode} ${translate(visualAid.signalTypeKey)}` : translate(visualAid.signalTypeKey)
                        || visualAid.signalName,
                    parameterId: visualAid.parameterId,
                    userLimitName: visualAid.label,
                    comparisonTypeID: comparisonTypes[visualAid.limitType],
                    comparisonTypeName: visualAid.limitType,
                    userLimitValue: visualAid.value,
                    enableEmailAlert: visualAid.isEnabledForEmailAlert,
                    unit: visualAid.unit,
                    unitGroupID: visualAid.unitGroupID
                });
            }
        });
    }
    return orderBy(customUserLimits, 'kpiName', 'asc');
};

export const getUserLimitValue = (userLimitGroup) => {
    let defaultUserLimitValue = '';
    for (let i = 0; i < userLimitGroup.length; ++i) {
        const sectionValue = userLimitGroup[i]?.userLimitName;
        defaultUserLimitValue += sectionValue;
        if (i < userLimitGroup.length - 1) {
            defaultUserLimitValue += ' | ';
        }
    }
    return defaultUserLimitValue;
};

export const findDuplicate = (customUserLimits, selectedKPI, userLimitValue, selectedComparisonType, userLimitName) => {
    return find(customUserLimits, (userLimit) => {
        return userLimit.kpiIdentifier.toLowerCase() === selectedKPI.measurementTypeIdentifier.toLowerCase() &&
            userLimit.userLimitValue === parseFloat(userLimitValue) &&
            userLimit.comparisonTypeID === selectedComparisonType.value ||
            userLimit.userLimitName.toLowerCase() === userLimitName.toLowerCase();
    });
};

// eslint-disable-next-line import/no-unused-modules
export const getDefaultAndAdditionalKpis = (allKpis = []) => {
    return {
        kpis: allKpis?.filter(kpi => !kpi.allSignal),
        additionalKpis: allKpis?.filter(kpi => kpi.allSignal)
    };
};

export const userLimitNameShouldChange = (value) => {
    return !(value.length > MAX_LENGTH_USER_LIMIT_NAME + 1);
};

