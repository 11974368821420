import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class SubscriptionInfoApi {
    static getSubscriptionList(assetIdParams) {
        const endpoint = formatEndpoint(endpoints.subscription.getSubscriptionList);
        return RestManagerWithoutBaseUrl.post(endpoint, assetIdParams);
    }
}

export default SubscriptionInfoApi;
