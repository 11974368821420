import React from 'react';
import colors from 'theme/_colors.scss';

const IconFactory = ({ width = 22, height = 18, viewBox = '0 0 22 18', color = colors.black, onClick }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' onClick={onClick} width={width} height={height} viewBox={viewBox} fill='none'>
			<path d='M18 0V8H16V4L8 8V4L0 8V16C0.0156147 16.5359 0.239524 17.0446 0.624169 17.418C1.00882 17.7914 1.52389 18.0002 2.06 18H19.94C20.4863 18 21.0103 17.783 21.3966 17.3966C21.783 17.0103 22 16.4863 22 15.94V0H18ZM6 15H3V12H6V15ZM12 15H9V12H12V15ZM18 15H15V12H18V15Z' fill={color}/>
		</svg>
	);
};

export default IconFactory;
