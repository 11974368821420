import React from 'react';
import PropTypes from 'prop-types';

const IconSuccess = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = 'white' }) =>
	<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} fill='none'>
		<path d='M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM10 18L5 13L6.5 11.51L10 15L17.5 7.51L19 9L10 18Z' fill={color} />
	</svg>;

IconSuccess.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconSuccess;
