import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { EventLogsApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';

// Action creators
export const setActiveTabAction = (activeTab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        activeTab
    };
};

const getEventLoggersAction = getApiActionCreator(actionTypes.GET_EVENT_LOGGERS);

// Actions
export const getLoggers = (component, event) => {
    return dispatch => {
        dispatch(getEventLoggersAction(apiCallStatus.LOADING));
        EventLogsApi.getLoggers(component, event.rawEvent.eventId, event.rawEvent.utc)
            .then((data) => {
                dispatch(getEventLoggersAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getEventLoggersAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_ASSISTANCE_PAGE });
        dispatch({ type: actionTypes.CLEAR_SELF_EVALUATION_STORE });
        dispatch({ type: actionTypes.CLEAR_TREND_SIGNAL_STORE });
    };
};
