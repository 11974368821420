import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconShowFiltersButton = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.black }) => {

    return <svg width={width} height={height} viewBox={viewBox} fill='none' role='filterIcon' xmlns='http://www.w3.org/2000/svg'>
        <rect width='32' height='32' rx='16' fill={color} fillOpacity='0.08' />
        <path d='M18 23H16.7L14 20V15L9 10V9H23V10L18 15V23Z' fill={colors.mineShaft} />
    </svg>;
};

IconShowFiltersButton.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};

export default IconShowFiltersButton;
