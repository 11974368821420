import colors from 'theme/_colors.scss';

const statusColors = {
    Poor: colors.pomegranate,
    Tolerable: colors.orangePeel,
    Good: colors.jungleGreen,
    Unknown: colors.smoke
};

export const statusColumns = {
    Poor: {
        color: colors.pomegranate,
        y: 5,
        label: 'ABB.Powertrain.Frontend.conditionDriveStatusPoor'
    },
    Tolerable: {
        color: colors.orangePeel,
        y: 5,
        label: 'ABB.Powertrain.Frontend.conditionDriveStatusTolerable'
    },
    Good: {
        color: colors.jungleGreen,
        y: 5,
        label: 'ABB.Powertrain.Frontend.conditionDriveStatusGood'
    },
    Unknown: {
        color: colors.smoke,
        y: 5,
        label: 'ABB.Powertrain.Frontend.conditionDriveStatusUnknown'
    }
};

export const statuses = [
    {
        id: 'das-li-1',
        color: statusColors.Unknown,
        title: 'ABB.Powertrain.Frontend.dailyAgingStressUnknown',
        description: 'ABB.Powertrain.Frontend.dailyAgingStressUnknownDescription'
    },
    {
        id: 'das-li-2',
        color: statusColors.Good,
        title: 'ABB.Powertrain.Frontend.dailyAgingStressSlower',
        description: 'ABB.Powertrain.Frontend.dailyAgingStressSlowerDescription'
    },
    {
        id: 'das-li-3',
        color: statusColors.Tolerable,
        title: 'ABB.Powertrain.Frontend.dailyAgingStressInLine',
        description: 'ABB.Powertrain.Frontend.dailyAgingStressInLineDescription'
    },
    {
        id: 'das-li-4',
        color: statusColors.Poor,
        title: 'ABB.Powertrain.Frontend.dailyAgingStressFaster',
        description: 'ABB.Powertrain.Frontend.dailyAgingStressFasterDescription'
    }
];
