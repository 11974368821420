import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    SidePanel,
    GeneralButton,
    ScrollableList,
    InfoMessage,
    SearchBox
} from 'sharedComponents';
import { PowertrainListItem } from './PowertrainListItem';
import { PowertrainDetails } from './PowertrainDetails';
import { PowertrainWizard } from './PowertrainWizard';
import { IconAddFilled } from 'svgIcons/MotionPortalIcons';
import { getSiteIds, getOrganizationIds } from './helpers';
import { pageSize } from './constants';
import colors from 'theme/_colors.scss';
import './style.scss';

const PowertrainConfigurationComponent = ({
    actions,
    model
}) => {
    const { t: translate } = useTranslation();

    const {
        filteredPowertrains,
        powertrainsLoadingStatus,
        selectedPowertrain,
        searchboxFilter,
        powertrainsCurrentPage,
        hasMorePowertrains,
        sites,
        selectedSites,
        organizations
    } = model;
    const {
        setSelectedPowertrain,
        setSearchboxFilter,
        deletePowertrainRequest,
        setPowertrainForEditing,
        getPowertrainsRequest
    } = actions;
    const [powertrainWizardVisible, setPowertrainWizardVisible] = useState(false);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            if (selectedSites?.length) {
                getPowertrainsRequest(getSiteIds(selectedSites), [], searchboxFilter, 1);
            } else if (organizations?.length) {
                getPowertrainsRequest([], getOrganizationIds(organizations), searchboxFilter, 1);
            }
        }, searchboxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [searchboxFilter, JSON.stringify(selectedSites), JSON.stringify(organizations)]);

    const renderPowertrains = () => {
        return filteredPowertrains.map((powertrain) => <PowertrainListItem
            key={powertrain.powertrainId}
            powertrain={powertrain}
            isSelected={selectedPowertrain?.powertrainId === powertrain.powertrainId}
            setSelectedPowertrain={setSelectedPowertrain}
        />);
    };

    return (
        <div className='tools_powertrain-configuration'>
            <SidePanel>
                <GeneralButton
                    className='tools_powertrain-configuration_sidePanel_addNewPowertrain-button'
                    type='primary'
                    text={translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab_AddNewPowertrainButton')}
                    icon={IconAddFilled({ width: '14px', height: '14px', color: colors.white })}
                    onClick={() => { setPowertrainWizardVisible(true); }} />
                <SearchBox
                    onChange={setSearchboxFilter}
                    value={searchboxFilter}
                    placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameText')} />
                <ScrollableList
                    emptyLabel={translate('ABB.Powertrain.Frontend.noMatchesFoundLabel')}
                    withLoading
                    loadingStatus={powertrainsLoadingStatus}
                    hasMore={hasMorePowertrains}
                    pageSize={pageSize}
                    loadMore={() => {
                        if (selectedSites?.length) {
                            getPowertrainsRequest(
                                getSiteIds(selectedSites),
                                [],
                                searchboxFilter,
                                powertrainsCurrentPage
                            );
                        } else if (organizations?.length) {
                            getPowertrainsRequest(
                                [],
                                getOrganizationIds(organizations),
                                searchboxFilter,
                                powertrainsCurrentPage
                            );
                        }
                    }}>
                    {renderPowertrains()}
                </ScrollableList>
            </SidePanel>
            {selectedPowertrain ?
                <PowertrainDetails
                    searchboxFilter={searchboxFilter}
                    powertrain={selectedPowertrain}
                    selectedSites={selectedSites}
                    sites={sites}
                    organizations={organizations}
                    deletePowertrain={deletePowertrainRequest}
                    setSelectedPowertrain={setSelectedPowertrain}
                    setPowertrainWizardVisible={setPowertrainWizardVisible}
                    setPowertrainForEditing={setPowertrainForEditing}
                /> :
                <InfoMessage title='ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab_NoPowertrainSelected_label' />}

            {powertrainWizardVisible && <PowertrainWizard
                show={powertrainWizardVisible}
                close={() => { setPowertrainWizardVisible(false); }}
            />}

        </div>
    );
};

PowertrainConfigurationComponent.propTypes = {
    model: PropTypes.shape({
        filteredPowertrains: PropTypes.array,
        powertrainsLoadingStatus: PropTypes.string,
        selectedPowertrain: PropTypes.any,
        searchboxFilter: PropTypes.string,
        organizations: PropTypes.array,
        powertrainsCurrentPage: PropTypes.number,
        hasMorePowertrains: PropTypes.bool,
        sites: PropTypes.array,
        selectedSites: PropTypes.array,
    }),
    actions: PropTypes.shape({
        setSelectedPowertrain: PropTypes.func,
        setSearchboxFilter: PropTypes.func,
        deletePowertrain: PropTypes.func,
        setPowertrainForEditing: PropTypes.func,
        getPowertrainsRequest: PropTypes.func,
        deletePowertrainRequest: PropTypes.func
    })
};

export default PowertrainConfigurationComponent;
