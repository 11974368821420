import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';


import ScatterPlotComponent from './ScatterPlotComponent';


const ScatterPlotContainer = (props) => {
	return <ScatterPlotComponent {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const ScatterPlotContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(ScatterPlotContainer);


export default ScatterPlotContainerConn;
