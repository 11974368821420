import { filterTabs } from 'helpers/constants';
import { actionTypes } from './constants';
import { getContractInfo } from './helpers';


const initialState = {
    activeTab: filterTabs.POWERTRAINS,
    selectedPowertrain: null,
    selectedAsset: null,
    selectedAssetCapability: {
        data: null,
        error: null,
        loadingStatus: null
    },
    newAssetCapability: {
        data: null,
        error: null,
        loadingStatus: null
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                selectedAsset: null,
                activeTab: action.activeTab
            };
        }
        case actionTypes.SET_SELECTED_POWERTRAIN: {
            return {
                ...state,
                activeTab: state.activeTab,
                selectedPowertrain: action.powertrain
            };
        }
        case actionTypes.SET_SELECTED_ASSET: {
            return {
                ...state,
                selectedAssetCapability: action.asset ? state.selectedAssetCapability : initialState.selectedAssetCapability,
                selectedAsset: action.asset
            };
        }
        case actionTypes.UPDATE_ASSET_SUCCESS: {
            return {
                ...state,
                selectedAsset: action.data,
            };
        }
        case actionTypes.GET_ASSET_CAPABILITY: {
            return {
                ...state,
                selectedAssetCapability: {
                    data: {
                        ...action.data,
                        contractInfo: getContractInfo(action.data)
                    },
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.GET_ASSET_NEWCAPABILITIES: {
            return {
                ...state,
                newAssetCapability: {
                    data: {
                        ...action.data
                    },
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.SET_SELECTED_FILTERS: {
            return {
                ...state,
                selectedAsset: null,
                selectedAssetCapability: initialState.selectedAssetCapability
            };
        }
        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                selectedAssetCapability: { ...initialState.selectedAssetCapability }
            };
        }
        default:
            return state;
    }
};
