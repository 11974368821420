import moment from 'moment';
import sortBy from 'lodash/sortBy';

import { downloadFile, b64toBlob } from 'helpers/download-file-util';
import logger from 'helpers/logger';
import { ParameterFilesApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';


const getParameterFileList = getApiActionCreator(actionTypes.GET_PARAMETER_FILE_LIST);
const getParameterFile = getApiActionCreator(actionTypes.GET_PARAMETER_FILE);

export const getParameterFileListForAsset = (asset, from, to) => {
	return (dispatch) => {

		if (!asset || !from || !to) {
			dispatch(getParameterFileList(apiCallStatus.SUCCESS, []));
		} else {

			// Let the UI know that data fetching is ongoing
			dispatch(getParameterFileList(apiCallStatus.LOADING));

			ParameterFilesApi.getParameterFileList(asset, from, to)
				.then((res) => {
					logger.debug('Parameter file list loaded successfully with following params:', asset);
					let data = res ? res.fileInformations : [];
					data = sortBy(data, paramterFile => new moment(paramterFile.timestamp)).reverse();
					dispatch(getParameterFileList(apiCallStatus.SUCCESS, data));
				})
				.catch((err) => {
					dispatch(getParameterFileList(apiCallStatus.ERROR, null, err));
				});
		}
	};
};

export const getParameterFileWithId = (asset, id, messages) => {
	return (dispatch) => {

		if (!asset || !id) {
			dispatch(getParameterFile(apiCallStatus.SUCCESS, []));
		} else {

			// Let the UI know that data fetching is ongoing
			dispatch(getParameterFile());

			ParameterFilesApi.getParameterFile(asset, id)
				.then((data) => {
					logger.debug('Parameter file loaded successfully with following params:', asset);
					if (data && data.stream) {
						dispatch(getParameterFile(apiCallStatus.SUCCESS, data));
						const fileBlob = b64toBlob(data.stream);
						downloadFile(data.name, fileBlob);
					}
					else {
						throw new Error(messages.error);
					}
				})
				.catch((err) => {
					dispatch(getParameterFile(apiCallStatus.ERROR, null, err));
				});
		}
	};
};

export const clearParameterFileData = () => {
	return (dispatch) => {
		dispatch(getParameterFileList(apiCallStatus.SUCCESS, []));
		dispatch(getParameterFile(apiCallStatus.SUCCESS, null));
	};
};
