import { createSelector } from 'reselect';
import { filterContracts } from '../../PowertrainOverview/helper';

const getLoadingStatusAsset = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.loadingStatus;
};

const getActiveTab = ({ powertrainTools }) => {
    return powertrainTools.subscriptionStatus.activeTab;
};
const getSubscriptionData = ({ powertrainTools, generalFilter }) => {
    return filterContracts(powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos, generalFilter?.assets?.data);
};

export const getModel = createSelector(
    [
        getLoadingStatusAsset,
        getActiveTab,
        getSubscriptionData,
    ],
    (
        loadingStatusAsset,
        activeTab,
        contracts
    ) => {
        return {
            loadingStatusAsset,
            activeTab,
            contracts
        };
    }
);
