import React from 'react';
import {
    filter,
    findIndex,
    map,
    sortBy,
    uniq,
    find,
    includes,
    intersectionWith
} from 'lodash';
import { getAssetIcon } from 'helpers/icons';
import { allAssetTypes, apiCallStatus } from 'helpers/constants';
import { allAssetTypeFilter } from 'helpers/filterHelper';
import {
    wizardSteps,
    wizardStepStates,
    pageSize,
    MAX_LENGTH_POWERTRAIN_NAME
} from './constants';


export const getWizardDefaultStepStates = () => {
    return {
        [wizardSteps.STEP1]: {
            state: wizardStepStates.ACTIVE,
            acceptAction: null,
            cancelAction: null,
        },
        [wizardSteps.STEP2]: {
            state: wizardStepStates.INACTIVE,
            acceptAction: null,
            cancelAction: null,
        },
        [wizardSteps.STEP3]: {
            state: wizardStepStates.INACTIVE,
            acceptAction: null,
            cancelAction: null,
        },
    };
};

export const setWizardStepState = (wizardSteps, step, newState) => {
    wizardSteps[step].state = newState;
    return wizardSteps;
};

const mapAssets = (assets, addedAssets, assetType = null) => {
    return assets && filter(assets, asset => {
        return assetType && assetType !== allAssetTypes ?
            asset.assetType === assetType && !find(addedAssets, addedAsset => addedAsset.id === asset.id) :
            !find(addedAssets, addedAsset => addedAsset.id === asset.id);
    }) || [];
};

export const getAssetTypes = (assets, translate) => {
    const options = [];

    const assetTypes = uniq(map(assets, (asset) => asset.assetType));

    map(assetTypes, (assetType, indx) => {
        const label = translate(`ABB.Powertrain.Frontend.${assetType.toLowerCase()}Label`) || assetType;
        options.push({
            id: indx + 1,
            title: <>{getAssetIcon(assetType, { width: 16, height: 16 })} {label}</>,
            assetType: assetType,
            assetTypeLabel: label,
        });
    });

    return [
        allAssetTypeFilter(translate),
        ...sortBy(options, [option => option?.assetTypeLabel])
    ];
};

const filterAssetsBySearchCriteria = (assets, searchCriteria) => {
    return searchCriteria !== '' ?
        filter(assets, asset => includes(asset.id, searchCriteria) ||
            includes(asset.name.toLowerCase(), searchCriteria?.toLowerCase()) ||
            includes(asset.serialNumber, searchCriteria)) :
        assets;
};

export const filterAddedAssetsByType = (assets, assetTypeFilter, searchCriteria) => {
    let filteredAssets = [];

    filteredAssets = !assetTypeFilter ?
        assets :
        filter(assets, asset => assetTypeFilter?.assetType === allAssetTypes ?
            true : asset.assetType === assetTypeFilter?.assetType);

    return filterAssetsBySearchCriteria(filteredAssets, searchCriteria);
};

export const filterAvailableAssetsByType = (assets, addedAssets, assetTypeFilter) => {
    let filteredAssets = [];

    filteredAssets = !assetTypeFilter ?
        mapAssets(assets, addedAssets) :
        mapAssets(assets, addedAssets, assetTypeFilter?.assetType);

    return filteredAssets;
};

export const getPowertrainAssetIds = (powertrain) => {
    return powertrain.assets.map((asset) => asset.id);
};

export const isPowertrainNameValid = (value) => {
    return value.length <= MAX_LENGTH_POWERTRAIN_NAME;
};

export const powertrainNameShouldChange = (value) => {
    return !(value.length > MAX_LENGTH_POWERTRAIN_NAME + 1 || value === ' ');
};

export const assemblePowertrain = (powertrainId, name, site, assets) => {
    return {
        powertrainId,
        name,
        siteId: site.siteId,
        assets
    };
};


const filterAssetsByPowertrainSite = (assets, siteId) => {
    return assets?.length && assets.filter((asset) => {
        return asset?.site?.siteId === siteId;
    });
};


export const disassemblePowertrain = (powertrain) => {
    const filteredAssets = powertrain && powertrain.assets?.length && powertrain.siteId ?
        filterAssetsByPowertrainSite(powertrain.assets, powertrain.siteId) : [];
    return {
        powertrainName: powertrain.name,
        selectedSite: { siteId: powertrain.siteId },
        powertrainID: powertrain.powertrainId,
        addedAssets: [...filteredAssets],
    };
};

export const addAssetToList = (assets, asset) => {
    const newAssetsList = assets ? assets : [];
    newAssetsList.push(asset);
    return newAssetsList;
};

export const removeAssetFromList = (assets, assetToRemove) => {
    assets.splice(findIndex(assets, asset => asset.id === assetToRemove.id), 1);
    return assets;
};

export const getUpdatedAvailableAssets = (availableAssets, action) => {
    const hasMore = action.data?.assets && !(action.data?.assets?.length < pageSize);
    const data = action.loadingStatus !== apiCallStatus.LOADING &&
        action.data?.pageNumber === 1 ? [] : [...availableAssets.data];

    return action.loadingStatus !== apiCallStatus.SUCCESS ? {
        ...availableAssets,
        loadingStatus: action.loadingStatus
    } : {
        ...availableAssets,
        loadingStatus: action.loadingStatus,
        data: data && [...data, ...action.data.assets],
        pageNumber: hasMore ? action.data?.pageNumber + 1 : action.data?.pageNumber,
        hasMore
    };
};

export const formatAddPowertrainRequest = (powertrain) => {
    return {
        name: powertrain.name,
        siteId: powertrain.siteId,
        description: ''
    };
};

export const formatGetAssetsRequest = (siteId, organizationIds, pageNumber, filter) => {
    return {
        refreshData: true,
        pageNumber,
        pageSize,
        ...filter?.length && { filter },
        siteIds: [siteId],
        organizationIds,
        assetGroupIds: []
    };
};

export const getAssetListIntersect = (availableAssets, allAssets) => {
    return intersectionWith(
        availableAssets,
        allAssets,
        (available, all) => available.assetId === all.assetID);
};
