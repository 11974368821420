import { getActionWithDefaultData } from 'helpers/actionHelper';
import { actionTypes as userLimitActionTypes } from './UserLimits/constants';
import { actionTypes } from './constants';


const initialState = {
    userLimits: {
        data: null,
        error: null,
        loadingStatus: null,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case userLimitActionTypes.GET_USER_LIMITS: {
            return {
                ...state,
                userLimits: getActionWithDefaultData(action, initialState.userLimits)
            };
        }
        case actionTypes.CLEAR_STORE: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

