import { filter, forEach } from 'lodash';
import { overallStatus } from '../constants';
import { convertDataToChartData } from '../helpers';

export const formatChartData = (powertrains, searchTerm = null) => {
    const filteredPowertrains = searchTerm !== null ?
        filter(powertrains, (powertrain) =>
            powertrain.systemName.toLowerCase().includes(searchTerm.toLowerCase())) : powertrains;

    const totalConditionStates = {
        [overallStatus.POOR]: {
            total: 0
        },
        [overallStatus.TOLERABLE]: {
            total: 0
        },
        [overallStatus.GOOD]: {
            total: 0
        },
        [overallStatus.UNKNOWN]: {
            total: 0
        }
    };

    forEach(filteredPowertrains, (powertrain) => {
        forEach(
            [overallStatus.POOR,
            overallStatus.TOLERABLE,
            overallStatus.GOOD,
            overallStatus.UNKNOWN],
            (stateName) => {
                if (powertrain.overallStatus === stateName) {
                    ++totalConditionStates[stateName].total;
                }
            });
    });

    return convertDataToChartData(totalConditionStates);
};

