import PropTypes from 'prop-types';
import React from 'react';
import { getAssetIcon } from 'helpers/icons';
import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';

const AssetListItem = ({
	asset,
	selectedComponentID,
	onAssetClick
}) => {

	return <div
		className={`asset-list-item ${selectedComponentID === asset.componentID && 'selected'}`}
		onClick={() => onAssetClick(asset.componentID)}
	>
		<div className='asset-icon-container'>{getAssetIcon(asset.componentType?.typeName, { width: 20, height: 20 })}</div>
		<pre className='asset-name'>{asset.componentName}</pre>
		<IconCondition condition={asset.overallStatus} />
	</div>;
};

AssetListItem.propTypes = {
	asset: PropTypes.any,
	selectedComponentID: PropTypes.any,
	onAssetClick: PropTypes.func,
};

export default AssetListItem;
