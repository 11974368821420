import React from 'react';

const IconDropDownMo = ({ width = 8, height = 5, color = '#0F0F0F' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path fillRule='evenodd' clipRule='evenodd' d='M4 4.7L0 0.7L0.7 0L4 3.29L7.3 0L8 0.7L4 4.7Z' fill={color} />
		</svg>
	);
};

export default IconDropDownMo;
