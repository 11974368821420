export const loginUrls = {
	register: 'https://www.usermanagement.abb.com/account/Register',
	faq: 'http://new.abb.com/motors-generators/service/advanced-services/smart-sensor/faq',
	appStore: 'https://itunes.apple.com/app/smart-sensor-for-motors/id1222531884',
	googlePlayStore: 'https://play.google.com/store/apps/details?id=com.abb.ability.smartsensor',
	abbAppStoreChina: 'https://cloudapps02.abb.com.cn/9aag025609/Packages/download/SmartSensor',
	providerInformation: 'http://new.abb.com/provider-information',
	privacyPolicy: 'http://new.abb.com/privacy-policy',
	privacyPolicyCookies: 'https://new.abb.com/privacy-policy#Cookies',
	smartSensorProductionSite: 'https://smartsensor.abb.com/',
	powertrainProductionSite: 'https://powertrain.abb.com',
};

export const cookieConsentKey = 'cookieConsent';
