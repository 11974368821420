import { languageLabels } from 'helpers/constants';
import { isSupported } from 'helpers/languageHelper';
import find from 'lodash/find';
import map from 'lodash/map';


export const formatLanguages = (languages) => {
    if (!(languages && languages.length)) {
        return [];
    }

    const englishLanguageData = languages.find((language) => language.code === 'en');

    const formattedLanguages = languages.map((language) => {
        return {
            id: language.code,
            label: languageLabels[language.code],
            value: language.code,
        };
    })
        .filter(language => language.value !== 'en' && isSupported(language.value))
        .sort((a, b) => a.label < b.label ? -1 : 1);

    return [
        {
            id: englishLanguageData.code,
            label: englishLanguageData.nativeName,
            value: englishLanguageData.code
        },
        {
            displayName: '',
            identifier: 'separatorID',
            isDisabled: true
        },
        ...formattedLanguages
    ];
};

export const formatSelectedLanguage = (selectedLanguageCode, availableLanguages) => {
    let selectedLanguage = null;

    if (selectedLanguageCode && availableLanguages) {
        selectedLanguage = find(availableLanguages, (language) => language.code === selectedLanguageCode);
    }

    return selectedLanguage ? {
        id: selectedLanguage.code,
        label: languageLabels[selectedLanguage.code],
        value: selectedLanguage.code
    } : null;
};

const mapUnitGroupsForPayload = (unitGroups) => {
    return map(unitGroups, (unitGroup) => {
        return {
            groupId: unitGroup.measurementUnitGroup.id,
            standardId: unitGroup.standardId
        };
    });
};

export const mapLanguageMicroservicePayload = (languageCode, userData) => {
    return {
        languageCode,
        landingPageId: userData.landingPageId,
        measurementUnitGroups: mapUnitGroupsForPayload(userData.measurementUnitGroups)
    };
};
