import { createSelector } from 'reselect';
import { getLoadingStatuses } from 'helpers/selectors';


const getSites = ({ generalFilter }) => {
    return generalFilter.sites?.data ? generalFilter.sites.data.map((site) => {
        return {
            siteId: site.siteID,
            siteName: site.siteName
        };
    }) : [];
};

const getSelectedSites = ({ generalFilter }) => {
    return generalFilter.selectedFilters.sitesChecked ?
        generalFilter.selectedFilters.sites.map((site) => {
            return {
                siteId: site.siteID,
                siteName: site.siteName
            };
        }) :
        [];
};

const getOrganizations = ({ generalFilter }) => {
    return generalFilter.selectedFilters.organizations || [];
};

const getSearchboxFilter = ({ powertrainTools }) => {
    return powertrainTools.configuration.searchboxFilter;
};

const getSelectedPowertrain = ({ powertrainTools }) => {
    return powertrainTools.configuration.selectedPowertrain;
};

const getPowertrains = ({ powertrainTools }) => {
    return powertrainTools.configuration?.powertrains?.data;
};

const getPowertrainsLoadingStatus = ({ powertrainTools }) => {
    return powertrainTools.configuration?.powertrains?.loadingStatus;
};

const getPowertrainsCurrentPage = ({ powertrainTools }) => {
    return powertrainTools.configuration?.powertrains?.pageNumber;
};

const getHasMorePowertrains = ({ powertrainTools }) => {
    return powertrainTools.configuration?.powertrains?.hasMore;
};

export const getModel = createSelector(
    [
        getSites,
        getSelectedSites,
        getOrganizations,
        getLoadingStatuses,
        getSelectedPowertrain,
        getSearchboxFilter,
        getPowertrains,
        getPowertrainsLoadingStatus,
        getPowertrainsCurrentPage,
        getHasMorePowertrains
    ],
    (
        sites,
        selectedSites,
        organizations,
        generalFilterLoadingStatuses,
        selectedPowertrain,
        searchboxFilter,
        filteredPowertrains,
        powertrainsLoadingStatus,
        powertrainsCurrentPage,
        hasMorePowertrains
    ) => {
        return {
            sites,
            selectedSites,
            organizations,
            generalFilterLoadingStatuses,
            selectedPowertrain,
            searchboxFilter,
            filteredPowertrains,
            powertrainsLoadingStatus,
            powertrainsCurrentPage,
            hasMorePowertrains
        };
    }
);
