import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useNewCapability } from 'sharedHooks';

import { Row } from 'react-bootstrap';
import ParameterSection from './Sections/ParameterSection';
import './style.scss';


const TrendKPIListComponent = (props) => {
    const { model, /* actions */ } = props; // COMMENTED ACTIONS FOR REMOVE WARNINGS
    const capability = useNewCapability();

    useEffect(() => {
        // if (capability.isSmartSensor()) {
        //     actions.getHealthStatuses(props.asset);
        // } // UN-COMMENT THIS CODE WHEN NEED TO CALL HEALTHSTATUS API
    }, [capability]);

    const sectionKpi = [{ data: model?.trendKPIsList }];

    return (
        <div className='signal-block'>
            {model?.trendKPIsList?.length ?
                <Row className='trend-kpi-list measurement-list-container'>
                    {
                        map(sectionKpi, (kpiSection, kpiIndex) => {
                            return <ParameterSection
                                key={kpiIndex}
                                section={kpiSection}
                                capability={capability}
                                selectedTrendKPIs={model.selectedTrendKPIs} />;
                        })
                    }
                </Row> : null}
        </div>
    );
};

TrendKPIListComponent.propTypes = {
    actions: PropTypes.shape({
        getTrendKPIsForAssetV2: PropTypes.func
    }),
    asset: PropTypes.object,
    model: PropTypes.shape({
        trendKPIsList: PropTypes.array,
        selectedTrendKPIs: PropTypes.array
    })
};

export default TrendKPIListComponent;
