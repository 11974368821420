import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { map } from 'lodash';

import { useCapability } from 'sharedHooks';
import { getToggleOptions } from './helpers';
import { tabContainerIds } from './constants';
import { OperationalParametersHeader } from './OperationalParametersHeader';

import './style.scss';

const OperationalParametersComponent = ({
    model: {
        activeTab,
        multiChart
    },
    asset
}) => {
    const toggleOptions = getToggleOptions(useCapability(), multiChart);

    return toggleOptions.length ?
        <Container fluid id='operational-parameters-container'>
            <OperationalParametersHeader toggleOptions={toggleOptions} />
            <Row className='operational-parameter-tab-chart-row'>
                <Col md={12} className='operational-parameter-tab-container'>
                    {
                        map(toggleOptions, (opt) => {
                            return <div key={opt.value} style={{ display: opt.value === activeTab ? null : 'none' }} id={tabContainerIds[opt.value]}>
                                <opt.component activeTab={activeTab} asset={asset} multiChart={multiChart} />
                            </div>;
                        })
                    }
                </Col>
            </Row>
        </Container> : null;
};

OperationalParametersComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
    }),
    asset: PropTypes.object,
};

export default OperationalParametersComponent;
