import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import * as constants from './constants';
import logger from 'helpers/logger';
import { getApiActionCreator } from 'helpers/actionHelper';
import { downloadLoggerFile } from './helpers';

//Actions
const downloadLoggerAction = getApiActionCreator(constants.DOWNLOAD_LOGGER);

const clearLoggerFileAction = () => {
	return {
		type: constants.CLEAR_LOGGER_FILE,
	};
};

const clearLoggerFile = () => {
	return (dispatch) => {
		dispatch(clearLoggerFileAction());
	};
};

//Action Creators
export const downloadLogger = (asset, loggerItem) => {
	return dispatch => {
		dispatch(downloadLoggerAction(apiCallStatus.LOADING));
		EventLogsApi.downloadLogger(asset, loggerItem.id, loggerItem.type, loggerItem.timestamp)
			.then((data) => {
				logger.debug('Logger successfully downloaded for:', asset, loggerItem);
				downloadLoggerFile(data.fileContent, loggerItem.fileFullName);
				dispatch(downloadLoggerAction(apiCallStatus.SUCCESS, data.fileContent));
				clearLoggerFile();
			})
			.catch((err) => {
				dispatch(downloadLoggerAction(apiCallStatus.ERROR, null, err));
			});
	};
};
