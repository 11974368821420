export const pdfReaderInstalled = () => {
    const hasAcrobatInstalled = () => {
        const getActiveXObject = (name) => {
            // eslint-disable-next-line no-undef, no-empty
            try { return new ActiveXObject(name); } catch (e) { }
        };
        return getActiveXObject('AcroPDF.PDF') || getActiveXObject('PDF.PdfCtrl');
    };

    const isIos = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    return navigator.mimeTypes['application/pdf'] || hasAcrobatInstalled() || isIos();
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const getUrlForBlob = (blob) => {
    if (blob) {
        return window.URL.createObjectURL(blob);
    }
};
