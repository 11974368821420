import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconSmartHMI = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M22.71 13.71L18 18.41L14 14.41L10.71 17.71L9.29 16.29L14 11.59L18 15.59L21.29 12.29L22.71 13.71ZM25.1
		15C25.1 16.7998 24.5663 18.5592 23.5664 20.0557C22.5665 21.5522 21.1452 22.7185 19.4824 23.4073C17.8196 24.096
		15.9899 24.2763 14.2247 23.9251C12.4595 23.574 10.838 22.7073 9.56533 21.4347C8.29267 20.162 7.42598 18.5405 7.07485
		16.7753C6.72373 15.0101 6.90394 13.1804 7.5927 11.5176C8.28145 9.85476 9.44782 8.43353 10.9443 7.43361C12.4408
		6.43369 14.2002 5.89998 16 5.89998C17.1954 5.89867 18.3793 6.13315 19.484 6.59C20.5886 7.04685 21.5923 7.7171
		22.4376 8.56238C23.2829 9.40765 23.9531 10.4114 24.41 11.516C24.8668 12.6207 25.1013 13.8046 25.1 15ZM24.1 15C24.1
		13.398 23.6249 11.8319 22.7349 10.4999C21.8449 9.16783 20.5798 8.12963 19.0997 7.51656C17.6197 6.90349 15.991
		6.74308 14.4198 7.05562C12.8485 7.36816 11.4052 8.13961 10.2724 9.27242C9.13963 10.4052 8.36818 11.8485 8.05564
		13.4198C7.7431 14.991 7.90351 16.6196 8.51658 18.0997C9.12965 19.5798 10.1678 20.8448 11.4999 21.7349C12.8319
		22.6249 14.398 23.1 16 23.1C17.0637 23.1 18.117 22.8905 19.0997 22.4834C20.0825 22.0763 20.9754 21.4797 21.7276
		20.7275C22.4797 19.9754 23.0764 19.0825 23.4834 18.0997C23.8905 17.117 24.1 16.0637 24.1 15ZM24 25.23V29H21V27C19.4157
		27.6601 17.7163 28 16 28C14.2837 28 12.5843 27.6601 11 27V29H8V25.23C5.86629 23.5642 4.30582 21.2738 3.53632
		18.6785C2.76681 16.0832 2.82666 13.3124 3.70752 10.7528C4.58837 8.19316 6.24628 5.97233 8.44993 4.40019C10.6536
		2.82805 13.293 1.98303 16 1.98303C18.707 1.98303 21.3464 2.82805 23.5501 4.40019C25.7537 5.97233 27.4116 8.19316
		28.2925 10.7528C29.1733 13.3124 29.2332 16.0832 28.4637 18.6785C27.6942 21.2738 26.1337 23.5642 24 25.23ZM16
		26C18.1756 26 20.3023 25.3548 22.1113 24.1461C23.9202 22.9375 25.3301 21.2195 26.1627 19.2095C26.9952 17.1995
		27.2131 14.9878 26.7886 12.854C26.3642 10.7202 25.3166 8.76019 23.7782 7.22181C22.2398 5.68343 20.2798 4.63578
		18.146 4.21134C16.0122 3.78691 13.8005 4.00474 11.7905 4.83731C9.7805 5.66987 8.06253 7.07977 6.85383 8.88871C5.64514
		10.6977 5 12.8244 5 15C5 17.9174 6.15893 20.7153 8.22183 22.7782C10.2847 24.8411 13.0826 26 16 26Z' fill={color} />
	</svg>;
};

IconSmartHMI.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconSmartHMI;
