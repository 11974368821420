import React from 'react';
import { Col } from 'react-bootstrap';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { GeneralPagination } from 'sharedComponents/GeneralPagination';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { eventStatus } from '../../constants';
import { pageSize } from './constants';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';

const EventDetailsSSCommentList = ({ model, selectedPage, setSelectedPage, displayedComments }) => {
    const { t: translate } = useTranslation();
    return (
        <Col
            className='event-details-comments'
            xl={model.selectedEvent.rawEvent.status === eventStatus.CLOSED ? 12 : 7}
            xs={12}
        >
            <div className='event-comments-label'>
                {translate('ABB.Powertrain.Frontend.eventDetailsEventCommentsLbl')}
            </div>
            <LoadingStatusWrapper
                loadingStatus={model.eventComments.loadingStatus}
            >
                {model.eventComments?.data.length > 0 ?
                    <>
                        {map(displayedComments, (comment, i) => {
                            return (
                                <div
                                    className='event-details-comment'
                                    key={`key${i}`}
                                >
                                    <div className='comment-user-time'>
                                        {comment.userName} |{' '}
                                        {convertUtcToLocalDateTime(
                                            comment.commentTimeStamp
                                        )}
                                    </div>
                                    <div className='user-comment'>
                                        {comment.comment}
                                    </div>
                                </div>
                            );
                        })}
                        <GeneralPagination
                            currentPage={selectedPage}
                            total={model.eventComments.data.length}
                            pageSize={pageSize}
                            onChange={setSelectedPage}
                        />
                    </>
                    :
                    <div className='no-comment-available'>
                        {translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                    </div>
                }
            </LoadingStatusWrapper>
        </Col>
    );
};

export default EventDetailsSSCommentList;
