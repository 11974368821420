import endpoints, { formatEndpoint } from '../endpoints';
import {
	assetObjectToComponentRequest
} from '../helpers';
import { RestManager } from '../RestManager';

class EventLogs {

	static getEventLogs(assets, from, to, organizationID) {
		const endpoint = formatEndpoint(endpoints.eventLogs.eventlogs, { from, to, organizationID });
		return RestManager.post(endpoint, assets);
	}

	static postEventComment(component, eventLogID, eventTimestamp, causeOfEvent, comment, isMaskedEvent) {
		const endpoint = endpoints.eventLogs.eventComment;

		return RestManager.put(endpoint, {
			component: assetObjectToComponentRequest(component),
			eventLogID,
			eventTimestamp,
			causeOfEvent,
			comment,
			isMaskedEvent,
		});
	}

	static deleteComment(component, commentID, timestamp) {
		const endpoint = formatEndpoint(endpoints.eventLogs.deleteComment, { id: commentID, timestamp });
		return RestManager.delete(endpoint, assetObjectToComponentRequest(component));
	}

	static getEventComments(asset, eventLogIDList, eventLogID, timestamp) {
		const endpoint = formatEndpoint(endpoints.eventLogs.eventComments, { eventLogID, timestamp });
		return RestManager.post(endpoint, { ...assetObjectToComponentRequest(asset), eventLogIDList });
	}

	static getLoggers(asset, eventID, eventTime) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.loggers, { eventID, eventTime });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getEventLogLoggerSignals(asset, params) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.signal);
		return RestManager.post(endpoint, { ...params, component: assetObjectToComponentRequest(asset) });
	}

	static downloadLogger(asset, loggerID, loggerType, loggerDate) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.downloadLogger, { loggerID, loggerType, loggerDate });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}
}

export default EventLogs;
