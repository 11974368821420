import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

const getLoggers = ({ eventDetailsCMD }) => {
	return sortBy(eventDetailsCMD.loggers.data, logger => new Date(logger.timestamp)).reverse();
};

const geteventLogDateKind = ({ eventLog }) => {
	return eventLog.dateKind;
};

const getLoggersLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggers?.loadingStatus;
};

const getLoggerFileLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggerFile?.loadingStatus;
};


export const getModel = createSelector([
	getLoggers,
	geteventLogDateKind,
	getLoggersLoadingStatus,
	getLoggerFileLoadingStatus
],
	(
		loggers,
		dateKind,
		loggersLoadingStatus,
		loggerFileLoadingStatus,
	) => {
		return {
			loggers,
			dateKind,
			loggersLoadingStatus,
			loggerFileLoadingStatus,
		};
	});
