import { useEffect } from 'react';

import useIsDirty from 'sharedHooks/useIsDirty';

const DirtyFlagCalculation = (props) => {

    const { actions, kpi } = props;
    const { config } = kpi;

    const [isDirty, setIsDirty] = useIsDirty([config]);

    const resetDirtyFlag = () => {
        setTimeout(() => { setIsDirty(false); }, 0);
    };

    useEffect(() => {
        const state = {
            isAlertValid: true,
            isAlarmValid: true,
            isDirty
        };
        actions.setParamEditState({ [kpi.measurementTypeID]: state });
    }, [isDirty]);


    return props.render(resetDirtyFlag);
};

export default DirtyFlagCalculation;
