import { createSelector } from 'reselect';
import { formatAssetInfoData, formatSections, getOrgNameFromId } from './helpers';
import { getOrganizations } from 'helpers/selectors';


const getAssetDetails = ({ detailedInformation }) => {
    return detailedInformation.assetDetails;
};

const getSubscriptionStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos;
};

const getAssetDetailsSelector = createSelector([getAssetDetails, getOrganizations, getSubscriptionStatus],
    (assetDetails, organizations, subscriptionStatus) => {
        return {
            details: formatAssetInfoData({
                ...assetDetails.data?.details,
                organizationName: getOrgNameFromId(organizations, assetDetails.data?.details?.organizationID),
                subscriptionCode: subscriptionStatus?.length > 0 && subscriptionStatus[0]?.subscriptionInfos && subscriptionStatus[0]?.subscriptionInfos.length > 0 ? subscriptionStatus[0]?.subscriptionInfos[0]?.type ? subscriptionStatus[0]?.subscriptionInfos[0]?.type : '-' : '-',
                subscriptionEndDate: subscriptionStatus?.length > 0 && subscriptionStatus[0]?.subscriptionInfos && subscriptionStatus[0]?.subscriptionInfos.length > 0 ? subscriptionStatus[0]?.subscriptionInfos[0]?.endDate ? subscriptionStatus[0]?.subscriptionInfos[0]?.endDate : '-' : '-'
            }),
            sections: formatSections(assetDetails.data?.sections),
            loadingStatus: assetDetails.loadingStatus
        };
    });


export const getModel = createSelector([
    getAssetDetailsSelector
], (
    assetDetails
) => {
    return {
        assetDetails
    };
});
