import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailAlertComponent from './EmailAlertComponent';
import { getModel } from './selectors';
import * as actions from './actions';

import './style.scss';

const EmailAlertContainer = (props) => {

	useEffect(() => {
		if (props.model.filteredAssets && props.model.filteredAssets.length) {
			props.actions.loadCapabilitiesForAssets(props.model.filteredAssets);
		}
	}, [props.model.filteredAssets]);

	useEffect(() => {
		if (props.model.selectedAsset) {
			props.actions.getEmailAlertSettings([props.model.selectedAsset]);
		}
	}, [props.model.selectedAsset]);

	useEffect(() => () => {
		props.actions.clearStore();
	}, []);

	return <EmailAlertComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const EmailAlertContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(EmailAlertContainer);

export default EmailAlertContainerConn;
