import PropTypes from 'prop-types';
import React from 'react';
import { FFTTooltipPointValueRow } from './FFTTooltipPointValueRow';
import { getMeasurementUnitSymbol } from 'helpers/unitHelper';
import { roundNumber } from 'helpers/numberHelper';
import './styles.scss';


const FFTTooltip = (props) => {
    const {
        points,
        frequencyUnitSymbol,
        magnitudeUnitSymbol,
        precision
    } = props;

    const frequency = roundNumber(points[0].x, precision).toFixed(precision);
    const rpm = Math.floor(frequency) * 60;

    return (
        <div className='custom-fft-tooltip'>
            <div className='t-header'>
                {`${rpm} ${getMeasurementUnitSymbol(14)}`}
                <br />
                {`${frequency} ${frequencyUnitSymbol}`}
            </div>
            {points.map((point, kpiIndex) => {
                return (
                    <div className='t-item' key={kpiIndex}>
                        {
                            <FFTTooltipPointValueRow point={point} magnitudeUnitSymbol={magnitudeUnitSymbol} />
                        }
                    </div>
                );
            })}
        </div>
    );
};

FFTTooltip.propTypes = {
    points: PropTypes.array,
};

export default FFTTooltip;
