import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { InfoMessage, LoadingStatusWrapper, PopOutChart } from 'sharedComponents';
import useChartConfig, { chartPlugins } from './useChartConfig';
import { crossAssetTrendsContainerId } from './constants';
import { paramListForChartDataRequest } from '../../helpers';

import '../style.scss';
import { EnableUniqueScaling, PopOutButton, ResetZoomButton, withLeftButtonGroup, withPopOutRow, withRightButtonGroup } from 'sharedComponents/Chart/headers';

const TrendComponent = ({
    chartData,
    removeKPIData,
    chartLoadingStatus,
    setIsZoomed,
    zoomInToSelectedTrendKpiData,
    resetChartData,
    model: { enableUniqueScaling, isZoomed } }
) => {

    const zoomInServer = (from, to) => {
        const list = paramListForChartDataRequest(chartData);
        zoomInToSelectedTrendKpiData(list, true, from, to);
    };

    const resetServerZoom = () => {
        const list = paramListForChartDataRequest(chartData);
        resetChartData(list, false);
    };

    const chartOptions = useChartConfig(chartData, removeKPIData);

    const CustomChartHeader = withPopOutRow([
        withLeftButtonGroup([EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);

    return (
        <Container fluid className='cross-asset-chart-container'>
            <LoadingStatusWrapper
                useCustomAnchor
                transparent
                loadingStatus={chartLoadingStatus}>
                <PopOutChart
                    hasKpi={!!chartData?.length}
                    renderNoKpi={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                        text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                    loadingStatus={chartLoadingStatus}
                    enableUniqueScaling={enableUniqueScaling}
                    options={chartOptions}
                    modules={chartPlugins}
                    container={crossAssetTrendsContainerId}
                    isZoomed={isZoomed}
                    setIsZoomed={setIsZoomed}
                    zoomInServer={zoomInServer}
                    resetServerZoom={resetServerZoom}
                    mainChartHeader={CustomChartHeader}
                />
            </LoadingStatusWrapper>
        </Container>
    );
};

TrendComponent.propTypes = {
    actions: PropTypes.shape({
        removeKPIData: PropTypes.func
    }),
    model: PropTypes.shape({
        chartData: PropTypes.array,
        chartLoadingStatus: PropTypes.string,
        enableUniqueScaling: PropTypes.bool
    }),
    setIsZoomed: PropTypes.func
};

export default TrendComponent;
