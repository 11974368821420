import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserLimitsComponent from './UserLimitsComponent';
import { getModel } from './selectors';
import * as userLimitsActions from './actions';
import { kpiTypes } from 'helpers/constants';

const UserLimitsContainer = (props) => {
	useEffect(() => {
		props.actions.getPlotKPIList(props.asset, kpiTypes.TREND);
		props.actions.getUserLimits(props.asset);
		return () => {
			props.actions.clearStore();
		};
	}, []);

	return <UserLimitsComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(userLimitsActions, dispatch)
	};
};

const UserLimitsContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserLimitsContainer);

export default UserLimitsContainerConn;
