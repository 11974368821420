import * as actionTypes from './constants';
import { getUserManager } from './helpers';

const initialState = {
	environmentProperties: {
		data: null,
		loadingStatus: null
	},
	userManager: null,
	globalError: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.LOAD_ENVIRONMENT_PROPERTIES:
			return {
				...state,
				environmentProperties: { ...action },
				userManager: getUserManager(action.data)
			};
		case actionTypes.SET_GLOBAL_HTTP_ERROR:
			return {
				...state,
				globalError: action.globalError
			};
		default:
			return state;
	}
}
