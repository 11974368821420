import { actionTypes } from './constants';
import { actionTypes as operationalParamActions } from '../../constants';
import { PlotApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { createParamMeasurementAssetObj } from '../../../../helpers';

const scatterKpiAction = getApiActionCreator(actionTypes.GET_ASSET_SCATTER_KPIS);
export const getScatterKPIsForAsset = (assetObj, plotType) => {
    return (dispatch) => {
        dispatch(scatterKpiAction(apiCallStatus.LOADING));
        PlotApi.getPlotKPIList(assetObj, plotType)
            .then((data) => {
                dispatch(scatterKpiAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(scatterKpiAction(apiCallStatus.ERROR, null, err));
            });
    };
};

const scatterDataAction = getApiActionCreator(actionTypes.GET_SCATTER);
export const getScatterForAsset = (from, to, assetObj, measurement) => {
    return (dispatch, getState) => {
        // Create parameter object for POST body content
        const param = createParamMeasurementAssetObj(measurement, assetObj);
        const originalAssetID = getState().powertrainAssetDetails.selectedAsset.componentID;

        dispatch(scatterDataAction(apiCallStatus.LOADING));

        PlotApi.getScatter(param, from, to)
            .then((data) => {
                const assetID = getState().powertrainAssetDetails.selectedAsset.componentID;

                if (originalAssetID === assetID) {
                    dispatch(scatterDataAction(apiCallStatus.SUCCESS, data));
                }
            })
            .catch((err) => {
                dispatch(scatterDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setSelectedDates = (from, to) => {
    return (dispatch) => {
        dispatch({ type: operationalParamActions.SET_DATE_SELECTION, dateSelection: { from, to } });
    };
};


export const setSelectedKpi = (scatterSelectedKpi) => {
    return (dispatch, getState) => {
        const asset = getState().powertrainAssetDetails.selectedAsset;
        dispatch({
            type: actionTypes.SET_SCATTER_KPI,
            scatterSelectedKpi: {
                ...scatterSelectedKpi,
                assetTypeID: asset.componentType.typeID
            }
        });
    };
};

const removeSelectedKpiLegendAction = (signalIndex) => {
    return {
        type: actionTypes.REMOVE_TREND_KPI_SELECTION_LEGEND_SCATTER,
        signalIndex
    };
};

// Action Creators
export const removeSelectedKpiLegend = (signalIndex) => {
    return (dispatch) => {
        dispatch(removeSelectedKpiLegendAction(signalIndex));
    };
};
