import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';
import { SensorFeatures } from './SensorFeatures';
import { SensorDetails } from './SensorDetails';
import SensorPropertiesNotAvailable from './SensorPropertiesNotAvailable';
import './style.scss';

const SensorPropertiesComponent = (props) => {
    const { t: translate } = useTranslation();
    const { model } = props;

    return (
        <div id='sensor-properties-container'>
            <LoadingStatusWrapper
                useCustomAnchor
                loadingStatus={[model.assetInfo.loadingStatus,
                model.sensorFeatures.loadingStatus,
                model.sensorProperties?.loadingStatus,
                model.gatewayInfo.loadingStatus,
                model.assetDetails.loadingStatus]}>

                {!isEmpty(model.sensorProperties?.data) && props.asset.isMonitored ?
                    <div className='sensor-properties-component'>
                        <div className='sensor-properties-lists-container'>
                            <SensorDetails {...props} />
                        </div>
                        <div className='sensor-properties-lists-container'>
                            <SensorFeatures sensorFeatures={model.sensorFeatures.data} />
                        </div>
                    </div>
                    :
                    <SensorPropertiesNotAvailable text={translate('ABB.Powertrain.Frontend.SensorPropertiesNotApplicableText')} />
                }
            </LoadingStatusWrapper>
        </div>
    );
};

SensorPropertiesComponent.propTypes = {
    actions: PropTypes.shape({
        getSensorFeatures: PropTypes.func
    }),
    asset: PropTypes.any,
    model: PropTypes.shape({
        sensorFeatures: PropTypes.object,
        sensorProperties: PropTypes.object
    })
};


export default SensorPropertiesComponent;
