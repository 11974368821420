import { createSelector } from 'reselect';


const getPowertrainOverviewList = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.data;
};

const getPowertrainOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.loadingStatus;
};

const getPageNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.pageNumber;
};

const getHasMore = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.hasMore;
};

export const getModel = createSelector(
    [
        getPowertrainOverviewList,
        getPowertrainOverviewLoadingStatus,
        getPageNumber,
        getHasMore
    ],
    (
        powertrainOverviewList,
        powertrainOverviewLoadingStatus,
        pageNumber,
        hasMore
    ) => {
        return {
            powertrainOverviewList,
            powertrainOverviewLoadingStatus,
            pageNumber,
            hasMore
        };
    }
);
