import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PowertrainAssetsOverviewComponent from './PowertrainAssetsOverviewComponent';
import * as actions from './actions';
import { getModel } from './selectors';
import { connect } from 'react-redux';

const PowertrainAssetsOverviewContainer = (props) => {
    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <PowertrainAssetsOverviewComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PowertrainAssetsOverviewContainer);
