import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';


class Log {
    getIssueTypes() {
        const endpoint = formatEndpoint(endpoints.log.getIssueTypes);
        return RestManager.get(endpoint);
    }

    reportIssue(issueObj) {
        const endpoint = formatEndpoint(endpoints.log.reportIssue);
        return RestManager.post(endpoint, issueObj);
    }
}

export default new Log();
