import React from 'react';
import { OverviewPowertrains } from './OverviewPowertrains';
import { OverviewAssets } from './OverviewAssets';

import './style.scss';

const OverviewComponent = () => {
    return <div id='overview-condition-container' >
        <OverviewPowertrains />
        <OverviewAssets />
    </div>;
};

export default OverviewComponent;
