import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { ScrollToElementWrapper } from 'sharedComponents/ScrollToElementWrapper';
import PowertrainListItem from './PowertrainListItem';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'sharedHooks/useQuery';
import { scrollToViewDelayInAssetDetails } from '../../../constants';


const PowertrainList = ({ powertrains, onPowertrainClick, onAssetClick }) => {
    const { t: translate } = useTranslation();
    const selectedPowertrainID = useSelector(({ powertrainAssetDetails }) => powertrainAssetDetails.selectedPowertrain?.systemID);

    const { powertrainId: powertrainIdFromQuery } = useQueryParams();

    return (
        <div className='powertrain-list'>
            {powertrains.length ? map(powertrains, (powertrain, key) =>
                powertrain && <ScrollToElementWrapper key={key} condition={powertrainIdFromQuery === powertrain.systemID.toString()} delayMillisecond={scrollToViewDelayInAssetDetails} ><PowertrainListItem
                    powertrain={powertrain}
                    isSelected={selectedPowertrainID === powertrain.systemID}
                    onPowertrainClick={onPowertrainClick}
                    onAssetClick={onAssetClick}
                /></ScrollToElementWrapper>) : <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.noMatchesFoundLabel')}</div>}
        </div>
    );
};

PowertrainList.propTypes = {
    powertrains: PropTypes.any,
    onPowertrainClick: PropTypes.func,
    onAssetClick: PropTypes.func
};

export default PowertrainList;

