import map from 'lodash/map';
import find from 'lodash/find';
import { pageSize } from './constants';
import { apiCallStatus } from 'helpers/constants';


export const formatGetPowertrainsRequest = (siteIds, organizationsIds = [], nameFilter, pageNumber) => {
    return {
        refreshData: true,
        pageNumber,
        pageSize,
        organizationsIds,
        siteIds,
        nameFilter
    };
};

export const addNewPageToPowertrainList = (powertrains, action) => {
    const hasMore = action.data?.powertrains && !(action.data?.powertrains?.length < pageSize);
    const data = action.loadingStatus !== apiCallStatus.LOADING &&
        action.data?.pageNumber === 1 ? [] : [...powertrains.data];

    return action.loadingStatus !== apiCallStatus.SUCCESS ? {
        ...powertrains,
        loadingStatus: action.loadingStatus
    } : {
        ...powertrains,
        loadingStatus: action.loadingStatus,
        data: action.data && [...data, ...action.data.powertrains],
        pageNumber: hasMore ? action.data?.pageNumber + 1 : action.data?.pageNumber,
        hasMore
    };
};

export const updatePowertrainById = (powertrains, action) => {
    return action.loadingStatus !== apiCallStatus.SUCCESS ? {
        ...powertrains,
        loadingStatus: action.loadingStatus
    } : {
        ...powertrains,
        loadingStatus: action.loadingStatus,
        data: map(powertrains.data, (powertrain) => {
            return powertrain.powertrainId === action.data.powertrainId ?
                action.data.powertrain : powertrain;
        }),
    };
};

export const getSiteIds = (sites) => {
    return map(sites, (site) => site.siteId);
};

export const getOrganizationIds = (organizations) => {
    return organizations?.length ?
        map(organizations, (organization) => organization.organizationID) :
        [];
};

export const getSiteById = (sites, siteId) => {
    return find(sites, (site) => site.siteId === siteId);
};
