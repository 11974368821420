import React from 'react';
import PropTypes from 'prop-types';


const IconInfoNotification = ({ width = 24, height = 24, viewBox = '0 0 24 24'}) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} fill='none'>
    <path d='M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM13 12V18H11V12H10V10H12C12.6 10 13 10.4 13 11V12ZM11.5 8C10.7 8 10 7.3 10 6.5C10 5.7 10.7 5 11.5 5C12.3 5 13 5.7 13 6.5C13 7.3 12.3 8 11.5 8Z' fill='white'/>
    </svg>;
};

IconInfoNotification.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string
};

export default IconInfoNotification;
