import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import localStorageHelper from 'helpers/localStorageHelper';
import splashScreen from '../../../img/error_splash_screen.png';
import { GeneralButton } from 'sharedComponents';
import { IconMOPortalTitle } from 'svgIcons/MotionPortalIcons';
import authHelper from 'helpers/authHelper';
import './styles.scss';


const GlobalErrorComponent = (props) => {
    const { t: translate } = useTranslation();
    const { history, model } = props;

    const navigateToHome = () => {
        model?.globalError?.statusCode === 403 && localStorageHelper.removeItemForUser('generalFilter.selectedFilters');
        history.push(model.defaultPage);
        history.go(); // trigger full page reload
    };

    if (model.globalError) {
        return <div className='error-page-container' style={{ backgroundImage: `url(${splashScreen})` }}>
            <Container className='error-content'>
                <Row>
                    <Col className='title'>
                        <IconMOPortalTitle width={450} height={80} />
                    </Col>
                </Row>
                <Row>
                    <Col className='status-code'>
                        <span>
                            {translate('ABB.Powertrain.Frontend.ptGlobalErrorStatusCodeText')}
                            {' '}
                        </span>
                        <span>{model.globalError.statusCode}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className='details'>
                        {translate('ABB.Powertrain.Frontend.ptGlobalErrorDetailsDefault')}
                    </Col>
                </Row>
                <Row>
                    <Col className='button-area'>
                        <GeneralButton type='primary' text={translate('ABB.Powertrain.Frontend.ptGlobalErrorHomeBtn')} onClick={navigateToHome} />
                        <span className='logout-text' onClick={() => authHelper.logout()} onKeyDown={() => authHelper.logout()}>
                            {translate('ABB.Powertrain.Frontend.ptGlobalErrorLogOut')}
                        </span>
                    </Col>
                </Row>
            </Container>
        </div>;
    }
    return props.children;
};

GlobalErrorComponent.propTypes = {
    children: PropTypes.any,
    history: PropTypes.shape({
        go: PropTypes.func,
        push: PropTypes.func
    }),
    model: PropTypes.shape({
        defaultPage: PropTypes.string,
        showError: PropTypes.bool,
        statusCode: PropTypes.number
    })
};

export default GlobalErrorComponent;
