import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconEllipsis = ({ width = 11, height = 20, viewBox='0 0 11 20', color = colors.mineShaft }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
	<path d='M1.91245 15.154C1.65111 15.154 1.43645 15.0747 1.26845 14.916C1.10045 14.7573 1.01645 14.552 1.01645 14.3C1.01645 14.048 1.10045 13.8427 1.26845 13.684C1.43645 13.516 1.65111 13.432 1.91245 13.432C2.17378 13.432 2.38378 13.516 2.54245 13.684C2.71045 13.8427 2.79445 14.048 2.79445 14.3C2.79445 14.5427 2.71045 14.748 2.54245 14.916C2.38378 15.0747 2.17378 15.154 1.91245 15.154ZM5.50815 15.154C5.24682 15.154 5.03215 15.0747 4.86415 14.916C4.69615 14.7573 4.61215 14.552 4.61215 14.3C4.61215 14.048 4.69615 13.8427 4.86415 13.684C5.03215 13.516 5.24682 13.432 5.50815 13.432C5.76948 13.432 5.97948 13.516 6.13815 13.684C6.30615 13.8427 6.39015 14.048 6.39015 14.3C6.39015 14.5427 6.30615 14.748 6.13815 14.916C5.97948 15.0747 5.76948 15.154 5.50815 15.154ZM9.10385 15.154C8.84252 15.154 8.62785 15.0747 8.45985 14.916C8.29185 14.7573 8.20785 14.552 8.20785 14.3C8.20785 14.048 8.29185 13.8427 8.45985 13.684C8.62785 13.516 8.84252 13.432 9.10385 13.432C9.36518 13.432 9.57518 13.516 9.73385 13.684C9.90185 13.8427 9.98585 14.048 9.98585 14.3C9.98585 14.5427 9.90185 14.748 9.73385 14.916C9.57518 15.0747 9.36518 15.154 9.10385 15.154Z' fill={color}/>
	</svg>;

};

IconEllipsis.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

export default IconEllipsis;
