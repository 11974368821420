import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { mapEnvironmentPropertiesResponse } from './interceptors';
class Api {

    getEnvironmentProperties() {
        const endpoint = formatEndpoint(endpoints.environmentProperties);

        return RestManager.get(endpoint).then(mapEnvironmentPropertiesResponse);
    }
}

export default new Api();
