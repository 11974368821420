import * as constants from './constants';
import logger from 'helpers/logger';
import { apiCallStatus } from 'helpers/constants';
import { BulkUploadApi } from 'api';
import { map, filter } from 'lodash';
import { getApiActionCreator } from 'helpers/actionHelper';


//Actions
const getBulkUploadStatusAction = getApiActionCreator(constants.GET_BULK_UPLOAD_STATUS);
const processBulkUploadAction = getApiActionCreator(constants.PROCESS_BULK_UPLOAD);

const setUploadLoadingAction = (uploading) => {
	return {
		type: constants.SET_UPLOAD_LOADING,
		uploading,
	};
};

const setFileUploadStatusAction = (file, uploadStatus) => {
	return {
		type: constants.SET_FILE_UPLOAD_STATUS,
		file,
		uploadStatus,
	};
};

const setFileBlobNameAction = (file, blobName) => {
	return {
		type: constants.SET_FILE_BLOB_NAME,
		file,
		blobName,
	};
};

const setProcessAllowedAction = (processAllowed) => {
	return {
		type: constants.SET_PROCESS_ALLOWED,
		processAllowed,
	};
};

const clearFileBoxAction = () => {
	return {
		type: constants.CLEAR_FILE_BOX,
	};
};

//Action creators
const getUploadStatus = (dispatch, asset, dateInterval) => {
	dispatch(getBulkUploadStatusAction(apiCallStatus.LOADING));
	BulkUploadApi.getBulkUploadStatus(asset, dateInterval.from, dateInterval.to)
		.then((data) => {
			logger.debug('Bulk upload status loaded successfully');
			dispatch(getBulkUploadStatusAction(apiCallStatus.SUCCESS, data));
		})
		.catch((err) => {
			logger.error(err && err.message);
			dispatch(getBulkUploadStatusAction(apiCallStatus.ERROR, null, err));
		});
};

export const processBulkUpload = (asset, files, dateInterval) => {
	return (dispatch) => {
		dispatch(processBulkUploadAction(apiCallStatus.LOADING));
		dispatch(clearFileBoxAction());
		BulkUploadApi.processBulkUpload(asset, map(files, file => {
			return {
				blobName: file.blobName,
				fileName: file.fileContent.name
			};
		})).then(() => {
			dispatch(processBulkUploadAction(apiCallStatus.SUCCESS));
			dispatch(setProcessAllowedAction(false));
			getUploadStatus(dispatch, asset, dateInterval);
		}).catch((err) => {
			if (err) {
				dispatch(processBulkUploadAction(apiCallStatus.ERROR, null, err));
				dispatch(setProcessAllowedAction(false));
				logger.error(err.message);
			}
		});
	};
};

export const uploadFiles = (asset, files, dateInterval) => {
	return (dispatch) => {
		dispatch(setUploadLoadingAction(true));
		let successfulUpload = false;

		const starterPromise = Promise.resolve(null);

		filter(files, (file) => file.uploadStatus !== constants.fileUploadStatus.SUCCESS).reduce(
			(lastPromise, file) => lastPromise.then(() => BulkUploadApi.initializeBulkUpload(asset).then((uploadLocation) => {
				dispatch(setFileBlobNameAction(file, uploadLocation.blobName));
				dispatch(setFileUploadStatusAction(file, constants.fileUploadStatus.LOADING));

				return BulkUploadApi.uploadFile(uploadLocation.url, uploadLocation.blobName, file.fileContent).then(() => {
					dispatch(setFileUploadStatusAction(file, constants.fileUploadStatus.SUCCESS));
					successfulUpload = true;
				}).catch((err) => {
					dispatch(setFileUploadStatusAction(file, constants.fileUploadStatus.FAILED));
					if (err) {
						logger.error(err.message);
					}
				});

			}).catch((err) => {
				if (err) {
					logger.error(err.message);
				}
			})),
			starterPromise
		).then(() => {
			dispatch(setUploadLoadingAction(false));
			dispatch(setProcessAllowedAction(successfulUpload));
			getUploadStatus(dispatch, asset, dateInterval);
		});
	};
};
