import React from 'react';
import PropTypes from 'prop-types';


export const FFTTooltipPointValueRow = ({ point, magnitudeUnitSymbol }) => {
    const pointValue = point.y;
    return (
        <>
            {
                point.series.userOptions.component &&
                <div><b>{point.series.userOptions.component}</b></div>
            }
            <span className='symbol' style={{ backgroundColor: point.color, opacity: '1' }} />
            <span>{` ${point.series.name}: ${pointValue} ${magnitudeUnitSymbol}`}</span>
        </>
    );
};

FFTTooltipPointValueRow.propTypes = {
    point: PropTypes.shape({
        color: PropTypes.string,
        series: PropTypes.shape({
            name: PropTypes.string,
            userOptions: PropTypes.shape({
                allSignal: PropTypes.bool,
                component: PropTypes.string
            })
        }),
        y: PropTypes.number
    }),
};
