import { createSelector } from 'reselect';
import map from 'lodash/map';
import { getLoadingSummary } from 'helpers/loadingStatusHelper';
import { formatChartData } from '../../../commonTrendHelpers';
import { apiCallStatus } from 'helpers/constants';


const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const getMultiChartPreselection = ({ operationalParametersV2 }) => { // in the future this will be received from BE
    return operationalParametersV2.preselectedKPIs?.multi?.trend || [];
};

const getAvailableKPIs = ({ operationalParametersV2 }) => {
    return operationalParametersV2.availableKPIs?.trend?.data;
};

const getTrendKPILoadingStatus = ({ operationalParametersV2 }) => {
    return operationalParametersV2.availableKPIs?.trend.loadingStatus;
};

const getLoadingStatusForCharts = ({ operationalParametersV2 }) => {
    const loadingStatuses = map(operationalParametersV2.charts?.multi?.trend, (trendChart) => {
        return trendChart.loadingStatus;
    });
    return getLoadingSummary(loadingStatuses) || apiCallStatus.LOADING;
};

const getMultiTrendData = ({ operationalParametersV2 }) => {
    const multiChartData = formatChartData(
        operationalParametersV2.charts?.multi?.trend || [],
        operationalParametersV2.availableKPIs?.trend?.data
    );
    return multiChartData;
};

const getUserLimits = ({ detailedInformationHeader }) => {
    return detailedInformationHeader.userLimits?.data?.visualAids;
};

const getUserLimitsLoadingStatus = ({ detailedInformationHeader }) => {
    return detailedInformationHeader.userLimits?.loadingStatus;
};

export const getModel = createSelector([
    getDateSelection,
    getAvailableKPIs,
    getMultiChartPreselection,
    getTrendKPILoadingStatus,
    getMultiTrendData,
    getUserLimits,
    getUserLimitsLoadingStatus,
    getLoadingStatusForCharts
],
    (
        dateSelection,
        availableKPIs,
        multiChartPreselection,
        trendKPILoadingStatus,
        multiChartData,
        userLimits,
        userLimitsLoadingStatus,
        loadingStatusForCharts
    ) => {
        return {
            dateSelection,
            availableKPIs,
            multiChartPreselection,
            trendKPILoadingStatus,
            multiChartData,
            userLimits,
            userLimitsLoadingStatus,
            loadingStatusForCharts
        };
    });


