import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';
import { EmailSubscriptionsApi, ComponentApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';

const getEmailAlertSettingsAction = getApiActionCreator(actionTypes.GET_EMAIL_ALERT_SETTINGS);
const setEmailAlertSettingsAction = getApiActionCreator(actionTypes.SET_EMAIL_ALERT_SETTINGS);
const loadCapabilitiesForAssetsAction = getApiActionCreator(actionTypes.LOAD_CAPABILITIES);


export const loadCapabilitiesForAssets = (assets) => {
	return (dispatch) => {
		dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.LOADING, []));
		ComponentApi.getAssetsCapabilities(assets).then((capabilities) => {
			dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.SUCCESS, capabilities));
		}).catch((err) => {
			dispatch(loadCapabilitiesForAssetsAction(apiCallStatus.ERROR, [], err));
		});
	};
};

export const getEmailAlertSettings = (assets) => {
	return dispatch => {
		dispatch(getEmailAlertSettingsAction(apiCallStatus.LOADING, null));
		EmailSubscriptionsApi.getEmailSubscriptions(assets).then((settings) => {
			dispatch(getEmailAlertSettingsAction(apiCallStatus.SUCCESS, settings));
		}).catch((err) => {
			dispatch(getEmailAlertSettingsAction(apiCallStatus.ERROR, null, err));
		});
	};
};

export const setEmailAlertSettings = (settings) => {
	return dispatch => {
		dispatch(setEmailAlertSettingsAction(apiCallStatus.LOADING, settings));
		EmailSubscriptionsApi.setEmailSubscriptions(settings).then((settings) => {
			dispatch(setEmailAlertSettingsAction(apiCallStatus.SUCCESS, settings));
			dispatch(getEmailAlertSettingsAction(apiCallStatus.SUCCESS, settings));
		}).catch((err) => {
			dispatch(setEmailAlertSettingsAction(apiCallStatus.ERROR, settings, err));
		});
	};
};

export const clearStore = () => {
	return (dispatch) => {
		dispatch({ type: actionTypes.CLEAR_STORE});
	};
};
