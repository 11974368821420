import { map } from 'lodash';
import { contentTabs, MAX_LOGGER_SIGNALS } from './constants';
import { setColor } from '../../../commonTrendHelpers';
import { LoggerVisualisation } from './LoggerVisualisation';
import { LoggerDownload } from './LoggerDownload';
import { SelfFaultEvaluation } from './SelfFaultEvaluation';
import { TrendSignal } from './TrendSignal';

export const getToggleOptions = (translate, capability) => {
    return [
        ...capability.hasEventSelfFaultEvaluationCapability() ?
            [{
                label: translate(contentTabs.SELFEVALUATE),
                value: contentTabs.SELFEVALUATE,
                component: SelfFaultEvaluation
            }] : [],
        ...capability.hasEventLoggerVisualizationCapability() ?
            [{
                label: translate(contentTabs.LOGGERVISUALISATION),
                value: contentTabs.LOGGERVISUALISATION,
                component: LoggerVisualisation
            }] : [],
        ...capability.hasEventTrendSignalCapability() ?
            [{
                label: translate(contentTabs.TRENDSIGNAL),
                value: contentTabs.TRENDSIGNAL,
                component: TrendSignal
            }] : [],
        ...capability.hasEventLoggerDownloadCapability() ?
            [{
                label: translate(contentTabs.LOGGERDOWNLOAD),
                value: contentTabs.LOGGERDOWNLOAD,
                component: LoggerDownload
            }] : [],
    ];
};

export const addIDtoLoggerSignals = (loggers) => {
    map(loggers, (logger) => {
        map(logger.signals, (signal, i) => {
            logger.signals[i] = { id: `${i}_${signal}`, value: signal };
        });
    });
    return loggers;
};

export const removeSelectedIndex = (array, index) => {
    array.splice(index, 1);
    return array;
};

export const addSelectedLoggerSignal = (selectedLoggerSignals, currentSelectedLoggerSignals) => {
    const newSelectedLoggerSignals = structuredClone(selectedLoggerSignals);
    newSelectedLoggerSignals.splice(newSelectedLoggerSignals.length, 0, ...currentSelectedLoggerSignals);

    if (newSelectedLoggerSignals.length > MAX_LOGGER_SIGNALS) {
        newSelectedLoggerSignals.shift();
    }
    setColor(newSelectedLoggerSignals);

    return newSelectedLoggerSignals;
};
