import { translate } from 'helpers/translateHelper';
import { useHistory } from 'react-router';
import { routes } from 'routes';


export const getContextMenuConfigForAsset = (assetId) => {
    const history = useHistory();

    return {
        id: `condition-monitoring-card-${assetId}`,
        options: [
            {
                id: `AssetDetails-${assetId}`,
                title: translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetDetailsLabel'),
                onOptionSelect: () => history.push({ pathname: routes.AssetDetails, search: new URLSearchParams({ assetId }).toString() })
            },
            {
                id: `CrossAssetDetails-${assetId}`,
                title: translate('ABB.Powertrain.Frontend.condMonitoringOverviewCrossAssetLabel'),
                onOptionSelect: () => history.push({ pathname: routes.CrossKpi, search: new URLSearchParams({ assetId }).toString() })
            }
        ]
    };
};


