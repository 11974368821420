import React from 'react';

import { toLocaleDateTimeString } from 'helpers/dateHelper';
import { IconDownload } from 'svgIcons/MotionPortalIcons';

const exportParametersIcon = (id, onDownloadFile) => {

    const handleDownload = () => {
        onDownloadFile(id);
    };

    return <div className='parameter-backup-download-button' onClick={handleDownload}>
        <IconDownload
            width={16}
            height={16}
        />
    </div>;
};

export const getColumns = (translate, onDownloadFile) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.parameterBackupFile'),
            dataIndex: 'timestamp',
            isSorting: true,
            width: 480,
            render: (timestamp) => toLocaleDateTimeString(new Date(timestamp))
        },
        {
            title: translate('ABB.Powertrain.Frontend.parameterBackupActions'),
            dataIndex: 'action',
            render: (_, item) => exportParametersIcon(item.id, onDownloadFile)
        }
    ];
};
