import { getDefaultPageRoute } from 'helpers/redirect';
import { createSelector } from 'reselect';

const getEnvironmentProperties = ({ portal }) => {
    return portal.environmentProperties.data;
};

const getLoadingStatus = ({ portal }) => {
    return portal.environmentProperties.loadingStatus;
};

const getDefaultPage = () => {
    return getDefaultPageRoute();
};

const getUserData = ({ overview }) => {
    return overview.user.data;
};

export const getModel = createSelector([
    getEnvironmentProperties,
    getLoadingStatus,
    getDefaultPage,
    getUserData
], (
    environmentProperties,
    loadingStatus,
    defaultPage,
    userData,
) => {

    return {
        environmentProperties,
        loadingStatus,
        defaultPage,
        userData,
    };
});
