
import React from 'react';
import PropTypes from 'prop-types';


const IconFilterButton = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'white' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M3 2.5L8.824 8L3 13.5L4.588 15L12 8L4.588 0.999999L3 2.5Z' fill={color} />
	</svg>;
};

IconFilterButton.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	viewBox: PropTypes.string
};
export default IconFilterButton;
