import React from 'react';
import { ChartView } from './ChartView';
import { CrossAssetAnalysisSidepanel } from './CrossAssetAnalysisSidepanel';

import './style.scss';

const CrossAssetAnalysisComponent = () => {
    return (
        <div className='cross-asset-analysis-container'>
            <CrossAssetAnalysisSidepanel />
            <ChartView />
        </div>
    );
};

export default CrossAssetAnalysisComponent;
