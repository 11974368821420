import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCapability } from 'sharedHooks';
import { filterCMDAssets, getFormattedAsset } from './helpers';
import { EventLogs } from './EventLogs';
import { EventLogFilter } from './EventLogFilter';
import { EventDetailsSS } from './EventDetails/EventDetailsSS';
import { EventDetailsCMD } from './EventDetails/EventDetailsCMD';


const EventLogComponent = (props) => {

    const capability = useCapability();
    const isSmartSensor = capability.isSmartSensor();

    useEffect(() => {
        props.model.generalFilteredAssets && !isSmartSensor &&
            props.actions.loadCapabilitiesForAssets(filterCMDAssets(props.model.generalFilteredAssets));
    }, [props.model.generalFilteredAssets]);

    useEffect(() => {
        props.actions.setSelectedAssets([getFormattedAsset(props.model.selectedAsset)]);
    }, [props.model.selectedAsset]);

    const renderEventDetails = () => {
        return isSmartSensor ?
            <EventDetailsSS
                {...props}
                setSelectedEvent={props.actions.setSelectedEvent}
            />
            :
            <EventDetailsCMD
                {...props}
                setSelectedEvent={props.actions.setSelectedEvent}
            />;
    };

    return (
        <div className='event-log-content'>
            {!props.model.selectedEvent ?
                <>
                    <EventLogFilter {...props}
                        isSS={isSmartSensor}
                        setSelectedAssets={props.actions.setSelectedAssets}
                        setIsExapnded={props.actions.setIsExapnded}
                    />
                    <EventLogs
                        {...props}
                        isSS={isSmartSensor}
                        setSelectedEvent={props.actions.setSelectedEvent}
                        setIsExapnded={props.actions.setIsExapnded}
                        isExpanded={props.model.isExpanded}
                        assistanceCapability={
                            capability.hasAssistancePageCapability()
                        }
                    />
                </>
                : renderEventDetails()
            }
        </div>
    );
};

EventLogComponent.propTypes = {
    actions: PropTypes.shape({
        setSelectedEvent: PropTypes.func,
        loadCapabilitiesForAssets: PropTypes.func,
        setSelectedAssets: PropTypes.func
    }),
    asset: PropTypes.any,
    model: PropTypes.shape({
        selectedAsset: PropTypes.object,
        selectedEvent: PropTypes.object,
        generalFilteredAssets: PropTypes.array
    })
};


export default EventLogComponent;
