import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';


const PowertrainListItem = ({
    setSelectedPowertrain,
    powertrain,
    isSelected
}) => {
    const { name } = powertrain;

    const handlePowertrainSelection = () => {
        const selection = isSelected ? null : powertrain;
        setSelectedPowertrain(selection);
    };

    return <div
        onClick={handlePowertrainSelection}
        className={`powertrainList_item ${isSelected ? 'selected' : ''}`} >
        <span className='powertrainList_item_label' >{name}</span>
    </div>;
};

PowertrainListItem.propTypes = {
    setSelectedPowertrain: PropTypes.func,
    powertrain: PropTypes.any,
    isSelected: PropTypes.bool,
};

export default PowertrainListItem;
