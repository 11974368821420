import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OperationalParametersComponent from './OperationalParametersComponent';
import { getModel } from './selectors';
import * as actions from './actions';
import {
	setOffset,
	setParamEditState,
	setSelectedMeasurementTypeId
} from '../actions';


const OperationalParametersContainer = (props) => {
	return <OperationalParametersComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				...actions,
				setOffset,
				setParamEditState,
				setSelectedMeasurementTypeId
			}, dispatch)
	};
};

const OperationalParametersContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(OperationalParametersContainer);

export default OperationalParametersContainerConn;
