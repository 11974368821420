import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconEnvelope = ({ width = 28, height = 18, viewBox = '0 0 28 18', color = colors.mineShaft }) => {

    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H26C26.5304 18 27.0391 17.7893 27.4142 17.4142C27.7893 17.0391 28 16.5304 28 16V0H0ZM26 2V4.31L14 8.93L2 4.31V2H26ZM2 16V6.46L14 11.07L26 6.46V16H2Z' fill={color} />
    </svg>;
};

IconEnvelope.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
};

export default IconEnvelope;
