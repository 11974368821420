import React from 'react';
import PropTypes from 'prop-types';

const IconCirclePlus = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'none' }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg' role='add-icon'>
        <path d='M8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1V1ZM12 9H9V12H7V9H4V7H7V4H9V7H12V9Z' fill='#0F0F0F' />
    </svg>;


};

IconCirclePlus.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

export default IconCirclePlus;
