import { assetObjectToComponentRequest } from '../helpers';
import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';

class DailyAgingStress {

    static getDailyAgingStress(asset, from, to) {
        const endpoint = formatEndpoint(endpoints.dailyAgingStress.getDailyAgingStress);
        return RestManager.post(endpoint, { ...assetObjectToComponentRequest(asset), from, to });
    }
}

export default DailyAgingStress;
