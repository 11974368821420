import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';

import NotificationStatusBar from './NotificationStatusBar';
import './style.scss';

// REVERT TO THIS while adding the blue and yellow banner
// const NotificationStatusBarContainer = ({ model, actions }) => {
// 	return model.contractInfo ? <NotificationStatusBar model={model} actions={actions} /> : null;
// };

const NotificationStatusBarContainer = ({ model, actions }) => {
	return <NotificationStatusBar model={model} actions={actions} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const NotificationStatusBarContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationStatusBarContainer);

export default NotificationStatusBarContainerConn;
