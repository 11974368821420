import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ScrollableList
} from 'sharedComponents';
import {
    IconDataLogger,
    IconWarningType
} from 'svgIcons/MotionPortalIcons';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import colors from 'theme/_colors.scss';
import './style.scss';


const ScrollableListComponent = ({
    noDataLabel,
    className,
    loadingStatus,
    hasMore,
    pageSize,
    loadMore,
    renderList
}) => {
    const { t: translate } = useTranslation();

    const renderInfoTooltip = (description) => {
        return <InfoButtonWithOnlineHelp infoText={description} />;
    };

    const renderHeader = () => {
        return <div className='asset-list-header'>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNameColumnTitle')}
            </div>
            <div className='header-item'>
                <div className='column-icon'>
                    <IconWarningType color={colors.black} />
                </div>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewThresholdColumnTitle')}
                {renderInfoTooltip(translate('ABB.Powertrain.Frontend.thresholdColumnInfoText'))}
            </div>
            <div className='header-item'>
                <div className='column-icon'>
                    <IconDataLogger heigth='16px' width='16px' />
                </div>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewTrendColumnTitle')}
                {renderInfoTooltip(translate('ABB.Powertrain.Frontend.trendColumnInfoText'))}
            </div>
        </div>;
    };

    return <div className={`overview-scrollable-list ${className}`}>
        {renderHeader()}
        <ScrollableList
            emptyLabel={noDataLabel}
            withLoading
            loadingStatus={loadingStatus}
            hasMore={hasMore}
            pageSize={pageSize}
            loadMore={() => loadMore()}
        >
            {renderList()}
        </ScrollableList>
    </div>;
};

ScrollableListComponent.propTypes = {
    noDataLabel: PropTypes.string,
    className: PropTypes.string,
    loadingStatus: PropTypes.string,
    hasMore: PropTypes.bool,
    pageSize: PropTypes.number,
    loadMore: PropTypes.func,
    renderList: PropTypes.func
};

export default ScrollableListComponent;
