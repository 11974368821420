import filter from 'lodash/filter';
import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
	labels: {
		data: [],
		loadingStatus: null
	},
	suggestedLabels: {
		data: [],
		loadingStatus: null
	},
	addLabels: {
		loadingStatus: null
	},
	removeLabels: {
		loadingStatus: null
	},
	nameplatePictures: {
		data: [],
		loadingStatus: null
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.LOAD_ASSET_LABELS:
			return {
				...state,
				labels: { ...state.labels, ...action },
			};
		case actionTypes.LOAD_SUGGESTED_ASSET_LABELS:
			return {
				...state,
				suggestedLabels: { ...state.suggestedLabels, ...action },
			};
		case actionTypes.ADD_LABEL_TO_ASSET: {
			const labels = action.loadingStatus === apiCallStatus.SUCCESS ?
				{ ...state.labels, data: [...state.labels.data, action.data] }
				: state.labels;
			return {
				...state,
				labels,
				addLabels: { ...state.addLabels, ...action }
			};
		}
		case actionTypes.REMOVE_LABEL_FROM_ASSET: {
			const labels = action.loadingStatus === apiCallStatus.SUCCESS ?
				{ ...state.labels, data: filter(state.labels.data, (label) => label.tagId !== action.data) }
				: state.labels;
			return {
				...state,
				labels,
				removeLabels: { ...state.removeLabels, ...action }
			};
		}
		case actionTypes.GET_ASSET_PICTURES:
			return {
				...state,
				nameplatePictures: { ...state.nameplatePictures, ...action },
			};
		case actionTypes.CLEAR_ASSET_PICTURES:
			return {
				...state,
				nameplatePictures: initialState.nameplatePictures
			};
		default:
			return state;
	}
}
