
import { apiCallStatus } from '../../../../../helpers/constants';
import * as actionTypes from './constants';

const initialState = {
	changedParameterFileList: {
		data: [],
		loadingStatus: apiCallStatus.LOADING
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CHANGED_PARAMETER_LIST:
			return {
				...state,
				changedParameterFileList: { ...state.changedParameterFileList, ...action }
			};
		default:
			return state;
	}
};

export default reducer;

