import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MuteAssetComponent from './MuteAssetComponent';
import { getModel } from './selectors';
import * as actions from './actions';


const MuteAssetContainer = (props) => {

    useEffect(() => {
        if (props.model.selectedAsset?.id) {
            props.actions.getAssetMuteSettings(props.model.selectedAsset.id);
        }
    }, [props.model.selectedAsset]);

    useEffect(() => () => {
        props.actions.clearStore();
    }, []);

    return <MuteAssetComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return { model };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

MuteAssetContainer.propTypes = {
    model: PropTypes.shape({
        selectedAsset: PropTypes.object
    }),
    actions: PropTypes.shape({
        getAssetMuteSettings: PropTypes.func,
        clearStore: PropTypes.func
    })
};

const MuteAssetContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(MuteAssetContainer);

export default MuteAssetContainerConn;
