import React from 'react';
import PropTypes from 'prop-types';

import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import Option from './Option';

import './style.scss';


const CountrySelector = (props) => {

	const {
		...otherProps
	} = props;

	return <GeneralSelect
		{...otherProps}
		getOptionLabel={option => option.countryDisplayName}
		getOptionValue={option => option.countryCode}
		components={{ Option }}
		className='general-filter-selector-container country-selector'
		blurInputOnSelect={false}
		isMulti
		isDisabled={props.isLoading || !props.sitesChecked}
		closeMenuOnSelect={false}
		hideSelectedOptions={false}
	/>;
};

CountrySelector.propTypes = {
	isLoading: PropTypes.bool,
	sitesChecked: PropTypes.bool
};

export default CountrySelector;
