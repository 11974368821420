import { createSelector } from 'reselect';

const getNotifications = ({ notificationStatusBar }) => {
	return notificationStatusBar.data;
};

const selectContractInfo = ({ powertrainAssetDetails }) => {
	if (powertrainAssetDetails?.selectedAssetCapability?.data?.componentID === powertrainAssetDetails?.selectedAsset?.componentID) {
		return powertrainAssetDetails.selectedAssetCapability?.data?.contractInfo?.message ?
			powertrainAssetDetails.selectedAssetCapability.data.contractInfo : null;
	}
	else {
		return null;
	}
};

const selectedAssetSubscriptionInfo = ({ powertrainTools }) => {
	return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos[0]?.subscriptionInfos[0]?.type;
};

export const getModel = createSelector([
	getNotifications,
	selectContractInfo,
	selectedAssetSubscriptionInfo
], (
	notification,
	contractInfo,
	assetSubscriptionInfo
) => {
	return {
		notification,
		contractInfo,
		assetSubscriptionInfo
	};
});
