import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';
import SelfEvaluateComponent from './SelfFaultEvaluationComponent';

const SelfEvaluateContainer = (props) => {
	return (
		<SelfEvaluateComponent {...props} />
	);
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const SelfEvaluateContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(SelfEvaluateContainer);

export default SelfEvaluateContainerConn;
