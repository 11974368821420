import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { apiCallStatus } from 'helpers/constants';
import { LoadingStatusWrapper } from 'sharedComponents';
import { TrendChart } from './TrendChart';
import { TrendKPIList } from './TrendKPIList';
import { opButtons } from '../../constants';
import { tabIDs } from '../../../constants';
import useReloadTab from '../../useReloadTab';


const TrendComponent = ({
    model: {
        activeDetailedInformationTab,
        dateSelection,
        measurementExportLoadingStatus,
        selectedTrendKPIs,
        trendKPIs,
        trendKPIsLoadingStatus,
        trendDataLoadingStatus,
        userLimitsLoadingStatus
    },
    actions: {
        getTrendKPIsForAsset,
        reloadAllSelectedTrendKpiData
    },
    activeTab,
    asset,
}) => {

    const refreshData = () => {
        if (trendKPIsLoadingStatus === apiCallStatus.SUCCESS &&
            activeTab === opButtons.trends &&
            activeDetailedInformationTab === tabIDs.operationalParameters &&
            trendKPIs.length &&
            selectedTrendKPIs.length &&
            dateSelection.from &&
            dateSelection.to) {
            reloadAllSelectedTrendKpiData(selectedTrendKPIs);
        }
    };

    useReloadTab(refreshData,
        opButtons.trends,
        [trendKPIsLoadingStatus]);

    useEffect(() => {
        if (trendKPIsLoadingStatus !== apiCallStatus.SUCCESS) {
            getTrendKPIsForAsset(asset);
        }
    }, []);

    return <>
        <LoadingStatusWrapper
            useCustomAnchor
            transparent
            indicatorConfig={{ placement: 'sticky' }}
            loadingStatus={[
                trendKPIsLoadingStatus,
                measurementExportLoadingStatus,
                trendDataLoadingStatus,
                userLimitsLoadingStatus
            ]}
        >
            <TrendChart asset={asset} />
            <TrendKPIList asset={asset} />
        </LoadingStatusWrapper>
    </>;
};

TrendComponent.propTypes = {
    actions: PropTypes.shape({
        getTrendKPIsForAsset: PropTypes.func
    }),
    model: PropTypes.shape({
        activeDetailedInformationTab: PropTypes.string,
        dateSelection: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string
        }),
        trendKPIs: PropTypes.array,
        trendKPIsLoadingStatus: PropTypes.string,
        measurementExportLoadingStatus: PropTypes.string,
        trendDataLoadingStatus: PropTypes.string,
        userLimitsLoadingStatus: PropTypes.string,
    }),
    activeTab: PropTypes.string,
    asset: PropTypes.object,
};

export default TrendComponent;
