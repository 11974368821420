import React from 'react';
import { isNil } from 'lodash';
import {
    IconEnvironment,
    IconAvailability,
    IconReliability,
    IconStress
} from 'svgIcons/MotionPortalIcons';
import { StateID } from 'helpers/constants';

const ConditionIconsRow = (statuses) => {
    const renderWhenValid = (status, IconComponent) => {
        return isNil(status) || status === StateID.NOT_APPLICABLE ?
            null :
            <span className='condition-icon'><IconComponent condition={status} /></span>;
    };

    return (
        <div className='condition-icon__row'>
            {renderWhenValid(statuses.Availability, IconAvailability)}
            {renderWhenValid(statuses.Environment, IconEnvironment)}
            {renderWhenValid(statuses.Reliability, IconReliability)}
            {renderWhenValid(statuses.Stress, IconStress)}
        </div>
    );
};

export default ConditionIconsRow;
