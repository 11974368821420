import { map, escapeRegExp, filter } from 'lodash';
import { dataSources } from 'helpers/constants';
import {
    eventStatus,
    eventClosingReason,
    causeOfEvents,
    EventTypeIDs,
    EventTypeAssetApplicability
} from './constants';


export const getEventStatusOptions = (translate) => {
    return map(eventStatus, (id, key) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${key}`) };
    });
};

export const getDefaultEventStatus = (status, translate) => {
    const defaultKey = Object.keys(eventStatus).find(key => eventStatus[key] === status);
    return { id: status, title: translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${defaultKey}`) };
};

const filterBySearchTerm = (eventName, searchTerm) => {
    const searchRe = new RegExp(escapeRegExp(searchTerm), 'i');
    return eventName.search(searchRe) !== -1;
};

const isEventFilterMatch = (filters, ev) => {
    const filteredEventTypes = filters.eventTypes.filter(item =>
        ev.dataSource === EventTypeAssetApplicability.SMART_SENSOR ?
            item !== EventTypeIDs.FAULT : item !== EventTypeIDs.ALARM);

    const filterByHasCommentsMatch = filters.withCommentsOnly ? filters.withCommentsOnly === ev.hasComment : true;
    const filterHasDataLoggersMatch = filters.withDataLoggersOnly ? filters.withDataLoggersOnly === ev.hasDataLoggers || filters.withDataLoggersOnly === ev.hasBinaryLogger : true;
    const filterByDuringOperationMatch = filters.occuredDuringOperationOnly ? filters.occuredDuringOperationOnly === ev.duringOperation : true;
    const filterEventTypesMatch = filteredEventTypes?.length ? filteredEventTypes.indexOf(ev.typeName) !== -1 : true;
    const filterCauseOfEventMatch = filters.causeOfEvent !== causeOfEvents.All ? filters.causeOfEvent === ev.causeOfEvent : true;
    const filterStatusOfSSEventMatch = filters.eventStatus !== eventStatus.ALL ? filters.eventStatus === ev.status : true;
    const filterEventNameMatch = filters.eventName ? filterBySearchTerm(ev.eventName, filters.eventName) : true;
    return filterEventTypesMatch &&
        filterByHasCommentsMatch &&
        filterHasDataLoggersMatch &&
        filterByDuringOperationMatch &&
        filterCauseOfEventMatch &&
        filterStatusOfSSEventMatch &&
        filterEventNameMatch;
};

export const filterEventLogs = (eventLogs, filters) => {
    const events = eventLogs?.events ? eventLogs.events : [];
    return events.filter(e => isEventFilterMatch(filters, e));
};

export const getClosingReasons = (translate) => {
    return [
        {
            id: eventClosingReason.Dismiss,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.Dismiss}`),
        },
        {
            id: eventClosingReason.FalseAlarm,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.FalseAlarm}`),
        },
        {
            id: eventClosingReason.Corrected,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.Corrected}`),
        }
    ];
};

export const updateEventLogs = (eventLogs, newEvent) => {
    return map(eventLogs, (eventLog) => {
        if (eventLog.eventId === newEvent.eventId) {
            eventLog.closingReason = newEvent.closingReason;
            eventLog.status = eventStatus.CLOSED;
            return eventLog;
        }
        else { return eventLog; }
    });
};


export const filterCMDAssets = (assets) => {
    return assets?.length &&
        filter(assets, asset => asset?.componentType?.dataSourceType === dataSources.CMD4);
};

export const getFormattedAsset = (asset) => {
    return {
        value: asset.componentID,
        label: asset.componentName,
        isDisabled: false,
        componentType: asset.componentType,
        componentID: asset.componentID
    };
};
