import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { routes } from 'routes';
import { OverviewPieChart } from '../OverviewPieChart';
import { InfoButtonWithOnlineHelp, InfoMessage } from 'sharedComponents';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';
import { formatChartData } from './helpers';
import { filterTabs } from 'helpers/constants';

import './style.scss';

const OverviewPowertrainsComponent = (props) => {
    const { t: translate } = useTranslation();

    const powertrains = props.model.powertrains;
    const history = useHistory();

    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        Array.isArray(powertrains) && setChartData(formatChartData(powertrains));
    }, [powertrains]);

    return <div id='overview-condition-powertrains'>
        <LoadingStatusWrapper loadingStatus={[props.model.powertrainsLoadingStatus, ...props.model.generalFilterLoadingStatuses]}>
            {chartData?.length ?
                <><div className='powertrain-condition-title'>
                    {translate('ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionTitleText')}
                    <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionInfoText')} />
                </div>
                    <OverviewPieChart chartData={chartData}
                        id='pie-chart-powertrains'
                        titleText={translate('ABB.Powertrain.Frontend.powertrainsLabel')}
                        chartClickHandler={(e) => history.push(routes.PowertrainAssetsOverview, { conditionStatus: e.status, tab: filterTabs.POWERTRAINS })}
                        legendClickHandler={(e) => history.push(routes.PowertrainAssetsOverview, { conditionStatus: e.status, tab: filterTabs.POWERTRAINS })}
                    />
                </> :
                <div className='no-powertrains-data'>
                    <InfoMessage
                        title='ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionNoPowertrainsTextTop'
                        text='ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionNoPowertrainsTextBottom'
                    />
                </div>}
        </ LoadingStatusWrapper>
    </div>;
};

OverviewPowertrainsComponent.propTypes = {
    model: PropTypes.shape({
        powertrains: PropTypes.array,
        generalFilterLoadingStatuses: PropTypes.array,
        powertrainsLoadingStatus: PropTypes.string
    })
};

export default OverviewPowertrainsComponent;
