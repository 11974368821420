import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useCapability } from 'sharedHooks';

import { Row } from 'react-bootstrap';
import ParameterSection from './Sections/ParameterSection';
import './style.scss';


const TrendKPIListComponent = (props) => {
    const { model, actions } = props;

    const capability = useCapability();

    useEffect(() => {
        if (capability.isSmartSensor()) {
            actions.getHealthStatuses(props.asset);
        }
    }, [capability]);

    return (
        <div className='signal-block'>
            {model.trendKPIsList.length ?
                <Row className='trend-kpi-list measurement-list-container'>
                    {
                        map(model.trendKPIsList, (kpiSection, kpiIndex) => {
                            return <ParameterSection
                                key={kpiIndex}
                                section={kpiSection}
                                capability={capability}
                                selectedTrendKPIs={model.selectedTrendKPIs} />;
                        })
                    }
                </Row> : null}
        </div>
    );
};

TrendKPIListComponent.propTypes = {
    actions: PropTypes.shape({
        getTrendKPIsForAsset: PropTypes.func
    }),
    asset: PropTypes.object,
    model: PropTypes.shape({
        trendKPIsList: PropTypes.array
    })
};

export default TrendKPIListComponent;
