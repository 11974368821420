import React, { useState, useEffect } from 'react';
import Tippy from '@tippy.js/react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import IconInfo from 'svgIcons/MotionPortalIcons/IconInfo';
import {
    formatSensorDetailsData,
    formatGatewayInfo
} from './helpers';
import colors from 'theme/_colors.scss';

const SensorDetailsList = ({ sensorProperties, gatewayInfo, sensorTypeId,
    isFirmwareUpdateEnforced }) => {

    const { t: translate } = useTranslation();
    const [sensorDetails, setSensorDetails] = useState([]);

    useEffect(() => {
        setSensorDetails(formatSensorDetailsData(sensorProperties,
            isFirmwareUpdateEnforced, translate));
    }, [sensorProperties]);

    useEffect(() => {
        setSensorDetails(prevState => [...prevState, ...formatGatewayInfo(gatewayInfo,
            sensorTypeId, translate)]);
    }, [gatewayInfo]);

    return map(sensorDetails, (el, index) => {
        return el ?
            <div key={index} className='sensor-properties-list-item'>
                <div className='data-label'>{el.label ? el.label : el.name}</div>
                <div className='data-value'>{el.value}<div>{el.isFirmwareUpdateEnforced ?
                    <div className='error-message-firmware-update'>
                        <Tippy
                            content={translate('ABB.Powertrain.Frontend.sensorDetailsFirmwareUpdateDescriptionText')}
                            interactive={true}
                            interactiveBorder={10}
                            animation='scale'
                            theme='light-border'
                            trigger='click'
                            placement='bottom'
                        >
                            <div className='info-icon'>
                                <IconInfo width={'14px'} height={'14px'} color={colors.torchRed} />
                            </div>
                        </Tippy>
                        <div className='error-message-text'>{translate('ABB.Powertrain.Frontend.sensorDetailsFirmwareUpdateText')}</div>
                    </div> : null}</div></div>
            </div> : null;
    });
};

export default SensorDetailsList;
