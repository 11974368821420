import { allAssetTypes, filterTabs, StateID } from 'helpers/constants';
import { actionTypes } from './constants';


const initialState = {
    activeTab: filterTabs.POWERTRAINS,
    conditionStatusFilter: {
        [StateID.POOR]: false,
        [StateID.TOLERABLE]: false,
        [StateID.GOOD]: false,
        [StateID.UNKNOWN]: false,
    },
    assetTypeFilter: allAssetTypes,
    searchboxFilter: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...initialState,
                activeTab: action.activeTab
            };
        }
        case actionTypes.TOGGLE_CONDITION_STATUS_FILTER: {
            return {
                ...state,
                conditionStatusFilter: {
                    ...state.conditionStatusFilter,
                    [action.conditionStatusID]: !state.conditionStatusFilter[action.conditionStatusID]
                }
            };
        }
        case actionTypes.SET_ASSET_TYPES_FILTER: {
            return {
                ...state,
                assetTypeFilter: action.assetTypeFilter
            };
        }
        case actionTypes.SET_SEARCHBOX_FILTER: {
            return {
                ...state,
                searchboxFilter: action.searchboxFilter
            };
        }
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};
