import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconUnits = ({ width = '24', height = '24', viewBox = '0 0 24 24', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 1L2 5V18L12 23L22 18V5L12 1ZM18.81 5.88L12 8.91L5.19 5.91L12 3.15L18.81 5.88ZM4 7.54L11 10.65V20.26L4 16.76V7.54ZM13
        20.26V10.65L20 7.54V16.76L13 20.26Z' fill={color} />
    </svg>;
};

IconUnits.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconUnits;
