import { useState, useCallback, useEffect } from 'react';

const getSize = (element) => {
    if (!element) {
        return {
            width: 0,
            height: 0
        };
    }

    return {
        width: element.scrollHeight,
        height: element.scrollHeight
    };
};

const useContentSize = (ref) => {
    const [size, setSize] = useState(getSize(ref ? ref.current : {}));

    const handleResize = useCallback(
        (elements) => {
            if (elements) {
                const el = elements[0].target;
                setSize(getSize(el));
            }
        },
        [ref]
    );

    useEffect(
        () => {
            if (!ref.current) { return; }
            getSize(ref);
            const resizeObserver = new ResizeObserver((elements) => {
                handleResize(elements);
            });
            resizeObserver.observe(ref.current);

            return () => { resizeObserver.disconnect(); };
        },
        [ref]
    );

    return size;
};

export default useContentSize;
