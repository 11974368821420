import React from 'react';
import ApproveOnlyModal from 'sharedComponents/PDFModal/ApproveOnlyModal';
import { EulaModal } from '../EulaModal';
import PdfModal from 'sharedComponents/PDFModal/PdfModal';
import withBlobContentPath from 'sharedComponents/PDFModal/withBlobModal';
import withFetchedMessages from 'sharedComponents/PDFModal/withFetchedMessages';
import { ReportIssueModal } from '../ReportIssueModal';

const ImportantInformationModal = withFetchedMessages(withBlobContentPath(PdfModal));
const BroadcastedMessageModal = withBlobContentPath(ApproveOnlyModal);

// eslint-disable-next-line import/no-unused-modules
export const ModalPicker = ({ modalType, show, closeModal, modalMetadata, pdfContent }) => {
    const modalTypes = {
        'pdf': <PdfModal {...modalMetadata} {...{ show, closeModal }} />,
        'approveOnlyBroadcastPDF': <BroadcastedMessageModal
            broadcastedMessagePath={modalMetadata.pdfContent}
            close={() => { }} {...modalMetadata}  {...{ show, closeModal }}
            pdfContent={pdfContent}
        />,
        'eula': <EulaModal {...modalMetadata} {...{ show, closeModal }} />,
        'importantInfoFetchedPDF': <ImportantInformationModal {...modalMetadata} {...{ show, closeModal }} />,
        'reportIssue': <ReportIssueModal {...modalMetadata} {...{ show, closeModal }} />,
    };

    return show ? <>
        {modalType && modalTypes[modalType]}
    </> : null;
};
