import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconBellStrikeThrough = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.nero }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M1.18612 0.480001C1.14034 0.430876 1.08514 0.391475 1.02381 0.364147C0.962477 0.336819 0.896268 0.322124 0.829133 0.32094C0.761998 0.319755 0.695312 0.332105 0.633053 0.357252C0.570794 0.382399 0.514238 0.419829 0.466759 0.467308C0.41928 0.514788 0.38185 0.571344 0.356703 0.633602C0.331555 0.695861 0.319206 0.762547 0.32039 0.829682C0.321575 0.896818 0.336269 0.963026 0.363597 1.02436C0.390925 1.08569 0.430327 1.14089 0.479451 1.18667L2.48212 3.19C2.19612 3.79 2.02678 4.45667 2.00212 5.16067L1.99945 5.32733V8.002L1.05612 10.1087C1.01297 10.2051 0.994631 10.3108 1.00277 10.4161C1.01091 10.5215 1.04527 10.6231 1.10272 10.7117C1.16017 10.8004 1.23888 10.8733 1.3317 10.9237C1.42451 10.9742 1.52847 11.0006 1.63412 11.0007H10.2935L12.8121 13.5207C12.9059 13.6145 13.0331 13.6672 13.1658 13.6672C13.2984 13.6672 13.4257 13.6145 13.5195 13.5207C13.6133 13.4269 13.6659 13.2997 13.6659 13.167C13.6659 13.0343 13.6133 12.9071 13.5195 12.8133L1.18612 0.480001ZM13.0008 10.368C13.0008 10.5063 12.9554 10.6407 12.8715 10.7507L3.70012 1.58C4.4224 0.945691 5.31222 0.533032 6.263 0.391447C7.21379 0.249862 8.18523 0.385353 9.06099 0.781692C9.93675 1.17803 10.6797 1.81842 11.2009 2.62615C11.722 3.43389 11.9993 4.37473 11.9995 5.336V8.00133L12.9441 10.108C12.981 10.1897 13.0001 10.2783 13.0001 10.368H13.0008Z' fill={color} />
    </svg>;
};

IconBellStrikeThrough.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconBellStrikeThrough;
