import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { assetObjectToComponentRequest } from '../helpers';
import { userLimitsToRequest } from './userLimitsApiHelper';
import { handleGetUserLimitsConversion, handleSaveUserLimits } from './userLimitsApiUoMHelper';

class UserLimits {
    static getUserLimits(component) {
        const endpoint = formatEndpoint(endpoints.userLimits.getUserLimits);
        return RestManager
            .post(endpoint, assetObjectToComponentRequest(component))
            .then(handleGetUserLimitsConversion);
    }

    static saveUserLimits(component, defaultUserLimits, customUserLimits, eTag) {
        const endpoint = formatEndpoint(endpoints.userLimits.saveUserLimits);
        customUserLimits = handleSaveUserLimits(customUserLimits);
        return RestManager
            .put(endpoint, userLimitsToRequest(component, defaultUserLimits, customUserLimits, eTag));

    }
}

export default UserLimits;
