import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import './style.scss';
import NotificationStatusBarContent from './NotificationStatusBarContent';
import { NOTIFICATION_BAR } from 'helpers/constants';

const NotificationStatusBar = ({ model: { assetSubscriptionInfo } }) => {
	const [isHide, setIshide] = useState(true);

	// REVERT TO THIS while adding the blue and yellow banner
	// useEffect(() => {
	// 	if (assetSubscriptionInfo) {
	// 		actions.setContractNotification(assetSubscriptionInfo);
	// 	} else {
	// 		actions.closeNotificationBar();
	// 		setIshide(isHide);

	// 	}
	// }, [assetSubscriptionInfo, isHide]);

	useEffect(() => {
		if (assetSubscriptionInfo === NOTIFICATION_BAR.FREE_FOR_RENEWAL || assetSubscriptionInfo === NOTIFICATION_BAR.FREE) {
			setIshide(true);
		} else {
			setIshide(false);
		}
	}, [assetSubscriptionInfo]);


	const onClose = () => {
		// REVERT TO THIS while adding the blue and yellow banner
		// actions.closeNotificationBar(notification);
		// if (props.onClose) {
		// 	props.onClose(notification);
		// }
		setIshide(false);
	};

	return (
		assetSubscriptionInfo === NOTIFICATION_BAR.FREE_FOR_RENEWAL || assetSubscriptionInfo === NOTIFICATION_BAR.FREE ? <NotificationStatusBarContent notification={assetSubscriptionInfo} onClose={onClose} isHide={isHide} /> : null
	);

};

NotificationStatusBar.displayName = 'NotificationStatusBar';

NotificationStatusBar.propTypes = {
	contractInfo: PropTypes.shape({
		link: PropTypes.shape({
			description: PropTypes.string,
			url: PropTypes.string
		}),
		message: PropTypes.string,
		type: PropTypes.string,
	}),
	props: PropTypes.shape({
		onClose: PropTypes.func,
		closeNotification: PropTypes.func,
	})
};

export default NotificationStatusBar;

