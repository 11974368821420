import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { assetObjectToComponentRequest } from '../helpers';

class EmailSubscriptions {

	static getEmailSubscriptions(assets = []) {
		const endpoint = formatEndpoint(endpoints.emailSubscription.getSubscription);
		return RestManager.post(endpoint, assets.map(c => assetObjectToComponentRequest(c)));
	}

	static setEmailSubscriptions(dataObject) {
		const endpoint = formatEndpoint(endpoints.emailSubscription.setSubscription);
		return RestManager.post(endpoint, dataObject);
	}
}

export default EmailSubscriptions;
