import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconTransformer = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M14 13.09L9.29004 17.79L10.71 19.21L14 15.91L18 19.91L22.71 15.21L21.29 13.79L18 17.09L14 13.09Z' fill={color} />
		<path d='M27 7H25.58L24.73 3H22.25L21.42 7H18.08L17.23 3H14.75L13.92 7H10.58L9.73 3H7.25L6.42 7H5C4.46957 7 3.96086 7.21071
		3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V24C3 24.5304 3.21071 25.0391 3.58579 25.4142C3.96086 25.7893 4.46957 26 5
		26H10V27H7V29H25V27H22V26H27C27.5304 26 28.0391 25.7893 28.4142 25.4142C28.7893 25.0391 29 24.5304 29 24V9C29 8.46957
		28.7893 7.96086 28.4142 7.58579C28.0391 7.21071 27.5304 7 27 7ZM23.47 4.5L24 7H23L23.47 4.5ZM16 4.5L16.54 7H15.45L16
		4.5ZM8.5 4.5L9 7H8L8.5 4.5ZM6 24H5V9H6V24ZM24 24H8V9H24V24ZM27 24H26V9H27V24Z' fill={color} />
	</svg>;
};

IconTransformer.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconTransformer;
