import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import { LoadingStatusWrapper } from 'sharedComponents';
import { useTranslation } from 'react-i18next';
import { IconInfoFilled } from 'svgIcons/MotionPortalIcons';
import KPITrendChartListComponent from './KPITrendChartList';
import { DriveStatus } from './SingleChartView/DriveStatus';
import { apiCallStatus } from 'helpers/constants';
import { getDriveStatusData, getDriveOverAllStatus } from './helpers';
import colors from 'theme/_colors.scss';
import './style.scss';


const ConditionInsightsComponent = ({
    model: {
        conditionInsights
    },
}) => {
    const { t: translate } = useTranslation();
    const driveKPIS = getDriveStatusData(conditionInsights?.driveKPI);
    const overAllDriveStatus = getDriveOverAllStatus(conditionInsights?.driveKPI);
    const trendChartList = conditionInsights?.trendKPI;
    const renderDriveStatusSection = () => {
        if (conditionInsights?.driveKPI) {
            return (
                <Row className='conditional-insights-drive-status-component'>
                    <Col>
                        <DriveStatus model={{ selectedTrendsData: { data: driveKPIS, loadingStatus: apiCallStatus?.SUCCESS, zoom: { isZoomed: false }, chartID: 'drive-status' } }} title={translate('ABB.Powertrain.Frontend.conditionDriveChartTitle')} conditionStatus={overAllDriveStatus}></DriveStatus>
                    </Col>
                </Row>
            );
        }
        return null;
    };


    const renderEmptyStatusView = () => {
        return (
            <div className='conditional-insight-status-message'>
                <IconInfoFilled width={'32px'} height={'32px'} color={colors.nero} />
                <span>{translate('ABB.Powertrain.Frontend.conditionDetailsOKMessage')}</span>
            </div>
        );
    };

    const renderOverAllKPIView = () => {
        if (trendChartList && trendChartList?.length > 0 || driveKPIS && driveKPIS?.length > 0) {
            return (
                <React.Fragment>
                    {renderDriveStatusSection()}
                    <KPITrendChartListComponent
                        trendParams={trendChartList}
                        loadingStatus={conditionInsights?.loadingStatus}
                    ></KPITrendChartListComponent>
                </React.Fragment>
            );
        }
        return renderEmptyStatusView();
    };
    return (
        <Container fluid id='conditional-insights-parameters-container'>
            <LoadingStatusWrapper loadingStatus={[conditionInsights?.loadingStatus]}>
                {renderOverAllKPIView()}
            </LoadingStatusWrapper>
        </Container>);
};

ConditionInsightsComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
        conditionInsights: PropTypes.object,
        assetDetails: PropTypes.object
    })
};
export default ConditionInsightsComponent;
