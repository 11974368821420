import { filterTabs, StateID, allAssetTypes } from 'helpers/constants';
import { filter } from 'lodash';

const activeTabCheck = (assets, powertrains, activeTab) => {
    return activeTab === filterTabs.ASSETS ? assets : powertrains;
};

const itemTypeCheck = (item, assetTypeFilter, activeTab) => {
    if (assetTypeFilter === allAssetTypes) {
        return true;
    }
    return activeTab === filterTabs.ASSETS ? item.componentType.typeName === assetTypeFilter : item.components.some(item => item.componentType.typeName === assetTypeFilter);
};

export const getAssetAndPowertrainNumber = ({ assets, powertrains, activeTab, assetTypeFilter }, conditionStatus) => {
    return filter(activeTabCheck(assets, powertrains, activeTab), (item) => {
        return item.overallStatus === StateID[conditionStatus] && itemTypeCheck(item, assetTypeFilter, activeTab);
    }).length;
};
