import { labelBounds } from './constants';

export const getLabel = (labelItem) => {
	let label = '';
	switch (labelItem.visualize) {
		case labelBounds.Both:
			label = `${labelItem.start} ... ${labelItem.end}`;
			break;
		case labelBounds.LowerThanUpper:
			label = `< ${labelItem.end}`;
			break;
		case labelBounds.GreaterThanLower:
			label = `> ${labelItem.start}`;
			break;
		default:
			label = `${labelItem.start} ... ${labelItem.end}`;
			break;
	}
	return label;
};
