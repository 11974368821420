import React from 'react';
import { Expandable } from 'sharedComponents';
import { EventTypes } from '../constants';
import './style.scss';

const EventCategory = (props) => {
    return (
        <Expandable
            title={
                <div className='event-group'>
                    <span className='event-type-icon'>{EventTypes.find(et => et.typeID === props.category).icon}</span>
                    <span className='event-name'>{props.name}</span>
                </div>
            }
            open={props?.isExpanded}
            onClick={() => {
                props.setIsExpanded(!props.isExpanded);
            }}
        >
            {props.children}
        </Expandable>
    );
};

export default EventCategory;
