import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconBell = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.nero }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M21 18.19L20.25 17.44L19.5 16.68C19.2999 16.4799 19.1313 16.2506 19 16V9.80997C18.9793 8.21662 18.4054 6.68001 17.3765 5.46321C16.3476 4.24642 14.9278 3.42514 13.36 3.13997C13.4664 2.9114 13.5134 2.6597 13.4967 2.40814C13.4801 2.15659 13.4003 1.91328 13.2647 1.70072C13.1292 1.48815 12.9423 1.31317 12.7212 1.19196C12.5001 1.07074 12.2521 1.0072 12 1.0072C11.7479 1.0072 11.4999 1.07074 11.2788 1.19196C11.0577 1.31317 10.8708 1.48815 10.7353 1.70072C10.5997 1.91328 10.5199 2.15659 10.5033 2.40814C10.4866 2.6597 10.5336 2.9114 10.64 3.13997C9.07224 3.42514 7.65236 4.24642 6.62347 5.46321C5.59458 6.68001 5.02067 8.21662 5 9.80997V16C4.86865 16.2506 4.70007 16.4799 4.5 16.68L3.75 17.44L3 18.19V21.08H10C10 21.6104 10.2107 22.1191 10.5858 22.4942C10.9609 22.8693 11.4696 23.08 12 23.08C12.5304 23.08 13.0391 22.8693 13.4142 22.4942C13.7893 22.1191 14 21.6104 14 21.08H21V18.19Z' fill={color} />
    </svg>;
};

IconBell.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconBell;
