
import { useEffect, useState } from 'react';
import { setMaxPrecision } from 'helpers/formatters';

const useOffset = (props) => {

    const { selectedKpi, actions: { setOffset } } = props;
    const { config } = selectedKpi;
    const [calculatedMin, setCalculatedMin] = useState(config.min);
    const [calculatedMax, setCalculatedMax] = useState(config.max);
    const [showCalibrationError, setShowCalibrationError] = useState(null);

    useEffect(() => {
        const calibrationOffset = parseInt(config.offset || 0);
        const calibrationOffsetMax = Math.floor((Math.abs(config.min) + Math.abs(config.max)) / 2);

        const isCalibrationError = Math.abs(calibrationOffset) > calibrationOffsetMax;
        setShowCalibrationError(isCalibrationError);
        if (!isCalibrationError) {
            setCalculatedMin(setMaxPrecision(
                config.min + (calibrationOffset > 0 ? calibrationOffset : 0),
                config.precision
            ));
            setCalculatedMax(setMaxPrecision(
                config.max + (calibrationOffset < 0 ? calibrationOffset : 0),
                config.precision
            ));
        }
        else {
            setOffset(selectedKpi.measurementTypeID, calibrationOffset > 0 ? calibrationOffsetMax : -calibrationOffsetMax);
        }

    }, [selectedKpi]);

    return [calculatedMin, calculatedMax, showCalibrationError];

};

export default useOffset;
