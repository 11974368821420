import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GeneralPagination } from 'sharedComponents/GeneralPagination';
import { GeneralSelect, LoadingStatusWrapper } from 'sharedComponents';
import { IconTrash } from 'svgIcons/MotionPortalIcons';
import { filterCommentsByCause, formatComments, getEventCauseOptions } from './helpers';
import { pageSize } from './constants';
import { paginateCommentList } from 'helpers/paginationHelper';
import { causeOfEvents } from '../../../constants';

const SelfFaultEvaluationCommentList = ({ model, actions, selectedAsset }) => {
    const { t: translate } = useTranslation();
    const [causeOptionsFilter] = useState(getEventCauseOptions(translate));

    const [selectedPage, setSelectedPage] = useState(1);

    const [displayedComments, setDisplayedComments] = useState([]);
    const [filteredComments, setFilteredComments] = useState([]);

    const [causeFilter, setCauseFilter] = useState(causeOptionsFilter.find(c => c.index === causeOfEvents.All));

    useEffect(() => {
        if (selectedPage > 1 && filteredComments.length !== 0 && displayedComments.length === 0) {
            setSelectedPage(selectedPage - 1);
        }
        setDisplayedComments(paginateCommentList(filteredComments, (selectedPage - 1) * pageSize, selectedPage * pageSize - 1));
    }, [filteredComments]);

    useEffect(() => {
        setDisplayedComments(paginateCommentList(model.comments, (selectedPage - 1) * pageSize, selectedPage * pageSize - 1));
        setFilteredComments(filterCommentsByCause(model.comments, causeFilter));
    }, [selectedPage, model.comments]);

    const eventCauseFilterSelectHandler = (eventCauseFilter) => {
        setCauseFilter(eventCauseFilter);
        const filteredCommentsByCause = filterCommentsByCause(model.comments, eventCauseFilter);
        setFilteredComments(filteredCommentsByCause);
        setSelectedPage(1);
    };

    const deleteComment = (comment) => {
        actions.deleteComment(selectedAsset, model.selectedEvent, comment);
    };

    return (
        <Col className='comment-list' xl={7} xs={12}>
            <div className='comment-list-label'>{translate('ABB.Powertrain.Frontend.eventCommentsLabelSelfEvaluate')}</div>

            <div className='cause-filter-label'>{translate('ABB.Powertrain.Frontend.causeFilterLabelSelfEvaluate')}</div>
            <GeneralSelect
                className='cause-filter-select'
                placeholder=''
                options={causeOptionsFilter}
                value={causeFilter}
                onChange={eventCauseFilterSelectHandler}
            />

            <LoadingStatusWrapper loadingStatus={[model.getCommentStatus, model.deleteCommentStatus]}>
                {model.comments?.length && displayedComments.length ?
                    <>
                        {
                            <div className='comments-wrapper'>
                                {
                                    formatComments(displayedComments, model.showUtcDates, translate).map((item, index) => {
                                        return (
                                            <div className='comment-wrapper' key={index}>
                                                <div className='comment-details'>
                                                    <div className='comment-header'>
                                                        {item.header}
                                                    </div>
                                                    <div className='user-comment-text'>{item.text}</div>
                                                </div>
                                                {item.canDeleteComment &&
                                                    <div className='delete-comment-icon-wrapper'>
                                                        <div className='delete-comment-icon' onClick={() => { deleteComment(item.commentRef); }}>
                                                            <IconTrash />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }
                        <GeneralPagination
                            currentPage={selectedPage}
                            total={filteredComments.length}
                            pageSize={pageSize}
                            onChange={setSelectedPage}
                        />
                    </>
                    :
                    <div className='no-data-label'>{translate('ABB.Powertrain.Frontend.noCommentsLabelSelfEvaluate')}</div>
                }
            </LoadingStatusWrapper>
        </Col>
    );
};

SelfFaultEvaluationCommentList.propTypes = {
    actions: PropTypes.shape({
        deleteComment: PropTypes.func,
    }),
    model: PropTypes.shape({
        selectedEvent: PropTypes.object,
        getCommentStatus: PropTypes.string,
        comments: PropTypes.array,
        showUtcDates: PropTypes.bool,
    }),
    selectedAsset: PropTypes.object,
};

export default SelfFaultEvaluationCommentList;
