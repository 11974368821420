import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import trimStart from 'lodash/trimStart';
import { isValidChange } from './helpers';
import { removeUnallowedCharsFromString } from 'helpers/validate';
import { GeneralDialog, GeneralInput } from 'sharedComponents';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { apiCallStatus } from 'helpers/constants';
import { reNameStatus } from './constants';

import './style.scss';


const RenameAssetComponent = (props) => {
    const { t: translate } = useTranslation();
    const { model: { assetUpdateStatus } } = props;
    const [assetName, setAssetName] = useState(props.asset?.componentName);
    const oldassetName = props.asset?.componentName;

    const onNameChange = (value) => {
        const modifiedInput = trimStart(removeUnallowedCharsFromString(value));
        modifiedInput && modifiedInput.length <= 100 && setAssetName(trimStart(modifiedInput));
    };

    const onClose = () => {
        setAssetName(props.asset?.componentName);
        props.close();
    };

    const onSave = () => {
        const asset = { ...props.asset, componentName: assetName, eTag: oldassetName };
        props.actions.updateAsset(asset);
    };

    useEffect(() => {
        if (props?.model?.assetRenameStatus === reNameStatus.reNameAsset) {
            onClose();
        }
    }, [props?.model?.assetRenameStatus]);
    return <GeneralDialog
        id='rename-modal'
        show={props.show}
        save={onSave}
        close={onClose}
        title={props.title}
        closeButton={assetUpdateStatus !== apiCallStatus.LOADING}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.componentRenameModalCancel'),
            onClick: () => { onClose(); },
            disabled: assetUpdateStatus === apiCallStatus.LOADING
        }}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.componentRenameModalSave'),
            onClick: () => { onSave(); },
            disabled: !isValidChange(assetName, props.asset?.componentName) ||
                assetUpdateStatus === apiCallStatus.LOADING
        }}
    >
        <Container className='rename-modal-container g-0'>
            <LoadingStatusWrapper loadingStatus={assetUpdateStatus} hideError >
                <Row className='asset-name-label'><span>{translate('ABB.Powertrain.Frontend.assetNameLabel')}</span></Row>
                <Row>
                    <GeneralInput
                        id='renameComponent'
                        type='text'
                        value={assetName}
                        placeholder={translate('ABB.Powertrain.Frontend.componentRenameModalPlaceholder')}
                        onChange={(e) => { onNameChange(e); }}
                    />
                </Row>
            </LoadingStatusWrapper>
        </Container>
    </GeneralDialog>;
};

export default RenameAssetComponent;
