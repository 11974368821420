import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventLogFilterSSComponent from './EventLogFilterSSComponent';
import EventLogFilterCMDComponent from './EventLogFilterCMDComponent';
import { getModel } from './selectors';
import * as actions from './actions';
import './style.scss';


const EventLogFilterContainer = (props) => {
	return props.isSS
		? <EventLogFilterSSComponent {...props} />
		: <EventLogFilterCMDComponent {...props} />;
};

EventLogFilterContainer.propTypes = {
	isSS: PropTypes.bool
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const EventLogFilterContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(EventLogFilterContainer);

export default EventLogFilterContainerConn;
