import {
    baseToCustomUOM,
    getMeasurementUnitSymbol
} from 'helpers/unitHelper';
import map from 'lodash/map';


export const handleFFTDataConversion = (response) => {
    if (!response?.dataValues) {
        return response;
    }

    response.magnitudeUnitSymbol = getMeasurementUnitSymbol(response.amplitudeMeasurementUnitGroupID);
    response.frequencyUnitSymbol = getMeasurementUnitSymbol(response.frequencyMeasurementUnitGroupID);

    response.dataValues = map(response.dataValues, (dataValue) => {
        dataValue.sensorAxisDataValues = baseToCustomUOM.convertMeasurementMultiPrecision(
            dataValue.sensorAxisDataValues,
            {
                magnitude: { measurementUnitGroup: response.amplitudeMeasurementUnitGroupID, precision: 4 },
                frequency: { measurementUnitGroup: response.frequencyMeasurementUnitGroupID, precision: response.precision }
            }
        );

        return dataValue;
    });

    return response;
};
