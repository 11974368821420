import { createSelector } from 'reselect';

const getDownloadConfirmationParams = ({ downloadMeasurements }) => {
	return downloadMeasurements.measurementDownloadDialogParams;
};

const getDateSelection = ({ overview }) => {
	return overview.dateSelection;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getTrendKPIsLoadingStatus = ({ operationalParameters }) => {
	return operationalParameters?.availableKPIs?.trend?.loadingStatus;
};

const getMeasurementExportLoadingStatus = ({ downloadMeasurements }) => {
	return downloadMeasurements.measurementExport.loadingStatus;
};

export const getModel = createSelector([
	getDownloadConfirmationParams,
	getDateSelection,
	getSelectedAsset,
	getTrendKPIsLoadingStatus,
	getMeasurementExportLoadingStatus
],
	(
		measurementDownloadDialogParams,
		dateSelection,
		asset,
		trendKpisLoadingStatus,
		measurementExportLoadingStatus
	) => {
		return {
			measurementDownloadDialogParams,
			dateSelection,
			asset,
			trendKpisLoadingStatus,
			measurementExportLoadingStatus
		};
	});
