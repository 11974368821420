import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { wizardSteps, wizardStepStates } from './constants';
import { GeneralInput } from 'sharedComponents';
import AssetOverviewTable from './AssetListWithFilter/AssetTable/AssetOverviewTable';
import { getSiteById } from '../helpers';
import PropTypes from 'prop-types';
import './style.scss';


const PowertrainWizardStep3 = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const {
        powertrainName,
        selectedSite,
        sites,
        addedAssets,
        activeStep
    } = model;
    const { setWizardStepState } = actions;


    useEffect(() => {
        activeStep === wizardSteps.STEP3 &&
            setWizardStepState(wizardSteps.STEP3, wizardStepStates.COMPLETED);
    }, [activeStep]);

    const renderPowertrainNameInput = () => {
        return <>
            <Row id='powertrain-name-label'>
                <Col className='control-label'> {translate('ABB.Powertrain.Frontend.powertrainWizardPowertrainNameLabel')} </Col>
            </Row>
            <Row className='control-item'>
                <Col>
                    <GeneralInput
                        className='powertrain-name'
                        id='powertrain-name'
                        disabled={true}
                        value={powertrainName}
                    />
                </Col>
            </Row>
        </>;
    };
    const renderSelectedSiteInput = () => {
        return <>
            <Row id='powertrain-site-label'>
                <Col className='control-label'> {translate('ABB.Powertrain.Frontend.powertrainWizardSiteLabel')} </Col>
            </Row>
            <Row className='control-item'>
                <Col>
                    <GeneralInput
                        value={getSiteById(sites, selectedSite.siteId)?.siteName}
                        className='site-select'
                        id='site-select'
                        disabled={true}
                    />
                </Col>
            </Row>
        </>;
    };

    const renderAssetsOverviewTableSection = () => {
        return <>
            <Col className='powertrain-assets-table'>
                <Row className='table-title-row'>
                    <Col className='text-medium-large'>
                        {translate('ABB.Powertrain.Frontend.powertrainWizardPowertrainAssetsText')}
                    </Col>
                </Row>
                <Row className='asset-table-row'>
                    <Col>
                        <AssetOverviewTable assets={addedAssets} />
                    </Col>
                </Row>
            </Col>
        </>;
    };

    return <div className='step3'>
        <Row className='overview-content'>
            <Col>
                {renderPowertrainNameInput()}
                {renderSelectedSiteInput()}
            </Col>
            {renderAssetsOverviewTableSection()}
        </Row>
    </div>;
};

PowertrainWizardStep3.propTypes = {
    model: PropTypes.shape({
        powertrainName: PropTypes.string,
        selectedSite: PropTypes.shape({
            siteID: PropTypes.number.isRequired,
            siteName: PropTypes.string.isRequired,
        }),
        addedAssets: PropTypes.array,
        activeStep: PropTypes.string,
    }),
    actions: PropTypes.shape({
        setWizardStepState: PropTypes.func,
    })
};

export default PowertrainWizardStep3;
