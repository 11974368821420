export const actionTypes = {
    CLEAR_STORE: 'powertrainTools/edit/CLEAR_STORE',
    GET_POWERTRAINS: 'powertrainTools/GET_POWERTRAINS',
    ADD_POWERTRAIN: 'powertrainWizard/ADD_POWERTRAIN',
    DELETE_POWERTRAIN: 'powertrainWizard/DELETE_POWERTRAIN',
    EDIT_POWERTRAIN: 'powertrainWizard/EDIT_POWERTRAIN',
    SET_SELECTED_POWERTRAIN: 'powertrainTools/SET_SELECTED_POWERTRAIN',
    SET_SEARCHBOX_FILTER: 'powertrainTools/SET_SEARCHBOX_FILTER',
    SET_POWERTRAIN_FOR_EDITING: 'powertrainWizard/SET_POWERTRAIN_FOR_EDITING',

    //TO DO: edit when common powertrain list is used for configuration and overview
    RELOAD_OVERVIEW_POWERTRAINS_REQUEST: 'powertrainWizard/RELOAD_OVERVIEW_POWERTRAINS_REQUEST',
    GET_OVERVIEW_POWERTRAINS: 'overview/GET_POWERTRAINS',

    //saga
    LOAD_POWERTRAINS_REQUEST: 'powertrainTools/LOAD_POWERTRAINS_REQUEST',
    DELETE_POWERTRAIN_REQUEST: 'powertrainTools/DELETE_POWERTRAIN_REQUEST'
};

export const pageSize = 1000;
