import { createSelector } from 'reselect';

import { formatParameterFileList } from './helpers';


const getChangedParameterFileList = ({ changedParameters }) => {
	return changedParameters && {
		...changedParameters.changedParameterFileList,
		data: formatParameterFileList(changedParameters.changedParameterFileList.data)
	};
};

const getSelectedDrive = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getChangedParametersListLoading = ({ changedParameters }) => {
	return changedParameters && changedParameters.changedParameterFileList && changedParameters.changedParameterFileList.loadingStatus;
};

export const getModel = createSelector([
	getChangedParameterFileList,
	getSelectedDrive,
	getChangedParametersListLoading
],
	(
		changedParameterFileList,
		selectedDrive,
		changedParametersListLoading
	) => {
		return {
			changedParameterFileList,
			selectedDrive,
			changedParametersListLoading
		};
	});
