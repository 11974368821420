import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
    Checkbox,
    Switch,
    TimeSelectorRow
} from 'sharedComponents';
import {
    getNotificationGroupColumns,
    isNotificationGroupMuted,
    updateMutedGroupList
} from './helpers';
import './style.scss';


const EditMutedAssetComponent = (props) => {
    const {
        disabledForPeriod,
        setDisabledForPeriod,
        period,
        setPeriod,
        mutedGroups,
        setMutedGroups,
        notificationGroups
    } = props;
    const { t: translate } = useTranslation();

    const renderNotificationGroupWithCheckbox = (notificationGroup) => {
        return <div
            className='mute-notification-group'
            key={notificationGroup?.notificationGroupCode}
        >
            <Checkbox
                checked={isNotificationGroupMuted(notificationGroup?.notificationGroupCode, mutedGroups)}
                onToggle={() => setMutedGroups(updateMutedGroupList(mutedGroups, notificationGroup))}
                width={'16px'}
                height={'16px'}
            />
            <div className='group-name'>
                {translate(notificationGroup?.notificationGroupNameTextKey) ||
                    notificationGroup?.notificationGroupName}
            </div>
        </div>;
    };

    const renderNotificationGroups = () => {
        const columns = getNotificationGroupColumns(notificationGroups);

        return map(columns, (column) => {
            return <div className='column' key={column.id}>
                {map(column.columnData, (notificationGroup) => {
                    return renderNotificationGroupWithCheckbox(notificationGroup);
                })}</div>;
        });
    };

    return <div className='edit-muted-asset-container'>
        <div className='edit-muted-asset-description'>
            {translate('ABB.Powertrain.Frontend.mutedAssetsEditPopupDescription')}
        </div>
        <div className='notification-group-selection'>
            <div className='section-title'>
                {translate('ABB.Powertrain.Frontend.mutedAssetsSelectedNotificationGroups')}
            </div>
            <div className='notification-groups-list'>{renderNotificationGroups()}</div>
        </div>
        <div className='period-selection'>
            <div className='section-title'>
                <Switch
                    onChange={() => {
                        disabledForPeriod && setPeriod(null);
                        if (!disabledForPeriod) {
                            const today = new Date();
                            setPeriod({
                                from: today,
                                to: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
                            });
                        }
                        setDisabledForPeriod(!disabledForPeriod);
                    }}
                    checked={disabledForPeriod}
                />
                <div className='title-text'>
                    {translate('ABB.Powertrain.Frontend.mutedAssetsSelectedPeriod')}
                </div>
            </div >
            {disabledForPeriod && <div className='mute-period'>
                {translate('ABB.Powertrain.Frontend.mutedAssetsTimePeriod')}
                <TimeSelectorRow
                    fromDate={period?.from}
                    toDate={period?.to}
                    handleSelectDate={(from, to) => { setPeriod({ from, to }); }}
                    maxDaysBack={0}
                    numberOfMonths={2}
                    withPredefinedSelection={false}
                    disableFuture={false}
                />
            </div>}
        </div>
    </div>;
};

EditMutedAssetComponent.propTypes = {
    disabledForPeriod: PropTypes.bool,
    setDisabledForPeriod: PropTypes.func,
    period: PropTypes.object,
    setPeriod: PropTypes.func,
    mutedGroups: PropTypes.array,
    setMutedGroups: PropTypes.func,
    notificationGroups: PropTypes.array
};

export default EditMutedAssetComponent;
