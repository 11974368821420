import React, { useContext } from 'react';

import { IconAbbLogo, IconMOPortalTitle } from 'svgIcons/MotionPortalIcons';
import { LoginContext } from '../LoginComponent';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import colors from 'theme/_colors.scss';
import './style.scss';


const PowertrainLoginComponent = () => {
    const { notProductionWarningComponent, handleLogin, errorMessage } = useContext(LoginContext);

    return <div className='login-powertrain'>
        <div className='login-container'>
            <div className='login-card'>
                <div className='login-header'>
                    <IconAbbLogo color={colors.red} width={88} height={32} />
                </div>
                <div className='login-body'>
                    <div className='login-body_app-name-text'>
                        <IconMOPortalTitle width={510} height={90} />
                        <div>Assets that let you know when it&apos;s time for service</div>
                    </div>
                    {errorMessage}
                    <div>
                        {notProductionWarningComponent}
                        <GeneralButton className='login-body_login-button' type='primary' text='Log in' onClick={handleLogin} />
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default PowertrainLoginComponent;
