import { createSelector } from 'reselect';

const getUser = ({ overview }) => {
    return overview.user ? overview.user : null;
};

const getUserLoadingStatus = ({ overview }) => {
    return overview.user.loadingStatus;
};

const getAvailableLanguages = ({ overview }) => {
    return overview.availableLanguages?.data;
};

const getAvailableLanguagesLoadingStatus = ({ overview }) => {
    return overview.availableLanguages?.loadingStatus;
};

export const getModel = createSelector(
    [
        getUser,
        getUserLoadingStatus,
        getAvailableLanguages,
        getAvailableLanguagesLoadingStatus,
    ],
    (
        user,
        userLoadingStatus,
        availableLanguages,
        availableLanguagesLoadingStatus,
    ) => {
        return {
            user,
            userLoadingStatus,
            availableLanguages,
            availableLanguagesLoadingStatus,
        };
    }
);
