import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'sharedHooks/useWindowSize';
import { IconRefresh } from 'svgIcons/MotionPortalIcons';
import { TimeSelectorRow, LoadingIndicator } from 'sharedComponents';
import { MAX_SELECTION_DAYS } from './constants';
import { getColumns, getPredefinedRangeStart } from './helpers';
import { sortingDirections, GeneralTable } from 'sharedComponents/GeneralTable';
import { assetTypes } from 'helpers/constants';

import './styles.scss';

const BulkUploadStatusSectionComponent = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const windowSize = useWindowSize();
    const [dateInterval, setDateInterval] = useState({
        from: getPredefinedRangeStart(1),
        to: new Date(new Date().setHours(23, 59, 59, 999))
    });

    useEffect(() => {
        actions.setDateInterval(dateInterval);
        actions.getBulkUploadStatus(model.selectedAsset, dateInterval);
    }, [dateInterval]);

    const handleSelectDate = (from, to) => {
        from && to && setDateInterval({ from, to });
    };
    return (
        <div className='bulk-status-section' id='bulk-status-section'>
            <div className='status-section-header' >
                <TimeSelectorRow
                    fromDate={dateInterval.from}
                    toDate={dateInterval.to}
                    label={''}
                    handleSelectDate={handleSelectDate}
                    maxDaysBack={MAX_SELECTION_DAYS}
                    preSelectedDays={2}
                    numberOfMonths={2}
                    showLoading={model.isUploadStatusLoading}
                    dateSelectButtonText={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadFileSectionApplyInterval')}
                    footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                />
                <div className='section-label'>
                    <span>{translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadStatus')}</span>
                    <button
                        disabled={model.isUploadStatusLoading}
                        className='refresh-button'
                        title={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadFileSectionRefresh')}
                        onClick={() => actions.getBulkUploadStatus(model.selectedAsset, dateInterval)}>
                        <IconRefresh />
                    </button>

                </div>
            </div>
            <Row>
                <Col>
                    {!model.isUploadStatusLoading
                        ? <GeneralTable
                            className='upload-status-table'
                            tableKey='id'
                            columns={getColumns(translate)}
                            data={model.uploadStatusList}
                            emptyText={translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                            scroll={{ x: model.uploadStatusList?.length > 0 ? 1000 : 890, y: null }}
                            paginationProps={{ pageSize: windowSize[1] < 1081 ? 5 : 10 }}
                            initialSortingProps={{
                                column: 'uploadTimeUtc',
                                direction: sortingDirections.DESC
                            }}
                            type={assetTypes.PRIMARY}
                        />
                        : <div className='extra-loading-wrapper' ><LoadingIndicator /></div>
                    }
                </Col>
            </Row>
        </div>
    );
};

BulkUploadStatusSectionComponent.propTypes = {
    actions: PropTypes.shape({

    }),
    model: PropTypes.shape({
        selectedAsset: PropTypes.object,
        uploadStatusList: PropTypes.array,
        isUploadStatusLoading: PropTypes.bool,
    })
};

export default BulkUploadStatusSectionComponent;
