import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default (reloadTabCallback, tabId, deps) => {

    const dateSelection = useSelector(({ overview }) => overview.dateSelection);
    const activeTab = useSelector(({ operationalParameters }) => operationalParameters.activeTab);

    const [date, setDate] = useState(dateSelection);

    useEffect(() => {
        const dateChanged = date.from !== dateSelection.from
            || date.to !== dateSelection.to;
        if (activeTab === tabId && dateChanged) {
            setDate(dateSelection);
            reloadTabCallback();
        }
    }, [dateSelection]);

    deps && useEffect(() => {
        reloadTabCallback();
    }, [...deps]);
};
