import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconComment = ({ width = 24, height = 24, viewBox = '0 0 24 24' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M23 2H1V17H9V21.83L16 17H23V2ZM21 4V15H15.38L11 18V15H3V4H21ZM6 7H18V8H6V7ZM18 9H6V10H18V9ZM6 11H18V12H6V11Z' fill={colors.mineShaft} />
		<mask mask-type='alpha' maskUnits='userSpaceOnUse' x='1' y='2' width='22' height='20'>
			<path fillRule='evenodd' clipRule='evenodd' d='M23 2H1V17H9V21.83L16 17H23V2ZM21 4V15H15.38L11 18V15H3V4H21ZM6 7H18V8H6V7ZM18 9H6V10H18V9ZM6 11H18V12H6V11Z' fill={colors.white} />
		</mask>
	</svg>;
};

IconComment.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};


export default IconComment;
