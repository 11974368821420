import React from 'react';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { ToggleButton } from 'sharedComponents/ToggleButton';
import { IconCondition, IconTrash } from 'svgIcons/MotionPortalIcons';
import { LEVEL, LIMIT_SYMBOL } from './constants';
import { translate } from 'helpers/translateHelper';
import { useDispatch } from 'react-redux';
import { deleteLimit, updateLimit } from '../actions';
import { getUnitsGroupID } from './helper';
import { getMeasurementUnitSymbol } from 'helpers/unitHelper';

const options = [
    { label: 'Alarm', value: 'Alarm', prefixIcon: <IconCondition condition={3} />, prefixIconSelected: <IconCondition condition={3} /> },
    { label: 'Alert', value: 'Alert', prefixIcon: <IconCondition condition={2} />, prefixIconSelected: <IconCondition condition={2} /> }
];

const Limit = ({ item, timeSeries, kpiList }) => {
    const dispatch = useDispatch();

    const handleDeleteLimit = (item) => {
        dispatch(deleteLimit(item));
    };

    const handleUpdateLimit = (uniqId, field, value) => {
        dispatch(updateLimit(uniqId, field, value, timeSeries.uniqId));
    };

    return (
        <div className='limit-box'>
            <div className='limit-values-container'>
                {timeSeries?.isDefaultLimitEditable === false && item?.isDefault &&
                    <div className='item-disable'></div>
                }
                <div className='limit-left-values'>
                    <div className='limit-level-container'>
                        <ToggleButton
                            options={options}
                            selectedValue={item.limitLevel}
                            onSelected={(val) => handleUpdateLimit(item.uniqId, 'limitLevel', val)}
                        />
                    </div>
                    <div className='limit-value-fields'>
                        <GeneralSelect
                            value={item.limitDirection ? { label: item.limitDirection === LEVEL.UpperLimit || item.limitDirection === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower, value: item.limitDirection } : { label: LIMIT_SYMBOL.Default, value: '', isDisabled: true }}
                            options={
                                [
                                    { label: '>', value: LEVEL.UpperLimit },
                                    { label: '<', value: LEVEL.LowerLimit }
                                ]
                            }
                            onChange={(e) => {
                                handleUpdateLimit(item.uniqId, 'limitDirection', e.value);
                            }}
                        />
                        <div
                            className={`
                                limit-value-uom 
                                ${timeSeries.minValue && timeSeries.maxValue && (item.limitValue < timeSeries.minValue || item.limitValue > timeSeries.maxValue)
                                    ? 'error'
                                    : ''
                                }
                            `
                            }>
                            <input
                                type='number'
                                placeholder={translate('ABB.Powertrain.Frontend.inputValue')}
                                value={item?.limitValue === 0 ? '0' : item?.limitValue || ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^-?\d*\.?\d{0,2}$/;

                                    if (regex.test(value)) {
                                        handleUpdateLimit(item.uniqId, 'limitValue', value);
                                    }
                                }}
                                name='limitValue'
                            />
                            {timeSeries.unit && <>
                                <div className='limit-pipe'>|</div>
                                <div className='uom-text'>{getMeasurementUnitSymbol(getUnitsGroupID(timeSeries.displayNameTextKey, kpiList)) || timeSeries.unit}</div>
                            </>}
                        </div>
                    </div>
                </div>
                {timeSeries?.isDefaultLimitEditable && item?.isDefault ? null : <div className='limit-right-values'>
                    <GeneralButton
                        className='limit-delete-button'
                        icon={<IconTrash width={15}
                            height={18}
                            style={{ marginRight: 0 }} />}
                        onClick={() => handleDeleteLimit(item)}>
                    </GeneralButton>
                </div>}
            </div>
        </div>
    );
};

export default Limit;
