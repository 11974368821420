import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

// import { dateTimeStringFormatter } from 'helpers/dateHelper';

const formatSensorPropertiesData = (sensorProperties) => {
    if (!sensorProperties) {
        return [];
    }
    const sortedSensorProperties = sortBy(sensorProperties, el => el.sequenceNo);
    const visibleProperties = filter(sortedSensorProperties, el => el.isVisible);
    return visibleProperties;
};

export const formatGatewayInfo = (gatewayInfo, sensorTypeId, translate) => {
    const gatewayInfoData = [];

    if (gatewayInfo) {
        if (gatewayInfo.name && sensorTypeId === 1) {
            gatewayInfoData.push({
                label: translate('ABB.Powertrain.Frontend.gatewayName'),
                value: gatewayInfo.name
            });
        }
        if (gatewayInfo.uniqueID) {
            gatewayInfoData.push({
                label: translate('ABB.Powertrain.Frontend.gatewayMAC'),
                value: gatewayInfo.uniqueID
            });
        }
        if (gatewayInfo.manufacturer && gatewayInfo.model) {
            gatewayInfoData.push({
                label: translate('ABB.Powertrain.Frontend.gatewayManufacturerType'),
                value: gatewayInfo.manufacturer + ' ' + gatewayInfo.model
            });
        }
        if (gatewayInfo.onlineStatus === true || gatewayInfo.onlineStatus === false) {
            const onlineStatus = gatewayInfo.onlineStatus ? translate('ABB.Powertrain.Frontend.gatewayOnline') :
                translate('ABB.Powertrain.Frontend.gatewayOffline');
            gatewayInfoData.push({
                label: translate('ABB.Powertrain.Frontend.gatewayOnlineStatus'),
                value: onlineStatus
            });
        }
    }
    else {
        return [];
    }
    return gatewayInfoData;
};

export const formatSensorDetailsData = (sensorProperties,
    isFirmwareUpdateEnforced, translate) => {

    const sensorDetails = [
        {
            label: translate('ABB.Powertrain.Frontend.sensorIdentifier'),
            value: sensorProperties.sensorIdentifier
        },
        {
            label: translate('ABB.Powertrain.Frontend.sensorType'),
            value: sensorProperties.sensorTypeDescription
        },
        {
            label: translate('ABB.Powertrain.Frontend.firmwareVersion'),
            value: sensorProperties.applicationData.firmwareVersion,
            isFirmwareUpdateEnforced
        },
        {
            label: translate('ABB.Powertrain.Frontend.hardwareRevision'),
            value: sensorProperties.revision
        },
        // sensorProperties.subscription && {
        //     label: translate('ABB.Powertrain.Frontend.subscriptionType'),
        //     value: sensorProperties.subscription.subscriptionDisplayName
        // },
        // sensorProperties.subscription.expirationDate && {
        //     label: translate('ABB.Powertrain.Frontend.subscriptionExpirationDate'),
        //     value: dateTimeStringFormatter(sensorProperties.subscription.expirationDate)
        // }
    ];

    sensorDetails.push(...formatSensorPropertiesData(sensorProperties.sensorProperties));

    return sensorDetails;
};
