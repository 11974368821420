import { routes } from 'routes';

export const getTabOptions = (translate) => {
    return {
        powertrainConfiguration: {
            title: translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab'),
            route: routes.ToolsPowertrainConfiguration
        },
        subscriptionStatus: {
            title: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatusTab'),
            route: routes.ToolsSubscriptionStatus,
        }
    };
};
