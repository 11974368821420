import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { InfoMessage, PopOutChart } from 'sharedComponents';
import useChartConfig, { chartPlugins } from './useChartConfig';

import './style.scss';
import { EnableUniqueScaling, PopOutButton, ResetZoomButton, withLeftButtonGroup, withPopOutRow, withRightButtonGroup } from 'sharedComponents/Chart/headers';

const TrendSignalChartComponent = (props) => {

    const {
        model,
        actions,
    } = props;

    const zoomIn = (from, to) => {
        actions.zoomInToSelectedTrendKpiData(model.selectedTrendsData, from, to, props.asset);
    };
    const resetZoom = () => {
        actions.reloadAllSelectedTrendKpiData(model.selectedTrendsData, props.asset);
    };

    const graphOptions = useChartConfig(model.selectedTrendsData, model.event, model.showUtcDates, actions.removeSelectedKpiLegend);

    const CustomHeader = withPopOutRow([
        withLeftButtonGroup([EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);

    return (
        <Row className='trend-signal-chart-area'>
            <Col md={12}>
                <PopOutChart
                    hasKpi={model.selectedTrendsData.length !== 0}
                    renderNoKpi={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                        text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                    enableUniqueScaling={model.enableUniqueScaling}
                    zoomInServer={zoomIn}
                    resetServerZoom={resetZoom}
                    isZoomed={model.isZoomed}
                    setIsZoomed={actions.setIsZoomed}
                    options={graphOptions}
                    modules={chartPlugins}
                    loadingStatus={model.trendDataLoadingStatus}
                    container='op-trend-chart'
                    mainChartHeader={CustomHeader}
                />
            </Col>
        </Row>
    );
};

export default TrendSignalChartComponent;
