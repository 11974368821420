import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AlertConfigRow from './AlertConfigRow';
import AlarmConfigRow from './AlarmConfigRow';
import OffsetConfigRow from '../OffsetConfigRow';
import ButtonRow from './ButtonRow';
import SuggestionRow from './SuggestionRow';
import SliderRow from './SliderRow';
import useOffset from './useOffset';

import { isOffsetInvalid } from './helpers';


const SelectedParameter = (props) => {

    const { actions, selectedKpi, autoDetectionIntervals, resetDirtyFlag } = props;
    const { config } = selectedKpi;
    const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);

    const [calculatedMin, calculatedMax, showCalibrationError] = useOffset(props);

    return <>
        {
            selectedKpi.isCalibrationEnabled ?
                <OffsetConfigRow
                    selectedKpi={selectedKpi}
                    isOutOfRange={(value) => isOffsetInvalid(value, config)}
                    showCalibrationError={showCalibrationError}
                    setOffset={actions.setOffset}
                    justifyContentEnd={true}
                /> : null
        }
        <AlertConfigRow
            selectedKpi={selectedKpi}
            setAlert={actions.setAlert}
            setAlertNotification={actions.setAlertNotification}
            calculatedMin={calculatedMin} />
        <AlarmConfigRow
            selectedKpi={selectedKpi}
            setAlarm={actions.setAlarm}
            setAlarmNotification={actions.setAlarmNotification}
            calculatedMin={calculatedMin} />
        <SliderRow
            selectedKpi={selectedKpi}
            calculatedMin={calculatedMin}
            calculatedMax={calculatedMax}
            setAlert={actions.setAlert}
            setAlarm={actions.setAlarm}
        />
        {isSuggestionOpen && <SuggestionRow
            selectedKpi={selectedKpi}
            autoDetectionIntervals={autoDetectionIntervals}
            getCalculatedThresholds={actions.getCalculatedThresholds}
            resetHealthStatuses={actions.resetHealthStatuses}
        />}
        <ButtonRow
            resetDirtyFlag={resetDirtyFlag}
            selectedKpi={selectedKpi}
            isSuggestionOpen={isSuggestionOpen}
            autoDetectionIntervals={autoDetectionIntervals}
            setIsSuggestionOpen={setIsSuggestionOpen}
            saveSelectedAlertAlarmOffsetConfig={actions.saveSelectedAlertAlarmOffsetConfig}
        />
    </>;
};

SelectedParameter.propTypes = {
    actions: PropTypes.shape({
        getCalculatedThresholds: PropTypes.func,
        resetHealthStatuses: PropTypes.func,
        saveSelectedAlertAlarmOffsetConfig: PropTypes.func,
        setAlarm: PropTypes.func,
        setAlert: PropTypes.func,
        setOffset: PropTypes.func
    }),
    autoDetectionIntervals: PropTypes.any,
    selectedKpi: PropTypes.shape({
        config: PropTypes.object,
        isCalibrationEnabled: PropTypes.bool
    })
};

export default SelectedParameter;
