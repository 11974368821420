import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HealthParametersComponent from './HealthParametersComponent';
import { getModel } from './selectors';
import * as actions from './actions';
import { setOffset, setParamEditState, setSelectedMeasurementTypeId, saveHealthStatusEmailNotification, setEmailNotificationPayload } from '../actions';


const HealthParametersContainer = (props) => {
	return <HealthParametersComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...actions, setOffset, setParamEditState, setSelectedMeasurementTypeId, saveHealthStatusEmailNotification, setEmailNotificationPayload }, dispatch)
	};
};

const HealthParametersContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(HealthParametersContainer);

export default HealthParametersContainerConn;
