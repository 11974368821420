import { getDefaultPageRoute } from 'helpers/redirect';
import { createSelector } from 'reselect';


const getGlobalError = ({ portal }) => {
    return portal.globalError;
};

const getDefaultPage = () => {
    return getDefaultPageRoute();
};

export const getModel = createSelector([
    getGlobalError,
    getDefaultPage
], (
    globalError,
    defaultPage
) => {
    return {
        globalError,
        defaultPage
    };
});
