import { reducer as generalNotificationReducer } from '../components/SharedComponents/GeneralNotification';
import { reducer as parameterBackupReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/ParameterBackup';
import { reducer as changedParametersReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/ChangedParameterList';
import { reducer as overviewReducer } from '../components/PowertrainOverview';
import { reducer as generalFilterReducer } from '../components/GeneralFilter';
import { reducer as generalFilterEditReducer } from '../components/GeneralFilter/GeneralFilterModal/GeneralFilterEdit';
import { reducer as assetDetailsInfoHeaderReducer } from '../components/PowertrainAssetDetails/DetailedInformation/AssetDetails/Header';
import { reducer as assetNameplateImageHolderReducer } from '../components/PowertrainAssetDetails/DetailedInformation/AssetDetails/Header/AssetNameplateImageHolder';
import { reducer as eventLogReducer } from '../components/PowertrainAssetDetails/DetailedInformation/EventLog';
import { reducer as sensorPropertiesReducer } from '../components/PowertrainAssetDetails/DetailedInformation/SensorProperties';
import { reducer as EmailAlertReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/EmailAlertSettings';
import { reducer as portalReducer } from '../components/Portal';
import { reducer as powertrainAssetDetailsReducer } from '../components/PowertrainAssetDetails';
import { reducer as detailedInformationHeaderReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header';
import { reducer as operationalParametersReducer } from '../components/PowertrainAssetDetails/DetailedInformation/OperationalParameters';
import { reducer as operationalParametersReducerV2 } from '../components/PowertrainAssetDetails/DetailedInformation/OperationalParametersV2';
import { reducer as detailedInformationReducer } from '../components/PowertrainAssetDetails/DetailedInformation';
import { reducer as assetAndPowetrainFilterReducer } from '../components/PowertrainAssetDetails/DetailedInformation/AssetAndPowertrainFilter';
import { reducer as eventDetailsCMDReducer } from '../components/PowertrainAssetDetails/DetailedInformation/EventLog/EventDetails/EventDetailsCMD';
import { reducer as notificationStatusBarReducer } from '../components/PowertrainAssetDetails/DetailedInformation/NotificationStatusBar';
import { reducer as eventDetailsSSReducer } from '../components/PowertrainAssetDetails/DetailedInformation/EventLog/EventDetails/EventDetailsSS';
import { reducer as bulkUpload } from '../components/PowertrainAssetDetails/DetailedInformation/Header/BulkUpload';
import { reducer as alertAlarmOffsetConfigReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/AlertAlarmOffset';
import { reducer as muteAsset } from '../components/PowertrainAssetDetails/DetailedInformation/Header/MuteAsset';
import { reducer as userLimitsReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/UserLimits';
import { reducer as LimitConfigReducer } from '../components/PowertrainAssetDetails/DetailedInformation/LimitConfigurationBeta';
import { reducer as downloadMeasurementsReducer } from '../components/PowertrainAssetDetails/DetailedInformation/Header/DownloadMeasurements';
import { reducer as selfFaultEvaluationReducer } from '../components/PowertrainAssetDetails/DetailedInformation/EventLog/EventDetails/EventDetailsCMD/SelfFaultEvaluation';
import { reducer as crossAssetAnalysisSidepanelReducer } from '../components/PowertrainAssetDetails/CrossAssetAnalysis/CrossAssetAnalysisSidepanel';
import { reducer as eventDetailsTrendSignalReducer } from '../components/PowertrainAssetDetails/DetailedInformation/EventLog/EventDetails/EventDetailsCMD/TrendSignal';
import { reducer as chartViewReducer } from '../components/PowertrainAssetDetails/CrossAssetAnalysis/ChartView/';
import { reducer as powertrainAssetOverviewReducer } from '../components/PowertrainConditionMonitoring/PowertrainsAssetsOverview/';
import { reducer as powertrainToolsReducer } from '../components/PowertrainTools';
import { reducer as maintenanceOverviewReducer } from '../components/PowertrainConditionMonitoring/MaintenanceOverview';
import { reducer as conditionIndicesReducer } from '../components/PowertrainConditionMonitoring/ConditionIndicesOverview';
import { reducer as reportIssueModalReducer } from '../components/PowertrainOverview/Header/ReportIssueModal';
import { reducer as advancedAnalyticsReducer } from '../components/PowertrainAssetDetails/DetailedInformation/AdvancedAnalytics';
import { reducer as reportsReducer } from '../components/Reports';
import { reducer as assetConditionInsightsReducer } from '../components/PowertrainAssetDetails/DetailedInformation/ConditionInsights/ConditionInsightsReducer';
import { reducer as notificationSettingsReducer } from '../components/UserProfileSettings/NotificationSettings';

export const reducers = {
    parameterBackup: parameterBackupReducer,
    changedParameters: changedParametersReducer,
    overview: overviewReducer,
    generalFilter: generalFilterReducer,
    generalFilterEdit: generalFilterEditReducer,
    powertrainAssetDetails: powertrainAssetDetailsReducer,
    sensorProperties: sensorPropertiesReducer,
    userLimits: userLimitsReducer,
    limitConfig: LimitConfigReducer,
    assetDetailsInfoHeader: assetDetailsInfoHeaderReducer,
    eventLog: eventLogReducer,
    selfFaultEvaluation: selfFaultEvaluationReducer,
    emailAlert: EmailAlertReducer,
    muteAsset: muteAsset,
    portal: portalReducer,
    operationalParameters: operationalParametersReducer,
    operationalParametersV2: operationalParametersReducerV2,
    generalNotification: generalNotificationReducer,
    detailedInformationHeader: detailedInformationHeaderReducer,
    detailedInformation: detailedInformationReducer,
    assetAndPowetrainFilter: assetAndPowetrainFilterReducer,
    eventDetailsCMD: eventDetailsCMDReducer,
    eventDetailsSS: eventDetailsSSReducer,
    notificationStatusBar: notificationStatusBarReducer,
    bulkUpload,
    alertAlarmOffsetConfig: alertAlarmOffsetConfigReducer,
    crossAssetAnalysisSidepanel: crossAssetAnalysisSidepanelReducer,
    downloadMeasurements: downloadMeasurementsReducer,
    eventDetailsTrendSignal: eventDetailsTrendSignalReducer,
    chartView: chartViewReducer,
    powertrainAssetOverview: powertrainAssetOverviewReducer,
    powertrainTools: powertrainToolsReducer,
    maintenanceOverview: maintenanceOverviewReducer,
    conditionIndices: conditionIndicesReducer,
    advancedAnalytics: advancedAnalyticsReducer,
    reports: reportsReducer,
    reportIssueModal: reportIssueModalReducer,
    assetNameplateImage: assetNameplateImageHolderReducer,
    assetConditionInsights: assetConditionInsightsReducer,
    notificationSettings: notificationSettingsReducer
};
