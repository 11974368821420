import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { SubscriptionInfoApi } from 'api/index';
import { getApiActionCreator } from 'helpers/actionHelper';

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const setActiveTabAction = (activeTab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        activeTab
    };
};

export const getContractsAction = getApiActionCreator(actionTypes.ACTIVE_SUB_DATA);

export const getSubscriptionInfo = (assetIdParams) => {
    return (dispatch) => {
        dispatch(getContractsAction(apiCallStatus.LOADING));
        SubscriptionInfoApi.getSubscriptionList(assetIdParams)
            .then((data) => {
                dispatch(getContractsAction(apiCallStatus.SUCCESS, data));
            }, (err) => {
                dispatch(getContractsAction(apiCallStatus.ERROR, null, err));
            });
    };

};
