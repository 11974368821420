import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';
import { apiCallStatus } from 'helpers/constants';
import { GeneralDialog, ToggleButton, LoadingStatusWrapper } from 'sharedComponents';
import { getToggleOptions } from './helpers';
import CancelConfirmationDialog from './CancelConfirmationDialog';


import './styles.scss';

const AlertAlarmOffsetComponent = (props) => {
    const { t: translate } = useTranslation();
    const { actions, model } = props;

    const [showCancelConfirm, setShowCancelConfirm] = useState(false);

    const [tabOptions] = useState(getToggleOptions());

    const loadingSummary = useLoadingSummary(null, [
        model.trendKpisLoadingStatus,
        model.trendHealthStatusesLoadingStatus,
        model.autoDetectionLoadingStatus,
        model.defaultHealthStatusesLoading,
        model.saveOffsetLoadingStatus,
        model.saveAlertAlarmLoadingStatus
    ]);

    return <>
        <GeneralDialog
            show={props.show}
            close={() => {
                if (!model.isSaveAllDisabled || loadingSummary === apiCallStatus.LOADING) {
                    setShowCancelConfirm(true);
                }
                else {
                    props.close();
                }
            }}
            persistent
            title={props.title}
            id='alert-alarm-offset-modal'
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            }}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.saveAllButton'),
                disabled: model.isSaveAllDisabled || loadingSummary === apiCallStatus.LOADING,
                onClick: () => {
                    props.actions.saveAllAlarmAlertOffsetConfig()
                        .then(() => {
                            props.close();
                        });
                }
            }}
            closeButton={true}
        >
            <Container>

                <Row className='toggle-button-row'>
                    <Col>
                        <ToggleButton
                            options={tabOptions}
                            onSelected={(tab) => actions.setSelectedTab(tab)}
                            selectedValue={model.selectedTabId}
                        />
                    </Col>
                </Row>
                <LoadingStatusWrapper loadingStatus={loadingSummary} useCustomAnchor={true}>
                    <Row>
                        <Col>
                            {
                                map(tabOptions, (tabOptions) => {
                                    const Tab = tabOptions.tabComponent;
                                    return <div key={tabOptions.value} className={model.selectedTabId === tabOptions.value ? '' : 'hidden-tab'}>
                                        <Tab actions={actions} />
                                    </div>;
                                })
                            }
                        </Col>
                    </Row>
                </LoadingStatusWrapper>

            </Container>
        </GeneralDialog >
        <CancelConfirmationDialog
            show={showCancelConfirm}
            close={() => setShowCancelConfirm(false)}
            confirm={() => {
                setShowCancelConfirm(false);
                props.close();
            }
            }
        />
    </>;
};

AlertAlarmOffsetComponent.propTypes = {
    actions: PropTypes.shape({
        saveAllAlarmAlertOffsetConfig: PropTypes.func,
        setSelectedTab: PropTypes.func
    }),
    close: PropTypes.func,
    model: PropTypes.shape({
        autoDetectionLoadingStatus: PropTypes.string,
        defaultHealthStatusesLoading: PropTypes.string,
        isSaveAllDisabled: PropTypes.bool,
        saveAlertAlarmLoadingStatus: PropTypes.string,
        saveOffsetLoadingStatus: PropTypes.string,
        selectedTabId: PropTypes.string,
        trendHealthStatusesLoadingStatus: PropTypes.string,
        trendKpisLoadingStatus: PropTypes.string
    }),
    show: PropTypes.bool,
    title: PropTypes.string
};

export default AlertAlarmOffsetComponent;
