import { find, remove } from 'lodash';


export const getNotificationGroupColumns = (notificationGroups) => {
    if (notificationGroups?.length > 5) {
        const columnLength = Math.ceil(notificationGroups.length / 2);
        return [
            {
                id: 'col1',
                columnData: notificationGroups.slice(0, columnLength)
            },
            {
                id: 'col2',
                columnData: notificationGroups.slice(columnLength, notificationGroups?.length)
            }
        ];
    } else {
        return [{
            id: 'col1',
            columnData: notificationGroups
        }];
    }
};

export const isNotificationGroupMuted = (code, mutedGroups) => {
    return !!find(mutedGroups, (mutedGroup) => mutedGroup.notificationGroupCode === code);
};

export const updateMutedGroupList = (mutedGroups, selectedGroup) => {
    const found = find(mutedGroups, (mutedGroup) => {
        return mutedGroup.notificationGroupCode === selectedGroup.notificationGroupCode;
    });

    if (found) {
        return remove(mutedGroups, (mutedGroup) => {
            return mutedGroup.notificationGroupCode !== selectedGroup.notificationGroupCode;
        });
    } else {
        return mutedGroups?.length ? [...mutedGroups, selectedGroup] : [selectedGroup];
    }
};
