export const actionTypes = {
    GET_ASSET_KPI_TREND_LIST: 'assetConditionInsights/GET_ASSET_KPI_TREND_LIST',
    LOAD_ASSET_KPI_TREND_LIST: 'assetConditionInsights/LOAD_ASSET_KPI_TREND_LIST'
};

export const DRIVE_STATUS_KEY = 'DriveStatus';

export const OVER_ALL_STATUS = {
    Poor: 3,
    Tolerable: 2,
    Good: 1,
    Unknown: 0,
};

