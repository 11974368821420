import { find, map, orderBy } from 'lodash';

import { PICTURE_TYPES, PICTURE_SIZES } from './constants';

const getAssetImageDescription = (imageType, translate) => {
    switch (imageType) {
        case PICTURE_TYPES.AssetImage:
            return translate('ABB.Powertrain.Frontend.lbl-asset-image');
        case PICTURE_TYPES.NameplateImage:
            return translate('ABB.Powertrain.Frontend.lbl-nameplate-image');
        default:
            return null;
    }
};

export const getThumbnailForPictureType = (pictures, pictureType) => {
    const assetImage = find(pictures, pictureData => pictureData.pictureType === pictureType);
    if (assetImage && assetImage.picturePath) {
        const img = find(assetImage.picturePath, image => image.size === PICTURE_SIZES.Thumbnail);
        return img ? img.path : null;
    }

    return null;
};

export const formatAndSortImages = (images, translate) => {
    return map(orderBy(images, ['pictureType', 'timestamp'], ['asc', 'desc']), imageData => {
        const imageDesc = getAssetImageDescription(imageData.pictureType, translate);
        const originalImage = find(imageData.picturePath, image => image.size === PICTURE_SIZES.Original);
        const thumbnailImage = find(imageData.picturePath, image => image.size === PICTURE_SIZES.Thumbnail);

        return {
            original: originalImage && originalImage.path,
            thumbnail: thumbnailImage && thumbnailImage.path,
            description: imageDesc
        };
    });
};

// eslint-disable-next-line import/no-unused-modules
export const uploadImageToBlobStorage = (blobURl, file) => {
    return fetch(blobURl, { method: 'PUT', body: file, headers: { 'x-ms-blob-type': 'BlockBlob' } });
};

// eslint-disable-next-line import/no-unused-modules
export const checkAssetImageType = (fileName) => {
    const re = /(\.jpg|\.jpeg|\.png)$/i;
    if (!re.exec(fileName)) {
        return false;
    }
    return true;
};

