import { getAllAssets } from 'helpers/selectors';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import { filterAssetsForReportGeneration, getFilterForReportType } from './helpers';
import { getReportCapabilities } from '../selectors';

import { hasCapability, isSensorTypeMSR } from '../helpers';
import { CAPABILITIES } from 'helpers/constants';


export const getAssetsForReportType = createSelector(
    [
        getAllAssets,
        (_, filterObject) => filterObject
    ],
    (assets, filterObject) => filterAssetsForReportGeneration(assets, filterObject)
);

const getIsModalOpen = ({ reports }) => {
    return reports?.reportGenerator.isModalOpen;
};

const getCapabilitiesLoadingStatuses = ({ reports }) => {
    return Object.values(reports?.reportGenerator?.capabilities).map((capability) => capability?.loadingStatus);
};

const getReportGenerationLoadingStatus = ({ reports }) => {
    return reports?.reportGenerator?.loadingStatus;
};

const getSelectedReportType = ({ reports }) => {
    return reports?.reportGenerator?.selectedReportType;
};

const getSensorOfAssets = ({ reports }) => {
    return reports?.sensorOfAssets?.data;
};

const getAssetOptions = createSelector(
    [
        getAllAssets,
        getReportCapabilities,
        getSensorOfAssets,
        getSelectedReportType,
    ],
    (assets, reportCapabilities, sensorOfAssets, selectedReportType) => {
        return filterAssetsForReportGeneration(sortBy(assets.filter((asset) => {
            return hasCapability(asset.id, reportCapabilities, CAPABILITIES.assetInstantReport) &&
                !isSensorTypeMSR(asset, sensorOfAssets);
        }), (o) => (o?.componentName || '').toLowerCase()), getFilterForReportType(selectedReportType));
    }
);

export const getModel = createSelector([
    getIsModalOpen,
    getCapabilitiesLoadingStatuses,
    getReportGenerationLoadingStatus,
    getAssetOptions,
],
    (
        isModalOpen,
        capabilitiesLoadingStatuses,
        reportGenerationLoadingStatus,
        assetOptions,
    ) => {
        return {
            isModalOpen,
            capabilitiesLoadingStatuses,
            reportGenerationLoadingStatus,
            assetOptions,
        };
    });
