import logger from 'helpers/logger';
import { GLOBAL_ERROR_STATUS_CODES, HTTP_STATUS_GONE, HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_PRECONDITION_FAILED } from 'api/RestManager/constants';
import { SET_GLOBAL_HTTP_ERROR, TIMESERIES_LIMITS } from '../../components/Portal/constants';
import { SET_NOTIFICATION, NOTIFICATION_TYPES } from '../../components/SharedComponents/GeneralNotification/constants';
import { translate } from 'helpers/translateHelper';


export const errorHandlingMiddleware = storeAPI => next => action => {
    const error = action.error;
    if (error) {
        logger.error(error);
        if (GLOBAL_ERROR_STATUS_CODES.indexOf(error.statusCode) !== -1) {
            //TODO: Just a work arround to remove the default error for limits
            //Bug ID 369074
            if ((error.config.url ?? '').endsWith(TIMESERIES_LIMITS)) {
                return;
            }
            storeAPI.dispatch({
                type: SET_GLOBAL_HTTP_ERROR,
                globalError: error
            });
        }
        else {
            let message = error.message;
            if (error.statusCode === HTTP_STATUS_PRECONDITION_FAILED) {
                message = translate('ABB.Powertrain.Frontend.ptNotificationHttpErrorDataHasBeenChanged');
            }
            else if (error.statusCode === HTTP_STATUS_UNAUTHORIZED) {
                message = translate('ABB.Powertrain.Frontend.ptNotificationHttpErrorUnauthorized', { ErrorCode: error.statusCode });
            }
            else if (error.statusCode === HTTP_STATUS_GONE) {
                message = translate('ABB.Powertrain.Frontend.ptNotificationHttpErrorGone', { ErrorCode: error.statusCode });
            }
            storeAPI.dispatch({
                type: SET_NOTIFICATION,
                data: {
                    type: NOTIFICATION_TYPES.ERROR,
                    message: message || translate('ABB.Powertrain.Frontend.somethingWentWrongTitle'),
                    sticky: true
                }
            });
        }
    }

    return next(action);
};
