import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map, keys } from 'lodash';
import { LoadingStatusWrapper, RadioButton } from 'sharedComponents';
import NotificationTypeList from './NotificationTypeListComponent';
import { digestOptions } from '../constants';
import {
    isDigestOptionSelected,
    updateDigestOption
} from './helpers';
import './style.scss';


const digestOptionsVisible = false;

const NotificationChannelsComponent = (props) => {
    const { t: translate } = useTranslation();

    const {
        notificationChannels,
        notificationSettingsLoadingStatus,
        selectedNotificationGroups
    } = props.notificationSettings;

    const renderDigestOptions = () => {
        return notificationChannels?.summarySettings &&
            map(keys(digestOptions), (digestOptionKey) => {
                return <RadioButton
                    key={digestOptionKey}
                    id={digestOptionKey}
                    onClick={() => {
                        props.saveNotificationSettings(
                            props.organizationID,
                            updateDigestOption(notificationChannels, digestOptions[digestOptionKey]));
                    }}
                    checked={isDigestOptionSelected(notificationChannels?.summarySettings, digestOptions[digestOptionKey])}
                    label={translate(`ABB.Powertrain.Frontend.notificationsEmailDigest${digestOptions[digestOptionKey]}`)}
                />;
            });
    };

    return <LoadingStatusWrapper
        loadingStatus={notificationSettingsLoadingStatus}
    >
        <div className='notification-channels-container'>
            {digestOptionsVisible && <div className='notification-channels-section'>
                <div className='title'>{translate('ABB.Powertrain.Frontend.notificationsEmailDigestTitle')}</div>
                <div className='description'>{translate('ABB.Powertrain.Frontend.notificationsEmailDigestDescription')}</div>
                {renderDigestOptions()}
            </div>}
            <div className='notification-channels-section'>
                <div className='title'>{translate('ABB.Powertrain.Frontend.notificationsChannelInfoTitle')}</div>
                <div className='description'>{translate('ABB.Powertrain.Frontend.notificationsChannelInfoDescription')}</div>
            </div>
            <NotificationTypeList
                notificationChannels={notificationChannels}
                saveNotificationSettings={props.saveNotificationSettings}
                selectNotificationGroup={props.selectNotificationGroup}
                selectedNotificationGroups={selectedNotificationGroups}
                organizationID={props.organizationID}
            />
        </div>
    </LoadingStatusWrapper>;
};

NotificationChannelsComponent.propTypes = {
    notificationSettings: PropTypes.shape({
        notificationChannels: PropTypes.object,
        notificationSettingsLoadingStatus: PropTypes.string,
        selectedNotificationGroups: PropTypes.array
    }),
    organizationID: PropTypes.number,
    saveNotificationSettings: PropTypes.func,
    selectNotificationGroup: PropTypes.func
};

export default NotificationChannelsComponent;
