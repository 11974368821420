import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconBearing = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M16 8C14.4177 8 12.871 8.46919 11.5554 9.34824C10.2398 10.2273 9.21447 11.4767 8.60896 12.9385C8.00346
		14.4003 7.84504 16.0089 8.15372 17.5607C8.4624 19.1126 9.22433 20.538 10.3431 21.6569C11.462 22.7757 12.8874
		23.5376 14.4393 23.8463C15.9911 24.155 17.5997 23.9965 19.0615 23.391C20.5233 22.7855 21.7727 21.7602 22.6518
		20.4446C23.5308 19.129 24 17.5823 24 16C24 13.8783 23.1571 11.8434 21.6569 10.3431C20.1566 8.84285 18.1217 8 16
		8ZM16 22C14.8133 22 13.6533 21.6481 12.6666 20.9888C11.6799 20.3295 10.9108 19.3925 10.4567 18.2961C10.0026 17.1997
		9.88378 15.9933 10.1153 14.8295C10.3468 13.6656 10.9182 12.5965 11.7574 11.7574C12.5965 10.9182 13.6656 10.3468
		14.8295 10.1153C15.9933 9.88378 17.1997 10.0026 18.2961 10.4567C19.3925 10.9108 20.3295 11.6799 20.9888 12.6666C21.6481
		13.6533 22 14.8133 22 16C22 17.5913 21.3679 19.1174 20.2426 20.2426C19.1174 21.3679 17.5913 22 16 22ZM28 25V16C27.9972
		13.5208 27.2265 11.1033 25.794 9.07991C24.3615 7.05647 22.3374 5.5264 20 4.7V3H12V4.7C10.6332 5.17919 9.36567 5.90447
		8.26 6.84L4.71 3.29L3.29 4.71L6.84 8.26C5.00436 10.421 3.99764 13.1646 4 16V25H2V29H30V25H28ZM6 16C6 13.3478 7.05357
		10.8043 8.92893 8.92893C10.8043 7.05357 13.3478 6 16 6C18.6522 6 21.1957 7.05357 23.0711 8.92893C24.9464 10.8043 26
		13.3478 26 16V25H6V16ZM19.47 14.12L20.53 15.18L17.34 18.42L14.67 15.72L12.53 17.88L11.47 16.82L14.67 13.58L17.34 16.28L19.47 14.12Z' fill={color} />
	</svg>;
};

IconBearing.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconBearing;
