import { getTrendKPIsForAsset } from '../../commonActions';
import { getHealthStatuses } from '../../OperationalParameters/SingleChartView/Trend/TrendKPIList/actions';
import { actionTypes, NOTIFICATION_CHANNEL_ID } from './constants';
import { getSaveOffsetRequestList } from './helpers';
import { getHealthStatusEmailNotification, saveAlertAlarmConfig, saveOffset, updateHealthStatusEmailNotification, deleteHealthStatusEmailNotification } from './sharedActions';

export const setSelectedMeasurementTypeId = (selectedMeasurementTypeID) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_SELECTED_MEASUREMENT_TYPE_ID, selectedMeasurementTypeID });
	};
};

export const setSelectedTab = (selectedTabId) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_SELECTED_TAB, selectedTabId });
	};
};

export const cloneTrendKpis = () => {
	return (dispatch, getState) => {
		dispatch({ type: actionTypes.CLONE_TREND_KPIS, trendKPIs: getState().operationalParameters.availableKPIs.trend.data });
	};
};

export const cloneHealthStatuses = () => {
	return (dispatch, getState) => {
		dispatch({ type: actionTypes.CLONE_HEALTH_STATUSES, trendHealthStatuses: getState().operationalParameters.trendHealthStatuses.data });
	};
};


export const clearStore = () => {
	return (dispatch) => {
		dispatch({ type: actionTypes.CLEAR_STORE });
	};
};

export const setOffset = (measurementTypeID, value) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_OFFSET_VALUE, measurementTypeID, value });
	};
};

export const setParamEditState = (editState) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_PARAM_EDIT_STATE, editState });
	};
};

export const setEmailNotificationPayload = (payload) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_EMAIL_NOTIFICATION_PAYLOAD, payload });
	};
};

export const saveHealthStatusEmailNotification = (payload) => {
	return (dispatch) => {
		return dispatch(updateHealthStatusEmailNotification(payload));
	};
};

// GET EMAIL NOTIFICATION API ACTION
export const getHealthStatusEmailNotificationAction = (payload) => {
	return (dispatch) => {
		return dispatch(getHealthStatusEmailNotification(payload));
	};
};

// DELETE EMAIL NOTIFICATION API ACTION
export const deleteHealthStatusEmailNotificationAction = (payload) => {
	return (dispatch) => {
		return dispatch(deleteHealthStatusEmailNotification(payload));
	};
};

export const saveAllAlarmAlertOffsetConfig = () => {
	return (dispatch, getState) => {
		const state = getState();
		const asset = state.powertrainAssetDetails.selectedAsset;
		const trendHealthStatuses = state.alertAlarmOffsetConfig.trendHealthStatuses;
		const saveOffsetsReqList = getSaveOffsetRequestList(asset.componentID, state.alertAlarmOffsetConfig.trendKPIs);
		const emailNotificationPayload = state.alertAlarmOffsetConfig.emailNotificationPayload;
		return dispatch(saveOffset(asset, saveOffsetsReqList))
			.then(() => {
				return dispatch(saveAlertAlarmConfig(asset, trendHealthStatuses));
			}).then(() => {
				return dispatch(getTrendKPIsForAsset(asset));
			}).then(() => {
				return dispatch(getHealthStatuses(asset));
			})
			.then(() => {
				if (emailNotificationPayload && emailNotificationPayload.length) {
					emailNotificationPayload.map((en) => {
						if (en?.value) {
							const payload = {
								notificationTypeID: en.notificationTypeID,
								channelID: NOTIFICATION_CHANNEL_ID.email,
								payload: { assetIDList: [asset?.smSeIdentfier] }
							};
							return dispatch(saveHealthStatusEmailNotification(payload));
						} else {
							const payload = {
								notificationTypeID: en.notificationTypeID,
								channelID: NOTIFICATION_CHANNEL_ID.email,
								payload: [asset?.smSeIdentfier]
							};
							return dispatch(deleteHealthStatusEmailNotificationAction(payload));
						}
					});
				}
			});
	};
};
