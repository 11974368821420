import { forEach, keys, indexOf, find } from 'lodash';
import { digestOptions } from '../constants';


export const isDigestOptionSelected = (digestOptionSettings, option) => {
    if (option === digestOptions.NONE) {
        return !digestOptionSettings[digestOptions.DAILY] &&
            !digestOptionSettings[digestOptions.WEEKLY];
    }
    return digestOptionSettings[option];
};

export const updateDigestOption = (notificationSettings, selectedDigestOption) => {
    const summarySettingsUpdate = {
        [digestOptions.DAILY]: false,
        [digestOptions.WEEKLY]: false,
    };

    if (selectedDigestOption !== digestOptions.NONE) {
        summarySettingsUpdate[selectedDigestOption] = true;
    }

    return {
        summarySettings: {
            ...notificationSettings.summarySettings,
            ...summarySettingsUpdate
        },
        notificationGroups: []
    };
};

export const isNotificationGroupSelected = (selectedNotificationGroups, groupCode) => {
    return indexOf(selectedNotificationGroups, groupCode) > -1;
};

const getChannelSetting = (notificationSettings, notificationGroup, notificationType, channel) => {
    const group = find(notificationSettings.notificationGroups, (notificationSetting) => {
        return notificationSetting.notificationGroupCode === notificationGroup;
    });

    const type = group && find(group.notificationTypes, (notificationTypeSetting) => {
        return notificationTypeSetting.notificationTypeCode === notificationType;
    });

    const channelToUpdate = type && find(type.channels, (channelSetting) => {
        return channelSetting.channelCode === channel;
    });

    return channelToUpdate?.enabled;
};

export const updateChannelSettingForNotificationType = (
    notificationSettings,
    notificationGroup,
    notificationType,
    channel
) => {
    const channelSettingUpdate = {
        channelCode: channel,
        enabled: !getChannelSetting(notificationSettings, notificationGroup, notificationType, channel)
    };
    return {
        summarySettings: notificationSettings.summarySettings,
        notificationGroups: [{
            notificationGroupCode: notificationGroup,
            notificationTypes: [{
                notificationTypeCode: notificationType,
                channels: [channelSettingUpdate]
            }]
        }]
    };
};

export const updateTooltipStates = (notificationGroupVisibility, selectedGroup) => {
    forEach(keys(notificationGroupVisibility), (groupKey) => {
        if (groupKey === selectedGroup) {
            notificationGroupVisibility[groupKey] = !notificationGroupVisibility[groupKey];
        } else {
            notificationGroupVisibility[groupKey] = false;
        }
    });
    return notificationGroupVisibility;
};

export const getInitialTooltipStates = (notificationGroups) => {
    const notificationGroupsObj = {};
    forEach(notificationGroups, (notificationGroup) => {
        notificationGroupsObj[notificationGroup] = false;
    });
    return notificationGroupsObj;
};
