import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { popupTypes } from 'helpers/constants';
import { GeneralDialog } from 'sharedComponents';

import './styles.scss';

const CancelConfirmationDialog = (props) => {

    const { t: translate } = useTranslation();

    return <GeneralDialog
        modalClassName='dialog-second-level'
        backdropClassName='dialog-second-level-backdrop'
        notificationType={popupTypes.WARNING}
        show={props.show}
        close={props.close}
        title={translate('ABB.Powertrain.Frontend.unsavedChangesWarningText')}
        cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.cancelLabel') }}
        closeButton={true}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.yesLabel'),
            onClick: props.confirm
        }}
    >
    </GeneralDialog>;
};

CancelConfirmationDialog.propTypes = {
    close: PropTypes.any,
    confirm: PropTypes.any,
    show: PropTypes.any
};

export default CancelConfirmationDialog;
