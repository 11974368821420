import React from 'react';


const IconPlus = () => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
        <path d='M14 7H9V2H7V7H2V9H7V14H9V9H14V7Z' fill='currentColor' />
    </svg>;
};

export default IconPlus;
