import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';
import { EventTypes, EventTypeAssetApplicability } from '../constants';
import Tippy from '@tippy.js/react';
import { IconInfo } from 'svgIcons/MotionPortalIcons';

const EventTypeFilter = ({
    selectedStates,
    selectState,
    assetType
}) => {
    const { t: translate } = useTranslation();
    const eventTypes = EventTypes.filter(
        eventType => eventType.applicableTo === assetType ||
            eventType.applicableTo === EventTypeAssetApplicability.ALL);

    const renderEventTypeLabels = () => {
        return map(eventTypes, (eventType, index) =>
            <Row key={index}>
                <Col md='auto' className='pr-1'>
                    {eventType.icon}
                </Col>
                <Col className='event-list-filter-tippy-label pl-1'>
                    {translate(eventType.label)}
                </Col>
            </Row>
        );
    };

    const renderTooltipContent = () => {
        return <Container>
            <Row className='event-list-filter-tippy-title'>
                <Col>{translate('ABB.Powertrain.Frontend.eventListFilterEventTypesLbl')}</Col>
            </Row>
            {renderEventTypeLabels()}
        </Container>;
    };

    const renderEventTypeButtons = () => {
        return map(eventTypes, eventType => {
            return <button
                className={'event-type' + (includes(selectedStates, eventType.typeID) ? ' selected' : '')}
                onClick={() => selectState(eventType.typeID)}
                key={eventType.typeID}
            >
                {eventType.icon}
            </button>;
        });
    };

    return (
        <div className='event-type-filter'>
            <div>{translate('ABB.Powertrain.Frontend.eventListFilterEventTypeLbl')}</div>
            <div className='event-types'>
                {renderEventTypeButtons()}
                <Tippy
                    content={renderTooltipContent()}
                    className='event-type-filter-info-tippy'
                    interactive={true}
                    animation='scale'
                    theme='light-border'
                    trigger='click'
                    placement='bottom'
                    popperOptions={{
                        modifiers: {
                            preventOverflow: {
                                escapeWithReference: true
                            }
                        }
                    }}
                >
                    <div className='info-icon-wrapper'>
                        <IconInfo color={'black'} />
                    </div>
                </Tippy>
            </div>
        </div>
    );
};

EventTypeFilter.propTypes = {
    selectedStates: PropTypes.array,
    selectState: PropTypes.func,
    assetType: PropTypes.number
};

export default EventTypeFilter;
