import React, { useEffect, useState } from 'react';
import colors from 'theme/_colors.scss';
import { translate } from 'helpers/translateHelper';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { convertDailyAgingStressData } from './helpers';
import { renderToStaticMarkup, renderToString } from 'react-dom/server';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import DriveStatusChartToolTipComponent from './DriveStatusChartToolTipComponent';


// eslint-disable-next-line import/no-unused-modules
export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.EXPORTING];


const useChartConfig = (chartData) => {
    const [options, setOptions] = useState({});
    useEffect(() => {
        const { data, xUnit, yUnit, categories } = convertDailyAgingStressData(chartData);
        const columnWidth = 35;
        const chartWidth = data ? Math.max(data?.length * (columnWidth + columnWidth / 5), 80) : undefined;

        setOptions({
            resizable: {
                enabled: true
            },
            chart: {
                height: 180,
                type: 'column',
                scrollablePlotArea: {
                    opacity: 0,
                    minWidth: chartWidth + 10,
                    scrollPositionX: chartWidth + 10
                },
                plotBackgroundColor: null,
                plotShadow: false,
                spacingTop: 70,
                events: {
                    beforePrint: function () {
                        this.exportSVGElements[0].hide();
                    },
                    afterPrint: function () {
                        this.exportSVGElements[0].show();
                    }
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                scrollbar: {
                    enabled: true,
                },
                categories,
                labels: {
                    rotation: 50,
                    formatter: function () {
                        if (isNaN(this.value)) {
                            return this.value;
                        } else {
                            return null;
                        }
                    },
                    color: '#000000',
                    style: {
                        fontWeight: 'bold',
                        fontColor: '#000000'
                    }
                },
                title: {
                    text: xUnit,
                },
                id: 'xAxis',
                lineColor: '#000000',
                lineWidth: 2,
                tickPixelInterval: 25,
                tickColor: '#000000',
                tickWidth: 2
            },
            yAxis: {
                gridLineColor: 'transparent',
                title: {
                    text: yUnit,
                },
                labels: { enabled: false },
                id: 'yAxis',
            },
            plotOptions: {
                series: {
                    pointWidth: columnWidth,
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0,
                    shadow: false
                }
            },
            lang: {
                noData: renderToStaticMarkup(<ChartNoDataText
                    title={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataDailyAgingStress')}
                    text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuideDailyAgingStress')} />),
                printChart: translate('ABB.Powertrain.Frontend.printChart'),
                downloadPNG: translate('ABB.Powertrain.Frontend.saveAsImage'),
            },
            noData: {
                useHTML: true
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'white',
                borderWidth: 0,
                shadow: false,
                hideDelay: 0.05,
                formatter: function () {
                    return renderToString(
                        <DriveStatusChartToolTipComponent
                            timeStamp={this.point?.options?.timestamp}
                            value={this.point?.options}
                        />
                    );
                }
            },
            legend: { enabled: false },
            series: [{
                data,
                states: {
                    hover: {
                        brightness: 0.2
                    }
                },
            }],
            credits: {
                enabled: false
            },
            navigation: {
                theme: {
                    states: {
                        hover: {
                            fill: colors.white,
                            stroke: 'none'
                        },
                        select: {
                            fill: colors.white,
                            select: 'none'
                        }
                    }
                },
                buttonOptions: {
                    align: 'right'
                },
                menuStyle: {
                    borderColor: '#fff',
                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                    borderRadius: '6px'
                },
                menuItemStyle: {
                    padding: '6px 12px',
                    fontSize: '14px'
                },
                menuItemHoverStyle: {
                    backgroundColor: '#ebebeb',
                    color: '#1f1f1f'
                }
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: ['downloadPNG', 'printChart'],
                        verticalAlign: 'top',
                        x: 0,
                        y: -70
                    }
                }
            }
        });

    }, [chartData]);

    return options;
};

export default useChartConfig;
