import { useLayoutEffect, useState } from 'react';


const useWindowSize = (w) => {
	const [size, setSize] = useState([0, 0]);
	const win = w || window;
	useLayoutEffect(() => {
		function updateSize() {
			setSize([win.innerWidth, win.innerHeight]);
		}
		win.addEventListener('resize', updateSize);
		updateSize();
		return () => win.removeEventListener('resize', updateSize);
	}, []);
	return size;
};

export default useWindowSize;
