import cookie from 'react-cookies';

const cookieHelper = {

	isJsonObject: (value) => {
		try {
			JSON.parse(value);
		} catch (e) {
			return false;
		}
		return true;
	},

	getCookie: (name) => {
		return cookie.load(name);
	},

	setCookie: (name, value, expires) => {
		value = cookieHelper.isJsonObject ? value : JSON.stringify(value);
		const expireDate = new Date();
		expireDate.setMonth(expireDate.getMonth() + 1);

		return cookie.save(name, value,
			{
				path: '/',
				expires: expires ? expires : expireDate,
				secure: true
			});
	},

	removeCookie: (name) => {
		return cookie.remove(name);
	},
};

export default cookieHelper;
