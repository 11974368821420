import { StateID } from 'helpers/constants';

export const actionTypes = {
    SET_ACTIVE_TAB: 'powertrainAssetsOverview/SET_ACTIVE_TAB',
    TOGGLE_CONDITION_STATUS_FILTER: 'powertrainAssetsOverview/TOGGLE_CONDITION_STATUS_FILTER',
    SET_ASSET_TYPES_FILTER: 'powertrainAssetsOverview/SET_ASSET_TYPES_FILTER',
    SET_SEARCHBOX_FILTER: 'powertrainAssetsOverview/SET_SEARCHBOX_FILTER',
    CLEAR_STORE: 'powertrainAssetsOverview/CLEAR_STORE'
};

export const overviewCardsStatusOrder = [
    StateID.POOR,
    StateID.TOLERABLE,
    StateID.GOOD,
    StateID.UNKNOWN,
    StateID.NOT_APPLICABLE,
];
