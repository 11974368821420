export const actionTypes = {
    GET_ASSET_HISTOGRAM_KPIS: 'operationalParameters/GET_ASSET_HISTOGRAM_KPIS',
    GET_HISTOGRAM: 'operationalParameters/GET_HISTOGRAM',
    SET_HISTOGRAM_KPI: 'operationalParameters/SET_HISTOGRAM_KPI',
    REMOVE_TREND_KPI_SELECTION_LEGEND_HISTOGRAM: 'operationalParameters/REMOVE_TREND_KPI_SELECTION_LEGEND_HISTOGRAM',
};


export const labelBounds = {
    Both: 'Both',
    LowerThanUpper: 'LowerThanUpper',
    GreaterThanLower: 'GreaterThanLower'
};
