import { createSelector } from 'reselect';
import {
    filterAddedAssetsByType,
    filterAvailableAssetsByType,
    getAssetListIntersect
} from './helpers';


const getSites = ({ generalFilter }) => {
    return generalFilter.sites?.data ? generalFilter.sites.data.map((site) => {
        return {
            siteId: site.siteID,
            siteName: site.siteName
        };
    }) : [];
};

const getSitesLoadingStatus = ({ generalFilter }) => {
    return generalFilter.sites?.loadingStatus;
};

const getPowertrainID = ({ powertrainTools }) => {
    return powertrainTools.wizard.powertrainID;
};

const getPowertrainName = ({ powertrainTools }) => {
    return powertrainTools.wizard.powertrainName;
};

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

const getSelectedSite = ({ powertrainTools }) => {
    return powertrainTools.wizard?.selectedSite;
};

const getSelectedAssetTypeAvailableSection = ({ powertrainTools }) => {
    return powertrainTools.wizard.selectedAssetTypeAvailableSection;
};

const getSelectedAssetTypeAddedSection = ({ powertrainTools }) => {
    return powertrainTools.wizard.selectedAssetTypeAddedSection;
};

const getAssets = ({ powertrainTools, overview }) => {
    return getAssetListIntersect(
        powertrainTools?.wizard?.availableAssets?.data,
        overview?.assets?.data
    );
};

const getAssetsLoadingStatus = ({ powertrainTools }) => {
    return powertrainTools.wizard?.availableAssets?.loadingStatus;
};

const getAvailableAssetsFiltered = createSelector([
    ({ powertrainTools, overview }) => getAssetListIntersect(
        powertrainTools?.wizard?.availableAssets?.data,
        overview?.assets?.data
    ),
    ({ powertrainTools }) => powertrainTools.wizard.addedAssets,
    ({ powertrainTools }) => powertrainTools.wizard.selectedAssetTypeAvailableSection
], (
    assets,
    addedAssets,
    assetType
) => {
    return assets && filterAvailableAssetsByType(assets, addedAssets, assetType);
});

const getAssetFilterAvailableSection = ({ powertrainTools }) => {
    return powertrainTools.wizard.assetFilterAvailableSection;
};

const getAddedAssets = ({ powertrainTools }) => {
    return powertrainTools.wizard.addedAssets;
};

const getAddedAssetsChangedFlag = ({ powertrainTools }) => {
    return powertrainTools.wizard.addedAssetsChangedFlag;
};

const getPowertrainDetailsChangedFlag = ({ powertrainTools }) => {
    return powertrainTools.wizard.powertrainDetailsChangedFlag;
};

const getAddedAssetsFiltered = createSelector([
    ({ powertrainTools }) => powertrainTools.wizard.addedAssets,
    ({ powertrainTools }) => powertrainTools.wizard.selectedAssetTypeAddedSection,
    ({ powertrainTools }) => powertrainTools.wizard.assetFilterAddedSection,
], (
    assets,
    assetType,
    searchCriteria,

) => {
    return assets ? filterAddedAssetsByType(assets, assetType, searchCriteria) : [];
});

const getAssetFilterAddedSection = ({ powertrainTools }) => {
    return powertrainTools.wizard.assetFilterAddedSection;
};

const getPowertrainWizardStepStates = ({ powertrainTools }) => {
    return powertrainTools.wizard.powertrainWizardStepStates;
};

const getPowertrainWizardActiveStep = ({ powertrainTools }) => {
    return powertrainTools.wizard.activeStep;
};

const getEditModeFlag = ({ powertrainTools }) => {
    return powertrainTools.wizard.isEditMode;
};

const getAvailableAssetsCurrentPage = ({ powertrainTools }) => {
    return powertrainTools.wizard?.availableAssets?.pageNumber;
};

const getAvailableAssetsHasMore = ({ powertrainTools }) => {
    return powertrainTools.wizard?.availableAssets?.hasMore;
};

export const getModel = createSelector(
    [
        getSites,
        getSitesLoadingStatus,
        getPowertrainID,
        getPowertrainName,
        getSelectedOrganization,
        getSelectedSite,
        getSelectedAssetTypeAvailableSection,
        getSelectedAssetTypeAddedSection,
        getAssets,
        getAssetsLoadingStatus,
        getAvailableAssetsFiltered,
        getAssetFilterAvailableSection,
        getAddedAssets,
        getAddedAssetsChangedFlag,
        getPowertrainDetailsChangedFlag,
        getAddedAssetsFiltered,
        getAssetFilterAddedSection,
        getPowertrainWizardStepStates,
        getPowertrainWizardActiveStep,
        getEditModeFlag,
        getAvailableAssetsCurrentPage,
        getAvailableAssetsHasMore
    ],
    (
        sites,
        sitesLoadingStatus,
        powertrainID,
        powertrainName,
        selectedOrganization,
        selectedSite,
        selectedAssetTypeAvailableSection,
        selectedAssetTypeAddedSection,
        assets,
        assetsLoadingStatus,
        availableAssets,
        assetFilterAvailableSection,
        addedAssets,
        addedAssetsChangedFlag,
        powertrainDetailsChangedFlag,
        addedAssetsFiltered,
        assetFilterAddedSection,
        powertrainWizardStepStates,
        activeStep,
        isEditMode,
        availableAssetsCurrentPage,
        availableAssetsHasMore
    ) => {
        return {
            sites,
            sitesLoadingStatus,
            powertrainID,
            powertrainName,
            selectedOrganization,
            selectedSite,
            selectedAssetTypeAvailableSection,
            selectedAssetTypeAddedSection,
            assets,
            assetsLoadingStatus,
            availableAssets,
            assetFilterAvailableSection,
            addedAssets,
            addedAssetsChangedFlag,
            powertrainDetailsChangedFlag,
            addedAssetsFiltered,
            assetFilterAddedSection,
            powertrainWizardStepStates,
            activeStep,
            isEditMode,
            availableAssetsCurrentPage,
            availableAssetsHasMore
        };
    }
);
