import { createSelector } from 'reselect';
import { getActiveTab } from '../selectors';

const getFFTData = ({ operationalParameters }) => {
    return operationalParameters?.FFT?.data;
};

const hasRawData = ({ operationalParameters }) => {
    return operationalParameters?.FFT?.data && Object.hasOwn(operationalParameters.FFT.data, 'dataValues')
        && operationalParameters.FFT.data.dataValues.length !== 0;
};

const getFFTDataLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters?.FFT?.loadingStatus;
};

const getSensortypeID = ({ detailedInformation }) => {
    return detailedInformation?.assetDetails?.data?.details?.sensorTypeID;
};

const getSpeed = ({ detailedInformation }) => {
    const nameplateData = detailedInformation.assetDetails?.data?.sections.find(
        (section) => section.sectionKey === 'NameplateData')?.data;
    const nameplateSpeed = nameplateData?.find(
        (entry) => entry.nominalPropertyKey === 'Nameplate_Speed'
    )?.nominalPropertyValue;
    const nameplateFrequency = nameplateData?.find(
        (entry) => entry.nominalPropertyKey === 'Nameplate_LineFrequency'
    )?.nominalPropertyValue;
    return nameplateFrequency && nameplateSpeed && nameplateSpeed / nameplateFrequency;
};

export const getModel = createSelector([
    getActiveTab,
    getFFTData,
    getFFTDataLoadingStatus,
    hasRawData,
    getSpeed,
    getSensortypeID
], (
    activeTab,
    fftData,
    fftDataLoadingStatus,
    hasRawData,
    speed,
    sensorTypeID
) => {
    return {
        activeTab,
        fftData,
        fftDataLoadingStatus,
        hasRawData,
        speed,
        sensorTypeID
    };
});
