import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BulkUploadComponent from './BulkUploadComponent';
import { getModel } from './selectors';
import * as actions from './actions';

const BulkUploadContainer = (props) => {
	return <BulkUploadComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const BulkUploadContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(BulkUploadContainer);

export default BulkUploadContainerConn;
