import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconDiagnosticEventIndicator = ({ width = 16, height = 16, showInnerIcon = true }) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 32 25'>
		<g>
			<path fill={colors.viking} d='M16 0l16 25H0l6.766-10.571z' transform='translate(-272 -677) rotate(180 152 351)' />
			{
				showInnerIcon && <g>
					<path fill={colors.white} d='M199.458 297.178l4.55-.021v-7.383a4.714 4.714 0 0 0-.585-8.568.4.4 0 0 0-.543.376v2.923l-1.1 1.1-1.1-1.1v-2.923a.4.4 0 0 0-.542-.378 4.715 4.715 0 0 0-.606 8.567z' transform='translate(-272 -677) translate(283 678.533) translate(-197.072 -281.177)' />
				</g>
			}
		</g>
	</svg>;
};

IconDiagnosticEventIndicator.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showInnerIcon: PropTypes.bool
};

export default IconDiagnosticEventIndicator;
