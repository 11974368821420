import React from 'react';

import AlertAlarmButton from './AlertAlarmButton';
import UserLimitsButton from './UserLimitsButton';

const ConfigButtonWrapper = (props) => {
    const { capability } = props;
    if(capability.isSmartSensor()) {
        return <AlertAlarmButton {...props}/>;
    }
    else {
        return <UserLimitsButton {...props}/>;
    }
};

export default ConfigButtonWrapper;
