import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LimitConfigComponent from './LimitConfigComponent';
import { getModel } from './selectors';
import * as actions from './actions';

import './style.scss';

const LimitConfigContainer = (props) => {

    return (
        <LimitConfigComponent {...props} />
    );
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return { model };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const LimiConfigContainerConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(LimitConfigContainer);

export default LimiConfigContainerConnect;
