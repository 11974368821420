import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconNameplate = ({ width = 48, height = 48 }) => {
	const st0 = { fill: 'none', stroke: colors.cinder, strokeWidth: 3, strokeLinejoin: 'bevel' };

	return <svg id='nameplate_icon' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 48 48'>
		<g>
			<g>
				<line x1='37' y1='22.9' x2='10.8' y2='22.8' {...st0} />
				<line x1='39.6' y1='15.5' x2='8.5' y2='15.5' {...st0} />
				<line x1='34.4' y1='29.8' x2='13.5' y2='30' {...st0} />
			</g>
			<rect x='2.6' y='6.1' width='42.6' height='33' {...st0} />
		</g>
	</svg>;
};

IconNameplate.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
};

export default IconNameplate;

