import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BulkUploadFileSectionComponent from './BulkUploadFileSectionComponent';
import { getModel } from './selectors';
import * as bulkUploadFileSectionActions from './actions';


const BulkUploadFileSectionContainer = (props) => {
	return <BulkUploadFileSectionComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { ...model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		fileSelection: bindActionCreators(bulkUploadFileSectionActions, dispatch)
	};
};

const BulkUploadFileSectionContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(BulkUploadFileSectionContainer);

export default BulkUploadFileSectionContainerConn;
