import { sanitize } from 'dompurify';
import localStorageHelper from 'helpers/localStorageHelper';
import cookieHelper from 'helpers/cookieHelper';
import { defaultLandingPage } from 'routes';


const getCurrentMessage = (messages) => {
    return messages
        .filter((message) => {

            const startDate = message['startDate'];
            const endDate = message['endDate'];
            const messagePath = message['messagePath'];

            if (!startDate || !endDate || !messagePath) {
                return false;
            }

            const start = new Date(startDate);
            const end = new Date(endDate);
            const now = new Date();

            return start <= now && now <= end;
        })
        .sort((a, b) => { return a.createdOn > b.createdOn ? -1 : 1; })[0];
};

export const mergeUserInfo = (userInfo, userInfoMicroService) => {
    return {
        organizations: userInfo.organizations,
        isSuperAdmin: userInfo.isSuperAdmin,
        adminRoleID: userInfo.adminRoleID,
        roles: userInfo.roles,
        isEULAAccepted: userInfo.isEULAAccepted,
        refreshToken: userInfo.refreshToken,
        userID: userInfo.userID,

        languageCode: userInfoMicroService.language.code,
        email: userInfoMicroService.user.email,
        firstName: userInfoMicroService.user.firstName,
        lastName: userInfoMicroService.user.lastName,
        measurementUnitGroups: userInfoMicroService.measurementUnitGroups,
        landingPageId: userInfoMicroService.landingPage.id,
        isAdminFromCIAM: userInfoMicroService.isAdmin,
        //Should be removed after the full switch
        ciamID: userInfoMicroService.user.id
    };
};

const getBroadcastedMessage = ({ broadcastMessages }) => {
    if (broadcastMessages && broadcastMessages.length) {

        // Get the latest message
        const currentMessage = getCurrentMessage(broadcastMessages);
        return {
            broadcastMessageID: currentMessage.broadcastMessageID,
            messagePath: currentMessage.messagePath
        };
    }
};

const setLanguageCookie = ({ language }) => {
    const languageCode = language ? language.code : 'en';

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 3);

    cookieHelper.setCookie('userLanguage', `c=${languageCode}|uic${languageCode}`, expirationDate);
};

export const setPersistentUserInfo = (userInfo, userInfoMicroService) => {
    if (userInfo) {
        localStorageHelper.setItem(`broadcastedMessage_${userInfo.userID}`, getBroadcastedMessage(userInfo));
        localStorageHelper.setItem('user', mergeUserInfo(userInfo, userInfoMicroService));
        localStorageHelper.setItem('loginTimestamp', new Date().getTime());

        setLanguageCookie(userInfoMicroService);
    }
};

export const storeAuthTokens = (authTokens) => {
    localStorageHelper.setItem('authTokens', authTokens);
};

// Getting return url

const popReturnUrl = () => {
    const returnUrl = localStorageHelper.getItem('returnUrl');
    returnUrl && localStorageHelper.removeItem('returnUrl');
    return returnUrl;
};

const shouldReturnToLandingPage = (returnUrl) => {
    return !returnUrl
        || returnUrl === '/'
        || returnUrl.toLowerCase() === '/home'
        || returnUrl.toLowerCase() === 'home/index';
};

export const getRedirectUrl = (user, environmentalProperties) => {

    if (user && environmentalProperties) {
        const returnUrl = popReturnUrl();

        if (shouldReturnToLandingPage(returnUrl)) {
            return defaultLandingPage;
        }

        return sanitize(returnUrl);
    }
    return null;
};

