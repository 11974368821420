// eslint-disable-next-line import/namespace
import { actionTypes } from './constants';
import storageHelper from 'helpers/localStorageHelper';
import { apiCallStatus, NOTIFICATION_TYPES, userCapabilities } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { CapabilityApi } from 'api';
import { notify } from 'sharedComponents/GeneralNotification';
import logger from 'helpers/logger';
import { push } from 'redux-first-history';
import authHelper from 'helpers/authHelper';
import { translate } from 'helpers/translateHelper';
import { history } from '../../boot-client';
/* ------------------------------------
* Actions
* ------------------------------------ */

const loadUserAction = getApiActionCreator(actionTypes.LOAD_USER);
const getUserCapabilitiesAction = getApiActionCreator(actionTypes.GET_USER_CAPABILITIES);

/* ------------------------------------
* Actions Creators
* ------------------------------------ */

export const redirect = (path) => {
    return (dispatch) => {
        dispatch(push(path));
    };
};

export const getUser = () => {
    return (dispatch, getState) => {
        let user = getState().overview.user.data;
        if (!user) {
            user = storageHelper.getItem('user');
            if (user) {
                dispatch(loadUserAction(apiCallStatus.SUCCESS, user));
            }
            else {
                dispatch(loadUserAction(apiCallStatus.ERROR, null, new Error('User information not found')));
            }
        }
    };
};

/**
 * Checks token expiration status and force logs out user if any token is expired
 * Called when path changes
 *
 * @returns dispatch || void
 */
export const checkTokenExpiration = () => {
    return (dispatch, getState) => {
        const currentRoute = history.location.pathname;

        //User should be logged out without notification if he has no tokens stored.
        if (!authHelper.getTokensData()) {
            authHelper.forceLogoutUser(getState);
            authHelper.redirectToLogin(currentRoute);
            return;
        }
        //User should be logged out and notified if his tokens are no longer valid
        else if (!authHelper.areAllTokensValid()) {
            authHelper.forceLogoutUser(getState);
            authHelper.redirectToLogin(currentRoute);
            return dispatch({
                type: actionTypes.SET_NOTIFICATION,
                data: {
                    type: NOTIFICATION_TYPES.INFO,
                    message: translate('ABB.Powertrain.Frontend.ptNotificationForceLoggedOut'),
                    sticky: true
                }
            });
        }
        return Promise.resolve({ type: actionTypes.TOKEN_IS_VALID });
    };
};

export const getUserCapabilities = () => {
    return (dispatch) => {
        CapabilityApi.checkCapabilities(Object.values(userCapabilities)).then((userCapabilitiesInfo) => {
            dispatch(getUserCapabilitiesAction(apiCallStatus.SUCCESS, userCapabilitiesInfo));
        }).catch((error) => {
            dispatch(getUserCapabilitiesAction(apiCallStatus.SUCCESS, null)); //to not stop the basic UI view
            notify(translate('ABB.Powertrain.Frontend.userCapabilityError'), NOTIFICATION_TYPES.ERROR, true);
            logger.error(error?.message);
        });
    };
};
