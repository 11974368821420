import { AssetLabelsApi } from 'api';
import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';


const getAssetLabelsAction = getApiActionCreator(actionTypes.LOAD_ASSET_LABELS);
const getSuggestedAssetLabelsAction = getApiActionCreator(actionTypes.LOAD_SUGGESTED_ASSET_LABELS);
const addLabelToAssetAction = getApiActionCreator(actionTypes.ADD_LABEL_TO_ASSET);
const removeLabelFromAssetAction = getApiActionCreator(actionTypes.REMOVE_LABEL_FROM_ASSET);

export const getAssetLabels = (assetID) => {
	return (dispatch) => {
		dispatch(getAssetLabelsAction(apiCallStatus.LOADING));
		AssetLabelsApi.getAssetTags(assetID)
			.then((labels) => {
				dispatch(getAssetLabelsAction(apiCallStatus.SUCCESS, labels));
			}, (err) => {
				dispatch(getAssetLabelsAction(apiCallStatus.ERROR, [], err));
			});
	};
};

export const getSuggestedAssetLabels = (organizationID) => {
	return (dispatch) => {
		dispatch(getSuggestedAssetLabelsAction(apiCallStatus.LOADING));
		AssetLabelsApi.getSuggestedAssetTags(organizationID)
			.then((suggestedLabels) => {
				dispatch(getSuggestedAssetLabelsAction(apiCallStatus.SUCCESS, suggestedLabels));
			}, (err) => {
				dispatch(getSuggestedAssetLabelsAction(apiCallStatus.ERROR, [], err));
			});
	};
};

export const addLabelToAsset = (assetID, labelValue) => {
	return (dispatch) => {
		dispatch(addLabelToAssetAction(apiCallStatus.LOADING));
		AssetLabelsApi.addTagToAsset(assetID, labelValue)
			.then((newLabel) => {
				dispatch(addLabelToAssetAction(apiCallStatus.SUCCESS, newLabel));
			}, (err) => {
				dispatch(addLabelToAssetAction(apiCallStatus.ERROR, null, err));
			});
	};
};

export const removeLabelFromAsset = (assetID, labelID) => {
	return (dispatch) => {
		dispatch(removeLabelFromAssetAction(apiCallStatus.LOADING));
		AssetLabelsApi.removeTagFromAsset(labelID)
			.then(() => {
				dispatch(removeLabelFromAssetAction(apiCallStatus.SUCCESS, labelID));
			}, (err) => {
				dispatch(removeLabelFromAssetAction(apiCallStatus.ERROR, null, err));
			});
	};
};
