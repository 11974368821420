import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { defaultConditions } from './constants';

const useAssetConditionValues = (asset) => {
	const [conditionValues, setConditionValues] = useState();

	useEffect(() => {
		if (asset) {
			const newConditionValues = {...defaultConditions};
			newConditionValues.overall = asset.overallStatus;
			if (!isEmpty(asset.statuses)) {
				newConditionValues.availability = asset.statuses.Availability.status;
				newConditionValues.environment = asset.statuses.Environment.status;
				newConditionValues.reliability = asset.statuses.Reliability.status;
				newConditionValues.stress = asset.statuses.Stress.status;
			}
			setConditionValues(newConditionValues);
		}
	}, [asset]);

	return conditionValues;
};

export default useAssetConditionValues;
