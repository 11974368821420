import React from 'react';
import AssetsOverviewComponent from './AssetsOverviewComponent';
import { connect } from 'react-redux';
import { getModel } from './selectors';

const AssetsOverviewContainer = (props) => {
    return <AssetsOverviewComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const AssetsOverviewContainerConn = connect(
    mapStateToProps
)(AssetsOverviewContainer);

export default AssetsOverviewContainerConn;
