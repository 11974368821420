import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModel } from './selectors';
import TokenExchangeComponent from './TokenExchangeComponent';
import * as actions from './actions';


const TokenExchangeContainer = (props) => {

	return <TokenExchangeComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const TokenExchangeContainerConn = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(TokenExchangeContainer));


export default TokenExchangeContainerConn;
