import React, { useEffect, useState } from 'react';

import transform from 'lodash/transform';
import minBy from 'lodash/minBy';
import uniqBy from 'lodash/uniqBy';

import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { translate } from 'helpers/translateHelper';
import { toLocaleDateTimeString } from 'helpers/dateHelper';
import colors from 'theme/_colors.scss';
import { afterChartReDraw, chartLegendItemClick } from '../../../../../helpers';
import TrendTooltip from './TrendTooltip';
import { renderToStaticMarkup } from 'react-dom/server';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { defaultBoostThreshold } from 'helpers/constants';


const chartConfig = {
    title: {
        align: 'left',
        style: {
            fontSize: '14px',
            fontFamily: 'ABBvoice-Regular'
        }
    },
    chart: {
        zoomType: 'x',
        events: {}
    },
    boost: {
        allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
    },
    time: {
        useUTC: null
    },
    xAxis: {
        type: 'datetime',
        title: {
            align: 'low',
            fontSize: '14px',
            fontFamily: 'ABBvoice-Regular',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        labels: {
            format: '{value: %H:%M:%S.%L}',
            staggerLines: 2,
            style: {
                fontSize: '12px',
                fontFamily: 'ABBvoice-Regular'
            },

        },
        crosshair: {
            enabled: true,
            width: 2,
            color: colors.tundora,
        }
    },
    legend: {
        align: 'right',
        enabled: true,
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 8,
        symbolWidth: 8
    },
    tooltip: {
        shared: true,
        valueDecimals: 2,
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        dateTimeLabelFormats: {
            day: '%d.%m.%Y<br/>%H:%M',
            hour: '%d.%m %H:%M',
        },
    },
    plotOptions: {
        series: {
            turboThreshold: 0,
            animation: false,
            boostThreshold: 0
        }
    },
    lang: {
        noData: 'No data available'
    },
    noData: {
        style: {
            fontFamily: 'ABBvoice-Regular',
            fontWeight: 'normal',
            fontSize: '15px',
            color: colors.mineShaft,
        }
    },
    exporting: {
        enabled: false,
        buttons: {
            contextButton: {
                menuItems: ['downloadPNG'],
                verticalAlign: 'bottom',
                x: 10,
                y: 0
            }
        }
    },
    credits: {
        enabled: false
    },
};

const getTriggerPoint = (signals) => {
    const signalWithMaxTimeStep = minBy(signals, 'timeStepMS');
    if (signalWithMaxTimeStep) {
        return new Date(signalWithMaxTimeStep.triggerPoint).getTime();
    }
};

const getSignalData = (selectedLoggerSignals) => {
    return transform(selectedLoggerSignals, (acc, item, index) => {
        const config = item.config ? item.config : {};
        const unit = config?.unit || '';
        const precision = config?.precision;
        const seriesId = index;
        const yAxisId = `yAxis_${index}`;
        acc.yAxis.push({
            labels: {
                format: '{value}',
                style: {
                    color: item.color,
                    fontSize: '12px',
                    fontFamily: 'ABBvoice-Regular'
                },
            },
            id: yAxisId,
            title: {
                text: undefined,
            },
            opposite: index % 2 !== 0,
            visible: true
        });

        acc.seriesData.push({
            loggerName: item.filename,
            name: item.signal,
            data: item.signalData,
            tooltip: {
                valueSuffix: ` ${unit}`,
                valueDecimals: precision,
                style: {
                    fontFamily: 'ABBvoice-Regular',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                },
            },
            color: item.color,
            marker: {
                enabled: false,
                fillColor: 'white',
                color: item.color,
            },
            lineWidth: 1,
            id: seriesId,
            events: {
                legendItemClick: chartLegendItemClick
            },
            yAxis: yAxisId
        });
        if (item.signalData.length) {
            const firstTimeStamp = item.signalData[0][0];
            const lastTimeStamp = item.signalData[item.signalData.length - 1][0];
            acc.min = acc.min === null ? firstTimeStamp : Math.min(acc.min, firstTimeStamp);
            acc.max = acc.max === null ? lastTimeStamp : Math.max(acc.max, lastTimeStamp);
            acc.allSeriesLength = acc.allSeriesLength + item.signalData.length;
        }

    }, { yAxis: [], seriesData: [], min: null, max: null, allSeriesLength: 0 });
};

export const chartPlugins = [CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.HIGHCHARTS_MORE];

const useChartConfig = (selectedLoggerSignals, removeFn) => {

    const [options, setOptions] = useState({});
    const [titleTime, setTitleTime] = useState({ min: null, max: null });

    const handleTitleTime = (min, max) => {
        if (!min || !max) {
            return;
        }
        if (titleTime.min !== min || titleTime.max !== max) {
            setTitleTime({
                min,
                max
            });
        }
    };

    const dateFormatConfig = { showMs: true, showOffset: false, asUTC: true };

    const getTitle = (min, max) => {
        return `${toLocaleDateTimeString(titleTime.min || min, dateFormatConfig)} - ${toLocaleDateTimeString(titleTime.max || max, dateFormatConfig)}`;
    };

    useEffect(() => {
        const { yAxis, seriesData, min, max, allSeriesLength } = getSignalData(selectedLoggerSignals);
        chartConfig.lang.noData = translate('ABB.Powertrain.Frontend.noDataForSelectedPeriodLabel');
        chartConfig.yAxis = yAxis;
        chartConfig.xAxis.min = min;
        chartConfig.xAxis.max = max;
        chartConfig.title.text = getTitle(min, max);
        chartConfig.series = seriesData;
        chartConfig.time.useUTC = true;
        chartConfig.xAxis.plotLines = [{
            color: colors.black,
            width: 2,
            value: getTriggerPoint(selectedLoggerSignals),
            zIndex: 13,
            label: {
                allowOverlap: false,
                text: translate('ABB.Powertrain.Frontend.eventListAssistanceLoggerTrigger'),
                rotation: 0,
                y: -5,
                x: -50,
                style: {
                    fontSize: 14,
                    textOverflow: 'none'
                },
            }
        }];
        chartConfig.xAxis.title.text = translate('ABB.Powertrain.Frontend.LoggerXAxisLabel');

        chartConfig.chart.events = {
            redraw: function (chartInstance) {
                const extremes = chartInstance.target.xAxis[0]?.getExtremes();
                handleTitleTime(extremes.min, extremes.max);
                afterChartReDraw(chartInstance.target.container.id, removeFn);
            }
        };

        chartConfig.legend = {
            align: 'right',
            enabled: true,
            symbolRadius: 0,
            alignColumns: false,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8,
            useHTML: true,
            itemStyle: {
                fontFamily: 'ABBvoice-Regular',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '16px',
            },
            labelFormatter: function () {
                return renderToStaticMarkup(<RemovableLegend
                    signalSourceName={this.userOptions.loggerName}
                    legend={this} />);
            }
        };

        chartConfig.tooltip.formatter = function () {

            const selectedLoggers = [];
            this.points.forEach(point => {
                selectedLoggers.push({ loggerName: point.series.userOptions.loggerName });
            });
            const uniqueLoggers = uniqBy(selectedLoggers, 'loggerName');

            uniqueLoggers.forEach(logger => {
                logger.signals = [];
                this.points.forEach(point => {
                    if (point.series.userOptions.loggerName === logger.loggerName) {
                        logger.signals.push({
                            name: point.series.userOptions.name,
                            color: point.color,
                            valueSuffix: point.series.tooltipOptions.valueSuffix,
                            value: point.y
                        });
                    }
                });
            });
            return renderToStaticMarkup(<TrendTooltip
                loggers={uniqueLoggers}
                dateFormat={dateFormatConfig}
                timeStamp={this.x} />
            );
        };
        chartConfig.plotOptions.series.boostThreshold = allSeriesLength > defaultBoostThreshold ? 1 : 0;
        setOptions({ ...chartConfig });

    }, [selectedLoggerSignals]);
    useEffect(() => {
        const { min, max } = getSignalData(selectedLoggerSignals);
        chartConfig.title.text = getTitle(min, max);
        setOptions({ ...chartConfig });

    }, [titleTime?.min, titleTime?.max, selectedLoggerSignals]);
    return options;
};

export default useChartConfig;
