export const actionTypes = {
    CLEAR_STORE: 'powertrainTools/subscriptionStatus/CLEAR_STORE',
    SET_ACTIVE_TAB: 'powertrainTools/subscriptionStatus/SET_ACTIVE_TAB',
    ACTIVE_SUB_DATA: 'powertrainTools/subscriptionStatus/ACTIVE_SUB_DATA'
};

export const subscriptionStatus = {
    EXPIRED: 'expired',
    ACTIVE: 'active',
    FREE: 'Free',
    FREE_FOR_RENEWAL: 'Free for renewal'
};

export const subscriptionTimeline = [
    { label: 'All', value: 'All' },
    { label: '1 month', value: '1' },
    { label: '3 months', value: '3' },
    { label: '6 months', value: '6' },
    { label: '1 year', value: '12' },
    { label: 'More than 1 year', value: '>12' }
];

export const subscriptionAssetType = [
    { label: 'All asset types', value: 'all' }
];
export const customMultiSelectStyles = {
    control: (provided) => ({
        ...provided,
        width: 300,
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'nowarp'
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: '50px'
    })
};
