import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TimeSelectorRow, SearchBox, GeneralSelect } from 'sharedComponents';
import EventTypeFilter from './EventTypeFilter';
import { eventStatus, EventTypeAssetApplicability } from '../constants';
import { getEventStatusOptions, getDefaultEventStatus } from '../helpers';
import { selectState } from 'helpers/filterHelper';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';

const EventLogFilterSSComponent = (props) => {
    const { t: translate } = useTranslation();

    const {
        filters,
        dateSelection
    } = props.model;

    const {
        setDateSelection,
        setFilters
    } = props.actions;

    const eventStatusOptions = getEventStatusOptions(translate);

    const handleSelectDate = (from, to) => {
        if (from && to) {
            setDateSelection({ from, to });
        }
    };

    const handleFilterByEventType = (typeID) => {
        const eventTypes = selectState(filters.eventTypes, typeID);
        setFilters({ eventTypes });
        props.setIsExapnded(false);
    };

    return (
        <Container className='event-log-filter-content'>
            <div className='filter-area'>
                <Row>
                    <Col className='ss-event-filter-date-selector' md='auto' >
                        <div className='time-label'>{translate('ABB.Powertrain.Frontend.occurredOnLbl')}</div>
                        <TimeSelectorRow
                            fromDate={dateSelection.from}
                            toDate={dateSelection.to}
                            handleSelectDate={handleSelectDate}
                            maxDaysRange={90}
                            maxDaysBack={roleBasedCapabilityHelper.hasExtendedDateSelector() ? 365 : 90}
                            preSelectedDays={7}
                            numberOfMonths={2}
                            showLoading={false}
                            footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                        />
                    </Col>
                    <Col className='event-log-filter' md='auto'>
                        <EventTypeFilter {...props}
                            selectedStates={filters.eventTypes}
                            selectState={handleFilterByEventType}
                            assetType={EventTypeAssetApplicability.SMART_SENSOR}
                        />
                    </Col>
                    <Col md='auto'>
                        <div className='status-label'>{translate('ABB.Powertrain.Frontend.eventLogFilterStatusLabel')}</div>
                        <GeneralSelect id='status-ddn'
                            options={eventStatusOptions}
                            defaultValue={getDefaultEventStatus(filters.eventStatus || eventStatus.ALL, translate)}
                            onChange={(eventStatus) => setFilters({ eventStatus: eventStatus.id })}
                            getOptionLabel={option => option.title}
                            getOptionValue={option => option.id}
                            menuShouldScrollIntoView={false}
                            isSearchable={false}
                        />
                    </Col>
                    <Col md='auto' className='ss-event-filter-search'>
                        <SearchBox
                            onChange={(searchTerm) => setFilters({ eventName: searchTerm })}
                            value={filters.eventName}
                            placeholder={translate('ABB.Powertrain.Frontend.searchEventByNameLbl')}
                        />
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

EventLogFilterSSComponent.propTypes = {
    model: PropTypes.shape({
        filters: PropTypes.object,
        dateSelection: PropTypes.object
    }),
    actions: PropTypes.shape({
        setDateSelection: PropTypes.func,
        setFilters: PropTypes.func
    })
};

export default EventLogFilterSSComponent;
