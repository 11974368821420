import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { map } from 'lodash';
import { Row } from 'react-bootstrap';
import ParameterSection from './Sections/ParameterSection';
import './style.scss';

const TrendSignalKPIListComponent = (props) => {
    const { model, actions } = props;

    useEffect(() => {
        actions.getTrendKPIListForAsset(props.asset);
    }, [props.asset]);

    return (
        <>
            {model.trendKPIsList.length ?
                <Row className='trend-kpi-list measurement-list-container'>
                    {
                        map(props.model.trendKPIsList, (kpiSection) => {
                            return <ParameterSection
                                key={kpiSection.sectionId}
                                section={kpiSection}
                                selectedTrendsData={model.selectedTrendsData}
                                asset={props.asset} />;
                        })
                    }
                </Row> : null}

        </>
    );
};

TrendSignalKPIListComponent.propTypes = {
    actions: PropTypes.shape({
        getTrendKPIsForAsset: PropTypes.func
    }),
    asset: PropTypes.object,
    model: PropTypes.shape({
        trendKPIsList: PropTypes.array,
        trendKPIsLoadingStatus: PropTypes.string
    })
};

export default TrendSignalKPIListComponent;
