import React from 'react';
import PropTypes from 'prop-types';
import { resolveFillValueByConditionValue } from './functions';


const IconAvailability = ({ width = 14, height = 14, condition = -1, viewBox = '0 0 14 14', color }) => {
	const fillValue = resolveFillValueByConditionValue(condition);
	const appliedColor = color ? color : fillValue;

	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M8 9V11H6V9H8ZM8 3V8H6V3H8ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 6.21207 1.15519 5.43185 1.45672 4.7039C1.75825 3.97595 2.20021 3.31451 2.75736 2.75736C3.31451 2.20021 3.97595 1.75825 4.7039 1.45672C5.43185 1.15519 6.21207 1 7 1C7.78793 1 8.56815 1.15519 9.2961 1.45672C10.0241 1.75825 10.6855 2.20021 11.2426 2.75736C11.7998 3.31451 12.2417 3.97595 12.5433 4.7039C12.8448 5.43185 13 6.21207 13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13Z' fill={appliedColor} />
	</svg>;
};

IconAvailability.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	condition: PropTypes.number,
	viewBox: PropTypes.string
};

export default IconAvailability;
