import { all, spawn, call } from 'redux-saga/effects';
import logger from 'helpers/logger';
import { powertrainConfigurationWatchers } from '../components/PowertrainTools/PowertrainConfiguration';
import { powertrainWizardWatchers } from '../components/PowertrainTools/PowertrainConfiguration/PowertrainWizard';
import { reloadContextWatchers } from '../components/GeneralFilter/GeneralFilterModal/AssetSearch';
import { conditionIndicesOverviewWatchers } from '../components/PowertrainConditionMonitoring/ConditionIndicesOverview';
import { assetPictureUploadWatchers } from '../components/PowertrainAssetDetails/DetailedInformation/AssetDetails/Header/AssetNameplateImageHolder/assetImageSaga';
import { assetConditionalInsightsWatchers } from '../components/PowertrainAssetDetails/DetailedInformation/ConditionInsights/conditionInsightsSaga';
import { notificationSettingsWatchers } from '../components/UserProfileSettings/NotificationSettings';
import { limitConfigWatchers } from '../components/PowertrainAssetDetails/DetailedInformation/LimitConfigurationBeta';


export function* rootSaga() {
    const sagas = [
        powertrainConfigurationWatchers,
        powertrainWizardWatchers,
        reloadContextWatchers,
        conditionIndicesOverviewWatchers,
        assetPictureUploadWatchers,
        assetConditionalInsightsWatchers,
        notificationSettingsWatchers,
        limitConfigWatchers
        // Add other component-specific Sagas here if needed
    ];
    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    logger.error(e);
                }
            }
        })
    ));
}
