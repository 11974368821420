import React from 'react';
import {
    lowerCase,
    upperFirst,
    orderBy,
    groupBy,
    map,
    forIn,
    forEach
} from 'lodash';
import { convertUtcToLocalDateTime, getUTCDateString, toLocaleDateTimeString } from 'helpers/dateHelper';
import { IconDataLogger, IconComment, IconClose } from 'svgIcons/MotionPortalIcons';
import { dateKind, EventTypes, eventStatus } from '../constants';
import { eventTypeOrderSmSe } from './constants';


const getSSColumns = (translate, closeEventHandler, setSelectedEvent) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogNameTitle'),
            dataIndex: 'eventName',
            fixed: 'left',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogAdviceTitle'),
            dataIndex: 'description',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogActionsTitle'),
            dataIndex: 'rawEvent',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (event) => <div>{event.status !== eventStatus.CLOSED ?
                <div className='close-button'>
                    <span onClick={() => { closeEventHandler(event); }}>
                        <IconClose />
                    </span>
                </div> : null}
            </div>
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogOccurredOnTitle'),
            dataIndex: 'occurredOn',
            width: 200,
            isSorting: true,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            }),
            render: (occurredOn) => convertUtcToLocalDateTime(occurredOn)
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogStatusTitle'),
            dataIndex: 'status',
            width: 150,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogClosingReasonTitle'),
            dataIndex: 'closingReason',
            width: 150,
            onCell: (event) => ({
                onClick: () => setSelectedEvent(event)
            })
        }
    ];
};

const getDriveColumns = (translate, nameWithType = false, setSelectedEvent, assistanceCapability, selectedDateKind) => {
    const eventNameColumn = {
        title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogNameTitle'),
        dataIndex: 'eventName',
        fixed: 'left',
        isSorting: true,
        onCell: (data) => ({
            onClick: () => assistanceCapability && setSelectedEvent(data)
        })
    };

    if (nameWithType) {
        eventNameColumn.dataIndex = 'rawEvent',
            eventNameColumn.render = (event) => <div className='event-name-with-type'>
                <div className='event-type-icon'>{EventTypes.find(et => et.typeID === event.typeName).icon}</div>
                <div>{event.eventName}</div>
            </div>;
    }

    return [
        eventNameColumn,
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogCauseTitle'), //*
            dataIndex: 'cause',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogTimeTitle'), //*
            dataIndex: 'time',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
            render: (time) => toLocaleDateTimeString(time, { asUTC: selectedDateKind === dateKind.UTC, showOffset: true })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogDeviceTimestampTitle'), //*
            dataIndex: 'deviceTimestamp',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            })
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogAssetName'), //*
            dataIndex: 'assetName',
            width: 200,
            isSorting: true,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            }),
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInfoEventLogInfoTitle'),
            dataIndex: 'info',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (info) => <div className='other-info'>
                {info.hasDataLoggers || info.hasBinaryLogger ? <div className='action-icon'><IconDataLogger /></div> : null}
                {info.hasComment ? <div className='action-icon'><IconComment /></div> : null}
            </div>,
            onCell: (data) => ({
                onClick: () => assistanceCapability && setSelectedEvent(data)
            })
        }
    ];
};

const mapEvents = (events = [], translate) => {
    return map(events, (event, index) => {
        return {
            id: `event_${index}`,
            rawEvent: {
                ...event,
                sortingItem: event.eventName
            },
            assetName: event.componentName,
            eventName: event.eventName,
            description: event.description,
            occurredOn: event.utc,
            status: event.status ? translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${event.status}`) : '',
            closingReason: event.closingReason ? translate(`ABB.Powertrain.Frontend.eventClosingReason${event.closingReason}`) : '',
            cause: translate(`ABB.Powertrain.Frontend.eventListFilterDriveEvents${event.causeOfEvent}`),
            time: event.utc,
            deviceTimestamp: event.deviceTime,
            info: {
                hasComment: event.hasComment,
                hasDataLoggers: event.hasDataLoggers,
                hasBinaryLogger: event.hasBinaryLogger
            }
        };
    });
};

const formatDriveEventLogGroups = (eventLogs, translate) => {
    const groupedEvents = groupBy(eventLogs || [], 'eventName');
    const formattedData = [];

    forIn(groupedEvents, (eventGroup, index) => {
        formattedData.push({
            id: `eventGroup_${index}`,
            eventGroupName: `${index} (${eventGroup.length})`,
            eventType: eventGroup[0].typeName,
            events: mapEvents(eventGroup, translate)
        });
    });

    return formattedData.sort((a, b) => a.eventGroupName.localeCompare(b.eventGroupName));
};

const getEventTypeLabel = (key) => `ABB.Powertrain.Frontend.lblEventListEventType${upperFirst(lowerCase(key))}`;

const formatSmartSensorEventLogGroups = (eventLogs, translate) => {
    const groupedEvents = groupBy(eventLogs, 'typeName');
    const formattedData = [];
    forIn(groupedEvents, (eventGroup, key) => {
        formattedData.push({
            eventGroupName: `${translate(getEventTypeLabel(key))} (${eventGroup.length} ${translate('ABB.Powertrain.Frontend.groupsLbl')})`,
            eventType: eventGroup[0].typeName,
            events: mapEvents(eventGroup, translate),
            orderID: eventGroup[0]?.typeName && eventTypeOrderSmSe[eventGroup[0].typeName]
        });
    });

    return orderBy(formattedData, 'orderID');
};

const formatEventLogList = (eventLogs, translate) => {
    return {
        events: mapEvents(eventLogs, translate),
    };
};

export const formatEventLogs = (eventLogs, isSS, grouped, translate) => {
    if (isSS) {
        return formatSmartSensorEventLogGroups(eventLogs, translate);
    }
    else if (grouped) {
        return formatDriveEventLogGroups(eventLogs, translate);
    }
    return formatEventLogList(eventLogs, translate);
};


export const getColumnConfig = (isSS, grouped, translate, closeEventHandler, setSelectedEvent, assistanceCapability, dateKind) => {
    return isSS ?
        getSSColumns(translate, closeEventHandler, setSelectedEvent) :
        getDriveColumns(translate, !grouped, setSelectedEvent, assistanceCapability, dateKind);
};

export const getDateInterval = (dateSelection, selectedDateKind) => {
    if (selectedDateKind === dateKind.UTC) {
        const from = new Date(dateSelection.from);
        const offsetInHours = from.getTimezoneOffset();
        const to = new Date(dateSelection.to);
        dateSelection = {
            from: getUTCDateString(new Date(from.getTime() - offsetInHours * 60 * 1000)),
            to: getUTCDateString(new Date(to.getTime() - offsetInHours * 60 * 1000))
        };
    }
    return dateSelection;
};


export const formatAssetListRequest = (assets) => {
    const assetListRequest = [];

    forEach(assets, (asset) => {
        if (asset.componentID) {
            assetListRequest.push({
                ComponentID: asset.componentID,
                TypeID: asset.componentType.typeID,
                DataSourceType: asset.componentType.dataSourceType
            });
        }
    });

    return assetListRequest;
};
