import { filterAssets, filterPowertrains } from 'helpers/assetAndPowertrainFilterHelper';
import { getGeneralFilteredPowertrains, getGeneralFilteredAssets } from 'helpers/selectors';
import { createSelector } from 'reselect';
import { orderByAlphabet } from './helpers';

const getFilters = ({ assetAndPowetrainFilter }) => {
    return assetAndPowetrainFilter;
};

export const getFormattedAssets = createSelector(
    [getGeneralFilteredAssets, getFilters],
    (assets, filters) => {
        return filterAssets(assets, filters);
    }
);

export const getFormattedPowertrains = createSelector(
    [getGeneralFilteredPowertrains, getFilters],
    (powertrains, filters) => {
        return orderByAlphabet(filterPowertrains(powertrains, filters));
    }
);
