import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class LimitConfigActions {

    getLimitKpi(assetId) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getLimitKpi, { assetId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    postLimitConfig(assetId, timeseries) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.postTimeseriesLimits);
        return RestManagerWithoutBaseUrl.put(endpoint, { assetId, timeseries });
    }

    getTimeSeriesLimits(assetId) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getTimeseriesLimits, { assetId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }
}

export default new LimitConfigActions();
