import { popupTypes } from 'helpers/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


import { GeneralDialog, GeneralButton } from 'sharedComponents';
import { BulkUploadFileSection } from './BulkUploadFileSection';
import { BulkUploadStatusSection } from './BulkUploadStatusSection';
import { checkUploadedFiles } from './helpers';

import './style.scss';


const BulkUploadComponent = ({ show, close, model, actions }) => {
    const { t: translate } = useTranslation();
    const [fileUploadWarningVisibility, setFileUploadWarningVisibility] = useState(false);
    const [fileUploadInfoVisibility, setFileUploadInfoVisibility] = useState(false);

    const files = useSelector(({ bulkUpload }) => {
        return {
            isFiles: bulkUpload && bulkUpload.bulkFiles
                && bulkUpload.bulkFiles.length ? true : false,
            isUploadFinished: checkUploadedFiles(bulkUpload.bulkFiles)
        };
    });

    const showProcessWaning = () => {
        setFileUploadInfoVisibility(true);
    };

    const processBulkUpload = () => {
        actions.processBulkUpload(model.selectedAsset, model.bulkFiles, model.dateInterval);
        setFileUploadInfoVisibility(false);
    };

    const handleUploadClick = () => {
        actions.uploadFiles(model.selectedAsset, model.bulkFiles, model.dateInterval);
    };

    return <>
        <GeneralDialog
            modalClassName='dialog-second-level'
            backdropClassName='dialog-second-level-backdrop'
            notificationType={popupTypes.WARNING}
            show={fileUploadWarningVisibility}
            close={() => setFileUploadWarningVisibility(false)}
            title={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadWarningPopupTitle')}
            cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDialogNoLabel') }}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDialogYesLabel'),
                onClick: close
            }}
        >
            {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadWarningPopupText')}
        </GeneralDialog>
        <GeneralDialog
            modalClassName='dialog-second-level'
            backdropClassName='dialog-second-level-backdrop'
            notificationType={popupTypes.INFO}
            show={fileUploadInfoVisibility}
            close={() => setFileUploadInfoVisibility(false)}
            title={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadInfoPopupTitle')}
            cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDialogNoLabel') }}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDialogYesLabel'),
                onClick: processBulkUpload
            }}
        >
            {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadInfoPopupText')}
        </GeneralDialog>
        <GeneralDialog
            show={show}
            close={() => { files.isFiles ? setFileUploadWarningVisibility(true) : close(); }}
            title={<span><strong>{translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadModalTitle')} &ldquo;{model?.selectedAsset?.componentName}&rdquo;</strong></span>}
            closeButton={true}
            dialogClassName='bulk-upload-dialog'
        >
            <BulkUploadFileSection />
            <div className='note-buttons-container'>
                <div className='bulk-upload-note'>
                    {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadNote')}
                </div>
                <div className='buttons-row'>
                    <GeneralButton
                        onClick={handleUploadClick}
                        disabled={!model?.bulkFiles?.length || !model?.isUploadAllowed || model?.isUploading || model?.isProcessing}
                        type='normal'
                        text={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadButton')}
                    />
                    <GeneralButton
                        onClick={showProcessWaning}
                        disabled={!model?.isProcessAllowed || model?.isUploading || !files.isUploadFinished || !files.isFiles || model?.isProcessing}
                        type='primary'
                        text={translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadProcessButton')}
                    />
                </div>
            </div>
            <BulkUploadStatusSection />
        </GeneralDialog>
    </>;
};

export default BulkUploadComponent;
