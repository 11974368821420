import { EventLogsApi, AssetDetailsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import * as actionTypes from '../constants';
import { getDateInterval, formatAssetListRequest } from './helpers';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { notify } from 'sharedComponents/GeneralNotification';
import { getApiActionCreator } from 'helpers/actionHelper';


const getEventLogsAction = getApiActionCreator(actionTypes.GET_EVENT_LOGS);
const closeEventLogAction = getApiActionCreator(actionTypes.CLOSE_EVENT_LOG);
const updateEventLogAction = getApiActionCreator(actionTypes.UPDATE_EVENT_LOGS);


export const getEventLogs = (assets, filters, organizationID, truncatedText) => {
    return (dispatch) => {
        dispatch(getEventLogsAction(apiCallStatus.LOADING));
        const dateSelection = getDateInterval(filters.dateSelection, filters.dateKind);
        EventLogsApi.getEventLogs(formatAssetListRequest(assets), dateSelection.from, dateSelection.to, organizationID)
            .then((eventLogs) => {
                dispatch(getEventLogsAction(apiCallStatus.SUCCESS, eventLogs));
                if (eventLogs && eventLogs.isTruncated) {
                    notify(truncatedText, NOTIFICATION_TYPES.WARN, true);
                }
                logger.debug('Event logs successfully loaded');
            }, (err) => {
                dispatch(getEventLogsAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const closeEventLog = (event, closingReason, comment) => {
    return (dispatch) => {
        dispatch(closeEventLogAction(apiCallStatus.LOADING));
        AssetDetailsApi.closeEventLog(closingReason.id, comment, event.eventLogIDList)
            .then(() => {
                dispatch(updateEventLogAction(apiCallStatus.SUCCESS, { eventId: event.eventId, closingReason }));
                dispatch(closeEventLogAction(apiCallStatus.SUCCESS));
            }).catch((err) => {
                dispatch(closeEventLogAction(apiCallStatus.ERROR, null, err));
            });
    };
};
