import { actionTypes } from './constants';
import {
    updateSelectedItems,
    updateAllSelection
} from './helpers';


const initialState = {
    notificationChannels: {
        data: null,
        error: null,
        loadingStatus: null
    },
    mutedAssets: {
        data: null,
        error: null,
        loadingStatus: null
    },
    selectedAssets: [],
    selectedNotificationGroups: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATION_SETTINGS: {
            return {
                ...state,
                notificationChannels: {
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.GET_MUTED_ASSETS: {
            return {
                ...state,
                selectedAssets: [],
                mutedAssets: {
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.SELECT_NOTIFICATION_GROUP: {
            return {
                ...state,
                selectedNotificationGroups: updateSelectedItems(
                    state.selectedNotificationGroups,
                    action.selectedGroup
                )
            };
        }
        case actionTypes.SELECT_MUTED_ASSET: {
            return {
                ...state,
                selectedAssets: action.assetId ?
                    updateSelectedItems(state.selectedAssets, action.assetId) :
                    updateAllSelection(state.selectedAssets, state.mutedAssets?.data)
            };
        }
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
}
