import PropTypes from 'prop-types';
import map from 'lodash/map';
import React from 'react';
import { useSelector } from 'react-redux';
import { ScrollToElementWrapper } from 'sharedComponents/ScrollToElementWrapper';
import { useTranslation } from 'react-i18next';
import AssetListItem from './AssetListItem';
import { useQueryParams } from 'sharedHooks/useQuery';


const AssetList = ({
    assets,
    onAssetClick,
    insidePowertrain }) => {
    const { t: translate } = useTranslation();
    const selectedComponentID = useSelector(({ powertrainAssetDetails }) =>
        powertrainAssetDetails.selectedAsset?.componentID);

    const { assetId: assetIdFromQuery } = useQueryParams();

    return (
        <div className='asset-list'>
            {assets.length ? map(assets, (asset, assetKey) =>
                asset && <ScrollToElementWrapper
                    key={assetKey}
                    condition={!insidePowertrain && assetIdFromQuery === asset.componentID}
                >
                    <AssetListItem asset={asset} onAssetClick={onAssetClick} selectedComponentID={selectedComponentID} />
                </ScrollToElementWrapper>) : <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.noMatchesFoundLabel')}</div>}
        </div>
    );
};

AssetList.propTypes = {
    assets: PropTypes.any,
    insidePowertrain: PropTypes.bool,
    onAssetClick: PropTypes.func,
};

export default AssetList;
