import React, { useEffect, useState } from 'react';
import { forEach, map, transform } from 'lodash';
import isNumber from 'lodash/isNumber';
import { translate, getTranslatedKPIName } from 'helpers/translateHelper';
import colors from 'theme/_colors.scss';

import { afterChartReDrawStringData, chartLegendItemClick } from '../../../../helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import { TrendTooltip } from 'sharedComponents/ChartComponents/Trend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';

const getChartConfig = removeKPIData => {
    return {
        title: {
            text: null
        },
        chart: {
            zoomType: 'x',
            height: null,
            events: {
                render: function (chartInstance) {
                    afterChartReDrawStringData(chartInstance.target.container.id, removeKPIData);
                }
            },
            marginBottom: 100
        },
        boost: {
            allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            id: 'x',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S',
            },
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            },
            minPadding: 0,
            maxPadding: 0
        },
        legend: {
            useHTML: true,
            layout: 'horizontal',
            align: 'right',
            enabled: true,
            reversed: false,
            itemWidth: 300,
            itemDistance: 16,
            itemMarginTop: 10,
            maxHeight: 85,
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'ABBvoice-Regular',
                letterSpacing: '0',
                lineHeight: '17px',
                textAlign: 'center',
                textOverflow: 'ellipsis'
            },
            symbolRadius: 0,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8,
            y: 20
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            dateTimeLabelFormats: {
                day: '%d.%m.%Y<br/>%H:%M',
                hour: '%d.%m %H:%M',
            },
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                animation: false,
                boostThreshold: 0
            }
        },
        lang: {
            noData: 'No data'
        },
        noData: {
            useHTML: true
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG'],
                    verticalAlign: 'bottom',
                    x: 10,
                    y: 0
                }
            }
        }
    };
};

const getMeasurements = (config, measurements, showRangeData) => {
    const averages = [];
    const rangedata = [];
    forEach(measurements, (measurement) => {
        if (measurement.timestamp !== null) {
            const date = new Date(measurement.timestamp).getTime();
            averages.push([date, measurement?.value ? measurement.value + config.offset
                : measurement.value]);
            if (showRangeData) {
                rangedata.push([date,
                    isNumber(measurement?.min) ? measurement.min + config.offset : null,
                    isNumber(measurement?.max) ? measurement.max + config.offset : null]);
            }
        }
    });

    return { averages, rangedata };
};

const getUserLimitsSeries = (userLimits, config, seriesDataPoints) => {
    let series = [];
    if (userLimits && userLimits.length) {
        series = map(userLimits, (ul, index) => {
            return {
                name: ul.label || config.displayName || '',
                id: `ul_${index}`,
                showInLegend: false,
                color: ul.color || colors.teak,
                marker: { enabled: false },
                lineWidth: 1,
                dashStyle: 'Solid',
                data: transform(seriesDataPoints, (acc, sd) => {
                    const [date, value] = sd;
                    if (value !== null) {
                        acc.push([date, ul.value]);
                    }
                }, []),
                unit: config && config.unit ? config.unit : '',
                tooltip: {
                    valueSuffix: ` ${config && config.unit ? config.unit : ''}`,
                },
            };
        });
    }
    return series;
};


const getSeries = (selectedKpisData, userLimits, yAxisTitleVisible) => {

    const ret = { yAxis: [], seriesData: [], allSeriesLength: 0 };

    transform(selectedKpisData, (acc, item, index) => {
        const config = item.config ? item.config : {};
        const unit = config && config.unit || '';
        const precision = config && config.precision;
        const name = getTranslatedKPIName(config);
        const trendDataValues = item.values;
        const seriesId = `series_${index}`;
        const yAxisId = `y_${index}`;
        const { averages, rangedata } = getMeasurements(config, trendDataValues, selectedKpisData?.length === 1);
        acc.yAxis.push({
            labels: {
                format: '{value} ' + unit,
                style: {
                    color: item.color
                }
            },
            id: yAxisId,
            title: {
                text: name,
                enabled: yAxisTitleVisible,
                style: {
                    color: item.color
                }
            },
            opposite: index % 2 !== 0,
            visible: true
        });

        acc.seriesData.push({
            name: `${name}`,
            data: averages,
            tooltip: {
                valueSuffix: ` ${unit}`,
                valueDecimals: precision
            },
            color: item.color,
            marker: {
                enabled: true,
                //symbol: 'square',
                radius: 2
            },
            lineWidth: 1,
            id: seriesId,
            yAxis: yAxisId,
            events: {
                legendItemClick: chartLegendItemClick
            },
        });
        if (rangedata.length) {
            acc.seriesData.push(
                {
                    name: `${name}`,
                    data: rangedata,
                    type: 'arearange',
                    id: 'rangeSeries',
                    lineWidth: 2,
                    radius: 2,
                    linkedTo: ':previous',
                    fillOpacity: 0.3,
                    opacity: 0.3,
                    color: item.color,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ` ${unit}`,
                    },
                    marker: {
                        enabled: true,
                        radius: 2
                    }
                }
            );
        }
        if (userLimits?.length) {
            acc.seriesData = [...acc.seriesData, ...getUserLimitsSeries(userLimits, config, averages)];
        }
    }, ret);

    const allSeriesLength = ret.seriesData?.length ? ret.seriesData.reduce((count, series) => series.data?.length + count, 0) : 0;

    return { ...ret, allSeriesLength };
};

const useChartConfig = (selectedKpisData, userLimits, yAxisTitleVisible = true, removeKPIData, multiChart) => {

    const [options, setOptions] = useState({});
    const chartConfig = getChartConfig(removeKPIData);

    useEffect(() => {
        const { yAxis, seriesData, allSeriesLength } = getSeries(selectedKpisData, userLimits, yAxisTitleVisible);
        chartConfig.yAxis = yAxis;
        chartConfig.series = seriesData;
        chartConfig.plotOptions.series.boostThreshold = allSeriesLength > defaultBoostThreshold ? 1 : 0;
        chartConfig.lang.noData = renderToStaticMarkup(
            <ChartNoDataText
                title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />);
        chartConfig.enableNoDataZoom = true;
        chartConfig.tooltip.formatter = function () {
            return renderToStaticMarkup(<TrendTooltip points={this.points} timeStamp={this.x} />);
        };
        chartConfig.legend.labelFormatter = function () {
            return renderToStaticMarkup(<RemovableLegend
                signalSourceName={this.userOptions.component}
                legend={this}
                multiChart={multiChart}
            />);
        };
        setOptions({ ...chartConfig });
    }, [selectedKpisData, userLimits]);

    return options;
};

export default useChartConfig;
