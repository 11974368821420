import React, { useMemo, useCallback, useState } from 'react';
import { ContextMenu } from 'sharedComponents/ContextMenu';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { IconClose, IconPlus } from 'svgIcons/MotionPortalIcons';
import { getTranslatedKPINameV2, translate } from 'helpers/translateHelper';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { useDispatch } from 'react-redux';
import { addLimit, removeSignal, updateSignal, discardLimit, handleSignalUpdate } from '../actions';
import { KPI_ERROR, KPI_TYPE } from './constants';
import { getContextMenuOptions, getUnitsGroupID } from './helper';
import { GeneralDialog } from 'sharedComponents/GeneralDialog';
import { map as lmap, find as lfind, filter as lfilter } from 'lodash';
import { getMeasurementUnitSymbol } from 'helpers/unitHelper';

//TODO  : - : this will be removed when timeseriesKey is sent as part of limitConfig data
const getTimeSeriesKey = (kpiListData, keyName) => {
    const additionalTrendList = (kpiListData ?? []).filter(item => item.timeseriesGroupName === 'AdditionalTrends');
    const keyObj = additionalTrendList[0].timeseries.filter(item => item.timeseriesName === keyName);
    return keyObj[0]?.timeseriesKey || '';
};

const getPayload = (item, result, kpiList) => {
    const timeseries = result[0].timeseries[0];

    return {
        ...item,
        isTrend: result[0].timeseriesGroupCode === KPI_TYPE.Trend,
        timeseriesKey: result[0].timeseriesGroupCode === KPI_TYPE.Trend ? getTimeSeriesKey(kpiList, timeseries.timeseriesName) : timeseries.timeseriesKey,
        trendkey: result[0].timeseriesGroupCode !== KPI_TYPE.Trend ? '' : timeseries.timeseriesKey,
        displayNameTextKey: timeseries.timeseriesNameTextKey,
        appendCodeToTranslation: timeseries.appendCodeToTranslation,

    };

};

const LimitTitle = (props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const handleChange = (selected, field) => {
        if (props.item.isSignalError) {
            dispatch(updateSignal(props.item.uniqId, KPI_ERROR.isSignalError, false));
        }

        dispatch(updateSignal(props.item.uniqId, field, selected.title));
    };

    const handleUpdate = (field, value) => {
        dispatch(updateSignal(props.item.uniqId, field, value));
    };

    const handleAddLimit = (uniqId) => {
        dispatch(addLimit(uniqId));
    };

    const handleDiscard = (uniqId) => {
        dispatch(discardLimit(uniqId));
    };

    const handleRemoveSignal = () => {
        dispatch(removeSignal(props.item.uniqId));
    };

    const handleTimeSeries = useCallback((value) => {
        const data = lfilter(props.model.limitKPI.kpiList ?? [], item => {
            return lfind(item.timeseries, series => {
                return series.timeseriesNameTextKey === value;
            });
        });

        const result = lmap(data, obj => {
            return {
                ...obj,
                timeseries: lfilter(obj.timeseries, series => {
                    return series.timeseriesNameTextKey === value;
                })
            };
        });


        const payload = getPayload(props.item, result, props.model.limitKPI.kpiList);
        dispatch(handleSignalUpdate(payload));
    }, [props.model.limitKPI.kpiList, props.item]);

    const kpiOptionsMemo = useMemo(() => {

        const kpiList = props.model.limitKPI.kpiList ?? [];

        kpiList.forEach(item => {
            if (item.timeseriesGroupName === 'Trends') {
                item.timeseries.sort((a, b) => a.timeseriesKey.localeCompare(b.timeseriesKey));
            }
        });


        const accumulatedKPIItems = (kpiList ?? []).reduce((acc, item) => {
            item.timeseries.forEach(series => {
                const title = getTranslatedKPINameV2(series);
                const id = getTranslatedKPINameV2(series);
                const key = series.timeseriesNameTextKey;
                const minValue = series.minValue;
                const maxValue = series.maxValue;
                const uom = series.unit;
                const isLimitEditable = series.isDefaultLimitEditable;
                // const timeSeriesName = series.timeseriesName;

                // TODO Need to check if the translation is required with the below conditon
                // if (series.appendCodeToTranslation) {
                //     title = !series.timeseriesNameTextKey ? `${getTranslatedKPINameV2(series.timeseriesName)}` : `${series.timeseriesKey}`;
                //     id = !series.timeseriesNameTextKey ? `${series.timeseriesName}` : `${series.timeseriesKey}`;
                // }

                acc.push({ title, id, key, minValue, maxValue, uom, isLimitEditable });
            });

            return acc;
        }, []);

        const accumulatedKeys = new Set(props.model.currentTimeSeries.map(item => item.displayNameTextKey));
        const filteredTimeseries = accumulatedKPIItems.filter(series => !accumulatedKeys.has(series.key));

        return filteredTimeseries;

    }, [props.model.limitKPI.kpiList, props.model.currentTimeSeries]);


    const handleMenu = useCallback((value) => {
        switch (value) {
            case 'removeKpi': setShowModal(true);
                break;
            case 'export': console.log('export data');
                break;
            default:
                break;
        }
    }, []);

    return (
        <div className='limit-title-container'>
            <GeneralDialog
                show={showModal}
                close={() => setShowModal(false)}
                notificationType='info'
                title='Remove KPI'
                cancelButtonProps={{
                    text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                    onClick: () => setShowModal(false)
                }}
                acceptButtonProps={{
                    text: translate('ABB.Powertrain.Frontend.lblRemove'),
                    onClick: handleRemoveSignal
                }}
                closeButton={true}
                persistent={false}
            >
                <div>
                    This will remove all limits set for the KPI.
                </div>
            </GeneralDialog>
            <div className='limit-title-left'>
                <div className='limit-signal' onClick={e => e.stopPropagation()}>
                    <GeneralSelect
                        options={kpiOptionsMemo}
                        onChange={(selected) => {
                            handleTimeSeries(selected.key, selected.title);
                            handleChange(selected, 'displayName');
                            handleUpdate('unit', selected.uom);
                            handleUpdate('minValue', selected.minValue);
                            handleUpdate('maxValue', selected.maxValue);
                            // handleUpdate('timeSeriesName', selected.timeSeries);
                            props.setExpandedItem(props.item.uniqId);
                        }}
                        isDisabled={false}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        menuShouldScrollIntoView={false}
                        isSearchable={true}
                        disableOnSelect
                        selectedValue={props.item.displayName || ''}
                        error={props.item.isSignalError}
                    />
                    {props.item.unit && <span className='limit-uom'>({getMeasurementUnitSymbol(getUnitsGroupID(props.item.displayNameTextKey, props.model.limitKPI.kpiList)) || props.item.unit})</span>}
                    {/* <span className='limit-pipe'>|</span> */}
                </div>
                {/* <div className='limit-offset'>
                    {' Offset: +1'}
                </div> */}
            </div>
            <div className='limit-title-right'>
                {props.model.timeSeries.isLoading || props.item.isModified && <GeneralButton
                    type='discreet'
                    icon={IconClose({ width: '14px', height: '14px', color: '#1f1f1f' })}
                    text={'Discard'}
                    className='limit-discard'
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDiscard(props.item.uniqId);
                    }}
                />}
                {/* <div className='limit-notify'>
                    <Switch
                        checked={chck}
                        onChange={handleChange}
                    />  <span>Notify me</span>
                </div> */}
                <div className={`add-limit ${!props.model.timeSeries.isLoading && props.item.limits.length > 0 ? '' : 'add-limit-disabled'} ${props.item?.isAddingCustomLimitsAllowed === false ? 'add-limit-disabled' : ''}`} onClick={(e) => {
                    e.stopPropagation();
                    if (e.currentTarget.classList.contains('add-limit-disabled')) {
                        return;
                    }
                    props.setExpandedItem(props.item.uniqId);
                    handleAddLimit(props.item.uniqId);
                }}>
                    <IconPlus />
                    <div>Add Limit</div>
                </div>
                <div onClick={e => e.stopPropagation()}>
                    <ContextMenu
                        options={getContextMenuOptions(props.item.isNew, [{
                            id: 'removeKpi',
                            title: translate('ABB.Powertrain.Frontend.lblRemoveSignalBtn'),
                        }],)}
                        onSelect={handleMenu}
                    />
                </div>
            </div>
        </div>
    );
};

export default LimitTitle;
