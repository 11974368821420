import { actionTypes, wizardSteps } from './constants';
import {
    getWizardDefaultStepStates,
    setWizardStepState,
    getUpdatedAvailableAssets,
    addAssetToList,
    removeAssetFromList,
    disassemblePowertrain,
} from './helpers';


export const initialState = {
    isEditMode: false,
    powertrainID: null,
    powertrainName: '',
    selectedSite: null,
    selectedAssetTypeAvailableSection: null,
    assetFilterAvailableSection: '',
    selectedAssetTypeAddedSection: null,
    assetFilterAddedSection: '',
    addedAssets: null,
    addedAssetsChangedFlag: false,
    powertrainDetailsChangedFlag: false,
    powertrainWizardStepStates: getWizardDefaultStepStates(),
    activeStep: wizardSteps.STEP1,
    availableAssets: {
        data: [],
        loadingStatus: null,
        pageNumber: 1,
        hasMore: true
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return {
                ...initialState,
                powertrainWizardStepStates: getWizardDefaultStepStates(),
            };
        }
        case actionTypes.GET_AVAILABLE_ASSETS: {
            return {
                ...state,
                availableAssets: getUpdatedAvailableAssets(state.availableAssets, action),
            };
        }
        case actionTypes.SET_WIZARD_STEP_STATE: {
            return {
                ...state,
                powertrainWizardStepStates: setWizardStepState(state.powertrainWizardStepStates, action.step, action.state)
            };
        }
        case actionTypes.SET_WIZARD_ACTIVE_STEP: {
            return {
                ...state,
                activeStep: action.step,
                selectedAssetTypeAvailableSection: initialState.selectedAssetTypeAvailableSection,
                assetFilterAvailableSection: initialState.assetFilterAvailableSection,
                selectedAssetTypeAddedSection: initialState.selectedAssetTypeAddedSection,
                assetFilterAddedSection: initialState.assetFilterAddedSection,
            };
        }
        case actionTypes.SET_SELECTED_POWERTRAIN_NAME: {
            return {
                ...state,
                powertrainName: action.powertrainName,
                powertrainDetailsChangedFlag: true,
            };
        }
        case actionTypes.SET_SELECTED_SITE: {
            return {
                ...state,
                selectedSite: action.site,
                addedAssets: initialState.addedAssets
            };
        }

        case actionTypes.SET_SELECTED_ASSET_TYPE_AVAILABLE_SECTION: {
            return {
                ...state,
                selectedAssetTypeAvailableSection: action.assetType
            };
        }
        case actionTypes.SET_ASSET_FILTER_AVAILABLE_SECTION: {
            return {
                ...state,
                assetFilterAvailableSection: action.assetFilter
            };
        }
        case actionTypes.ADD_ASSET: {
            return {
                ...state,
                addedAssets: [...addAssetToList(state.addedAssets, action.asset)],
                addedAssetsChangedFlag: true,
            };
        }
        case actionTypes.REMOVE_ASSET: {
            return {
                ...state,
                addedAssets: [...removeAssetFromList(state.addedAssets, action.asset)],
                addedAssetsChangedFlag: true,
            };
        }
        case actionTypes.SET_SELECTED_ASSET_TYPE_ADDED_SECTION: {
            return {
                ...state,
                selectedAssetTypeAddedSection: action.assetType
            };
        }
        case actionTypes.SET_ASSET_FILTER_ADDED_SECTION: {
            return {
                ...state,
                assetFilterAddedSection: action.assetFilter
            };
        }
        case actionTypes.SET_POWERTRAIN_FOR_EDITING: {
            return {
                ...state,
                isEditMode: true,
                ...disassemblePowertrain(action.powertrain)
            };
        }
        default:
            return state;
    }
};
