import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconAlarm = ({ width = 18, height = 18, color = 'none' }) => {
	return <svg width={width} height={height} viewBox='0 0 17 17' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='1' y='1' width='14' height='14' rx='7' fill={colors.pomegranate} />
		<path d='M8 12.1667C8.45833 12.1667 8.83333 11.7917 8.83333 11.3333H7.16666C7.16666 11.7917 7.5375 12.1667 8 12.1667ZM10.5 9.66666V7.58333C10.5 6.30416 9.81666 5.23333 8.625 4.95V4.66666C8.625 4.32083 8.34583 4.04166 8 4.04166C7.65416 4.04166 7.375 4.32083 7.375 4.66666V4.95C6.17916 5.23333 5.5 6.3 5.5 7.58333V9.66666L4.66666 10.5V10.9167H11.3333V10.5L10.5 9.66666Z' fill='white' />
	</svg>;
};

IconAlarm.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconAlarm;
