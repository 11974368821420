import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { find } from 'lodash';
import { createParamMeasurementAssetObj, getZoomingDates } from '../../helpers';
import { pagesWithTrendChart } from '../../constants';
import { transformPlotRequestData, getPlotAPI } from './helpers';

const resetChartAction = () => {
    return {
        type: actionTypes.RESET_CHART,
    };
};

export const setActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const resetChart = () => {
    return (dispatch) => {
        dispatch(resetChartAction());
    };
};

const setChartDataLoadingAction = getApiActionCreator(actionTypes.SET_CHART_DATA_LOADING_STATUS);

const loadKPIDataAction = (data, kpi, component, kpiType, trendChartZoom, color = undefined) => {
    return {
        type: actionTypes.LOAD_KPI_DATA,
        data,
        kpi,
        component,
        color,
        kpiType,
        trendChartZoom: trendChartZoom
    };
};

export const removeKPIData = (data) => {
    return {
        type: actionTypes.REMOVE_SELECTED_KPI,
        data
    };
};

export const removeSelectedKPI = (data) => {
    return {
        type: actionTypes.REMOVE_SELECTED_KPI_CHKBOX,
        data
    };
};

export const setSelectedDates = (from, to) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DATE_SELECTION, dateSelection: { from, to } });
    };
};

export const getChartDataForNewDate = (list, isTrendChartZoomed, fromZoom, toZoom) => {
    const componentKPITypes = transformPlotRequestData(list);
    return (dispatch, getState) => {
        const { chartView: { selectedKPIType } } = getState();
        let { fromDate, toDate, zoomed } = getZoomingDates(getState(), pagesWithTrendChart.crossAssetVisualization);

        if (isTrendChartZoomed) {
            fromDate = fromZoom;
            toDate = toZoom;
            zoomed = true;
        } else if (fromZoom && toZoom) {
            fromDate = fromZoom;
            toDate = toZoom;
            zoomed = false;
        }

        dispatch(setChartDataLoadingAction(apiCallStatus.LOADING));
        const apiKpiData = getPlotAPI(getState());
        apiKpiData(componentKPITypes, fromDate, toDate)
            .then((chartData) => {
                list.forEach((item) => {
                    if (chartData && Array.isArray(chartData)) {
                        const newValue = find(chartData, (o) => {
                            const itemIdentifier = (item.kpi.measurementTypeIdentifier || '').toLowerCase();
                            const oIdentifier = (o.config.measurementTypeIdentifier || '').toLowerCase();
                            return itemIdentifier === oIdentifier && item.componentKPIType[0].componentID === o.componentID;
                        });

                        dispatch(loadKPIDataAction(newValue ? [newValue] : [], item.kpi, item.component, selectedKPIType, {
                            isZoomed: zoomed,
                            from: fromDate,
                            to: toDate
                        }));
                    }
                });
                dispatch(setChartDataLoadingAction(apiCallStatus.SUCCESS));
            })
            .catch((err) => {
                dispatch(setChartDataLoadingAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const getChartData = (kpi, component) => {
    const formattedRequest = createParamMeasurementAssetObj(kpi, component);
    return (dispatch, getState) => {

        const { chartView: { selectedKPIType } } = getState();
        const { fromDate, toDate, zoomed } = getZoomingDates(getState(), pagesWithTrendChart.crossAssetVisualization);

        dispatch(setChartDataLoadingAction(apiCallStatus.LOADING));
        const apiKpiData = getPlotAPI(getState());
        apiKpiData(formattedRequest, fromDate, toDate)
            .then((chartData) => {
                dispatch(loadKPIDataAction(chartData, kpi, component, selectedKPIType, {
                    isZoomed: zoomed,
                    from: fromDate,
                    to: toDate
                }));
                dispatch(setChartDataLoadingAction(apiCallStatus.SUCCESS));
            })
            .catch((err) => {
                dispatch(setChartDataLoadingAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setIsZoomed = (isTrendChartZoomed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ZOOMED, isTrendChartZoomed });
    };
};

export const resetChartData = (list) => {
    return (dispatch, getState) => {
        const { from, to } = getState().overview.dateSelection;
        dispatch(getChartDataForNewDate(list, false, from, to));
    };
};

export const zoomInToSelectedTrendKpiData = (list, isTrendChartZoomed, from, to) => {
    return (dispatch) => {
        return dispatch(getChartDataForNewDate(list, isTrendChartZoomed, from, to));
    };
};
