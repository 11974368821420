import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconLeftArrow = ({ width = '16px', height = '16px', viewBox = '0 0 16 16', color = colors.mineShaft }) =>
	<svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M14.6667 7.33366H3.99999L7.66666 3.66699L6.66666 2.66699L1.33333 8.00033L6.66666 13.3337L7.66666 12.3337L3.99999 8.66699H14.6667V7.33366Z' fill={color}/>
	</svg>;

IconLeftArrow.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconLeftArrow;
