import { map } from 'lodash';

export const addPatchesForBrowsers = () => {
	if (!Object.entries) {
		Object.entries = (object) => {
			const res = [];
			map(object, (item, key) => {
				res.push([key, item]);
			});
			return res;
		};
	}
};
