import { actionTypes } from './constants';

const setSelectedAssetAction = (asset) => {
	return {
		type: actionTypes.SET_SELECTED_ASSET,
		asset
	};
};

const closeNotificationStatusBarAction = () => {
	return {
		type: actionTypes.CLOSE_NOTIFICATION_BAR,
	};
};

export const setActiveTabAction = (activeTab) => {
	return {
		type: actionTypes.SET_ACTIVE_TAB,
		activeTab
	};
};

export const toggleConditionStatusFilterAction = (conditionStatusID) => {
	return {
		type: actionTypes.TOGGLE_CONDITION_STATUS_FILTER,
		conditionStatusID
	};
};

export const setConditionIndexFilterAction = (conditionIndexFilter) => {
	return {
		type: actionTypes.SET_CONDITION_INDEX_FILTER,
		conditionIndexFilter
	};
};

export const setSearchboxFilterAction = (searchboxFilter) => {
	return {
		type: actionTypes.SET_SEARCHBOX_FILTER,
		searchboxFilter
	};
};

export const setGatewayTypeFilterAction = (gatewayTypeFilter) => {
	return {
		type: actionTypes.SET_GATEWAY_TYPE_FILTER,
		gatewayTypeFilter
	};
};

export const setSelectedAsset = (asset) => {
	return dispatch => {
		dispatch(closeNotificationStatusBarAction());
		dispatch(setSelectedAssetAction(asset));
	};
};

export const resetAssetAndPowertrainFilters = () => {
	return {
		type: actionTypes.RESET_FILTERS
	};
};

