import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import SelfFaultEvaluationCommentActions from './SelfFaultEvaluationCommentActions';
import SelfFaultEvaluationCommentList from './SelfFaultEvaluationCommentList';

import './style.scss';

const SelfEvaluateComponent = ({ actions, model, selectedAsset }) => {

	useEffect(() => {
		actions.getComments(selectedAsset, model.selectedEvent);
	}, []);

	return (
		<div className='self-evaluate-comments-section'>
			<Row>
				<SelfFaultEvaluationCommentActions
					model={model}
					actions={actions}
					selectedAsset={selectedAsset}
				/>

				<SelfFaultEvaluationCommentList
					model={model}
					actions={actions}
					selectedAsset={selectedAsset}
				/>
			</Row>
		</div>
	);
};

SelfEvaluateComponent.propTypes = {
	actions: PropTypes.shape({
		getComments: PropTypes.func,
	}),
	model: PropTypes.shape({
		selectedEvent: PropTypes.object,
	}),
	selectedAsset: PropTypes.object
};

export default SelfEvaluateComponent;
