import React from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { IconEnvelope, IconGlobe, IconPTUser } from 'svgIcons/MotionPortalIcons';
import {
    formatLanguages,
    formatSelectedLanguage,
} from './helpers';

import './style.scss';


const UserProfileComponent = ({
    model: {
        user,
        userLoadingStatus,
        availableLanguagesLoadingStatus,
        availableLanguages
    },
    actions
}) => {
    const { t: translate } = useTranslation();
    const selectedLanguage = user.data.languageCode;

    return <div className='user-profile-settings-content component_profile'>
        <div className='user-profile-settings-content-header'>{translate('ABB.Powertrain.Frontend.userProfileSettingProfile')}</div>
        <div className='profile-settings'>
            <LoadingStatusWrapper
                debounce={false}
                loadingStatus={[userLoadingStatus, availableLanguagesLoadingStatus]}
                transparent
                fullScreen >
                <div className='profile-item-wrapper'>
                    <div className='icon'>
                        <IconPTUser width='28' height='28' />
                    </div>
                    <div className='value-wrapper'>
                        <div className='item'>
                            {translate('ABB.Powertrain.Frontend.searchPlaceholderNameText')}
                        </div>
                        <div className='value'>
                            {`${user.data?.firstName} ${user.data?.lastName}`}
                        </div>
                    </div>
                </div>
                <div className='profile-item-wrapper'>
                    <div className='icon'>
                        <IconEnvelope />
                    </div>
                    <div className='value-wrapper'>
                        <div className='item'>
                            {translate('ABB.Powertrain.Frontend.emailLabel')}
                        </div>
                        <div className='value'>
                            {user.data?.email}
                        </div>
                    </div>
                </div>
                <div className='profile-item-wrapper'>
                    <div className='icon'>
                        <IconGlobe />
                    </div>
                    <form role='form'>
                        <div className='value-wrapper'>
                            <label htmlFor='profile-language-select' className='item'>
                                {translate('ABB.Powertrain.Frontend.languageLabel')}
                            </label>
                            <div className='value'>
                                <GeneralSelect
                                    inputId='profile-language-select'
                                    name='profile-language-select'
                                    id='language_dropdown'
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    value={formatSelectedLanguage(selectedLanguage, availableLanguages)}
                                    options={formatLanguages(availableLanguages)}
                                    onChange={(languageCode) => {
                                        actions.updateSelectedLanguage(languageCode.id);
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </LoadingStatusWrapper>
        </div>
    </div>;
};

export default UserProfileComponent;
