import { filter, sortBy, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsKPILoaded, selectShouldScrollToSelection } from '../selectors';
import { getTranslatedKPIName } from 'helpers/translateHelper';


const translateAndSortKPIs = (kpis) => {
    return sortBy(map(kpis, (kpi) => {
        return {
            ...kpi,
            displayName: getTranslatedKPIName(kpi)
        };
    }), (kpi) => kpi?.displayName?.toLowerCase());
};

export const getKPIListForAsset = (kpiList) => {
    const parameterKPIList = translateAndSortKPIs(filter(kpiList?.kpis, (kpi) => {
        return kpi && kpi.allSignal === true;
    }));

    const standardKPIList = translateAndSortKPIs(filter(kpiList?.kpis, (kpi) => {
        return kpi && kpi.allSignal === false;
    }));
    return {
        kpis:
        {
            parameterKPIList,
            standardKPIList
        },
        loadingStatus: kpiList?.loadingStatus
    };
};

export const getSelectedParameterSignals = (chartData) => {
    return filter(chartData, (data) => data.kpi.allSignal).map(filteredData => filteredData.kpi);
};


export const useKPILoadingTrigger = () => {

    const [scrollTriggered, setScrollTriggered] = useState(false);
    const isKpiLoaded = useSelector((state) => selectIsKPILoaded(state));
    const shouldScrollToSelection = useSelector((state) => selectShouldScrollToSelection(state));

    useEffect(() => {
        if (!scrollTriggered && isKpiLoaded && shouldScrollToSelection) {
            setScrollTriggered(true);
        }
    }, [isKpiLoaded]);

    return scrollTriggered;
};


export const isKpiSelected = (chartData, kpi) => {
    if (chartData?.filter((selectedKPI) => selectedKPI?.kpi?.componentID === kpi?.componentID && selectedKPI?.kpi.measurementTypeKey === kpi?.measurementTypeKey)?.length > 0) {
        return true;
    }
    return false;
};
