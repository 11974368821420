import { approveBroadcastedMessageHandler, getBroadcastedMessage } from '../../../../components/Portal/helpers';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { mainMenuLinks } from './constants';
import { useSelector } from 'react-redux';


const useModalOptions = (setModalVisible, setCurrentModal, acceptActions, closeModal) => {
    const { t: translate } = useTranslation();
    const modalOptions = useRef([]);
    const isEULAAccepted = useSelector((state) => state.overview.user?.data?.isEULAAccepted);
    const isBroadcastedMessageAccepted = getBroadcastedMessage() ? false : true;

    const showEulaFunction = () => {
        setCurrentModal({
            modalType: 'eula',
            modalMetadata: {
                link: mainMenuLinks.eula,
                closeOnAcceptOnly: true,
                persistent: true,
                acceptButtonProps: {
                    text: translate('ABB.Powertrain.Frontend.acceptButton'),
                    onClick: () => {
                        acceptActions.EULA();
                        closeModal();
                    }
                }
            }
        });
    };

    const showBroadcastMessageFunction = (broadcastedMessage) => {
        setCurrentModal({
            modalType: 'approveOnlyBroadcastPDF',
            modalMetadata: {
                pdfContent: broadcastedMessage?.messagePath,
                acceptButtonProps: {
                    text: translate('ABB.Powertrain.Frontend.acknowledgeButton'),
                    onClick: () => {
                        approveBroadcastedMessageHandler(getBroadcastedMessage()?.broadcastMessageID);
                        closeModal();
                    }
                }
            }
        });
    };


    useEffect(() => {
        const broadcastedMessage = getBroadcastedMessage();
        const showEula = isEULAAccepted !== undefined && !isEULAAccepted;
        const showBroadcastMessage = broadcastedMessage !== undefined && broadcastedMessage?.broadcastMessageID;
        setModalVisible(true);

        showEula && showEulaFunction();
        !showEula && showBroadcastMessage && showBroadcastMessageFunction(broadcastedMessage);

    }, [isEULAAccepted, isBroadcastedMessageAccepted]);

    return modalOptions.current;
};

export default useModalOptions;
