import { UserProfileApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';
import localStorageHelper from 'helpers/localStorageHelper';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';

const acceptEULAAction = getApiActionCreator(actionTypes.ACCEPT_EULA);

export const acceptEULA = () => {
    return (dispatch, getState) => {
        const userData = getState().overview.user.data;
        dispatch(acceptEULAAction(apiCallStatus.LOADING));
        UserProfileApi.acceptEULA()
            .then((response) => {
                if (response.success) {
                    localStorageHelper.setItem('user', { ...userData, isEULAAccepted: true });
                    dispatch(acceptEULAAction(apiCallStatus.SUCCESS, { isEULAAccepted: true }));
                }
                else {
                    throw { message: 'Accepting EULA was not successful' };
                }
            }, (err) => {
                dispatch(acceptEULAAction(apiCallStatus.ERROR, null, err));
            });
    };
};
