import React from 'react';

import { IconInfoFilled } from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';

const SensorPropertiesNotAvailable = ({ text }) => {
	return <div className='sensor-properties-not-available-component'>
		<div className='sensor-properties-not-available-img-text'>
			<div className='data-icon'>
				<div className='info-icon'>
					<IconInfoFilled width={'44px'} height={'44px'} color={colors.nero} />
				</div>
			</div>
			<div className='not-available-text'>{text}</div>
		</div>
	</div>;
};

export default SensorPropertiesNotAvailable;
