import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconAdd = ({ width = '48px', height = '48px', viewBox = '0 0 48 48', color = colors.black, showCircle = false, style }) => {
	return <svg width={width} height={height} viewBox={viewBox} xmlns='http://www.w3.org/2000/svg' style={style}>
		{showCircle &&
			<circle cx='23.65' cy='23.72' r='21.24' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		}
		<line x1='23.56' y1='37.08' x2='23.92' y2='10.2' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<line x1='10.3' y1='23.46' x2='37.18' y2='23.82' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
	</svg>;
};

IconAdd.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string,
	showCircle: PropTypes.bool,
	style: PropTypes.object
};

export default IconAdd;
