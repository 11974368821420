import React from 'react';
import OverviewPowertrainsComponent from './OverviewPowertrainsComponent';
import { connect } from 'react-redux';
import { getModel } from './selectors';

const OverviewPowertrainsContainer = (props) => {
    return <OverviewPowertrainsComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const OverviewPowertrainsContainerConn = connect(
    mapStateToProps
)(OverviewPowertrainsContainer);

export default OverviewPowertrainsContainerConn;
