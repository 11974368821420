import { createSelector } from 'reselect';


const getSelectedAssets = ({ notificationSettings }) => {
    return notificationSettings?.selectedAssets;
};

const getMutedAssetsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.loadingStatus;
};

const getMutedAssetList = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.data || [];
};

const getMutedAssets = createSelector([
    getMutedAssetsLoadingStatus,
    getMutedAssetList
], (
    mutedAssetsLoadingStatus,
    assetList
) => {
    return {
        mutedAssetsLoadingStatus,
        assetList
    };
});

export const getModel = createSelector(
    [
        getSelectedAssets,
        getMutedAssets,
    ],
    (
        selectedAssets,
        mutedAssets,
    ) => {
        return {
            selectedAssets,
            mutedAssets,
        };
    }
);
