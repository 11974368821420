import { createSelector } from 'reselect';


const getAssetUpdateStatus = ({ detailedInformation }) => {
	return detailedInformation.renameAsset?.loadingStatus;
};

const getAssetRenameStatus = ({ detailedInformation }) => {
	return detailedInformation.renamedAsset;
};

export const getModel = createSelector([
	getAssetUpdateStatus,
	getAssetRenameStatus
],
	(
		assetUpdateStatus,
		assetRenameStatus
	) => {
		return {
			assetUpdateStatus,
			assetRenameStatus
		};
	});
