import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { acceptedExtensions, maxFileSize } from './constants';
import { isExtensionSupported, getUploadStatusIcon } from './helpers';
import { IconAddFile, IconClose } from 'svgIcons/MotionPortalIcons';

import './styles.scss';

const BulkUploadFileSectionComponent = (props) => {
    const { t: translate } = useTranslation();
    const hiddenFileInput = useRef(null);
    const [uploadDisabled, setUploadDisabled] = useState(false);

    useEffect(() => {
        return () => {
            return props.fileSelection.clearFileSection();
        };
    }, []);

    useEffect(() => {
        setUploadDisabled(props.isUploading || props.isProcessing || !props.isUploadAllowed);
    }, [props.isUploading, props.isProcessing, props.isUploadAllowed]);

    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: acceptedExtensions,
        maxSize: maxFileSize,
        onDrop: acceptedFiles => props.fileSelection.updateSelectedFiles(acceptedFiles),
        disabled: uploadDisabled,
    });

    const handleFileSelectClick = () => {
        hiddenFileInput.current.click();
    };

    const removeFile = (index) => {
        props.fileSelection.removeSelectedFile(index);
    };

    const renderFiles = (files) => {
        return files.map((file, i) =>
            <div className='bulk-upload-selected-file' key={'fileOption' + i} >
                {getUploadStatusIcon(file.uploadStatus)}
                <div>{file.fileContent.name}</div>
                <div className={uploadDisabled ? 'bulk-upload-selected-file-delete disabled' : 'bulk-upload-selected-file-delete'}
                    disabled={uploadDisabled}
                    id={i}
                    onClick={(e) => { !uploadDisabled && removeFile(e.currentTarget.id); }} >
                    <IconClose />
                </div>
            </div>
        );
    };

    const handleChange = event => {
        let bulkFiles = [];
        if (event && event.target && event.target.files) {
            map(event.target.files, (file) => {
                const fileName = file.name;
                if (isExtensionSupported(fileName) && file.size < maxFileSize) {
                    bulkFiles = [...bulkFiles, file];
                }
            });
        }
        props.fileSelection.updateSelectedFiles(bulkFiles);
        event.target.value = null;
    };

    return (
        <div className='bulk-file-section' id='bulk-file-section'>
            <Row className='section-heading' >
                <Col md={4} className='bulk-data-upload-container'>
                    <span className='section-label'>{translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDataUpload')}</span>
                </Col>
                <Col className='section-body'>
                    <span>{translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadFileSectionDropAFile')}</span>
                    <span className={uploadDisabled ? 'select-bulk-upload disabled' : 'select-bulk-upload'}
                        onClick={handleFileSelectClick}>
                        {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadFileSectionSelectFiles')}
                    </span>
                    <input
                        type='file'
                        className='select-bulk-upload-input'
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        accept={Object.values(acceptedExtensions)}
                        disabled={uploadDisabled}
                        multiple
                    />
                </Col>
            </Row>
            <Row className='dropzone-row'>
                <Col>
                    <div className='dropzone-container dropzone-scrollbar dropzone-scrollbar-primary'>
                        <div {...getRootProps(props.bulkFiles.length === 0 ? { className: 'dropzone-no-file' } : { className: 'dropzone-file' })}>
                            <input {...getInputProps()} />
                            {props.bulkFiles.length === 0 ?
                                <Row className='justify-content-md-center'>
                                    <Col md='auto' className='icon-file-drop'>
                                        <IconAddFile />
                                    </Col>
                                </Row> : null}
                            {
                                props.bulkFiles.length === 0
                                    ? <Row className='justify-content-md-center'>
                                        <Col md='auto' className='upload-label'>
                                            {!props.isUploadAllowed
                                                ? translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDisabledLabel')
                                                : translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadDescription')}</Col>
                                    </Row>
                                    : null
                            }
                            {renderFiles(props.bulkFiles)}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

BulkUploadFileSectionComponent.propTypes = {
    bulkFiles: PropTypes.array.isRequired,
    fileSelection: PropTypes.object.isRequired,
    isUploadAllowed: PropTypes.bool,
    isUploading: PropTypes.bool,
    isProcessing: PropTypes.bool,
};

export default BulkUploadFileSectionComponent;
