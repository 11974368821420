import { sortBy } from 'lodash';
import { ALL_ASSETS } from './constants';

export const formatAssetList = (currentAsset, assetList = [], translate) => {
    let assets = [];
    assetList.forEach((d) => {
        if (d.componentID !== currentAsset.componentID) {
            assets.push({
                label: d.componentName,
                value: d.componentID,
                isDisabled: !d.hasCapability,
                assets: [d]
            });
        }
    });

    assets.push({
        label: currentAsset.componentName,
        value: currentAsset.componentID,
        isDisabled: false,
        assets: [currentAsset]
    });

    assets = sortBy(assets, d => d.label);

    return [{
        label: translate('ABB.Powertrain.Frontend.allAssetsLabel'),
        value: ALL_ASSETS,
        isDisabled: false,
        assets: assetList.filter(d => d.hasCapability)
    }, ...assets];
};

export const formatSettings = (settings, assets, maxEmailAlertsPerDayEnabled, maxEmailAlertsPerDay, alertTypes) => {
    const formattedSettings = settings;
    formattedSettings.componentIDs = assets.map(d => d.componentID);
    formattedSettings.maxEmailAlertsPerDayEnabled = maxEmailAlertsPerDayEnabled;
    formattedSettings.maxEmailAlertsPerDay = maxEmailAlertsPerDay;
    formattedSettings.data = alertTypes.map((at) => ({
        code: at.code,
        enabled: at.enabled
    }));
    return formattedSettings;
};
