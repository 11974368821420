
import { StateID } from 'helpers/constants';

export const getConditionClass = (condition) => {
    const conditionClasses = {
        [StateID.POOR]: 'poor',
        [StateID.TOLERABLE]: 'tolerable',
        [StateID.GOOD]: 'good',
        [StateID.UNKNOWN]: 'unknown',
        [StateID.NOT_APPLICABLE]: 'not-applicable',
    };
    return conditionClasses[condition] ?? '';
};


export const getSelectedClass = (selected) => {
    switch (selected) {
        case true:
            return 'opened';
        case false:
            return 'faded';
        default:
            return '';
    }
};


