import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SensorDetailsList from './SensorDetailsList';

const SensorDetailsComponent = (props) => {

    const { t: translate } = useTranslation();

    const sensorProperties = props.model.sensorProperties.data;
    const gatewayInfo = props.model.gatewayInfo.data;
    const isFirmwareUpdateEnforced = sensorProperties &&
        sensorProperties.applicationData &&
        sensorProperties.applicationData.isFirmwareUpdateEnforced ?
        sensorProperties.applicationData.isFirmwareUpdateEnforced : null;

    return <div className='sensor-properties-list'>
        <div className='sensor-properties-list-title'>{translate('ABB.Powertrain.Frontend.sensorDetails')}</div>
        <SensorDetailsList sensorProperties={sensorProperties}
            gatewayInfo={gatewayInfo}
            sensorTypeId={props.model.sensorTypeId}
            isFirmwareUpdateEnforced={isFirmwareUpdateEnforced}
        />
    </div>;
};

export default SensorDetailsComponent;

SensorDetailsComponent.propTypes = {
    model: PropTypes.shape({
        gatewayInfo: PropTypes.object,
        selectedAssetId: PropTypes.number,
        sensorProperties: PropTypes.object,
        sensorIdentifier: PropTypes.string,
        sensorTypeId: PropTypes.number,
    })
};
