import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconPTUser = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => {
    return <div>
        <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 5C11.2044 5 10.4413 5.31607 9.87868 5.87868C9.31607 6.44129 9 7.20435 9 8C9 8.79565 9.31607 9.55871 9.87868 10.1213C10.4413 10.6839 11.2044 11 12 11C12.7956 11 13.5587 10.6839 14.1213 10.1213C14.6839 9.55871 15 8.79565 15 8C15 7.20435 14.6839 6.44129 14.1213 5.87868C13.5587 5.31607 12.7956 5 12 5ZM12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM19 17.65V17.32C19.063 16.2414 18.6966 15.1816 17.9809 14.3722C17.2652 13.5628 16.2582 13.0695 15.18 13H8.82C7.74177 13.0695 6.73483 13.5628 6.0191 14.3722C5.30338 15.1816 4.937 16.2414 5 17.32V17.65C3.93207 16.3285 3.26002 14.7317 3.06159 13.0442C2.86316 11.3568 3.14644 9.64766 3.87868 8.11447C4.61092 6.58128 5.7622 5.28668 7.19938 4.38039C8.63656 3.4741 10.3009 2.99315 12 2.99315C13.6991 2.99315 15.3634 3.4741 16.8006 4.38039C18.2378 5.28668 19.3891 6.58128 20.1213 8.11447C20.8536 9.64766 21.1368 11.3568 20.9384 13.0442C20.74 14.7317 20.0679 16.3285 19 17.65Z' fill={colors.mineShaft} />
        </svg>
    </div>;
};

IconPTUser.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string
};

export default IconPTUser;
