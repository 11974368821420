import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconAssetAging = ({ width = 16, height = 4, viewBox = '0 0 16 4', color = colors.dodgerBlue }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.000488281 2C0.000488281 1.17157 0.448204 0.5 1.00049 0.5H15.0005C15.5528 0.5 16.0005 1.17157 16.0005 2C16.0005 2.82843 15.5528 3.5 15.0005 3.5H1.00049C0.448204 3.5 0.000488281 2.82843 0.000488281 2Z' fill={color} />
    </svg>;
};

IconAssetAging.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconAssetAging;
