import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConditionIndicesOverviewComponent from './ConditionIndicesOverviewComponent';
import * as actions from './actions';
import { getModel } from './selectors';


const ConditionIndicesOverviewContainer = (props) => {
    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <ConditionIndicesOverviewComponent {...props} />;
};

ConditionIndicesOverviewContainer.propTypes = {
    actions: PropTypes.shape({
        clearStore: PropTypes.func
    })
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionIndicesOverviewContainer);
