import filter from 'lodash/filter';
import find from 'lodash/find';
import keys from 'lodash/keys';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import {
    excludedFromStandard,
    includedFromAdditional
} from './constants';
import { chartColors } from 'helpers/constants';
import { getTranslatedKPIName } from 'helpers/translateHelper';
import {
    createParamMeasurementComponentObj,
    getFlatAvailableKPIList
} from '../../../commonTrendHelpers';


const getStandardKPIsToDisplay = (standardKPIs) => {
    return filter(keys(standardKPIs), (kpi) => {
        return !find(excludedFromStandard, (excludedKPI) => {
            return kpi === excludedKPI;
        });
    });
};

const getAdditionalKPIsToDisplay = (additionalKPIs) => {
    return filter(keys(additionalKPIs), (kpi) => {
        return find(includedFromAdditional, (includedKPI) => {
            return kpi === includedKPI;
        });
    });
};


export const getMultiGraphConfig = (kpis) => {
    return kpis && kpis.length === 2 ?
        [
            ...getStandardKPIsToDisplay(kpis[0].data),
            ...getAdditionalKPIsToDisplay(kpis[1].data)
        ] :
        [];
};

export const formatMultiGraphRequest = (kpisToDisplay, allKPIs, selectedAsset) => {
    const availableKPIs = getFlatAvailableKPIList(allKPIs);

    const measurementTypes = map(kpisToDisplay, (kpi) => {
        return availableKPIs[kpi];
    });

    return createParamMeasurementComponentObj(measurementTypes, selectedAsset);
};

export const getUserLimitsForChart = (userLimits, kpis) => {
    const userLimitsForKPI = filter(userLimits, (userLimit) => {
        return findIndex(kpis, (kpi) => userLimit.signalCode.toLowerCase() === kpi.toLowerCase()) > -1;
    });
    return userLimitsForKPI || [];
};

export const formatMultiTrendData = ({ data, isZoomed, from = null, to = null } = {}, loadingStatus) => {
    const multiTrendData = map(data, (chartData) => {
        return {
            kpiList: [{
                color: chartColors[0],
                values: chartData.values,
                kpiIdentifier: chartData?.config?.measurementTypeIdentifier
            }],
            zoom: { isZoomed: isZoomed, from, to },
            loadingStatus: loadingStatus,
            chartName: getTranslatedKPIName(chartData.config),
            chartID: chartData?.config?.measurementTypeIdentifier
        };
    });

    return multiTrendData;
};

export const setMultiTrendDataToLoadingStatus = (charts, isZoomed, from, to, loadingStatus) => {
    const multiTrendData = map(charts, (chartData) => {
        return {
            ...chartData,
            zoom: { isZoomed: isZoomed, from, to },
            loadingStatus
        };
    });

    return multiTrendData;
};
