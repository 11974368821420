import PropTypes from 'prop-types';
import React from 'react';
import { replace } from 'lodash';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import { GeneralInput } from 'sharedComponents';

import { isPrecisionCorrect } from './helpers';


const OffsetConfigRow = (props) => {
    const { t: translate } = useTranslation();
    const { setOffset, justifyContentEnd, selectedKpi: { config, measurementTypeID }, isOutOfRange, showCalibrationError } = props;

    const onOffsetChange = (value) => {
        value = typeof value === 'string' ? replace(value, ',', '.') : value;
        if (isPrecisionCorrect(value, config.precision)) {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                setOffset(measurementTypeID, parsedValue);
            }
            else {
                setOffset(measurementTypeID, null);
            }
        }
    };

    return <div className={`alert-alarm-dialog-row ${justifyContentEnd ? 'justify-content-end' : 'justify-content-start'}`}>
        <div className='alert-alarm-input-wrapper d-flex align-items-end'>
            <div className='alert-alarm-input d-flex'>
                <span className='aa-input-label'>{translate('ABB.Powertrain.Frontend.calibrationValue')}</span>
                <GeneralInput
                    id='kpi-offset'
                    numeric={true}
                    value={config.offset}
                    onChange={(value) => {
                        onOffsetChange(value);
                    }}
                    isOutOfRange={isOutOfRange}
                    clearable={false}
                    validationFunction={() => !showCalibrationError}
                    validationMessage={translate('ABB.Powertrain.Frontend.calibrationOffsetTooBigError')}
                />
            </div>
            <div className='alert-alarm-info d-flex align-items-center'>
                <Tippy
                    content={translate('ABB.Powertrain.Frontend.calibrationInfo')}
                    interactive={true}
                    interactiveBorder={10}
                    animation='scale'
                    theme='light-border'
                    trigger='click'
                    maxWidth={450}
                    placement='left-start'
                    offset='-30,0'
                >
                    <div className='info-icon'>
                        <IconInfo width={'14px'} height={'14px'} />
                    </div>
                </Tippy>
            </div>
        </div>
    </div>;
};

OffsetConfigRow.propTypes = {
    isOutOfRange: PropTypes.func,
    setOffset: PropTypes.func,
    showCalibrationError: PropTypes.bool
};

export default OffsetConfigRow;
