import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventLogComponent from './EventLogComponent';
import { getModel } from './selectors';
import * as actions from './actions';
import './style.scss';


const EventLogContainer = (props) => {

	return <div id='event-log-tab-container'>
		<EventLogComponent {...props} />
	</div>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const EventLogContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(EventLogContainer);

export default EventLogContainerConn;
