import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-unused-modules */
// eslint-disable-next-line no-unused-vars
import { translate } from 'helpers/translateHelper';
import { PopOutChart } from 'sharedComponents/Chart';
import { PopOutButton, ResetZoomButton, withLeftButtonGroup, withPopOutRow, withRightButtonGroup } from 'sharedComponents/Chart/headers';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';
import { InfoMessage } from 'sharedComponents/InfoMessage';
import useChartConfig, { chartPlugins } from './useChartConfig';
import './styles.scss';


const DriveStatusChartComponent = ({ trendDataLoadingStatus, selectedTrendsData, isZoomed, title, conditionStatus }) => {
    const ChartTitle = () => {
        return <div className={`${selectedTrendsData.length ? 'dive-status-title--no-data' : 'drive-status-title'}`}>
            <span className='drive-status-icon'><IconCondition condition={conditionStatus}></IconCondition></span>
                <b>{title}</b>
                <InfoButtonWithOnlineHelp infoText={translate(
                    'ABB.Powertrain.Frontend.conditionTrendDriveTooltip'
                )} withoutLink />
            </div>;
    };

    const MainChartHeader = withPopOutRow([
        withLeftButtonGroup([ChartTitle]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    /*const PopOutChartHeader = withPopOutRow([
        withLeftButtonGroup([ChartTitle]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);*/
    const cyclicLoadChartOptions = useChartConfig(selectedTrendsData, 'Cyclic');
    return (
        <div className={'cyclic-chart drive-status__chart'}>
            <PopOutChart
                hasKpi={true}
                renderNoKpi={
                    () => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                        text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />
                }
                loadingStatus={trendDataLoadingStatus}
                options={cyclicLoadChartOptions}
                modules={chartPlugins}
                container={'drivestatus-chart-pop-out'}
                isZoomed={isZoomed}
                setIsZoomed={() => { }}
                mainChartHeader={MainChartHeader}
            //popOutChartHeader={PopOutChartHeader}
            //popOutFooter={<Legend />}
            />
        </div>
    );
};
DriveStatusChartComponent.propTypes = {
    setIsZoomed: PropTypes.func,
    selectedTrendsData: PropTypes.array,
    trendDataLoadingStatus: PropTypes.string,
    isZoomed: PropTypes.bool,
    chartKey: PropTypes.string,
    yAxisTitleVisible: PropTypes.bool,
    legendVisible: PropTypes.bool,
    title: PropTypes.string,
    conditionStatus: PropTypes.number
};
export default DriveStatusChartComponent;


