import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { EventTypes, eventStatus } from '../../constants';
import { LoadingStatusWrapper } from 'sharedComponents';
import { pageSize } from './constants';
import { IconLeftArrow } from 'svgIcons/MotionPortalIcons';
import { paginateCommentList } from 'helpers/paginationHelper';
import EventDetailsSSCommentList from './EventDetailsSSCommentList';
import EventDetailsSSCommentActions from './EventDetailsSSCommentActions';

import './style.scss';


const EventDetailsSSComponent = (props) => {

    const { t: translate } = useTranslation();
    const event = props.model.selectedEvent.rawEvent;

    const [selectedPage, setSelectedPage] = useState(1);
    const [displayedComments, setDisplayedComments] = useState([]);

    const calculateDisplayedCommentList = (startIndex, endIndex) => {
        setDisplayedComments(paginateCommentList(props.model.eventComments.data, startIndex, endIndex));
    };

    useEffect(() => {
        props.actions.getComments(props.asset, event);
    }, []);

    useEffect(() => {
        calculateDisplayedCommentList((selectedPage - 1) * pageSize, selectedPage * pageSize - 1);
    }, [selectedPage, props.model.eventComments.data]);

    useEffect(() => () => {
        props.setSelectedEvent(null);
    }, []);

    return (
        <div className='event-detailsSS-content'>
            <div className='event-details-header'>
                <div className='event-type-icon'>{EventTypes.find(et => et.typeID === event.typeName).icon}</div>
                <div className='event-type-group' xl={{ span: 5, order: 1 }} xs={{ span: 9, order: 1 }}>
                    <div>{event.eventName}</div>
                    <div className='event-log-additional-details'>
                        <div>{translate('ABB.Powertrain.Frontend.eventDetailsAdviceLbl')}: {event.description}</div>
                        <div>{translate('ABB.Powertrain.Frontend.eventDetailsLoggedOnLbl')}: {convertUtcToLocalDateTime(event.utc)}</div>
                    </div>
                </div>
                <div className='back-to-event-list'>
                    <div className='switch-component'>
                        <IconLeftArrow />
                        <span> {translate('ABB.Powertrain.Frontend.eventDetailsBackToLbl')} </span>
                        <span onClick={() => props.setSelectedEvent(null)}>
                            <span className='event-list-lbl'> {translate('ABB.Powertrain.Frontend.eventDetailsEventListLbl')} </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className='event-details-comments-section'>
                <Row>
                    <LoadingStatusWrapper loadingStatus={props.model.eventClosingLoadingStatus}>
                        {event.status !== eventStatus.CLOSED ?
                            <EventDetailsSSCommentActions
                                model={props.model}
                                actions={props.actions}
                                asset={props.asset}
                            /> : null
                        }
                        <EventDetailsSSCommentList
                            model={props.model}
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            displayedComments={displayedComments}
                        />
                    </LoadingStatusWrapper>
                </Row>
            </div>
        </div>
    );
};

EventDetailsSSComponent.propTypes = {
    actions: PropTypes.shape({
        getComments: PropTypes.func,
    }),
    model: PropTypes.shape({
        eventComments: PropTypes.object,
        selectedEvent: PropTypes.object,
        eventClosingLoadingStatus: PropTypes.string,
    }),
};

export default EventDetailsSSComponent;

