import { actionTypes } from './constants';

const initialState = {
    issueTypes: {
        data: null,
        error: null,
        loadingStatus: null
    },
    reportData: {
        error: null,
        loadingStatus: null
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_ISSUE_TYPES:
            return {
                ...state,
                issueTypes: {
                    ...action
                }
            };
        case actionTypes.REPORT_ISSUE:
            return {
                ...state,
                reportData: {
                    ...action
                }
            };
        case actionTypes.CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}
