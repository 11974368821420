import React from 'react';

import { environmentStages } from 'helpers/constants';
import { cookieConsentKey, loginUrls } from './constants';
import cookieHelper from 'helpers/cookieHelper';
import localStorageHelper from 'helpers/localStorageHelper';
import { IconWarning } from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';

export const getNotProductionWarning = (environmentStage) => {
    if (environmentStage !== environmentStages.PROD) {
        return <div className='not-production-warning-container'>
            <IconWarning width={28} height={28} color={colors.orangePeel} />
            <div>This is a development version of the portal.
                <br />
                <a
                    className='not-production-warning'
                    href={loginUrls.powertrainProductionSite}
                >
                    Click here to navigate to the official version.
                </a>
            </div>
        </div>;
    }

    return null;
};

export const getErrorMessage = (errorMessage) => {
    if (errorMessage) {
        return (
            <p className='error-message'>
                {errorMessage}
            </p>
        );
    }

    return null;
};

export const hasCookieConsent = () => {
    return cookieHelper.getCookie(cookieConsentKey) && true;
};

export const storeConsent = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 20);
    cookieHelper.setCookie(cookieConsentKey, true, expirationDate);
};

export const setReturnUrl = (returnUrl) => {
    returnUrl && localStorageHelper.setItem('returnUrl', returnUrl.replace('/2.0', ''));
};

export const stopClarityDataCollecting = () => {
    window.clarity && window.clarity('stop');
};
