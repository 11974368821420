import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';
import { adjustEndDate } from './helpers';


class Report {
    static getReports(organizationsArray, isAdmin) {
        const endpoint = formatEndpoint(endpoints.report.getReports, { isAdmin });
        const organizations = organizationsArray.map((organization) => organization.organizationID.toString());
        return RestManagerWithoutBaseUrl.post(endpoint, { organizations });
    }

    static downloadReport(reportId) {
        const endpoint = formatEndpoint(endpoints.report.downloadReport, { reportId });
        return RestManagerWithoutBaseUrl.post(endpoint);
    }

    static generateReport(reportTypeIdentifier, assetIdentifier, measurementUnit) {
        const endpoint = formatEndpoint(endpoints.report.generateReport);
        return RestManagerWithoutBaseUrl.post(endpoint, { reportTypeIdentifier, assetIdentifier, measurementUnit });
    }

    static uploadReport(reportUploadParams) {
        const endpoint = formatEndpoint(endpoints.report.uploadReport);
        return RestManagerWithoutBaseUrl.post(endpoint, reportUploadParams);
    }

    static publishReport(reportId) {
        const endpoint = formatEndpoint(endpoints.report.publishReport, { reportId });
        return RestManagerWithoutBaseUrl.put(endpoint);
    }

    static rejectReport(reportId) {
        const endpoint = formatEndpoint(endpoints.report.rejectReport, { reportId });
        return RestManagerWithoutBaseUrl.put(endpoint);
    }

    static generateFleetReport(generateFleetReportParams) {
        const endpoint = formatEndpoint(endpoints.report.generateFleetReport);
        generateFleetReportParams.reportEndDate = adjustEndDate(generateFleetReportParams.reportEndDate);
        return RestManagerWithoutBaseUrl.post(endpoint, generateFleetReportParams);
    }

    static deleteReport(reportId) {
        const endpoint = formatEndpoint(endpoints.report.deleteReport, { reportId });
        return RestManagerWithoutBaseUrl.put(endpoint);
    }
}

export default Report;
