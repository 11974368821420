import { createSelector } from 'reselect';

import { filterPowertrainsOverview } from 'helpers/assetAndPowertrainFilterHelper';
import { groupAndSortByOverallStatus, orderInAlphabet } from '../helpers';
import { getGeneralFilteredPowertrains } from 'helpers/selectors';


const getFilters = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview;
};

const getEnabledFilteredPowertrains = createSelector(
    [getGeneralFilteredPowertrains, getFilters],
    (powertrains, filters) => {
        return filterPowertrainsOverview(powertrains, filters);
    }
);

const getFormattedPowertrains = createSelector(
    [getEnabledFilteredPowertrains],
    (filteredPowertrains) => {
        const groupedPowertrains = groupAndSortByOverallStatus(filteredPowertrains);
        return orderInAlphabet(groupedPowertrains);
    }
);

const getGeneralFilteredPowertrainsCount = createSelector([getGeneralFilteredPowertrains],
    (powertrains) => {
        return powertrains.length;
    }
);


export const getModel = createSelector([
    getGeneralFilteredPowertrainsCount,
    getFormattedPowertrains,
    getFilters
], (
    generalFilteredPowertrainsCount,
    powertrains,
    filters
) => ({
    generalFilteredPowertrainsCount,
    powertrains,
    filters
}));
