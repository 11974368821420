import { actionTypes, advancedAnalyticsButtons } from './constants';
import { getInitialEndDate, getInitialStartDate } from 'sharedComponents/TimeSelectorRow/helpers';


const initialState = {
    activeTab: advancedAnalyticsButtons.annualMaintenancePlan,
    annualMaintenancePlan: {
        data: null,
        error: null,
        loadingStatus: null
    },
    dailyAgingStress: {
        data: null,
        error: null,
        loadingStatus: null,
        dateSelection: {
            from: getInitialStartDate(30),
            to: getInitialEndDate()
        }
    }
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab
            };
        case actionTypes.GET_ANNUAL_MAINTENANCE_PLAN:
            return {
                ...state,
                annualMaintenancePlan: { ...action }
            };
        case actionTypes.GET_DAILY_AGING_STRESS:
            return {
                ...state,
                dailyAgingStress: {
                    ...state.dailyAgingStress,
                    ...action
                }
            };
        case actionTypes.SET_DATE_SELECTION:
            return {
                ...state,
                dailyAgingStress: {
                    ...state.dailyAgingStress,
                    dateSelection: {
                        ...action.dateSelection
                    }
                }
            };
        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                annualMaintenancePlan: initialState.annualMaintenancePlan,
                dailyAgingStress: {
                    ...initialState.dailyAgingStress,
                    dateSelection: {
                        ...state.dailyAgingStress.dateSelection
                    }
                }
            };
        }
        default:
            return state;
    }
};
