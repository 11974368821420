import React from 'react';
import PropTypes from 'prop-types';

const IconChecked = ({ width = '15px', height = '10px', color='none' }) => {
    return <svg className='checked-vector-icon' width={width} height={height} viewBox='0 0 10 10' fill={color} xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.5 0.5L5 7L2 4L0.5 5.5L5 10L13 2L11.5 0.5Z' />
        </svg>;
};

IconChecked.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

export default IconChecked;
