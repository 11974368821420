import { createSelector } from 'reselect';


const getNameplatePictures = ({ assetDetailsInfoHeader }) => {
	return {
		data: assetDetailsInfoHeader.nameplatePictures.data,
		loadingStatus: assetDetailsInfoHeader.nameplatePictures.loadingStatus
	};
};

const getAssetNameplateImageUploadInfo = ({ assetNameplateImage }) => {
	return {
		data: assetNameplateImage.assetPictureUpload.data,
		loadingStatus: assetNameplateImage.assetPictureUpload.loadingStatus
	};
};

export const getModel = createSelector([
	getNameplatePictures,
	getAssetNameplateImageUploadInfo
], (
	pictures,
	assetNameplateImage
) => {
	return {
		pictures,
		assetNameplateImage
	};
});
