const loadLanguage = (languageCode) => {
    switch (languageCode) {
        case 'en': return import('./ABB.Powertrain.en.json');
        case 'de': return import('./ABB.Powertrain.de.json');
        case 'es': return import('./ABB.Powertrain.es.json');
        case 'fr': return import('./ABB.Powertrain.fr.json');
        case 'it': return import('./ABB.Powertrain.it.json');
        case 'ja': return import('./ABB.Powertrain.ja.json');
        case 'kor': return import('./ABB.Powertrain.ko.json');
        case 'pl': return import('./ABB.Powertrain.pl.json');
        case 'pt': return import('./ABB.Powertrain.pt.json');
        case 'ro': return import('./ABB.Powertrain.ro.json');
        case 'ru': return import('./ABB.Powertrain.ru.json');
        case 'sv': return import('./ABB.Powertrain.sv.json');
        case 'vi': return import('./ABB.Powertrain.vi.json');
        case 'zh-hant': return import('./ABB.Powertrain.zh-Hant.json');
        case 'zh': return import('./ABB.Powertrain.zh.json');
        default: return import('./ABB.Powertrain.Frontend.json');
    }
};

export const loadResources = (lang) => {
    return loadLanguage(lang);
};
