import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { PlotApi } from 'api';
import { actionTypes } from './constants';
import {
    getTrendSignalDateInterval,
    createEmptyTrendDataObject
} from './helpers';
import { createParamMeasurementComponentObj } from '../../../../commonTrendHelpers';
import { map } from 'lodash';


const getTrendDataAction = getApiActionCreator(actionTypes.GET_TREND_DATA);
export const getTrendData = (selectedKpi, from, to, asset) => {
    return (dispatch) => {

        // Create parameter object for POST body content
        const paramsArray = createParamMeasurementComponentObj(selectedKpi, asset);

        dispatch(getTrendDataAction(apiCallStatus.LOADING));

        return PlotApi.getTrend(paramsArray, from, to)
            .then((dataSeries) => {
                if (dataSeries.length === 0) {
                    dataSeries = createEmptyTrendDataObject(selectedKpi, asset);
                }

                dispatch(getTrendDataAction(apiCallStatus.SUCCESS, dataSeries));
                return dataSeries;
            })
            .catch((err) => {
                dispatch(getTrendDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const addTrendKpi = (selectedKpi, asset) => {
    return (dispatch, getState) => {
        const trendChartZoom = getState().eventDetailsTrendSignal.trendChartZoom;
        const anyKpiSelected = getState().eventDetailsTrendSignal.selectedTrendsData.length > 0;
        const isZoomed = trendChartZoom.isZoomed && anyKpiSelected;
        const { from, to } = isZoomed && trendChartZoom.from && trendChartZoom.to ? trendChartZoom : getTrendSignalDateInterval(getState().eventLog.selectedEvent);
        return dispatch(getTrendData(selectedKpi, from, to, asset))
            .then((newKpiData) => {
                dispatch({
                    type: actionTypes.ADD_SELECTED_TREND_KPI_DATA, newKpiData, trendChartZoom: {
                        isZoomed,
                        from,
                        to
                    }
                });
            });
    };
};

export const reloadAllSelectedTrendKpiData = (allTrendKpis, asset) => {
    return (dispatch, getState) => {
        const { from, to } = getTrendSignalDateInterval(getState().eventLog.selectedEvent);
        const mapAllTrendKpis = map(allTrendKpis, (kpi) => kpi.config);


        return dispatch(getTrendData(mapAllTrendKpis, from, to, asset))
            .then((newKpiData) => {
                dispatch({
                    type: actionTypes.RELOAD_ALL_SELECTED_TREND_KPI_DATA, newKpiData, trendChartZoom: {
                        isZoomed: false,
                        from,
                        to
                    }
                });
            });
    };
};

export const removeSelectedKpi = (selectedKpi) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.REMOVE_SELECTED_TREND_KPI_DATA, selectedKpi });
    };
};

//Action
const removeSelectedKpiLegendAction = (signalIndex) => {
    return {
        type: actionTypes.REMOVE_SELECTED_TREND_LEGEND,
        signalIndex
    };
};

// Action Creators
export const removeSelectedKpiLegend = (signalIndex) => {
    return (dispatch) => {
        dispatch(removeSelectedKpiLegendAction(signalIndex));
    };
};

//
export const zoomInToSelectedTrendKpiData = (allTrendKpis, from, to, asset) => {
    return (dispatch) => {
        const mapAllTrendKpis = map(allTrendKpis, (kpi) => kpi.config);

        return dispatch(getTrendData(mapAllTrendKpis, from, to, asset))
            .then((newKpiData) => {
                dispatch({
                    type: actionTypes.ADD_SELECTED_TREND_KPI_DATA, newKpiData, trendChartZoom: {
                        isZoomed: true,
                        from,
                        to
                    }
                });
            });
    };
};

export const setIsZoomed = (isTrendChartZoomed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ZOOMED, isTrendChartZoomed });
    };
};
