import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconConditionPrediction = ({ width = 16, height = 3, viewBox = '0 0 16 3', color = colors.dodgerBlue }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line x1='14.5005' y1='1.5' x2='1.50049' y2='1.5' stroke={color} strokeWidth='3' strokeLinecap='round' strokeDasharray='2 6' />
    </svg>;
};

IconConditionPrediction.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconConditionPrediction;
