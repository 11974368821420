import { isNil, forEach } from 'lodash';

import { baseToCustomUOM, getMeasurementUnits, getMeasurementUnitSymbol } from 'helpers/unitHelper';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { qualityFlags } from 'helpers/constants';
import { setMaxPrecision } from 'helpers/formatters';


const convertCalibrationUoM = (
    measurementTypeUnitGroupID,
    measurementCalibration,
    measurementUnitGroups,
    convertFn
) => {
    if (!isNaN(parseFloat(measurementCalibration))) {
        const zeroRefValue = convertFn(measurementTypeUnitGroupID, 0, measurementUnitGroups);
        const convertedCalibrationValue = convertFn(measurementTypeUnitGroupID, measurementCalibration, measurementUnitGroups);

        return convertedCalibrationValue - zeroRefValue;
    }

    return measurementCalibration;
};


export const formatKPITrendResponse = (res) => {
    return res.map(trendData => {
        const unitGroupId = trendData.config && trendData.config.unitGroupID;
        trendData.config.unit = getMeasurementUnitSymbol(unitGroupId);
        trendData.values = baseToCustomUOM.convertMeasurementList(
            trendData.values,
            { value: unitGroupId, min: unitGroupId, max: unitGroupId },
            trendData.config.precision
        );

        trendData.values = trendData.values.map(v => {
            const metrics = v.qualityFlag === qualityFlags.Success
                ? {}
                : {
                    max: null,
                    min: null,
                    value: null
                };

            return {
                ...v,
                ...metrics
            };
        });

        return trendData;
    });
};


export const formatKPIScatterResponse = (res) => {
    return res.map(scatterData => {
        const { xUnitGroupID, yUnitGroupID, precision } = scatterData.config;
        scatterData.config.xUnit = getMeasurementUnitSymbol(xUnitGroupID);
        scatterData.config.yUnit = getMeasurementUnitSymbol(yUnitGroupID);
        scatterData.values = baseToCustomUOM.convertMeasurementList(
            scatterData.values,
            { x: xUnitGroupID, y: yUnitGroupID },
            precision
        );

        scatterData.values = scatterData.values.map(v => {
            const isValid = v.xQualityFlag === qualityFlags.Success && v.yQualityFlag === qualityFlags.Success;

            return {
                ...v,
                date: convertUtcToLocalDateTime(v.date),
                x: isValid ? v.x : null,
                y: isValid ? v.y : null
            };
        });

        return scatterData;
    });
};

export const convertKPIHistogramResponse = (res) => {

    return res.map(histogramData => {
        const { binUnitGroupID, aggregationUnitGroupID, precision } = histogramData.config;

        histogramData.config.xUnit = getMeasurementUnitSymbol(binUnitGroupID);
        histogramData.config.yUnit = getMeasurementUnitSymbol(aggregationUnitGroupID);
        histogramData.values = baseToCustomUOM.convertMeasurementList(
            histogramData.values,
            { start: binUnitGroupID, end: binUnitGroupID, value: aggregationUnitGroupID },
            precision
        );
        histogramData.values = histogramData.values.map(value => {
            value.label = `${value.start} - ${value.end}`;
            return value;
        });

        return histogramData;
    });
};

export const convertKPIsResponse = (res) => {
    const kpiList = [];

    forEach(res, kpi => {
        if (kpi.isVisible) {
            kpi.unit = kpi.unitGroupID ? getMeasurementUnitSymbol(kpi.unitGroupID) : kpi.unit;
            kpiList.push(kpi);
        }
    });

    return kpiList;
};


export const handleGetMeasurementTypesConversion = (measurementTypeGroups) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    forEach(measurementTypeGroups, (measurementTypeGroupItem) => {
        forEach(measurementTypeGroupItem.measurementTypes, (measurementTypeItem) => {
            const valueObj = baseToCustomUOM.convertAndFormatWithGroups(
                measurementTypeItem.measurementTypeUnitGroupID,
                measurementTypeItem.measurementTypeValue,
                userMeasurementUnitGroups);
            if (measurementTypeItem.isCalibrationEnabled) {
                measurementTypeItem.measurementCalibration = setMaxPrecision(
                    convertCalibrationUoM(
                        measurementTypeItem.measurementTypeUnitGroupID,
                        measurementTypeItem.measurementCalibration,
                        userMeasurementUnitGroups,
                        baseToCustomUOM.convertWithGroups),
                    isNil(measurementTypeItem.measurementTypePrecision) ? 4 : measurementTypeItem.measurementTypePrecision);
            }
            measurementTypeItem.measurementTypeValue = valueObj.measurementValue;
            measurementTypeItem.measurementTypeUnit = valueObj.measurementUnit;
        });
    });

    return measurementTypeGroups;
};
