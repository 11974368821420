import { actionTypes as generalFilterActions } from '../components/GeneralFilter/GeneralFilterModal/constants';
import { actionTypes as reportActions, reportStateProps } from '../components/Reports/constants';

const statePropPath = {
	SELECTED_FILTERS: 'generalFilter.selectedFilters',
	IS_REPORT_FILTER_VISIBLE: reportStateProps.IS_REPORT_FILTER_VISIBLE
};


export const saveConfiguration = [
	{
		stateProps: [statePropPath.SELECTED_FILTERS],
		action: generalFilterActions.SET_SELECTED_FILTERS,
	},
	{
		stateProps: [statePropPath.IS_REPORT_FILTER_VISIBLE],
		action: reportActions.TOGGLE_FILTER_VISIBILITY,
	},

];
