import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { PlotApi } from 'api';
import CapabilityHelper from 'helpers/capabilityHelper';

const getKPIsForKPITypeAction = getApiActionCreator(actionTypes.GET_KPIS_FOR_KPI_TYPE);

export const setShoulScrollToSelection = (shouldScrollToSelection) => {
    return {
        type: actionTypes.SET_SHOULD_SCROLL_TO_SELECTION,
        shouldScrollToSelection
    };
};

const setSelectedAssetAction = (asset) => {
    return {
        type: actionTypes.SET_SELECTED_ASSET,
        asset
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

const clearKIPListAction = (asset) => {
    return {
        type: actionTypes.CLEAR_KPI_LIST,
        asset
    };
};

export const setKpi = (selectedKpi, asset) => {
    return {
        type: actionTypes.SET_SELECTED_KPI,
        selectedKpi,
        asset
    };
};

export const setActiveTabAction = (activeTab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        activeTab
    };
};

export const setSearchboxFilterAction = (searchboxFilter) => {
    return {
        type: actionTypes.SET_SEARCHBOX_FILTER,
        searchboxFilter
    };
};

export const setGatewayTypeFilterAction = (gatewayTypeFilter) => {
    return {
        type: actionTypes.SET_GATEWAY_TYPE_FILTER,
        gatewayTypeFilter
    };
};

export const setSelectedPowertrainID = (powertrain) => {
    return {
        type: actionTypes.SET_SELECTED_POWERTRAIN,
        powertrain
    };
};

export const setSelectedAsset = (asset) => {
    return dispatch => {
        dispatch(clearKIPListAction());
        dispatch(setSelectedAssetAction(asset));
    };
};

export const getKPIsForKPIType = (asset, capability, kpiType) => {
    return (dispatch) => {
        dispatch(getKPIsForKPITypeAction(apiCallStatus.LOADING));
        const capabilityHelper = new CapabilityHelper(capability, asset);
        if (capabilityHelper.hasPlotTypeCapability(kpiType) &&
            capabilityHelper.hasCrossAssetAnalysisCapability()) {
            PlotApi.getPlotKPIList(asset, kpiType)
                .then((kpis) => {
                    dispatch(getKPIsForKPITypeAction(apiCallStatus.SUCCESS, kpis));
                })
                .catch((err) => {
                    dispatch(getKPIsForKPITypeAction(apiCallStatus.ERROR, null, err));
                });
        } else {
            dispatch(getKPIsForKPITypeAction(apiCallStatus.SUCCESS, []));
        }
    };
};
