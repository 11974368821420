import { filter, maxBy } from 'lodash';
import { createSelector } from 'reselect';

import { dataSources } from 'helpers/constants';
import { getTimeSinceDateString } from 'helpers/dateHelper';
import {
    getFlatAvailableKPIList,
    formatChartData
} from '../../../../commonTrendHelpers';
import { getOpTrendUniqueYScaling } from '../helpers';


const getSelectedTrendKPIs = ({ operationalParameters }) => {
    return operationalParameters?.preselectedKPIs?.single?.trend;
};

const getAvailableTrendKPIs = ({ operationalParameters }) => {
    return operationalParameters?.availableKPIs?.trend?.data;
};

const getUserLimits = ({ detailedInformationHeader }) => {
    return detailedInformationHeader?.userLimits?.data;
};

const getTrendHealthStatuses = ({ operationalParameters }) => {
    return operationalParameters?.trendHealthStatuses?.data;
};

const getEnableUniqueScaling = ({ operationalParameters }) => {
    return getOpTrendUniqueYScaling(
        operationalParameters?.charts?.single?.trend[0]?.kpiList,
        operationalParameters?.availableKPIs?.trend?.data
    );
};

const getTrendData = ({ operationalParameters }) => {
    return operationalParameters?.charts?.single?.trend;
};

const getTrendDataSelector = createSelector([
    getAvailableTrendKPIs,
    getTrendData
],
    (
        availableTrendKpis,
        data2
    ) => {

        const data = formatChartData(
            data2 || [],
            availableTrendKpis
        );
        return data[0];
    });

const getLastMeasurementTimeStampLabel = createSelector([
    getAvailableTrendKPIs,
    ({ powertrainAssetDetails }) => powertrainAssetDetails.selectedAsset
],
    (trendKPIsData,
        asset
    ) => {
        let label = null;
        if (asset.componentType.dataSourceType === dataSources.SMARTSENSOR) {
            const availableTrendKPIList = getFlatAvailableKPIList(trendKPIsData);
            const mType = maxBy(Object.values(availableTrendKPIList),
                (measurementType) => {
                    return measurementType.measurementTimeStamp;
                });
            label = getTimeSinceDateString(mType?.measurementTimeStamp);
        }
        return label;
    });

const getUserLimitsSelector = createSelector([
    getUserLimits,
    ({ operationalParameters }) => operationalParameters?.charts?.single?.trend[0]?.kpiList,
    getAvailableTrendKPIs,
],
    (userLimits,
        selectedTrendsData,
        trendKPIsData
    ) => {
        let kpiUserLimits = [];
        const availableTrendKPIList = getFlatAvailableKPIList(trendKPIsData);
        if (userLimits && selectedTrendsData.length === 1) {
            const [{ kpiIdentifier, values }] = selectedTrendsData;
            if (values.length) {
                kpiUserLimits = filter(userLimits.visualAids, (limit) => {
                    return limit.signalCode.toLowerCase() === availableTrendKPIList[kpiIdentifier]?.measurementTypeCode.toLowerCase();
                });
            }
        }
        return kpiUserLimits;
    });

export const getModel = createSelector([
    getSelectedTrendKPIs,
    getTrendDataSelector,
    getUserLimitsSelector,
    getEnableUniqueScaling,
    getLastMeasurementTimeStampLabel,
    getTrendHealthStatuses,
    getAvailableTrendKPIs
], (
    selectedTrendKPIs,
    selectedTrendsData,
    userLimits,
    enableUniqueScaling,
    lastMeasurementTimeStampLabel,
    trendHealthStatuses,
    availableTrendKPIs
) => {
    return {
        selectedTrendKPIs,
        selectedTrendsData,
        userLimits,
        enableUniqueScaling,
        lastMeasurementTimeStampLabel,
        trendHealthStatuses,
        availableTrendKPIs
    };
});
