import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';

import EventDetailsCMDComponent from './EventDetailsCMDComponent';


const EventDetailsContainer = (props) => {
	useEffect(() => () => {
		props.actions.clearStore();
	}, []);

	return <EventDetailsCMDComponent {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const EventDetailsCMDContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(EventDetailsContainer);


export default EventDetailsCMDContainerConn;
