
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetPowertrainSelectorComponent from './AssetPowertrainSelectorComponent';
import { getModel } from './selectors';
import * as actions from './actions';


const AssetPowertrainSelectorContainer = (props) => {
    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <AssetPowertrainSelectorComponent {...props} />;
};

const mapStateToProps = state => ({ model: getModel(state) });

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetPowertrainSelectorContainer);

