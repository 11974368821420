import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconDelete = ({ width = '12', height = '14', viewBox = '0 0 12 14', color = colors.codGray, onClick }) => {
	return <svg className='delete-icon' width={width} height={height} viewBox={viewBox} onClick={onClick} fill='none' xmlns='http://www.w3.org/2000/svg' role='delete-icon'>
		<path d='M3 0H9V1H3V0ZM11 2H0V4H1V11.91C0.998675 12.1848 1.05183 12.4572 1.15639 12.7114C1.26096 12.9655 1.41486 13.1965 1.60919 13.3908C1.80353 13.5851 2.03446 13.739 2.28863 13.8436C2.5428 13.9482 2.81517 14.0013 3.09 14H8.73C9.33204 14 9.90942 13.7608 10.3351 13.3351C10.7608 12.9094 11 12.332 11 11.73V4H12V2H11ZM5 12H4V5H5V12ZM8 12H7V5H8V12Z' fill={color} />
	</svg>;
};

IconDelete.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconDelete;
