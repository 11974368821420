import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PowertrainLoginComponent from './PowertrainLogin/PowertrainLoginComponent';
import { environmentStages } from 'helpers/constants';
import { getNotProductionWarning, hasCookieConsent, setReturnUrl, getErrorMessage, stopClarityDataCollecting } from './helpers';
import CookieConsentModal from './CookieConsentModal';
import AuthenticationLoadingScreen from './AuthenticationLoadingScreen';

import './style.scss';


export const LoginContext = createContext();

const LoginComponent = ({
    environmentStage,
    userManager,
    returnUrl,
    errorMessage
}) => {
    const [cookieConsentModalVisible, setCookieConsentModalVisible] = useState(false);
    const [loginPending, setLoginPending] = useState(false);

    const handleLogin = () => {
        setLoginPending(true);
    };

    const closeCookieConsentModal = () => setCookieConsentModalVisible(false);

    useEffect(() => {
        setReturnUrl(returnUrl);
        stopClarityDataCollecting();
        // Skipping cookie consent on developement
        if (environmentStage !== environmentStages.DEV && !hasCookieConsent()) {
            setCookieConsentModalVisible(true);
        }
    }, []);

    return <LoginContext.Provider value={{
        notProductionWarningComponent: getNotProductionWarning(environmentStage),
        errorMessage: getErrorMessage(errorMessage),
        handleLogin
    }}>
        <div>
            <CookieConsentModal show={cookieConsentModalVisible} close={closeCookieConsentModal} />
            <PowertrainLoginComponent />

            {loginPending && <AuthenticationLoadingScreen
                userManager={userManager}
            />}
        </div>
    </LoginContext.Provider>;
};

LoginComponent.propTypes = {
    environmentStage: PropTypes.any,
    returnUrl: PropTypes.any,
    userManager: PropTypes.any,
    errorMessage: PropTypes.any
};

export default LoginComponent;
