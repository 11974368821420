import { actionTypes } from '../constants';


export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_ASSET_GROUP_OVERVIEW });
    };
};

export const getAssetGroupConditionIndicesRequest = (request) => ({
    type: actionTypes.LOAD_ASSET_GROUP_CONDITION_INDICES_REQUEST,
    request
});
