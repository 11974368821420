import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SearchBox, GeneralSelect } from 'sharedComponents';
import { filterTabs } from 'helpers/constants';
import { getGatewayTypeOptions, getDefaultValue } from '../../../helpers';
import ConditionBasedFilter from './ConditionBasedFilter';


const FilterComponent = ({
    setConditionIndexFilterAction,
    toggleConditionStatusFilterAction,
    setSearchboxFilterAction,
    setGatewayTypeFilterAction
}) => {
    const { t: translate } = useTranslation();
    const {
        searchboxFilter,
        gatewayTypeFilter
    } = useSelector(({ assetAndPowetrainFilter }) => assetAndPowetrainFilter);

    const activeTab = useSelector(({ powertrainAssetDetails }) => powertrainAssetDetails.activeTab);
    return (
        <Fragment>
            <SearchBox
                placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameIDSerialNrText')}
                onChange={setSearchboxFilterAction}
                value={searchboxFilter}
            />

            <ConditionBasedFilter
                setConditionIndexFilterAction={setConditionIndexFilterAction}
                toggleConditionStatusFilterAction={toggleConditionStatusFilterAction}
            />

            {activeTab === filterTabs.ASSETS &&
                <GeneralSelect
                    options={getGatewayTypeOptions(translate)}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    defaultValue={getDefaultValue(gatewayTypeFilter, translate)}
                    menuShouldScrollIntoView={false}
                    onChange={(value) => setGatewayTypeFilterAction(value.id)}
                    className='gateway-type-filter'
                    isSearchable={false}
                />
            }
        </Fragment>
    );
};

export default FilterComponent;
