import { createSelector } from 'reselect';

import { getRedirectUrl } from './helpers';


const getUserManager = ({ portal }) => {
	return portal.userManager;
};

const getRedirectUrlSelector = ({ overview: { user }, portal: { environmentProperties } }) => {
	return getRedirectUrl(user.data, environmentProperties);
};

export const getModel = createSelector([
	getUserManager,
	getRedirectUrlSelector
], (
	userManager,
	redirectUrl
) => {

	return {
		userManager,
		redirectUrl
	};
});
