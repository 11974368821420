import React from 'react';
import PropTypes from 'prop-types';

const IconError = ({ width = '24', height = '24', viewBox = '0 0 24 24', color = 'white' }) =>
	<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} fill='none'>
		<path d='M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM16.71 15.29L15.29 16.71L12 13.41L8.71 16.71L7.29 15.29L10.59 12L7.29 8.71L8.71 7.29L12 10.59L15.29 7.29L16.71 8.71L13.41 12L16.71 15.29Z' fill={color} />
	</svg>;

IconError.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconError;
