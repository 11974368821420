import { AuthenticationApi, UserProfileApi } from 'api';
import { apiCallStatus, } from 'helpers/constants';
import * as actionTypes from './constants';
import {
    setPersistentUserInfo,
    storeAuthTokens,
    mergeUserInfo
} from './helpers';
import { getApiActionCreator } from 'helpers/actionHelper';

const loadUserInfoAction = getApiActionCreator(actionTypes.LOAD_USER);


export const loadUserInfo = (idToken, clientID, setTokensRetrieved, errorHandler) => {
    return (dispatch) => {
        dispatch(loadUserInfoAction(apiCallStatus.LOADING));
        Promise.all([
            AuthenticationApi.loginWithToken(idToken, clientID),
            UserProfileApi.getUserProfile(),
        ])
            .then(([userInfo, userInfoMicroService]) => {
                setPersistentUserInfo(userInfo, userInfoMicroService);
                storeAuthTokens(userInfo.authTokens);
                dispatch(loadUserInfoAction(apiCallStatus.SUCCESS, mergeUserInfo(userInfo, userInfoMicroService)));
                setTokensRetrieved(true);
            }).catch((error) => {
                dispatch(loadUserInfoAction(apiCallStatus.ERROR, null, error));
                errorHandler();
            });
    };
};
