import { toLocaleDateTimeString } from 'helpers/dateHelper';
import { map, sortBy, filter } from 'lodash';
import { causeOfEvents } from '../../../constants';

const getEventCause = (causeOfEvent, translate) => {
    const key = Object.keys(causeOfEvents).find(key => causeOfEvents[key] === causeOfEvent);
    return translate(`ABB.Powertrain.Frontend.eventCauseOptionSelfEvaluate${causeOfEvents[key]}`);
};

export const getEventCauseOptions = (translate, includeAll = true) => {
    const listOfCauses = map(causeOfEvents, (index) => {
        return { index, label: translate(`ABB.Powertrain.Frontend.eventCauseOptionSelfEvaluate${index}`) };
    });
    if (!includeAll) {
        listOfCauses.shift();
    }
    return sortBy(listOfCauses, [entry => entry.label.toLowerCase()]);
};

export const formatComments = (comments = [], showUtcDates, translate) => {
    return comments.map(item => {
        const cause = item.causeOfEvent !== -1 ? getEventCause(item.causeOfEvent, translate) : '';
        const authorType = item.authorType ? translate(`ABB.Powertrain.Frontend.by${item.authorType}SelfEvaluate`) : '';
        const serialNumber = item.assetSerialNumber ? `${translate('ABB.Powertrain.Frontend.serialNumberSelfEvaluate')} ${item.assetSerialNumber}` : '';
        const timeStamp = item.commentTimeStamp ? toLocaleDateTimeString(item.commentTimeStamp, { showOffset: true, asUTC: showUtcDates }) : '';

        return {
            header: `${cause} | ${authorType} | ${serialNumber} | ${timeStamp}`,
            text: item.comment,
            canDeleteComment: item.userCanDeleteComment,
            commentRef: item,
        };
    });
};

export const filterCommentsByCause = (comments = [], cause) => {
    return cause.index === -1 ? comments : filter(comments, comment => comment.causeOfEvent === cause.index);
};

export const checkIfPreselectedCause = (rawEvent, causeOptions) => {
    return rawEvent.initialForFutureEvents ?
        causeOptions.find(c => c.index === rawEvent.initialCauseOfEvent) :
        causeOptions.find(c => c.index === causeOfEvents.Unclassified);
};
