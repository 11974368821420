import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager, RestManagerWithoutBaseUrl, withPromise } from '../RestManager';
import { mapAssetObj } from './componentApiHelper';
import { assetObjectToComponentRequest } from '../helpers';
import {
    handleGetAssetPropertiesConversion,
    handleHealthStatusThresholdsConversion,
    handleGetHealthStatusesConversion,
    handleSaveMeasurementCalibration,
    handleSaveHealthStatusGroupsConversion
} from './componentApiUoMHelper';
import { RestManagerWithSmartSensorUrl } from 'api/RestManager/RestManager';

class assetActions {

    updateAsset(data) {
        const endpoint = formatEndpoint(endpoints.assetRename.updateAssetList,
            {
                assetId: data.id,
            }
        );
        return RestManagerWithoutBaseUrl.put(endpoint, mapAssetObj(data));
    }

    getAssetCapability(asset) {
        const endpoint = formatEndpoint(endpoints.component.capability,
            {
                id: asset.componentID,
                dataSource: asset.componentType.dataSourceType
            }
        );
        return RestManager.get(endpoint);
    }

    getAssetDetails(asset) {
        const endpoint = formatEndpoint(endpoints.component.details,
            {
                id: asset.componentID,
                dataSource: asset.componentType.dataSourceType
            }
        );
        return RestManager
            .get(endpoint)
            .then(handleGetAssetPropertiesConversion);
    }

    getAssetDetailsV2(asset) {
        const endpoint = formatEndpoint(endpoints.assetDetails.getAssetDetails,
            {
                assetID: asset.id
            }
        );
        return RestManagerWithoutBaseUrl
            .get(endpoint)
            .then(handleGetAssetPropertiesConversion);
    }

    exportExcelForMeasurementTypeGroup(asset, params) {
        const endpoint = formatEndpoint(endpoints.component.exportExcelForMeasurementTypeGroup,
            {
                dataSource: asset.componentType.dataSourceType
            }
        );

        return RestManager.post(endpoint, {
            componentID: asset.componentID,
            componentName: asset.componentName,
            ...params
        });
    }


    getAssetsCapabilities(assets) {
        const endpoint = formatEndpoint(endpoints.component.capabilities);
        return RestManager.post(endpoint, assets.map(c => assetObjectToComponentRequest(c)));
    }

    getHealthStatuses(asset) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.get,
            {
                id: asset.componentID,
                dataSource: asset.componentType.dataSourceType
            }
        );
        return RestManager
            .get(endpoint)
            .then(handleGetHealthStatusesConversion);
    }

    getDefaultHealthStatuses(asset, measurementTypeID, measurementTypeUnitGroupID) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.default,
            {
                dataSource: asset.componentType.dataSourceType,
                measurementTypeID,
                assetTypeID: asset.componentType.typeID
            }
        );
        return RestManager
            .get(endpoint)
            .then((defaultHealthStatuses) => handleHealthStatusThresholdsConversion(defaultHealthStatuses, measurementTypeUnitGroupID));
    }

    getCalculatedThresholds(asset, measurementTypeID, measurementTypeUnitGroupID, from, to) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.calculate,
            {
                id: asset.componentID,
                dataSource: asset.componentType.dataSourceType,
                measurementTypeID,
                from,
                to
            });
        return RestManager.get(endpoint)
            .then((calculatedThresholds) => handleHealthStatusThresholdsConversion(calculatedThresholds, measurementTypeUnitGroupID));
    }

    saveHealthStatusGroups(asset, measurementStatusGroups) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.save,
            {
                id: asset.componentID,
                dataSource: asset.componentType.dataSourceType,
            });

        return withPromise(() => {
            measurementStatusGroups = handleSaveHealthStatusGroupsConversion(measurementStatusGroups);

            return RestManager.post(endpoint, measurementStatusGroups);
        });
    }

    saveMeasurementCalibration(asset, offsetList) {
        const componentID = asset.componentID;
        const endpoint = formatEndpoint(endpoints.assetDetails.operationalParameters.saveMeasurementCalibration, { assetID: componentID });

        return withPromise(() => {
            offsetList = handleSaveMeasurementCalibration(offsetList);

            return RestManager.post(endpoint, offsetList);
        });
    }

    collectRawData(sensorIdentifier, sensorTypeID, dataSource) {
        const endpoint = formatEndpoint(endpoints.component.collectRawData, {
            sensorIdentifier,
            sensorTypeID,
            dataSource
        });
        return RestManager.post(endpoint);
    }

    loadMeasurement(sensorIdentifier, sensorTypeID, dataSource) {
        const endpoint = formatEndpoint(endpoints.component.loadMeasurement, {
            sensorIdentifier,
            sensorTypeID,
            dataSource
        });
        return RestManager.post(endpoint);
    }

    getSensorOfAssets(assetIds) {
        const endpoint = formatEndpoint(endpoints.assetDetails.getSensorOfAssets);
        return RestManagerWithoutBaseUrl.post(endpoint, { assetIds });
    }

    updateHealthStatusEmailNotification(notificationTypeId, channelID, payload) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.updateHealthStatusEmailNotification, { notificationTypeId, channelID });
        return RestManagerWithSmartSensorUrl.post(endpoint, payload);
    }

    // GET EMAIL NOTIFICATION CONFIG
    getHealthStatusEmailNotification(notificationTypeId, channelID, assetID) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.getHealthStatusEmailNotification, { notificationTypeId, channelID, assetID });
        return RestManagerWithSmartSensorUrl.get(endpoint);
    }

    // DELETE EMAIL NOTIFICATION CONFIG
    deleteHealthStatusEmailNotification(notificationTypeId, channelID, assetID) {
        const endpoint = formatEndpoint(endpoints.component.healthStatus.deleteHealthStatusEmailNotification, { notificationTypeId, channelID });
        return RestManagerWithSmartSensorUrl.delete(endpoint, assetID);
    }
}

export default new assetActions();
