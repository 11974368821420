import React from 'react';
import PropTypes from 'prop-types';
import { getAssetIcon } from 'helpers/icons';
import { renderThreshold, renderTrend } from './helpers';


const AssetRowComponent = ({ asset }) => {
    return <div className='item'>
        <div className='item-name'>
            <div className='item-icon'>
                {getAssetIcon(asset.assetType, { width: 20, height: 20 })}
            </div>
            <div className='name'>{asset.assetName}</div>
        </div>
        {renderThreshold(asset)}
        {renderTrend(asset)}
    </div>;
};

AssetRowComponent.propTypes = {
    asset: PropTypes.shape({
        assetId: PropTypes.number,
        assetType: PropTypes.string,
        assetName: PropTypes.string
    })
};

export default AssetRowComponent;
