import PropTypes from 'prop-types';
import React from 'react';

const ScatterTooltip = ({ point, xUnit, yUnit }) => {
    return <div className='scatter-tooltip'>
        <div className='scatter-tooltip-header'>{point.date}</div>
        {point.x} {xUnit}<br />
        {point.y} {yUnit}<br /></div>;
};


ScatterTooltip.propTypes = {
    point: PropTypes.object,
    name: PropTypes.string,
    xUnit: PropTypes.string,
    yUnit: PropTypes.string
};

export default ScatterTooltip;
