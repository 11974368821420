import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { ComponentApi, ReportApi } from 'api';

import { actionTypes } from '../constants';
import { getAssetsForReportType } from './selectors';
import { getFilterForReportType } from './helpers';
import { loadReports } from '../actions';


const getCapabilitiesAction = getApiActionCreator(actionTypes.GET_CAPABILITIES);
const generateReportAction = getApiActionCreator(actionTypes.GENERATE_REPORT);
const generateFleetReportAction = getApiActionCreator(actionTypes.GENERATE_FLEET_REPORT);
const getSensorOfAssetsAction = getApiActionCreator(actionTypes.GET_SENSOR_OF_ASSETS);

export const closeGeneratorModal = () => (dispatch) => dispatch({ type: actionTypes.CLOSE_GENERATOR_MODAL });

export const getCapabilitiesByDataSourceType = (reportType) => {
    return (dispatch, getState) => {
        const filterForReportType = getFilterForReportType(reportType);
        const assets = getAssetsForReportType(getState(), filterForReportType);

        dispatch(getCapabilitiesAction(apiCallStatus.LOADING, { reportTypeId: reportType.reportTypeId }));
        ComponentApi.getAssetsCapabilities(assets)
            .then((data) => {
                dispatch(getCapabilitiesAction(apiCallStatus.SUCCESS, {
                    reportTypeId: reportType.reportTypeId,
                    capabilities: data.map(capabilityAsset => {
                        capabilityAsset.id = assets.find(asset => asset.componentID === capabilityAsset.componentID).id;
                        return capabilityAsset;
                    })
                }));
            })
            .catch((err) => {
                dispatch(getCapabilitiesAction(apiCallStatus.ERROR, { reportTypeId: reportType.reportTypeId, err }));
            });
    };
};

export const generateReport = (reportType, asset, unit) => {
    return async (dispatch) => {
        dispatch(generateReportAction(apiCallStatus.LOADING));
        return await ReportApi.generateReport(reportType.reportTypeId, asset.id.toString(), unit.value)
            .then(() => {
                dispatch(generateReportAction(apiCallStatus.SUCCESS));
                dispatch(loadReports());
            })
            .catch((err) => {
                dispatch(generateReportAction(apiCallStatus.ERROR, null, err));
            });
    };
};


export const setReportTypeInRedux = (reportType) => (dispatch) => dispatch({ type: actionTypes.SET_REPORT_GENERATION_TYPE, reportType });

export const generateFleetReport = (reportType, reportingPeriod, unit) => {
    return async (dispatch, getState) => {
        dispatch(generateFleetReportAction(apiCallStatus.LOADING));
        const organization = getState().generalFilter?.selectedFilters?.organizations[0];

        const generateFleetReportParams = {
            reportTypeIdentifier: reportType.reportTypeId,
            organizationIdentifier: organization.organizationID.toString(),
            measurementUnit: unit.value,
            reportStartDate: reportingPeriod.from,
            reportEndDate: reportingPeriod.to
        };
        return await ReportApi.generateFleetReport(generateFleetReportParams)
            .then(() => {
                dispatch(generateFleetReportAction(apiCallStatus.SUCCESS));
                dispatch(loadReports());
            })
            .catch((err) => {
                dispatch(generateFleetReportAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const getSensorOfAssets = () => {
    return async (dispatch, getState) => {
        dispatch(getSensorOfAssetsAction(apiCallStatus.LOADING));
        const assetIds = getState().overview?.assets?.data.map(asset => asset.id);
        return await ComponentApi.getSensorOfAssets(assetIds)
            .then((data) => {
                dispatch(getSensorOfAssetsAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getSensorOfAssetsAction(apiCallStatus.ERROR, null, err));
            });
    };
};
