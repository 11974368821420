import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import ReportIssueModalComponent from './ReportIssueModalComponent';
import { getModel } from './selectors';


const ReportIssueModalContainer = (props) => {
    return <ReportIssueModalComponent {...props} />;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const ReportIssueModalContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportIssueModalContainer);

export default ReportIssueModalContainerConn;
