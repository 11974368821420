import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { apiCallStatus, kpiTypes } from 'helpers/constants';
import { GeneralSelect, InfoMessage, PopOutChart } from 'sharedComponents';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';

import useChartConfig, { chartPlugins } from './useChartConfig';
import { opButtons } from '../../constants';
import { tabIDs } from '../../../constants';
import useReloadTab from '../../useReloadTab';

import { EnableUniqueScaling, PopOutButton, ResetZoomButton, withLeftButtonGroup, withPopOutRow, withRightButtonGroup } from 'sharedComponents/Chart/headers';
import { getTranslatedKPIName } from 'helpers/translateHelper';

const HistogramComponent = ({
    model: {
        activeDetailedInformationTab,
        dateSelection,
        histogramData,
        histogramDataConfig,
        histogramDataLoadingStatus,
        histogramKpis,
        histogramKpisLoadingStatus,
        selectedKpi,
    },
    actions: {
        getHistogramForAsset,
        getHistogramKPIsForAsset,
        setSelectedKpi,
        removeSelectedKpiLegend
    },
    activeTab,
    asset,
}) => {
    const { t: translate } = useTranslation();
    const [isZoomed, setIsZoomed] = useState(false);

    const refreshData = () => {
        if (histogramKpisLoadingStatus === apiCallStatus.SUCCESS &&
            activeTab === opButtons.histogram &&
            activeDetailedInformationTab === tabIDs.operationalParametersv2 &&
            histogramKpis.length &&
            selectedKpi &&
            dateSelection.from &&
            dateSelection.to) {
            getHistogramForAsset(dateSelection.from, dateSelection.to, asset, selectedKpi);
        }
    };

    useEffect(() => {
        setIsZoomed(false);
    }, [dateSelection]);

    useReloadTab(refreshData,
        opButtons.histogram,
        [selectedKpi, histogramKpisLoadingStatus, dateSelection]);

    useEffect(() => {
        histogramKpisLoadingStatus !== apiCallStatus.SUCCESS &&
            getHistogramKPIsForAsset(asset, kpiTypes.HISTOGRAM);
    }, []);

    const options = useChartConfig(histogramData, histogramDataConfig, histogramKpis, removeSelectedKpiLegend);

    const HistogramKPISelector = () => {
        return < GeneralSelect
            id='histogram_chart_type_selector'
            placeholder={translate('ABB.Powertrain.Frontend.pleaseSelectKPI')}
            options={histogramKpis}
            value={selectedKpi || ''}
            onChange={setSelectedKpi}
            getOptionLabel={option => getTranslatedKPIName(option)}
            getOptionValue={option => option.measurementTypeIdentifier} />;
    };

    const MainChartHeader = withPopOutRow([
        withLeftButtonGroup([HistogramKPISelector, EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);

    const PopOutChartHeader = withPopOutRow([
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    return histogramKpisLoadingStatus === apiCallStatus.SUCCESS && histogramKpis.length === 0 ?
        <Row className='button-row-container'>
            <Col className='table-empty-content'>
                <InfoMessage title='ABB.Powertrain.Frontend.noSupportedMeasurementTypes' />
            </Col>
        </Row> :
        <LoadingStatusWrapper
            useCustomAnchor
            transparent
            loadingStatus={[histogramKpisLoadingStatus, histogramDataLoadingStatus]}>
            <PopOutChart
                isZoomed={isZoomed}
                setIsZoomed={setIsZoomed}
                hasKpi={!!selectedKpi}
                renderNoKpi={() => <InfoMessage
                    title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                    text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                hasData={!!histogramData?.length}
                renderNoData={() => <InfoMessage
                    title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                    text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
                options={options}
                modules={chartPlugins}
                container='op-histogram-chart'
                mainChartHeader={MainChartHeader}
                popOutChartHeader={PopOutChartHeader}
            />
        </LoadingStatusWrapper>;
};

export default HistogramComponent;

HistogramComponent.propTypes = {
    actions: PropTypes.shape({
        getHistogramForAsset: PropTypes.func,
        getHistogramKPIsForAsset: PropTypes.func,
        setSelectedKpi: PropTypes.func,
    }),
    model: PropTypes.shape({
        activeDetailedInformationTab: PropTypes.string,
        dateSelection: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string
        }),
        selectedKpi: PropTypes.object,
        histogramData: PropTypes.array,
        histogramDataConfig: PropTypes.object,
        histogramDataLoadingStatus: PropTypes.string,
        histogramKpis: PropTypes.array,
        histogramKpisLoadingStatus: PropTypes.string,
    }),
    activeTab: PropTypes.string,
    asset: PropTypes.object,
};
