import React from 'react';
import PropTypes from 'prop-types';
import { StateID } from 'helpers/constants';
import colors from 'theme/_colors.scss';


const IconMaintenancePriority = ({ condition = -1, width = '16', height = '16', viewBox = '0 0 16 16' }) => {

    const maintenancePriority = (color) => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='16' height='16' fill={color} />
            <g clipPath='url(#clip0_18271_115158)'>
                <path d='M12.8413 4.80275L10.3738 7.2725L8.73129 5.6285L11.1988 3.1595L10.79 2.75H8.73804L6.68004 4.80275V7.682L4.87629
                9.4865L4.53279 9.83L3.12354 11.24C2.95612 11.3973 2.83806 11.5998 2.78373 11.823C2.72939 12.0462 2.74114 12.2804 2.81754
                12.497C2.88772 12.6787 3.00194 12.8401 3.14992 12.9667C3.2979 13.0934 3.47502 13.1813 3.66537 13.2225C3.85572 13.2638 4.05333
                13.2571 4.24047 13.2032C4.42761 13.1492 4.59841 13.0496 4.73754 12.9133L5.62779 12.0215L5.94954 11.6788L8.32179 9.326H11.1988L13.25
                7.2725V5.21975L12.8413 4.80275Z' fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_18271_115158'>
                    <rect width='12' height='12' fill='white' transform='translate(2 2)' />
                </clipPath>
            </defs>
        </svg>;

    };

    const conditionUnknown = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height={height} fill={colors.silver} />
            <path fillRule='evenodd' clipRule='evenodd' d='M6.54541 10.9092H8.72723V13.091H6.54541V10.9092Z' fill={colors.white} />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.38597 5.96514C9.38597 5.61858 9.2442 5.34292 8.96065 5.13814C8.67711 4.93336 8.27805 4.83097 7.76348 4.83097C7.32241 4.83097 6.8866 4.9281 6.45603 5.12239C6.02546 5.31666 5.68417 5.57658 5.43213 5.90213L4.34521 4.48441C4.72327 3.98034 5.22734 3.59178 5.85744 3.31874C6.48754 3.0457 7.19114 2.90918 7.96826 2.90918C9.11293 2.90918 10.0134 3.16647 10.6698 3.68104C11.3261 4.19562 11.6543 4.90447 11.6543 5.80761C11.6543 6.41671 11.5099 6.9339 11.2211 7.35922C10.9323 7.78453 10.4886 8.13896 9.89004 8.4225C9.57499 8.56953 9.32821 8.70604 9.14968 8.83206C8.97115 8.95808 8.84251 9.09723 8.76375 9.2495C8.68499 9.40177 8.64561 9.62493 8.64561 9.91898V10.2025H6.48753V9.7457C6.48753 9.0631 6.60568 8.54327 6.84196 8.18622C7.07825 7.82916 7.51669 7.50887 8.15728 7.22532C8.61936 7.01529 8.93965 6.81839 9.11818 6.63461C9.2967 6.45083 9.38597 6.22768 9.38597 5.96514Z' fill={colors.white} />
        </svg>;
    };

    const notSupported = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height={height} fill={colors.silver} />
            <rect width={width} height={height} fill={colors.silver} />
            <path fillRule='evenodd' clipRule='evenodd' d='M12.71 4.71004L11.29 3.29004L8.00004 6.59004L4.71004 3.29004L3.29004 4.71004L6.59004 8.00004L3.29004 11.29L4.71004 12.71L8.00004 9.41004L11.29 12.71L12.71 11.29L9.41004 8.00004L12.71 4.71004Z' fill='white' />
        </svg>;
    };

    switch (condition) {
        case StateID.GOOD: return maintenancePriority(colors.jungleGreen);
        case StateID.TOLERABLE: return maintenancePriority(colors.orangePeel);
        case StateID.POOR: return maintenancePriority(colors.pomegranate);
        case StateID.NOT_APPLICABLE: return notSupported(); //TODO check state id for this from BE
        default: return conditionUnknown();
    }

};

IconMaintenancePriority.propTypes = {
    condition: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string
};

export default IconMaintenancePriority;
