import React, { Suspense } from 'react';

import {
    IconPowertrain,
    IconDrive,
    IconMotor,
    IconPump,
    IconTransformer,
    IconGenerator,
    IconBearing,
    IconAmbient,
    IconGenericMachine,
    IconGearing,
    IconWeldingMachine,
    IconAutomaticLubricator,
    IconWiMon,
    IconSmartHMI,
    IconMovingPart,
    IconFan,
    IconGearBox
} from 'svgIcons/MotionPortalIcons';

import { LoadingIndicator } from 'sharedComponents';
import { ASSET_TYPES } from './constants';
import { lowerCaseFirst } from './formatters';
import colors from 'theme/_colors.scss';


const formatMountType = (mountType) => {
    // Must replace twice, for cases like FootMountFlangeMount
    const filteredMountType = mountType.replace('Mount', '').replace('Mount', '');

    return lowerCaseFirst(filteredMountType.search('Foot') === 0 && filteredMountType.length > 4
        ? filteredMountType.replace('Foot', '') + 'Foot'
        : filteredMountType);
};

export const getMountingIcon = ({
    AssetMountingType,
    TerminalBoxPosition,
    SensorMountingOrientation
}) => {
    const MountingIcon = React.lazy(() =>
        import(`svgIcons/MotionPortalIcons/MountingIcons/${formatMountType(AssetMountingType)}`
            + (TerminalBoxPosition ? `TermBox${TerminalBoxPosition}`
                + (SensorMountingOrientation ?
                    `Sensor${SensorMountingOrientation}`
                    : '')
                : '')
            + 'Icon')
            .catch(() => import('svgIcons/MotionPortalIcons/IconWarning')));
    return (
        <Suspense fallback={<LoadingIndicator />}>
            <MountingIcon width={120} height={120} />
        </Suspense>
    );
};

export const getAssetIcon = (assetTypeName, customProps) => {
    if (assetTypeName) {
        const iconsStr = typeof assetTypeName === 'string' ? assetTypeName.toLowerCase() + 'Icon' : assetTypeName;

        switch (iconsStr) {
            case 'powertrainIcon':
                return <IconPowertrain {...customProps} />;
            case 'driveIcon':
            case ASSET_TYPES.DRIVE:
                return <IconDrive  {...customProps} />;
            case 'motorIcon':
            case ASSET_TYPES.MOTOR:
                return <IconMotor {...customProps} />;
            case 'pumpIcon':
            case ASSET_TYPES.PUMP:
                return <IconPump {...customProps} />;
            case 'transformerIcon':
            case ASSET_TYPES.TRANSFORMER:
                return <IconTransformer {...customProps} />;
            case 'generatorIcon':
            case ASSET_TYPES.GENERATOR:
                return <IconGenerator {...customProps} />;
            case 'bearingIcon':
            case ASSET_TYPES.BEARING:
                return <IconBearing {...customProps} />;
            case 'gearingIcon':
            case ASSET_TYPES.GEARING:
                return <IconGearing {...customProps} />;
            case 'smarthmideviceIcon':
            case ASSET_TYPES.SMARTHMIDEVICE:
                return <IconSmartHMI {...customProps} />;
            case 'ambientIcon':
            case ASSET_TYPES.AMBIENT:
                return <IconAmbient {...customProps} />;
            case 'ecmdriveIcon':
            case ASSET_TYPES.ECMDRIVE:
                return <IconDrive {...customProps} />;
            case 'genericmachineIcon':
            case ASSET_TYPES.GENERICMACHINE:
                return <IconGenericMachine {...customProps} />;
            case 'wimonIcon':
            case ASSET_TYPES.WIMON:
                return <IconWiMon {...customProps} />;
            case 'movingpartIcon':
            case ASSET_TYPES.MOVINGPART:
                return <IconMovingPart {...customProps} />;
            case 'fanIcon':
            case ASSET_TYPES.FAN:
                return <IconFan {...customProps} />;
            case 'gearboxIcon':
            case ASSET_TYPES.GEARBOX:
                return <IconGearBox {...customProps} />;
            case 'automaticlubricatorIcon':
            case ASSET_TYPES.AUTOMATICLUBRICATOR:
                return <IconAutomaticLubricator width='28' height='30' viewBox='-5 0 50 48' color={colors.black} {...customProps} />;
            case 'weldingmachineIcon':
                return <IconWeldingMachine width='30' height='30' viewBox='-5 -5 50 50' color={colors.black} {...customProps} />;
            default: return null;
        }
    }
};
