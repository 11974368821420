import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager, RestManagerWithoutBaseUrl } from '../RestManager';
import localStorageHelper from '../../helpers/localStorageHelper';
import { handleSortAssetList, mapSiteProps } from './headerApiHelper';
import { userRoles } from 'helpers/constants';
import { mapToOrganizationRequestBody } from '../helpers';


class header {
    getAssetGroups(organizationsParams) {
        const endpoint = formatEndpoint(endpoints.assetDetails.getAssetGroups);
        return RestManagerWithoutBaseUrl.post(endpoint, organizationsParams);
    }

    getSites(options) {
        const endpoint = formatEndpoint(endpoints.site.getSites);
        return RestManagerWithoutBaseUrl.post(endpoint, options)
            .then((sites) => mapSiteProps(sites));
    }

    addSite(site) {
        return RestManager.put(endpoints.site.addSite, site);
    }

    //get systems based on organization an user
    getSystems(sites, organizations, isRefresh) {
        const user = localStorageHelper.getItem('user');
        const params = {
            userName: user && user.email,
            isSuperAdmin: user && (user.isSuperAdmin || user.adminRoleID === userRoles.SUPERVIEWER),
            refreshData: isRefresh
        };
        const endpoint = formatEndpoint(endpoints.system.getSystems, params);

        return RestManager.post(endpoint, { siteIDs: sites, organizations })
            .then((systems) => {
                if (!systems) {
                    throw { message: 'getSystems() returned null!' };
                }
                return systems;
            });
    }

    //Get all components
    getAssets(selectedOrgFilters, isRefresh) {
        const user = localStorageHelper.getItem('user');
        const params = {
            isSuperAdmin: user && (user.isSuperAdmin || user.adminRoleID === userRoles.SUPERVIEWER),
            refreshData: isRefresh
        };
        const organizations = mapToOrganizationRequestBody(selectedOrgFilters);
        const endpoint = formatEndpoint(endpoints.component.components, params);
        return RestManager.post(endpoint, organizations)
            .then(handleSortAssetList)
            .then((assets) => {
                if (!assets) {
                    throw { message: 'getComponents() returned null!' };
                }
                return assets;
            });
    }

    getAvailableAssets(params) {
        const endpoint = formatEndpoint(endpoints.system.getAssetList, params);
        return RestManagerWithoutBaseUrl.post(endpoint, params);
    }
}

export default new header();
