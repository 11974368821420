import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetNameplateImageHolderComponent from './AssetNameplateImageHolderComponent';
import * as actions from './actions';
import { getModel } from './selectors';
import './style.scss';

const AssetNameplateImageHolderContainer = (props) => {

	useEffect(() => () => {
		props.actions.clearAssetPictures();
	}, []);

	return <AssetNameplateImageHolderComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const AssetNameplateImageHolderContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(AssetNameplateImageHolderContainer);

export default AssetNameplateImageHolderContainerConn;
