import { UserProfileApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import localStorageHelper from 'helpers/localStorageHelper';
import { actionTypes } from './constants';
import {
    mapUnitGrayBEPayload,
    mapAllUnitsGrayBEPayload,
    mapUnitMicroservicePayload,
    mapAllUnitsMicroservicePayload,
    updateUnitInUserDataState,
    updateAllUnitsInUserDataState
} from './helpers';


const updateUnitSelectionAction = getApiActionCreator(actionTypes.UPDATE_UNIT_SELECTION);

export const selectUnitStandard = (newStandard, updatedGroupID = null) => {
    return (dispatch, getState) => {
        const userData = getState().overview.user.data;
        const newUserDataPayload = updatedGroupID ?
            mapUnitGrayBEPayload(userData, newStandard, updatedGroupID) :
            mapAllUnitsGrayBEPayload(userData, newStandard);
        const newUserDataMicroservicePayload = updatedGroupID ?
            mapUnitMicroservicePayload(userData, newStandard, updatedGroupID) :
            mapAllUnitsMicroservicePayload(userData, newStandard);

        dispatch(updateUnitSelectionAction(apiCallStatus.LOADING));

        Promise.all([
            UserProfileApi.update(newUserDataPayload),
            UserProfileApi.updateUser(newUserDataMicroservicePayload)
        ])
            .then(() => {
                const newMeasurementUnitsToState = updatedGroupID ?
                    updateUnitInUserDataState(userData, newStandard, updatedGroupID) :
                    updateAllUnitsInUserDataState(userData, newStandard);
                dispatch(updateUnitSelectionAction(apiCallStatus.SUCCESS, newMeasurementUnitsToState));
                localStorageHelper.setItem('user', { ...userData, measurementUnitGroups: newMeasurementUnitsToState });
            }, (err) => {
                dispatch(updateUnitSelectionAction(apiCallStatus.ERROR, null, err));
            });
    };
};
