import React from 'react';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';
import UnitsComponent from './UnitsComponent';
import { bindActionCreators } from 'redux';


const UnitsContainer = (props) => {
    return <UnitsComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsContainer);
