import React from 'react';
import { connect } from 'react-redux';

import GlobalErrorComponent from './GlobalErrorComponent';
import { getModel } from './selectors';
import { withRouter } from 'react-router';


const GlobalErrorContainer = (props) => {
	return <GlobalErrorComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(GlobalErrorContainer));
