import { useEffect } from 'react';

import useIsDirty from 'sharedHooks/useIsDirty';

import { alarmValidation, alertValidation, isEmailNotificationChanged } from './helpers';


const DirtyFlagCalculation = (props) => {

    const { actions, kpi, healthEmailNotifications, emailNotificationPayload } = props;
    const { config } = kpi;

    const [isDirty, setIsDirty] = useIsDirty([config]);

    const resetDirtyFlag = () => {
        setTimeout(() => {
            setIsDirty(false);
        }, 10);
    };

    useEffect(() => {
        const state = {
            isAlertValid: alertValidation(config?.alert, config),
            isAlarmValid: alarmValidation(config?.alarm, config),
            isDirty
        };
        const isEmailNotificationValid = isEmailNotificationChanged(healthEmailNotifications, emailNotificationPayload);
        actions.setParamEditState({ [kpi?.measurementTypeID]: state, isEmailNotificationValid });
    }, [isDirty, config?.alert, config?.alarm, healthEmailNotifications]);


    return props.render(resetDirtyFlag);
};

export default DirtyFlagCalculation;
