import map from 'lodash/map';

export const assetObjectToComponentRequest = (asset) => {
	return {
		ComponentID: asset?.componentID,
		TypeID: asset?.componentType.typeID,
		DataSourceType: asset?.componentType.dataSourceType,
	};
};

export const assetObjectToComponentRequest2 = (asset) => {
	return {
		componentID: asset?.componentID,
		componentTypeID: asset?.componentType.typeID,
		dataSourceType: asset?.componentType.dataSourceType
	};
};

export const mapToOrganizationRequestBody = (selectedOrgFilters) => {
	return map(selectedOrgFilters, (o) => { return { organizationID: o.organizationID, dataSourceType: o.dataSourceType }; });
};
