import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconWarningType = ({ width = 16, height = 16, color = colors.orangePeel }) => {
	return <svg width={width} height={height} viewBox='0 0 16 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M14.927 13.202L8.481 2.26C8.42897 2.17989 8.35768 2.11411 8.27364 2.06868C8.18961 2.02326 8.09552 1.99964 8 2C7.90447 1.99964 7.81039 2.02326 7.72635 2.06868C7.64232 2.11411 7.57103 2.17989 7.519 2.26L1.073 13.202C1.0249 13.2834 0.999785 13.3764 1.00031 13.4709C1.00084 13.5655 1.027 13.6581 1.076 13.739C1.176 13.904 1.358 14.004 1.554 13.999H14.446C14.5415 14.0013 14.6359 13.9786 14.7198 13.9329C14.8037 13.8873 14.8741 13.8204 14.924 13.739C15.024 13.574 15.025 13.369 14.927 13.202ZM8.875 12.285H7.125V10.57H8.875V12.285ZM8.875 9.712H7.125V5.425H8.875V9.712Z' fill={color} />
	</svg>;
};

IconWarningType.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconWarningType;
