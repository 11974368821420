import { isNil, forEach } from 'lodash';
import { baseToCustomUOM, getMeasurementUnits, getMeasurementUnitSymbol } from 'helpers/unitHelper';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { qualityFlagsTimeSeries } from 'helpers/constants';
import { setMaxPrecision } from 'helpers/formatters';

const convertCalibrationUoM = (
    measurementTypeUnitGroupID,
    measurementCalibration,
    measurementUnitGroups,
    convertFn
) => {
    if (!isNaN(parseFloat(measurementCalibration))) {
        const zeroRefValue = convertFn(measurementTypeUnitGroupID, 0, measurementUnitGroups);
        const convertedCalibrationValue = convertFn(measurementTypeUnitGroupID, measurementCalibration, measurementUnitGroups);

        return convertedCalibrationValue - zeroRefValue;
    }

    return measurementCalibration;
};
// eslint-disable-next-line import/no-unused-modules
export const handleGetMeasurementTypesConversion = (measurementTypeGroups) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    forEach(measurementTypeGroups, (measurementTypeGroupItem) => {
        forEach(measurementTypeGroupItem.timeseries, (measurementTypeItem) => {
            const valueObj = baseToCustomUOM.convertAndFormatWithGroups(
                measurementTypeItem.timeseriesUnitGroupID,
                measurementTypeItem.timeseriesLastValue,
                userMeasurementUnitGroups);
            if (measurementTypeItem.isCalibrationEnabled) {
                measurementTypeItem.measurementCalibration = setMaxPrecision(
                    convertCalibrationUoM(
                        measurementTypeItem.timeseriesUnitGroupID,
                        measurementTypeItem.measurementCalibration,
                        userMeasurementUnitGroups,
                        baseToCustomUOM.convertWithGroups),
                    isNil(measurementTypeItem.timeseriesPrecision) ? 4 : measurementTypeItem.timeseriesPrecision);
            }
            measurementTypeItem.timeseriesLastValue = valueObj.measurementValue;
            measurementTypeItem.unit = valueObj.measurementUnit;
        });
    });

    return measurementTypeGroups;
};

export const formatKPITrendResponse = (res) => {
    return res.map(trendData => {
        const unitGroupId = trendData.config && trendData.config.timeseriesUnitGroupID;
        trendData.config.unit = getMeasurementUnitSymbol(unitGroupId);
        trendData.values = baseToCustomUOM.convertMeasurementList(
            trendData.values,
            { value: unitGroupId, min: unitGroupId, max: unitGroupId },
            trendData.config.timeseriesPrecision
        );

        trendData.values = trendData.values.map(v => {
            const metrics = v.quality === qualityFlagsTimeSeries.Success
                ? {
                    date: convertUtcToLocalDateTime(v.date),
                    max: v.max,
                    min: v.min,
                    value: v.value
                }
                : {
                    max: null,
                    min: null,
                    value: null
                };

            return {
                ...v,
                ...metrics
            };
        });

        return trendData;
    });
};
