import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { menuBarItems } from './constants';
import { UserProfileSettingsDropdown } from './UserProfileSettingsDropdown';
import { MainMenuDropdown } from './MainMenuDropdown';
import { IconMOPortalTitle } from 'svgIcons/MotionPortalIcons';

const HeaderComponent = () => {
    const { t: translate } = useTranslation();
    const [menuBarFits, setMenuBarFits] = useState(window.innerWidth > 1160);

    const updateMenuVisibility = () => {
        setMenuBarFits(window.innerWidth > 1160);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMenuVisibility, false);

        return () => {
            window.removeEventListener('resize', updateMenuVisibility, false);
        };
    }, []);

    const renderMenuBarItems = () =>
        menuBarFits && <Nav>
            {
                menuBarItems.map((headerItem, key) =>
                    <Nav.Link key={key} as={NavLink} to={headerItem.route}>{translate(headerItem.title)}</Nav.Link>)
            }
        </Nav >;

    return (
        <div className='header-component'>
            <Navbar className='pt-navbar-container' fluid='true'>
                <Navbar.Brand>
                    <span className='mo-portal-title'>
                        <IconMOPortalTitle />
                    </span>
                </Navbar.Brand>
                <Navbar.Collapse>
                    {renderMenuBarItems()}
                    <Nav className='pt-navbar-right'>
                        <UserProfileSettingsDropdown />
                        <MainMenuDropdown menuBarFits={menuBarFits} menuBarItems={menuBarItems} />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div >
    );
};

export default HeaderComponent;
