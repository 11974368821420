import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTrendKPIsForAssetV2 } from '../../../commonActions';
import { getModel } from './selectors';
import * as actions from './actions';
import TrendComponent from './TrendComponent';


const TrendContainer = (props) => {
    return <TrendComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, getTrendKPIsForAssetV2 }, dispatch)
    };
};

const TrendContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendContainer);


export default TrendContainerConn;
