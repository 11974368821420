import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { filter, find } from 'lodash';
import { Checkbox, GeneralSelect } from 'sharedComponents';
import { ALL_VALUE, SELECT_ACTION, DESELECT_ACTION } from './constants';
import { getAllAssetsOption } from './helpers';
import './style.scss';


const ValueContainer = ({ children, ...props }) => {
    const { t: translate } = useTranslation();
    const { getValue, hasValue } = props;
    const selections = getValue();
    const length = find(selections, selection => selection.value === ALL_VALUE) ?
        selections.length - 1 : selections.length;
    return (
        <components.ValueContainer {...props}>
            {React.Children.map(children, (child) => {
                return !hasValue || child.type === components.Input ? child : null;
            })}
            {hasValue && !props.selectProps.menuIsOpen ?
                <div>{`${length} ${translate('ABB.Powertrain.Frontend.eventListAssetsSelectedLabel')}`}</div>
                : null
            }
        </components.ValueContainer>
    );
};

ValueContainer.propTypes = {
    children: PropTypes.array,
    getValue: PropTypes.func,
    hasValue: PropTypes.bool,
    selectProps: PropTypes.object
};

const Option = props =>
    <div>
        <components.Option {...props}>
            <div className={`drive-selector-option ${props.isDisabled ? 'disabled' : ''}`}>
                <Checkbox
                    checked={props.isSelected}
                    disabled={props.isDisabled}
                    width={'16px'}
                    height={'16px'}
                />
                <label>{props.label}</label>
            </div>
        </components.Option>
    </div>;

Option.propTypes = {
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    label: PropTypes.string
};

const AssetMultiSelectComponent = (props) => {
    const { t: translate } = useTranslation();

    const [isSelectFocused, setIsSelectFocused] = useState();
    const [inputValue, setInputValue] = useState();
    const [options, setOptions] = useState([]);

    const {
        selectedOptions,
        isDisabled,
        allowSelectAll,
        lockedOption,
        onChange,
        isLoading,
        ...otherProps
    } = props;

    useEffect(() => {
        setOptions(allowSelectAll ?
            [getAllAssetsOption(translate), ...props.options] :
            props.options);
    }, [props.options]);

    const onInputChange = (query, action) => {
        if (action !== 'set-value') {
            setInputValue(query);
        }
    };

    const onFocus = () => {
        setIsSelectFocused(true);
    };

    const onBlur = () => {
        setIsSelectFocused(false);
    };

    const onSelectionChange = (value, event) => {
        if (lockedOption && event?.option?.value === lockedOption.value) {
            return false;
        }
        if (allowSelectAll) {
            if (event?.action === SELECT_ACTION && event?.option?.value === ALL_VALUE) {
                onChange([...filter(options, opt => opt.value !== ALL_VALUE && !opt.isDisabled), lockedOption]);
            } else if (event?.action === DESELECT_ACTION && event?.option?.value === ALL_VALUE) {
                onChange([lockedOption]);
            } else if (event?.action === DESELECT_ACTION) {
                onChange(filter(value, opt => opt.value !== ALL_VALUE));
            } else if (value?.length === options.length - 1) {
                onChange(filter(options, opt => opt.value !== ALL_VALUE));
            } else {
                onChange(value);
            }
        } else {
            onChange(value);
        }
    };

    const createValue = (selectedOptions) => {
        if (allowSelectAll) {
            const enabledOptionsLength = filter(options, o => !o.isDisabled ||
                lockedOption && o.value === lockedOption.value).length;
            return selectedOptions && selectedOptions.length === enabledOptionsLength - 1 ?
                [getAllAssetsOption(translate), ...selectedOptions] : selectedOptions;
        }
        return selectedOptions;
    };

    return (
        <GeneralSelect
            {...otherProps}
            isDisabled={isDisabled}
            className='drive-selector-dropdown'
            closeMenuOnSelect={false}
            captureMenuScroll={true}
            isMulti={true}
            components={{
                Option,
                ValueContainer
            }}
            isLoading={isLoading}
            onFocus={onFocus}
            onBlur={onBlur}
            onInputChange={onInputChange}
            inputValue={inputValue}
            selectProps={{ isSelectFocused }}
            options={options}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            onChange={onSelectionChange}
            value={createValue(selectedOptions)}
        />
    );
};

AssetMultiSelectComponent.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array,
    isDisabled: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    lockedOption: PropTypes.object,
};

export default AssetMultiSelectComponent;
