
import React from 'react';

import { IconError, IconInfoNotification, IconSuccess, IconWarning } from 'svgIcons/MotionPortalIcons';
import { NOTIFICATION_TYPES, NOTIFICATION_BAR } from './constants';

export const getNotificationIcon = (notificationType) => {
	switch (notificationType) {
		case NOTIFICATION_BAR.FREE_FOR_RENEWAL:
		case NOTIFICATION_BAR.FREE:
		case NOTIFICATION_TYPES.ERROR:
			return (
				<IconError width={22} height={22} />
			);
		case NOTIFICATION_TYPES.INFO:
			return (
				<IconInfoNotification width={22} height={22} />
			);
		case NOTIFICATION_TYPES.SUCCESS:
			return (
				<IconSuccess width={22} height={22} />
			);
		case NOTIFICATION_TYPES.WARN:
			return (
				<IconWarning width={22} height={22} />
			);
		default:
	}
	return null;
};
