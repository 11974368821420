import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    getEnvironmentStageSelector,
    getEnvironmentVariables
} from 'helpers/selectors';
import { mainMenuLinks } from './constants';
import { translate } from 'helpers/translateHelper';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';


const useMainMenuOptions = (setModalVisible, setCurrentModal) => {

    const { i18n } = useTranslation();
    const [menuOptions, setMenuOptions] = useState([]);

    const environment = useSelector(getEnvironmentStageSelector);
    const environmentVariables = useSelector(getEnvironmentVariables);

    const adminSettings = {
        label: 'ABB.Powertrain.Frontend.mainMenu.adminSettings',
        href: environmentVariables.CMDAdminPortalUrl
    };

    const orgManagement = {
        label: 'ABB.Powertrain.Frontend.mainMenu.orgMgt',
        href: environmentVariables.orgMgtUrl
    };

    const userLanguageCode = i18n.language;

    useEffect(() => {
        setMenuOptions([
            ...roleBasedCapabilityHelper.hasCMDAdminPortalMenu() ? [adminSettings] : [],
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.importantInformation',
                onClick: () => {
                    setModalVisible(true);
                    setCurrentModal({
                        modalType: 'importantInfoFetchedPDF', modalMetadata: {
                            title: translate('ABB.Powertrain.Frontend.importantInformationModalLabel')
                        }
                    });
                }
            },
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.onlineHelp',
                href: `${mainMenuLinks.onlineHelpLinks[environment]}${userLanguageCode}`
            },
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.reportIssue',
                onClick: () => {
                    setModalVisible(true);
                    setCurrentModal({
                        modalType: 'reportIssue'
                    });
                }
            },
            ...environmentVariables.disableOrganizationManagement ? [orgManagement] : [],
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.eula',
                href: mainMenuLinks.eula
            },
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.abbAUP',
                href: mainMenuLinks.abbAUP
            },
            {
                label: 'ABB.Powertrain.Frontend.mainMenu.privacyPolicy',
                href: mainMenuLinks.privacyPolicy
            }
        ]);
    }, [userLanguageCode]);

    return menuOptions;
};

export default useMainMenuOptions;
