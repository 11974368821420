import PropTypes from 'prop-types';
import React from 'react';
import { InfoMessage, PopOutChart } from 'sharedComponents';
import { chartPlugins } from './constants';
import useChartConfig from './useChartConfig';
import {
    EnableUniqueScaling,
    LastSyncDate,
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup
} from 'sharedComponents/Chart/headers';


const TrendChart = ({
    selectedTrendKPIs,
    selectedTrendsData,
    removeSelectedKpiLegend,
    trendDataLoadingStatus,
    userLimits,
    enableUniqueScaling,
    availableTrendKPIs,
    lastMeasurementTimeStampLabel,
    isZoomed,
    chartKey = '',
    reloadAllSelectedTrendKpiData,
    setIsZoomed,
    zoomInToSelectedTrendKpiData,
    yAxisTitleVisible,
    multiChart
}) => {
    const resetServerZoom = () => {
        reloadAllSelectedTrendKpiData(selectedTrendKPIs);
    };

    const zoomIn = (from, to) => {
        zoomInToSelectedTrendKpiData(availableTrendKPIs, selectedTrendKPIs, from, to);
    };

    const graphOptions = useChartConfig(selectedTrendsData, userLimits, yAxisTitleVisible, removeSelectedKpiLegend, multiChart);

    const CustomHeader = withPopOutRow([
        withLeftButtonGroup([LastSyncDate, EnableUniqueScaling]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])]);

    return (
        <PopOutChart
            hasKpi={!!selectedTrendKPIs?.length}
            renderNoKpi={() => <InfoMessage
                title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
            hasData={!!selectedTrendsData?.length}
            renderNoData={() => trendDataLoadingStatus ? <InfoMessage
                title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' /> : <InfoMessage
                title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
            enableUniqueScaling={enableUniqueScaling}
            lastMeasurementLabel={lastMeasurementTimeStampLabel}
            zoomInServer={zoomIn}
            resetServerZoom={resetServerZoom}
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            loadingStatus={trendDataLoadingStatus}
            options={graphOptions}
            modules={chartPlugins}
            container={chartKey}
            mainChartHeader={CustomHeader}
        />
    );
};

TrendChart.propTypes = {
    reloadAllSelectedTrendKpiData: PropTypes.func,
    setIsZoomed: PropTypes.func,
    zoomInToSelectedTrendKpiData: PropTypes.func,
    selectedTrendKPIs: PropTypes.array,
    enableUniqueScaling: PropTypes.bool,
    lastMeasurementTimeStampLabel: PropTypes.string,
    selectedTrendsData: PropTypes.array,
    availableTrendKPIs: PropTypes.array,
    trendDataLoadingStatus: PropTypes.string,
    userLimits: PropTypes.array,
    isZoomed: PropTypes.bool,
    chartKey: PropTypes.string,
    yAxisTitleVisible: PropTypes.bool,
    legendVisible: PropTypes.bool,
    multiChart: PropTypes.string
};

export default TrendChart;
