import { environments, environmentStages } from './constants';
import localStorageHelper from './localStorageHelper';


export const getEnvironmentStage = (environment) => {
    switch (environment) {
        case environments.SMART_SENSOR_DEV:
        case environments.POWERTRAIN_DEV:
        case environments.SMART_SENSOR_DEV_LOCAL:
        case environments.POWERTRAIN_DEV_LOCAL:
            return environmentStages.DEV;

        case environments.SMART_SENSOR_ALPHA:
        case environments.POWERTRAIN_ALPHA:
            return environmentStages.ALPHA;

        case environments.SMART_SENSOR_BETA:
        case environments.SMART_SENSOR_BETA_CN:
        case environments.POWERTRAIN_BETA:
        case environments.POWERTRAIN_BETA_CN:
            return environmentStages.BETA;

        case environments.SMART_SENSOR_PROD:
        case environments.SMART_SENSOR_PROD_CN:
        case environments.POWERTRAIN_PROD:
        case environments.POWERTRAIN_PROD_CN:
            return environmentStages.PROD;

        default:
            return null;
    }
};

const checkEnvironmentStage = (environment) => {
    let env = environment;
    if (!env) {
        const envProps = localStorageHelper.nonEncrypted.getItem('environmentProperties');
        env = envProps && envProps.environment;
    }
    return getEnvironmentStage(env);
};

export const isDev = (environment) => {
    const environmentStage = checkEnvironmentStage(environment);
    return environmentStage === environmentStages.DEV || environmentStage === environmentStages.ALPHA;
};

export const isNodeEnvTest = () => {
    const NODE_ENV = process.env['NODE_ENV'];
    return NODE_ENV === 'test';
};
