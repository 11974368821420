import { apiCallStatus } from 'helpers/constants';
import { sensorActionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { AssetDetailsApi } from 'api';

const getSensorFeaturesAction = getApiActionCreator(sensorActionTypes.GET_SENSOR_FEATURES);
const getSensorPropertiesAction = getApiActionCreator(sensorActionTypes.GET_SENSOR_PROPERTIES);
const getGatewayInfoAction = getApiActionCreator(sensorActionTypes.GET_GATEWAY_INFO);

export const getGatewayInfo = (assetID) => {
    return (dispatch) => {
        dispatch(getGatewayInfoAction(apiCallStatus.LOADING));
        AssetDetailsApi.getGatewayInfo(assetID)
            .then((data) => {
                dispatch(getGatewayInfoAction(apiCallStatus.SUCCESS, data));
            },
                (err) => {
                    dispatch(getGatewayInfoAction(apiCallStatus.ERROR, null, err));
                });
    };
};

const getSensorFeatures = (firmwareID, sensorIdentifier, sensorTypeID) => {
    return (dispatch) => {
        dispatch(getSensorFeaturesAction(apiCallStatus.LOADING));
        AssetDetailsApi.getSensorFeatures(firmwareID, sensorIdentifier, sensorTypeID)
            .then((data) => {
                dispatch(getSensorFeaturesAction(apiCallStatus.SUCCESS, data));
            },
                (err) => {
                    dispatch(getSensorFeaturesAction(apiCallStatus.ERROR, null, err));
                });
    };
};

export const getSensorProperties = (sensorIdentifier, sensorTypeId) => {
    return (dispatch) => {
        dispatch(getSensorPropertiesAction(apiCallStatus.LOADING));
        AssetDetailsApi.getSensorProperties(sensorIdentifier, sensorTypeId)
            .then((data) => {
                dispatch(getSensorPropertiesAction(apiCallStatus.SUCCESS, data));
                if (data.applicationData && data.applicationData.firmwareID) {
                    dispatch(getSensorFeatures(data.applicationData.firmwareID, sensorIdentifier, sensorTypeId));
                }
            },
                (err) => {
                    dispatch(getSensorPropertiesAction(apiCallStatus.ERROR, null, err));
                });
    };
};

