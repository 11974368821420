import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconPopOut = ({ width = '32px', height = '32px', viewBox = '0 0 32 32' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width={width} height={height} rx='16' fill={colors.black} fillOpacity='0.08' />
		<path d='M11 17H9V22C9 22.2652 9.10536 22.5196 9.29289 22.7071C9.48043 22.8946 9.73478 23 10 23H15V21H11V17ZM22 9H13C12.7348 9 12.4804 9.10536 12.2929 9.29289C12.1054 9.48043 12 9.73478 12 10V19C12 19.2652 12.1054 19.5196 12.2929 19.7071C12.4804 19.8946 12.7348 20 13 20H22C22.2652 20 22.5196 19.8946 22.7071 19.7071C22.8946 19.5196 23 19.2652 23 19V10C23 9.73478 22.8946 9.48043 22.7071 9.29289C22.5196 9.10536 22.2652 9 22 9ZM21 18H14V11H21V18Z' fill={colors.mineShaft} />
	</svg>;
};

IconPopOut.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string
};

export default IconPopOut;
