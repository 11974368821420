import forEach from 'lodash/forEach';

import { propertyType } from 'helpers/constants';
import {
	baseToCustomUOM,
	customToBaseUOM,
	getMeasurementUnits
} from 'helpers/unitHelper';

const internalHandleHealthStatusesConversion = (healthStatuses, conversionFn) => {
	const userMeasurementUnitGroups = getMeasurementUnits();
	forEach(healthStatuses, (measurementHealthStatus) => {
		forEach(measurementHealthStatus.healthStatuses, (healthStatusItem) => {
			healthStatusItem.valueFrom = conversionFn(measurementHealthStatus.measurementUnitGroupID, healthStatusItem.valueFrom, userMeasurementUnitGroups);
			healthStatusItem.valueTo = conversionFn(measurementHealthStatus.measurementUnitGroupID, healthStatusItem.valueTo, userMeasurementUnitGroups);
		});
	});

	return healthStatuses;
};

const convertCalibrationUoM = (
	measurementTypeUnitGroupID,
	measurementCalibration,
	measurementUnitGroups,
	convertFn
) => {
	if (!isNaN(parseFloat(measurementCalibration))) {
		const zeroRefValue = convertFn(measurementTypeUnitGroupID, 0, measurementUnitGroups);
		const convertedCalibrationValue = convertFn(measurementTypeUnitGroupID, measurementCalibration, measurementUnitGroups);

		return convertedCalibrationValue - zeroRefValue;
	}

	return measurementCalibration;
};

export const handleGetHealthStatusesConversion = (healthStatuses) => {

	return internalHandleHealthStatusesConversion(healthStatuses, baseToCustomUOM.convertWithGroups);
};

export const handleSaveMeasurementCalibration = (measurementCalibrationObj) => {
	const measurementUnitGroups = getMeasurementUnits();
	forEach(measurementCalibrationObj, (measurementCalibration) => {
		measurementCalibration.value = convertCalibrationUoM(
			measurementCalibration.measurementTypeUnitGroupID,
			measurementCalibration.value || 0,
			measurementUnitGroups,
			customToBaseUOM.convertWithGroups
		);
	});

	return measurementCalibrationObj;
};

export const handleSaveHealthStatusGroupsConversion = (healthStatuses) => {

	return internalHandleHealthStatusesConversion(healthStatuses, customToBaseUOM.convertWithGroups);
};


export const handleGetAssetPropertiesConversion = (assetDetails) => {
	const userMeasurementUnitGroups = getMeasurementUnits();
	forEach(assetDetails.sections, (section) => {
		forEach(section.data, (prop) => {
			if (prop.propertyDataType === propertyType.Decimal) {
				const convertObj = baseToCustomUOM.convertAndFormatWithGroups(prop.propertyUnitGroupID, prop.propertyValue, userMeasurementUnitGroups);
				prop.propertyValue = convertObj.measurementValue;
				prop.propertyDefaultUnit = convertObj.measurementUnit;
			}
		});
	});
	return assetDetails;
};


export const handleHealthStatusThresholdsConversion = (thresholds, measurementUnitGroupID) => {
	const userMeasurementUnitGroups = getMeasurementUnits();
	forEach(thresholds, (healthStatusItem) => {
		healthStatusItem.valueFrom = baseToCustomUOM.convertWithGroups(measurementUnitGroupID, healthStatusItem.valueFrom, userMeasurementUnitGroups);
		healthStatusItem.valueTo = baseToCustomUOM.convertWithGroups(measurementUnitGroupID, healthStatusItem.valueTo, userMeasurementUnitGroups);
	});
	return thresholds;
};
