import React from 'react';
import PropTypes from 'prop-types';

const IconDownload = ({ width = '48px', height = '48px', viewBox = '0 0 16 16', color = 'none' }) =>
	<svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M2 12V14H14V12H15V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V12H2ZM9 1V9L11 7L12.5 8.5L8 13L3.5 8.5L5 7L7 9V1H9Z' fill='#0F0F0F' />
	</svg>;

IconDownload.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fill: PropTypes.string,
};

export default IconDownload;
