import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SearchBox, GeneralSelect } from 'sharedComponents';
import { filterTabs } from 'helpers/constants';
import { getGatewayTypeOptions, getDefaultValue } from '../../helpers';


const FilterComponent = ({ powertrains, selectedPowertrain, activeTab, onPowertrainClick, actions }) => {
    const { t: translate } = useTranslation();
    const {
        searchboxFilter,
        gatewayTypeFilter
    } = useSelector(({ crossAssetAnalysisSidepanel }) => crossAssetAnalysisSidepanel);

    return activeTab === filterTabs.POWERTRAINS ?
        <GeneralSelect
            options={powertrains}
            getOptionLabel={pt => pt.systemName}
            getOptionValue={pt => pt.systemID}
            menuShouldScrollIntoView={false}
            onChange={powertrain => onPowertrainClick(powertrain.systemID)}
            className='powertrain-filter'
            value={selectedPowertrain}
            placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameText')} /> :
        <>
            <SearchBox
                placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameIDSerialNrText')}
                onChange={actions.setSearchboxFilterAction}
                value={searchboxFilter} />
            <GeneralSelect
                options={getGatewayTypeOptions(translate)}
                getOptionLabel={option => option.title}
                getOptionValue={option => option.id}
                defaultValue={getDefaultValue(gatewayTypeFilter, translate)}
                menuShouldScrollIntoView={false}
                onChange={value => actions.setGatewayTypeFilterAction(value.id)}
                className='gateway-type-filter'
                isSearchable={false} />
        </>;
};

export default FilterComponent;
