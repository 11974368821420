import { map, sumBy } from 'lodash';
import { DRIVE_STATUS_KEY, OVER_ALL_STATUS } from './constants';
// eslint-disable-next-line import/no-unused-modules
export const afterChartReDrawStringData = (chartContainerId, removeFn) => {
    const htmlElements = document.querySelectorAll(`#${chartContainerId} .remove-button`);
    map(htmlElements, element => {
        // Clone the node, in order to remove all previous event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        newElement.addEventListener('click', (e) => {
            removeFn(parseInt(e.currentTarget.id.split('_')[1]));
            const allButtons = document.querySelectorAll('div.highcharts-legend-item');
            const hiddenButtons = document.querySelectorAll('div.highcharts-legend-item-hidden');
            if (allButtons && hiddenButtons) {
                const nonHiddenCount = allButtons.length - hiddenButtons.length;
                if (nonHiddenCount === 1 && hiddenButtons.length > 0) {
                    hiddenButtons.forEach(hiddenButton => hiddenButton.querySelector('.item-kpi-name').click());
                }
            }
            e.stopPropagation();
        });
    });
};
// eslint-disable-next-line import/no-unused-modules
export const chartLegendItemClick = (e) => {
    if (e.target.visible) {
        const totalVisible = sumBy(
            e.target.chart.series,
            ({ visible, xData, index, legendItem }) => {
                return Number(visible === true
                    && (xData.length === 0 && e.target.index === index || xData.length > 0)
                    && !!legendItem);
            }
        );
        if (totalVisible === 1) {
            e.preventDefault();
            return false;
        }
    }
};
export const getFlatAvailableKPIList = (kpis) => {
    return kpis?.length ?
        {
            ...kpis[0]?.data,
            ...kpis[1]?.data
        } :
        {};
};

export const getDriveStatusData = (driveChartData) => {
    return driveChartData?.conditionTrends && driveChartData?.conditionTrends?.length > 0 ? driveChartData?.conditionTrends[0]?.values : [];

};

export const getDriveOverAllStatus = (driveChartData) => {
    return driveChartData?.currentCondition ? OVER_ALL_STATUS[driveChartData?.currentCondition] : 0;
};

export const getAllKPITrendChartList = (KPIList) => {
    return KPIList?.filter((trendKPIItem) => trendKPIItem?.key !== DRIVE_STATUS_KEY);
};

export const getDriveKPIList = (KPIList) => {
    const driveList = KPIList?.filter((trendKPIItem) => trendKPIItem?.key === DRIVE_STATUS_KEY);
    return driveList && driveList[0] ? driveList[0] : 0;
};

export const getDateRange = () => {
    const fromDate = new Date();
    fromDate.setDate(fromDate?.getDate() - 89);
    const toDate = new Date();
    return {
        from: fromDate?.toISOString(),
        to: toDate?.toISOString(),
    };
};

export const getConditionStatus = (status) => {
    return OVER_ALL_STATUS[status];
};
