export const actionTypes = {
    SET_ACTIVE_TAB: 'operationalParameters/SET_ACTIVE_TAB',
    SET_DATE_SELECTION: 'overview/SET_DATE_SELECTION',
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    LOAD_MEASUREMENT: 'operationalParameters/LOAD_MEASUREMENT',
    COLLECT_RAW_DATA: 'operationalParameters/COLLECT_RAW_DATA',
    SET_ACTIVE_TREND_TAB: 'operationalParameters/SET_ACTIVE_TREND_TAB',
    SET_MULTICHART_CONFIG: 'operationalParameters/SET_MULTICHART_CONFIG',
    GET_MULTI_TREND_DATA: 'operationalParameters/GET_MULTI_TREND_DATA',
    CLEAR_MULTICHART_DATA: 'operationalParameters/CLEAR_MULTICHART_DATA'
};

export const opButtons = {
    trends: 'Trends',
    scatterPlot: 'ScatterPlot',
    histogram: 'Histogram',
    vibrationFFT: 'VibrationFFT'
};

export const multiChartOptions = {
    SINGLE: 'single',
    MULTI: 'multi'
};

export const multiChartToggleOptions = [{
    label: 'ABB.Powertrain.Frontend.operationalParametersSingleGraph',
    value: multiChartOptions.SINGLE
},
{
    label: 'ABB.Powertrain.Frontend.operationalParametersMultiGraph',
    value: multiChartOptions.MULTI
}];

export const tabContainerIds = {
    [opButtons.trends]: 'trend-container',
    [opButtons.scatterPlot]: 'scatter-container',
    [opButtons.histogram]: 'histogram-container',
    [opButtons.vibrationFFT]: 'vibration-fft-container'
};
