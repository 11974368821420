import { assetObjectToComponentRequest, mapToOrganizationRequestBody } from '../helpers';
import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import localStorageHelper from '../../helpers/localStorageHelper';
import { userRoles } from 'helpers/constants';


class Maintenance {

    static getDetails(asset) {
        const endpoint = formatEndpoint(endpoints.maintenance.getDetails);
        return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
    }

    static getAssets(organizations) {
        const endpoint = formatEndpoint(endpoints.maintenance.getAssets);
        return RestManager.post(endpoint, mapToOrganizationRequestBody(organizations));
    }

    static getPowertrains(organizations, sites) {
        const user = localStorageHelper.getItem('user');
        const params = {
            userName: user && user.email,
            isSuperAdmin: user && (user.isSuperAdmin || user.adminRoleID === userRoles.SUPERVIEWER)
        };
        const endpoint = formatEndpoint(endpoints.maintenance.getPowertrains, params);
        return RestManager.post(endpoint, { siteIDs: sites, organizations });
    }
}

export default Maintenance;
