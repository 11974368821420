import React from 'react';
import PropTypes from 'prop-types';

const IconKpiUnknown = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'none' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='#DBDBDB' />
		<path fillRule='evenodd' clipRule='evenodd' d='M6.54541 10.9092H8.72723V13.091H6.54541V10.9092Z' fill='#DBDBDB' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.38621 5.96514C9.38621 5.61858 9.24444 5.34292 8.9609 5.13814C8.67735 4.93336 8.2783 4.83097 7.76372 4.83097C7.32265 4.83097 6.88684 4.9281 6.45627 5.12239C6.02571 5.31666 5.68441 5.57658 5.43237 5.90213L4.34546 4.48441C4.72352 3.98034 5.22759 3.59178 5.85768 3.31874C6.48778 3.0457 7.19138 2.90918 7.9685 2.90918C9.11318 2.90918 10.0137 3.16647 10.67 3.68104C11.3264 4.19562 11.6546 4.90447 11.6546 5.80761C11.6546 6.41671 11.5102 6.9339 11.2214 7.35922C10.9326 7.78453 10.4889 8.13896 9.89029 8.4225C9.57524 8.56953 9.32845 8.70604 9.14993 8.83206C8.9714 8.95808 8.84276 9.09723 8.76399 9.2495C8.68523 9.40177 8.64585 9.62493 8.64585 9.91898V10.2025H6.48778V9.7457C6.48778 9.0631 6.60592 8.54327 6.84221 8.18622C7.07849 7.82916 7.51693 7.50887 8.15753 7.22532C8.6196 7.01529 8.93989 6.81839 9.11842 6.63461C9.29695 6.45083 9.38621 6.22768 9.38621 5.96514Z' fill='#DBDBDB' />
	</svg>;


};

IconKpiUnknown.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconKpiUnknown;
