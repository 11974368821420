import {
    IconCondition,
    IconAvailability,
    IconEnvironment,
    IconReliability,
    IconStress,
} from 'svgIcons/MotionPortalIcons';

export const defaultConditions = {
    overall: -1,
    availability: -1,
    environment: -1,
    reliability: -1,
    stress: -1,
};

export const overallConditionConfig =
{
    type: 'overall',
    Icon: IconCondition,
    texts: [
        { condition: -1, text: 'ABB.Powertrain.Frontend.notapplicableLabel' },
        { condition: 0, text: 'ABB.Powertrain.Frontend.unknownLabel' },
        { condition: 1, text: 'ABB.Powertrain.Frontend.goodLabel' },
        { condition: 2, text: 'ABB.Powertrain.Frontend.tolerableLabel' },
        { condition: 3, text: 'ABB.Powertrain.Frontend.poorLabel' }]
};

export const conditionConfig = [
    {
        type: 'availability',
        Icon: IconAvailability,
        texts: [
            { condition: -1, text: 'ABB.Powertrain.Frontend.availabilityConditionNotApplicable' },
            { condition: 0, text: 'ABB.Powertrain.Frontend.availabilityConditionUnknown' },
            { condition: 1, text: 'ABB.Powertrain.Frontend.availabilityConditionGood' },
            { condition: 2, text: 'ABB.Powertrain.Frontend.availabilityConditionTolerable' },
            { condition: 3, text: 'ABB.Powertrain.Frontend.availabilityConditionPoor' }]
    },
    {
        type: 'environment',
        Icon: IconEnvironment,
        texts: [
            { condition: -1, text: 'ABB.Powertrain.Frontend.environmentConditionNotApplicable' },
            { condition: 0, text: 'ABB.Powertrain.Frontend.environmentConditionUnknown' },
            { condition: 1, text: 'ABB.Powertrain.Frontend.environmentConditionGood' },
            { condition: 2, text: 'ABB.Powertrain.Frontend.environmentConditionTolerable' },
            { condition: 3, text: 'ABB.Powertrain.Frontend.environmentConditionPoor' }]
    },
    {
        type: 'reliability',
        Icon: IconReliability,
        texts: [
            { condition: -1, text: 'ABB.Powertrain.Frontend.reliabilityConditionNotApplicable' },
            { condition: 0, text: 'ABB.Powertrain.Frontend.reliabilityConditionUnknown' },
            { condition: 1, text: 'ABB.Powertrain.Frontend.reliabilityConditionGood' },
            { condition: 2, text: 'ABB.Powertrain.Frontend.reliabilityConditionTolerable' },
            { condition: 3, text: 'ABB.Powertrain.Frontend.reliabilityConditionPoor' }]
    },
    {
        type: 'stress',
        Icon: IconStress,
        texts: [
            { condition: -1, text: 'ABB.Powertrain.Frontend.stressConditionNotApplicable' },
            { condition: 0, text: 'ABB.Powertrain.Frontend.stressConditionUnknown' },
            { condition: 1, text: 'ABB.Powertrain.Frontend.stressConditionGood' },
            { condition: 2, text: 'ABB.Powertrain.Frontend.stressConditionTolerable' },
            { condition: 3, text: 'ABB.Powertrain.Frontend.stressConditionPoor' }]
    }
];
