import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { GeneralSelect, SearchBox } from 'sharedComponents';
import AssetTable from './AssetTable/AssetTable';
import './style.scss';


const AssetListWithFilterComponent = ({
    className,
    title,
    options,
    selectedValue,
    onSelectChange,
    isLoading,
    onSearchChange,
    searchValue,
    searchPlaceHolder,
    assets,
    actionType,
    action,
    loadingStatus,
    hasMore,
    loadMore
}) => {
    return <Col className={className} role='asset-list-with-filters'>
        <Row className='section-title'>
            <Col className='text-medium-large'>
                {title}
            </Col>
        </Row>
        <Row className='filter-row'>
            <Col className='filter-control'>
                <GeneralSelect
                    options={options}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    value={selectedValue}
                    menuShouldScrollIntoView={false}
                    onChange={onSelectChange}
                    className='asset-type-filter'
                    id='asset-type-filter'
                    isSearchable={false}
                    isLoading={isLoading} />
            </Col>
            <Col className='filter-control'>
                <SearchBox
                    onChange={onSearchChange}
                    value={searchValue}
                    placeholder={searchPlaceHolder} />
            </Col>
        </Row>
        <Row className='asset-table-row'>
            <Col>
                <AssetTable
                    assets={assets}
                    actionType={actionType}
                    action={action}
                    loadingStatus={loadingStatus}
                    hasMore={hasMore}
                    loadMore={loadMore}
                />
            </Col>
        </Row>
    </Col>;
};

AssetListWithFilterComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.array,
    selectedValue: PropTypes.object,
    onSelectChange: PropTypes.func,
    isLoading: PropTypes.bool,
    onSearchChange: PropTypes.func,
    searchValue: PropTypes.string,
    searchPlaceHolder: PropTypes.string,
    assets: PropTypes.array,
    actionType: PropTypes.string,
    action: PropTypes.func,
    loadingStatus: PropTypes.string,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func
};

export default AssetListWithFilterComponent;
