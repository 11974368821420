import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';
import HistogramComponent from './HistogramComponent';


const HistogramContainer = (props) => {
	return <HistogramComponent {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const HistogramContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(HistogramContainer);


export default HistogramContainerConn;
