import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';
// import { apiCallStatus } from 'helpers/constants';
// import { Loading } from 'sharedComponents';

import EventDetailsSSComponent from './EventDetailsSSComponent';


const EventDetailsSSContainer = (props) => {
	useEffect(() => () => {
		props.actions.clearStore();
	}, []);

	return <EventDetailsSSComponent {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const EventDetailsSSContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(EventDetailsSSContainer);


export default EventDetailsSSContainerConn;
