import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconWarning = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.orangePeel }) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} fill='none'>
		<path d='M16 2C8.268 2 2 8.268 2 16C2 23.732 8.268 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601
		25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM14 7H18V19H14V7ZM18 26H14V22H18V26Z' fill={color} />
	</svg>;
};

IconWarning.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconWarning;
