import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { RadioButton } from 'sharedComponents';

const Option = props => {
	return <components.Option {...props}>
		<RadioButton
			id={props.data.label}
			checked={props.isSelected}
			onClick={() => null}
			label={props.label}
		/>
	</components.Option>;
};


Option.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string,
		type: PropTypes.any
	}),
	isSelected: PropTypes.bool,
	label: PropTypes.string
};

export default Option;
