import React from 'react';
import { join, map } from 'lodash';
import { useDispatch } from 'react-redux';

import { setMeasurementDialogParams } from '../../actions';

import IconDownload from 'svgIcons/MotionPortalIcons/IconDownload';
import { actionTypes, assetMenuItems } from '../../../../../../Header/constants';

const DownloadAllKpisButton = (props) => {

    const { section: { data }, capability } = props;

    const dispatch = useDispatch();

    const downloadMeasurementGroup = () => {
        const kpis = map(data, (kpi) => kpi.measurementTypeIdentifier);

        dispatch(setMeasurementDialogParams({
            measurementTypeIds: join(kpis, ',')
        }));
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: assetMenuItems.EXPORT_MEASUREMENTS });
    };

    return <>
        {
            capability.hasMeasurementGroupExportCapability() ? <div onClick={downloadMeasurementGroup}>
                <IconDownload width={16} height={16} />
            </div> : null
        }
    </>
        ;
};

export default DownloadAllKpisButton;
