import './i18n';
import './css/main.scss';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { createReduxHistory, generateStore } from './configureStore';
import { addPatchesForBrowsers } from 'helpers/browserHelper';
import { setCustomVH } from 'helpers/customPropertyHelper';
import { Portal } from './components/Portal';
import { Router } from 'react-router-dom';
import logger from 'helpers/logger';

// eslint-disable-next-line no-unused-vars
//import { worker } from './mocks/browser';
import { reducers as allReducers } from './store';

export const store = generateStore(allReducers);

// Create browser history to use in the Redux store
export const history = createReduxHistory(store);

document.title = 'ABB Powertrain Dashboard';

// In case there are basic functions missing from some browser (i.e.: Internet Explorer), here we re-create them.
addPatchesForBrowsers();
setCustomVH();
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-unused-vars
    const { worker } = require('./mocks/browser');
    worker.start({
        onUnhandledRequest(req) {
            logger.warn(`[MSW] Found an unhandled ${req.method} request to ${req.url.href}`);
        }
    });
    worker.printHandlers();
}

function renderApp() {
    const container = document.getElementById('react-app');
    const root = ReactDOMClient.createRoot(container);
    root.render(
        <Provider store={store}>
            {/* eslint-disable react/no-children-prop */}
            <Router
                history={history}
                children={<Portal />}
            />
            {/* eslint-enable react/no-children-prop */}
        </Provider>);
}

const attemptRender = setInterval(() => {
    if (document.getElementById('react-app')) {
        renderApp();
        clearInterval(attemptRender);
    }
}, 1000);
