import map from 'lodash/map';
import { toLocaleDateTimeString } from 'helpers/dateHelper';

export const getColumns = (translate) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.changedParametersListID'),
            dataIndex: 'parameterId',
            isSorting: true,
            width: 150,
        },
        {
            title: translate('ABB.Powertrain.Frontend.changedParametersParameterName'),
            dataIndex: 'parameterName',
            isSorting: true,
        },
        {
            title: translate('ABB.Powertrain.Frontend.changedParametersListOriginalValue'),
            dataIndex: 'oldValue',
            isSorting: true,
            width: 150,
        },
        {
            title: translate('ABB.Powertrain.Frontend.changedParametersListChangedValue'),
            dataIndex: 'newValue',
            isSorting: true,
            width: 150,
        },
        {
            title: translate('ABB.Powertrain.Frontend.changedParametersGatewayTime'),
            dataIndex: 'gatewayTime',
            isSorting: true,
            width: 200,
            render: (gatewayTime) => toLocaleDateTimeString(gatewayTime)

        },
    ];
};

export const formatParameterFileList = (files) => {
    return map(files, (file, index) => {
        return {
            ...file,
            id: index,
            gatewayTime: file.deviceTime
        };
    });
};
