import includes from 'lodash/includes';

import { getPriorDateMinusDays } from 'helpers/dateHelper';
import { dateSelectorType } from './constants';
import { isDev, isNodeEnvTest } from './environmentHelper';
import { basePathWithoutSlash } from './formatters';


const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

//Add SPABasePath without leading slash
const withPrefix = (key) => `${basePathWithoutSlash()}/${key}`;

const getLocalStorageItem = (itemKey) => {
    const item = localStorage.getItem(withPrefix(itemKey));
    return isJson(item) ? JSON.parse(item) : null;
};

const setLocalStorageItem = (itemKey, object) => {
    localStorage.setItem(withPrefix(itemKey), JSON.stringify(object));
};

const removeLocalStorageItem = (itemKey) => {
    localStorage.removeItem(withPrefix(itemKey));
};

const localStorageHelper = {
    searchObjectByKey: (obj, key) => {
        if (key in obj) {
            return obj[key];
        }
        for (const i of obj) {
            const newObj = obj[i];
            if (typeof newObj === 'object') {
                return localStorageHelper.searchObjectByKey(newObj, key);
            } else {
                return newObj;
            }
        }
    },

    setItemForUser: (key, value) => {
        const uid = localStorageHelper.getCurrentUserId();
        uid && setLocalStorageItem(`${key}_${uid}`, value);
    },

    getItemForUser: (key) => {
        const uid = localStorageHelper.getCurrentUserId();
        return getLocalStorageItem(`${key}_${uid}`);
    },

    removeItemForUser: (key) => {
        const uid = localStorageHelper.getCurrentUserId();
        return removeLocalStorageItem(`${key}_${uid}`);
    },

    getLocalStorageProperty: (storage, itemKey) => {
        return localStorageHelper.searchObjectByKey(JSON.parse(storage), itemKey);
    },

    clearLocalStorageItem: () => {
        localStorage.clear();
    },

    removeLocalStorageLogs: () => {
        const localStorageKeys = Object.keys(localStorage);
        localStorageKeys.forEach(key => {
            if (includes(key, 'loglevel')) {
                removeLocalStorageItem(key);
            }
        });
    },

    getActiveDataSources: () => {
        const authTokens = localStorageHelper.getItem('authTokens');

        return authTokens.map(authToken => {
            return authToken.dataSourceType;
        });
    },

    getCurrentUserId: () => {
        const user = localStorageHelper.getItem('user');

        return user ? user.userID : null;
    },

    userDataAvailable: () => {
        //check if the user info is in the local storage
        const userId = localStorageHelper.getCurrentUserId();
        if (!userId) {
            //no user data, clear local storage in case other data is present
            localStorageHelper.clearLocalStorageItem();
            return false;
        }

        return true;
    },

    getItem: (key) => {
        if (isDev() || isNodeEnvTest()) {
            return getLocalStorageItem(key);
        }

        const encryptedKey = key && btoa(key);
        const res = getLocalStorageItem(encryptedKey);

        return res && JSON.parse(decodeURIComponent(atob(res)));
    },
    setItem: (key, data) => {
        if (isDev() || isNodeEnvTest()) {
            return setLocalStorageItem(key, data);
        }

        const encryptedKey = key && btoa(key);
        const encryptedData = data && btoa(encodeURIComponent(JSON.stringify(data)));

        setLocalStorageItem(encryptedKey, encryptedData);
    },
    removeItem: (key) => {
        if (isDev() || isNodeEnvTest()) {
            return removeLocalStorageItem(key);
        }

        const encryptedKey = key && btoa(key);
        removeLocalStorageItem(encryptedKey);
    },

    nonEncrypted: {
        getItem: (key) => getLocalStorageItem(key),
        setItem: (key, data) => setLocalStorageItem(key, data),
        removeItem: (key) => removeLocalStorageItem(key)
    },

    DateSelector: {
        setForCurrentUser: (startDate, endDate, predefinedSelection = null) => {
            if (!predefinedSelection && startDate && endDate) {
                localStorageHelper.setItemForUser(
                    'dateSelector',
                    { type: dateSelectorType.CUSTOM, value: { startDate, endDate } }
                );
            }
            else if (predefinedSelection && predefinedSelection !== 0) {
                localStorageHelper.setItemForUser(
                    'dateSelector',
                    { type: dateSelectorType.PREDEFINED, value: predefinedSelection }
                );
            }
        },
        getIntervalForCurrentUser: () => {

            // If there is no such element, return with null
            const dateSelectorObject = localStorageHelper.getItemForUser('dateSelector');
            if (!dateSelectorObject || !dateSelectorObject.type || !dateSelectorObject.value) {
                return null;
            }

            const { type, value } = dateSelectorObject;

            if (type === dateSelectorType.CUSTOM && value.startDate && value.endDate) {
                return value;
            }
            else if (type === dateSelectorType.PREDEFINED && typeof value === 'number') {
                const startDate = getPriorDateMinusDays(value).toJSON();
                const endDate = new Date().toJSON();

                return { startDate, endDate };
            }

            return null;
        }
    },
    AssetParameters: {
        setForCurrentUser: (assetTypeID, measurementTypesIDs) => {
            let assetTypesWithPreselections = localStorageHelper
                .getItemForUser('selectedMeasurementTypeIDs');
            assetTypesWithPreselections = assetTypesWithPreselections ? assetTypesWithPreselections : {};
            assetTypesWithPreselections[assetTypeID] = measurementTypesIDs;

            localStorageHelper.setItemForUser('selectedMeasurementTypeIDs', assetTypesWithPreselections);
        },
        getForCurrentUser: (assetTypeID) => {
            const assetTypesWithPreselections = localStorageHelper
                .getItemForUser('selectedMeasurementTypeIDs');
            return assetTypesWithPreselections && assetTypesWithPreselections[assetTypeID] ? assetTypesWithPreselections[assetTypeID] : [];
        }
    }
};

export default localStorageHelper;
