import {
    sensorTypesID,
    frequencyMax,
    PiezoSensor
} from './constants';
import some from 'lodash/some';

const getAxisZoomValueBySensorType = (sensortypeID) => {
    switch (sensortypeID) {
        case sensorTypesID.Bearing:
            return frequencyMax.Edwin;
        case sensorTypesID.CoMo:
        case sensorTypesID.CoMoLight:
        case sensorTypesID.CoMoOG:
            return frequencyMax.CoMo;
        case sensorTypesID.MSR:
            return frequencyMax.MSR;
        case sensorTypesID.Motor:
        case sensorTypesID.Pump:
            return frequencyMax.Gen1;
        default: return null;
    }
};

export const getSpectrumRangeOptions = (translate, motorSpeed, sensortypeID, fftData) => {
    return [
        {
            id: 'Full',
            title: translate('ABB.Powertrain.Frontend.fullSpectrumLabel'),
            xAxisZoomValue: getAxisZoomValueBySensorType(sensortypeID)
        },
        {
            id: 'Default',
            title: translate('ABB.Powertrain.Frontend.defaultSpectrumLabel'),
            xAxisZoomValue: 1000
        },
        ...motorSpeed ?
            [{
                id: '10x',
                title: translate('ABB.Powertrain.Frontend.10xSpectrumLabel'),
                xAxisZoomValue: 10 * motorSpeed
            },
            {
                id: '100x',
                title: translate('ABB.Powertrain.Frontend.100xSpectrumLabel'),
                xAxisZoomValue: 100 * motorSpeed
            }] : [],
        ...some(fftData?.dataValues, (data) => data.sensorAxisName === PiezoSensor.axisName) ? [
            {
                id: PiezoSensor.id,
                title: PiezoSensor.id
            },
        ] : []
    ];
};
