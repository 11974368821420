import { StateID, filterTabs, statuses, gatewayTypes } from 'helpers/constants';
import { actionTypes } from './constants';


const initialState = {
    conditionStatusFilter: {
        [StateID.POOR]: false,
        [StateID.TOLERABLE]: false,
        [StateID.GOOD]: false,
        [StateID.UNKNOWN]: false,
    },
    conditionIndexFilter: statuses.ALL,
    searchboxFilter: null,
    gatewayTypeFilter: gatewayTypes.ALL
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                gatewayTypeFilter: action.activeTab === filterTabs.POWERTRAINS
                    ? gatewayTypes.ALL
                    : state.gatewayTypeFilter
            };
        }
        case actionTypes.TOGGLE_CONDITION_STATUS_FILTER: {
            return {
                ...state,
                conditionStatusFilter: {
                    ...state.conditionStatusFilter,
                    [action.conditionStatusID]: !state.conditionStatusFilter[action.conditionStatusID]
                }
            };
        }
        case actionTypes.SET_CONDITION_INDEX_FILTER: {
            return {
                ...state,
                conditionIndexFilter: action.conditionIndexFilter
            };
        }
        case actionTypes.SET_SEARCHBOX_FILTER: {
            return {
                ...state,
                searchboxFilter: action.searchboxFilter
            };
        }
        case actionTypes.SET_GATEWAY_TYPE_FILTER: {
            return {
                ...state,
                gatewayTypeFilter: action.gatewayTypeFilter
            };
        }
        case actionTypes.RESET_FILTERS: {
            Object.keys(state.conditionStatusFilter).forEach(key => state.conditionStatusFilter[key] = false);
            return {
                ...state,
                conditionStatusFilter: { ...state.conditionStatusFilter },
                conditionIndexFilter: statuses.ALL,
                gatewayTypeFilter: gatewayTypes.ALL,
                searchboxFilter: null
            };
        }
        default:
            return state;
    }
};
