import Tippy from '@tippy.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconInfo from 'svgIcons/MotionPortalIcons/IconInfo';

const InfoButton = (props) => {
    const { kpi } = props;
    const { t: translate } = useTranslation();


    return <>
        {kpi.measurementTypeDescription ? <Tippy
            content={translate(kpi.measurementTypeDescriptionKey) || kpi.measurementTypeDescription}
            interactive={true}
            interactiveBorder={10}
            animation='scale'
            theme='light-border'
            trigger='click'
            placement='left'
        >
            <span onClick={(event) => event.stopPropagation()}>
                <IconInfo />
            </span>
        </Tippy> : null
        }
    </>;
};

InfoButton.propTypes = {
    kpi: PropTypes.object
};

export default InfoButton;
