import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { EventLog } from './EventLog';
import { GeneralRouterTabs } from 'sharedComponents';
import { getTabId, getTabOptions, isSmSeAsset } from './helpers';
import { AssetDetails } from './AssetDetails';
import { OperationalParameters } from './OperationalParameters';
import { ConditionInsights } from './ConditionInsights';
import { OperationalParameters as OperationalParametersV2 } from './OperationalParametersV2';
import { SensorProperties } from './SensorProperties';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { useCapability, useNewCapability } from 'sharedHooks';
import { AdvancedAnalyticsLazy } from './AdvancedAnalytics';
import featureConfigHelper from 'helpers/featureConfigHelper';
import { LimitConfigContainer } from './LimitConfigurationBeta';


const Tabs = ({
    model: {
        asset,
        capabilityLoadingStatus,
        conditionInsightAssetStatus
    },
    actions: {
        setActiveTab
    } }) => {
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();

    const capability = useCapability();
    const newCapability = useNewCapability();
    const location = useLocation();

    const eventLogIsVisible = capability.hasEventListCapability();
    const eventLogIsHide = capability.hasEventListCapabilityAvailable();
    const operationalParametersIsVisible = capability.hasTrendCapability() && capability.hasHistogramCapability() && capability.hasScatterCapability();
    const operationalParametersIshide = capability.hasTrendCapabilityAvailable() && capability.hasHistogramCapabilityAvailable() && capability.hasScatterCapabilityAvailable();
    const advancedAnalyticsIsVisible = capability.hasCBMCapability();
    const operationalParameterV2IsVisible = newCapability.hasOpCapability();
    const limitConfigIsVisible = newCapability.hasLimitConfigCapability();
    const hasConditionIndexNewCapability = newCapability.hasConditionIndexNewCapability();

    const [tabOptions, setTabOptions] = useState(getTabOptions(translate, isSmSeAsset(asset)));

    useEffect(() => {
        location && setActiveTab(getTabId(tabOptions, location.pathname));
    }, [location]);

    useEffect(() => {
        setTabOptions(getTabOptions(translate, isSmSeAsset(asset), eventLogIsVisible, advancedAnalyticsIsVisible, operationalParametersIsVisible, operationalParametersIshide, eventLogIsHide, operationalParameterV2IsVisible, limitConfigIsVisible, conditionInsightAssetStatus, hasConditionIndexNewCapability));
    }, [asset, eventLogIsVisible, advancedAnalyticsIsVisible, operationalParametersIsVisible, operationalParametersIshide, eventLogIsHide, operationalParameterV2IsVisible, limitConfigIsVisible, conditionInsightAssetStatus, hasConditionIndexNewCapability]);
    return (
        <GeneralRouterTabs
            options={tabOptions}
            variant='panel'
        >
            <LoadingStatusWrapper loadingStatus={capabilityLoadingStatus}>
                {capability.isSet() &&
                    <Switch>
                        <Route id={tabOptions.assetDetails.tabID} path={tabOptions.assetDetails.route}>
                            <AssetDetails asset={asset} />
                        </Route>
                        <Route path={tabOptions.conditionInsights.route}>
                            <ConditionInsights asset={asset} />
                        </Route>
                        <Route path={tabOptions.operationalParameters.route}>
                            <OperationalParameters asset={asset} />
                        </Route>
                        <Route path={tabOptions.operationalParametersV2.route}>
                            <OperationalParametersV2 asset={asset} />
                        </Route>
                        {eventLogIsVisible &&
                            <Route path={tabOptions.eventLog.route}>
                                <EventLog asset={asset} />
                            </Route>
                        }
                        {
                            <Route path={tabOptions.limitsConfiguration.route}>
                                <LimitConfigContainer asset={asset} />
                            </Route>
                        }
                        {!tabOptions.sensorProperties.hidden &&
                            <Route path={tabOptions.sensorProperties.route}>
                                <SensorProperties asset={asset} />
                            </Route>
                        }
                        {advancedAnalyticsIsVisible && featureConfig.isCBMFeatureEnabled() &&
                            <Route path={tabOptions.advancedAnalytics.route}>
                                <AdvancedAnalyticsLazy asset={asset} />
                            </Route>
                        }
                        <Redirect from='*' to={{ pathname: tabOptions.assetDetails.route, search: location.search }} />
                    </Switch>
                }
            </LoadingStatusWrapper>
        </GeneralRouterTabs >
    );
};

Tabs.propTypes = {
    model: PropTypes.shape({
        asset: PropTypes.object,
        capabilityLoadingStatus: PropTypes.any,
        conditionInsightAssetStatus: PropTypes.any
    }),
    actions: PropTypes.shape({
        setActiveTab: PropTypes.func
    })
};

export default Tabs;
