
import { forEach } from 'lodash';

import logger from 'helpers/logger';
import { SystemAndUtilitiesApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import localStorageHelper from 'helpers/localStorageHelper';
import { getApiActionCreator } from 'helpers/actionHelper';
import * as actionTypes from './constants';
import authHelper from 'helpers/authHelper';
import config from '../../config/appConfig';

/* ------------------------------------
* Actions Creators
* ------------------------------------ */

const loadEnvironmentPropertiesAction = getApiActionCreator(actionTypes.LOAD_ENVIRONMENT_PROPERTIES);
export const loadEnvironmentProperties = () => {
    return (dispatch) => {
        dispatch(loadEnvironmentPropertiesAction(apiCallStatus.LOADING));
        SystemAndUtilitiesApi.getEnvironmentProperties()
            .then((environmentProperties) => {
                logger.debug('Environment properties successfully loaded');
                localStorageHelper.nonEncrypted.setItem('environmentProperties', environmentProperties);

                dispatch(loadEnvironmentPropertiesAction(apiCallStatus.SUCCESS, environmentProperties));
            }, (err) => {
                dispatch(loadEnvironmentPropertiesAction(apiCallStatus.ERROR, null, err));
            });
    };
};

/**
 * Action to try token refreshing
 * Called periodically
 *
 * @remarks
 * Token should be refreshed so that users can use the application for extended periods of time.
 * Expiration status is checked periodically and a token refresh is started {config.tokenExpirationLimit} ms before
 * expiration time.
 * Refresh is not initiated if the token is already expired.
 *
 */
export const tryTokenRefresh = () => {
    return (dispatch) => {
        const tokensData = authHelper.getTokensData();
        let promise = Promise.resolve();

        if (tokensData && authHelper.isValidIAMToken()) {
            forEach(tokensData, (token) => {
                const expDate = new Date(token.expiration).getTime();
                const msUntilExpiration = expDate - new Date().getTime();
                if (msUntilExpiration <= config.tokenExpirationLimit && msUntilExpiration > 0) {
                    if (token?.dataSourceType) {
                        promise = promise.then(() => authHelper.refreshToken(config.dataSourcesType.CommonSensor));
                    }
                }
            });
        }

        return promise.catch((error) => {
            dispatch({ type: actionTypes.REFRESH_TOKEN, error });
            return Promise.reject(error); // let the error propagate to the component
        });
    };
};
