import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChangedParametersListModalComponent from './ChangedParameterListComponent';
import { getModel } from './selectors';
import * as changedParametersListActions from './actions';


const ChangedParameterListModalContainer = (props) => {
	return <ChangedParametersListModalComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(changedParametersListActions, dispatch)
	};
};

const ChangedParametersListContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChangedParameterListModalContainer);

export default ChangedParametersListContainerConn;
