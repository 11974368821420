import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { assetObjectToComponentRequest } from './../helpers';

class ParameterFiles {
	static getParameterFileList(asset, from, to) {
		const endpoint = formatEndpoint(endpoints.parameterFile.getList, { from, to });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getParameterFile(asset, id) {
		const endpoint = formatEndpoint(endpoints.parameterFile.download, { id });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getChangedParameterFileList(asset, from, to) {
		const endpoint = formatEndpoint(endpoints.parameterFile.changedParameters, { from, to });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}
}

export default ParameterFiles;
