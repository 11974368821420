import { getTrendKPIsForAsset } from '../../../commonActions';
import { mapToOffsetReqObj } from '../helpers';
import { saveOffset } from '../sharedActions';

export const saveSelectedKpiOffset = (selectedKpi) => {
	return (dispatch, getState) => {
		const asset = getState().powertrainAssetDetails.selectedAsset;
		const { param } = selectedKpi;
		const reqObj = mapToOffsetReqObj(asset.componentID, param);
		return dispatch(saveOffset(asset, [reqObj]))
			.then(() => dispatch(getTrendKPIsForAsset(asset)));
	};
};
