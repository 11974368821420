import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';


class NotificationsApi {
    getNotificationSettings(organizationId) {
        const endpoint = formatEndpoint(endpoints.notifications.getNotificationSettings, { organizationId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    saveNotificationSettings(organizationId, notificationSettings) {
        const endpoint = formatEndpoint(endpoints.notifications.saveNotificationSettings, { organizationId });
        return RestManagerWithoutBaseUrl.put(endpoint, notificationSettings);
    }

    getMutedAssetList(organizationIds) {
        const endpoint = formatEndpoint(endpoints.notifications.getMutedAssetList);
        return RestManagerWithoutBaseUrl.post(endpoint, { organizationIds });
    }

    deleteMutedAssets(assetIds) {
        const endpoint = formatEndpoint(endpoints.notifications.deleteMutedAssets);
        return RestManagerWithoutBaseUrl.delete(endpoint, { assetIds });
    }

    getAssetMuteSettings(assetID) {
        const endpoint = formatEndpoint(endpoints.notifications.getAssetMuteSettings, { assetID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    muteAsset(payload) {
        const endpoint = formatEndpoint(endpoints.notifications.updateAssetMuteSettings);
        return RestManagerWithoutBaseUrl.put(endpoint, payload);
    }
}

export default new NotificationsApi();
