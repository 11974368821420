import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus, kpiTypes } from 'helpers/constants';
import { PlotApi } from 'api';


const getTrendKPIListAction = getApiActionCreator(actionTypes.GET_TREND_KPIS);
export const getTrendKPIListForAsset = (asset) => {
    return (dispatch) => {
        dispatch(getTrendKPIListAction(apiCallStatus.LOADING));
        PlotApi.getMeasurementTypes(asset, kpiTypes.TREND)
            .then((data) => {
                dispatch(getTrendKPIListAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getTrendKPIListAction(apiCallStatus.ERROR, null, err));
            });
    };
};
