import { createSelector } from 'reselect';
import { mapTrendKpiList } from '../../../../../commonTrendHelpers';


const getTrendKPIsList = createSelector([
    ({ eventDetailsTrendSignal }) => eventDetailsTrendSignal.trendKpis?.data,
], (
    trendKpis,
) => {
    return mapTrendKpiList(trendKpis);
});

const getTrendKPIsLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trendKpis?.loadingStatus;
};
const getTrendDataLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trend?.loadingStatus;
};

const getSelectedTrendsData = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.selectedTrendsData;
};

export const getModel = createSelector([
    getTrendKPIsLoadingStatus,
    getTrendDataLoadingStatus,
    getTrendKPIsList,
    getSelectedTrendsData
], (
    trendKPIsLoadingStatus,
    trendDataLoadingStatus,
    trendKPIsList,
    selectedTrendsData

) => {
    return {
        trendKPIsLoadingStatus,
        trendDataLoadingStatus,
        trendKPIsList,
        selectedTrendsData
    };
});
