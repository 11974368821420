import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { AssetSearch } from './AssetSearch';


const GeneralFilterViewComponent = (props) => {
    const { t: translate } = useTranslation();
    const {
        organizations: selectedOrganizations,
        countries: selectedCountries,
        sites: selectedSites,
        assetGroup: selectedAssetGroup,
        sitesChecked,
        assetGroupChecked
    } = props.selectedFilters;

    const {
        organizationsLoadingStatus,
        sitesLoadingStatus,
        assetGroupsLoadingStatus,
        contextLoadingStatus
    } = props.loadingData;

    return <Container className='filter-view-container'>
        <AssetSearch
            searchAsset={props.searchAsset}
            onHide={props.onHide}
            assets={props.assets}
        />
        <div className='active-filters-label'>
            {translate('ABB.Powertrain.Frontend.generalFilterAdvancedFilterbl')}
        </div>
        <div className='filter-view-content'>
            <LoadingStatusWrapper
                loadingStatus={[organizationsLoadingStatus, sitesLoadingStatus, assetGroupsLoadingStatus, contextLoadingStatus]}
                transparent
                useCustomAnchor>
                <Row className='available-filters-label'>
                    {translate('ABB.Powertrain.Frontend.generalFiltersOrganizationsLabel')}
                </Row>
                {map(selectedOrganizations, (org) =>
                    <Row key={org.organizationID} className='filter-text ms-3'>
                        {org.guid ? `${org.name} (${org.guid})` : `${org.name} (${org.organizationID})`}
                    </Row>)
                }
                <Row className='available-filters-label'>
                    {translate('ABB.Powertrain.Frontend.generalFiltersCountriesLabel')}
                </Row>
                {
                    sitesChecked ? map(selectedCountries, (country) =>
                        <Row key={country.countryCode} className='filter-text ms-3'>
                            {country.countryDisplayName}
                        </Row>)
                        : null
                }
                <Row className='available-filters-label'>
                    {translate('ABB.Powertrain.Frontend.generalFiltersSitesLabel')}
                </Row>
                {
                    sitesChecked ? map(selectedSites, (site) =>
                        <Row key={`${site.servIsSiteId}${site.siteID}`} className='filter-text ms-3 g-0'>
                            <Col className='view-icon-label'>{site.siteName}</Col>
                        </Row>)
                        : null
                }
                <Row className='available-filters-label'>
                    {translate('ABB.Powertrain.Frontend.generalFiltersAssetGroupLabel')}
                </Row>
                {
                    selectedAssetGroup && assetGroupChecked ? <Row className='filter-text ms-3 g-0'>
                        <Col className='view-icon-label'>{selectedAssetGroup.name}</Col>
                    </Row>
                        : null
                }
            </LoadingStatusWrapper>
        </div>
    </Container>;
};

GeneralFilterViewComponent.propTypes = {
    loadingData: PropTypes.shape({
        organizationsLoadingStatus: PropTypes.string,
        sitesLoadingStatus: PropTypes.string,
        assetGroupsLoadingStatus: PropTypes.string,
        contextLoadingStatus: PropTypes.string
    }),
    selectedFilters: PropTypes.shape({
        organizations: PropTypes.array,
        countries: PropTypes.array,
        sites: PropTypes.array,
        assetGroup: PropTypes.object,
        sitesChecked: PropTypes.bool,
        assetGroupChecked: PropTypes.bool
    }),
    searchAsset: PropTypes.func,
    onHide: PropTypes.func,
    assets: PropTypes.array
};

export default GeneralFilterViewComponent;
