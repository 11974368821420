import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { getFlatAvailableKPIList } from '../../../commonTrendHelpers';

export const getOpTrendUniqueYScaling = (selectedTrends, availableTrendKPIs) => {
    let uniqueYScalingAvailable = false;
    const trendKPIList = getFlatAvailableKPIList(availableTrendKPIs);
    if (selectedTrends.length > 1) {
        const { kpiIdentifier } = selectedTrends[0];
        const unit = trendKPIList[kpiIdentifier]?.measurementTypeUnit || '';

        forEach(selectedTrends, (trendData) => {
            uniqueYScalingAvailable = trendKPIList[trendData.kpiIdentifier]?.measurementTypeUnit === unit;
            return uniqueYScalingAvailable;
        });
    }

    return uniqueYScalingAvailable;
};

export const createEmptyOpTrendDataObject = (measurements, componentObj) => {
    const measurementArray = isArray(measurements) ? measurements : [measurements];

    const trendArrays = map(measurementArray, (measurement) => {
        return {
            componentID: componentObj.componentID,
            config: { ...measurement },
            dataSourceType: componentObj.componentType.dataSourceType,
            values: [],
        };
    });

    return trendArrays;
};
