import { actionTypes } from './constants';
import { filterTabs, gatewayTypes } from 'helpers/constants';

const initialState = {
    searchboxFilter: null,
    gatewayTypeFilter: gatewayTypes.ALL,
    kpiList: {
        loadingStatus: null,
        kpis: [],
    },
    assetKPILists: [],
    selectedKpi: null,
    asset: null,
    shouldScrollToSelection: true
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_STORE:
            return {
                ...initialState
            };
        case actionTypes.RESET_CHART:
            return {
                ...state,
                selectedKpi: null
            };
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                gatewayTypeFilter: action.activeTab === filterTabs.POWERTRAINS
                    ? gatewayTypes.ALL
                    : state.gatewayTypeFilter
            };
        }

        case actionTypes.CLEAR_KPI_LIST: {
            return {
                ...state,
                kpiList: { ...initialState.kpiList }
            };
        }

        case actionTypes.SET_SEARCHBOX_FILTER: {
            return {
                ...state,
                searchboxFilter: action.searchboxFilter
            };
        }
        case actionTypes.SET_GATEWAY_TYPE_FILTER: {
            return {
                ...state,
                gatewayTypeFilter: action.gatewayTypeFilter
            };
        }
        case actionTypes.GET_KPIS_FOR_KPI_TYPE: {
            return {
                ...state,
                kpiList: {
                    loadingStatus: action.loadingStatus,
                    kpis: action.data
                }
            };
        }
        case actionTypes.SET_SELECTED_KPI: {
            return {
                ...state,
                selectedKpi: {
                    kpi: action.selectedKpi,
                    asset: action.asset
                }
            };
        }
        case actionTypes.SET_SHOULD_SCROLL_TO_SELECTION: {
            return {
                ...state,
                shouldScrollToSelection: action.shouldScrollToSelection
            };
        }
        default:
            return state;
    }
};
