export const SET_SELECTED_FILES = 'hierarchy/heading/bulkUploadFileSection/SET_SELECTED_FILES';
export const REMOVE_SELECTED_FILE = 'hierarchy/heading/bulkUploadFileSection/REMOVE_SELECTED_FILE';
export const CLEAR_FILE_SECTION = 'hierarchy/heading/bulkUploadFileSection/CLEAR_FILE_SECTION';

export const GET_BULK_UPLOAD_STATUS = 'hierarchy/heading/bulkUploadFileSection/GET_BULK_UPLOAD_STATUS';
export const SET_DATE_INTERVAL = 'hierarchy/heading/bulkUploadFileSection/SET_DATE_INTERVAL';
export const PROCESS_BULK_UPLOAD = 'bulkUpload/PROCESS_BULK_UPLOAD';

export const SET_UPLOAD_LOADING = 'bulkUpload/SET_UPLOAD_LOADING';
export const SET_FILE_UPLOAD_STATUS = 'bulkUpload/SET_FILE_UPLOAD_STATUS';
export const SET_FILE_BLOB_NAME = 'bulkUpload/SET_FILE_BLOB_NAME';
export const SET_PROCESS_ALLOWED = 'bulkUpload/SET_PROCESS_ALLOWED';
export const CLEAR_FILE_BOX = 'bulkUpload/CLEAR_FILE_BOX';


export const fileUploadStatus = {
	NEW: 0,
	LOADING: 1,
	SUCCESS: 2,
	FAILED: 3,
};
