import React from 'react';
import { connect } from 'react-redux';
import { getModel } from './selectors';

import TrendComponent from './TrendComponent';


const TrendContainer = (props) => {
    return <TrendComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const TrendContainerConn = connect(
    mapStateToProps,
    null
)(TrendContainer);


export default TrendContainerConn;
