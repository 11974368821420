import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { GeneralSelect, GeneralInput, LoadingStatusWrapper } from 'sharedComponents';
import { isPowertrainNameValid, powertrainNameShouldChange } from './helpers';
import { getSiteById } from '../helpers';
import { wizardSteps, wizardStepStates } from './constants';
import './style.scss';


const PowertrainWizardStep1 = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const {
        powertrainName,
        selectedOrganization,
        sites,
        selectedSite,
        isEditMode,
        sitesLoadingStatus
    } = model;
    const {
        setWizardStepState,
        setPowertrainNameAction,
        setSelectedSiteAction
    } = actions;

    useEffect(() => {
        if (isEditMode) {
            if (powertrainName !== '' && isPowertrainNameValid(powertrainName) && selectedSite !== null) {
                setWizardStepState(wizardSteps.STEP1, wizardStepStates.COMPLETED);
            } else {
                setWizardStepState(wizardSteps.STEP1, wizardStepStates.ACTIVE);
            }
        } else {
            if (powertrainName !== '' && isPowertrainNameValid(powertrainName) && selectedOrganization !== null && selectedSite !== null) {
                setWizardStepState(wizardSteps.STEP1, wizardStepStates.COMPLETED);
            } else {
                setWizardStepState(wizardSteps.STEP1, wizardStepStates.ACTIVE);
            }
        }
    }, [powertrainName, selectedOrganization, selectedSite]);

    const renderPowertrainNameControl = () => {
        return <>
            <Row id='powertrain-name-label'>
                <Col className='control-label'>
                    {translate('ABB.Powertrain.Frontend.powertrainWizardPowertrainNameLabel')}
                </Col>
            </Row>
            <Row className='control-item'>
                <Col>
                    <GeneralInput
                        className='powertrain-name'
                        id='powertrain-name'
                        placeholder={translate('ABB.Powertrain.Frontend.powertrainWizardPowertrainNameInputPlaceholder')}
                        onChange={(value) => {
                            if (powertrainNameShouldChange(value)) {
                                setPowertrainNameAction(value);
                            }
                        }}
                        value={powertrainName}
                        validationFunction={isPowertrainNameValid}
                        validationMessage={translate('ABB.Powertrain.Frontend.characterLimitReached')}
                    />
                </Col>
            </Row></>;
    };

    const renderSiteSelectorControl = () => {
        return <>
            <Row id='powertrain-site-label'>
                <Col className='control-label'>
                    {translate('ABB.Powertrain.Frontend.powertrainWizardSiteLabel')}
                </Col>
            </Row>
            <Row className='control-item'>
                <Col>
                    {
                        // this select uses the sites from overview
                    }
                    <GeneralSelect
                        onChange={(site) => {
                            setSelectedSiteAction(site);
                        }}
                        getOptionLabel={option => option.siteName}
                        getOptionValue={option => option.siteId}
                        menuShouldScrollIntoView={false}
                        options={sites}
                        value={selectedSite && getSiteById(sites, selectedSite.siteId)}
                        className='site-select'
                        placeholder={translate('ABB.Powertrain.Frontend.powertrainWizardSiteSelectPlaceholder')}
                        isDisabled={isEditMode || powertrainName === '' || selectedOrganization === null}
                    />
                </Col>
            </Row>
        </>;
    };

    return <div className='step1'>
        <Row >
            <Col className='powertrain-wizard-user-guide-text'>
                {translate('ABB.Powertrain.Frontend.powertrainWizardWelcomeText')}
            </Col>
        </Row>
        <Row className='powertrain-wizard-user-guide-row'>
            <Col className='powertrain-wizard-user-guide-text'>
                {
                    isEditMode ?
                        translate('ABB.Powertrain.Frontend.powertrainWizardEditText1') :
                        translate('ABB.Powertrain.Frontend.powertrainWizardAddText1')
                }
            </Col>
        </Row>
        <Row className='powertrain-wizard-user-guide-row'>
            <Col className='powertrain-wizard-user-guide-text'>
                {
                    isEditMode ?
                        translate('ABB.Powertrain.Frontend.powertrainWizardEditText2') :
                        translate('ABB.Powertrain.Frontend.powertrainWizardAddText2')
                }
            </Col>
        </Row>
        <LoadingStatusWrapper loadingStatus={sitesLoadingStatus}>
            {renderPowertrainNameControl()}
            {renderSiteSelectorControl()}
        </LoadingStatusWrapper>
    </div >;
};

PowertrainWizardStep1.propTypes = {
    model: PropTypes.shape({
        organizations: PropTypes.array,
        sites: PropTypes.array,
        powertrainName: PropTypes.string,
        selectedOrganization: PropTypes.shape({
            organizationID: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }),
        selectedSiteId: PropTypes.number,
        isEditMode: PropTypes.bool,
    }),
    actions: PropTypes.shape({
        setWizardStepState: PropTypes.func,
        setPowertrainNameAction: PropTypes.func,
        setSelectedSiteAction: PropTypes.func,
    })
};

export default PowertrainWizardStep1;
