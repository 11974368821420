import { actionTypes, capabilityForReportTypeId, reportTypesForFiltering } from './constants';
import { mapReports, updateReportDownloadProgress } from './helpers';

const initialState = {
    list: {
        data: [],
        error: null,
        loadingStatus: null
    },
    isFilterVisible: true,
    filters: {
        searchboxFilter: '',
        dateFilter: { from: undefined, to: undefined },
        reportingPeriodFilter: { from: undefined, to: undefined },
        reportTypeFilter: reportTypesForFiltering[0],
        reportStatusFilter: { reportStateId: undefined, reportStatusName: undefined },
    },
    reportGenerator: {
        isModalOpen: false,
        capabilities:  //Creates default data object for each supported capabilityType. Needed for consistent length loading state in redux
            Object.keys(capabilityForReportTypeId).reduce((acc, capabilityForReportTypeId) => (
                {
                    ...acc,
                    [capabilityForReportTypeId]: { data: [], loadingStatus: null }
                }), {}),
        selectedReportType: null,
        loadingStatus: null,
    },
    reportUpload: {
        isModalOpen: false,
        selectedReportType: null,
        reportingPeriod: { from: undefined, to: undefined },
        loadingStatus: null,
    },
    publishReportLoadingStatus: null,
    rejectReportLoadingStatus: null,
    reportCapabilities: {
        data: [],
        error: null,
        loadingStatus: null
    },
    sensorOfAssets: {
        data: [],
        error: null,
        loadingStatus: null
    },
    deleteReportLoadingStatus: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REPORTS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: mapReports(action.data, state.reportCapabilities.data),
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.SET_FILTERS: {
            return {
                ...state,
                filters: { ...state.filters, ...action.filters }
            };
        }
        case actionTypes.OPEN_GENERATOR_MODAL: {
            return {
                ...state,
                reportGenerator: { ...state.reportGenerator, isModalOpen: true }
            };
        }
        case actionTypes.CLOSE_GENERATOR_MODAL: {
            return {
                ...state,
                reportGenerator: { ...state.reportGenerator, isModalOpen: false }
            };
        }
        case actionTypes.GET_CAPABILITIES: {
            return {
                ...state,
                reportGenerator: {
                    ...state.reportGenerator,
                    capabilities: {
                        ...state.reportGenerator.capabilities,
                        ...action.data.reportTypeId && { [action.data.reportTypeId]: { data: action.data.capabilities, loadingStatus: action.loadingStatus } }
                    }
                }
            };
        }
        case actionTypes.OPEN_UPLOAD_MODAL: {
            return {
                ...state,
                reportUpload: { ...state.reportUpload, isModalOpen: true }
            };
        }
        case actionTypes.CLOSE_UPLOAD_MODAL: {
            return {
                ...state,
                reportUpload: { ...state.reportUpload, isModalOpen: false }
            };
        }
        case actionTypes.SET_REPORT_GENERATION_TYPE: {
            return {
                ...state,
                reportGenerator: {
                    ...state.reportGenerator,
                    selectedReportType: action.reportType
                }
            };
        }
        case actionTypes.SET_REPORT_UPLOAD_TYPE: {
            return {
                ...state,
                reportUpload: {
                    ...state.reportUpload,
                    selectedReportType: action.reportType
                }
            };
        }
        case actionTypes.UPLOAD_REPORT: {
            return {
                ...state,
                reportUpload: {
                    ...state.reportUpload,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.GENERATE_REPORT:
        case actionTypes.GENERATE_FLEET_REPORT: {
            return {
                ...state,
                reportGenerator: {
                    ...state.reportGenerator,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.TOGGLE_FILTER_VISIBILITY:
        case actionTypes.SET_FILTER_VISIBILITY_FROM_LS: {
            return {
                ...state,
                isFilterVisible: action.isFilterVisible
            };
        }
        case actionTypes.DOWNLOAD_REPORT: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: [...updateReportDownloadProgress(state.list.data, action.data, action.loadingStatus)]
                }
            };
        }
        case actionTypes.PUBLISH_REPORT: {
            return {
                ...state,
                publishReportLoadingStatus: action.loadingStatus
            };
        }
        case actionTypes.REJECT_REPORT: {
            return {
                ...state,
                rejectReportLoadingStatus: action.loadingStatus
            };
        }
        case actionTypes.GET_REPORT_CAPABILITIES: {
            return {
                ...state,
                reportCapabilities: {
                    ...state.reportCapabilities,
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.GET_SENSOR_OF_ASSETS: {
            return {
                ...state,
                sensorOfAssets: {
                    ...state.sensorOfAssets,
                    data: action.data,
                    error: action.error,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.DELETE_REPORT: {
            return {
                ...state,
                deleteReportLoadingStatus: action.loadingStatus
            };
        }
        default:
            return state;
    }
};
