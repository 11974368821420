import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';
import NotificationSettingsComponent from './NotificationSettingsComponent';
import { bindActionCreators } from 'redux';


const NotificationSettingsContainer = (props) => {
    useEffect(() => {
        if (props.model.organization?.organizationID) {
            props.actions.getNotificationSettings(props.model.organization.organizationID);
        }
    }, [props.model.organization]);

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <NotificationSettingsComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

NotificationSettingsContainer.propTypes = {
    actions: PropTypes.shape({
        getNotificationSettings: PropTypes.func,
        clearStore: PropTypes.func
    }),
    model: PropTypes.shape({
        organization: PropTypes.object
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsContainer);
