import { createSelector } from 'reselect';

const getUserManager = ({ portal }) => {
    return portal.userManager;
};

const getUser = ({ overview }) => {
    return overview.user.data;
};

const getUserCapabilitiesLoadingStatus = ({ overview }) => {
    return overview?.userCapabilities?.loadingStatus;
};

export const getModel = createSelector([
    getUserManager,
    getUser,
    getUserCapabilitiesLoadingStatus
], (
    userManager,
    user,
    userCapabilitiesLoadingStatus
) => {
    return {
        userManager,
        user,
        userCapabilitiesLoadingStatus
    };
});
