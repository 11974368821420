import { filter, map, reduce, sortBy, find } from 'lodash';
import { allAssetTypes, gatewayTypes, statuses } from './constants';


export const isSubstringOf = (haystack, needle) => {
    return haystack && needle && haystack.toLowerCase().includes(needle);
};

const getSelectedConditionIndexStatus = (itemWithStatuses, conditionIndexFilter) => {
    return conditionIndexFilter === statuses.ALL
        ? itemWithStatuses.overallStatus
        : itemWithStatuses.statuses[conditionIndexFilter]?.status;
};

export const passesFilter = (filterAvailable, filterCondition) => {
    return filterAvailable
        ? filterCondition
        : true;
};

export const matchesWithTheSearchFilter = (asset, searchTerm) => {

    const { serialNumber, componentID, componentName, systemName } = asset;
    const propertiesToFilter = [serialNumber, componentID, componentName, systemName];

    return systemName
        ? isSubstringOf(systemName, searchTerm)
        : reduce(
            propertiesToFilter,
            (acc, nextProperty) => acc || isSubstringOf(nextProperty, searchTerm),
            false);
};


export const filterAssets = (assets, { conditionStatusFilter, searchboxFilter, conditionIndexFilter, gatewayTypeFilter, assetTypeFilter }) => {
    const isConditionStatusFilterSet = Object.values(conditionStatusFilter).reduce((acc, next) => acc || next);

    return filter(assets, (asset) => {
        return passesFilter(isConditionStatusFilterSet, conditionStatusFilter[getSelectedConditionIndexStatus(asset, conditionIndexFilter || statuses.ALL)])
            && passesFilter(searchboxFilter && searchboxFilter.length, matchesWithTheSearchFilter(asset, searchboxFilter?.toLowerCase()))
            && passesFilter(gatewayTypeFilter, gatewayTypeFilter === gatewayTypes.ALL || gatewayTypeFilter === asset.gatewayType)
            && passesFilter(assetTypeFilter, assetTypeFilter === allAssetTypes || assetTypeFilter === asset.componentType.typeName);
    });
};

export const filterPowertrains = (powertrains, filters) => {
    const isConditionStatusFilterSet = Object.values(filters.conditionStatusFilter).reduce((acc, next) => acc || next);

    const powertrainsWithFormattedAssets = map(powertrains, powertrain => {
        return {
            ...powertrain,
            components: sortBy(filterAssets(powertrain.components, filters), (asset) => asset.componentName.toLowerCase())
        };
    });

    return filter(powertrainsWithFormattedAssets, (powertrain) => {
        return powertrain.components.length > 0 ||
            passesFilter(
                isConditionStatusFilterSet,
                filters.conditionStatusFilter[getSelectedConditionIndexStatus(powertrain, filters.conditionIndexFilter)]
            )
            && passesFilter(
                filters.searchboxFilter && filters.searchboxFilter.length,
                matchesWithTheSearchFilter(powertrain, filters.searchboxFilter?.toLowerCase()));

    });
};

const assetTypeIncluded = (powertrain, assetTypeFilter) => {
    const assetTypeMatching = (asset) => asset.componentType.typeName === assetTypeFilter;
    return powertrain.components.some(assetTypeMatching);
};

export const filterPowertrainsOverview = (powertrains, { conditionStatusFilter, searchboxFilter, assetTypeFilter }) => {
    const isConditionStatusFilterSet = Object.values(conditionStatusFilter).reduce((acc, next) => acc || next);
    const isAssetFilterSet = assetTypeFilter !== allAssetTypes;


    return filter(powertrains, powertrain => {
        return (
            passesFilter(
                isConditionStatusFilterSet,
                conditionStatusFilter[powertrain.overallStatus]
            ) &&
            passesFilter(
                searchboxFilter && searchboxFilter.length,
                matchesWithTheSearchFilter(powertrain, searchboxFilter?.toLowerCase())
            ) &&
            passesFilter(
                isAssetFilterSet,
                assetTypeIncluded(powertrain, assetTypeFilter)
            )
        );
    });
};

export const isAssetInCurrentContext = (filterValue, assets) => {
    return !!find(assets, (asset) => {
        return asset.componentID === filterValue ||
            asset.serialNumber && asset.serialNumber?.toLowerCase() === filterValue?.toLowerCase();
    });
};
