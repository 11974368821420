import forEach from 'lodash/forEach';

import { allAssetTypes } from 'helpers/constants';
import { overallStatus } from '../constants';
import { convertDataToChartData } from '../helpers';

export const getChartData = (assets, typeName) => {
    const filteredAssets = typeName !== allAssetTypes ?
        assets.filter((asset) => asset.componentType.typeName === typeName)
        : assets;
    const totalConditionStates = {
        [overallStatus.POOR]: {
            total: 0
        },
        [overallStatus.TOLERABLE]: {
            total: 0
        },
        [overallStatus.GOOD]: {
            total: 0
        },
        [overallStatus.UNKNOWN]: {
            total: 0
        }
    };

    forEach(filteredAssets, (asset) => {
        forEach(
            [overallStatus.POOR,
            overallStatus.TOLERABLE,
            overallStatus.GOOD,
            overallStatus.UNKNOWN],
            (stateName) => {
                if (asset.overallStatus === stateName) {
                    ++totalConditionStates[stateName].total;
                }
            });
    });

    return convertDataToChartData(totalConditionStates, typeName);
};
