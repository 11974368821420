import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';
import MutedAssetsComponent from './MutedAssetsComponent';
import { bindActionCreators } from 'redux';


const MutedAssetsContainer = (props) => {
    useEffect(() => {
        if (props.organization?.organizationID) {
            props.actions.getMutedAssetList(props.organization?.organizationID);
        }
    }, [props.organization]);

    return <MutedAssetsComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

MutedAssetsContainer.propTypes = {
    actions: PropTypes.shape({
        getMutedAssetList: PropTypes.func
    }),
    organization: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(MutedAssetsContainer);
