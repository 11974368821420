import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconProfile = ({ width = '24', height = '24', viewBox = '0 0 24 24', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15.64 13C18.05 13 20 15.42 20 18.4V23H4V18.4C4 15.42 6 13 8.36 13H15.64ZM12.06 2C12.6509 2 13.2361
        2.1164 13.7821 2.34254C14.328 2.56869 14.8241 2.90016 15.242 3.31802C15.6598 3.73588 15.9913 4.23196 16.2175 4.77792C16.4436
        5.32389 16.56 5.90905 16.56 6.5C16.56 7.09095 16.4436 7.67611 16.2175 8.22208C15.9913 8.76804 15.6598 9.26412 15.242
        9.68198C14.8241 10.0998 14.328 10.4313 13.7821 10.6575C13.2361 10.8836 12.6509 11 12.06 11C11.4683 11.0026 10.882 10.8881
        10.3348 10.6629C9.78764 10.4376 9.29051 10.1063 8.87212 9.68788C8.45373 9.26949 8.12236 8.77236 7.89715 8.2252C7.67194
        7.67804 7.55735 7.09169 7.56 6.5C7.56 5.30653 8.03411 4.16193 8.87802 3.31802C9.72193 2.47411 10.8665 2 12.06 2Z' fill={color} />
    </svg>;
};

IconProfile.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconProfile;
