import { createSelector } from 'reselect';


const getAssetGroupOverviewList = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.data;
};

const getAssetGroupOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.loadingStatus;
};

const getPageNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.pageNumber;
};

const getHasMore = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.hasMore;
};

export const getModel = createSelector(
    [
        getAssetGroupOverviewList,
        getAssetGroupOverviewLoadingStatus,
        getPageNumber,
        getHasMore
    ],
    (
        assetGroupOverviewList,
        assetGroupOverviewLoadingStatus,
        pageNumber,
        hasMore
    ) => {
        return {
            assetGroupOverviewList,
            assetGroupOverviewLoadingStatus,
            pageNumber,
            hasMore
        };
    }
);
