import React from 'react';
import PropTypes from 'prop-types';
import IconConfig from 'svgIcons/MotionPortalIcons/IconConfig';
import { useHistory } from 'react-router';
import { routes } from 'routes';

const UserLimitsButton = (props) => {

    const history = useHistory();

    const { kpi } = props;
    const { isPlottableToTrendChart } = kpi;

    const openUserLimitsConfig = (e) => {
        e.stopPropagation();
        history.push(`${routes.LimitsConfiguration}${history.location.search}`);
    };

    return <>
        {
            isPlottableToTrendChart ?
                <span onClick={(e) => openUserLimitsConfig(e)}>
                    <IconConfig />
                </span> : null
        }
    </>;
};

UserLimitsButton.propTypes = {
    kpi: PropTypes.object
};

export default UserLimitsButton;
