import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { mapFromFormattedSelectedOption, mapToFormattedOptions, mapToFormattedSelectedOptions } from './helpers';
import Option from './Option';

import './style.scss';
import { Virtuoso } from 'react-virtuoso';

const MenuList = ({ children }) => {
    return Array.isArray(children) ?
        <Virtuoso
            style={{ height: `${Math.max(Math.min(32 * children.length, 224), 32)}px` }}
            totalCount={children.length}
            itemContent={(index) => <>{children[index]}</>
            }
        />
        :
        <>{children}</>;
};

const OrganizationSelector = (props) => {

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (props?.options?.length) {
            const opt = mapToFormattedOptions(props.options);
            setOptions(opt);
        }
    }, [props.options]);

    useEffect(() => {
        setSelectedOptions(mapToFormattedSelectedOptions(props.selectedOrganization));
    }, [props.selectedOrganization]);

    const onChange = (selection) => {
        props.onChange(mapFromFormattedSelectedOption(selection));
    };

    const {
        ...otherProps
    } = props;

    return <GeneralSelect
        {...otherProps}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        onChange={onChange}
        options={options}
        value={selectedOptions}
        className='organization-selector-container'
        blurInputOnSelect={true}
        isDisabled={props.isLoading}
        closeMenuOnSelect={true}
        components={{
            Option,
            MenuList
        }}
        hideSelectedOptions={false}
    />;
};

OrganizationSelector.propTypes = {
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    selectedOrganization: PropTypes.object
};


export default OrganizationSelector;
