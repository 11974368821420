import { actionTypes } from './constants';
import { b64toBlob, downloadFile } from 'helpers/download-file-util';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { getSelectedAsset } from '../selectors';

import { AssetDetailsApi } from 'api';

const downloadFFTRawDataAction = getApiActionCreator(actionTypes.DOWNLOAD_RAW_DATA);

export const setSelectedDates = (from, to) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DATE_SELECTION, dateSelection: { from, to } });
    };
};

export const downloadRawData = () => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);
        const assetID = selectedAsset.componentID;
        const dataSource = selectedAsset.componentType.dataSourceType;
        const assetName = selectedAsset.componentName;

        dispatch(downloadFFTRawDataAction(apiCallStatus.LOADING));
        AssetDetailsApi.downloadRawData(assetID, assetName, dataSource)
            .then((rawData) => {
                downloadFile(rawData.fileName, b64toBlob(rawData.fileContent));
            })
            .catch((err) => {
                dispatch(downloadFFTRawDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ACTIVE_TAB, activeTab });
    };
};

export const setActiveTrendTab = (multiChart) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ACTIVE_TREND_TAB, multiChart });
    };
};
