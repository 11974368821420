import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RenameAsset from './RenameAssetComponent';
import * as updateAssetAction from './actions';
import { getModel } from './selectors';


const RenameAssetContainer = (props) => {
	return <RenameAsset {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(updateAssetAction, dispatch)
	};
};

const RenameContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(RenameAssetContainer);

export default RenameContainerConn;
