export const adjustEndDate = (endDate) => {
    const endDateObject = new Date(endDate);
    const today = new Date();
    if (endDateObject.getFullYear() === today.getFullYear() &&
        endDateObject.getMonth() === today.getMonth() &&
        endDateObject.getDate() === today.getDate()) {
        return today.toISOString();
    } else {
        return endDate;
    }
};
