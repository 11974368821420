export const actionTypes = {
    GET_ASSETS: 'overview/GET_ASSETS',
    GET_POWERTRAINS: 'overview/GET_POWERTRAINS',
    GET_ORGANIZATIONS: 'generalFilter/GET_ORGANIZATIONS',
    GET_SITES: 'generalFilter/GET_SITES',
    GET_ASSET_GROUPS: 'generalFilter/GET_ASSET_GROUPS',
    SET_SELECTED_FILTERS: 'generalFilter/SET_SELECTED_FILTERS',
    SET_RELOAD_CONTEXT_LOADING_STATUS: 'overview/SET_RELOAD_CONTEXT_LOADING_STATUS',
    ASSET_SEARCH_REQUEST: 'generalFilter/ASSET_SEARCH_REQUEST',
    SET_CIAM_SYNC_FLAG: 'generalFilter/SET_CIAM_SYNC_FLAG'
};

export const pageSizeOrg = 30000;
export const pageSizeSitesAndAssetGroups = 10000;

