import { createSelector } from 'reselect';
import {
    getLoadingStatuses
} from 'helpers/selectors';


const getAssetNumber = ({ conditionIndices }) => {
    return conditionIndices?.assets?.count;
};

const getAssetGroupNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.count;
};

const getAssetGroupAssetNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.assetCount;
};

const getPowertrainNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.count;
};

const getPowertrainAssetNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.assetCount;
};

const getAssetOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assets?.loadingStatus;
};

const getAssetGroupOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.loadingStatus;
};

const getPowertrainOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.loadingStatus;
};

const getConditionFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.conditionFilter;
};

const getAssetTypeFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.assetTypeFilter;
};

const getActiveTab = ({ conditionIndices }) => {
    return conditionIndices?.selectedTab;
};

const getSearchBoxFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.searchBox;
};

const getSelectedFilters = ({ generalFilter }) => {
    return generalFilter?.selectedFilters;
};

export const getModel = createSelector(
    [
        getLoadingStatuses,
        getAssetNumber,
        getAssetGroupNumber,
        getAssetGroupAssetNumber,
        getPowertrainNumber,
        getPowertrainAssetNumber,
        getAssetOverviewLoadingStatus,
        getAssetGroupOverviewLoadingStatus,
        getPowertrainOverviewLoadingStatus,
        getConditionFilter,
        getAssetTypeFilter,
        getActiveTab,
        getSearchBoxFilter,
        getSelectedFilters
    ],
    (
        generalFilterLoadingStatuses,
        assetNumber,
        assetGroupNumber,
        assetGroupAssetNumber,
        powertrainNumber,
        powertrainAssetNumber,
        assetOverviewLoadingStatus,
        assetGroupOverviewLoadingStatus,
        powertrainOverviewLoadingStatus,
        conditionFilter,
        assetTypeFilter,
        activeTab,
        searchBoxFilter,
        selectedFilters
    ) => {
        return {
            generalFilterLoadingStatuses,
            assetNumber,
            assetGroupNumber,
            assetGroupAssetNumber,
            powertrainNumber,
            powertrainAssetNumber,
            assetOverviewLoadingStatus,
            assetGroupOverviewLoadingStatus,
            powertrainOverviewLoadingStatus,
            conditionFilter,
            assetTypeFilter,
            activeTab,
            searchBoxFilter,
            selectedFilters
        };
    }
);
