import { forEach } from 'lodash';
import { customerTypes, internalDomains } from './constants';

const setCustomTag = (key, value) => {
    if (window.clarity) {
        window.clarity('set', key, value);
    }
};

const getDomainRegex = (domain) => {
    return new RegExp(`.*[\\.@]${domain}$`);
};

const getCustomerType = (emailAddress) => {
    emailAddress = emailAddress.toLowerCase();
    let customerType = customerTypes.Unknown;
    const emailValidityRegexp = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
    if (emailValidityRegexp.test(emailAddress)) {
        customerType = customerTypes.External;
        forEach(internalDomains, internalDomain => {
            if (getDomainRegex(internalDomain).test(emailAddress)) {
                customerType = customerTypes.Internal;
                return false;
            }
        });
    }
    return customerType;
};

export const setInitialClarityTags = (user) => {
    let domain;
    try {
        domain = user?.email?.split('@')[1];
    } finally {
        domain && setCustomTag('CustomerDomain', domain);
        user?.email && setCustomTag('CustomerType', getCustomerType(user?.email));
    }
};
