/* eslint-disable import/no-unused-modules */
/* eslint-disable import/no-named-as-default-member */

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { defaultLanguage, supportedLanguageCodes } from './helpers/constants';
import { isDev } from './helpers/environmentHelper';
import { loadResources } from '../Localization';
import logger from 'helpers/logger';


loadResources(defaultLanguage).then((res) => {
    const resources = {};
    resources[defaultLanguage] = { translation: res };

    i18n
        .use(initReactI18next)
        .init({
            resources,
            lng: defaultLanguage,
            fallbackLng: defaultLanguage,
            supportedLngs: supportedLanguageCodes,
            interpolation: {
                escapeValue: false
            },
            load: 'currentOnly',
            lowerCaseLng: true,
            parseMissingKeyHandler: (key) => {
                if (isDev()) {
                    logger.warn('Missing translation for key:', key);
                }
                return null;
            }
        });
});

export default i18n;
