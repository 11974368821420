import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconAddFilled = ({ width = '48px', height = '48px', viewBox = '0 0 48 48' }) => {
	return <svg id='Add' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
		<g>
			<path d='M37.41,11A19.42,19.42,0,1,1,10,10.63l.05-.05A19.42,19.42,0,0,1,37.41,11Z' fill={colors.white} />
			<g>
				<polyline points='23.41 36.71 23.57 24.42 23.74 12.14' fill='none' stroke={colors.dodgerBlue} strokeLinejoin='bevel' strokeWidth='2' />
				<polyline points='11.29 24.26 23.57 24.42 35.86 24.59' fill='none' stroke={colors.dodgerBlue} strokeLinejoin='bevel' strokeWidth='2' />
			</g>
		</g>
	</svg>;
};

IconAddFilled.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string
};

export default IconAddFilled;
