import React from 'react';
import { connect } from 'react-redux';

import { getModel } from './selectors';
import GeneralFilterSideBar from './GeneralFilterSideBar';


const GeneralFilterSideBarContainer = (props) => {
	return <GeneralFilterSideBar {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};


export default connect(
	mapStateToProps,
	null
)(GeneralFilterSideBarContainer);
