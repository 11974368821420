import { actionTypes } from '../constants';


export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_POWERTRAIN_OVERVIEW });
    };
};

export const getPowertrainConditionIndicesRequest = (request) => ({
    type: actionTypes.LOAD_POWERTRAIN_CONDITION_INDICES_REQUEST,
    request
});
