import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import {
    errorHandlingMiddleware,
    saveToLocalStorageMiddleware,
    rootSaga
} from './store';


const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory({ basename: process.env.SPABasePath }),
});

export { createReduxHistory };

const buildRootReducer = (allReducers) => combineReducers({
    ...allReducers, router: routerReducer
});


const configureStore = (reducers, preloadedState) => {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    // If devTools is installed, connect to it
    const devToolsExtension =
        windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;

    // Combine all reducers
    const allReducers = buildRootReducer(reducers);
    const store = createStore(
        allReducers,
        preloadedState,
        compose(
            applyMiddleware(
                thunk,
                sagaMiddleware,
                routerMiddleware,
                errorHandlingMiddleware,
                saveToLocalStorageMiddleware),
            devToolsExtension ? devToolsExtension() : (next) => next
        ));
    sagaMiddleware.run(rootSaga);
    return store;
};

export const generateStore = (reducers, preloadedState) => configureStore(reducers, preloadedState);

