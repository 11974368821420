import PropTypes from 'prop-types';
import React from 'react';
import { Slider } from 'sharedComponents';

const SliderRow = (props) => {
    const { selectedKpi, calculatedMin, calculatedMax, setAlert, setAlarm } = props;
    return <div className='alert-alarm-dialog-row'>
        <Slider
            min={calculatedMin}
            max={calculatedMax}
            step={1 / Math.pow(10, selectedKpi.config.precision)}
            reverse={selectedKpi.config.min > selectedKpi.config.max}
            values={[selectedKpi.config.alert, selectedKpi.config.alarm]}
            onChange={(val) => {
                setAlert(selectedKpi.measurementTypeID, val[0]);
                setAlarm(selectedKpi.measurementTypeID, val[1]);
            }}
        />
    </div>;
};

SliderRow.propTypes = {
    calculatedMax: PropTypes.number,
    calculatedMin: PropTypes.number,
    selectedKpi: PropTypes.shape({
        measurementTypeID: PropTypes.number,
        config: PropTypes.shape({
            alarm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            alert: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            max: PropTypes.number,
            min: PropTypes.number,
            precision: PropTypes.number
        })
    }),
    setAlarm: PropTypes.func,
    setAlert: PropTypes.func
};

export default SliderRow;
