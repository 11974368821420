import { createSelector } from 'reselect';

const getGeneralFilter = ({ overview }) => {
    return overview?.assets?.data;
};
export const getModel = createSelector(
    [
        getGeneralFilter
    ],
    (
        generalFilter
    ) => {
        return {
            generalFilter
        };
    }
);
