import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import { routes } from 'routes';
import { tabIDs, sensorTypeMappings } from './constants';
import { getUTCDateString, formatStartDate, formatEndDate } from 'helpers/dateHelper';
import {
    dataSources,
    DAY_IN_MS,
    chartColors,
    apiCallStatus,
} from 'helpers/constants';
//import React from 'react';
import featureConfigHelper from 'helpers/featureConfigHelper';
//import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';

export const getTabOptions = (translate, isSSAsset, eventLoggerVisualization, advancedAnalyticsIsVisible, operationalParametersIsVisible, operationalParametersIshide, eventLogIsHide, operationalParameterV2IsVisible, limitConfigIsVisible, conditionInsightStatus, hasConditionIndexNewCapability) => {
    const featureConfig = new featureConfigHelper();
    return {
        assetDetails: {
            tabID: tabIDs.assetDetails,
            title: translate('ABB.Powertrain.Frontend.detailedInformationAssetDetailsTab'),
            route: routes.AssetDetails
        },
        operationalParameters: {
            tabID: tabIDs.operationalParameters,
            title: translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTab'),
            route: routes.OperationalParameters,
            disabled: !operationalParametersIsVisible,
            hidden: !operationalParametersIshide
        },
        operationalParametersV2: {
            tabID: tabIDs.operationalParametersv2,
            title: translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTabV2'),
            route: routes.OperationalParametersV2,
            hidden: !operationalParameterV2IsVisible
        },
        eventLog: {
            tabID: tabIDs.eventLog,
            title: translate('ABB.Powertrain.Frontend.detailedInformationEventLogTab'),
            route: routes.EventLog,
            disabled: !eventLoggerVisualization,
            hidden: !eventLogIsHide
        },
        limitsConfiguration: {
            tabID: tabIDs.limitsConfiguration,
            title: translate('ABB.Powertrain.Frontend.detailedInformationLimitsConfigurationTab'),
            route: routes.LimitsConfiguration,
            hidden: !limitConfigIsVisible
        },
        sensorProperties: {
            tabID: tabIDs.sensorProperties,
            title: translate('ABB.Powertrain.Frontend.detailedInformationSensorPropertiesTab'),
            route: routes.SensorProperties,
            hidden: !isSSAsset
        },
        advancedAnalytics: {
            tabID: tabIDs.advancedAnalytics,
            title: translate('ABB.Powertrain.Frontend.advancedAnalyticsTab'),
            route: routes.AdvancedAnalytics,
            hidden: !featureConfig.isCBMFeatureEnabled() ||
                !advancedAnalyticsIsVisible
        },
        conditionInsights: {
            tabID: tabIDs.conditionInsights,
            title: translate('ABB.Powertrain.Frontend.conditionInsightsTab'),
            //Commented Out For Timebeing based on discussion 367953
            //titleIcon: conditionInsightStatus?.conditionStatus ? <IconCondition condition={conditionInsightStatus?.conditionStatus} /> : null,
            route: routes.ConditionInsights,
            hidden: !hasConditionIndexNewCapability
        }
    };
};
// eslint-disable-next-line import/no-unused-modules
export const isSmSeAsset = (asset) => {
    return asset && asset.componentType && asset.componentType.dataSourceType === dataSources.SMARTSENSOR;
};
// eslint-disable-next-line import/no-unused-modules
export const getDefaultDateSelection = () => {
    const fromDate = new Date();
    fromDate.setTime(fromDate.getTime() - 7 * DAY_IN_MS);
    const formatedFromDate = formatStartDate(fromDate);

    const toDate = formatEndDate(new Date());
    toDate.setTime(toDate.getTime());

    return {
        from: getUTCDateString(formatedFromDate),
        to: getUTCDateString(toDate)
    };
};

export const getTabId = (tabOptions, route) => {
    let tabID = '';
    forOwn(tabOptions, tabOption => {
        if (tabOption.route === route) {
            tabID = tabOption.tabID;
        }
    });
    return tabID;
};

// eslint-disable-next-line import/no-unused-modules
export const getInitialMultiTrendCharts = (preselectedKPIs) => {
    return map(preselectedKPIs, (kpi) => {
        return {
            chartName: '',
            chartID: kpi,
            kpiList: [{
                color: chartColors[0],
                values: [],
                kpiIdentifier: kpi
            }],
            zoom: { isZoomed: false, from: null, to: null },
            loadingStatus: apiCallStatus.SUCCESS,
        };
    });
};

// eslint-disable-next-line import/no-unused-modules
export const getSensorTypeValueFromCode = (SensorCode) => {
    return sensorTypeMappings[SensorCode];
};
