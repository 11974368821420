import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconRefresh = ({ width = '16px', height = '16px', viewBox = '0 0 16 16', color = colors.codGray }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M9.00098 6.99991H15V0.999915H13.52V3.69991C12.6407 2.56964 11.4357 1.73638 10.0679 1.31263C8.69997 0.888875 7.235 0.895035 5.87072 1.33028C4.50644 1.76552 3.30856 2.60889 2.4388 3.74651C1.56903 4.88414 1.06927 6.26125 1.00698 7.69191C0.944791 9.12181 1.32241 10.5364 2.08894 11.7451C2.85547 12.9537 3.97408 13.8984 5.29403 14.4517C6.61398 15.0051 8.07182 15.1405 9.47112 14.8398C10.8704 14.5391 12.1439 13.8167 13.12 12.7699L12.12 11.6999C11.3335 12.5731 10.2884 13.1719 9.13734 13.4087C7.98629 13.6456 6.78967 13.5081 5.72235 13.0163C4.65503 12.5245 3.77297 11.7043 3.20508 10.6755C2.63719 9.64661 2.41325 8.46311 2.56598 7.29791C2.71935 6.13363 3.24069 5.04881 4.05396 4.20166C4.86724 3.35451 5.92989 2.78936 7.08695 2.58862C8.24401 2.38789 9.43493 2.56208 10.486 3.08579C11.5371 3.6095 12.3934 4.45533 12.93 5.49991H8.99998V6.99991H9.00098Z' fill={color} />
	</svg>;
};

IconRefresh.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconRefresh;
