import { createSelector } from 'reselect';


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getAssetMuteSettings = ({ muteAsset }) => {
    return muteAsset.assetMuteSettings?.data;
};

const getAssetMuteSettingsLoadingStatus = ({ muteAsset }) => {
    return muteAsset.assetMuteSettings?.loadingStatus;
};

const getUpdateMuteSettingsLoadingStatus = ({ muteAsset }) => {
    return muteAsset.updateAssetMuteSettingsLoadingStatus;
};

export const getModel = createSelector([
    getSelectedAsset,
    getAssetMuteSettings,
    getAssetMuteSettingsLoadingStatus,
    getUpdateMuteSettingsLoadingStatus
],
    (
        selectedAsset,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateMuteSettingsLoadingStatus
    ) => {
        return {
            selectedAsset,
            assetMuteSettings,
            assetMuteSettingsLoadingStatus,
            updateMuteSettingsLoadingStatus
        };
    });
