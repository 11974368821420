import { actionTypes } from '../../PowertrainOverview/constants';
import { UserProfileApi } from 'api';
import localStorageHelper from 'helpers/localStorageHelper';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { mapLanguageMicroservicePayload } from './helpers';


const loadAvailableLanguages = getApiActionCreator(actionTypes.LOAD_AVAILABLE_LANGUAGES);
const updateLanguageAction = getApiActionCreator(actionTypes.UPDATE_SELECTED_LANGUAGE);

export const getAvailableLanguages = () => {
    return (dispatch) => {
        dispatch(loadAvailableLanguages(apiCallStatus.LOADING));

        UserProfileApi.getAvailableLanguages()
            .then((languages) => {
                dispatch(loadAvailableLanguages(apiCallStatus.SUCCESS, languages));
            }, (err) => {
                dispatch(loadAvailableLanguages(apiCallStatus.ERROR, null, err));
            });
    };
};

export const updateSelectedLanguage = (languageCode) => {
    return (dispatch, getState) => {
        const userData = getState().overview.user.data;
        dispatch(updateLanguageAction(apiCallStatus.LOADING));

        Promise.all([
            UserProfileApi.update({ languageCode }),
            UserProfileApi.updateUser(mapLanguageMicroservicePayload(languageCode, userData))
        ]).then(() => {
            dispatch(updateLanguageAction(apiCallStatus.SUCCESS, languageCode));
            localStorageHelper.setItem('user', { ...localStorageHelper.getItem('user'), languageCode });
        }, (err) => {
            dispatch(updateLanguageAction(apiCallStatus.ERROR, null, err));
        });
    };
};
