import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IconFilterButton } from 'svgIcons/MotionPortalIcons';
import { GeneralFilterModal } from './GeneralFilterModal';

import './style.scss';


const GeneralFilterSideBar = ({ model: { isGeneralFilterVisibleOnRoute } }) => {

    const { t: translate } = useTranslation();
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const onFilterbarClick = () => {
        setIsFilterModalOpen(true);
    };

    return <>
        {isGeneralFilterVisibleOnRoute && !isFilterModalOpen &&
            <div id='general-filter-sidebar' role='sidebar-container' onClick={onFilterbarClick} onKeyDown={onFilterbarClick}>
                <div className='filter-text'>{translate('ABB.Powertrain.Frontend.organizationFilter')}</div>
                <div className='icon-collapsed'>
                    <IconFilterButton />
                </div>
            </div>}
        <GeneralFilterModal
            show={isFilterModalOpen}
            onHide={() => setIsFilterModalOpen(false)}
        />
    </>;
};

GeneralFilterSideBar.propTypes = {
    model: PropTypes.shape({
        isGeneralFilterVisibleOnRoute: PropTypes.bool
    })
};

export default GeneralFilterSideBar;
