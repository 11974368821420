import { renderToStaticMarkup } from 'react-dom/server';
import React, { useEffect, useState } from 'react';
import { forEach, transform } from 'lodash';

import colors from 'theme/_colors.scss';
import { translate, getTranslatedKPIName } from 'helpers/translateHelper';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { afterChartReDrawStringData, chartLegendItemClick } from '../../../../../../helpers';
import { TrendTooltip } from 'sharedComponents/ChartComponents/Trend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';


const getChartConfig = removeFn => {
    return {
        title: {
            text: null
        },
        chart: {
            zoomType: 'x',
            height: null,
            spacingTop: 17,
            events: {
                render: function (chartInstance) {
                    afterChartReDrawStringData(chartInstance.target.container.id, removeFn);
                }
            }
        },
        boost: {
            allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S',
            },
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            },
            minPadding: 0,
            maxPadding: 0
        },
        legend: {
            useHTML: true,
            layout: 'horizontal',
            align: 'right',
            itemStyle: {
                fontFamily: 'ABBvoice-Regular',
                fontSize: '14px',
            },
            itemDistance: 25,
            itemMarginTop: 10,
            enabled: true,
            symbolRadius: 0,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            dateTimeLabelFormats: {
                day: '%d.%m.%Y<br/>%H:%M',
                hour: '%d.%m %H:%M',
            },
        },
        plotOptions: {
            series: {
                softThreshold: false,
                turboThreshold: 0,
                animation: false,
                boostThreshold: 0
            }
        },
        lang: {
            noData: 'No data'
        },
        noData: {
            useHTML: true
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG'],
                    verticalAlign: 'bottom',
                    x: 10,
                    y: 0
                }
            }
        }
    };
};

const getMeasurements = (measurements) => {
    const averages = [];
    forEach(measurements, (measurement) => {
        if (measurement.timestamp !== null) {
            const date = new Date(measurement.timestamp).getTime();
            averages.push([date, measurement.value]);

        }
    });

    return averages;
};

const getSeries = (selectedKpisData) => {

    const ret = { yAxis: [], seriesData: [], allSeriesLength: 0 };

    transform(selectedKpisData, (acc, item, index) => {
        const config = item.config ? item.config : {};
        const unit = config && config.unit || '';
        const precision = config && config.precision;
        const name = getTranslatedKPIName(config);
        const trendDataValues = item.values;
        const seriesId = `series_${index}`;
        const yAxisId = `yAxis_${index}`;
        const averages = getMeasurements(trendDataValues);
        acc.yAxis.push({
            labels: {
                format: '{value} ' + unit,
                style: {
                    color: item.color
                }
            },
            id: yAxisId,
            title: {
                text: name,
                enabled: true,
                style: {
                    color: item.color
                }
            },
            opposite: index % 2 !== 0,
            visible: true,
            maxPadding: 0.20,
            endOnTick: false,
        });

        acc.seriesData.push({
            name: `${name}`,
            data: averages,
            tooltip: {
                valueSuffix: ` ${unit}`,
                valueDecimals: precision
            },
            color: item.color,
            marker: {
                enabled: true,
                radius: 2
            },
            lineWidth: 1,
            id: seriesId,
            yAxis: yAxisId,
            events: {
                legendItemClick: chartLegendItemClick
            }
        });
    }, ret);

    const allSeriesLength = ret.seriesData?.length ? ret.seriesData.reduce((count, series) => series.data.length + count, 0) : 0;
    return { ...ret, allSeriesLength };
};

export const chartPlugins = [CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.HIGHCHARTS_MORE];

const addOccuredOnPlotline = (chart, occurredOn) => {
    const { min, max } = chart.target.xAxis[0].getExtremes();
    const seriesMiddle = (min + max) / 2;
    const occurredOnEpoch = new Date(occurredOn).getTime();

    chart.target.xAxis[0].addPlotLine({
        id: 0,
        color: colors.black,
        width: 2,
        value: new Date(occurredOn),
        zIndex: 13,
        label: {
            allowOverlap: false,
            text: translate('ABB.Powertrain.Frontend.eventDetailsTrendSignalEventOccurred'),
            rotation: 0,
            x: occurredOnEpoch > seriesMiddle ? -5 : 5,
            style: {
                fontSize: 14
            },
            align: occurredOnEpoch > seriesMiddle ? 'right' : 'left'
        },
    });
};

const useChartConfig = (selectedKpisData, event, showUtcDates, removeFn) => {

    const [options, setOptions] = useState({});
    const chartConfig = getChartConfig(removeFn);

    useEffect(() => {
        const { yAxis, seriesData, allSeriesLength } = getSeries(selectedKpisData);
        chartConfig.time.useUTC = showUtcDates;
        chartConfig.yAxis = yAxis;
        chartConfig.series = seriesData;
        chartConfig.plotOptions.series.boostThreshold = allSeriesLength > defaultBoostThreshold ? 1 : 0;
        chartConfig.lang.noData = renderToStaticMarkup(<ChartNoDataText
            title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
            text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />);
        chartConfig.enableNoDataZoom = true;
        chartConfig.chart.events = {
            load: function (chart) {
                addOccuredOnPlotline(chart, event.occurredOn);
            },
            redraw: function (chart) {
                chart.target.xAxis[0].removePlotLine(0);
                addOccuredOnPlotline(chart, event.occurredOn);
            },
            render: function (chartInstance) {
                afterChartReDrawStringData(chartInstance.target.container.id, removeFn);
            }
        };
        chartConfig.legend = {
            align: 'right',
            enabled: true,
            symbolRadius: 0,
            alignColumns: false,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8,
            useHTML: true,
            itemStyle: {
                fontFamily: 'ABBvoice-Regular',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '16px',
            },
            labelFormatter: function () {
                return renderToStaticMarkup(<RemovableLegend
                    signalSourceName={this.userOptions.loggerName}
                    legend={this} />);
            }
        };

        chartConfig.tooltip.formatter = function () {
            return renderToStaticMarkup(<TrendTooltip
                dateConfig={{ asUTC: showUtcDates, showOffset: true }}
                points={this.points}
                timeStamp={this.x} />
            );
        };

        setOptions({ ...chartConfig });

    }, [selectedKpisData]);


    return options;
};

export default useChartConfig;
