import React from 'react';

import colors from 'theme/_colors.scss';


const IconFile = ({ width = 12, height = 16, viewBox = '0 0 12 16', color = colors.codGray }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M10 8V9H2V8H10ZM10 6V7H2V6H10ZM8 4V5H2V4H8ZM8.71 0H0V16H12V3.29L8.71 0ZM11 15H1V1H8.29L11 3.71V15Z' fill={color} />
	</svg>;
};

export default IconFile;
