import { fileUploadStatus } from './constants';
import { findIndex, some } from 'lodash';

export const containsNewFile = (bulkFiles) => {
	return findIndex(bulkFiles, (file) => { return file.uploadStatus === fileUploadStatus.NEW; }) >= 0;
};

export const checkUploadedFiles = (bulkFiles) => {
	return !some(bulkFiles, (file) => file.uploadStatus !== fileUploadStatus.SUCCESS);
};
