import React from 'react';
import PropTypes from 'prop-types';
import { resolveFillValueByConditionValue } from './functions';


const IconStress = ({ width = 14, height = 13, viewBox = '0 0 14 13', condition = -1, color }) => {
	const fillValue = resolveFillValueByConditionValue(condition);
	const appliedColor = color ? color : fillValue;

	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M14 12V13H0V12H14ZM14 0V5H12.66V2.34L8 7L6 5L0 11V9L6 3L8 5L11.63 1.37H9V0H14Z' fill={appliedColor} />
	</svg>;
};

IconStress.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	condition: PropTypes.number
};

export default IconStress;
