import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import logger from 'helpers/logger';

const logChanges = (modified, original, key, log) => {
	if (log) {
		logger.debug('Key => ' + key);
		logger.debug('Original => ' + JSON.stringify(original));
		logger.debug('Modified => ' + JSON.stringify(modified));
	}
};

const checkIfEqual = (modified, original, log) => {
	let equals = true;
	for (let i = 0; i < modified.length; i++) {
		if (modified[i] && original[i]) {
			forEach(modified[i], (val, key) => {
				if (!isEqual(val, original[i][key])) {
					logChanges(val, original[i][key], key, log);
					equals = false;
				}
				return equals;
			});
		}
		else if (modified[i] || original[i]) {
			logChanges(modified[i], original[i], i, log);
			equals = false;
		}
		if (!equals) {
			break;
		}
	}
	return equals;
};

const useIsDirty = (depList) => {
	const [isDirtyPage, setIsDirtyPage] = useState(null);
	const prevDepList = useRef(null);

	useEffect(() => {
		if (isDirtyPage === false) {
			prevDepList.current = depList;
		}
	}, [isDirtyPage]);

	useEffect(() => {
		if (prevDepList.current === null) { //skip first invocation
			prevDepList.current = depList;
		}
		else {
			if (isDirtyPage === null) {
				setIsDirtyPage(false);
			}
			else {
				setIsDirtyPage(!checkIfEqual(depList, prevDepList.current, false));
			}
		}
	}, depList);

	return [isDirtyPage, setIsDirtyPage];
};
export default useIsDirty;
