import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { apiCallStatus, kpiTypes } from 'helpers/constants';
import { GeneralSelect, InfoMessage, PopOutChart } from 'sharedComponents';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';
import useChartConfig, { chartPlugins } from './useChartConfig';
import { opButtons } from '../../constants';
import { tabIDs } from '../../../constants';
import useReloadTab from '../../useReloadTab';
import { PopOutButton, ResetZoomButton, withLeftButtonGroup, withPopOutRow, withRightButtonGroup } from 'sharedComponents/Chart/headers';

import './style.scss';
import { getTranslatedKPIName } from 'helpers/translateHelper';

const ScatterPlotComponent = ({
    model: {
        activeDetailedInformationTab,
        dateSelection,
        scatterData,
        scatterDataConfiguration,
        scatterDataLoadingStatus,
        scatterKpis,
        scatterKpisLoadingStatus,
        selectedKpi,
    },
    actions: {
        getScatterForAsset,
        getScatterKPIsForAsset,
        setSelectedKpi,
        removeSelectedKpiLegend
    },
    activeTab,
    asset,
}) => {
    const { t: translate } = useTranslation();
    const [isZoomed, setIsZoomed] = useState(false);
    const refreshData = () => {
        if (scatterKpisLoadingStatus === apiCallStatus.SUCCESS &&
            activeTab === opButtons.scatterPlot &&
            activeDetailedInformationTab === tabIDs.operationalParametersv2 &&
            scatterKpis.length &&
            selectedKpi &&
            dateSelection.from &&
            dateSelection.to) {
            getScatterForAsset(dateSelection.from, dateSelection.to, asset, selectedKpi);
        }
    };

    useEffect(() => {
        setIsZoomed(false);
    }, [dateSelection]);

    useReloadTab(refreshData,
        opButtons.scatterPlot,
        [selectedKpi, scatterKpisLoadingStatus, dateSelection]);

    useEffect(() => {
        scatterKpisLoadingStatus !== apiCallStatus.SUCCESS &&
            getScatterKPIsForAsset(asset, kpiTypes.SCATTER);
    }, []);

    const options = useChartConfig(scatterData, scatterDataConfiguration, scatterKpis, removeSelectedKpiLegend);

    const ScatterKPISelector = () => {
        return <GeneralSelect
            id='scatter_chart_type_selector'
            placeholder={translate('ABB.Powertrain.Frontend.pleaseSelectKPI')}
            options={scatterKpis}
            value={selectedKpi || ''}
            onChange={setSelectedKpi}
            getOptionLabel={option => getTranslatedKPIName(option)}
            getOptionValue={option => option.measurementTypeIdentifier} />;
    };

    const MainChartHeader = withPopOutRow([
        withLeftButtonGroup([ScatterKPISelector]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    const PopOutChartHeader = withPopOutRow([
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    return scatterKpisLoadingStatus === apiCallStatus.SUCCESS && scatterKpis.length === 0 ?
        <Row className='button-row-container'>
            <Col className='table-empty-content'>
                <InfoMessage title='ABB.Powertrain.Frontend.noSupportedMeasurementTypes' />
            </Col>
        </Row> :
        <LoadingStatusWrapper
            useCustomAnchor
            transparent
            loadingStatus={[scatterKpisLoadingStatus, scatterDataLoadingStatus]}>
            <PopOutChart
                isZoomed={isZoomed}
                setIsZoomed={setIsZoomed}
                hasKpi={!!selectedKpi}
                renderNoKpi={() => <InfoMessage
                    title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                    text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                hasData={!!scatterData?.length}
                renderNoData={() => <InfoMessage
                    title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                    text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
                options={options}
                modules={chartPlugins}
                container='op-scatter-chart'
                mainChartHeader={MainChartHeader}
                popOutChartHeader={PopOutChartHeader}
            />
        </LoadingStatusWrapper >;
};

export default ScatterPlotComponent;

ScatterPlotComponent.propTypes = {
    actions: PropTypes.shape({
        getScatterForAsset: PropTypes.func,
        getScatterKPIsForAsset: PropTypes.func,
        setSelectedKpi: PropTypes.func
    }),
    model: PropTypes.shape({
        activeDetailedInformationTab: PropTypes.string,
        dateSelection: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string
        }),
        selectedKpi: PropTypes.object,
        scatterData: PropTypes.array,
        scatterDataConfiguration: PropTypes.object,
        scatterDataLoadingStatus: PropTypes.string,
        scatterKpis: PropTypes.array,
        scatterKpisLoadingStatus: PropTypes.string,
    }),
    activeTab: PropTypes.string,
    asset: PropTypes.object,
};
