import { createSelector } from 'reselect';

const getTrendKPIsLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trendKpis?.loadingStatus;
};
const getTrendDataLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trend?.loadingStatus;
};

export const getModel = createSelector([
    getTrendKPIsLoadingStatus,
    getTrendDataLoadingStatus,
], (
    trendKPIsLoadingStatus,
    trendDataLoadingStatus,
) => {
    return {
        trendKPIsLoadingStatus,
        trendDataLoadingStatus,
    };
});
