import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager, RestManagerWithoutBaseUrl } from '../RestManager';


class UserProfileApi {

    update(userData) {
        const endpoint = formatEndpoint(endpoints.userProfile.update);
        return RestManager.post(endpoint, { ...userData, isUpdatedByFrontend: true });
    }

    updateUser(userData) {
        const endpoint = formatEndpoint(endpoints.profile.updateUserProfile);
        return RestManagerWithoutBaseUrl.patch(endpoint, userData);
    }

    acceptEULA() {
        const endpoint = formatEndpoint(endpoints.userProfile.acceptEULA);
        return RestManager.post(endpoint);
    }

    getAvailableLanguages() {
        const endpoint = formatEndpoint(endpoints.profile.getAvailableLanguages);
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    getUserProfile() {
        const endpoint = formatEndpoint(endpoints.profile.getUserProfile);
        return RestManagerWithoutBaseUrl.get(endpoint);
    }
}

export default new UserProfileApi();
