/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unused-modules
import React from 'react';
import { map, flatten } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    getConditionStatus,
    getFormatedAlarmAlertLimits,
    getFormattedAlarmAlertLines,
    getCondtionTrendIndicators,
    getHighErrorThresholdValue,
    getHighAlertThresholdValue,
    getLowAlertThresholdValue,
    getLowErrorThresholdValue
} from './helpers';
import KPIListChartListItem from './KPITrendChartListItem';

const KPITrendChartListComponent = (props) => {
    const { t: translate } = useTranslation();
    const renderKPITrendChartSections = () => {
        const totalKPIItems = map(props?.trendParams, (chartItem, index) => {
            return (
                <div
                    className='conditional-kpi-section-chart-item'
                    key={chartItem?.key}
                >
                    <KPIListChartListItem
                        model={{
                            data: chartItem?.trend,
                            key: chartItem?.key,
                            from: '',
                            to: '',
                            alertAlarmLimitBackgrounds:
                                getFormatedAlarmAlertLimits(
                                    chartItem?.conditionTrends
                                ),
                            alertAlarmLimitLines: getFormattedAlarmAlertLines(
                                chartItem?.conditionTrends
                            ),
                            conditionIndicators: getCondtionTrendIndicators(
                                chartItem?.conditionEvents
                            ),
                            title: chartItem?.name,
                            nameTextKey: chartItem?.nameTextKey,
                            loadingStatus: props?.loadingStatus,
                            conditionStatus: getConditionStatus(
                                chartItem?.currentCondition
                            ),
                            zoom: false,
                            unit: chartItem?.unit,
                            highErrorValue: getHighErrorThresholdValue(
                                chartItem?.conditionTrends
                            ),
                            highAlertLimitValue: getHighAlertThresholdValue(
                                chartItem?.conditionTrends
                            ),
                            lowErrorValue: getLowErrorThresholdValue(chartItem?.conditionTrends),
                            lowAlertValue: getLowAlertThresholdValue(chartItem?.conditionTrends),
                            unitGroupId: chartItem?.unitGroupId
                        }}
                        data={chartItem?.trend}
                        key={chartItem?.key}
                        name={chartItem?.name}
                        loadingStatus={props?.loadingStatus}
                        unit={chartItem?.unit}
                        currentCondition={chartItem?.currentCondition}
                    ></KPIListChartListItem>
                </div>
            );
        });
        return (
            <div className='conditional-kpi-section-chart-list'>
                {totalKPIItems}
            </div>
        );
    };

    return renderKPITrendChartSections();
};


// eslint-disable-next-line import/no-unused-modules
export default KPITrendChartListComponent;
