import { takeLatest, call, put } from 'redux-saga/effects';
import { NotificationSettingsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getNotificationSettingsAction,
    getMutedAssetListAction
} from './actions';


function* getNotificationSettingsSaga({ organizationID }) {
    yield put(getNotificationSettingsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.getNotificationSettings, organizationID);
        yield put(getNotificationSettingsAction(apiCallStatus.SUCCESS, response));
        logger.debug('Notification settings successfully loaded');
    } catch (err) {
        yield put(getNotificationSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* saveNotificationSettingsSaga({ organizationID, notificationSettings }) {
    yield put(getNotificationSettingsAction(apiCallStatus.LOADING));
    try {
        yield call(NotificationSettingsApi.saveNotificationSettings, organizationID, notificationSettings);
        const response = yield call(NotificationSettingsApi.getNotificationSettings, organizationID);
        yield put(getNotificationSettingsAction(apiCallStatus.SUCCESS, response));
        logger.debug('Notification settings successfully updated');
    } catch (err) {
        yield put(getNotificationSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

function* getMutedAssetListSaga({ organizationID }) {
    yield put(getMutedAssetListAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.getMutedAssetList, [organizationID]);
        yield put(getMutedAssetListAction(apiCallStatus.SUCCESS, response));
        logger.debug('Muted asset list successfully loaded');
    } catch (err) {
        yield put(getMutedAssetListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* updateMutedAssetSaga({ organizationID, assetId, notificationGroups, startDate, endDate }) {
    yield put(getMutedAssetListAction(apiCallStatus.LOADING));
    try {
        yield call(
            NotificationSettingsApi.muteAsset,
            { assetId, notificationGroups, startDate, endDate }
        );
        const response = yield call(NotificationSettingsApi.getMutedAssetList, [organizationID]);
        yield put(getMutedAssetListAction(apiCallStatus.SUCCESS, response));
        logger.debug('Muted asset list successfully updated');
    } catch (err) {
        yield put(getMutedAssetListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* deleteMutedAssetsSaga({ organizationID, assetIDs }) {
    yield put(getMutedAssetListAction(apiCallStatus.LOADING));
    try {
        yield call(
            NotificationSettingsApi.deleteMutedAssets,
            assetIDs);
        const response = yield call(NotificationSettingsApi.getMutedAssetList, [organizationID]);
        yield put(getMutedAssetListAction(apiCallStatus.SUCCESS, response));
        logger.debug('Muted assets successfully deleted');
    } catch (err) {
        yield put(getMutedAssetListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* notificationSettingsWatchers() {
    yield takeLatest(actionTypes.LOAD_NOTIFICATION_SETTINGS_REQUEST, getNotificationSettingsSaga);
    yield takeLatest(actionTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST, saveNotificationSettingsSaga);
    yield takeLatest(actionTypes.LOAD_MUTED_ASSETS_REQUEST, getMutedAssetListSaga);
    yield takeLatest(actionTypes.UPDATE_MUTED_ASSET_REQUEST, updateMutedAssetSaga);
    yield takeLatest(actionTypes.DELETE_MUTED_ASSETS_REQUEST, deleteMutedAssetsSaga);
}
