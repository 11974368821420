import { actionTypes } from '../constants';


export const getMutedAssetList = (organizationID) => ({
    type: actionTypes.LOAD_MUTED_ASSETS_REQUEST,
    organizationID
});

export const selectMutedAsset = (assetId = null) => ({
    type: actionTypes.SELECT_MUTED_ASSET,
    assetId
});

export const saveMutedAssetSettings = (organizationID, notificationGroups, period, assetId) => ({
    type: actionTypes.UPDATE_MUTED_ASSET_REQUEST,
    organizationID,
    assetId,
    notificationGroups,
    startDate: period?.from || null,
    endDate: period?.to || null
});
