import { ConfiguredNotificationsApi } from 'api/index';
import localStorageHelper from 'helpers/localStorageHelper';
import logger from 'helpers/logger';
import { UserManager } from 'oidc-client-ts';

import { oidcConfiguration } from '../../config/appConfig';

export const getUserManager = (environmentProperties) => {
    if (!environmentProperties) {
        return null;
    }
    const {
        openIDClientID,
        openIDIssuer
    } = environmentProperties;

    const authConfig = { ...oidcConfiguration };

    authConfig['client_id'] = openIDClientID;
    authConfig['authority'] = openIDIssuer;

    return new UserManager(authConfig);
};

export const formatLanguageResource = (applangaResources, localResources = null) => {
    return {
        'ABB': {
            'Powertrain': {
                'Frontend': {
                    ...localResources ? localResources?.ABB?.Powertrain?.Frontend : applangaResources?.ABB?.Powertrain?.Frontend
                }
            },
            'CMD': {
                'Backend': {
                    'KPI': {
                        ...applangaResources?.ABB?.CMD?.Backend?.KPI
                    }
                }
            },
            'SmartSensor': {
                'Backend': {
                    'KPI': {
                        ...applangaResources?.ABB?.SmartSensor?.Backend?.KPI
                    },
                    'IssueType': {
                        ...applangaResources?.ABB?.SmartSensor?.Backend?.IssueType
                    },
                    'IssueSubtype': {
                        ...applangaResources?.ABB?.SmartSensor?.Backend?.IssueSubtype
                    }
                }
            }
        }
    };
};

export const approveBroadcastedMessageHandler = (messageID) => {
    const userID = localStorageHelper.getCurrentUserId();
    ConfiguredNotificationsApi
        .removeBroadcastedMessageForUser(messageID, userID)
        .catch((err) => {
            return err ? logger.error(err.message) : null;
        });
    localStorageHelper.removeItem(`broadcastedMessage_${userID}`);
};

export const getBroadcastedMessage = () =>
    localStorageHelper.getItem(`broadcastedMessage_${localStorageHelper.getCurrentUserId()}`);

