import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContextMenu } from 'sharedComponents';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useHeaderMenus from './useHeaderMenus';
import { getAssetIcon } from 'helpers/icons';
import { actionTypes } from './constants';
import isEmpty from 'lodash/isEmpty';

import './style.scss';

const HeaderComponent = (props) => {
    const { asset } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const [headerMenus, selectedHeaderMenu] = useHeaderMenus(asset?.componentType?.typeName);

    useEffect(() => {
        return () => dispatch({ type: actionTypes.CLEAR_STORE });
    }, [asset.componentID]);

    useEffect(() => {
        selectedHeaderMenu?.menuAction && selectedHeaderMenu.menuAction(dispatch, history, asset);
    }, [selectedHeaderMenu]);

    const toggleSelection = (selectedMenuItemId) => {
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: selectedMenuItemId });
        dispatch({ type: actionTypes.CLOSE_DIALOGUE, data: { reName: null } });
    };

    return <>
        <Container className='detailed-information-header' fluid>
            <Row className='asset-information'>
                <Col md='auto'>{getAssetIcon(asset?.componentType?.typeName, { width: '24', height: '24' })}</Col>
                <Col className='asset-name'>{asset?.componentName}</Col>
                <Col md='auto'>
                    {
                        asset?.isUserAccessible &&
                        !isEmpty(headerMenus) &&
                        <ContextMenu id='headingContextMenu'
                            options={headerMenus}
                            onSelect={toggleSelection} />
                    }
                </Col>
            </Row>
        </Container>
        {
            selectedHeaderMenu?.modalContentComponent &&
            <selectedHeaderMenu.modalContentComponent
                show={!!selectedHeaderMenu}
                close={() => toggleSelection(null)}
                title={selectedHeaderMenu.title}
                asset={asset}
            />
        }
    </>;
};

export default HeaderComponent;
