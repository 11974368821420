import find from 'lodash/find';
import {
    apiCallStatus,
    userCapabilities,
} from 'helpers/constants';

export default class UserCapabilityHelper {

    constructor(capability) {
        if (capability) {
            this.userCapabilityList = capability?.data?.capabilityInfos;
            this.isUserCapabilityLoading = capability.loadingStatus && capability.loadingStatus === apiCallStatus.LOADING;
        }
    }

    hasCapability(capabilityToCheck) {
        let hasCapability = false;
        if (this.userCapabilityList) {
            const foundCapability = find(this.userCapabilityList, (capability) => capability.capabilityCode === capabilityToCheck);
            if (foundCapability) {
                hasCapability = foundCapability.isAvailable === true;
            }
        }
        return hasCapability;
    }

    isLoading() {
        return this.isUserCapabilityLoading;
    }

    isSet() {
        return !!this.userCapabilityList;
    }

    hasConditionMonitoringCapability() {
        return this.hasCapability(userCapabilities.conditionOverview);
    }

    hasNotificationSettingsCapability() {
        return this.hasCapability(userCapabilities.notificationSettings);
    }
}

