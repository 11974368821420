import { FEATURES } from 'helpers/constants';

const featureDefaults = {
    isCBMEnabled: true,
    isReportsEnabled: true,
    isExpertReportUploadEnabled: true,
};

export default class featureConfigHelper {

    constructor(availableFeatures) {
        this.availableFeatures = availableFeatures || featureDefaults;
    }

    isFeatureEnabled(featureToCheck) {
        let isFeatureEnabled = false;

        if (this.availableFeatures) {
            const foundFeature = this.availableFeatures[featureToCheck];
            if (foundFeature) {
                isFeatureEnabled = true;
            }
        }
        return isFeatureEnabled;
    }

    isCBMFeatureEnabled() {
        return this.isFeatureEnabled(FEATURES.isCBMEnabled);
    }


    isReportsFeatureEnabled() {
        return this.isFeatureEnabled(FEATURES.isReportsEnabled);
    }

    isExpertReportUploadEnabled() {
        return this.isFeatureEnabled(FEATURES.isExpertReportUploadEnabled);
    }
}
