import sortBy from 'lodash/sortBy';
import { getAllAssets, getAssetsLoadingStatus, getLoadingStatuses } from 'helpers/selectors';
import { createSelector } from 'reselect';
import { hasCapability } from './helpers';
import { CAPABILITIES } from 'helpers/constants';


const getReportsLoadingStatus = ({ reports }) => {
    return reports?.list?.loadingStatus;
};

const getSelectedOrganizations = ({ generalFilter }) => {
    return generalFilter.selectedFilters.organizations;
};

const getReportCapabilitiesLoadingStatus = ({ reports }) => {
    return reports?.capabilities?.loadingStatus;
};

export const getReportCapabilities = ({ reports }) => {
    return reports?.reportCapabilities?.data;
};

export const getAssetsWithExpertReportUpload = createSelector(
    [
        getAllAssets,
        getReportCapabilities,
    ],
    (assets, reportCapabilities) => {
        return sortBy(assets.filter((asset) => {
            return hasCapability(asset.id, reportCapabilities, CAPABILITIES.expertReportUpload);
        }), (o) => (o?.componentName || '').toLowerCase());
    }
);

export const getModel = createSelector([
    getReportsLoadingStatus,
    getAssetsLoadingStatus,
    getLoadingStatuses,
    getReportCapabilitiesLoadingStatus,
    getSelectedOrganizations,
    getAllAssets,
    getAssetsWithExpertReportUpload,
], (
    reportsLoadingStatus,
    assetsLoadingStatus,
    generalFilterLoadingStatuses,
    reportCapabilitiesLoadingStatus,
    organizations,
    assets,
    assetsWithExpertReportUpload,
) => {
    return {
        reportsLoadingStatus,
        assetsLoadingStatus,
        generalFilterLoadingStatuses,
        reportCapabilitiesLoadingStatus,
        organizations,
        assets,
        assetsWithExpertReportUpload,
    };
});
