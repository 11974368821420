export const actionTypes = {
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    TOGGLE_CONDITION_STATUS_FILTER: 'detailedInformation/TOGGLE_CONDITION_STATUS_FILTER',
    RESET_FILTERS: 'detailedInformation/RESET_FILTERS',
    SET_CONDITION_INDEX_FILTER: 'detailedInformation/SET_CONDITION_INDEX_FILTER',
    SET_SEARCHBOX_FILTER: 'detailedInformation/SET_SEARCHBOX_FILTER',
    SET_GATEWAY_TYPE_FILTER: 'detailedInformation/SET_GATEWAY_TYPE_FILTER',
    SET_SELECTED_POWERTRAIN: 'powertrainAssetDetails/SET_SELECTED_POWERTRAIN',
    SET_ACTIVE_TAB: 'powertrainAssetDetails/SET_ACTIVE_TAB',
    CLOSE_NOTIFICATION_BAR: 'notificationStatusBar/CLOSE_NOTIFICATION_BAR',
};
