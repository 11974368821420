import React from 'react';
import OverviewAssetsComponent from './OverviewAssetsComponent';
import { connect } from 'react-redux';
import { getModel } from './selectors';

const OverviewAssetsContainer = (props) => {
    return <OverviewAssetsComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const OverviewAssetsContainerConn = connect(
    mapStateToProps
)(OverviewAssetsContainer);

export default OverviewAssetsContainerConn;
