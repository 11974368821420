import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconDegradedBatteryFull = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M2.1001 6.82501L2.1001 17.25L18.9751 17.25L18.9751 6.82501L2.1001 6.82501Z' stroke={color} strokeLinejoin='bevel' />
		<path d='M18.9751 14.7749L21.0001 14.7749L21.5251 14.0999L21.5251 11.9249L21.5251 9.89985L21.0001 9.22485L18.9751 9.22485' stroke={color} strokeLinejoin='bevel' />
		<path d='M9.44995 8.70013L9.44995 15.3001' stroke={color} strokeLinejoin='bevel' />
		<path d='M6.82495 8.70013L6.82495 15.3001' stroke={color} strokeLinejoin='bevel' />
		<path d='M4.2749 8.70013L4.2749 15.3001' stroke={color} strokeLinejoin='bevel' />
		<path d='M12.1499 8.70013L12.1499 15.3001' stroke={color} strokeLinejoin='bevel' />
		<path d='M14.8501 8.70013L14.8501 15.3001' stroke={color} strokeLinejoin='bevel' />
	</svg>;
};

IconDegradedBatteryFull.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};


export default IconDegradedBatteryFull;
