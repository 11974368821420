export const actionOptions = {
    AddComment: 1,
    CloseEvent: 2
};

export const maxCommentSize = 500;
export const pageSize = 10;

export const actionTypes = {
    CLOSE_EVENT_LOG: 'eventDetailsSS/CLOSE_EVENT_LOG',
    POST_EVENT_COMMENT: 'eventDetailsSS/POST_EVENT_COMMENT',
    GET_EVENT_COMMENTS: 'eventDetailsSS/GET_EVENT_COMMENTS',
    CLEAR_STORE: 'eventDetailsSS/CLEAR_STORE',
    UPDATE_EVENT_LOGS: 'eventlog/UPDATE_EVENT_LOGS'
};
