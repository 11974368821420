import React from 'react';
import { Row, Col } from 'react-bootstrap';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { getConditionDescription } from './helpers';
import useAssetConditionValues from './useAssetConditionValues';
import { overallConditionConfig, conditionConfig } from './constants';

import './style.scss';

const ConditionsPanelComponent = (props) => {
    const { t: translate } = useTranslation();

    const assetConditionValues = useAssetConditionValues(props.asset);
    // TODO PT condition value
    const conditionValues = assetConditionValues; // || powertrainConditionValue
    if (!conditionValues || conditionValues.overall < 0) {
        return null;
    }

    return (
        <Col className='conditions-panel-container'>
            <Row>
                <Col className='condition-col overall-condition' md={12}>
                    <div className='me-2'><overallConditionConfig.Icon condition={conditionValues.overall} /></div>
                    <div>{getConditionDescription(overallConditionConfig.texts, conditionValues.overall, translate)} {translate('ABB.Powertrain.Frontend.conditionLabel')}</div>
                </Col>
                {
                    map(conditionConfig, (conf) => {
                        if (conditionValues[conf.type] < 0) {
                            return null;
                        }
                        const value = conditionValues[conf.type];
                        return <Col className='condition-col' key={`${conf.type}${value}`} xl='auto' md={12}>
                            <div className='me-2'><conf.Icon condition={value} /></div>
                            <div>{getConditionDescription(conf.texts, value, translate)}</div>
                        </Col>;
                    })}
            </Row>
        </Col>
    );
};

export default ConditionsPanelComponent;
