import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconHamburger } from 'svgIcons/MotionPortalIcons';
import useMainMenuOptions from './useMainMenuOptions';
import useModalOptions from './useModalOptions';
import { ModalPicker } from './ModalPicker';

const MainMenuDropdownComponent = ({ actions, menuBarFits, menuBarItems }) => {
    const { t: translate } = useTranslation();
    const acknowledgeEULA = () => {
        actions.acceptEULA();
    };

    const [modalVisible, setModalVisible] = useState();
    const [currentModal, setCurrentModal] = useState({ modalType: 'pdf' });
    const mainMenus = useMainMenuOptions(setModalVisible, setCurrentModal);

    const closeModal = () => {
        setModalVisible(false);
        setCurrentModal({ ...currentModal, pdfContent: null });
    };

    useModalOptions(setModalVisible, setCurrentModal, { EULA: acknowledgeEULA }, closeModal);

    const renderMainMenuItems = () =>
        mainMenus.map((option, key) =>
            <Dropdown.Item
                key={key}
                {...(option.href && { href: option.href, target: '_blank' })}
                {...(option.onClick && { onClick: option.onClick })}
            >
                {translate(option.label)}
            </Dropdown.Item>);

    const renderMenuBarItems = () =>
        !menuBarFits &&
        <>
            {
                menuBarItems.map((menuBarItem, key) =>
                    <Dropdown.Item key={key} as={NavLink} to={menuBarItem.route}>{translate(menuBarItem.title)}</Dropdown.Item>)
            }
            <Dropdown.Divider />
        </>;

    return (
        <>
            <DropdownButton
                className='right-dropdown-menu'
                title={<IconHamburger color='black' />}
            >
                {renderMenuBarItems()}
                {renderMainMenuItems()}
            </DropdownButton>
            <ModalPicker
                show={modalVisible}
                modalType={currentModal.modalType}
                closeModal={closeModal}
                modalMetadata={{ ...currentModal.modalMetadata }}
            />
        </>
    );
};

export default MainMenuDropdownComponent;
