
import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
	parameterFileList: {
		data: [],
		loadingStatus: apiCallStatus.LOADING
	},
	parameterFile: {
		data: undefined,
		loadingStatus: apiCallStatus.LOADING
	}
};

// Reducer

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PARAMETER_FILE_LIST:
			return {
				...state,
				parameterFileList: { ...state.parameterFileList, ...action }
			};
		case actionTypes.GET_PARAMETER_FILE:
			return {
				...state,
				parameterFile: { ...state.parameterFile, ...action }
			};
		default:
			return state;
	}
};

export default reducer;

