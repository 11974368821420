import * as actionTypes from './constants';

const initialState = {
	data: undefined
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_NOTIFICATION_BAR:
			return {
				...state,
				data: action.data
			};
		case actionTypes.CLOSE_NOTIFICATION_BAR:
			return {
				...state,
				data: initialState.data
			};
		default:
			return state;
	}
}
