import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import {
    LoadingStatusWrapper
} from 'sharedComponents';
import { DriveStatusChart } from '../../CommonCharts/DriveStatus';

const DriveStatusComponent = ({
    model: {
        selectedTrendsData: {
            data,
            loadingStatus,
            zoom: { isZoomed },
            chartID
        }
    },
    title,
    conditionStatus
}) => {
    return (
        <LoadingStatusWrapper transparent useCustomAnchor loadingStatus={[loadingStatus]}>
            <Row>
                <Col>
                    <DriveStatusChart
                        trendDataLoadingStatus={loadingStatus}
                        selectedTrendsData={data}
                        isZoomed={isZoomed}
                        chartKey={chartID}
                        title={title}
                        conditionStatus={conditionStatus}
                    />
                </Col>
            </Row>
        </LoadingStatusWrapper>
    );
};
DriveStatusComponent.propTypes = {
    model: PropTypes.shape({
        selectedTrendsData: PropTypes.shape({
            loadingStatus: PropTypes.string,
            data: PropTypes.array,
            zoom: PropTypes.shape({
                isZoomed: PropTypes.bool,
            }),
            chartID: PropTypes.string
        })
    }),
    title: PropTypes.string,
    conditionStatus: PropTypes.number
};
export default DriveStatusComponent;


