export const LimitTypeOptions = [
    {
        value: 'oneDirectionAlarmOrAlert',
        name: 'One-direction alarm or alert',
        instance: 1,
        type: ['Alarm'],
        limitDirection: ['UpperLimit'],
    },
    {
        value: 'oneDirectionAlarmAndAlert',
        name: 'One-direction alarm and alert',
        instance: 2,
        type: ['Alarm', 'Alert'],
        limitDirection: ['UpperLimit', 'UpperLimit'],
    },
    {
        value: 'outOfRangeAlarmOrAlert',
        name: 'Out-of-range alarm or alert',
        instance: 2,
        type: ['Alert', 'Alert'],
        limitDirection: ['UpperLimit', 'UpperLimit'],
    },
    {
        value: 'outOfRangeAlarmAndAlert',
        name: 'Out-of-range alarm and alert',
        instance: 4,
        type: ['Alarm', 'Alert', 'Alert', 'Alarm'],
        limitDirection: ['UpperLimit', 'UpperLimit', 'LowerLimit', 'LowerLimit'],
    }
];

export const LEVEL = {
    UpperLimit: 'UpperLimit',
    LowerLimit: 'LowerLimit',
    Upper: 'UpperLimit'
};

export const LIMIT_SYMBOL = {
    Greater: '>',
    Lower: '<',
    Default: '>/<'
};

export const KPI_TYPE = {
    Trend: 'trends',
    HealthTrend: 'healthTrends'
};

export const KPI_ERROR = {
    isSignalError: 'isSignalError'
};

export const SLIDER_VALUES = {
    MIN: 0.9,
    MAX: 1.1
};

export const RANGE = {
    Min: 'min',
    Max: 'max'
};
