import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AssetAndPowertrainFilterComponent from './AssetAndPowertrainFilterComponent';
import { getModel } from './selectors';
import * as actions from './actions';

import './style.scss';

const AssetAndPowertrainFilterContainer = (props) => {

    useEffect(() => {
        return () => {
            props.actions.resetAssetAndPowertrainFilters();
        };
    }, []);

    return <AssetAndPowertrainFilterComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetAndPowertrainFilterContainer);

