import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconFan = ({ width = 32, height = 32, color = colors.black }) => {
	return <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046
		14.8954 18 16 18Z' fill={color} />
		<path d='M22.0401 29.96L19.0401 26.96L16.0401 29.96L14.6201 28.55L19.0401 24.13L22.0401 27.13L25.0401 24.13L26.4501
		25.55L22.0401 29.96Z' fill={color} />
		<path d='M12.79 27.55C7.73 26.14 4 21.5 4 16C4 9.38 9.38 4 16 4C22.62 4 28 9.38 28 16C28 18.35 27.31 20.55 26.13 22.4L26.45
		22.72L27.59 23.86C29.11 21.62 30 18.92 30 16C30 8.27 23.73 2 16 2C8.27 2 2 8.27 2 16C2 22.04 5.84 27.18 11.2 29.14L12.79
		27.55Z' fill={color} />
		<path d='M18.8301 9.17004C19.9001 9.17004 20.9001 9.59004 21.6601 10.34L20.2501 11.75L18.8401 13.16L20.2501 14.57L21.6601 15.98C23.2201
		17.54 23.2201 20.08 21.6601 21.64L20.2501 20.23L18.8401 18.82L17.4301 20.23L16.0201 21.64C15.2601 22.4 14.2601 22.81 13.1901 22.81C12.1201
		22.81 11.1201 22.39 10.3601 21.64L11.7701 20.23L13.1801 18.82L11.7701 17.41L10.3601 16C8.80007 14.44 8.80007 11.9 10.3601 10.34L11.7701
		11.75L13.1801 13.16L14.5901 11.75L16.0001 10.34C16.7601 9.58004 17.7601 9.17004 18.8301 9.17004ZM18.8301 7.17004C17.2901 7.17004 15.7601
		7.76004 14.5901 8.93004L13.1801 10.34L11.7701 8.93004C11.3801 8.54004 10.8701 8.34004 10.3601 8.34004C9.85007 8.34004 9.34007 8.54004
		8.95007 8.93004C6.61007 11.27 6.61007 15.07 8.95007 17.42L10.3601 18.83L8.95007 20.24C8.17007 21.02 8.17007 22.29 8.95007 23.07C10.1201
		24.24 11.6601 24.83 13.1901 24.83C14.7201 24.83 16.2601 24.24 17.4301 23.07L18.8401 21.66L20.2501 23.07C20.6401 23.46 21.1501 23.66
		21.6601 23.66C22.1701 23.66 22.6801 23.46 23.0701 23.07C25.4101 20.73 25.4101 16.93 23.0701 14.58L21.6601 13.17L23.0701 11.76C23.8501
		10.98 23.8501 9.71004 23.0701 8.93004C21.9001 7.76004 20.3601 7.17004 18.8301 7.17004Z' fill={color} />
	</svg>;
};

IconFan.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};

export default IconFan;
