import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import {
    Expandable,
    Switch
} from 'sharedComponents';
import {
    getInitialTooltipStates,
    updateTooltipStates,
    updateChannelSettingForNotificationType,
    isNotificationGroupSelected
} from './helpers';
import './style.scss';


const NotificationTypeListComponent = (props) => {
    const { t: translate } = useTranslation();
    const {
        notificationChannels,
        saveNotificationSettings,
        selectNotificationGroup,
        selectedNotificationGroups,
        organizationID
    } = props;

    const [
        descriptionTooltipVisibility,
        setDescriptionTooltipVisibility
    ] = useState(getInitialTooltipStates(notificationChannels?.notificationGroups));

    const handleInfoTooltipClick = (notificationGroupCode) => {
        setDescriptionTooltipVisibility(
            updateTooltipStates(descriptionTooltipVisibility, notificationGroupCode));
    };

    const renderHeader = () => {
        return <div className='notification-groups-header'>
            <div className='header-item notification-type'>
                {translate('ABB.Powertrain.Frontend.notificationGroupColumnTitle')}
            </div>
            <div className='header-item channels'>
                {translate('ABB.Powertrain.Frontend.emailNotificationColumnTitle')}
            </div>
            <div className='header-item' />
        </div>;
    };

    const renderInfoTooltip = (notificationGroupCode, description) => {
        return <Tippy
            content={description}
            visible={descriptionTooltipVisibility[notificationGroupCode]}
            trigger='click'
            interactive={true}
            interactiveBorder={10}
            animation='scale'
            theme='light-border'
            maxWidth='400px'
        >
            <button onClick={(e) => {
                e?.stopPropagation();
                handleInfoTooltipClick(notificationGroupCode);
            }}>
                <IconInfo width={'14px'} height={'14px'} />
            </button>
        </Tippy>;
    };

    const renderGroupTitle = (notificationGroup) => {
        return <div data-testid='notification-group-name'>
            {translate(notificationGroup?.notificationGroupNameTextKey) ||
                notificationGroup?.notificationGroupName}
            {renderInfoTooltip(notificationGroup.notificationGroupCode,
                translate(notificationGroup?.notificationGroupDescriptionTextKey) ||
                notificationGroup?.notificationGroupDescription)}
        </div>;
    };

    const renderChannel = (channel, notificationGroupCode, notificationType) => {
        return <div className='notification-type-col channel' key={channel.channelCode}>
            <Switch
                onChange={() => {
                    saveNotificationSettings(organizationID, updateChannelSettingForNotificationType(
                        notificationChannels,
                        notificationGroupCode,
                        notificationType?.notificationTypeCode,
                        channel?.channelCode
                    ));
                }}
                checked={channel.enabled}
            />
            <div className='switch-label'>
                {translate(`ABB.Powertrain.Frontend.notificationChannel${channel.channelCode}`)}
            </div>
        </div>;
    };

    const renderNotificationTypes = (notificationGroupCode, notificationTypes) => {
        return map(notificationTypes, (notificationType) => {
            return <div className='notification-type-row' data-testid='notification-type' key={notificationType?.notificationTypeCode}>
                <div className='notification-type-col type-name'>
                    {translate(notificationType?.notificationTypeNameTextKey) ||
                        notificationType?.notificationTypeName}
                </div>
                <div className='notification-type-channels-group'>{map(notificationType?.channels, (channel) => {
                    return renderChannel(channel, notificationGroupCode, notificationType);
                })}
                </div>
            </div>;
        });
    };

    const renderList = () => {
        return map(notificationChannels?.notificationGroups, (notificationGroup) => {
            return <Expandable
                id={notificationGroup?.notificationGroupCode}
                key={notificationGroup?.notificationGroupCode}
                className='notification-groups-list-item'
                title={renderGroupTitle(notificationGroup)}
                open={isNotificationGroupSelected(selectedNotificationGroups, notificationGroup?.notificationGroupCode)}
                onClick={() => selectNotificationGroup(notificationGroup?.notificationGroupCode)}
            >
                {renderNotificationTypes(
                    notificationGroup?.notificationGroupCode,
                    notificationGroup?.notificationTypes)}
            </Expandable>;
        });
    };

    return <div className='notification-groups-container'>
        {renderHeader()}
        <div className='notification-groups-list'>{renderList()}</div>
    </div>;
};

NotificationTypeListComponent.propTypes = {
    notificationChannels: PropTypes.object,
    saveNotificationSettings: PropTypes.func,
    selectNotificationGroup: PropTypes.func,
    selectedNotificationGroups: PropTypes.array,
    organizationID: PropTypes.number
};

export default NotificationTypeListComponent;
