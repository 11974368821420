import { actionTypes } from './constants';
import { getActionWithDefaultData } from 'helpers/actionHelper';


const initialState = {
    assetInfo: {
        data: [],
        loadingStatus: null,
    },
    sensorFeatures: {
        data: [],
        loadingStatus: null,
    },
    sensorProperties: {
        data: [],
        loadingStatus: null,
    },
    gatewayInfo: {
        data: [],
        loadingStatus: null,
    }
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_INFO:
            return {
                ...state,
                assetInfo: getActionWithDefaultData(action, initialState.assetInfo)
            };
        case actionTypes.GET_SENSOR_FEATURES:
            return {
                ...state,
                sensorFeatures: getActionWithDefaultData(action, initialState.sensorFeatures)
            };
        case actionTypes.GET_SENSOR_PROPERTIES:
            return {
                ...state,
                sensorProperties: getActionWithDefaultData(action, initialState.sensorProperties)
            };
        case actionTypes.GET_GATEWAY_INFO:
            return {
                ...state,
                gatewayInfo: getActionWithDefaultData(action, initialState.gatewayInfo)
            };
        case actionTypes.CLEAR_STORE:
            return {
                ...state,
                assetInfo: { ...initialState.assetInfo },
                sensorFeatures: { ...initialState.sensorFeatures },
                sensorProperties: { ...initialState.sensorProperties },
                gatewayInfo: { ...initialState.gatewayInfo },
            };
        default:
            return state;
    }
};
