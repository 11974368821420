import React from 'react';
import PropTypes from 'prop-types';
import { resolveFillValueByConditionValue } from './functions';


const IconReliability = ({ width = 14, height = 14, viewBox = '0 0 14 14', condition = -1, color }) => {
	const fillValue = resolveFillValueByConditionValue(condition);
	const appliedColor = color ? color : fillValue;

	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M7 0V7H14C14 8.38447 13.5895 9.73785 12.8203 10.889C12.0511 12.0401 10.9579 12.9373 9.67879 13.4672C8.3997 13.997 6.99224 14.1356 5.63437 13.8655C4.2765 13.5954 3.02922 12.9287 2.05026 11.9497C1.07129 10.9708 0.404603 9.7235 0.134506 8.36563C-0.13559 7.00776 0.00303304 5.6003 0.532846 4.32122C1.06266 3.04213 1.95987 1.94888 3.11101 1.17971C4.26216 0.410543 5.61553 0 7 0Z' fill={appliedColor} />
	</svg>;
};

IconReliability.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	condition: PropTypes.number,
	color: PropTypes.string
};

export default IconReliability;
