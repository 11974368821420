import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GeneralRouterTabs } from 'sharedComponents/GeneralTabs';
import { getTabOptions } from './helpers';
import { PowertrainConfiguration } from './PowertrainConfiguration';
import { SubscriptionStatus } from './SubscriptionStatus';
import { getModel } from './selectors';
import * as actions from './SubscriptionStatus/actions';

const PowertrainToolsComponent = (props) => {
    const { t: translate } = useTranslation();
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));
    const generalFilterAssetIds = props?.model?.generalFilter;
    useEffect(() => {
        const assetIds = props?.model?.generalFilter?.map((item) => item.id);
        const assetIdParams = { assetIds: assetIds, type: null, statusCodes: [], maxEndDate: null };
        setTabOptions(getTabOptions(translate));
        if (assetIds?.length > 0) {
            props.actions.getSubscriptionInfo(assetIdParams);
        }
    }, [generalFilterAssetIds, translate]);


    return (
        <div className='general-page-content'>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
            >
                <Switch>
                    <Route path={tabOptions.powertrainConfiguration.route}>
                        <PowertrainConfiguration />
                    </Route>
                    <Route path={tabOptions.subscriptionStatus.route}>
                        <SubscriptionStatus />
                    </Route>
                    <Redirect from='*' to={tabOptions.powertrainConfiguration.route} />
                </Switch>
            </GeneralRouterTabs>
        </div>
    );
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const PowertrainToolsConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(PowertrainToolsComponent);

PowertrainToolsComponent.propTypes = {
};

export default PowertrainToolsConn;
