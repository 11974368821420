import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainMenuDropdownComponent from './MainMenuDropdownComponent';
import * as actions from './actions';

const MainMenuDropdownContainer = (props) => {

    return <MainMenuDropdownComponent {...props} />;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const MainMenuDropdownContainerConn = connect(null,
    mapDispatchToProps
)(MainMenuDropdownContainer);

export default MainMenuDropdownContainerConn;
