import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ConditionMonitoringAssetListItem = ({
    assetIcon,
    assetName,
    conditionIconsRow,
    onAssetSelection,
}) => {
    return (
        <li className='condition-monitoring-asset' onClick={onAssetSelection}>
            <div className='condition-monitoring-asset__icon'>{assetIcon}</div>
            <span className='condition-monitoring-asset__name'>
                {assetName}
            </span>
            <div className='condition-monitoring-asset__condition-icons'>
                {conditionIconsRow}
            </div>
        </li>
    );
};

ConditionMonitoringAssetListItem.propTypes = {
    assetIcon: PropTypes.node,
    assetName: PropTypes.string,
    conditionIconsRow: PropTypes.node,
    onAssetSelection: PropTypes.func,
};

export default ConditionMonitoringAssetListItem;
