import { actionTypes } from './constants';
import { getAllKPITrendChartList, getDriveKPIList } from './helpers';
const initialState = {
    conditionInsightsTrendKpis: {
        trendKPI: [],
    },
    conditionInsightsDriveStatus: {
        driveKPI: null,
    },
    assetInfo: {
        assetId: null,
        conditionStatus: null
    },
    loadingStatus: ''

};

// eslint-disable-next-line import/no-unused-modules
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        //COMMON SECTION
        case actionTypes.GET_ASSET_KPI_TREND_LIST: {
            return {
                ...state,
                conditionInsightsTrendKpis: {
                    trendKPI: getAllKPITrendChartList(action?.data
                        ?.trendChartKPIS)
                },
                conditionInsightsDriveStatus: {
                    driveKPI: getDriveKPIList(action?.data?.trendChartKPIS)
                },
                assetInfo: {
                    conditionStatus: action?.data?.assetConditionStatus,
                    assetId: action?.data?.assetID
                },
                loadingStatus: action?.loadingStatus
            };
        }
        default:
            return state;
    }
};
