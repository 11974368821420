import * as actionTypes from './constants';

const initialState = {
	postComment: {
		loadingStatus: null,
	},
	deleteComment: {
		loadingStatus: null,
	},
	comments: {
		data: [],
		error: null,
		loadingStatus: null,
	}
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_COMMENTS:
			return {
				...state,
				comments: {
					data: action.data,
					error: action.error,
					loadingStatus: action.loadingStatus,
				},
			};
		case actionTypes.POST_EVENT_COMMENT:
			return {
				...state,
				postComment: {
					loadingStatus: action.loadingStatus
				},
			};
		case actionTypes.DELETE_COMMENT: {
			return {
				...state,
				deleteComment: {
					loadingStatus: action.loadingStatus
				},
			};
		}
		case actionTypes.CLEAR_SELF_EVALUATION_STORE:
			return initialState;
		default:
			return state;
	}
};
