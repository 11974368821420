import React from 'react';
import PropTypes from 'prop-types';
import { toLocaleDateTimeString } from 'helpers/dateHelper';


const TrendTooltip = (props) => {

    const { loggers, dateFormat, timeStamp } = props;
    return <div className='custom-trend-tooltip'>
        <div className='t-header'>{toLocaleDateTimeString(timeStamp, dateFormat)}</div>
        {
            loggers.map((logger) => {
                return <div key={logger.loggerName}>
                    <div className='logger-name'>{logger.loggerName}</div>
                    {
                        logger.signals.map((signal) => {
                            return <div className='t-item' key={signal.name + signal.value}>
                                <span className='symbol' style={{ backgroundColor: signal.color }} />
                                <span>
                                    <span>{` ${signal.name + ': ' + signal.value} `}</span>
                                    <b>{signal.valueSuffix}</b>
                                </span>
                            </div>;
                        })
                    }
                </div>;
            })
        }
    </div>;
};

TrendTooltip.propTypes = {
    loggers: PropTypes.array,
    dateFormat: PropTypes.object,
    timeStamp: PropTypes.number
};

export default TrendTooltip;
