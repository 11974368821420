import React from 'react';
import { map, sortBy } from 'lodash';
import { getAssetIcon } from 'helpers/icons';
import { allAssetTypeFilter } from 'helpers/filterHelper';


export const setOptions = (assets, translate) => {
    const options = [];

    const assetTypes = map(assets, (obj) => obj.assetType)
        .filter((item, index, arr) => arr.indexOf(item) === index);

    map(assetTypes, (assetType, indx) => {
        const label = translate(`ABB.Powertrain.Frontend.${assetType.toLowerCase()}Label`) || assetType;
        options.push({
            id: indx + 1,
            title: <>{getAssetIcon(assetType, { width: 16, height: 16 })} {label}</>,
            assetType,
            assetTypeLabel: label
        });
    });

    return [allAssetTypeFilter(translate), ...sortBy(options, [option => option?.assetTypeLabel])];
};
