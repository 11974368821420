import { filter, find } from 'lodash';
import { gatewayTypes } from 'helpers/constants';
import { matchesWithTheSearchFilter, passesFilter } from 'helpers/assetAndPowertrainFilterHelper';

export const filterPowertrainAssets = (powertrains, selectedPowertrain) => {
    return find(powertrains, (powertrain) => {
        return powertrain && powertrain.systemID === selectedPowertrain?.systemID;
    })?.components;
};

export const filterAllAssets = (assets, searchboxFilter, gatewayTypeFilter) => {
    return filter(assets, (asset) => {
        return passesFilter(searchboxFilter && searchboxFilter.length, matchesWithTheSearchFilter(asset, searchboxFilter?.toLowerCase()))
            && passesFilter(gatewayTypeFilter, gatewayTypeFilter === gatewayTypes.ALL || gatewayTypeFilter === asset.gatewayType);
    });
};
