import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';


const IconDegradedModeOne = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M2.17505 6.75L2.17505 17.175L19.05 17.175L19.05 6.75L2.17505 6.75Z' stroke={color} strokeLinejoin='bevel' />
		<path d='M19.0501 14.6999L21.0751 14.6999L21.6001 14.0999L21.6001 11.9249L21.6001 9.89985L21.0751 9.22485L19.0501 9.22485' stroke={color} strokeLinejoin='bevel' />
		<path d='M6.82471 8.69983L6.82471 15.2998' stroke={color} strokeLinejoin='bevel' />
		<path d='M4.2749 8.69983L4.2749 15.2998' stroke={color} strokeLinejoin='bevel' />
	</svg>;
};

IconDegradedModeOne.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};


export default IconDegradedModeOne;
