import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { Checkbox } from 'sharedComponents';


const Option = props =>
	<components.Option {...props}>
		<Checkbox
			checked={props.isSelected}
			width={'16px'}
			height={'16px'}
		/>
		<label>{props.label}</label>
	</components.Option>;


Option.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.any
	}),
	isSelected: PropTypes.bool,
	label: PropTypes.string
};

export default Option;
