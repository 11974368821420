import React from 'react';

import colors from 'theme/_colors.scss';


const IconClose = ({ width = 16, height = 16, color = colors.codGray }) => {
	return <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M12.71 4.71004L11.29 3.29004L8.00004 6.59004L4.71004 3.29004L3.29004 4.71004L6.59004 8.00004L3.29004 11.29L4.71004 12.71L8.00004 9.41004L11.29 12.71L12.71 11.29L9.41004 8.00004L12.71 4.71004Z' fill={color} />
	</svg>;

};

export default IconClose;
