import colors from 'theme/_colors.scss';
import { getTranslatedKPIName, translate } from 'helpers/translateHelper';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { HistogramTooltip } from 'sharedComponents/ChartComponents/Histogram';
import { firstDataColor } from 'helpers/constants';
import { getLabel } from './helpers';
import { afterChartReDrawStringData, chartLegendItemClick } from '../../../../helpers';
import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';

export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY];


const useChartConfig = (histograms, histogramConfig, histogramKpis, removeFn) => {
    const [options, setOptions] = useState({});
    useEffect(() => {
        const name = histogramConfig ? getTranslatedKPIName(histogramConfig) : null;
        const xUnit = histogramConfig?.xUnit ?? '';
        const yUnit = histogramConfig?.yUnit ?? '';
        const id = histogramKpis.findIndex((item) => item.displayName === name);
        const categories = [];
        const data = [];
        const sortedData = histograms.sort((a, b) => a.start - b.start);
        let maxValue = 0;

        sortedData.forEach((c) => {
            categories.push(getLabel(c));
            data.push(c.value);
            if (c.value > maxValue) {
                maxValue = c.value;
            }
        });
        setOptions({
            chart: {
                type: 'column',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                zoomType: 'y',
                events: {
                    redraw: function (chartInstance) {
                        afterChartReDrawStringData(chartInstance.target.container.id, removeFn);
                    }
                },
            },
            title: {
                text: ''
            },
            xAxis: data.length ? [
                {
                    categories,
                    startOnTick: true,
                    endOnTick: true,
                    title: {
                        text: xUnit,
                    },
                    gridLineWidth: 1,
                    id: 'xAxis',
                }
            ] : [],
            yAxis: {
                max: Math.ceil(maxValue / 10) * 10,
                tickInterval: 10,
                title: {
                    text: yUnit,
                    rotation: 270,
                },
                gridLineWidth: 1,
                id: 'yAxis',
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 1,
                    groupPadding: 0,
                    shadow: false
                },
                series: {
                    events: {
                        legendItemClick: chartLegendItemClick
                    }
                }
            },
            lang: {
                noData: renderToStaticMarkup(<ChartNoDataText
                    title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                    text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />)
            },
            noData: {
                useHTML: true
            },
            series: data.length ? [
                {
                    type: 'column',
                    name,
                    data,
                    id,
                    color: firstDataColor
                }
            ] : [],
            tooltip: {
                backgroundColor: 'white',
                style: {
                    fontSize: '14px',
                    lineHeight: '20px'
                },
                formatter: function () {
                    return renderToStaticMarkup(
                        <HistogramTooltip
                            name={this.series.name}
                            x={this.x}
                            y={this.y}
                            {...{ xUnit, yUnit }}
                        />);
                }
            },
            legend: {
                useHTML: true,
                layout: 'horizontal',
                align: 'right',
                itemStyle: {
                    fontFamily: 'ABBvoice-Regular',
                    fontSize: '14px',
                },
                itemDistance: 25,
                symbolHeight: 8,
                symbolWidth: 8,
                symbolRadius: 0,
                itemMarginTop: 10,
                labelFormatter: function () {
                    return renderToStaticMarkup(
                        <RemovableLegend
                            signalSourceName={this.userOptions.component}
                            legend={this}
                        />);
                }
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        states: {
                            hover: {
                                fill: colors.white,
                                stroke: 'none'
                            },
                            select: {
                                fill: colors.white,
                                select: 'none'
                            }
                        }
                    }
                }
            },
            exporting: {
                enabled: false,
            }
        });
    }, [histograms, histogramConfig]);

    return options;
};

export default useChartConfig;
