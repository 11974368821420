import React from 'react';
import './style.scss';
import { getConditionClass, getSelectedClass } from './helpers';
import PropTypes from 'prop-types';
import { IconDropUpMo } from 'svgIcons/MotionPortalIcons';
import { ContextMenu } from 'sharedComponents';
import { ConditionIconsRow } from '../ConditionIconsRow';
import Tippy from '@tippy.js/react';

const CardComponent = ({
    condition,
    statuses,
    headerText,
    bodyText,
    contextMenuConfig,
    onCardClick,
    children,
    selected = null
}) => {
    return <>
        <div className={`overview-card ${getConditionClass(condition)} ${getSelectedClass(selected)}`} onClick={onCardClick} >
            <div className='overview-card__status-border'></div>
            <div className='overview-card__header'>
                <div className='overview-card__header__text'>{headerText}</div>
                <div className='overview-card__header__icons'>
                    {ConditionIconsRow(statuses)}
                    {contextMenuConfig &&
                        <span className='context-menu' onClick={event => event.stopPropagation()}>
                            <ContextMenu {...contextMenuConfig} />
                        </span>
                    }
                </div>
            </div>
            <div className='overview-card__body'>
                <Tippy
                    className='overview-card_tippy'
                    content={bodyText}
                    theme='light-border'
                    trigger='mouseenter focus'
                    maxWidth={300}
                    placement='top-start'
                    delay={[500, 10]}
                >
                    <div>{bodyText}</div>
                </Tippy>
            </div>
            {selected &&
                <div className='overview-card__dropdown'>
                    <div className='overview-card__dropdown__icon'>
                        <IconDropUpMo width={14} height={9} />
                    </div>
                </div>}
        </div>
        {selected &&
            <div className='overview-card__details'>
                {children}
            </div>
        }
    </>;
};

CardComponent.propTypes = {
    condition: PropTypes.number,
    statuses: PropTypes.object,
    headerText: PropTypes.string,
    bodyText: PropTypes.string,
    contextMenuConfig: PropTypes.object,
    onCardClick: PropTypes.func,
};

export default CardComponent;
