import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconConfig from 'svgIcons/MotionPortalIcons/IconConfig';

import { actionTypes, assetMenuItems } from '../../../../../../../Header/constants';

const UserLimitsButton = (props) => {

    const dispatch = useDispatch();
    const { kpi } = props;
    const { isEditable, isPlottableToTrendChart } = kpi;

    const openUserLimitsConfig = (e) => {
        e.stopPropagation();
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: assetMenuItems.USER_LIMITS });
    };

    return <>
        {
            isEditable && isPlottableToTrendChart ?
                <span onClick={(e) => openUserLimitsConfig(e)}>
                    <IconConfig />
                </span> : null
        }
    </>;
};

UserLimitsButton.propTypes = {
    kpi: PropTypes.object
};

export default UserLimitsButton;
