import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';

import { IconInfo } from 'svgIcons/MotionPortalIcons';
import { GeneralButton } from 'sharedComponents';

const ButtonRow = (props) => {
    const { t: translate } = useTranslation();
    const { isSuggestionOpen,
        autoDetectionIntervals,
        selectedKpi,
        saveSelectedAlertAlarmOffsetConfig,
        resetDirtyFlag } = props;
    const { editState } = selectedKpi;
    const isSaveDisabled = editState ? !editState.isDirty || !editState.isAlertValid || !editState.isAlarmValid : false;
    const enableAutoDetection = autoDetectionIntervals.length > 0;

    return <div className='alert-alarm-dialog-row justify-content-end'>
        <div className='d-flex align-items-center'>
            {
                isSuggestionOpen && <>
                    <GeneralButton
                        className='cancel-suggest-button'
                        type='discreet'
                        onClick={() => {
                            props.setIsSuggestionOpen(!isSuggestionOpen);
                        }}
                        text={translate('ABB.Powertrain.Frontend.cancelLabel')}
                    />
                </>
            }
            {
                !isSuggestionOpen && enableAutoDetection && <>
                    <GeneralButton
                        className='suggest-button'
                        type='discreet'
                        onClick={() => {
                            props.setIsSuggestionOpen(!isSuggestionOpen);
                        }}
                        text={translate('ABB.Powertrain.Frontend.btn-suggest')}
                    />
                    <div className='alert-alarm-info d-flex align-items-center'>
                        <Tippy
                            content={translate('ABB.Powertrain.Frontend.alertAlarmSelectIntervalDescription')}
                            interactive={true}
                            interactiveBorder={10}
                            animation='scale'
                            theme='light-border'
                            trigger='click'
                            maxWidth={300}
                            placement='left-end'
                        >
                            <div className='info-icon'>
                                <IconInfo width={'14px'} height={'14px'} />
                            </div>
                        </Tippy>
                    </div>
                </>
            }
            <GeneralButton
                className='save-button'
                type='primary'
                disabled={isSaveDisabled}
                onClick={() => {
                    saveSelectedAlertAlarmOffsetConfig(selectedKpi)
                        .then(() => resetDirtyFlag());
                }}
                text={translate('ABB.Powertrain.Frontend.saveButton')}
            />
        </div>
    </div>;
};

ButtonRow.propTypes = {
    autoDetectionIntervals: PropTypes.array,
    isSuggestionOpen: PropTypes.bool,
    saveSelectedAlertAlarmOffsetConfig: PropTypes.func,
    selectedKpi: PropTypes.shape({
        editState: PropTypes.shape({
            isAlarmValid: PropTypes.bool,
            isAlertValid: PropTypes.bool,
            isDirty: PropTypes.bool
        })
    }),
    setIsSuggestionOpen: PropTypes.func,
};

export default ButtonRow;
