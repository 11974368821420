import { map, sortBy, uniqBy, forEach, keys } from 'lodash';
import { dataSources } from 'helpers/constants';
import CapabilityHelper from 'helpers/capabilityHelper';
import { dateKind, causeOfEvents } from '../constants';
import { ALL_VALUE } from './constants';


export const getDateKindOptions = (translate) => {
    return map(dateKind, (id, key) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterDateKind${key}`) };
    });
};

export const getDefaultTimeZoneValue = (timeZone, translate) => {
    const defaultKey = Object.keys(dateKind).find(key => dateKind[key] === timeZone);
    return defaultKey ? { id: timeZone, title: translate(`ABB.Powertrain.Frontend.eventListFilterDateKind${defaultKey}`) } : null;
};

export const getCauseOfDriveEventsOptions = (translate, includeAll = true) => {
    const listOfCauses = map(causeOfEvents, (id) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterDriveEvents${id}`) };
    });

    if (!includeAll) {
        listOfCauses.shift();
    }

    return sortBy(listOfCauses, [entry => entry.title.toLowerCase()]);
};

export const getDefaultCauseOfDriveEvent = (id, translate) => {
    return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterDriveEvents${id}`) };
};

const isAssetDisabledByCapability = (capabilities, asset) => {
    if (asset && keys(capabilities)?.length) {
        const capability = capabilities[asset.componentID];
        const capabilityHelper = new CapabilityHelper({ data: capability }, asset);
        return !(capabilityHelper.checkEventListMultipleAssetsCapability() &&
            capabilityHelper.hasEventListCapability());
    }

    return true;
};

export const getFormattedAssets = (capabilities, assets, selectedAsset) => {
    const assetList = [];

    forEach(assets, (asset) => {
        if (asset?.componentType?.dataSourceType === dataSources.CMD4) {
            assetList.push({
                value: asset.componentID,
                label: asset.componentName,
                isDisabled: isAssetDisabledByCapability(capabilities, asset) ||
                    selectedAsset.componentID === asset.componentID,
                componentType: asset.componentType,
                componentID: asset.componentID
            });
        }
    });

    return uniqBy(sortBy(assetList, (asset) => {
        return (asset.label || '').toLowerCase();
    }), 'componentID'
    );
};

export const getAllAssetsOption = (translate) => {
    return {
        value: ALL_VALUE,
        label: translate('ABB.Powertrain.Frontend.eventFilterallAssetsOption'),
        isDisabled: false
    };
};
