import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import { apiCallStatus } from 'helpers/constants';

export const getLoadingSummary = (loadingStatus) => {
    let loadingSummary = loadingStatus;

    if (isArray(loadingStatus)) {
        loadingSummary = null;

        forEach(loadingStatus, (loadingStatusValue) => {
            if (loadingStatusValue === apiCallStatus.LOADING) {
                loadingSummary = apiCallStatus.LOADING;
                return false;
            }
            else if (loadingStatusValue === apiCallStatus.ERROR) {
                loadingSummary = apiCallStatus.ERROR;
            }
            else if (loadingSummary !== apiCallStatus.ERROR) {
                loadingSummary = apiCallStatus.SUCCESS;
            }
        });
    }

    return loadingSummary;
};
