import { createSelector } from 'reselect';
import { getSensorFeature } from '../helpers';
import { sensorFeatureKeys } from '../../../constants';

const getLoadingStatuses = ({ operationalParameters, sensorProperties }) => {
    return [operationalParameters.loadMeasurementLoadingStatus,
    sensorProperties.sensorProperties.loadingStatus,
    sensorProperties.sensorFeatures.loadingStatus];
};

const getOnDemandDataCollectionRequestFeature = ({ sensorProperties }) => {
    return getSensorFeature(sensorProperties.sensorFeatures?.data, sensorFeatureKeys.OnDemandDataCollectionRequest);
};

const getOnDemandDataCollectionResponseMessageFeature = ({ sensorProperties }) => {
    return getSensorFeature(sensorProperties.sensorFeatures?.data, sensorFeatureKeys.OnDemandDataCollectionResponseMessage);
};

export const getModel = createSelector([
    getLoadingStatuses,
    getOnDemandDataCollectionRequestFeature,
    getOnDemandDataCollectionResponseMessageFeature
],
    (
        loadingStatuses,
        onDemandDataCollectionRequest,
        onDemandDataCollectionResponseMessage,
    ) => {
        return {
            loadingStatuses,
            onDemandDataCollectionRequest,
            onDemandDataCollectionResponseMessage
        };
    });
