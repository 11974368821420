import { createSelector } from 'reselect';
import { getActiveDetailedInformationTab } from '../../selectors';

const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const getSelectedScatterKPIData = ({ operationalParameters }) => {
    return operationalParameters.scatter.data;
};

const getScatterDataAndConfig = createSelector([
    getSelectedScatterKPIData
],
    (data,) => {
        return data.length ?
            {
                scatterData: data[0].values,
                scatterDataConfiguration: data[0].config
            }
            : {
                scatterData: [],
                scatterDataConfiguration: null
            };
    });

const getScatterLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters.scatter.loadingStatus;
};

const getScatterKpisData = ({ operationalParameters }) => {
    return operationalParameters.scatterKpis.data;
};

const getScatterKpisLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters.scatterKpis.loadingStatus;
};

const getSelectedKpi = ({ operationalParameters }) => {
    return operationalParameters.selectedScatterKPI;
};

export const getModel = createSelector([
    getActiveDetailedInformationTab,
    getDateSelection,
    getScatterDataAndConfig,
    getScatterLoadingStatus,
    getScatterKpisData,
    getScatterKpisLoadingStatus,
    getSelectedKpi,
],
    (
        activeDetailedInformationTab,
        dateSelection,
        scatterDataAndConfiguration,
        scatterDataLoadingStatus,
        scatterKpis,
        scatterKpisLoadingStatus,
        selectedKpi,
    ) => {
        return {
            activeDetailedInformationTab,
            dateSelection,
            ...scatterDataAndConfiguration,
            scatterDataLoadingStatus,
            scatterKpis,
            scatterKpisLoadingStatus,
            selectedKpi,
        };
    });
