export const roundNumber = (num, scale) => {
    if (!scale) {
        scale = 0;
    }
    if (('' + num).toLowerCase().indexOf('e') < 0) {
        return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
        const arr = ('' + num).toLowerCase().split('e');
        let sig = '';
        if (+arr[1] + scale > 0) {
            sig = '+';
        }
        return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
    }
};

export const isWholeNumber = (num) => {
    const numString = ('' + num).toLowerCase();
    const decimalPointIndex = numString.indexOf('.');
    const exponentStartIndex = numString.indexOf('e');
    if (exponentStartIndex < 0) {
        return decimalPointIndex < 0;
    }
    else {
        const exponent = Number(numString.slice(exponentStartIndex + 1));
        if (decimalPointIndex > 0) {
            const fractionDigitCount = numString.substring(decimalPointIndex + 1, numString.lastIndexOf('e')).length;
            return exponent >= fractionDigitCount;
        } else {
            return exponent >= 0;
        }
    }
};
