import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconConfig from 'svgIcons/MotionPortalIcons/IconConfig';

import { actionTypes as headerActionTypes, assetMenuItems } from '../../../../../../../Header/constants';
import { actionTypes } from '../constants';


const AlertAlarmButton = (props) => {

    const dispatch = useDispatch();
    const { sectionId, kpi } = props;
    const { isCalibrationEnabled, isEditable, isPlottableToTrendChart, measurementTypeID } = kpi;

    const openAlertAlarmConfig = (e) => {
        e.stopPropagation();
        dispatch({ type: actionTypes.SET_SELECTED_TAB, selectedTabId: sectionId });
        dispatch({ type: actionTypes.SET_SELECTED_MEASUREMENT_TYPE_ID, selectedMeasurementTypeID: measurementTypeID });
        dispatch({ type: headerActionTypes.SELECT_MENU_ITEM, selectedMenuItem: assetMenuItems.ALERT_ALARM_OFFSET });
    };

    return <>
        {
            (isEditable || isCalibrationEnabled) && isPlottableToTrendChart ?
                <span onClick={(e) => openAlertAlarmConfig(e)}>
                    <IconConfig />
                </span> : null
        }
    </>;
};

AlertAlarmButton.propTypes = {
    kpi: PropTypes.object
};

export default AlertAlarmButton;
