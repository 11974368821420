import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconDatePicker = ({ width = '14px', height = '15px', color = colors.codGray }) => {
	return <svg width={width} height={height} viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M5.00001 10V12H3.00001V10H5.00001ZM8.00001 10V12H6.00001V10H8.00001ZM5.00001 7V9H3.00001V7H5.00001ZM8.00001 7V9H6.00001V7H8.00001ZM11 7V9H9.00001V7H11ZM12 2V0H10V2H4.00001V0H2.00001V2H1.39276e-05V14.24C-0.00142693 14.3419 0.0179168 14.443 0.0568641 14.5372C0.0958115 14.6314 0.153545 14.7167 0.22654 14.7878C0.299536 14.8589 0.386261 14.9144 0.481421 14.9509C0.576581 14.9874 0.67818 15.0041 0.780014 15H13.22C13.3218 15.0041 13.4234 14.9874 13.5186 14.9509C13.6138 14.9144 13.7005 14.8589 13.7735 14.7878C13.8465 14.7167 13.9042 14.6314 13.9432 14.5372C13.9821 14.443 14.0015 14.3419 14 14.24V2H12ZM13 11.94V14H1.00001V5H13V11.94Z' fill={color} />
	</svg>;
};

IconDatePicker.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	cutoutColor: PropTypes.string,
	viewBox: PropTypes.string
};

export default IconDatePicker;
