import {
    sortBy, isNil, map, isArray,
    transform, difference, forEach, isEmpty
} from 'lodash';

import { getTranslatedKPIName, getTranslatedKPINameV2 } from 'helpers/translateHelper';
import { isWholeNumber, roundNumber } from 'helpers/numberHelper';
import { chartColors } from 'helpers/constants';

export const setColor = (list) => {
    const usedColors = transform(list, (acc, x) => {
        if (x.color) {
            acc.push(x.color);
        }
    }, []);

    const availableColors = difference(chartColors, usedColors);
    forEach(list, (item) => {
        if (!item.color) {
            item.color = availableColors.length ? availableColors.splice(0, 1)[0] : null;
        }
    });
    return list;
};

export const createParamMeasurementComponentObj = (measurement, componentObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return [{
        measurementTypes: map(measurementArray, (kpi) => {
            return {
                measurementTypeID: kpi.measurementTypeID,
                displayName: kpi.displayName,
                measurementTypeIdentifier: kpi.measurementTypeIdentifier,
                measurementTypeCode: kpi.measurementTypeCode,
                measurementTypeKey: kpi.measurementTypeKey,
            };
        }),
        componentID: componentObj.componentID,
        componentTypeID: componentObj.componentType.typeID,
        dataSourceType: componentObj.componentType.dataSourceType
    }];
};

// eslint-disable-next-line import/no-unused-modules
export const createParamMeasurementComponentObjV2 = (measurement, componentObj) => {
    const measurementArray = isArray(measurement) ? measurement : [measurement];
    return map(measurementArray, (kpi) => {
        return {
            assetId: componentObj?.id,
            timeseries: {
                timeseriesKey: kpi?.timeseriesKey
            }
        };
    });
};

export const mapMeasurementToConfigFormat = (mKpi) => {
    return {
        measurementTypeCode: mKpi.measurementTypeCode,
        displayName: mKpi.displayName,
        measurementTypeIdentifier: mKpi.measurementTypeIdentifier,
        isVisible: mKpi.isVisible,
        precision: mKpi.measurementTypePrecision,
        measurementTypeID: mKpi.measurementTypeID,
        typeName: mKpi.displayName,
        unitGroupID: mKpi.measurementTypeUnitGroupID
    };
};

const getMeasurementTypeValue = (measurementType) => {
    let measurementTypeValue = '-';
    if (!isNil(measurementType.measurementTypeValue)) {
        measurementTypeValue = parseFloat(measurementType.measurementTypeValue);
        if (!isNaN(measurementTypeValue)) {
            measurementTypeValue = measurementType.isCalibrationEnabled && measurementType.measurementCalibration
                ? measurementTypeValue + measurementType.measurementCalibration
                : measurementTypeValue;
            measurementTypeValue = isWholeNumber(measurementTypeValue) ? measurementTypeValue :
                roundNumber(measurementTypeValue, measurementType.measurementTypePrecision)
                    .toFixed(measurementType.measurementTypePrecision);
            measurementTypeValue = measurementType.measurementTypeUnit
                ? measurementTypeValue + ' ' + measurementType.measurementTypeUnit
                : measurementTypeValue.toString();
        }
    }
    return measurementTypeValue;
};

const getMeasurementTypeValueV2 = (measurementType) => {
    let measurementTypeValue = '-';
    if (!isNil(measurementType.timeseriesLastValue)) {
        measurementTypeValue = typeof measurementType.timeseriesLastValue === 'string' ? measurementType.timeseriesLastValue : parseFloat(measurementType.timeseriesLastValue);
        if (!isNaN(measurementTypeValue)) {
            measurementTypeValue = measurementType.isCalibrationEnabled && measurementType.measurementCalibration
                ? measurementTypeValue + measurementType.measurementCalibration
                : measurementTypeValue;
            measurementTypeValue = isWholeNumber(measurementTypeValue) ? measurementTypeValue :
                roundNumber(measurementTypeValue, measurementType.timeseriesPrecision)
                    .toFixed(measurementType.timeseriesPrecision);
            measurementTypeValue = measurementType.unit
                ? measurementTypeValue + ' ' + measurementType.unit
                : measurementTypeValue.toString();
        } else {
            measurementTypeValue = `${measurementType.timeseriesLastValue}`;
        }
    }
    return measurementTypeValue;
};

export const mapTrendKpiList = (trendKPIs) => {
    const sections = transform(sortBy(trendKPIs, g => g.measurementTypeSequenceNo), (acc, kpiSection) => {
        const data = {};

        kpiSection?.measurementTypes?.forEach((mType) => {
            if (mType.isVisible) {
                data[mType.measurementTypeIdentifier] = {
                    ...mType,
                    label: getTranslatedKPIName(mType),
                    value: mType.measurementTypeValue,
                    displayValue: getMeasurementTypeValue(mType)
                };
            }
        });

        acc.push({
            sectionId: kpiSection.measurementTypeGroupCode ? kpiSection.measurementTypeGroupCode : kpiSection.measurementTypeGroupName,
            data
        });
    }, []);

    return sections;
};
// eslint-disable-next-line import/no-unused-modules
export const mapTrendKpiListV2 = (trendKPIs) => {
    const sections = transform(trendKPIs, (acc, kpiSection) => {
        const data = {};

        kpiSection?.timeseries?.forEach((mType) => {
            //if (mType.isVisible) {
            data[mType.timeseriesKey] = {
                ...mType,
                label: getTranslatedKPINameV2(mType),
                value: mType?.timeseriesLastValue,
                displayValue: getMeasurementTypeValueV2(mType)
            };
            // }
        });

        acc.push({
            sectionId: kpiSection.timeseriesGroupCode ? kpiSection.timeseriesGroupCode : kpiSection.timeseriesGroupName,
            data
        });
    }, []);

    return sections;
};
export const getFlatAvailableKPIList = (kpis) => {
    return kpis?.reduce((allKpiData, kpi) => {
        if (kpi?.data) {
            return { ...allKpiData, ...kpi.data };
        }
        return allKpiData;
    }, {}) || {};
};

const getKPIConfigForChart = (kpi) => {
    return kpi && {
        appendCodeToTranslation: kpi.appendCodeToTranslation,
        displayName: kpi.displayName,
        measurementTypeCode: kpi.measurementTypeCode,
        measurementTypeIdentifier: kpi.measurementTypeIdentifier,
        measurementTypeKey: kpi.measurementTypeKey,
        offset: kpi.measurementCalibration || 0,
        precision: kpi.measurementTypePrecision,
        unit: kpi.measurementTypeUnit
    };
};
const getKPIConfigForChartV2 = (kpi) => {
    return !isEmpty(kpi) && {
        appendCodeToTranslation: kpi.appendCodeToTranslation,
        displayName: getTranslatedKPINameV2(kpi),
        measurementTypeCode: kpi.timeseriesGroupCode,
        measurementTypeIdentifier: kpi.timeseriesKey,
        measurementTypeKey: kpi.dataType,
        offset: kpi.measurementCalibration || 0,
        precision: kpi.timeseriesPrecision,
        unit: kpi.unit
    };
};

export const formatChartData = (chartList, availableKPIs) => {
    const kpiList = getFlatAvailableKPIList(availableKPIs);
    // map through charts
    const formattedChartData = map(chartList, (chartData) => {
        return {
            chartID: chartData.chartID,
            chartName: chartData.chartName,
            loadingStatus: chartData.loadingStatus,
            zoom: chartData.zoom,
            // map through KPIs of the chart
            data: map(chartData.kpiList, (kpiData) => {
                return {
                    color: kpiData.color,
                    kpiIdentifier: kpiData.kpiIdentifier,
                    config: getKPIConfigForChart(kpiList[kpiData.kpiIdentifier]),
                    values: kpiData.values
                };
            })
        };
    });

    return formattedChartData;
};

// eslint-disable-next-line import/no-unused-modules
export const formatChartDataV2 = (chartList, availableKPIs) => {
    const kpiList = getFlatAvailableKPIList(availableKPIs);
    // map through charts
    const formattedChartData = map(chartList, (chartData) => {
        return {
            chartID: chartData.chartID,
            chartName: chartData.chartName,
            loadingStatus: chartData.loadingStatus,
            zoom: chartData.zoom,
            // map through KPIs of the chart
            data: map(chartData.kpiList, (kpiData) => {
                return {
                    color: kpiData.color,
                    kpiIdentifier: kpiData.kpiIdentifier,
                    config: getKPIConfigForChartV2(kpiList[kpiData.kpiIdentifier]),
                    values: kpiData.values
                };
            })
        };
    });

    return formattedChartData;
};

