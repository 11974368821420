import { createSelector } from 'reselect';

const getSelectedEvent = ({ eventLog }) => {
    return eventLog.selectedEvent;
};

const getEventComments = ({ eventDetailsSS }) => {
    return eventDetailsSS.eventComments;
};

const getEventClosingLoadingStatus = ({ eventLog }) => {
    return eventLog.eventClosingLoadingStatus;
};

export const getModel = createSelector([
    getSelectedEvent,
    getEventComments,
    getEventClosingLoadingStatus,
], (
    selectedEvent,
    eventComments,
    eventClosingLoadingStatus,
) => {
    return {
        selectedEvent,
        eventComments,
        eventClosingLoadingStatus,
    };
});
