import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconNotification = ({ width = 15, height = 15 }) => {
	return <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<mask id='path-1-inside-1' fill='white'>
			<path fillRule='evenodd' clipRule='evenodd' d='M8.12602 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H5L7 15L8 13.5L9 12H11C12.1046 12 13 11.1046 13 10V7.87398C12.6804 7.95625 12.3453 8 12 8C9.79086 8 8 6.20914 8 4C8 3.6547 8.04375 3.31962 8.12602 3Z' />
		</mask>
		<path fillRule='evenodd' clipRule='evenodd' d='M8.12602 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H5L7 15L8 13.5L9 12H11C12.1046 12 13 11.1046 13 10V7.87398C12.6804 7.95625 12.3453 8 12 8C9.79086 8 8 6.20914 8 4C8 3.6547 8.04375 3.31962 8.12602 3Z' fill={colors.malibu} />
		<path d='M8.12602 3L9.09445 3.24926L9.41599 2H8.12602V3ZM5 12L5.83205 11.4453L5.53518 11H5V12ZM7 15L6.16795 15.5547L7 16.8028L7.83205 15.5547L7 15ZM9 12V11H8.46482L8.16795 11.4453L9 12ZM13 7.87398H14V6.58401L12.7507 6.90555L13 7.87398ZM8.12602 2H3V4H8.12602V2ZM3 2C1.34315 2 0 3.34315 0 5H2C2 4.44772 2.44772 4 3 4V2ZM0 5V10H2V5H0ZM0 10C0 11.6569 1.34315 13 3 13V11C2.44772 11 2 10.5523 2 10H0ZM3 13H5V11H3V13ZM4.16795 12.5547L6.16795 15.5547L7.83205 14.4453L5.83205 11.4453L4.16795 12.5547ZM7.83205 15.5547L8.83205 14.0547L7.16795 12.9453L6.16795 14.4453L7.83205 15.5547ZM8.83205 14.0547L9.83205 12.5547L8.16795 11.4453L7.16795 12.9453L8.83205 14.0547ZM9 13H11V11H9V13ZM11 13C12.6569 13 14 11.6569 14 10H12C12 10.5523 11.5523 11 11 11V13ZM14 10V7.87398H12V10H14ZM12.7507 6.90555C12.5119 6.96703 12.2605 7 12 7V9C12.4301 9 12.8489 8.94547 13.2493 8.84242L12.7507 6.90555ZM12 7C10.3431 7 9 5.65685 9 4H7C7 6.76142 9.23858 9 12 9V7ZM9 4C9 3.73949 9.03297 3.48813 9.09445 3.24926L7.15758 2.75074C7.05453 3.15111 7 3.56991 7 4H9Z' fill={colors.malibu} mask='url(#path-1-inside-1)' />
		<circle cx='12' cy='4' r='3' fill={colors.malibu} />
	</svg>
		;
};

IconNotification.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconNotification;
