import { AuthenticationApi } from 'api';
import indexOf from 'lodash/indexOf';
import find from 'lodash/find';

import logger from './logger';
import storageHelper from './localStorageHelper';
import config from '../config/appConfig';
import { unauthenticatedRouteNames, routesWithInfinitSession } from './constants';
import { history, store } from '../boot-client';
import cookieHelper from './cookieHelper';


/**
 * Autentication related helper functions
 */
const authHelper = {
    /**
     * Get auth tokens form local storage
     */
    getAuthToken: () => {
        let autTokens;
        const tokens = storageHelper.getItem('authTokens');
        if (tokens && tokens.length > 0) {
            const SSPToken = find(tokens, function (obj) { return obj.dataSourceType === 1; }); // eslint-disable-line
            autTokens = {
                SSPToken: SSPToken ? SSPToken.authenticationToken : null
            };
        }
        return autTokens;
    },

    /**
     * Get common sensor refresh tokens form local storage
     */
    getCommonSensorRefreshToken: () => {
        let refreshTokens = null;
        const user = storageHelper.getItem('user');
        if (user?.refreshToken) {
            refreshTokens = {
                'dataSourceType': config.dataSourcesType.CommonSensor,
                'refreshToken': user.refreshToken
            };
        }
        return refreshTokens;
    },

    getAccessToken: () => {
        const { 'openIDIssuer': openIdIssuer, openIDClientID } = storageHelper.nonEncrypted.getItem('environmentProperties') || {};
        const { 'access_token': accessToken } = storageHelper.nonEncrypted.getItem(`authenticated_user:${openIdIssuer}:${openIDClientID}`) || {};
        return accessToken;
    },


    /**
     * Update tokens in user object, stored in local storage.
     * @return New user object with updated Auth Token
     */
    getUserWithUpdatedToken: (tokens) => {
        const authTokens = storageHelper.getItem('authTokens');
        if (authTokens) {
            for (const i in tokens) {
                for (const j in authTokens) {
                    if (tokens[i].authenticationToken && tokens[i].dataSourceType === authTokens[j].dataSourceType) {
                        authTokens[j] = tokens[i];
                        break;
                    }
                }
            }
            return authTokens;
        }
        else {
            logger.error('Update tokens failed!');
        }
    },

    /**
     * Get token expiration dates
     */
    getTokensData: () => {
        const authTokens = storageHelper.getItem('authTokens');
        return authTokens?.length ? authTokens : null;
    },

    /**
    * Get summed validity of all tokens
    */
    areAllTokensValid: () => {
        const authTokens = storageHelper.getItem('authTokens');
        const tokens = authTokens?.length ? authTokens : null;

        if (!tokens) {
            return false;
        }
        const tokenValidities = tokens.map((token) => {
            const expDate = new Date(token.expiration).getTime();
            const msUntilExpiration = expDate - new Date().getTime();

            return msUntilExpiration > 0;
        });
        return tokenValidities.every(isValid => isValid === true);
    },

    shouldEndSession: () => {
        const loginTimestamp = storageHelper.getItem('loginTimestamp');
        const currentTimestamp = new Date().getTime();

        return loginTimestamp + config.userSessionLength < currentTimestamp;
    },

    //Need to perform cleanup also if local storage user is present, but userManager is not
    //Otherwise you will always get notification on the login page about being force logged out.
    forceLogoutUser: () => {
        const userManager = store.getState().portal.userManager;
        const user = storageHelper.getItem('user');
        const performCleanup = !!userManager || !!user;

        if (userManager) {
            userManager.removeUser();
        }
        if (user) {
            storageHelper.removeItemForUser('broadcastedMessage');
            storageHelper.removeItem('user');
            storageHelper.removeItem('authTokens');
            storageHelper.removeItem('loginTimestamp');
            storageHelper.removeItem('measurementUnitGroups');
            storageHelper.nonEncrypted.removeItem('environmentProperties');
        }

        return performCleanup;
    },

    isValidIAMToken: () => {
        let ret = true;
        if (authHelper.shouldEndSession()) {
            const currentRoute = history.location.pathname;
            ret = !authHelper.doesPageNeedTokenRefresh(currentRoute);
        }
        return ret;
    },
    handleExpiredToken: () => {
        const currentRoute = history.location.pathname;
        authHelper.doesPageNeedTokenRefresh(currentRoute) && authHelper.redirectToLogin(currentRoute);
    },

    /**
    * Refresh the auth tokens
    */
    refreshToken: (dataSource) => {
        const refreshToken = [];
        let CommonSensorToken;
        if (dataSource) {
            CommonSensorToken = authHelper.getCommonSensorRefreshToken();
            CommonSensorToken && refreshToken.push(CommonSensorToken);
        } else {
            logger.error('Can\'t invoke /refresh API, dataSource is empty');
        }
        return AuthenticationApi.refresh(refreshToken).then((response) => {
            if (response?.length) {
                const newTokens = authHelper.getUserWithUpdatedToken(response);
                if (newTokens) {
                    storageHelper.setItem('authTokens', newTokens);
                }
            } else {
                authHelper.handleExpiredToken();
            }
        });
    },

    doesPageNeedAuth: (pathname) => {
        return indexOf(unauthenticatedRouteNames, pathname) === -1;
    },

    doesPageNeedTokenRefresh: (pathname) => {
        return indexOf(routesWithInfinitSession, pathname) === -1;
    },

    redirectToLogin: (pathname) => {
        history.push('/Login?returnUrl=' + pathname + history.location.search);
    },

    logout: () => {
        const userManager = store.getState().portal.userManager;
        const currentRoute = history.location.pathname + history.location.search;

        cookieHelper.removeCookie('userLanguage');
        storageHelper.removeItem('user');
        storageHelper.removeItem('authTokens');
        storageHelper.removeItem('loginTimestamp');
        storageHelper.removeItem('measurementUnitGroups');
        storageHelper.setItem('returnUrl', currentRoute);

        userManager?.signoutRedirect();
    }
};

export default authHelper;
