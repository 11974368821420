import React from 'react';
import { Container } from 'react-bootstrap';
import { TrendSignalChart } from './TrendSignalChart';
import { TrendSignalKPIList } from './TrendSignalKPIList';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';
import './styles.scss';

const TrendSignalComponent = (props) => {
    const { model } = props;
    return <Container fluid id='assistance-trend-container'>
        <LoadingStatusWrapper useCustomAnchor transparent loadingStatus={[model.trendKPIsLoadingStatus, model.trendDataLoadingStatus]} >
            <TrendSignalChart asset={props.selectedAsset} />
            <TrendSignalKPIList asset={props.selectedAsset} />
        </LoadingStatusWrapper>
    </Container>;
};

TrendSignalComponent.propTypes = {
};


export default TrendSignalComponent;
