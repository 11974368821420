import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { addClientIdToTokens } from './helper';


class Auth {
	refresh(refreshTokens) {
		const endpoint = formatEndpoint(endpoints.authentication.refreshToken);
		return RestManager.put(endpoint, addClientIdToTokens(refreshTokens));
	}

	loginWithToken(IdToken, ClientID) {
		const endpoint = formatEndpoint(endpoints.authentication.loginWithToken);
		return RestManager.post(endpoint, { IdToken, ClientID });
	}
}

export default new Auth();
