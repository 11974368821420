import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconCancel = ({ width = '48px', height = '48px', color = colors.black, fillColor = 'none', viewBox = '0 0 48 48 ', showCircle = false }) => {
	return <svg width={width} height={height} viewBox={viewBox} xmlns='http://www.w3.org/2000/svg'>
		{showCircle &&
			<circle cx='23.65' cy='23.72' r='19.35' fill={fillColor} stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		}
		<line x1='15.1' y1='32.37' x2='32.41' y2='15.06' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth={fillColor !== 'none' ? '4' : '2'} />
		<line x1='15.1' y1='15.06' x2='32.41' y2='32.37' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth={fillColor !== 'none' ? '4' : '2'} />
	</svg>;
};

IconCancel.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	fillColor: PropTypes.string,
	showCircle: PropTypes.bool,
	viewBox: PropTypes.string
};

export default IconCancel;
