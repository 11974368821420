import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { UserLimitsApi, PlotApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';


const saveUserLimitsAction = getApiActionCreator(actionTypes.SAVE_USER_LIMITS);
const getPlotKPIListAction = getApiActionCreator(actionTypes.GET_PLOT_KPIS);
const getUserLimitsAction = getApiActionCreator(actionTypes.GET_USER_LIMITS);

export const getUserLimits = (component, forceReload) => {
    return (dispatch, getState) => {
        const state = getState();
        const { loadingStatus, data } = state.detailedInformationHeader.userLimits;
        let promise = Promise.resolve(data);
        if (!loadingStatus || forceReload) {
            dispatch(getUserLimitsAction(apiCallStatus.LOADING));
            promise = UserLimitsApi.getUserLimits(component)
                .then((res) => {
                    dispatch(getUserLimitsAction(apiCallStatus.SUCCESS, res));
                })
                .catch((err) => {
                    dispatch(getUserLimitsAction(apiCallStatus.ERROR, null, err));
                });
        }
        return promise;
    };
};


export const saveUserLimits = (component, defaultUserLimits, customUserLimits, eTag) => {
    return (dispatch) => {

        if (component && defaultUserLimits && customUserLimits) {
            dispatch(saveUserLimitsAction(apiCallStatus.LOADING));
            UserLimitsApi.saveUserLimits(component, defaultUserLimits, customUserLimits, eTag)
                .then(() => dispatch(getUserLimits(component, true)))
                .then(() => dispatch(saveUserLimitsAction(apiCallStatus.SUCCESS)))
                .catch((err) => {
                    dispatch(saveUserLimitsAction(apiCallStatus.ERROR, null, err));
                });
        }
    };
};

export const getPlotKPIList = (asset, plotType) => {

    return (dispatch) => {
        dispatch(getPlotKPIListAction(apiCallStatus.LOADING));
        PlotApi.getPlotKPIList(asset, plotType)
            .then((data) => {
                dispatch(getPlotKPIListAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getPlotKPIListAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
