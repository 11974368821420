import { createSelector } from 'reselect';
import { getGeneralFilteredAssets } from 'helpers/selectors';


const getSelectedEvent = ({ eventLog }) => {
	return eventLog.selectedEvent;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getIsExpanded = ({ eventLog }) => {
	return eventLog.isExpanded;
};

export const getModel = createSelector([
	getSelectedEvent,
	getGeneralFilteredAssets,
	getSelectedAsset,
	getIsExpanded
], (
	selectedEvent,
	generalFilteredAssets,
	selectedAsset,
	isExpanded
) => {
	return {
		selectedEvent,
		generalFilteredAssets,
		selectedAsset,
		isExpanded
	};
});


