import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { setColor } from '../../../../commonTrendHelpers';
import { MAX_TREND_SIGNALS } from '../../../../constants';
import { TREND_SELECT_HOURS_BEFORE_EVENT, TREND_SELECT_HOURS_AFTER_EVENT } from './constants';
import { getUTCDateString } from 'helpers/dateHelper';


export const getTrendSignalDateInterval = (event) => {
    const fromDate = new Date(event.occurredOn);
    const hour = fromDate.getHours();
    fromDate.setHours(hour - TREND_SELECT_HOURS_BEFORE_EVENT);
    const toDate = new Date(event.occurredOn);
    toDate.setHours(hour + TREND_SELECT_HOURS_AFTER_EVENT);
    return {
        from: getUTCDateString(fromDate),
        to: getUTCDateString(toDate)
    };
};

export const createEmptyTrendDataObject = (measurements, componentObj) => {
    const measurementArray = isArray(measurements) ? measurements : [measurements];

    const trendArrays = map(measurementArray, (measurement) => {
        return {
            componentID: componentObj.componentID,
            config: { ...measurement.config },
            dataSourceType: componentObj.componentType.dataSourceType,
            values: [],
        };
    });

    return trendArrays;
};

export const removeSelectedTrendKpiData = (selectedTrendDataArrays, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrendDataArrays, (trendDataArray) => trendDataArray.config.measurementTypeIdentifier === unselectedKPI.measurementTypeIdentifier);
    foundIndex > -1 && selectedTrendDataArrays.splice(foundIndex, 1);

    return selectedTrendDataArrays;
};

export const addSelectKpiData = (selectedTrendsData, newSelectedTrendKpiArray) => {
    if (!selectedTrendsData?.length && newSelectedTrendKpiArray?.length > 1) {
        selectedTrendsData = newSelectedTrendKpiArray;
        return setColor(selectedTrendsData);
    }

    const newSelectedTrend = newSelectedTrendKpiArray && newSelectedTrendKpiArray.length ? newSelectedTrendKpiArray[0] : [];
    if (!find(selectedTrendsData, (selectedTrend) => selectedTrend.config.measurementTypeIdentifier === newSelectedTrend.config.measurementTypeIdentifier)) {
        selectedTrendsData.push(newSelectedTrend);
        if (selectedTrendsData.length > MAX_TREND_SIGNALS) {
            selectedTrendsData.shift();
        }
    }
    selectedTrendsData = map(selectedTrendsData, (selectedTrend) => {
        const found = find(newSelectedTrendKpiArray, (newSelected) => newSelected.config.measurementTypeIdentifier === selectedTrend.config.measurementTypeIdentifier);
        if (found) {
            found.color = selectedTrend.color;
            return found;
        }
        else {
            return selectedTrend;
        }
    });
    selectedTrendsData?.length && setColor(selectedTrendsData);
    return selectedTrendsData;
};

export const getUniqueYScaling = (selectedTrends) => {
    let uniqueYScalingAvailable = false;

    if (selectedTrends.length > 1) {
        const unit = selectedTrends[0].config.unit || '';

        forEach(selectedTrends, (trendData) => {
            uniqueYScalingAvailable = trendData.config.unit === unit;
            return uniqueYScalingAvailable;
        });
    }

    return uniqueYScalingAvailable;
};
