import React, { useEffect } from 'react';

import { LoadingIndicator } from 'sharedComponents';

const LogoutComponent = ({ history }) => {

	useEffect(() => {
		history.push('Login');
	}, []);

	return (
		<LoadingIndicator text='Logging out...' />
	);
};

export default LogoutComponent;
