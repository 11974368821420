
import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconDrive = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M22.5 19.09L25.79 15.79L27.21 17.21L22.5 21.91L18.5 17.91L15.21 21.21L13.79 19.79L18.5 15.09L22.5
		19.09ZM17 13.5L14 15L11 13.5V6H17V13.5ZM15 8H13V10.62H15V8ZM20 27H8V4H20V13L22 15V2H6V27C6 27.5304 6.21071
		28.0391 6.58579 28.4142C6.96086 28.7893 7.46957 29 8 29V30H20V29C20.5304 29 21.0391 28.7893 21.4142 28.4142C21.7893
		28.0391 22 27.5304 22 27V25L20 23V27Z' fill={color} />
	</svg>;

};

IconDrive.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	viewBox: PropTypes.string
};
export default IconDrive;
