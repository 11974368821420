import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as componentActions from './actions';
import { getModel } from './selectors';
import TrendSignalComponent from './TrendSignalComponent';


const TrendSignalContainer = (props) => {

    return <TrendSignalComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...componentActions }, dispatch)
    };
};

const TrendSignalContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendSignalContainer);

export default TrendSignalContainerConn;
