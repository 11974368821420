import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconGearing = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M20 21.61L21.46 23.07L20.04 24.49L18.61 23C17.2895 24.0726 15.6901 24.7457 14 24.94V27H12V24.94C10.3099
        24.7457 8.7105 24.0726 7.39 23L5.93 24.49L4.51 23.07L6 21.61C4.92742 20.2895 4.25435 18.6901 4.06 17H2V15H4.06C4.25435
        13.3099 4.92742 11.7105 6 10.39L4.51 8.93L5.93 7.51L7.39 9C8.7105 7.92742 10.3099 7.25435 12 7.06V5H14V7.06C15.6901
        7.25435 17.2895 7.92742 18.61 9L20.07 7.54L21.49 8.96L20 10.39L18.6 11.82C17.7214 10.6398 16.4933 9.76646 15.0901
        9.32401C13.6869 8.88156 12.1799 8.8925 10.7833 9.35528C9.38667 9.81806 8.17137 10.7091 7.31001 11.902C6.44866
        13.0948 5.98506 14.5287 5.98506 16C5.98506 17.4713 6.44866 18.9052 7.31001 20.098C8.17137 21.2909 9.38667
        22.1819 10.7833 22.6447C12.1799 23.1075 13.6869 23.1184 15.0901 22.676C16.4933 22.2335 17.7214 21.3602 18.6
        20.18L20 21.61ZM13 12C13.7911 12 14.5645 12.2346 15.2223 12.6741C15.8801 13.1136 16.3928 13.7384 16.6955
        14.4693C16.9983 15.2002 17.0775 16.0044 16.9231 16.7804C16.7688 17.5563 16.3878 18.269 15.8284 18.8284C15.269
        19.3878 14.5563 19.7688 13.7804 19.9231C13.0044 20.0775 12.2002 19.9983 11.4693 19.6955C10.7384 19.3928 10.1136
        18.8801 9.67412 18.2223C9.2346 17.5645 9 16.7911 9 16C9 14.9391 9.42143 13.9217 10.1716 13.1716C10.9217 12.4214
        11.9391 12 13 12ZM13 14C12.6044 14 12.2178 14.1173 11.8889 14.3371C11.56 14.5568 11.3036 14.8692 11.1522 15.2346C11.0009
        15.6001 10.9613 16.0022 11.0384 16.3902C11.1156 16.7781 11.3061 17.1345 11.5858 17.4142C11.8655 17.6939 12.2219
        17.8844 12.6098 17.9616C12.9978 18.0387 13.3999 17.9991 13.7654 17.8478C14.1308 17.6964 14.4432 17.44 14.6629
        17.1111C14.8827 16.7822 15 16.3956 15 16C15 15.4696 14.7893 14.9609 14.4142 14.5858C14.0391 14.2107 13.5304 14
        13 14ZM28.29 13.62L25.66 16.25L22.34 12.92L18.29 16.92L19.71 18.34L22.34 15.7L25.66 19.03L29.71 15.03L28.29 13.62Z' fill={color} />
    </svg>;
};

IconGearing.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconGearing;
