import { actionTypes } from './constants';
import {
    addNewPageToPowertrainList,
    updatePowertrainById
} from './helpers';


const initialState = {
    powertrains: {
        data: [],
        loadingStatus: null,
        pageNumber: 1,
        hasMore: true
    },
    selectedPowertrain: null,
    searchboxFilter: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        case actionTypes.GET_POWERTRAINS: {
            return {
                ...state,
                powertrains: addNewPageToPowertrainList(state.powertrains, action)
            };
        }
        case actionTypes.SET_SELECTED_POWERTRAIN: {
            return {
                ...state,
                selectedPowertrain: action.powertrain
            };
        }
        case actionTypes.DELETE_POWERTRAIN: {
            return {
                ...state,
                powertrains: {
                    ...state.powertrains,
                    loadingStatus: action.loadingStatus
                },
                selectedPowertrain: null
            };
        }
        case actionTypes.ADD_POWERTRAIN: {
            return {
                ...state,
                powertrains: {
                    ...state.powertrains,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case actionTypes.EDIT_POWERTRAIN: {
            return {
                ...state,
                powertrains: updatePowertrainById(state.powertrains, action)
            };
        }
        case actionTypes.SET_SEARCHBOX_FILTER: {
            return {
                ...state,
                searchboxFilter: action.searchboxFilter,
                selectedPowertrain: null
            };
        }
        default:
            return state;
    }
};
