import { BlobServiceClient } from '@azure/storage-blob';

export const containers = {
    BULK_UPLOAD: 'bulk-upload'
};

export const uploadFileToBlobStorage = (blobURL, blobContainer, blobname, file, size) => {
    const blobServiceClient = new BlobServiceClient(blobURL);
    const containerClient = blobServiceClient.getContainerClient(blobContainer);
    const blockBlobClient = containerClient.getBlockBlobClient(blobname);
    return blockBlobClient.upload(file, size);
};
