import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingStatusWrapper, ToggleButton } from 'sharedComponents';
import { unitStandards } from './constants';
import { getUnitToggleOptions } from './helpers';

import './style.scss';


const UnitsComponent = ({
    model: { changeableUnits, loadingStatus },
    actions
}) => {
    const { t: translate } = useTranslation();
    return <div className='user-profile-settings-content component_units'>
        <div className='user-profile-settings-content-header'>{translate('ABB.Powertrain.Frontend.userProfileSettingUnits')}</div>
        <div>
            <LoadingStatusWrapper
                loadingStatus={loadingStatus}
                fullScreen
                transparent
            >
                <ul className='units'>
                    <li className='unit'>
                        <div className='unit__label'>
                            {translate('ABB.Powertrain.Frontend.userProfileSwitchAllLabel')}
                        </div>
                        <div className='unit__toggle--all'>
                            <div className='unit__toggle--all--left' onClick={() => actions.selectUnitStandard(unitStandards.Metric)}>
                                {translate('ABB.Powertrain.Frontend.userProfileAllToMetric')}
                            </div>
                            <div className='unit__toggle--all--right' onClick={() => actions.selectUnitStandard(unitStandards.Imperial)}>
                                {translate('ABB.Powertrain.Frontend.userProfileAllToImperial')}
                            </div>
                        </div></li>
                    {changeableUnits?.map((group) =>
                        <li key={group.measurementUnitGroup.id} className='unit'>
                            <div className='unit__label'>
                                {group.measurementUnitGroup.name}
                            </div>
                            <div className='unit__toggle'>
                                <ToggleButton
                                    options={getUnitToggleOptions(group)}
                                    selectedValue={group.standardId}
                                    onSelected={(selectedStandard) => {
                                        actions.selectUnitStandard(selectedStandard, group.measurementUnitGroup.id);
                                    }}
                                />
                            </div>
                        </li>
                    )}
                </ul>
            </LoadingStatusWrapper>
        </div>
    </div>;
};

UnitsComponent.propTypes = {
    model: PropTypes.shape({
        changeableUnits: PropTypes.array,
        loadingStatus: PropTypes.string,
    }),
    actions: PropTypes.shape({
        selectUnitStandard: PropTypes.func,
    })
};
export default UnitsComponent;
