import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconPowertrain = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M16.34 16.28L18.47 14.12L19.53 15.18L16.34 18.42L13.67 15.72L11.53 17.88L10.47 16.82L13.67
		13.58L16.34 16.28ZM7 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2
		20V11H8V20C8 20.2652 7.89464 20.5196 7.70711 20.7071C7.51957 20.8946 7.26522 21 7 21ZM6.5 12.5H3.5V19.5H6.5V12.5ZM4.25
		15.75H5.75V13.25H4.25V15.75ZM16 5C17.8299 5.00158 19.6305 5.45966 21.2387 6.33273C22.8469 7.2058 24.2119 8.46628
		25.21 10H27.53C26.4761 7.98061 24.9101 6.27359 22.9889 5.04989C21.0677 3.82619 18.8586 3.12878 16.583 3.02751C14.3075
		2.92623 12.0452 3.42465 10.0229 4.47285C8.00052 5.52104 6.2891 7.08222 5.06 9H7.52C8.55147 7.74967 9.84651 6.74274
		11.3125 6.05125C12.7784 5.35975 14.3791 5.00077 16 5ZM16 27C14.3791 26.9992 12.7784 26.6402 11.3125 25.9487C9.84651
		25.2572 8.55147 24.2503 7.52 23H5.06C6.2891 24.9178 8.00052 26.4789 10.0229 27.5271C12.0452 28.5753 14.3075 29.0738
		16.583 28.9725C18.8586 28.8712 21.0677 28.1738 22.9889 26.9501C24.9101 25.7264 26.4761 24.0194 27.53 22H25.21C24.2119
		23.5337 22.8469 24.7942 21.2387 25.6673C19.6305 26.5403 17.8299 26.9984 16 27ZM30 15.5V16.5H28.5V18C28.5 18.2652
		28.3946 18.5196 28.2071 18.7071C28.0196 18.8946 27.7652 19 27.5 19H27V20H22V19C21.7348 19 21.4804 18.8946 21.2929
		18.7071C21.1054 18.5196 21 18.2652 21 18V14C21 13.7348 21.1054 13.4804 21.2929 13.2929C21.4804 13.1054 21.7348 13
		22 13V12H25L26 13H27.5C27.7652 13 28.0196 13.1054 28.2071 13.2929C28.3946 13.4804 28.5 13.7348 28.5 14V15.5H30ZM27
		14.5H22.5V17.5H27V16.5H25V15.5H27V14.5Z' fill={color} />
	</svg>;
};

IconPowertrain.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconPowertrain;
