import { filterTabs, apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { calculateAssetsStatus, calculatePowertrainsStatus } from './helpers';


const initialState = {
    activeTab: filterTabs.ASSETS,
    filters: {
        priorityFilter: [
        ],
        assetTypeFilter: null,
        searchboxFilter: null
    },
    assets: [],
    assetsLoadingStatus: apiCallStatus.LOADING,
    powertrains: [],
    powertrainsLoadingStatus: apiCallStatus.SUCCESS,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.activeTab
            };
        }
        case actionTypes.SET_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...action.filters }
            };
        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                filters: {
                    priorityFilter: initialState.filters.priorityFilter,
                    searchboxFilter: initialState.filters.searchboxFilter
                }
            };
        }
        case actionTypes.GET_ASSETS: {
            return {
                ...state,
                ...action.loadingStatus !== apiCallStatus.LOADING ?
                    { assets: calculateAssetsStatus(action.data) }
                    : { assets: state.assets },
                assetsLoadingStatus: action.loadingStatus
            };
        }
        case actionTypes.GET_POWERTRAINS: {
            return {
                ...state,
                ...action.loadingStatus !== apiCallStatus.LOADING ?
                    { powertrains: calculatePowertrainsStatus(action.data) }
                    : { powertrains: state.powertrains },
                powertrainsLoadingStatus: action.loadingStatus
            };
        }
        default:
            return state;
    }
};

