import PropTypes from 'prop-types';
import React from 'react';
import { Expandable } from 'sharedComponents';

const ExpandableParameter = (props) => {

    const { actions, kpi, selectedKpi } = props;

    return <Expandable
        title={
            <div className='param-group'>
                <span className='param-name'>{kpi.label}</span>
            </div>
        }
        open={selectedKpi && kpi.measurementTypeID === selectedKpi.measurementTypeID}
        onClick={() => actions.setSelectedMeasurementTypeId(kpi.measurementTypeID)}
    >
        {props.children}
    </Expandable>;
};

ExpandableParameter.propTypes = {
    actions: PropTypes.shape({
        setSelectedMeasurementTypeId: PropTypes.func
    }),
    children: PropTypes.any,
    kpi: PropTypes.shape({
        label: PropTypes.string,
        measurementTypeID: PropTypes.number
    }),
    selectedKpi: PropTypes.shape({
        measurementTypeID: PropTypes.number
    })
};

export default ExpandableParameter;
