import { createSelector } from 'reselect';

const getLimitKPI = ({ limitConfig }) => {
    return limitConfig.limitKPI || [];
};

const getTimeSeriesList = ({ limitConfig }) => {
    return limitConfig.timeSeries || [];
};

const getCurrentTimeseries = ({ limitConfig }) => {
    return limitConfig.currentTimeSeries || [];
};

export const getModel = createSelector([
    getLimitKPI,
    getCurrentTimeseries,
    getTimeSeriesList
], (limitKPI, currentTimeSeries, timeSeries) => {
    return {
        limitKPI,
        currentTimeSeries,
        timeSeries
    };
});
