import PropTypes from 'prop-types';
import React from 'react';
import { toLocaleDateTimeString } from 'helpers/dateHelper';
import './styles.scss';

const ConditionIndicatorTooltip = ({ timeStamp, dateConfig, indicatorType, kpiPoint, label }) => {
    const uom = kpiPoint?.series?.tooltipOptions?.valueSuffix?.trim();
    return (
        <div className='condition-indicator-tooltip'>
            <div className='t-header'>
                {toLocaleDateTimeString(timeStamp, dateConfig)}
            </div>
            <div className='t-item'>
                <span className='symbol'>
                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(indicatorType)}`} />
                </span>
                <span className='type'>
                    {label}
                </span>
            </div>
            {kpiPoint ? <div className='t-item'>
                <span className='symbol' style={{ backgroundColor: kpiPoint?.color, opacity: '1' }} />
                <span className='kpi-type'>{`${kpiPoint?.series?.name}: ${kpiPoint?.y} ${uom}`}</span>
            </div> : null}
        </div>
    );
};

ConditionIndicatorTooltip.propTypes = {
    component: PropTypes.string,
    name: PropTypes.string,
    xUnit: PropTypes.string,
    yUnit: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    date: PropTypes.string,
    indicatorType: PropTypes.any,
    kpiPoint: PropTypes.any,
    label: PropTypes.string,
};

export default ConditionIndicatorTooltip;
