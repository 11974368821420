import React from 'react';
import { Container } from 'react-bootstrap';
import { Header } from './Header';
import { GeneralFilter } from './../GeneralFilter';
import { Helmet } from 'react-helmet';
import { clarity } from 'helpers/clarity';
import { setInitialClarityTags } from 'helpers/clarityHelper';
import { isClarityIncluded } from './helper';
import authHelper from 'helpers/authHelper';

const PowertrainOverviewComponent = ({
    model: { user },
    children
}) => {
    return (
        <div className='pt-overview-component'>
            {authHelper.areAllTokensValid() &&
                <Helmet>
                    {!isClarityIncluded() && <script type='module' src={clarity()} />}
                    {setInitialClarityTags(user)}
                </Helmet>
            }
            <Header />
            <GeneralFilter />
            <Container fluid className='pt-overview-content'>
                <div className='pt-overview-children'>
                    {children}
                </div>
            </Container>
        </div>
    );
};

export default PowertrainOverviewComponent;
