import React from 'react';

import colors from 'theme/_colors.scss';


const IconAddFile = ({ width = 32, height = 32, color = colors.mineShaft }) => {
	return <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M20.16 2L26 7.78V15C25.3652 14.6671 24.6941 14.4087 24 14.23V8.62L19.34 4H6V28H14.52C15.0286 28.756 15.649 29.4303 16.36 30H4V2H20.16ZM14.52 18C14.1098 18.6254 13.7742 19.2966 13.52 20H8V18H14.52ZM22 14C19.9426 13.9967 17.9471 14.7031 16.35 16H8V14H22ZM17 10V12H8V10H17ZM22 16C20.1435 16 18.363 16.7375 17.0503 18.0503C15.7375 19.363 15 21.1435 15 23C15 24.8565 15.7375 26.637 17.0503 27.9497C18.363 29.2625 20.1435 30 22 30C23.8565 30 25.637 29.2625 26.9497 27.9497C28.2625 26.637 29 24.8565 29 23C29 21.1435 28.2625 19.363 26.9497 18.0503C25.637 16.7375 23.8565 16 22 16ZM26 24H23V27H21V24H18V22H21V19H23V22H26V24Z' fill={color} fillOpacity='0.3' />
	</svg>;

};

export default IconAddFile;
