import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { getAssetTypes } from './helpers';
import { wizardSteps, wizardStepStates, operations } from './constants';
import AssetTableWithFilter from './AssetListWithFilter/AssetlistWithFilterComponent';
import './style.scss';


const PowertrainWizardStep2 = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const {
        selectedAssetTypeAvailableSection,
        selectedAssetTypeAddedSection,
        assetsLoadingStatus,
        availableAssets,
        assetFilterAvailableSection,
        addedAssets,
        addedAssetsFiltered,
        assetFilterAddedSection,
        activeStep,
        assets,
        selectedSite,
        availableAssetsHasMore,
        availableAssetsCurrentPage
    } = model;

    const {
        setWizardStepState,
        setSelectedAssetTypeAvailableSectionAction,
        setAssetFilterAvailableSectionAction,
        addAssetAction,
        removeAssetAction,
        setSelectedAssetTypeAddedSectionAction,
        setAssetFilterAddedSectionAction,
        getAvailableAssetsRequest
    } = actions;

    const [timer, setTimer] = useState(null);

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getAvailableAssetsRequest(selectedSite?.siteId, [], 1, assetFilterAvailableSection);
        }, assetFilterAvailableSection?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [assetFilterAvailableSection]);

    const [addedAssetTypes, setAddedAssetTypes] = useState([]);

    const addAsset = (asset) => {
        addAssetAction(asset);
        setSelectedAssetTypeAddedSectionAction(null);
        setAssetFilterAddedSectionAction('');
    };

    const availableAssetTypes = useMemo(() =>
        Array.isArray(assets) ? getAssetTypes(assets, translate) : []
    , [assets, translate]);

    useEffect(() => {
        if (Array.isArray(addedAssets) && addedAssets.length) {
            setAddedAssetTypes(getAssetTypes(addedAssets, translate));
            setWizardStepState(wizardSteps.STEP2, wizardStepStates.COMPLETED);
        }
        else {
            activeStep === wizardSteps.STEP2 &&
                setWizardStepState(wizardSteps.STEP2, wizardStepStates.ACTIVE);
            setSelectedAssetTypeAddedSectionAction(null);
            setAddedAssetTypes([]);
            setWizardStepState(wizardSteps.STEP3, wizardStepStates.INACTIVE);
        }
    }, [addedAssets]);

    const renderAvailableAssetsSection = () => {
        return <AssetTableWithFilter
            className='available-assets-section'
            title={translate('ABB.Powertrain.Frontend.powertrainWizardAvailableAssetsText')}
            options={availableAssetTypes}
            selectedValue={selectedAssetTypeAvailableSection}
            onSelectChange={setSelectedAssetTypeAvailableSectionAction}
            isLoading={false}
            onSearchChange={setAssetFilterAvailableSectionAction}
            searchValue={assetFilterAvailableSection}
            searchPlaceHolder={translate('ABB.Powertrain.Frontend.placeholderSearchAssetAndPowertrainList')}
            assets={availableAssets}
            actionType={operations.ADD}
            action={addAsset}
            loadingStatus={assetsLoadingStatus}
            hasMore={availableAssetsHasMore}
            loadMore={() => getAvailableAssetsRequest(
                selectedSite?.siteId,
                [],
                availableAssetsCurrentPage,
                assetFilterAvailableSection
            )}
        />;
    };

    const renderAddedAssetsSection = () => {
        return <AssetTableWithFilter
            className='selected-assets-section'
            title={translate('ABB.Powertrain.Frontend.powertrainWizardSelectedAssetsText')}
            options={addedAssetTypes}
            selectedValue={selectedAssetTypeAddedSection}
            onSelectChange={setSelectedAssetTypeAddedSectionAction}
            isLoading={false}
            onSearchChange={setAssetFilterAddedSectionAction}
            searchValue={assetFilterAddedSection}
            searchPlaceHolder={translate('ABB.Powertrain.Frontend.placeholderSearchAssetAndPowertrainList')}
            assets={addedAssetsFiltered}
            actionType={operations.REMOVE}
            action={removeAssetAction}
        />;
    };

    return <div className='step2'>
        <Row className='assets-filtered-text'>
            <Col className='powertrain-wizard-user-guide-text'> {translate('ABB.Powertrain.Frontend.powertrainWizardAssetFilteredText')} </Col>
        </Row>

        <Row className='asset-sections'>
            {renderAvailableAssetsSection()}
            {renderAddedAssetsSection()}
        </Row>
    </div>;
};

PowertrainWizardStep2.propTypes = {
    model: PropTypes.shape({
        selectedAssetTypeAvailableSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            assetType: PropTypes.string.isRequired,
            typeID: PropTypes.number,
            typeName: PropTypes.string,
        }),
        selectedAssetTypeAddedSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            assetType: PropTypes.string.isRequired,
            typeID: PropTypes.number,
            typeName: PropTypes.string,
        }),
        assets: PropTypes.array,
        assetsLoadingStatus: PropTypes.string,
        availableAssets: PropTypes.array,
        assetFilterAvailableSection: PropTypes.string,
        addedAssets: PropTypes.array,
        addedAssetsFiltered: PropTypes.array,
        assetFilterAddedSection: PropTypes.string,
        activeStep: PropTypes.string,
    }),
    actions: PropTypes.shape({
        setWizardStepState: PropTypes.func,
        setSelectedAssetTypeAvailableSectionAction: PropTypes.func,
        setAssetFilterAvailableSectionAction: PropTypes.func,
        addAssetAction: PropTypes.func,
        removeAssetAction: PropTypes.func,
        setSelectedAssetTypeAddedSectionAction: PropTypes.func,
        setAssetFilterAddedSectionAction: PropTypes.func,
    })
};

export default PowertrainWizardStep2;
