import { actionTypes } from './constants';
import {
    updateAssetNameForComponents,
    updateAssetNameInPwtList,
} from './helper';


const initialState = {
    user: {
        data: null,
        error: null,
        loadingStatus: null
    },
    assets: {
        data: [],
        error: null,
        loadingStatus: null
    },
    powertrains: {
        data: null,
        error: null,
        loadingStatus: null
    },
    eulaAccept: {
        loadingStatus: null
    },
    availableLanguages: {
        data: null,
        error: null,
        loadingStatus: null
    },
    dateSelection: {
        from: undefined,
        to: undefined
    },
    userCapabilities: {
        data: null,
        error: null,
        loadingStatus: null
    }
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_USER: {
            return {
                ...state,
                user: { ...state.user, ...action }
            };
        }
        case actionTypes.UPDATE_UNIT_SELECTION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    loadingStatus: action.loadingStatus,
                    data: {
                        ...state.user.data,
                        measurementUnitGroups: action.data ? action.data : state.user.data.measurementUnitGroups
                    }
                }
            };
        }
        case actionTypes.GET_ASSETS: {
            return {
                ...state,
                assets: { ...state.assets, ...action },
            };
        }
        case actionTypes.GET_POWERTRAINS: {
            return {
                ...state,
                powertrains: { ...state.powertrains, ...action },
            };
        }
        case actionTypes.UPDATE_ASSET_SUCCESS: {
            return {
                ...state,
                assets: { ...state.assets, data: updateAssetNameForComponents([...state.assets.data], action.data) },
                powertrains: { ...state.powertrains, data: updateAssetNameInPwtList([...state.powertrains.data], action.data) },
            };
        }
        case actionTypes.ACCEPT_EULA:
            return {
                ...state,
                eulaAccept: {
                    loadingStatus: action.loadingStatus
                },
                user: {
                    ...state.user, data: {
                        ...state.user.data,
                        isEULAAccepted: action.data ? action.data?.isEULAAccepted : state.user.data.isEULAAccepted
                    }
                }
            };
        case actionTypes.LOAD_AVAILABLE_LANGUAGES:
            return {
                ...state,
                availableLanguages: { ...state.availableLanguages, ...action }
            };
        case actionTypes.UPDATE_SELECTED_LANGUAGE:
            return {
                ...state,
                user: {
                    ...state.user,
                    data: {
                        ...state.user.data,
                        languageCode: action.data || state.user.data.code
                    },
                    loadingStatus: action.loadingStatus
                }
            };
        case actionTypes.SET_DATE_SELECTION:
            return {
                ...state,
                dateSelection: action.dateSelection
            };
        case actionTypes.GET_USER_CAPABILITIES: {
            return {
                ...state,
                userCapabilities: action
            };
        }
        default:
            return state;
    }
}
