import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SensorPropertiesComponent from './SensorPropertiesComponent';
import { getModel } from './selectors';
import * as actions from '../sensorActions';
import { getSensorTypeValueFromCode } from '../helpers';


const SensorPropertiesContainer = (props) => {

    const { model, actions } = props;

    useEffect(() => {
        if (model.assetDetails?.details &&
            model.assetDetails?.details?.sensorIdentifier !== model.sensorProperties?.data?.sensorIdentifier &&
            props.asset?.isMonitored) {
            //Todo
            //Here also change sensor Type code Code
            //actions.getSensorProperties(model.assetDetails.details.sensorIdentifier,
            //   model.assetDetails.details.sensorTypeID);
            actions.getSensorProperties(model.assetDetails.details.sensorIdentifier,
                getSensorTypeValueFromCode(model.assetDetails.details.sensorType));
            actions.getGatewayInfo(model.assetDetails.details.assetIdentifier);
        }
    }, [model.assetDetails?.details]);

    return <SensorPropertiesComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const SensorPropertiesContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(SensorPropertiesContainer);

export default SensorPropertiesContainerConn;
