import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getModel } from './selectors';
import LoginComponent from './LoginComponent';


const LoginContainer = (props) => {
	const queryParams = new URLSearchParams(useLocation().search);

	return <LoginComponent {...props.model} returnUrl={queryParams.get('returnUrl')} errorMessage={queryParams.get('errorMessage')} />;
};

LoginContainer.propTypes = {
	model: PropTypes.any
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const LoginContainerConn = withRouter(connect(
	mapStateToProps
)(LoginContainer));


export default LoginContainerConn;
