import { useSelector } from 'react-redux';
import CapabilityHelper from 'helpers/capabilityHelper';
import { useEffect, useRef, useState } from 'react';


const useCapability = () => {
    const capability = useSelector(({ powertrainAssetDetails }) => powertrainAssetDetails.selectedAssetCapability);
    const asset = useSelector(({ powertrainAssetDetails }) => powertrainAssetDetails.selectedAsset);
    const isInitialized = useRef(false);

    const [capabilityHelper, setCapabilityHelper] = useState(new CapabilityHelper(capability, asset));

    useEffect(() => {
        return () => {
            setCapabilityHelper(null);
        };
    }, []);

    useEffect(() => {
        if (isInitialized.current) {
            setCapabilityHelper(new CapabilityHelper(capability, asset));
        } else {
            isInitialized.current = true;
        }
    }, [capability, asset]);


    return capabilityHelper;
};

export default useCapability;
