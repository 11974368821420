import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetLabelsComponent from './AssetLabelsComponent';
import * as actions from './actions';
import { getModel } from './selectors';
import './style.scss';

const AssetLabelsContainer = (props) => {
	return <AssetLabelsComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const AssetLabelsContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(AssetLabelsContainer);

export default AssetLabelsContainerConn;
