import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModel } from './selectors';
import * as actions from './actions';
import TrendKPIListComponent from './TrendKPIListComponent';


const TrendKPIListContainer = (props) => {

	return <TrendKPIListComponent {...props} />;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const TrendKPIListContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(TrendKPIListContainer);


export default TrendKPIListContainerConn;
