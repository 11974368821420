import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconPowertrainGroup = ({ width = '16px', height = '16px', viewBox = '0 0 16 16', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.67 8.00005L8.67 7.00005L9.37 7.72005L7.67 9.35005L6.33 8.05005L5.33 9.05005L4.63 8.33005L6.33 6.65005L7.67 8.00005ZM8 2.00005C8.84214 2.00175 9.67448 2.18071 10.4429 2.5253C11.2113 2.86988 11.8986 3.37235 12.46 4.00005H13.74C13.0949 3.07584 12.2362 2.32108 11.2368 1.79991C10.2375 1.27874 9.12708 1.00659 8 1.00659C6.87292 1.00659 5.76251 1.27874 4.76317 1.79991C3.76382 2.32108 2.9051 3.07584 2.26 4.00005H3.54C4.10142 3.37235 4.78869 2.86988 5.55711 2.5253C6.32552 2.18071 7.15786 2.00175 8 2.00005ZM8 14C7.15786 13.9983 6.32552 13.8194 5.55711 13.4748C4.78869 13.1302 4.10142 12.6277 3.54 12H2.26C2.9051 12.9243 3.76382 13.679 4.76317 14.2002C5.76251 14.7214 6.87292 14.9935 8 14.9935C9.12708 14.9935 10.2375 14.7214 11.2368 14.2002C12.2362 13.679 13.0949 12.9243 13.74 12H12.46C11.8986 12.6277 11.2113 13.1302 10.4429 13.4748C9.67448 13.8194 8.84214 13.9983 8 14ZM16 7.50005V8.50005H15V9.00005C15 9.26526 14.8946 9.51962 14.7071 9.70715C14.5196 9.89469 14.2652 10 14 10V10.5H11V9.86005C10.8446 9.77782 10.7153 9.65364 10.6269 9.50161C10.5385 9.34958 10.4946 9.17581 10.5 9.00005V7.00005C10.5 6.73483 10.6054 6.48048 10.7929 6.29294C10.9804 6.1054 11.2348 6.00005 11.5 6.00005H14C14.2652 6.00005 14.5196 6.1054 14.7071 6.29294C14.8946 6.48048 15 6.73483 15 7.00005V7.50005H16ZM14 7.00005H11.5V9.00005H14V8.50005H13V7.50005H14V7.00005ZM2.5 10.5H1C0.734784 10.5 0.48043 10.3947 0.292893 10.2072C0.105357 10.0196 0 9.76526 0 9.50005V5.50005H3.5V9.50005C3.5 9.76526 3.39464 10.0196 3.20711 10.2072C3.01957 10.3947 2.76522 10.5 2.5 10.5ZM1 9.50005H2.5V6.50005H1V9.50005Z' fill={color} />
    </svg>;
};

IconPowertrainGroup.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};

export default IconPowertrainGroup;
