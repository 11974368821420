
import { find } from 'lodash';

import { ComponentApi } from 'api';
import { apiCallStatus } from 'helpers/constants';

import { getApiActionCreator } from 'helpers/actionHelper';
import { setMaxPrecision } from 'helpers/formatters';
import { notify } from 'sharedComponents/GeneralNotification';
import { translate } from 'helpers/translateHelper';

import { actionTypes } from '../constants';
import { isAlertHigherThanAlarm, isDeviationTooHigh } from './helpers';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { saveAlertAlarmConfig, saveOffset } from '../sharedActions';
import { getHealthStatuses } from '../../../OperationalParameters/SingleChartView/Trend/TrendKPIList/actions';
import { mapToOffsetReqObj } from '../helpers';
import { getTrendKPIsForAsset } from '../../../commonActions';

export const setAlert = (measurementTypeID, value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ALERT_VALUE, measurementTypeID, value });
    };
};


export const setAlarm = (measurementTypeID, value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ALARM_VALUE, measurementTypeID, value });
    };
};

export const setAlertNotification = (measurementTypeID, alertNotification) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ALERT_NOTIFICATION, measurementTypeID, alertNotification });
    };
};

export const setAlarmNotification = (measurementTypeID, alarmNotification) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ALARM_NOTIFICATION, measurementTypeID, alarmNotification });
    };
};

const getDefaultHealthStatusesAction = getApiActionCreator(actionTypes.GET_DEFAULT_HEALTH_STATUSES);
export const getDefaultHealthStatuses = (measurementTypeID, measurementTypeUnitGroupID) => {
    return (dispatch, getState) => {
        const store = getState();

        if (store.alertAlarmOffsetConfig.defaultHealthStatuses.data) {
            return Promise.resolve(store.alertAlarmOffsetConfig.defaultHealthStatuses.data);
        }
        else {
            const asset = store.powertrainAssetDetails.selectedAsset;
            dispatch(getDefaultHealthStatusesAction(apiCallStatus.LOADING));
            return ComponentApi.getDefaultHealthStatuses(asset, measurementTypeID, measurementTypeUnitGroupID)
                .then((data) => {
                    dispatch(getDefaultHealthStatusesAction(apiCallStatus.SUCCESS, data));
                    return data;
                })
                .catch((err) => {
                    dispatch(getDefaultHealthStatusesAction(apiCallStatus.ERROR, null, err));
                });
        }
    };
};


export const resetHealthStatuses = (selectedKpi) => {
    return (dispatch) => {
        const { measurementTypeID, measurementTypeUnitGroupID } = selectedKpi;
        dispatch(getDefaultHealthStatuses(measurementTypeID, measurementTypeUnitGroupID))
            .then((defaultThresholds) => {
                dispatch({ type: actionTypes.RESET_HEALTH_STATUSES, defaultThresholds, measurementTypeID });
            });
    };
};

const getCalculatedThresholdsAction = getApiActionCreator(actionTypes.GET_CALCULATED_THRESHOLDS);
export const getCalculatedThresholds = (selectedKpi, { from, to }) => {
    return (dispatch, getState) => {
        const asset = getState().powertrainAssetDetails.selectedAsset;
        const { config: { precision }, measurementTypeID, measurementTypeUnitGroupID } = selectedKpi;
        dispatch(getCalculatedThresholdsAction(apiCallStatus.LOADING));
        dispatch(getDefaultHealthStatuses(measurementTypeID, measurementTypeUnitGroupID))
            .then((defaultThresholds) => {
                return ComponentApi.getCalculatedThresholds(asset, measurementTypeID, measurementTypeUnitGroupID, from, to)
                    .then((thresholds) => {
                        thresholds.forEach((threshold, key) => {
                            thresholds[key].valueTo = setMaxPrecision(threshold.valueTo, precision);
                            thresholds[key].valueFrom = setMaxPrecision(threshold.valueFrom, precision);
                        });
                        const data = {
                            thresholds,
                            measurementTypeID,
                            isAlertHigherThanAlarm: null,
                            isDeviationTooHigh: null
                        };
                        data.isAlertHigherThanAlarm = isAlertHigherThanAlarm(thresholds);
                        if (data.isAlertHigherThanAlarm) {
                            notify(translate('ABB.Powertrain.Frontend.alertHigherThanAlarmText'), NOTIFICATION_TYPES.WARN, true);
                        }
                        else {
                            data.isDeviationTooHigh = isDeviationTooHigh(defaultThresholds, thresholds);
                            if (data.isDeviationTooHigh) {
                                notify(translate('ABB.Powertrain.Frontend.deviationTooHighText'), NOTIFICATION_TYPES.WARN, true);
                            }
                        }
                        dispatch(getCalculatedThresholdsAction(apiCallStatus.SUCCESS, data));
                    });


            })
            .catch((err) => {
                dispatch(getCalculatedThresholdsAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const filterHealthStatusesBySelectedHealthParameterID = (healthStatusValues, selectedHealthParameterID) => {
    const selectedHealthStatus = healthStatusValues && find(healthStatusValues, (healthStatus) => {
        return healthStatus.measurementTypeID === selectedHealthParameterID;
    });

    return selectedHealthStatus ? [selectedHealthStatus] : null;
};

export const saveSelectedAlertAlarmOffsetConfig = (selectedKpi) => {
    return (dispatch, getState) => {
        const state = getState();
        const asset = state.powertrainAssetDetails.selectedAsset;
        let saveSelectedOffsetPromise = Promise.resolve();
        if (selectedKpi.isCalibrationEnabled) {
            const reqObj = mapToOffsetReqObj(asset.componentID, selectedKpi);
            saveSelectedOffsetPromise = dispatch(saveOffset(asset, [reqObj]))
                .then(() => dispatch(getTrendKPIsForAsset(asset)));
        }
        const selectedHealthStatus = find(state.alertAlarmOffsetConfig.trendHealthStatuses, (healthStatus) => {
            return healthStatus.measurementTypeID === selectedKpi.measurementTypeID;
        });
        return saveSelectedOffsetPromise
            .then(() => dispatch(saveAlertAlarmConfig(asset, [selectedHealthStatus])))
            .then(() => dispatch(getHealthStatuses(asset)));

    };
};
