import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import { useCapability } from 'sharedHooks';
import { ToggleButton, TimeSelectorRow, GeneralButton } from 'sharedComponents';
import { LoadMeasurement } from './LoadMeasurement';
import { CollectRawData } from './CollectRawData';
import {
    opButtons,
    multiChartToggleOptions
} from '../constants';
import {
    formatMultiToggleOptions
} from './helpers';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';

import './style.scss';

const OperationalParametersHeaderComponent = ({
    model: {
        dateSelection,
        hasRawData,
        activeTab,
        multiChart,
        isCMDAsset
    },
    actions: {
        downloadRawData,
        setActiveTab,
        setSelectedDates,
        setActiveTrendTab
    },
    toggleOptions,
}) => {
    const { t: translate } = useTranslation();

    const capability = useCapability();
    const loadMeasurementOnDemandCapabilityAvailable = capability.hasLoadMeasurementOnDemandCapability();
    const collectRawDataCapabilityAvailable = capability.hasCollectRawDataCapability();

    const [isLoadMeasurementDialogVisible, setIsLoadMeasurementDialogVisible] = useState(false);
    const [isCollectRawDataDialogVisible, setIsCollectRawDataDialogVisible] = useState(false);

    useEffect(() => {
        toggleOptions.length && !toggleOptions.some(option => option.value === activeTab) && setActiveTab(toggleOptions[0].value);
    }, [toggleOptions]);


    const onToggleLoadMeasurementDialog = () => {
        setIsLoadMeasurementDialogVisible(!isLoadMeasurementDialogVisible);
    };

    const onToggleCollectRawDataDialog = () => {
        setIsCollectRawDataDialogVisible(!isCollectRawDataDialogVisible);
    };

    const handleSelectDate = (from, to) => {
        if (from && to) {
            setSelectedDates(from, to);
        }
    };

    const handleTabSelection = (tab) => {
        setActiveTab(tab);
    };

    const handleTrendTabSelection = (tab) => {
        setActiveTrendTab(tab);
    };

    return <>
        <LoadMeasurement show={isLoadMeasurementDialogVisible} close={onToggleLoadMeasurementDialog} />
        <CollectRawData show={isCollectRawDataDialogVisible} close={onToggleCollectRawDataDialog} />
        <Row className='tab-actions-container justify-content-end'>
            <Col xl lg={12}>
                <div className='toggle-buttons'>
                    <ToggleButton
                        options={toggleOptions}
                        onSelected={handleTabSelection}
                        selectedValue={activeTab}
                    />
                    {roleBasedCapabilityHelper.hasMultiChart() &&
                        activeTab === opButtons.trends &&
                        isCMDAsset &&
                        <ToggleButton
                            options={formatMultiToggleOptions(multiChartToggleOptions)}
                            onSelected={handleTrendTabSelection}
                            selectedValue={multiChart}
                        />}
                </div>
            </Col>
            {
                loadMeasurementOnDemandCapabilityAvailable &&
                <Col md='auto'>
                    <GeneralButton
                        type='normal'
                        text={translate('ABB.Powertrain.Frontend.opLoadMeasurmentBtn')}
                        onClick={onToggleLoadMeasurementDialog}
                    />
                </Col>
            }
            {
                activeTab === opButtons.vibrationFFT ?
                    <>
                        {
                            collectRawDataCapabilityAvailable &&
                            <Col md='auto'>
                                <GeneralButton
                                    type='normal'
                                    text={translate('ABB.Powertrain.Frontend.opCollectRawDataBtn')}
                                    onClick={onToggleCollectRawDataDialog}
                                />
                            </Col>
                        }
                        {
                            hasRawData &&
                            <Col md='auto'>
                                <GeneralButton
                                    type='normal'
                                    text={translate('ABB.Powertrain.Frontend.opDownloadRawDataBtn')}
                                    onClick={() => downloadRawData()}
                                />
                            </Col>
                        }
                    </> : null
            }
            {
                activeTab !== opButtons.vibrationFFT &&
                <Col className='col-xl-auto col-lg col-sm-auto'>
                    <TimeSelectorRow
                        fromDate={dateSelection.from}
                        toDate={dateSelection.to}
                        label=''
                        handleSelectDate={handleSelectDate}
                        maxDaysRange={90}
                        maxDaysBack={roleBasedCapabilityHelper.hasExtendedDateSelector() ? 365 : 90}
                        preSelectedDays={7}
                        numberOfMonths={2}
                        footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                    />
                </Col>
            }
        </Row>
    </>;
};

OperationalParametersHeaderComponent.propTypes = {
    model: PropTypes.shape({
        dateSelection: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string
        }),
        hasRawData: PropTypes.bool,
        activeTab: PropTypes.string,
        multiChart: PropTypes.string,
        isCMDAsset: PropTypes.bool
    }),
    actions: PropTypes.shape({
        downloadRawData: PropTypes.func,
        setActiveTab: PropTypes.func,
        setSelectedDates: PropTypes.func,
        setActiveTrendTab: PropTypes.func
    }),
    toggleOptions: PropTypes.array,
};

export default OperationalParametersHeaderComponent;
