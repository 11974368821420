import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconGlobe = ({ width = 26, height = 26, viewBox = '0 0 26 26', color = colors.mineShaft }) => {

    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13 0C5.82 0 0 5.82 0 13C0 20.18 5.82 26 13 26C20.18 26 26 20.18 26 13C26 9.55219 24.6304 6.24558 22.1924 3.80761C19.7544 1.36964 16.4478 0 13 0ZM17.33 2.89L14.4 5.82H13.17L9.82 2.47C10.8518 2.16083 11.9229 2.00252 13 2C14.4887 2.00061 15.9618 2.30339 17.33 2.89ZM2 13C2.00441 12.089 2.12197 11.182 2.35 10.3H7.45V12.6H5.45L4.95 13.1V15.31L5.45 15.81H9.88V19.2L6.9 22.14C5.39468 21.1369 4.15999 19.778 3.30529 18.1837C2.45059 16.5894 2.00225 14.8089 2 13ZM7.78 22.68L10.69 19.76L10.84 19.41V15.31L10.34 14.81H5.91V13.6H7.91L8.41 13.1V9.8L7.91 9.3H2.65C3.16825 7.85717 3.9808 6.53784 5.03607 5.42575C6.09135 4.31367 7.3663 3.43312 8.78 2.84L12.61 6.67L12.96 6.82H14.61L15 6.67L18.32 3.35C19.1148 3.80823 19.8503 4.36238 20.51 5L13.51 12L13.36 12.36V15.44L13.86 15.94H16.86V19.72L17.01 20.07L19.06 22.12C17.3974 23.2361 15.4617 23.8772 13.4615 23.9741C11.4614 24.071 9.47273 23.6201 7.71 22.67L7.78 22.68ZM19.94 21.53L17.94 19.53V15.45L17.44 14.95H14.44V12.57L21.24 5.7C22.2254 6.80905 22.9763 8.10598 23.4474 9.51275C23.9186 10.9195 24.1003 12.4071 23.9817 13.8859C23.863 15.3647 23.4465 16.8043 22.7571 18.1179C22.0676 19.4316 21.1196 20.5922 19.97 21.53H19.94Z' fill={color} />
    </svg>;
};

IconGlobe.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
};

export default IconGlobe;
