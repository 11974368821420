import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { assetObjectToComponentRequest2 } from '../helpers';
import {
    convertKPIsResponse,
    formatKPITrendResponse,
    convertKPIHistogramResponse,
    formatKPIScatterResponse,
    handleGetMeasurementTypesConversion
} from './plotApiUoMHelper';


class Plot {

    static getMeasurementTypes(asset, plotType) {
        const endpoint = formatEndpoint(endpoints.Plot.MeasurementType, { plotType });
        return RestManager
            .post(endpoint, assetObjectToComponentRequest2(asset))
            .then(handleGetMeasurementTypesConversion);
    }

    static getPlotKPIList(asset, plotType) {
        const endpoint = formatEndpoint(endpoints.Plot.List, { plotType });
        return RestManager
            .post(endpoint, assetObjectToComponentRequest2(asset))
            .then(convertKPIsResponse);
    }

    static getTrend(componentKPITypes, from, to, isZoom = false) {
        const endpoint = formatEndpoint(endpoints.Plot.Trend, { from, to, isZoom });
        return RestManager
            .post(endpoint, componentKPITypes)
            .then(formatKPITrendResponse);
    }

    static getScatter(assetKPITypes, from, to, isZoom = false) {
        const endpoint = formatEndpoint(endpoints.Plot.Scatter, { from, to, isZoom });
        return RestManager
            .post(endpoint, assetKPITypes)
            .then(formatKPIScatterResponse);
    }

    static getHistogram(assetKPITypes, from, to, isZoom = false) {
        const endpoint = formatEndpoint(endpoints.Plot.Histogram, { from, to, isZoom });
        return RestManager
            .post(endpoint, assetKPITypes)
            .then(convertKPIHistogramResponse);
    }
}
export default Plot;

