import { actionTypes } from './constants';
import { getDefaultAndAdditionalKpis } from './helpers';

const initialState = {
	saveUserLimits: {
		loadingStatus: null,
	},
	trendKpis: {
		kpis: [],
		additionalKpis: [],
		error: null,
		loadingStatus: null
	}
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SAVE_USER_LIMITS:
			return {
				...state,
				saveUserLimits: { ...action }
			};
		case actionTypes.GET_PLOT_KPIS:
			return {
				...state,
				trendKpis: {
					...state.trendKpis,
					loadingStatus: action.loadingStatus,
					...getDefaultAndAdditionalKpis(action.data)
				}
			};
		case actionTypes.CLEAR_STORE:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
