import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { conditionStatusKeys, StateID, filterTabs, apiCallStatus } from 'helpers/constants';
import { togglePowertrainAssets } from 'helpers/togglePowertrainAssetsHelper';
import { find, map } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import { SearchBox } from 'sharedComponents/SearchBox';
import { ToggleButton } from 'sharedComponents/ToggleButton';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { useQueryParams } from 'sharedHooks/useQuery';
import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';
import { setOptions } from '../../helpers';
import { getAssetAndPowertrainNumber } from './helpers';


const ConditionBasedFilterComponent = ({
    model: {
        activeTab,
        conditionStatusFilter,
        assetTypeFilter,
        searchBoxFilter,
        powertrains,
        assets,
        generalFilterLoadingStatuses,
        assetsLoadingStatus,
        powertrainsLoadingStatus,
    },
    actions,
}) => {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const { assetId } = useQueryParams();

    const assetTypes = setOptions(assets, translate);

    const loadingSummary = useLoadingSummary(
        null, [
        ...generalFilterLoadingStatuses,
        powertrainsLoadingStatus,
        assetsLoadingStatus,
    ]);

    const isLoading = () => loadingSummary !== apiCallStatus.SUCCESS;

    const handleTabSelection = tab => {
        actions.setActiveTab(tab);
    };

    useEffect(() => {
        if (location?.state) {
            actions.setActiveTab(location.state.tab);
            actions.toggleConditionStatusFilter(location.state.conditionStatus);
            location.state.assetType && actions.setAssetTypeFilter(location.state.assetType);
        }
    }, [location]);

    useEffect(() => {
        if (isLoading()) { return; }

        if (assetId) {
            actions.setActiveTab(filterTabs.ASSETS);
            actions.setSearchboxFilter(assetId);
        }
    }, [assetId, loadingSummary]);

    return (
        <div className='condition-based-filter'>
            <ToggleButton
                options={togglePowertrainAssets(translate)}
                onSelected={handleTabSelection}
                selectedValue={activeTab}
            />
            <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.powertrainAssetsOverviewInfo')} />

            <div className='condition-statuses'>
                {map(conditionStatusKeys, conditionStatus => {
                    return (
                        <div className='condition-status-wrapper' key={conditionStatus}>
                            <div className={
                                'condition-status' + (conditionStatusFilter[StateID[conditionStatus]] ? ' selected' : '')}
                                onClick={() => actions.toggleConditionStatusFilter(StateID[conditionStatus])}
                                key={conditionStatus}
                            >
                                <IconCondition condition={StateID[conditionStatus]} />
                            </div>
                            <div className='asset-number'>
                                {getAssetAndPowertrainNumber({ assets, powertrains, activeTab, assetTypeFilter }, conditionStatus)}
                            </div>
                        </div>
                    );
                })}
            </div>

            <GeneralSelect
                className='asset-type-filter'
                options={assetTypes}
                value={find(assetTypes, type => type.assetType === assetTypeFilter)}
                getOptionLabel={option => option.title}
                getOptionValue={option => option.id}
                defaultValue={assetTypes && !!assetTypes.length && assetTypes[0]}
                menuShouldScrollIntoView={false}
                onChange={value => actions.setAssetTypeFilter(value.assetType)}
                isSearchable={false}
            />
            <div className='search-field-wrapper'>
                <SearchBox
                    placeholder={translate('ABB.Powertrain.Frontend.nameOrSerialNoLabel')}
                    onChange={actions.setSearchboxFilter}
                    value={searchBoxFilter}
                />
            </div>
        </div>
    );
};

ConditionBasedFilterComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
        conditionStatusFilter: PropTypes.object,
        searchBoxFilter: PropTypes.string,
        powertrains: PropTypes.array,
        assets: PropTypes.array,
        generalFilterLoadingStatuses: PropTypes.array,
        assetsLoadingStatus: PropTypes.string,
        powertrainsLoadingStatus: PropTypes.string,
    }),
    actions: PropTypes.shape({
        setActiveTab: PropTypes.func,
        setAssetTypeFilter: PropTypes.func,
        toggleConditionStatusFilter: PropTypes.func,
        setSearchboxFilter: PropTypes.func
    }),

};

export default ConditionBasedFilterComponent;
