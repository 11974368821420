import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GeneralDialog, GeneralSelect, GeneralInput, GeneralButton, LoadingStatusWrapper, Switch } from 'sharedComponents';
import { apiCallStatus, popupTypes } from 'helpers/constants';
import { IconDelete } from 'svgIcons/MotionPortalIcons';
import { pull, groupBy } from 'lodash';
import { generateKPIs, generateComparisonTypes, getUserLimitValue, findDuplicate, userLimitNameShouldChange } from './helpers';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import './styles.scss';
import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';
import { MAX_LENGTH_USER_LIMIT_NAME } from './constants';


const UserLimitsComponent = (props) => {
    const { t: translate } = useTranslation();

    const [enableEmailAlert, setEnableEmailAlert] = useState(false);
    const [selectedComparisonType, setSelectedComparisonType] = useState(null);
    const [selectedKPI, setSelectedKPI] = useState(null);
    const [kpiSelectInput, setSelectedKPIInput] = useState(null);
    const [kpiFilteredOutput, setKpiFilteredOutput] = useState([]);
    const [userLimitName, setUserLimitName] = useState('');
    const [userLimitValue, setUserLimitValue] = useState('');
    const [comparisonTypes, setComparisonTypes] = useState([]);
    const [customUserLimits, setCustomUserLimits] = useState([]);
    const [defaultUserLimits, setDefaultUserLimits] = useState([]);
    const [userLimitValueUnit, setUserLimitValueUnit] = useState('');
    const [savePending, setSavePending] = useState(false);
    const [removeLimitConfirmVisible, setRemoveLimitConfirmVisible] = useState(false);
    const [unsavedChangesWarningVisible, setUnsavedChangesWarningVisible] = useState(false);
    const [selectedUserLimit, setSelectedUserLimit] = useState(null);

    const [isValidName, setIsValidName] = useState(false);
    const [isValidValue, setIsValidValue] = useState(false);

    const loadingSummary = useLoadingSummary(null, [props.model.trendKpisLoadingStatus, props.model.loadUserLimitsLoadingStatus, props.model.saveUserLimitsLoadingStatus]);


    useEffect(() => {
        setComparisonTypes(generateComparisonTypes());
    }, []);

    useEffect(() => {
        const userLimitsData = props.model.userLimitsData;
        if (userLimitsData) {
            setCustomUserLimits([...userLimitsData.customUserLimits]);
            setDefaultUserLimits(userLimitsData.defaultUserLimits);
            setKpiFilteredOutput(generateKPIs(props.model.trendKpis.kpis, props.model.trendKpis.additionalKpis));
        }
    }, [props.model.userLimitsData]);

    const handleSelectedKPI = (kpi) => {
        if (selectedKPI?.measurementTypeIdentifier !== kpi.measurementTypeIdentifier) {
            setSelectedKPI(kpi);
            setUserLimitValueUnit(kpi.unit);
        }
    };

    const updateUserLimitName = (value) => {
        setUserLimitName(value);
    };

    const userLimitNameValidation = (value) => {
        const isValid = value.length <= MAX_LENGTH_USER_LIMIT_NAME;
        setIsValidName(isValid);
        return isValid;
    };

    const userLimitValueValidation = (value) => {
        const isValid = !isNaN(value);
        setIsValidValue(isValid);
        return isValid;
    };

    const handleSelectedComparisonType = (comparisonType) => {
        if (selectedComparisonType?.value !== comparisonType.value) {
            setSelectedComparisonType(comparisonType);
        }
    };

    const toggleTemplateEmailAlertCheckbox = () => {
        setEnableEmailAlert(!enableEmailAlert);
    };

    const updateUserLimitValue = (value) => {
        setUserLimitValue(value);
    };

    const getComparisonType = (comparisonTypeID) => {
        return comparisonTypes.find((comparisonType) => { return comparisonType.value === comparisonTypeID; });
    };

    const addCustomUserLimit = () => {
        const currentUserLimits = [...customUserLimits];
        const duplicate = findDuplicate(
            customUserLimits,
            selectedKPI,
            userLimitValue,
            selectedComparisonType,
            userLimitName
        );

        if (customUserLimits.length === 0 || !duplicate) {
            currentUserLimits.unshift({
                kpiIdentifier: selectedKPI.measurementTypeIdentifier,
                kpiName: selectedKPI.label,
                unit: selectedKPI.unit || '',
                unitGroupID: selectedKPI.unitGroupID,
                userLimitName: userLimitName?.trim() || '',
                comparisonTypeID: selectedComparisonType.value,
                comparisonTypeName: selectedComparisonType.label,
                userLimitValue: parseFloat(userLimitValue),
                enableEmailAlert
            });

            setEnableEmailAlert(false);
            setSelectedComparisonType(null);
            setSelectedKPI(null);
            setUserLimitName('');
            setUserLimitValue('');
            setUserLimitValueUnit('');
            setCustomUserLimits(currentUserLimits);
            setSavePending(true);
        } else if (duplicate) {
            notify(translate('ABB.Powertrain.Frontend.duplicateUserLimitError'), NOTIFICATION_TYPES.ERROR, false);
        }
    };

    const toggleDefaultUserLimitEmailAlert = (userLimitItem) => {
        const originalData = (props.model.userLimitsData.defaultUserLimits ?? []).find(dataItem => dataItem.kpiIdentifier === userLimitItem.kpiIdentifier);

        setDefaultUserLimits(defaultUserLimits.map(
            el => el.kpiIdentifier === userLimitItem.kpiIdentifier ?
                {
                    ...el,
                    enableEmailAlert: !el.enableEmailAlert
                } : el
        ));

        originalData.enableEmailAlert === !userLimitItem.enableEmailAlert ? setSavePending(false) : setSavePending(true);
    };

    const toggleEmailAlertCheckbox = (userLimit) => {
        setCustomUserLimits(customUserLimits.map(
            el => el.kpiIdentifier === userLimit.kpiIdentifier &&
                el.userLimitName === userLimit.userLimitName &&
                el.comparisonTypeID === userLimit.comparisonTypeID &&
                el.userLimitValue === userLimit.userLimitValue &&
                el.enableEmailAlert === userLimit.enableEmailAlert ? { ...el, enableEmailAlert: !el.enableEmailAlert } : el
        ));
        setSavePending(true);
    };

    const deleteCustomUserLimit = (userLimit) => {
        setRemoveLimitConfirmVisible(true);
        setSelectedUserLimit(userLimit);
    };

    const handleUserLimitsSave = () => {
        props.actions.saveUserLimits(props.asset,
            defaultUserLimits,
            customUserLimits,
            props.model.eTag
        );
        setSavePending(false);
    };

    const renderDefaultUserLimits = () => {
        if ((defaultUserLimits ?? []).length) {
            const groupedDefaultUserLimits = groupBy(defaultUserLimits, 'kpiIdentifier');
            const userLimitsMarkup = [];
            Object.keys(groupedDefaultUserLimits).forEach((key, index) => {
                const userLimitGroup = groupedDefaultUserLimits[key];
                const userLimitItem = userLimitGroup[0];

                userLimitsMarkup.push(
                    <div key={index} className='default-user-limits-row'>
                        <div className='default-user-limits-item default-limit-kpi-name'>
                            {userLimitItem.kpiName}
                        </div>
                        <div className='default-user-limits-item default-limit-value'>
                            {getUserLimitValue(userLimitGroup)}
                        </div>
                        <div className='default-user-limits-item default-limit-enable-alert'>
                            <Switch id={`default_user_limit_checkbox_${index}`}
                                onChange={() => { toggleDefaultUserLimitEmailAlert(userLimitItem); }}
                                checked={userLimitItem.enableEmailAlert} />
                        </div>
                    </div>
                );
            });
            return userLimitsMarkup;
        } else {
            return <div className='no-default-user-limits'>{translate('ABB.Powertrain.Frontend.noDefaultUserLimitsText')}</div>;

        }
    };

    const renderCustomUserLimits = () => {
        return customUserLimits.map((customUserLimit, index) => {
            return (
                <div key={index} className='custom-user-limits-row'>
                    <div className='custom-user-limits-item custom-limit-kpi-name'>
                        {customUserLimit.kpiName}
                    </div>
                    <div className='custom-user-limits-item custom-limit-name'>
                        {customUserLimit.userLimitName}
                    </div>
                    <div className='custom-user-limits-item custom-limit-compariston-type' >
                        {getComparisonType(customUserLimit.comparisonTypeID).label}
                    </div>
                    <div className='custom-user-limits-item custom-limit-value'>
                        {customUserLimit.userLimitValue + ' ' + customUserLimit.unit}
                    </div>
                    <div className='custom-user-limits-item custom-limit-enable-alert'>
                        <Switch id={`custom_user_limit_checkbox_${index}`}
                            onChange={() => { toggleEmailAlertCheckbox(customUserLimit); }}
                            checked={customUserLimit.enableEmailAlert} />
                    </div>
                    <div className='custom-user-limits-item'>
                        <span className='delete-custom-user-limit'
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteCustomUserLimit(customUserLimit);
                            }}>
                            <IconDelete />
                        </span>
                    </div>
                </div>
            );
        });
    };

    const renderRemoveLimitPopup = () => {
        return <GeneralDialog
            show={removeLimitConfirmVisible}
            close={() => setRemoveLimitConfirmVisible(false)}
            notificationType={popupTypes.WARNING}
            title={translate('ABB.Powertrain.Frontend.deleteUserLimitConfirmationTitle')}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.deleteLabel'),
                onClick: () => {
                    pull(customUserLimits, selectedUserLimit);
                    setCustomUserLimits([...customUserLimits]);
                    setSavePending(true);
                    setRemoveLimitConfirmVisible(false);
                    setSelectedUserLimit(null);
                }
            }}
            dialogClassName='delete-user-limit-popup'
            cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.cancelLabel') }}
        >
            <div className='delete-user-limit-confirm-text'>
                {translate('ABB.Powertrain.Frontend.deleteUserLimitConfirmationText')}
            </div>
        </GeneralDialog>;
    };

    const renderUnsavedChangesWarningPopup = () => {
        return <GeneralDialog
            show={unsavedChangesWarningVisible}
            close={() => setUnsavedChangesWarningVisible(false)}
            notificationType={popupTypes.WARNING}
            title={translate('ABB.Powertrain.Frontend.unsavedChangesWarningPopupTitle')}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.discardButtonLabel'),
                onClick: () => {
                    setCustomUserLimits([...props.model.userLimitsData.customUserLimits]);
                    props.close();
                }
            }}
            dialogClassName='unsaved-changes-warning-popup'
            cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.cancelLabel') }}
        >
            <div className='unsaved-changes-warning-text'>
                {translate('ABB.Powertrain.Frontend.unsavedChangesWarningText')}
            </div>
        </GeneralDialog>;
    };
    useEffect(() => {
        if (kpiSelectInput) {
            const filteredkpi = generateKPIs(props.model.trendKpis.kpis, props.model.trendKpis.additionalKpis)?.filter((value) => {
                return value?.displayName?.toLowerCase().includes(kpiSelectInput?.toLowerCase());
            });
            setKpiFilteredOutput(filteredkpi);
        } else {
            setKpiFilteredOutput(generateKPIs(props.model.trendKpis.kpis, props.model.trendKpis.additionalKpis));
        }
    }, [kpiSelectInput]);
    const renderTemplateLimitRow = () => {
        return <div className='template-user-limit'>
            <div className='template-user-limit-item'>
                <GeneralSelect
                    classNamePrefix='kpi-dropdown'
                    id='template_kpi_dropdown'
                    value={selectedKPI}
                    placeholder={translate('ABB.Powertrain.Frontend.selectKPI')}
                    options={kpiFilteredOutput}
                    setSelectedKPIInput={setSelectedKPIInput}
                    onChange={kpi => handleSelectedKPI(kpi)}
                />
            </div>
            <div className='template-user-limit-item'>
                <GeneralInput
                    className='user-limit-input-name'
                    id='template_input_user_limit_name'
                    placeholder={translate('ABB.Powertrain.Frontend.userLimitName')}
                    disabled={!selectedKPI}
                    onChange={(value) => {
                        if (userLimitNameShouldChange(value)) {
                            updateUserLimitName(value);
                        }
                    }}
                    value={userLimitName}
                    validationFunction={(value) => userLimitNameValidation(value)}
                    validationMessage={translate('ABB.Powertrain.Frontend.characterLimitReached')}
                />
            </div>
            <div className='template-user-limit-item'>
                <GeneralSelect
                    classNamePrefix='comparison-dropdown'
                    id='template_comparison_dropdown'
                    value={selectedComparisonType}
                    placeholder={translate('ABB.Powertrain.Frontend.comparisonType')}
                    options={comparisonTypes}
                    isDisabled={!selectedKPI}
                    onChange={comparisonType => handleSelectedComparisonType(comparisonType)}
                />
            </div>
            <div className='template-user-limit-item'>
                <GeneralInput
                    className='user-limit-input-value'
                    id='template_input_user_limit_value'
                    placeholder={translate('ABB.Powertrain.Frontend.userLimitValue')}
                    numeric={true}
                    disabled={!selectedKPI}
                    onChange={updateUserLimitValue}
                    value={userLimitValue}
                    validationFunction={(value) => userLimitValueValidation(value)}
                    validationMessage={translate('ABB.Powertrain.Frontend.customUserLimitValueNotANumber')}
                />
                <GeneralInput
                    className='user-limit-unit'
                    id='template_input_user_limit_unit'
                    type='text'
                    disabled
                    onChange={() => { }}
                    value={userLimitValueUnit}
                />
            </div>
            <div className='template-user-limit-item'>
                <Switch className='template-alert-checkbox'
                    onChange={toggleTemplateEmailAlertCheckbox}
                    checked={enableEmailAlert} />
            </div>
            <div className='template-user-limit-item'>
                <GeneralButton
                    id='add_button'
                    className='btn-primary'
                    disabled={!selectedKPI || !userLimitName || !isValidName || !selectedComparisonType || userLimitValue === '' || !isValidValue}

                    onClick={addCustomUserLimit}
                    text={translate('ABB.Powertrain.Frontend.addButton')} />
            </div>
        </div>;
    };

    const renderUserLimitsDialog = () => {
        return <GeneralDialog
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.apply'),
                onClick: handleUserLimitsSave,
                disabled: !savePending || !loadingSummary || loadingSummary === apiCallStatus.LOADING
            }}
            closeButton={true}
            cancelButtonProps={{ text: translate('ABB.Powertrain.Frontend.cancelLabel') }}
            show={props.show}
            title={translate('ABB.Powertrain.Frontend.userLimits')}
            dialogClassName='user-limits-dialog'
            close={() => {
                savePending ? setUnsavedChangesWarningVisible(true) : props.close();
            }}
        >
            <div className='user-limits-content'>
                <LoadingStatusWrapper useCustomAnchor loadingStatus={loadingSummary} >
                    <Fragment>
                        <div className='default-user-limits'>
                            <div className='section-heading'>
                                {translate('ABB.Powertrain.Frontend.defaultUserLimits')}
                            </div>
                            <div className='default-user-limits-table'>
                                <div className='default-user-limits-headers'>
                                    <div className='default-user-limits-header' id='default-kpi-name-header'>
                                        {translate('ABB.Powertrain.Frontend.kpiName')}
                                    </div>
                                    <div className='default-user-limits-header' id='default-user-limit-header'>
                                        {translate('ABB.Powertrain.Frontend.userLimit')}
                                    </div>
                                    <div className='default-user-limits-header' id='default-enable-email-alert-header'>
                                        {translate('ABB.Powertrain.Frontend.enableEmailAlert')}
                                    </div>
                                </div>
                                <div className='default-user-limits-content'>
                                    {renderDefaultUserLimits()}
                                </div>
                            </div>
                        </div>
                        <div className='custom-user-limits'>
                            <div className='section-heading'>
                                {translate('ABB.Powertrain.Frontend.customUserLimits')}
                            </div>
                            <div className='custom-user-limits-table'>
                                <div className='custom-user-limits-headers'>
                                    <div className='custom-user-limits-header'>
                                        {translate('ABB.Powertrain.Frontend.kpiName')}
                                    </div>
                                    <div className='custom-user-limits-header'>
                                        {translate('ABB.Powertrain.Frontend.userLimitName')}
                                    </div>
                                    <div className='custom-user-limits-header'>
                                        {translate('ABB.Powertrain.Frontend.comparisonType')}
                                    </div>
                                    <div className='custom-user-limits-header'>
                                        {translate('ABB.Powertrain.Frontend.userLimitValue')}
                                    </div>
                                    <div className='custom-user-limits-header'>
                                        {translate('ABB.Powertrain.Frontend.enableEmailAlert')}
                                    </div>
                                </div>
                                {renderTemplateLimitRow()}
                                <div className='custom-user-limits-content'>
                                    {renderCustomUserLimits()}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </LoadingStatusWrapper>
            </div>
        </GeneralDialog >;
    };

    return (
        <Fragment>
            {renderRemoveLimitPopup()}
            {renderUnsavedChangesWarningPopup()}
            {renderUserLimitsDialog()}
        </Fragment>
    );
};

UserLimitsComponent.propTypes = {
    actions: PropTypes.shape({
        getUserLimits: PropTypes.func.isRequired,
        saveUserLimits: PropTypes.func.isRequired,
    }),
    model: PropTypes.shape({
        trendKpis: PropTypes.object.isRequired,
        trendKpisLoadingStatus: PropTypes.string,
        userLimitsData: PropTypes.object.isRequired,
        loadUserLimitsLoadingStatus: PropTypes.string,
        saveUserLimitsLoadingStatus: PropTypes.string,
    }),
    asset: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

export default UserLimitsComponent;
