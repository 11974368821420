import React from 'react';
import PropTypes from 'prop-types';

const IconCircleMinus = ({ width = 16, height = 16, viewBox = '1 0 16 16', color = 'none' }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M8.9443 1C7.08132 1 5.29464 1.7375 3.97731 3.05025C2.65999 4.36301 1.91992 6.14348 1.91992 8C1.91992 9.85652 2.65999 11.637 3.97731 12.9497C5.29464 14.2625 7.08132 15 8.9443 15C10.8073 15 12.594 14.2625 13.9113 12.9497C15.2286 11.637 15.9687 9.85652 15.9687 8C15.9687 6.14348 15.2286 4.36301 13.9113 3.05025C12.594 1.7375 10.8073 1 8.9443 1ZM12.9582 9H4.93037V7H12.9582V9Z' fill='#0F0F0F' />
    </svg>;


};

IconCircleMinus.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

export default IconCircleMinus;
