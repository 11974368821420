import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-unused-modules
export const getActiveDetailedInformationTab = ({ detailedInformation }) => {
    return detailedInformation?.activeTab;
};
// eslint-disable-next-line import/no-unused-modules
export const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.selectedAsset;
};

const getAssetDetails = ({ detailedInformation }) => {
    return {
        loadingStatus: detailedInformation?.assetDetails?.loadingStatus,
        details: detailedInformation?.assetDetails?.data?.details,
        id: detailedInformation?.assetDetails?.data?.id
    };
};

const getSensorProperties = ({ sensorProperties }) => {
    return sensorProperties?.sensorProperties;
};

const getAssetConditionInsights = ({ assetConditionInsights }) => {
    return {
        loadingStatus: assetConditionInsights?.loadingStatus,
        trendKPI: assetConditionInsights?.conditionInsightsTrendKpis?.trendKPI,
        driveKPI: assetConditionInsights?.conditionInsightsDriveStatus?.driveKPI,
        overAllStatus: assetConditionInsights?.assetInfo?.conditionStatus
    };
};
/*export const getActiveTab = ({ operationalParameters }) => {
    return operationalParameters?.activeTab;
};*/
// eslint-disable-next-line import/no-unused-modules
export const getModel = createSelector([
    //getActiveTab,
    getAssetDetails,
    getSensorProperties,
    getAssetConditionInsights
], (
    assetDetails,
    sensorProperties,
    conditionInsights
) => {
    return {
        assetDetails,
        sensorProperties,
        conditionInsights
    };
});
