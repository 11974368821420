import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { LoadingIndicator } from 'sharedComponents';

import colors from 'theme/_colors.scss';

const AuthenticationLoadingScreen = ({ userManager }) => {

	useEffect(() => {
		userManager && userManager.signinRedirect();
	}, []);

	return <div className='disabled-authentication-wrapper'>
		<LoadingIndicator color={colors.white} />
	</div>;
};

AuthenticationLoadingScreen.propTypes = {
	userManager: PropTypes.shape({
		getUser: PropTypes.func,
		signinRedirect: PropTypes.func,
		signinSilent: PropTypes.func
	})
};

export default AuthenticationLoadingScreen;
