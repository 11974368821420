import { createSelector } from 'reselect';

const getTrendKPIsList = createSelector([
    ({ operationalParameters }) => operationalParameters.availableKPIs.trend.data,
], (
    trendKPIs,
) => {
    return trendKPIs;
});

const getSelectedTrendKPIs = ({ operationalParameters }) => {
    return operationalParameters?.preselectedKPIs?.single?.trend;
};

const getSelectedTrendsData = ({ operationalParameters }) => {
    return operationalParameters?.charts?.single?.trend[0]?.kpiList;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

export const getModel = createSelector([
    getTrendKPIsList,
    getSelectedTrendsData,
    getSelectedTrendKPIs,
    getSelectedAsset,
], (
    trendKPIsList,
    selectedTrendsData,
    selectedTrendKPIs,
    selectedAsset,
) => {
    return {
        trendKPIsList,
        selectedTrendsData,
        selectedTrendKPIs,
        selectedAsset,
    };
});
