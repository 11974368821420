import React from 'react';
import { forEach, map, isEqual, find } from 'lodash';

import { acceptedExtensions } from './constants';
import { fileUploadStatus } from '../constants';
import { LoadingIndicator } from 'sharedComponents';
import { IconSuccess, IconError } from 'svgIcons/MotionPortalIcons';

import colors from 'theme/_colors.scss';


export const isExtensionSupported = (fileName) => {
	const extension = fileName.substr(fileName.lastIndexOf('.'));
	return Object.values(acceptedExtensions).find(value => value[0] === extension) ?? false;
};

export const removeBulkFileByIndex = (files, index) => {
	files.splice(index, 1);
	return files;
};

const fileAlreadyUploaded = (newFile, files) => {
	return find(files, (file) =>
		file.fileContent.name === newFile.name && file.fileContent.lastModified === newFile.lastModified
	) !== undefined;
};

export const updateSelectedFiles = (files, newFiles) => {
	map(newFiles, (file) => {
		if (!fileAlreadyUploaded(file, files)) {
			files.push({
				fileContent: file,
				uploadStatus: fileUploadStatus.NEW,
				blobName: '',
			});
		}
	});
	return files;
};

export const getUploadStatusIcon = (uploadStatus) => {
	switch (uploadStatus) {
		case fileUploadStatus.LOADING:
			return <LoadingIndicator size='xsmall' placement='initial' style={{ 'padding': '0px 10px 0px 0px' }} />;
		case fileUploadStatus.SUCCESS:
			return <IconSuccess width='14' height='14' color={colors.jungleGreen} />;
		case fileUploadStatus.FAILED:
			return <IconError color={colors.pomegranate} width='14' height='14' />;
		case fileUploadStatus.NEW:
		default:
			return '';
	}
};

export const updateFileUploadStatus = (files, fileToUpdate, uploadStatus) => {
	forEach(files, (file) => {
		if (isEqual(file, fileToUpdate)) {
			file.uploadStatus = uploadStatus;
		}
	});
	return files;
};

export const setFileBlobName = (files, fileToUpdate, blobName) => {
	forEach(files, (file) => {
		if (isEqual(file, fileToUpdate)) {
			file.blobName = blobName;
		}
	});
	return files;
};
