import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forEach } from 'lodash';

import { apiCallStatus } from 'helpers/constants';
import AlertAlarmOffsetComponent from './AlertAlarmOffsetComponent';
import { getModel } from './selectors';
import * as actions from './actions';
import * as trendKpiListActions from '../../OperationalParameters/SingleChartView/Trend/TrendKPIList/actions';
import { getTrendKPIsForAsset } from '../../commonActions';
import { NOTIFICATION_CHANNEL_ID, NOTIFICATION_TYPE_ID } from './constants';

const AlertAlarmOffsetContainer = (props) => {

	const { actions, model } = props;
	useEffect(() => {
		if (model.trendKpisLoadingStatus !== apiCallStatus.SUCCESS) {
			actions.getTrendKPIsForAsset(model.selectedAsset);
		}
		if (model.trendHealthStatusesLoadingStatus !== apiCallStatus.SUCCESS) {
			actions.getHealthStatuses(model.selectedAsset);
		}
		return () => {
			actions.clearStore();
		};
	}, []);

	useEffect(() => {
		if (model?.selectedAsset) {
			forEach(NOTIFICATION_TYPE_ID, (typeId) => {
				actions.getHealthStatusEmailNotificationAction({
					notificationTypeID: typeId,
					channelID: NOTIFICATION_CHANNEL_ID.email,
					assetID: model?.selectedAsset?.smSeIdentfier
				});
			});
		}
	}, [model?.selectedAsset]);

	useEffect(() => {
		if (model.trendKpisLoadingStatus === apiCallStatus.SUCCESS) {
			actions.cloneTrendKpis();
		}
	}, [model.trendKpisLoadingStatus]);

	useEffect(() => {
		if (model.trendHealthStatusesLoadingStatus === apiCallStatus.SUCCESS) {
			actions.cloneHealthStatuses();
		}
	}, [model.trendHealthStatusesLoadingStatus]);

	return <AlertAlarmOffsetComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...actions, ...trendKpiListActions, getTrendKPIsForAsset }, dispatch)
	};
};

const AlertAlarmOffsetContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(AlertAlarmOffsetContainer);

export default AlertAlarmOffsetContainerConn;
