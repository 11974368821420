import React from 'react';
import { map, sortBy } from 'lodash';
import { ASSET_TYPES_MS, allAssetTypes } from 'helpers/constants';
import { getAssetIcon } from 'helpers/icons';


export const getFormattedAssetTypes = (translate) => {
    const options = [];

    const types = Object.keys(ASSET_TYPES_MS);

    map(types, (type) => {
        const label = translate(`ABB.Powertrain.Frontend.${ASSET_TYPES_MS[type].toLowerCase()}Label`) ||
            ASSET_TYPES_MS[type];
        options.push({
            id: ASSET_TYPES_MS[type],
            title: <>{getAssetIcon(ASSET_TYPES_MS[type], { width: 16, height: 16 })} {label}</>,
            assetType: label
        });
    });

    return [
        {
            id: allAssetTypes,
            title: translate('ABB.Powertrain.Frontend.allassettypesLabel')
        },
        ...sortBy(options, [option => option?.assetType])
    ];
};

export const getFormattedAssetType = (type, translate) => {
    if (type) {
        const label = translate(`ABB.Powertrain.Frontend.${type.toLowerCase()}Label`) || type;
        return {
            id: type,
            title: <>{getAssetIcon(type, { width: 16, height: 16 })} {label}</>,
        };
    }
};
