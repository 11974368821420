import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { filterTabs } from 'helpers/constants';
import AssetList from './AssetList';
import PowertrainList from './PowertrainList';

import './style.scss';
import { useQuerySetter } from 'sharedHooks/useQuery';

const AssetAndPowertrainListComponent = ({
    model: {
        activeTab,
        assets,
        powertrains,
        generalFilterLoadingStatuses,
        assetsLoadingStatus,
        powertrainsLoadingStatus,
    },
}) => {
    const querySetter = useQuerySetter();

    const onAssetAssetFromPowertrainClick = useCallback((assetId) => { querySetter.routeToSelectedAssetFromPowertrainList(assetId); }, []);
    const onPowertrainClick = useCallback((powertrainId) => querySetter.routeToSelectedPowertrain(powertrainId), []);
    const onAssetAssetClick = useCallback((assetId) => querySetter.routeToSelectedAssetFromAssetList(assetId), []);

    return <div className='asset-and-powertrain-list-container'>
        {activeTab === filterTabs.POWERTRAINS
            ? <LoadingStatusWrapper loadingStatus={[...generalFilterLoadingStatuses, powertrainsLoadingStatus]}>
                <PowertrainList
                    powertrains={powertrains}
                    onAssetClick={onAssetAssetFromPowertrainClick}
                    onPowertrainClick={onPowertrainClick}
                />
            </LoadingStatusWrapper>
            : <LoadingStatusWrapper loadingStatus={[...generalFilterLoadingStatuses, assetsLoadingStatus]}>
                <AssetList assets={assets} onAssetClick={onAssetAssetClick} />
            </LoadingStatusWrapper>
        }
    </div>;
};

AssetAndPowertrainListComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
        assets: PropTypes.any,
        powertrains: PropTypes.any,
        assetsLoadingStatus: PropTypes.string,
        powertrainsLoadingStatus: PropTypes.string
    })
};

export default AssetAndPowertrainListComponent;
