import { createSelector } from 'reselect';
import { find } from 'lodash';

const getActiveTab = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.activeTab;
};

const getSelectedEvent = ({ eventLog }) => {
	return eventLog.selectedEvent;
};
const getDateKind = ({ eventLog }) => {
	return eventLog.dateKind;
};

const getSelectedAsset = ({ overview, eventLog }) => {
	return find(overview?.assets?.data, (asset) => {
		return asset?.componentID === eventLog?.selectedEvent?.rawEvent?.componentID;
	});
};

export const getModel = createSelector([
	getActiveTab,
	getSelectedEvent,
	getDateKind,
	getSelectedAsset
],
	(
		activeTab,
		selectedEvent,
		dateKind,
		selectedAsset
	) => {
		return {
			activeTab,
			selectedEvent,
			dateKind,
			selectedAsset
		};
	});
