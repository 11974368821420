import { createSelector } from 'reselect';


const getNotificationSettingsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.notificationChannels?.loadingStatus;
};

const getNotificationChannels = ({ notificationSettings }) => {
    return notificationSettings?.notificationChannels?.data;
};

const getSelectedNotificationGroups = ({ notificationSettings }) => {
    return notificationSettings?.selectedNotificationGroups;
};

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

const getNotificationSettings = createSelector([
    getNotificationSettingsLoadingStatus,
    getNotificationChannels,
    getSelectedNotificationGroups,
], (
    notificationSettingsLoadingStatus,
    notificationChannels,
    selectedNotificationGroups,
) => {
    return {
        notificationSettingsLoadingStatus,
        notificationChannels,
        selectedNotificationGroups
    };
});

export const getModel = createSelector(
    [
        getNotificationSettings,
        getSelectedOrganization
    ],
    (
        notificationSettings,
        organization
    ) => {
        return {
            notificationSettings,
            organization
        };
    }
);
