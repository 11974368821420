import localStorageHelper from './localStorageHelper';
import logger from 'helpers/logger';

function getClarityID() {
    try {
        return localStorageHelper.nonEncrypted.getItem('environmentProperties')?.clarityID;
    }
    catch {
        logger.error('Clarity Id retrieval error.');
        return '';
    }

}
export const clarity = () => {
    return (function (c, l, a, r, i, t, y) {
        if (!i) { return; }
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', getClarityID());
};
