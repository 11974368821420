import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import {
    CONDITION_STATUS,
    WARNING_COLOR,
    ERROR_COLOR,
    ALERT_LINE_COLOR,
    ALARM_LINE_COLOR,
    HIGH_ERROR_THRESHOLD,
    HIGH_WARNING_THRESHOLD,
    LOW_ERROR_THRESHOLD,
    LOW_WARNING_THRESHOLD,
    CONDITTION_INDICATOR_FILTERS,
} from './constants';

// eslint-disable-next-line import/no-unused-modules
export const getFormattedTimeSeriesWithMinMax = (timeSeriesData) => {
    return map(timeSeriesData, (o) => { return { ...o, min: o?.value - 0.5, max: o?.value + 0.5, qualityFlag: 9 }; });
};
// eslint-disable-next-line import/no-unused-modules
export const getChartConfigForTrends = (timeSeriesData, key, name, measurementTypeKey, unit, formatTimeseries) => {
    return [{
        color: '#000000',
        kpiIdentifier: key,
        config: {
            appendCodeToTranslation: true,
            displayName: name,
            measurementTypeKey: measurementTypeKey,
            offset: 0,
            precision: 0,
            unit: unit
        },
        values: formatTimeseries ? getFormattedTimeSeriesWithMinMax(timeSeriesData) : timeSeriesData
    }];
};

export const getConditionStatus = (conditionValue) => {
    return CONDITION_STATUS[conditionValue];
};
const getLatestLimitValue = (limitArray) => {
    return limitArray?.length && maxBy(limitArray, (item) => new Date(item?.timestamp));
};

// eslint-disable-next-line import/no-unused-modules
export const getFormatedAlarmAlertLimits = (conditionTrends) => {
    const highAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_WARNING_THRESHOLD);
    const highErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_ERROR_THRESHOLD);
    const lowErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_ERROR_THRESHOLD);
    const lowAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_WARNING_THRESHOLD);
    const alarmAlertLimits = [];

    const errorMaxValue = getLatestLimitValue(highErrorLimitValues?.[0]?.values);
    const errorMinValue = getLatestLimitValue(lowErrorLimitValues?.[0]?.values);
    const highAlertMinValue = getLatestLimitValue(highAlertLimits?.[0]?.values);
    const lowAlertMinValue = getLatestLimitValue(lowAlertLimits?.[0]?.values);

    if (highErrorLimitValues?.length) {
        alarmAlertLimits.push({ from: errorMaxValue?.value, to: null, color: ERROR_COLOR });
    }
    if (lowErrorLimitValues?.length) {
        alarmAlertLimits.push({ from: null, to: errorMinValue?.value, color: ERROR_COLOR });
    }

    if (highAlertLimits?.length) {
        const fromValue = highErrorLimitValues?.length ? highAlertMinValue?.value : null;
        alarmAlertLimits.push({ from: fromValue, to: errorMaxValue?.value, color: WARNING_COLOR });
    }

    if (lowAlertLimits?.length) {
        const toValue = highErrorLimitValues?.length ? lowAlertMinValue?.value : null;
        alarmAlertLimits.push({ from: errorMinValue?.value, to: toValue, color: WARNING_COLOR });
    }

    return alarmAlertLimits;
};
// eslint-disable-next-line import/no-unused-modules
export const getFormattedAlarmAlertLines = (conditionTrends) => {
    const highAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_WARNING_THRESHOLD);
    const highErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_ERROR_THRESHOLD);
    const lowErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_ERROR_THRESHOLD);
    const lowAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_WARNING_THRESHOLD);
    const alarmAlertLimits = [];
    const highErrorLine = highErrorLimitValues?.length ? getLatestLimitValue(highErrorLimitValues[0]?.values) : [];
    const lowErrorLine = lowErrorLimitValues?.length ? getLatestLimitValue(lowErrorLimitValues[0]?.values) : [];
    const highAlertLine = highAlertLimits?.length ? getLatestLimitValue(highAlertLimits[0]?.values) : [];
    const lowAlertLine = highAlertLimits?.length ? getLatestLimitValue(lowAlertLimits[0]?.values) : [];
    alarmAlertLimits.push(
        ...highErrorLine ? [{ value: highErrorLine?.value, color: ALARM_LINE_COLOR }] : [],
        ...lowErrorLine ? [{ value: lowErrorLine?.value, color: ALARM_LINE_COLOR }] : [],
        ...highAlertLine ? [{ value: highAlertLine?.value, color: ALERT_LINE_COLOR }] : [],
        ...lowAlertLine ? [{ value: lowAlertLine?.value, color: ALERT_LINE_COLOR }] : []
    );
    return alarmAlertLimits;

};

export const getCondtionTrendIndicators = (conditionTrends) => {
    return conditionTrends?.filter((conditionItem) => CONDITTION_INDICATOR_FILTERS?.includes(conditionItem?.kpiType));
};
// eslint-disable-next-line import/no-unused-modules
export const getHighErrorThresholdValue = (conditionTrends) => {
    const highErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_ERROR_THRESHOLD);
    if (highErrorLimitValues?.length) {
        try {
            const errorMaxValue = maxBy(highErrorLimitValues[0]?.values, (item) => new Date(item?.timestamp));
            return errorMaxValue?.value;
        } catch (error) {
            console.log('error date conversion', error);
        }
        return null;
    }
};

export const getLowErrorThresholdValue = (conditionTrends) => {
    const lowErrorLimitValues = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_ERROR_THRESHOLD);
    if (lowErrorLimitValues?.length) {
        try {
            const errorMinValue = maxBy(lowErrorLimitValues[0]?.values, (item) => new Date(item?.timestamp));
            return errorMinValue?.value;
        } catch (error) {
            console.log('error date conversion', error);
        }
        return null;
    }
};

// eslint-disable-next-line import/no-unused-modules
export const getHighAlertThresholdValue = (conditionTrends) => {
    const highAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === HIGH_WARNING_THRESHOLD);
    if (highAlertLimits?.length) {
        try {
            const alertMaxValue = maxBy(highAlertLimits[0]?.values, (item) => new Date(item?.timestamp));
            return alertMaxValue?.value;
        } catch (error) {
            console.log('error date conversion', error);
        }
        return null;
    }
};
// eslint-disable-next-line import/no-unused-modules
export const getLowAlertThresholdValue = (conditionTrends) => {
    const lowAlertLimits = conditionTrends?.filter((conditionItem) => conditionItem?.type === LOW_WARNING_THRESHOLD);
    if (lowAlertLimits?.length) {
        try {
            const alertMinValue = maxBy(lowAlertLimits[0]?.values, (item) => new Date(item?.timestamp));
            return alertMinValue?.value;
        } catch (error) {
            console.log('error date conversion', error);
        }
        return null;
    }
};


