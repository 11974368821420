import React from 'react';

const ImageHolder = ({ placeholder = null, imageToRender = null, selectedIndex = 0, onImageHolderClick }) => {
	return (
		<div
			className='image-holder'
			onClick={() => onImageHolderClick(selectedIndex)}>
			{
				imageToRender
					? <img src={imageToRender} loading='lazy' />
					: placeholder
			}
		</div>
	);
};

export default ImageHolder;
