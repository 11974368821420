import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { translate } from 'helpers/translateHelper';
import { opButtons, multiChartOptions } from './constants';
import { Trend } from './SingleChartView/Trend';
import { ScatterPlot } from './SingleChartView/ScatterPlot';
import { Histogram } from './SingleChartView/Histogram';
import { VibrationFFT } from './VibrationFFT';
import { TrendCharts } from './MultiChartView/TrendCharts';
import { MAX_TREND_SIGNALS } from '../constants';
import { setColor } from '../commonTrendHelpers';
import { useCapability } from 'sharedHooks';

const getTabsVisibility = (capability) => {
    const capabilityVibrationFFT = useCapability();
    return {
        [opButtons.trends]: capability.hasTrendCapability(),
        [opButtons.scatterPlot]: capability.hasScatterCapability(),
        [opButtons.histogram]: capability.hasHistogramCapability(),
        // [opButtons.vibrationFFT]: capability.hasVibrationFFTCapability(),
        [opButtons.vibrationFFT]: capabilityVibrationFFT.hasVibrationFFTCapability(), //legacy capability check added for VibrationFFT
    };
};

export const getToggleOptions = (capability, multiChart) => {
    const allTabs = [{
        label: translate('ABB.Powertrain.Frontend.opTrendsTabBtn'),
        value: opButtons.trends,
        component: multiChart === multiChartOptions.SINGLE ? Trend : TrendCharts
    },
    {
        label: translate('ABB.Powertrain.Frontend.opScatterPlotTabBtn'),
        value: opButtons.scatterPlot,
        component: ScatterPlot
    },
    {
        label: translate('ABB.Powertrain.Frontend.opHistogramTabBtn'),
        value: opButtons.histogram,
        component: Histogram,
    },
    {
        label: translate('ABB.Powertrain.Frontend.opVibrationFFTTabBtn'),
        value: opButtons.vibrationFFT,
        component: VibrationFFT
    }];
    const tabVisibility = getTabsVisibility(capability);

    return filter(allTabs, (tab) => {
        return tabVisibility[tab.value];
    });
};

const hasKpi = (kpiList, kpi) => {
    const flatKPIList = flatMap(kpiList, (kpiGroup) => kpiGroup.timeseries);

    return !!find(flatKPIList, (kpifomList) => {
        return kpifomList.timeseriesKey === kpi;
    });
};

export const isAssetCompatibleWithSelectedKPIs = (asset, selectedKPIs) => {
    return selectedKPIs.length && selectedKPIs[0]?.assetTypeID === asset?.componentType?.typeID ? true : false;
};

export const isAssetCompatibleWithSelectedTrendKPIs = (asset, assetTypeID) => {
    return assetTypeID && assetTypeID === asset?.componentType?.typeID ? true : false;
};

export const getCommonTrendKPIs = (newTrendKPIs, selectedTrendKPIs) => {
    const commonTrendKPIs = [];

    for (const selectedKPI of selectedTrendKPIs) {
        hasKpi(newTrendKPIs, selectedKPI) && commonTrendKPIs.push(selectedKPI);
    }
    return commonTrendKPIs;
};

export const getAvailableKPI = (newKPIs, selectedKPI) => {
    return find(newKPIs, newKPI => newKPI.timeseriesKey === selectedKPI?.timeseriesKey) ? selectedKPI : null;
};

export const addKPISelection = (selectedKPIs, newKPI) => {
    selectedKPIs.push(newKPI);
    if (selectedKPIs.length > MAX_TREND_SIGNALS) {
        selectedKPIs.shift();
    }
    return selectedKPIs;
};

export const addSelectOpTrendKPIData = (selectedTrendsData, newSelectedTrendKpiArray) => {

    if (!selectedTrendsData?.length && newSelectedTrendKpiArray?.length > 1) {
        newSelectedTrendKpiArray.forEach((newSelectedTrend) => {
            selectedTrendsData.push({
                kpiIdentifier: newSelectedTrend?.config?.timeseriesKey,
                values: newSelectedTrend.values
            });
        });
        return setColor(selectedTrendsData);
    }

    const newSelectedTrend = newSelectedTrendKpiArray && newSelectedTrendKpiArray.length ? newSelectedTrendKpiArray[0] : [];

    if (!find(selectedTrendsData, (selectedTrend) => selectedTrend?.kpiIdentifier === newSelectedTrend?.config?.timeseriesKey)) {
        selectedTrendsData.push({
            kpiIdentifier: newSelectedTrend?.config?.timeseriesKey,
            values: newSelectedTrend.values
        });
        if (selectedTrendsData.length > MAX_TREND_SIGNALS) {
            selectedTrendsData.shift();
        }
    }

    selectedTrendsData = map(selectedTrendsData, (selectedTrend) => {
        const found = find(newSelectedTrendKpiArray, (newSelected) => newSelected.config.timeseriesKey === selectedTrend?.kpiIdentifier);
        if (found) {
            return {
                color: selectedTrend.color,
                kpiIdentifier: found?.config?.timeseriesKey,
                values: found.values
            };
        }
        else {
            return selectedTrend;
        }
    });

    selectedTrendsData?.length && setColor(selectedTrendsData);

    return selectedTrendsData;
};

export const removeSelectedOpTrendKpiData = (selectedTrendDataArrays, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrendDataArrays, (trendDataArray) => trendDataArray?.kpiIdentifier === unselectedKPI.timeseriesKey);
    foundIndex > -1 && selectedTrendDataArrays?.splice(foundIndex, 1);

    return selectedTrendDataArrays;
};

export const removeSelectedTrendKpi = (selectedTrends, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrends, (kpi) => kpi === unselectedKPI.timeseriesKey);
    foundIndex > -1 && selectedTrends.splice(foundIndex, 1);

    return selectedTrends;
};
