import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconNoImage = ({ width = 100, height = 100, color = colors.alto, backgroundColor = colors.wildSand }) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 100 100'>
		<g id='Group_3956' transform='translate(2786 -13512)'>
			<rect id='Rectangle_2061' width='100' height='100' transform='translate(-2786 13512)' fill={backgroundColor} />
			<g id='insert_photo-24px' transform='translate(-2760 13538)'>
				<path id='Path_1201' d='M0,0H48V48H0Z' fill='none' />
				<path id='Path_1202' d='M40.333,7.667V40.333H7.667V7.667H40.333m0-4.667H7.667A4.68,4.68,0,0,0,3,7.667V40.333A4.68,4.68,0,0,0,7.667,45H40.333A4.68,4.68,0,0,0,45,40.333V7.667A4.68,4.68,0,0,0,40.333,3ZM28.993,23.673l-7,9.03L17,26.66l-7,9.007H38L28.993,23.673Z' fill={color} />
			</g>
		</g>
	</svg>
		;
};

IconNoImage.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	backgroundColor: PropTypes.string,
};

export default IconNoImage;
