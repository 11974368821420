import { createSelector } from 'reselect';
import { getActiveDetailedInformationTab } from '../../selectors';

const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const getSelectedHistogramKPIData = ({ operationalParameters }) => {
    return operationalParameters.histogram.data;
};

const getHistogramDataAndConfig = createSelector([
    getSelectedHistogramKPIData
],
    (data) => {
        return data.length ?
            {
                histogramData: data[0].values,
                histogramDataConfig: data[0].config
            }
            : {
                histogramData: [],
                histogramDataConfig: null
            };
    });

const getHistogramLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters.histogram.loadingStatus;
};

const getHistogramKpisData = ({ operationalParameters }) => {
    return operationalParameters.histogramKpis.data;
};

const getHistogramKpisLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters.histogramKpis.loadingStatus;
};

const getSelectedKpi = ({ operationalParameters }) => {
    return operationalParameters.selectedHistogramKPI;
};

export const getModel = createSelector([
    getActiveDetailedInformationTab,
    getDateSelection,
    getHistogramDataAndConfig,
    getHistogramLoadingStatus,
    getHistogramKpisData,
    getHistogramKpisLoadingStatus,
    getSelectedKpi
],
    (
        activeDetailedInformationTab,
        dateSelection,
        histogramDataAndConfig,
        histogramDataLoadingStatus,
        histogramKpis,
        histogramKpisLoadingStatus,
        selectedKpi,
    ) => {
        return {
            activeDetailedInformationTab,
            dateSelection,
            ...histogramDataAndConfig,
            histogramDataLoadingStatus,
            histogramKpis,
            histogramKpisLoadingStatus,
            selectedKpi,
        };
    });
