import React from 'react';
import PropTypes from 'prop-types';
import { GeneralDialog } from 'sharedComponents';
import './style.scss';


const EulaModalComponent = ({
    show,
    closeModal,
    closable = true,
    title,
    link,
    acceptButtonProps = null,
    persistent = false,
    closeOnAcceptOnly = false
}) => {
    const closeFunction = closable ? closeModal : () => { };

    return <GeneralDialog
        show={show}
        modalClassName='eula-modal-container'
        close={closeFunction}
        title={title}
        closeButton={closable && !closeOnAcceptOnly}
        acceptButtonProps={acceptButtonProps}
        persistent={persistent}
        closeOnAcceptOnly={closeOnAcceptOnly}
    >
        <div>Please take a moment to review and agree to our EULA. By clicking on the link provided here: <a href={link} target='_blank' rel='noreferrer'>EULA</a>,- you will be able to access the full text of the applicable EULA. Please read this document carefully as it contains important information about each party&apos;s rights and responsibilities and make sure you understand and agree before continuing.</div>
        <br />
        <div>By clicking on the <b>&apos;Accept&apos;</b> button, you confirm that you have read, understood, and agreed to our terms and conditions. Thank you for your cooperation.</div>
    </GeneralDialog >;
};


EulaModalComponent.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    closable: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    acceptButtonProps: PropTypes.object,
    persistent: PropTypes.bool,
    closeOnAcceptOnly: PropTypes.bool
};

export default EulaModalComponent;

