import { createSelector } from 'reselect';

const getChartData = ({ chartView }) => {
    return chartView.chartData;
};

const getSelectedKpi = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel?.selectedKpi?.kpi;
};

const getAsset = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel?.selectedKpi?.asset;
};
const getChartLoadingStatus = ({ chartView }) => {
    return chartView.chartStatus.loadingStatus;
};

const getSelectedKPIType = ({ chartView }) => {
    return chartView.selectedKPIType;
};

const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const getIsZoomed = ({ chartView }) => {
    return chartView.trendChartZoom.isZoomed;
};


export const getModel = createSelector([
    getSelectedKpi,
    getChartData,
    getAsset,
    getChartLoadingStatus,
    getSelectedKPIType,
    getDateSelection,
    getIsZoomed
],
    (
        selectedKpi,
        chartData,
        asset,
        chartLoadingStatus,
        selectedKPIType,
        dateSelection,
        isZoomed
    ) => {
        return {
            selectedKpi,
            chartData,
            asset,
            chartLoadingStatus,
            selectedKPIType,
            dateSelection,
            isZoomed
        };
    });
