import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager } from '../RestManager';
import { assetObjectToComponentRequest } from '../helpers';
import { containers, uploadFileToBlobStorage } from './blobHelper';

class BulkUpload {
	static processBulkUpload(asset, files) {
		const endpoint = formatEndpoint(endpoints.component.bulkUpload.processBulkUpload);
		return RestManager.put(endpoint, {
			files,
			component: assetObjectToComponentRequest(asset)
		});
	}

	static getBulkUploadStatus(asset, startDate, endDate) {
		const endpoint = formatEndpoint(endpoints.component.bulkUpload.getBulkUploadStatus);
		return RestManager.post(endpoint, {
			component: assetObjectToComponentRequest(asset),
			startDate,
			endDate,
		});
	}

	static initializeBulkUpload(asset) {
		const endpoint = formatEndpoint(endpoints.component.bulkUpload.initializeBulkUpload);
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static uploadFile(url, blobname, file) {
		const blobUrl = url.replace(`${containers.BULK_UPLOAD}/${blobname}`, '');
		return uploadFileToBlobStorage(blobUrl, containers.BULK_UPLOAD, blobname, file, file.size);
	}
}

export default BulkUpload;
