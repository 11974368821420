import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconRemove = ({ width = 30, height = 30, viewBox = '-5 -5 50 50', color = colors.black }) => {

	return <svg id='Remove' width={width} height={height} viewBox={viewBox}>
		<line x1='10.02' y1='37.51' x2='37.68' y2='9.85' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<line x1='10.02' y1='9.85' x2='37.68' y2='37.51' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
	</svg>;
};

IconRemove.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconRemove;
