import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import AssetOverviewComponent from './AssetOverviewComponent';
import * as actions from './actions';
import { getModel } from './selectors';


const AssetOverviewContainer = (props) => {
    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <LoadingStatusWrapper useCustomAnchor loadingStatus={[
        ...props.generalFilterLoadingStatuses
    ]}>
        <AssetOverviewComponent {...props} />
    </LoadingStatusWrapper>;
};

AssetOverviewContainer.propTypes = {
    actions: PropTypes.shape({
        clearStore: PropTypes.func
    }),
    generalFilterLoadingStatuses: PropTypes.array
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetOverviewContainer);
