import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { GeneralDialog } from 'sharedComponents';
import { requestStatuses, responseStatuses } from '../../../constants';
import { getRequestStatusText, getResponseStatusText, isRequestStatusRequested } from '../helpers';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';

import './style.scss';

const CollectRawDataComponent = ({
    model: {
        loadingStatuses,
        rawDataCollectionRequest,
        rawDataCollectionResponseMessage,
    },
    actions: {
        collectRawData
    },
    show,
    close,

}) => {
    const { t: translate } = useTranslation();

    return <GeneralDialog
        show={show}
        close={close}
        title={translate('ABB.Powertrain.Frontend.collectRawDataDialogTitle')}
        dialogClassName='collect-raw-data-dialog'
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
        }}
        acceptButtonProps={{
            disabled: rawDataCollectionRequest?.featureValue === requestStatuses.PENDING,
            text: translate('ABB.Powertrain.Frontend.requestButton'),
            onClick: () => {
                collectRawData().then(close);
            }
        }}
        closeButton={true}
    >
        <Container fluid className='collect-raw-data-container gx-0'>
            <LoadingStatusWrapper
                useCustomAnchor
                transparent
                loadingStatus={loadingStatuses}>
                <Row className='info-text-row gx-0'>
                    <Col>{translate('ABB.Powertrain.Frontend.collectRawDataInfoText')}</Col>
                </Row>
                <Row className='info-text-row gx-0'>
                    <Col>{translate('ABB.Powertrain.Frontend.collectRawDataNoteText')}</Col>
                </Row>
                <Row className='request-status-row gx-0'>
                    <Col>{translate('ABB.Powertrain.Frontend.requestStatusLabel')}&nbsp;
                        <span className='request-status-text'>
                            {rawDataCollectionRequest && getRequestStatusText(rawDataCollectionRequest?.featureValue)}
                        </span>
                    </Col>
                    {isRequestStatusRequested(rawDataCollectionRequest?.featureValue) &&
                        <Col>{convertUtcToLocalDateTime(rawDataCollectionRequest?.timeStamp)}</Col>}
                </Row>
                {rawDataCollectionResponseMessage?.featureValue !== null &&
                    rawDataCollectionResponseMessage?.featureValue !== responseStatuses.COMPLETED_SUCCESSFULLY &&
                    <Row className='error-reason-row gx-0'>
                        <Col>{translate('ABB.Powertrain.Frontend.errorReasonLabel')}&nbsp;
                            <span className='error-reason-text'>
                                {getResponseStatusText(rawDataCollectionResponseMessage?.featureValue)}
                            </span>
                        </Col>
                    </Row>}
            </LoadingStatusWrapper>
        </Container>
    </GeneralDialog>;
};

CollectRawDataComponent.propTypes = {
    model: PropTypes.shape({
        loadingStatuses: PropTypes.arrayOf(PropTypes.string),
        rawDataCollectionRequest: PropTypes.shape({
            featureValue: PropTypes.number,
            timeStamp: PropTypes.string
        }),
        rawDataCollectionResponseMessage: PropTypes.shape({
            featureValue: PropTypes.number
        }),
    }),
    actions: PropTypes.shape({
        collectRawData: PropTypes.func
    }),
    show: PropTypes.bool,
    close: PropTypes.func,
};

export default CollectRawDataComponent;
