import React, { useEffect } from 'react';
import ChartViewComponent from './ChartViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getModel } from './selectors';
import * as actions from './actions';


const ChartViewContainer = (props) => {

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <ChartViewComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartViewContainer);
