import { ComponentApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes, reNameStatus } from './constants';
import logger from 'helpers/logger';
import { getApiActionCreator } from 'helpers/actionHelper';
import { loadAssets } from '../../../../GeneralFilter/GeneralFilterModal/actions';
import { sortBy } from 'lodash';

const renameAssetAction = getApiActionCreator(actionTypes.UPDATE_ASSET_RENAME);

export const updateAssetList = () => {
    return (dispatch, getState) => {
        const generalFilter = getState().generalFilter;
        const selectedFilters = generalFilter?.selectedFilters;
        const isCIAMSyncFlag = generalFilter?.refeshCIAMSync;
        const organizations = selectedFilters?.organizations;
        const isAdmin = getState().overview?.user?.data?.isAdminFromCIAM;
        const selectedOrganizations = sortBy(organizations, (o) => (o.organizationName || '').toLowerCase());
        let refreshFlag = true;
        if (isAdmin && !isCIAMSyncFlag) {
            refreshFlag = false;
        }
        loadAssets(selectedOrganizations, refreshFlag, dispatch);
    };
};

export const updateAsset = (asset) => {
    return (dispatch) => {
        dispatch(renameAssetAction(apiCallStatus.LOADING));

        ComponentApi.updateAsset(asset)
            .then((data) => {
                dispatch(renameAssetAction(apiCallStatus.SUCCESS));
                dispatch({ type: actionTypes.UPDATE_ASSET_RENAME_SUCCESS, data: { ...asset, eTag: data.eTag } });
                logger.debug('Asset was successfully updated');
                dispatch(updateAssetList());
                dispatch({ type: actionTypes.CLOSE_DIALOGUE, data: { reName: reNameStatus.reNameAsset } });
            }, (err) => {
                dispatch(renameAssetAction(apiCallStatus.ERROR, null, err));
            });
    };
};
