import { useEffect, useRef } from 'react';

const useEffectOnlyOnce = (callback, dependencies, conditionCallback) => {
	const calledOnce = useRef(false);

	useEffect(() => {
		if (calledOnce.current) {
			return;
		}
		if (conditionCallback(dependencies)) {
			callback(dependencies);

			calledOnce.current = true;
		}
	}, [callback, conditionCallback, dependencies]);
};
export default useEffectOnlyOnce;
