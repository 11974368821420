import { CAPABILITIES } from 'helpers/constants';
import featureConfigHelper from 'helpers/featureConfigHelper';

const isExpertReportUploadEnabled = new featureConfigHelper().isExpertReportUploadEnabled();

export const actionTypes = {
    GET_REPORTS: 'reports/GET_REPORTS',
    DOWNLOAD_REPORT: 'reports/DOWNLOAD_REPORT',
    PUBLISH_REPORT: 'reports/PUBLISH_REPORT',
    REJECT_REPORT: 'reports/REJECT_REPORT',
    SET_FILTERS: 'reports/SET_FILTERS',
    OPEN_GENERATOR_MODAL: 'reports/OPEN_GENERATOR_MODAL',
    CLOSE_GENERATOR_MODAL: 'reports/CLOSE_GENERATOR_MODAL',
    GET_CAPABILITIES: 'reports/GET_CAPABILITIES',
    SET_REPORT_GENERATION_TYPE: 'reports/SET_REPORT_GENERATION_TYPE',
    GENERATE_REPORT: 'reports/GENERATE_REPORT',
    GENERATE_FLEET_REPORT: 'reports/GENERATE_FLEET_REPORT',
    TOGGLE_FILTER_VISIBILITY: 'reports/TOGGLE_FILTER_VISIBILITY',
    SET_FILTER_VISIBILITY_FROM_LS: 'reports/SET_FILTER_VISIBILITY_FROM_LS',
    OPEN_UPLOAD_MODAL: 'reports/OPEN_UPLOAD_MODAL',
    SET_REPORT_UPLOAD_TYPE: 'reports/SET_REPORT_UPLOAD_TYPE',
    CLOSE_UPLOAD_MODAL: 'reports/CLOSE_UPLOAD_MODAL',
    UPLOAD_REPORT: 'reports/UPLOAD_REPORT',
    GET_REPORT_CAPABILITIES: 'reports/GET_REPORT_CAPABILITIES',
    GET_SENSOR_OF_ASSETS: 'reports/GET_SENSOR_OF_ASSETS',
    DELETE_REPORT: 'reports/DELETE_REPORT',
};

export const reportStateProps = {
    IS_REPORT_FILTER_VISIBLE: 'reports.isFilterVisible'
};

export const reportStatuses = {
    Requested: 'Requested',
    Revoked: 'Revoked',
    Rejected: 'Rejected',
    Completed: 'Completed',
    Pending: 'Pending',
    Error: 'Error',
    InProgress: 'InProgress',
    reportTypeId: 3
};

export const reportErrorCodes = {
    '-1': 'UnexpectedError',
    '0': 'GeneralError',
    '1': 'InvalidAuthorization',
    '2': 'InvalidReportType',
    '10': 'InsufficientMeasurements',
    '20': 'InsufficientEligibleAssets',
};

export const reportTypes = {
    InstantAssetMotor: 'InstantAssetMotor',
    Drive: 'Drive',
    ExpertReport: 'ExpertReport',
    ExpertReportDrive: 'ExpertReportDrive',
    DriveFleetReport: 'DriveFleetReport',
    MotorFleetReport: 'MotorFleetReport',
    MachSenseRAssetExpertReport: 'ExpertReportMachSenseR',

};

export const instantReportTypes = [
    { reportTypeId: reportTypes.InstantAssetMotor, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelInstantAssetMotor' },
    { reportTypeId: reportTypes.Drive, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelDrive' },
];

export const expertReportTypes = [
    { reportTypeId: reportTypes.ExpertReport, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelExpertReport' },
    { reportTypeId: reportTypes.ExpertReportDrive, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelExpertReportDrive' },
    { reportTypeId: reportTypes.MachSenseRAssetExpertReport, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelMachSenseRAssetExpertReport' }
];

export const fleetReportTypes = [
    { reportTypeId: reportTypes.MotorFleetReport, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelMotorFleetReport' },
    //Disabled because current version of Drive Fleet reports is not in a state to be exposed to the end user
    //{ reportTypeId: reportTypes.DriveFleetReport, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelDriveFleetReport' }
];

export const reportTypesForFiltering = [
    { reportTypeId: undefined, reportTypeLabel: 'ABB.Powertrain.Frontend.reportsTypeLabelAll' },
    ...instantReportTypes,
    ...isExpertReportUploadEnabled ? expertReportTypes : [],
    ...fleetReportTypes,
];

export const capabilityForReportTypeId = {
    [reportTypes.InstantAssetMotor]: CAPABILITIES.assetInstantReport,
    [reportTypes.Drive]: CAPABILITIES.selfGeneratedReport
};

const unitStandard =
{
    Metric: 'Metric',
    Imperial: 'Imperial'
};

export const unitTypesForReport = [
    { value: unitStandard.Metric, label: 'ABB.Powertrain.Frontend.reportUnitMetric' },
    { value: unitStandard.Imperial, label: 'ABB.Powertrain.Frontend.reportUnitImperial' }
];

export const sensorTypes = {
    MSR: 'MACHsense-R'
};
