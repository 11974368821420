import { actionTypes, contentTabs } from './constants';
import {
	addSelectedLoggerSignal,
	removeSelectedIndex,
	addIDtoLoggerSignals
} from './helpers';

const initialState = {
	activeTab: contentTabs.SELFEVALUATE,
	loggers: {
		data: [],
		loadingStatus: undefined,
	},
	loggerFile: {
		loadingStatus: undefined,
		error: false
	},
	selectedLoggerSignals: {
		data: [],
		loadingStatus: undefined
	},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACTIVE_TAB: {
			return {
				...state,
				activeTab: action.activeTab
			};
		}
		case actionTypes.CLEAR_ASSISTANCE_PAGE:
			return initialState;
		case actionTypes.GET_EVENT_LOGGERS:
			return {
				...state,
				loggers: {
					loadingStatus: action.loadingStatus,
					data: addIDtoLoggerSignals(action.data)
				}
			};
		case actionTypes.ADD_SELECTED_LOGGER_SIGNAL:
			return {
				...state,
				selectedLoggerSignals: {
					data: action.data && action.data.length ? addSelectedLoggerSignal([...state.selectedLoggerSignals.data], action.data) : state.selectedLoggerSignals.data,
					loadingStatus: action.loadingStatus
				}
			};
		case actionTypes.REMOVE_SELECTED_LOGGER_SIGNAL:
			return {
				...state,
				selectedLoggerSignals: {
					data: removeSelectedIndex([...state.selectedLoggerSignals.data], action.signalIndex),
					loadingStatus: state.selectedLoggerSignals.loadingStatus
				}
			};
		case actionTypes.DOWNLOAD_LOGGER:
			return {
				...state,
				loggerFile: {
					...action
				}
			};
		case actionTypes.CLEAR_LOGGER_FILE:
			return {
				...state,
				loggerFile: initialState.loggerFile
			};
		default:
			return state;
	}
};
