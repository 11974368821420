import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    transform: 'scale(-1,1)'
};

const IconLifecycleServicesSeparator = ({ width = 23, height = 6, viewBox = '0 0 23 6', color = 'none', flip = false }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} style={flip ? styles : null} xmlns='http://www.w3.org/2000/svg'>
        <path d='M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5H23V2.5H3V3.5Z' fill='black' />
    </svg>;
};

IconLifecycleServicesSeparator.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};
export default IconLifecycleServicesSeparator;
