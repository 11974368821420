import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconWiMon = ({ width = '32', height = '32', viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M25.71 13.8899L21 18.5999L17 14.5999L13.71 17.8899L12.29 16.4799L17 11.7699L21 15.7699L24.29
		12.4799L25.71 13.8899ZM18 18.6899L20 20.6899V27.6899C20 28.2204 19.7893 28.7291 19.4142 29.1042C19.0391
		29.4792 18.5304 29.6899 18 29.6899H10C9.46957 29.6899 8.96086 29.4792 8.58579 29.1042C8.21071 28.7291 8
		28.2204 8 27.6899V3.68994C8 3.15951 8.21071 2.6508 8.58579 2.27573C8.96086 1.90066 9.46957 1.68994 10
		1.68994H18C18.5304 1.68994 19.0391 1.90066 19.4142 2.27573C19.7893 2.6508 20 3.15951 20 3.68994V11.6899L18
		9.68994V3.68994H15.5V9.68994H12.5V3.68994H10V23.6899H18V18.6899ZM18 27.6899V25.6899H10V27.6899H18Z' fill={color} />
	</svg>;
};

IconWiMon.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconWiMon;
