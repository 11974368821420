import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';


const IconMovingPart = ({ width = '32', height = '32', viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M32 16C32 18.1217 31.1571 20.1566 29.6569 21.6569C28.1566 23.1571 26.1217 24 24 24C21.8783
		24 19.8434 23.1571 18.3431 21.6569C16.8429 20.1566 16 18.1217 16 16H18C17.9862 17.4179 18.47 18.7957
		19.3672 19.8937C20.2644 20.9916 21.5182 21.7402 22.9104 22.0091C24.3026 22.278 25.7451 22.0502 26.9866
		21.3654C28.2282 20.6805 29.1903 19.582 29.7055 18.261C30.2207 16.9399 30.2564 15.4801 29.8063 14.1355C29.3562
		12.7909 28.4489 11.6467 27.2422 10.902C26.0355 10.1574 24.606 9.85943 23.2023 10.0601C21.7986 10.2607
		20.5098 10.9471 19.56 12H21V14H16V9H18V10.73C19.0736 9.51264 20.4921 8.65064 22.0672 8.25848C23.6422
		7.86631 25.2993 7.96253 26.8184 8.53436C28.3375 9.10618 29.6467 10.1266 30.5722 11.46C31.4977 12.7934
		31.9956 14.3769 32 16ZM23 28H12V4H23V6H25V3L24 2H11L10 3V29L11 30H24L25 29V26H23V28ZM25.84 18.42L29
		15.18L28 14.12L25.87 16.28L23.2 13.58L20 16.82L21 17.88L23.14 15.72L25.84 18.42Z' fill={color} />
	</svg>;
};

IconMovingPart.propTypes = {
	color: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string
};

export default IconMovingPart;

