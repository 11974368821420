import { createSelector } from 'reselect';
import { getActiveDetailedInformationTab } from '../../selectors';

const getDateSelection = ({ overview }) => {
    return overview?.dateSelection;
};

const getTrendKPIs = createSelector([
    ({ operationalParameters }) => operationalParameters?.availableKPIs?.trend?.data,
], (
    trendKPIs,
) => {
    return trendKPIs;
});

const getSelectedTrendKPIs = ({ operationalParameters }) => {
    return operationalParameters?.preselectedKPIs?.single?.trend;
};

const getMeasurementExportLoadingStatus = ({ downloadMeasurements }) => {
    return downloadMeasurements?.measurementExport?.loadingStatus;
};
const getTrendKPIsLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters?.availableKPIs?.trend?.loadingStatus;
};

const getTrendDataLoadingStatus = ({ operationalParameters }) => {
    return operationalParameters?.charts?.single?.trend[0]?.loadingStatus;
};

const getUserLimitsLoadingStatus = ({ detailedInformationHeader }) => {
    return detailedInformationHeader?.userLimits?.loadingStatus;
};

export const getModel = createSelector([
    getActiveDetailedInformationTab,
    getDateSelection,
    getMeasurementExportLoadingStatus,
    getSelectedTrendKPIs,
    getTrendKPIs,
    getTrendKPIsLoadingStatus,
    getTrendDataLoadingStatus,
    getUserLimitsLoadingStatus,
], (
    activeDetailedInformationTab,
    dateSelection,
    measurementExportLoadingStatus,
    selectedTrendKPIs,
    trendKPIs,
    trendKPIsLoadingStatus,
    trendDataLoadingStatus,
    userLimitsLoadingStatus
) => {
    return {
        activeDetailedInformationTab,
        dateSelection,
        measurementExportLoadingStatus,
        selectedTrendKPIs,
        trendKPIs,
        trendKPIsLoadingStatus,
        trendDataLoadingStatus,
        userLimitsLoadingStatus
    };
});
