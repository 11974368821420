import { useEffect, useState } from 'react';
import transform from 'lodash/transform';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';

import { translate } from 'helpers/translateHelper';

import { assetMenuItems } from './constants';


import { AlertAlarmOffset } from './AlertAlarmOffset';
import { ParameterBackup } from './ParameterBackup';
import { ChangedParameterList } from './ChangedParameterList';
import { RenameAsset } from './RenameAsset';
import { BulkUpload } from './BulkUpload';
import { EmailAlertSettings } from './EmailAlertSettings';
import { DownloadMeasurements } from './DownloadMeasurements';
import { useSelector } from 'react-redux';
import { UserLimits } from './UserLimits';
import { MuteAsset } from './MuteAsset';
import { useCapability, useUserCapability } from 'sharedHooks';
import { generateInstantReport, navigateToReportingPage } from './helpers';


const useHeaderMenus = (selectedAssetTypeId) => {
    const capability = useCapability();
    const userCapability = useUserCapability();
    const hasAlert = capability.hasAlertAlarmCapabilityAvailable();
    const hasEdit = capability.hasEditComponentCapability();
    const hasParameter = capability.hasParameterBackupCapability();
    const hasMeasurement = capability.hasMeasurementExportCapability();
    const hasChangedParameterListCapability = capability.hasChangedParameterListCapability();
    const hasUserLimitsCapability = capability.hasUserLimitsCapability();
    const hasBulkUploadCapability = capability.hasBulkUploadCapability();
    const hasEmailAlertCapability = capability.hasEmailAlertCapability();
    const hasInstantReportCapability = capability.hasInstantReportCapability();

    const allHeaderMenus = [
        {
            id: assetMenuItems.UPDATE_ASSET,
            availableCapabilityFn: (capability) => capability.hasEditChkCapability(),
            title: translate('ABB.Powertrain.Frontend.componentRenameModalTitle'),
            modalContentComponent: RenameAsset,
            disabled: !hasEdit
        },
        {
            id: assetMenuItems.EXPORT_PARAMETERS,
            availableCapabilityFn: (capability) => capability.hasParameterBackupCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.parameterBackupTitle'),
            modalContentComponent: ParameterBackup,
            disabled: !hasParameter
        },
        {
            id: assetMenuItems.EXPORT_MEASUREMENTS,
            availableCapabilityFn: (capability) => capability.hasMeasurementExportCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.downloadMeasurementsLabel'),
            modalContentComponent: DownloadMeasurements,
            disabled: !hasMeasurement
        },
        {
            id: assetMenuItems.CHANGED_PARAMETER_LIST,
            availableCapabilityFn: (capability) => capability.hasChangedParameterListCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.changedParametersList'),
            modalContentComponent: ChangedParameterList,
            disabled: !hasChangedParameterListCapability
        },
        {
            id: assetMenuItems.USER_LIMITS,
            availableCapabilityFn: (capability) => capability.hasUserLimitsCapabilityAvailable(),
            modalContentComponent: UserLimits,
            title: translate('ABB.Powertrain.Frontend.userLimits'),
            disabled: !hasUserLimitsCapability
        },
        {
            id: assetMenuItems.BULK_UPLOAD,
            availableCapabilityFn: (capability) => capability.hasBulkUploadCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.ptHierarchyHeaderMenuBulkUploadLabel'),
            modalContentComponent: BulkUpload,
            disabled: !hasBulkUploadCapability
        },
        {
            id: assetMenuItems.EMAIL_ALERT,
            availableCapabilityFn: (capability) => capability.hasEmailAlertCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.emailAlertMenuItem'),
            modalContentComponent: EmailAlertSettings,
            disabled: !hasEmailAlertCapability
        },
        {
            id: assetMenuItems.GENERATE_INSTANT_REPORT,
            availableCapabilityFn: (capability) => capability.hasInstantReportCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.reportGenerateInstantReportLabel'),
            menuAction: generateInstantReport,
            disabled: !hasInstantReportCapability
        },
        {
            id: assetMenuItems.VIEW_REPORTS_FOR_ASSET,
            title: translate('ABB.Powertrain.Frontend.reportViewReportsForAssetLabel'),
            menuAction: navigateToReportingPage,
            disabled: false
        },
        {
            id: assetMenuItems.ALERT_ALARM_OFFSET,
            availableCapabilityFn: (capability) => capability.hasAlertAlarmCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.alertAlarmConfigTitle'),
            modalContentComponent: AlertAlarmOffset,
            disabled: !hasAlert
        }, {
            id: assetMenuItems.MUTE_ASSET,
            title: translate('ABB.Powertrain.Frontend.muteAssetTitle'),
            availableCapabilityFn: () => userCapability.hasNotificationSettingsCapability(),
            modalContentComponent: MuteAsset
        },
    ];
    const [headerMenus, setHeaderMenus] = useState([]);
    const [selectedHeaderMenu, setSelectedHeaderMenu] = useState();

    const selectedMenuItemId = useSelector(({ detailedInformation }) => detailedInformation.selectedMenuItem);

    useEffect(() => {
        setSelectedHeaderMenu(find(headerMenus, { id: selectedMenuItemId }));
    }, [selectedMenuItemId]);

    useEffect(() => {
        const menuItemsToRender = transform(allHeaderMenus, (acc, menu) => {
            if (!menu.availableCapabilityFn || menu.availableCapabilityFn(capability)) {
                acc.push({ ...menu });
            }
        }, []);
        setHeaderMenus(orderBy(menuItemsToRender, 'title'));
    }, [selectedAssetTypeId, capability]);

    return [headerMenus, selectedHeaderMenu];
};

export default useHeaderMenus;
