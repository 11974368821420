import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useEffectOnlyOnce } from 'sharedHooks';
import { apiCallStatus } from 'helpers/constants';
import {
	getDefaultOrganization,
	validateOrgFilter,
	getDefaultFilters
} from './helpers';
import { useLocation } from 'react-router-dom';
import authHelper from 'helpers/authHelper';
import localStorageHelper from 'helpers/localStorageHelper';
import { useQueryParams } from 'sharedHooks/useQuery';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';


const useLoadDefaultFilters = ({ model, actions }) => {

	const location = useLocation();
	const history = useHistory();
	const { assetId } = useQueryParams();
	const { selectedFilters: savedFilters, organizations, loadingData, isCIAMSyncFlag, isAdmin } = model;

	const redirectToLogin = () => {
		authHelper.redirectToLogin(location.pathname + location.search);
	};

	const clearQueryParameter = () => {
		history.push({
			pathname: history.location.pathname,
		});
	};

	useEffect(() => {
		actions.getUserOrganizations();
	}, []);

	useEffect(() => {
		if (loadingData.organizationsLoadingStatus === apiCallStatus.SUCCESS && !organizations.length) {
			redirectToLogin();
		}
	}, [loadingData.organizationsLoadingStatus, organizations]);

	useEffectOnlyOnce(() => {
		const { organizations: selectedOrganizations } = savedFilters;

		if (assetId) {
			actions.searchAssetRequest(assetId, [], null, clearQueryParameter, selectedOrganizations);
		} else {
			actions.getSitesAndAssetGroups(selectedOrganizations);
			let refreshFlag = true;
			if (isAdmin && !isCIAMSyncFlag) {
				refreshFlag = false;
			}
			actions.getAvailableAssets(selectedOrganizations, refreshFlag);
			if (isAdmin) {
				actions.setCIAMSyncFlag(false);
			}
		}
	}, [loadingData.organizationsLoadingStatus, savedFilters.organizations],
		([orgLoadingStatus, selectedOrganizations]) => {
			return orgLoadingStatus === apiCallStatus.SUCCESS && selectedOrganizations?.length;
		});


	useEffect(() => {
		if (loadingData.organizationsLoadingStatus === apiCallStatus.SUCCESS &&
			(!savedFilters.organizations || savedFilters.organizations.length === 0)) {

			const selectedFiltersFromLS = localStorageHelper.getItemForUser('generalFilter.selectedFilters');

			const isOrgSelectionValid = validateOrgFilter(organizations, selectedFiltersFromLS?.organizations);

			const preselectedOrganizations =
				selectedFiltersFromLS?.organizations?.length && isOrgSelectionValid ?
					selectedFiltersFromLS.organizations :
					getDefaultOrganization(organizations);

			const preselectedFilters = selectedFiltersFromLS && isOrgSelectionValid ?
				selectedFiltersFromLS || savedFilters : getDefaultFilters();

			!isOrgSelectionValid && notify(translate('ABB.Powertrain.Frontend.generalFiltersResetFiltersNotifications'), NOTIFICATION_TYPES.INFO, true);
			if (preselectedOrganizations.length) {
				actions.setSelectedFilters({
					...preselectedFilters,
					organizations: preselectedOrganizations,

				});
			}
		}
	}, [organizations, savedFilters.organizations]);

};
export default useLoadDefaultFilters;
