import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import CapabilityHelper from 'helpers/userCapabilityHelper';

const useUserCapability = () => {
    const capability = useSelector(({ overview }) => overview?.userCapabilities);
    const isInitialized = useRef(false);

    const [capabilityHelper, setCapabilityHelper] = useState(new CapabilityHelper(capability));

    useEffect(() => {
        return () => {
            setCapabilityHelper(null);
        };
    }, []);

    useEffect(() => {
        if (isInitialized.current) {
            setCapabilityHelper(new CapabilityHelper(capability));
        } else {
            isInitialized.current = true;
        }
    }, [capability]);


    return capabilityHelper;
};

export default useUserCapability;
