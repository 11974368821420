import { defaultLandingPage } from 'routes';
import localStorageHelper from './localStorageHelper';

export const getDefaultPageRoute = () => {
    const environmentProperties = localStorageHelper.nonEncrypted.getItem('environmentProperties');

    return environmentProperties
        ? defaultLandingPage
        : '/Login';
};
