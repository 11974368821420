import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModel } from './selectors';
import * as actions from './actions';
import * as commonActions from '../actions';
import LoggerDownloadComponent from './LoggerDownloadComponent';

const LoggerDownloadContainer = (props) => {
	return <LoggerDownloadComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...actions, ...commonActions }, dispatch)
	};
};

const LoggerDownloadContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoggerDownloadContainer);

export default LoggerDownloadContainerConn;
