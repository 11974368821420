import React from 'react';
import { subscriptionStatus } from './constants';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { uniq, map } from 'lodash';
import { ASSET_TYPES } from 'helpers/constants';
import { getAssetIcon } from 'helpers/icons';
import { translate } from 'helpers/translateHelper';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';


export const getSearchMatcher = (search) => {
    const searchToLower = search?.toString()?.toLowerCase();
    return (value) => value?.toString()?.toLowerCase()?.includes(searchToLower);
};

export const getSubscriptionStatusToggleOptions = (translate) => [
    { label: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatusTab_Active_subscription'), value: subscriptionStatus.ACTIVE },
    { label: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatusTab_Expired_subscription'), value: subscriptionStatus.EXPIRED }
];

export const getAssesTypeOptions = (contracts) => {
    const contractTypes = map(uniq(map(contracts, 'typeName')
        .filter(typeName => typeName !== '')), typeName => typeName.toUpperCase());

    const assetTypeIds = map(contractTypes, contractType =>
        ASSET_TYPES[contractType]
    );

    return contractTypes.map((contractType, index) => {
        const assetTypeID = assetTypeIds[index];

        return {
            label:
                <>
                    {getAssetIcon(assetTypeID, { width: 16, height: 16 })} {translate(`ABB.Powertrain.Frontend.${contractType?.toLowerCase()}Label`)}
                </>
            ,
            value: contractType.toLowerCase(),
        };
    });
};

export const getSubscriptionTypeOptions = (contracts) => {
    const contractTypes = map(uniq(map(contracts?.filter(contracts => contracts?.type), 'type')
        .filter(type => type !== '')));

    return contractTypes?.map((contractType) => {
        return {
            label:
                <>
                    {contractType}
                </>
            ,
            value: contractType?.toLowerCase(),
        };
    });
};

const getFormattedInfoTooltip = (item) => {
    const featureCodesCell = <div>
        {

            <InfoButtonWithOnlineHelp infoText={item?.length > 0 ? item?.join(', ') : translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')} withoutLink />
        }
    </div>;
    return featureCodesCell;
};

export const getSubscriptionsTableColumnConfig = (translate) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.assetNameLabel'),
            dataIndex: 'componentName',
            width: 150,
            isSorting: true,
            render: (item) => item ? item : '-'
        },
        {
            title: translate('ABB.Powertrain.Frontend.assetSiNo'),
            dataIndex: 'serialNumber',
            width: 100,
            isSorting: true,
            render: (item) => item ? item : '-'
        },
        {
            title: translate('ABB.Powertrain.Frontend.assetDetailsAssetTypeLabel'),
            dataIndex: 'typeName',
            width: 100,
            isSorting: true,
            render: (item) => item ? item : '-'
        },
        {
            title: translate('ABB.Powertrain.Frontend.subscriptionType'),
            dataIndex: 'type',
            width: 100,
            isSorting: true,
            render: (item) => item ? item : '-'
        },
        {
            title: translate('ABB.Powertrain.Frontend.featureCode'),
            dataIndex: 'featureCodes',
            width: 100,
            isSorting: true,
            render: (item) => item ? getFormattedInfoTooltip(item) : '-'
        },
        {
            title: translate('ABB.Powertrain.Frontend.endDate'),
            dataIndex: 'endDate',
            width: 100,
            isSorting: true,
            render: (item) => item ? convertUtcToLocalDateTime(item, true) : '-'
        }
    ];
};
