import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconPump = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M22 17C22 15.8133 21.6481 14.6533 20.9888 13.6666C20.3295 12.6799 19.3925 11.9108 18.2961 11.4567C17.1997
		11.0026 15.9933 10.8838 14.8295 11.1153C13.6656 11.3468 12.5965 11.9182 11.7574 12.7574C10.9182 13.5965 10.3468
		14.6656 10.1153 15.8295C9.88378 16.9933 10.0026 18.1997 10.4567 19.2961C10.9108 20.3925 11.6799 21.3295 12.6666
		21.9888C13.6533 22.6481 14.8133 23 16 23C17.5913 23 19.1174 22.3679 20.2426 21.2426C21.3679 20.1174 22 18.5913
		22 17ZM12 17C12 16.2089 12.2346 15.4355 12.6741 14.7777C13.1136 14.1199 13.7384 13.6072 14.4693 13.3045C15.2002
		13.0017 16.0044 12.9225 16.7804 13.0769C17.5563 13.2312 18.269 13.6122 18.8284 14.1716C19.3878 14.731 19.7688
		15.4437 19.9231 16.2196C20.0775 16.9956 19.9983 17.7998 19.6955 18.5307C19.3928 19.2616 18.8801 19.8864 18.2223
		20.3259C17.5645 20.7654 16.7911 21 16 21C14.9391 21 13.9217 20.5786 13.1716 19.8284C12.4214 19.0783 12 18.0609
		12 17ZM26 17C26.0001 15.4298 25.6304 13.8817 24.921 12.481C24.2115 11.0803 23.1822 9.86627 21.9163 8.93731C20.6505
		8.00836 19.1836 7.39054 17.6346 7.13389C16.0855 6.87723 14.4978 6.98895 13 7.46V4H14V2H5V4H6V17C6.0018 18.7536
		6.46471 20.476 7.34231 21.9942C8.2199 23.5124 9.48131 24.7732 11 25.65V28H9V30H23V28H21V25.65C22.5187 24.7732
		23.7801 23.5124 24.6577 21.9942C25.5353 20.476 25.9982 18.7536 26 17ZM8 17V4H11V10.18L13.6 9.37C14.3758 9.12229
		15.1856 8.99745 16 9C17.5823 9 19.129 9.46919 20.4446 10.3482C21.7602 11.2273 22.7855 12.4767 23.391 13.9385C23.9965
		15.4003 24.155 17.0089 23.8463 18.5607C23.5376 20.1126 22.7757 21.538 21.6569 22.6569C20.538 23.7757 19.1126 24.5376
		17.5607 24.8463C16.0089 25.155 14.4003 24.9965 12.9385 24.391C11.4767 23.7855 10.2273 22.7602 9.34824 21.4446C8.46919
		20.129 8 18.5823 8 17ZM17 19.06L15 17.06L13.53 18.53L12.47 17.47L15 14.94L17 16.94L18.47 15.47L19.53 16.53L17 19.06Z' fill={color} />
	</svg>;
};

IconPump.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconPump;
