import { actionTypes, tabIDs } from './constants';
import { actionTypes as renameAssetActionTypes } from './Header/RenameAsset/constants';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes as headerActionTypes } from './Header/constants';

const initialState = {
    activeTab: tabIDs.assetDetails,
    assetDetails: {
        data: null,
        error: null,
        loadingStatus: null,
    },
    renameAsset: {
        loadingStatus: apiCallStatus.SUCCESS
    },
    renamedAsset: null,
    selectedMenuItem: null,
    conditionInsightAssetStatus: {
        isConditionInsightAvailable: false,
        conditionStatus: null,
        assetId: null
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_DETAILS: {
            return {
                ...state,
                assetDetails: { ...action }
            };
        }
        case renameAssetActionTypes.UPDATE_ASSET: {
            return {
                ...state,
                renameAsset: {
                    ...state.renameAsset,
                    loadingStatus: action.loadingStatus
                }
            };
        }
        case renameAssetActionTypes.UPDATE_ASSET_SUCCESS: {
            return {
                ...state,
                assetDetails: {
                    ...state.assetDetails,
                    data: {
                        ...state.assetDetails.data,
                        details: {
                            ...state.assetDetails.data.details,
                            assetName: action.data.componentName
                        },
                    }
                }
            };
        }
        case renameAssetActionTypes.CLOSE_DIALOGUE: {
            return {
                ...state,
                renamedAsset: action.data.reName
            };
        }
        case headerActionTypes.SELECT_MENU_ITEM: {
            return {
                ...state,
                selectedMenuItem: action.selectedMenuItem
            };
        }
        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                assetDetails: { ...initialState.assetDetails },
                selectedMenuItem: initialState.selectedMenuItem
            };
        }
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.activeTab
            };
        }
        case actionTypes.SET_SELECTED_ASSET_CONDITION_INSIGHTS_STATUS: {
            return {
                ...state,
                conditionInsightAssetStatus: action.conditionInsightStatus
            };
        }

        default:
            return state;
    }
};
