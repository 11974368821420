import { overallStatus } from './constants';

export const convertDataToChartData = (data, assetType = null) => {
    if (!data) {
        return [];
    }
    const chartData = [
        {
            y: data[overallStatus.POOR].total,
            name: 'poor',
            desc: 'Poor',
            status: overallStatus.POOR,
            assetType
        },
        {
            y: data[overallStatus.TOLERABLE].total,
            name: 'tolerable',
            desc: 'Tolerable',
            status: overallStatus.TOLERABLE,
            assetType
        },
        {
            y: data[overallStatus.GOOD].total,
            name: 'good',
            desc: 'Good',
            status: overallStatus.GOOD,
            assetType
        },
        {
            y: data[overallStatus.UNKNOWN].total,
            name: 'unknown',
            desc: 'Unknown',
            status: overallStatus.UNKNOWN,
            assetType
        }
    ];
    return chartData.filter((data) => data.y !== 0);
};
