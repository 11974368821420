import { filter, isNil, map, find } from 'lodash';


export const getMutedGroups = (notificationGroups) => {
    return filter(notificationGroups, (notificationGroup) => {
        return notificationGroup.muted ||
            isNil(notificationGroup.muted) && notificationGroup.muteDefault;
    });
};

export const formatUpdatedMuteSettings = (id, notificationGroups, groupsToBeMuted, period) => {
    return {
        assetId: id,
        notificationGroups: map(notificationGroups, (group) => {
            return {
                notificationGroupCode: group.notificationGroupCode,
                muted: !!find(groupsToBeMuted, (mutedGroup) => {
                    return mutedGroup.notificationGroupCode === group.notificationGroupCode;
                })
            };
        }),
        startDate: period?.from || null,
        endDate: period?.to || null
    };
};
