import { AssetDetailsApi } from 'api';
import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getAssetPicturesAction = getApiActionCreator(actionTypes.GET_ASSET_PICTURES);
export const uploadAssetPicturesAction = getApiActionCreator(actionTypes.UPLOAD_ASSET_PICTURES);
export const getAssetPictures = (assetID) => {
	return (dispatch) => {
		dispatch(getAssetPicturesAction(apiCallStatus.LOADING));
		AssetDetailsApi.getAssetPicturesV2(assetID)
			.then((pictures) => {
				dispatch(getAssetPicturesAction(apiCallStatus.SUCCESS, pictures));
			}, (err) => {
				dispatch(getAssetPicturesAction(apiCallStatus.ERROR, [], err));
			});
	};
};

export const uploadAssetPictureRequest = (
	assetID,
	fileName,
	pictureId,
	pictureType,
	file
) => ({
	type: actionTypes.UPLOAD_ASSET_PICTURE_REQUEST,
	assetID,
	fileName,
	pictureId,
	pictureType,
	file,
});

export const clearAssetPictures = () => {
	return {
		type: actionTypes.CLEAR_ASSET_PICTURES
	};
};

