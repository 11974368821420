import React from 'react';

const IconDropUp = ({ width = 8, height = 5, color = '#0F0F0F' }) => {

	return (
		<svg width={width} height={height} viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path fillRule='evenodd' clipRule='evenodd' d='M4 0.3L8 4.3L7.3 5L4 1.71L0.7 5L6.11959e-08 4.3L4 0.3Z' fill={color} />
		</svg>
	);
};

export default IconDropUp;
