import log from 'loglevel';
import { isDev } from 'helpers/environmentHelper';
import localStorageHelper from 'helpers/localStorageHelper';


const getLogLevel = () => {
    let logLevel = localStorageHelper.nonEncrypted.getItem('loglevel');
    if (isDev()) {
        logLevel = logLevel || 'DEBUG';
    }
    return logLevel || 'ERROR';
};

const logger = log.getLogger(process.env.SPABasePath || 'pt_log');
logger.setLevel(getLogLevel());

logger.getLogsFromStorage = () => {
    return localStorageHelper.getItem('loglevelLocalStorage') || null;
};

logger.setLogToStorage = (log) => {
    const logs = logger.getLogsFromStorage() || {};
    const currentLogKey = new Date().toJSON();
    logs[currentLogKey] = log;
    localStorageHelper.setItem('loglevelLocalStorage', logs);
};

export default logger;
