import PropTypes from 'prop-types';
import { mapAssetStatuses } from 'helpers/assetStatusHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMessage } from 'sharedComponents/InfoMessage';
import { CardComponent } from '../CardComponent';
import './style.scss';
import { getContextMenuConfigForAsset } from './helpers';

const AssetsOverviewComponent = ({
    model: {
        generalFilteredAssetsCount,
        formattedAssets
    } }) => {
    const { t: translate } = useTranslation();

    return (
        <div className='assets-overview'>
            {
                generalFilteredAssetsCount ?
                    formattedAssets.length ?
                        formattedAssets.map((status, index) =>
                            <div className='assets-overview__grid' key={`asset-row-${index}`}>
                                {status.map(asset => <CardComponent
                                    key={asset.componentID}
                                    condition={asset.overallStatus}
                                    statuses={mapAssetStatuses(asset.statuses)}
                                    contextMenuConfig={getContextMenuConfigForAsset(asset.componentID)}
                                    headerText={translate(`ABB.Powertrain.Frontend.${asset.componentType.typeName.toLowerCase()}Label`)}
                                    bodyText={asset.componentName}
                                />
                                )}
                            </div>
                        )
                        : <InfoMessage title='ABB.Powertrain.Frontend.noMatchesFoundLabel' />
                    : <InfoMessage
                        title='ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionNoAssetsText1'
                        text='ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionNoAssetsText2' />
            }
        </div>
    );
};

AssetsOverviewComponent.propTypes = {
    model: PropTypes.shape({
        assets: PropTypes.array,
        formattedAssets: PropTypes.array,
    })
};

export default AssetsOverviewComponent;
