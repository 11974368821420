import PropTypes from 'prop-types';
import React from 'react';
import { MEASUREMENT_HEALTH_STATUSES } from 'helpers/constants';

import IconKpiAlarm from 'svgIcons/MotionPortalIcons/IconKpiAlarm';
import IconKpiAlert from 'svgIcons/MotionPortalIcons/IconKpiAlert';
import IconKpiOK from 'svgIcons/MotionPortalIcons/IconKpiOK';
import IconKpiUnknown from 'svgIcons/MotionPortalIcons/IconKpiUnknown';

const HealthIcon = (props) => {
    const { kpi } = props;

    if (!kpi.isHealth) {
        return null;
    }

    switch (kpi.measurementHealthStatus) {
        case MEASUREMENT_HEALTH_STATUSES.Weak:
            return <IconKpiAlert />;
        case MEASUREMENT_HEALTH_STATUSES.Critical:
            return <IconKpiAlarm />;
        case MEASUREMENT_HEALTH_STATUSES.Healthy:
            return <IconKpiOK />;
        case MEASUREMENT_HEALTH_STATUSES.Undefined:
            return <IconKpiUnknown />;
        default: return null;
    }
};

HealthIcon.propTypes = {
    kpi: PropTypes.shape({
        isHealth: PropTypes.bool,
        measurementHealthStatus: PropTypes.string
    })
};


export default HealthIcon;
