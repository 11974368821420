import PropTypes from 'prop-types';
import React from 'react';
import { map } from 'lodash';

import ExpandableParameter from './../ExpandableParameter';

import SelectedParameter from './SelectedParameter';
import DirtyFlagCalculation from './DirtyFlagCalculation';

const OperationalParametersComponent = (props) => {
    const { model: { operationalParameters, selectedKpi }, actions } = props;

    return <>
        {
            map(operationalParameters, (kpi) => {
                return <DirtyFlagCalculation actions={actions}
                    kpi={kpi}
                    key={kpi.label}
                    render={(resetDirtyFlag) => {
                        return <ExpandableParameter
                            kpi={kpi}
                            actions={actions}
                            selectedKpi={selectedKpi}
                        >
                            {selectedKpi ?
                                <SelectedParameter
                                    resetDirtyFlag={resetDirtyFlag}
                                    key={selectedKpi.measurementTypeID}
                                    actions={actions}
                                    selectedKpi={selectedKpi}
                                /> : null}

                        </ExpandableParameter>;
                    }}
                >

                </DirtyFlagCalculation>;
            })
        }
    </>;
};

OperationalParametersComponent.propTypes = {
    actions: PropTypes.object
};


export default OperationalParametersComponent;
