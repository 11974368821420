import React from 'react';
import PropTypes from 'prop-types';

const IconKPIOK = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'none', stroke = '#0CA919' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke={stroke} />
		<path fillRule='evenodd' clipRule='evenodd' d='M3.63635 8.72716L6.54544 11.6363L12.3636 5.81807L10.9091 4.36353L6.54544 8.72716L5.0909 7.27262L3.63635 8.72716Z' fill={stroke} />
	</svg>;


};

IconKPIOK.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconKPIOK;
