import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconCaretRight = ({ width = '5px', height = '8px', viewBox = '0 0 5 8', color = colors.codGray }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M4.7 4L0.7 8L-3.0598e-08 7.3L3.29 4L-3.19093e-07 0.7L0.7 -3.0598e-08L4.7 4Z' fill={color} />
    </svg>;
};

IconCaretRight.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default IconCaretRight;
