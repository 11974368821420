import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconDelete, IconEdit, IconFactory } from 'svgIcons/MotionPortalIcons';
import { InfoMessage, GeneralDialog, GeneralSelect } from 'sharedComponents';
import { popupTypes, allAssetTypes } from 'helpers/constants';
import { allAssetTypeFilter } from 'helpers/filterHelper';
import { setOptions } from './helpers';
import { PowertrainComponentsItem } from '../PowertrainComponentsItem';
import { getSiteIds, getOrganizationIds, getSiteById } from '../helpers';
import './style.scss';

const PowertrainDetails = ({
    powertrain,
    sites,
    selectedSites,
    organizations,
    deletePowertrain,
    setSelectedPowertrain,
    setPowertrainWizardVisible,
    setPowertrainForEditing,
    searchboxFilter
}) => {
    const { t: translate } = useTranslation();

    const defaultFilter = allAssetTypeFilter(translate);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [components, setComponents] = useState([]);
    const [componentFilter, setComponentFilter] = useState(defaultFilter);
    const { name } = powertrain;

    useEffect(() => {
        powertrain.assets.sort((a, b) => a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1);
        setComponents(powertrain.assets);
        setComponentFilter(defaultFilter);
    }, [powertrain]);

    useEffect(() => {
        if (componentFilter.assetType === allAssetTypes) {
            setComponents(powertrain.assets);
        } else {
            setComponents(powertrain.assets.filter(component => component.assetType === componentFilter.assetType));
        }
    }, [componentFilter]);

    const renderComponents = () => {
        if (!powertrain || components.length === 0) {
            return <InfoMessage title='ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab_Details_NoAssetsAssigned_label' />;
        }
        return components.map(component => <PowertrainComponentsItem
            key={component.id}
            componentName={component.name}
            componentType={component.assetType}
        />);
    };

    const renderAssetTypeSelector = () => {
        return (
            <div className={'powertrain-details_body_selector'}>
                <GeneralSelect
                    options={setOptions(powertrain.assets, translate)}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    placeholder={translate('ABB.Powertrain.Frontend.allassettypesLabel')}
                    menuShouldScrollIntoView={false}
                    value={componentFilter}
                    onChange={value => setComponentFilter(value)}
                    isSearchable={false} />
            </div>
        );
    };

    const handlePowertrainDelete = (powertrainId) => {
        deletePowertrain(powertrainId, getOrganizationIds(organizations), getSiteIds(selectedSites), searchboxFilter);
        setShowDeleteDialog(false);
        setSelectedPowertrain(null);
    };

    const handlePowertrainEdit = () => {
        setPowertrainForEditing(powertrain);
        setPowertrainWizardVisible(true);
    };

    const renderDeleteDialog = () => {
        return <GeneralDialog
            show={showDeleteDialog}
            close={() => setShowDeleteDialog(false)}
            title={translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab_Details_DeleteDialog_title')}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.yesLabel'),
                onClick: () => handlePowertrainDelete(powertrain.powertrainId)
            }}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.noLabel'),
                onClick: () => setShowDeleteDialog(false)
            }}
            notificationType={popupTypes.WARNING}
        >
            <>{translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab_Details_DeleteDialog_body')}</>
        </GeneralDialog>;
    };

    const renderHeader = () => {
        return <div className={'powertrain-details_header'}>
            <div className={'powertrain-details_header_labels'}>
                <div className={'powertrain-details_header_labels_name'}>
                    {name}
                </div>
                <div className={'powertrain-details_header_labels_site'}>
                    <IconFactory />
                    {getSiteById(sites, powertrain.siteId)?.siteName}
                </div>
            </div>
            <div className={'powertrain-details_header_buttons'}>
                <IconEdit onClick={handlePowertrainEdit} />
                <IconDelete
                    onClick={() => setShowDeleteDialog(true)}
                    width={18}
                    height={22}
                />
            </div>
        </div>;
    };

    return <div className={'powertrain-details'}>
        {renderHeader()}
        <div className={'powertrain-details_body'}>
            {renderAssetTypeSelector()}
            {renderComponents()}
        </div>
        {renderDeleteDialog()}
    </div>;
};

PowertrainDetails.propTypes = {
    powertrain: PropTypes.shape({
        assets: PropTypes.array,
    }),
    sites: PropTypes.array,
    selectedSites: PropTypes.array,
    organizations: PropTypes.array,
    searchboxFilter: PropTypes.string,
    deletePowertrain: PropTypes.func,
    setSelectedPowertrain: PropTypes.func,
    setPowertrainWizardVisible: PropTypes.func,
    setPowertrainForEditing: PropTypes.func,
};

export default PowertrainDetails;
