import React from 'react';
import { connect } from 'react-redux';
import { getModel } from './selectors';

import AssetDetailsComponent from './AssetDetailsComponent';


const AssetDetailsContainer = (props) => {
	return <AssetDetailsComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const AssetDetailsContainerConn = connect(
	mapStateToProps,
	null
)(AssetDetailsContainer);


export default AssetDetailsContainerConn;
