import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getModel } from './selectors';
import * as actions from './actions';
import TrendSignalKPIListComponent from './TrendSignalKPIListComponent';


const TrendSignalKPIListContainer = (props) => {

    return <TrendSignalKPIListComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const TrendSignalKPIListContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendSignalKPIListContainer);


export default TrendSignalKPIListContainerConn;
