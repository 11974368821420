import { map, some, } from 'lodash';
import {
    maintenancePriorities,
    maintenancePriorityIDs,
    conditionUnknownReason,
    maintenanceRecommendation
} from 'helpers/constants';
import { translate } from 'helpers/translateHelper';
import { sortByKey } from 'helpers/filterHelper';
import { matchesWithTheSearchFilter } from 'helpers/assetAndPowertrainFilterHelper';
import localStorageHelper from 'helpers/localStorageHelper';


export const setFilterVisibilityToLocalStorage = (visibility) => {
    localStorageHelper.setItemForUser('maintenanceOverviewFilterVisibility', visibility);
};

export const getFilterVisibilityFromLocalStorage = () => {
    const localStorageExpandedStatus = localStorageHelper.getItemForUser('maintenanceOverviewFilterVisibility');
    return localStorageExpandedStatus === null ? true : localStorageHelper.getItemForUser('maintenanceOverviewFilterVisibility');
};

/**
 * Generates a formatter function, depending on the used key for maintenance condition.
 * Used for uniform mapping asset conditions for powertrain and assetlevel.
 * @param {*} conditionKey currently used values: "condition" || "maintenanceCondition"
 * @returns asset condition mapper
 */
const getAssetConditionInfoWithKey = (conditionKey, CBMAdvice = true) => {

    return (asset) => {
        let maintenanceOverviewStatus = '';
        let isCBMAvailable = true;

        switch (asset[conditionKey]) {
            case maintenancePriorities.MaintenanceOverdue: {
                if (asset.overDueInMonths) {
                    maintenanceOverviewStatus = CBMAdvice ?
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMOverDueForMonths', asset) :
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitOverDueForMonths', asset);
                }
                if (asset.overDueInYears) {
                    maintenanceOverviewStatus = CBMAdvice ?
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMOverDueForYears', asset) :
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitOverDueForYears', asset);
                }
                break;
            }
            case maintenancePriorities.PlanMaintenance: {
                if (asset.dueInMonths) {
                    maintenanceOverviewStatus = CBMAdvice ?
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMDueInMonths', asset) :
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitDueInMonths', asset);
                }
                if (asset.dueInYears) {
                    maintenanceOverviewStatus = CBMAdvice ?
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMDueInYears', asset) :
                        translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitDueInYears', asset);
                }
                break;
            }
            case maintenancePriorities.Over1yToMaintenance: {
                maintenanceOverviewStatus = CBMAdvice ?
                    translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMDueInYears', asset) :
                    translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitDueInYears', asset);
                break;
            }
            case maintenancePriorities.Unknown:
            default: {
                switch (asset.reasonForUnknown) {
                    case conditionUnknownReason.NoSubscription:
                        maintenanceOverviewStatus = CBMAdvice && translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMNotSupported');
                        isCBMAvailable = false;
                        break;
                    case conditionUnknownReason.NoData:
                        maintenanceOverviewStatus = CBMAdvice ?
                            translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMNotSupported') :
                            translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitNotSupported');
                        break;
                    case conditionUnknownReason.NotSupported:
                        maintenanceOverviewStatus = CBMAdvice ?
                            translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetCBMNotSupported') :
                            translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetPMKitNotSupported');
                        isCBMAvailable = false;
                        break;
                    default:
                        maintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewUnknown');
                        break;
                }
                break;
            }
        }

        return { maintenanceOverviewStatus, isCBMAvailable };
    };
};

export const calculateAssetsStatus = (assets) => {
    const assetsWithConditionInfo = map(assets, (asset) => {
        const { maintenanceOverviewStatus: maintenanceOverviewCBMStatus, isCBMAvailable } = getAssetConditionInfoWithKey('condition', true)(asset);
        const { maintenanceOverviewStatus: maintenanceOverviewPMKitStatus } = getAssetConditionInfoWithKey('condition', false)(asset?.maintenancePlan);
        asset.maintenancePriorityID = maintenanceRecommendation[asset.maintenancePriority].maintenanceID;
        asset.maintenancePriorityIconID = maintenanceRecommendation[asset.maintenancePriority].iconID;
        asset.maintenanceOverviewCBMStatus = maintenanceOverviewCBMStatus;
        asset.maintenanceOverviewPMKitStatus = maintenanceOverviewPMKitStatus;
        asset.isCBMAvailable = isCBMAvailable;

        return asset;
    });

    return assetsWithConditionInfo.sort(sortByKey('componentName'));
};

const getPowertrainConditionInfo = (powertrain) => {
    let ptMaintenancePriorityID = null;
    let ptMaintenanceOverviewStatus = '';
    switch (powertrain.overallMaintenanceCondition) {
        case maintenancePriorities.MaintenanceOverdue: {
            ptMaintenancePriorityID = maintenancePriorityIDs[maintenancePriorities.MaintenanceOverdue];
            ptMaintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewExecutePreventive');
            break;
        }
        case maintenancePriorities.PlanMaintenance: {
            ptMaintenancePriorityID = maintenancePriorityIDs[maintenancePriorities.PlanMaintenance];
            if (powertrain.dueInMonths) {
                ptMaintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetDueInMonths', powertrain);
            }
            if (powertrain.dueInYears) {
                ptMaintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetDueInYears', powertrain);
            }
            break;
        }
        case maintenancePriorities.Over1yToMaintenance: {
            ptMaintenancePriorityID = maintenancePriorityIDs[maintenancePriorities.Over1yToMaintenance];
            ptMaintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewAssetDueInYears', powertrain);
            break;
        }
        case maintenancePriorities.Unknown:
        default: {
            ptMaintenancePriorityID = maintenancePriorityIDs[maintenancePriorities.Unknown];
            ptMaintenanceOverviewStatus = translate('ABB.Powertrain.Frontend.maintenanceOverviewUnknown');
            break;
        }
    }
    return { ptMaintenancePriorityID, ptMaintenanceOverviewStatus };
};

export const calculatePowertrainsStatus = (powertrains) => {

    const powertrainsWithConditionInfo = map(powertrains, (powertrain) => {
        powertrain.name = powertrain.systemName;
        powertrain.key = `${powertrain.systemId}`;

        powertrain.components = map(powertrain.components, (asset) => {
            asset.name = asset.componentName;
            asset.key = `${powertrain.systemId}-${asset.componentID}`;
            const { maintenancePriorityID, maintenanceOverviewStatus, isCBMAvailable } = getAssetConditionInfoWithKey('maintenanceCondition')(asset);
            asset.maintenancePriorityID = maintenancePriorityID;
            asset.maintenanceOverviewStatus = maintenanceOverviewStatus;
            asset.isCBMAvailable = isCBMAvailable;

            return asset;
        })
            .sort(sortByKey('componentName'))
            .sort((a, b) => b.maintenancePriorityID - a.maintenancePriorityID);

        const { ptMaintenancePriorityID, ptMaintenanceOverviewStatus } = getPowertrainConditionInfo(powertrain);
        powertrain.maintenancePriorityID = ptMaintenancePriorityID;
        powertrain.maintenanceOverviewStatus = ptMaintenanceOverviewStatus;

        return powertrain;
    });

    return powertrainsWithConditionInfo.sort(sortByKey('systemName'));

};

const isAssetFilterMatching = (asset, { priorityFilter, assetTypeFilter, searchboxFilter }) => {
    const priorityFilterMatching = priorityFilter && priorityFilter.length ? priorityFilter.indexOf(asset.maintenancePriorityID) !== -1 : true;
    const assetTypeFilterMatching = assetTypeFilter ? assetTypeFilter === asset.assetTypeId : true;
    const searchboxFilterMatching = searchboxFilter ? matchesWithTheSearchFilter(asset, searchboxFilter?.toLowerCase()) : true;

    return priorityFilterMatching &&
        assetTypeFilterMatching &&
        searchboxFilterMatching;
};

export const filterAssets = (assets, filters) => {
    return assets.filter(asset => isAssetFilterMatching(asset, filters));
};

const isPowertrainFilterMatching = (powertrain, { priorityFilter, assetTypeFilter, searchboxFilter }) => {
    const priorityFilterMatching = priorityFilter && priorityFilter.length ?
        powertrain.components.some(asset => priorityFilter.indexOf(asset.maintenanceCondition) !== -1) : true;
    const assetTypeFilterMatching = assetTypeFilter ? some(powertrain.components, { componentType: { typeID: assetTypeFilter } }) : true;
    const searchboxFilterMatching = searchboxFilter ? matchesWithTheSearchFilter(powertrain, searchboxFilter?.toLowerCase()) ||
        powertrain.components.some(asset => matchesWithTheSearchFilter(asset, searchboxFilter?.toLowerCase())) : true;

    return priorityFilterMatching &&
        assetTypeFilterMatching &&
        searchboxFilterMatching;
};

export const filterPowertrains = (powertrains, filters) => {
    return powertrains.filter(powertrain => isPowertrainFilterMatching(powertrain, filters));
};
