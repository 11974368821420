
import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';
import { capabilityListToDictionary } from 'helpers/formatters';


const initialState = {
	settings: null,
	capabilities: [],
	capabilitiesLoadingStatus: apiCallStatus.LOADING,
	getEmailAlertSettingsStatus: apiCallStatus.LOADING,
	setEmailAlertSettingsStatus: apiCallStatus.SUCCESS
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_EMAIL_ALERT_SETTINGS:
			return {
				...state,
				getEmailAlertSettingsStatus: action.loadingStatus,
				settings: action.data
			};
		case actionTypes.SET_EMAIL_ALERT_SETTINGS:
			return {
				...state,
				setEmailAlertSettingsStatus: action.loadingStatus,
				settings: action.data
			};
		case actionTypes.LOAD_CAPABILITIES:
			return {
				...state,
				capabilitiesLoadingStatus: action.loadingStatus,
				capabilities: capabilityListToDictionary(action.data)
			};
		case actionTypes.CLEAR_STORE:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

