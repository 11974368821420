export const actionTypes = {
    GET_ASSETS: 'overview/GET_ASSETS',
    GET_POWERTRAINS: 'overview/GET_POWERTRAINS',
    LOAD_USER: 'overview/LOAD_USER',
    UPDATE_UNIT_SELECTION: 'userProfile/UPDATE_UNIT_SELECTION',
    UPDATE_ASSET: 'overview/UPDATE_ASSET',
    UPDATE_ASSET_SUCCESS: 'overview/UPDATE_ASSET_SUCCESS',
    GET_CONTRACTS: 'overview/GET_CONTRACTS',
    ACCEPT_EULA: 'userProfile/ACCEPT_EULA',
    LOAD_AVAILABLE_LANGUAGES: 'overview/LOAD_AVAILABLE_LANGUAGES',
    UPDATE_SELECTED_LANGUAGE: 'overview/UPDATE_SELECTED_LANGUAGE',
    SET_DATE_SELECTION: 'overview/SET_DATE_SELECTION',
    SET_NOTIFICATION: 'generalNotification/SET_NOTIFICATION',
    TOKEN_IS_VALID: 'overview/TOKEN_IS_VALID',
    REFRESH_TOKEN_IN_USER: 'overview/REFRESH_TOKEN_IN_USER',
    GET_USER_CAPABILITIES: 'overview/GET_USER_CAPABILITIES'
};
