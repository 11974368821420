import React from 'react';
import './style.scss';
import { getAssetIcon } from 'helpers/icons';
import PropTypes from 'prop-types';

const PowertrainsComponent = ({ componentName, componentType }) => {
    return <div className='powertrain-components_item'>
        {getAssetIcon(componentType, { width: 20, height: 20 })}
        <span className='powertrain-components_item_label'>{componentName}</span>
    </div>;
};

PowertrainsComponent.propTypes = {
    componentName: PropTypes.string,
    componentType: PropTypes.string
};
export default PowertrainsComponent;
