import PropTypes from 'prop-types';
import React, { useCallback } /* { useEffect }  */ from 'react';

import { Expandable } from 'sharedComponents';
import AssetList from './AssetList';
import PowerTrainListItemTitle from './PowerTrainListItemTitle';


const PowertrainListItem = ({
	powertrain,
	isSelected,
	onPowertrainClick,
	onAssetClick
}) => {
	const handlePowertrainToggle = useCallback(() => {
		if (!isSelected) {
			onPowertrainClick(powertrain.systemID);
		}
		else {
			onPowertrainClick(undefined);
		}
	}, [powertrain.systemID, isSelected]);


	return <Expandable
		title={<PowerTrainListItemTitle systemName={powertrain.systemName} overallStatus={powertrain.overallStatus} />}
		open={isSelected}
		onClick={handlePowertrainToggle}
	>
		{isSelected &&
			<AssetList assets={powertrain.components} insidePowertrain onAssetClick={onAssetClick} />
		}
	</Expandable>;
};

PowertrainListItem.propTypes = {
	powertrain: PropTypes.shape({
		components: PropTypes.any,
		overallStatus: PropTypes.any,
		systemID: PropTypes.any,
		systemName: PropTypes.any
	}),
	selectedPowertrainID: PropTypes.any,
	onAssetClick: PropTypes.func,
	onPowertrainClick: PropTypes.func
};

export default React.memo(PowertrainListItem);
