import * as actionTypes from './constants';

// Actions
const setSelectedFilesAction = (data) => {
	return {
		type: actionTypes.SET_SELECTED_FILES,
		bulkData: data,
	};
};

const removeSelectedFileAction = (index) => {
	return {
		type: actionTypes.REMOVE_SELECTED_FILE,
		index,
	};
};

const clearFileSectionAction = () => {
	return {
		type: actionTypes.CLEAR_FILE_SECTION
	};
};

// Action Creators
export const updateSelectedFiles = (data) => {
	return (dispatch) => {
		dispatch(setSelectedFilesAction(data));
	};
};

export const removeSelectedFile = (index) => {
	return (dispatch) => {
		dispatch(removeSelectedFileAction(index));
	};
};

export const clearFileSection = () => {
	return (dispatch) => {
		dispatch(clearFileSectionAction());
	};
};
