import { takeLatest, call, put } from 'redux-saga/effects';
import { AssetDetailsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { uploadImageToBlobStorage } from './helpers';
import { uploadAssetPicturesAction, getAssetPicturesAction } from './actions';
import * as actionTypes from './constants';

// Creates Presigned URL and Upload
function* uploadAssetPicuresSaga({ assetID, fileName, pictureId, pictureType, file }) {
    yield put(uploadAssetPicturesAction(apiCallStatus.LOADING));
    let blobURLInfo = null;
    try {
        blobURLInfo = yield call(AssetDetailsApi.generateUploadURL, assetID, fileName);
        if (blobURLInfo?.uploadUrl) {
            const uploadResponse = yield call(uploadImageToBlobStorage, blobURLInfo?.uploadUrl, file);
            if (uploadResponse) {
                const mapedResponse = yield call(AssetDetailsApi.uploadAssetPicture,
                    assetID, pictureId, fileName, blobURLInfo?.uploadUrl, pictureType);
                if (mapedResponse) {
                    yield put(getAssetPicturesAction(apiCallStatus.SUCCESS, mapedResponse));
                    yield put(uploadAssetPicturesAction(apiCallStatus.SUCCESS));
                }
            } else {
                yield put(uploadAssetPicturesAction(apiCallStatus.ERROR));
            }
        } else {
            yield put(uploadAssetPicturesAction(apiCallStatus.ERROR));
        }

    } catch (err) {
        yield put(uploadAssetPicturesAction(apiCallStatus.ERROR));
    }

}

export function* assetPictureUploadWatchers() {
    yield takeLatest(actionTypes?.UPLOAD_ASSET_PICTURE_REQUEST, uploadAssetPicuresSaga);
}


