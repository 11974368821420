import React from 'react';
import PropTypes from 'prop-types';

const IconKpiAlarm = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'none' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='#F03040' />
		<rect x='6.54541' y='2.18188' width='2.90909' height='7.27273' fill='#F03040' />
		<rect x='6.54541' y='10.9092' width='2.90909' height='2.90909' fill='#F03040' />
	</svg>;
};

IconKpiAlarm.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconKpiAlarm;
