import React from 'react';
import { connect } from 'react-redux';
import PowertrainsOverviewComponent from './PowertrainsOverviewComponent';
import { getModel } from './selectors';

const PowertrainsOverviewContainer = props => {
    return (
        <div className='powertrains-overview'>
            <PowertrainsOverviewComponent {...props} />
        </div>
    );
};

const mapStateToProps = state => ({ model: getModel(state) });

export default connect(mapStateToProps, null)(PowertrainsOverviewContainer);
