import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconSearch = ({ width = 14, height = 14, viewBox = '0 0 14 14', color = colors.silverChalice }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M13.5 12.457L8.94703 7.88701C9.63845 6.91369 9.93219 5.71313 9.76827 4.53054C9.60435 3.34795 8.99512 2.27255 8.06503 1.52401C7.13595 0.777781 5.95562 0.417167 4.76813 0.516744C3.58065 0.616322 2.47685 1.16847 1.68503 2.05901C0.890933 2.95142 0.467721 4.11345 0.501923 5.30753C0.536124 6.5016 1.02516 7.63751 1.86903 8.48301C2.65228 9.27356 3.69492 9.75431 4.80473 9.83664C5.91454 9.91897 7.0167 9.59733 7.90803 8.93101L12.46 13.5L13.499 12.457H13.5ZM2.58603 7.76301C1.90388 7.0717 1.52258 6.13882 1.5252 5.16762C1.52782 4.19643 1.91416 3.26562 2.60003 2.57801C2.93906 2.2383 3.34175 1.9688 3.78506 1.78492C4.22837 1.60103 4.70359 1.50638 5.18353 1.50638C5.66346 1.50638 6.13869 1.60103 6.582 1.78492C7.02531 1.9688 7.428 2.2383 7.76703 2.57801C8.45303 3.26549 8.83955 4.19622 8.84235 5.16742C8.84516 6.13861 8.46404 7.07157 7.78203 7.76301C7.44302 8.10821 7.03864 8.3824 6.59248 8.56957C6.14633 8.75675 5.66735 8.85315 5.18353 8.85315C4.6997 8.85315 4.22073 8.75675 3.77457 8.56957C3.32842 8.3824 2.92403 8.10821 2.58503 7.76301H2.58603Z' fill={colors.silverChalice} />
	</svg>;
};

IconSearch.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconSearch;
