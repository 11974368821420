import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { ScrollToElementWrapper } from 'sharedComponents/ScrollToElementWrapper';
import AssetItemComponent from './AssetItemComponent';
import { apiCallStatus } from 'helpers/constants';
import { useQueryParams } from 'sharedHooks/useQuery';
import { useKPILoadingTrigger } from './helpers';


const AssetList = ({
    assets,
    selectedAsset,
    selectedAssetCapability,
    onAssetClick,
    selectedKPIType,
    getKPIsForKPIType,
    selectedPowertrain,
    crossAssetAnalysisActiveTab,
    setKpi,
    chartData
}) => {
    const { t: translate } = useTranslation();
    const { assetId: assetIdFromQuery } = useQueryParams();


    useEffect(() => {
        if (selectedAssetCapability.loadingStatus === apiCallStatus.SUCCESS) {
            getKPIsForKPIType(selectedAsset, selectedAssetCapability, selectedKPIType);
        }
    }, [selectedAssetCapability.data, crossAssetAnalysisActiveTab]);

    const getEmptyAssetListLabel = () => {
        return !selectedPowertrain?.systemID ? <div className='asset-list-empty'>{translate('ABB.Powertrain.Frontend.noItemSelectedLabel')}</div> :
            <div className='asset-list-empty'>{translate('ABB.Powertrain.Frontend.noAssetsAssignedToPowertrainLabel')}</div>;
    };

    return (
        <div className='asset-list'>
            {assets?.length ? map(sortBy(assets, (asset) => (asset.componentName ?? '').toLowerCase()), (asset, key) =>
                asset && <ScrollToElementWrapper
                    key={key}
                    condition={assetIdFromQuery === asset?.componentID}
                    customTrigger={useKPILoadingTrigger}>
                    <AssetItemComponent
                        asset={asset}
                        selectedAsset={selectedAsset}
                        selectedAssetCapability={selectedAssetCapability}
                        onAssetClick={onAssetClick}
                        setKpi={setKpi}
                        chartData={chartData}
                        selectedKPIType={selectedKPIType}
                        getKPIsForKPIType={getKPIsForKPIType}
                        crossAssetAnalysisActiveTab={crossAssetAnalysisActiveTab}
                    /></ScrollToElementWrapper>) : getEmptyAssetListLabel()
            }
        </div>
    );
};

AssetList.propTypes = {
    assets: PropTypes.any,
    selectedAsset: PropTypes.any,
    selectedAssetCapability: PropTypes.any,
    selectedKPIType: PropTypes.any,
    setSelectedAsset: PropTypes.func,
    getKPIsForKPIType: PropTypes.func,
    selectedPowertrainID: PropTypes.any,
};

export default AssetList;
