import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GeneralDialog, GeneralInput, GeneralSelect, LoadingStatusWrapper, Switch, Checkbox } from 'sharedComponents';
import { formatAssetList, formatSettings } from './helpers';
import { DEFAULT_NO_OF_ALERTS, MIN_NO_OF_ALERTS, MAX_NO_OF_ALERTS } from './constants';


const EmailAlertComponent = (props) => {
    const { t: translate } = useTranslation();
    const [maxEmailAlertsTicked, setmaxEmailAlertsTicked] = useState(true);
    const [maxEmailAlerts, setmaxEmailAlerts] = useState(DEFAULT_NO_OF_ALERTS);
    const [tempmaxEmailAlerts, setTempmaxEmailAlerts] = useState(DEFAULT_NO_OF_ALERTS);
    const [alertTypes, setAlertTypes] = useState([]);
    const [assets, setAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [saveDisabled, setSaveDisabled] = useState(true);

    useEffect(() => {
        if (props.model.assets && props.model.assets.length && props.model.selectedAsset) {
            const formattedAssets = formatAssetList(props.model.selectedAsset, props.model.assets, translate);
            setAssets(formattedAssets);
            setSelectedAsset(formattedAssets.find(d => d.value === props.model.selectedAsset.componentID));
        }
    }, [props.model.assets, props.model.selectedAsset]);

    useEffect(() => {
        if (props.model.settings) {
            props.model.settings.maxEmailAlertsPerDayEnabled && setmaxEmailAlertsTicked(props.model.settings.maxEmailAlertsPerDayEnabled);

            if (typeof props.model.settings.maxEmailAlertsPerDay !== 'undefined') {
                setmaxEmailAlerts(props.model.settings.maxEmailAlertsPerDay);
                setTempmaxEmailAlerts(props.model.settings.maxEmailAlertsPerDay);
            }

            setAlertTypes(props.model.settings.data || []);
        }
    }, [props.model.settings]);

    const selectAsset = (asset) => {
        setSelectedAsset(asset);
        props.actions.getEmailAlertSettings(asset.assets);
    };

    const save = () => {
        props.actions.setEmailAlertSettings(formatSettings(props.model.settings,
            selectedAsset.assets,
            maxEmailAlertsTicked,
            maxEmailAlerts,
            alertTypes));
        setSaveDisabled(true);
    };

    const validateAndSetTempmaxEmailAlerts = (value) => {
        let val = parseInt(value);
        if (!isNaN(val)) {
            if (val < MIN_NO_OF_ALERTS) {
                val = MIN_NO_OF_ALERTS;
            } else {
                if (val > MAX_NO_OF_ALERTS) {
                    val = MAX_NO_OF_ALERTS;
                }
            }
        } else {
            val = '';
        }
        setTempmaxEmailAlerts(val);
        setSaveDisabled(false);
    };

    const maxEmailAlertsBlur = () => {
        if (tempmaxEmailAlerts) {
            setmaxEmailAlerts(tempmaxEmailAlerts);
        } else {
            setTempmaxEmailAlerts(maxEmailAlerts);
        }
    };

    const maxEmailAlertsTickChanged = () => {
        setmaxEmailAlertsTicked(!maxEmailAlertsTicked);
        setSaveDisabled(false);
    };

    const alertSettingToggled = (alertType) => {
        setAlertTypes(alertTypes.map(
            currentAlertType =>
                currentAlertType.code === alertType.code ?
                    { ...currentAlertType, enabled: !currentAlertType.enabled } : currentAlertType));
        setSaveDisabled(false);
    };

    const renderSettings = () => {
        return <Container className='settings-container'>
            <Row className='g-0'>
                <Switch className='maxEmailAlertsCheckbox' onChange={maxEmailAlertsTickChanged} checked={maxEmailAlertsTicked} />
                <span>{translate('ABB.Powertrain.Frontend.emailAlertMaxNoAlertLabel')}</span>
                <GeneralInput
                    id='maxEmailAlertsInput'
                    numeric={true}
                    className='max-number-input'
                    value={tempmaxEmailAlerts}
                    onChange={validateAndSetTempmaxEmailAlerts}
                    onBlur={maxEmailAlertsBlur} />
                <span>{translate('ABB.Powertrain.Frontend.emailAlertPerDayLabel')}</span>
            </Row>
            <Row className='settings-explanation'>
                <span>{translate('ABB.Powertrain.Frontend.emailAlertNote')}</span>
            </Row>
            <Row className='asset-selector-row g-0'>
                <div className='select-asset-container'>
                    <div className='select-asset-label'>{translate('ABB.Powertrain.Frontend.emailAlertSelectedAssetLabel')}</div>
                    <div>
                        <GeneralSelect
                            id='email-alert-selected-assets'
                            classNamePrefix='email-alert-selected-assets-dropdown'
                            placeholder=''
                            options={assets}
                            value={selectedAsset}
                            onChange={asset => selectAsset(asset)}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className='dropdown-explanation'>{translate('ABB.Powertrain.Frontend.emailAlertSelectedDrivesNote')}</div>
            </Row>
            <Row>
                <Col className='options-container'>
                    {
                        alertTypes.map((alertType, index) =>
                            <Row key={index} className='g-0'>
                                <Checkbox
                                    id={`email_alert_checkbox_${index}`}
                                    checked={alertType.enabled}
                                    onToggle={() => { alertSettingToggled(alertType); }}
                                />
                                <span>{translate(`ABB.Powertrain.Frontend.emailAlert${alertType.code}`)}</span>
                            </Row>)
                    }
                </Col>
            </Row>
        </Container>;

    };

    return <GeneralDialog
        show={props.show}
        close={props.close}
        title={translate('ABB.Powertrain.Frontend.emailAlertTitle')}
        modalClassName='email-alert-modal'
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.applyBtnLbl'),
            onClick: () => { save(); },
            disabled: saveDisabled,
        }}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel')
        }}
        closeButton={true}
    >
        <LoadingStatusWrapper loadingStatus={[props.model.getEmailAlertSettingsStatus, props.model.setEmailAlertSettingsStatus, props.model.getCapabilitiesStatus]}
            errorMessage={translate('ABB.Powertrain.Frontend.SomethingWentWrongError')}>
            {renderSettings()}
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

EmailAlertComponent.propTypes = {
    actions: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default EmailAlertComponent;
