import colors from 'theme/_colors.scss';

/**
 * Returns the fill color for the provided condition value. If unknown
 * condition value provided, will return grey.
 *
 * @param {number} conditionValue
 * @return {string} color in hex value including the hash character
 */
export function resolveFillValueByConditionValue(conditionValue) {
	let retValue = colors.silver;
	if (conditionValue) {
		switch (conditionValue) {
			case 1: // "Good"
				retValue = colors.jungleGreen;
				break;
			case 2: // "Tolerable"
				retValue = colors.orangePeel;
				break;
			case 3: // "Poor"
				retValue = colors.pomegranate;
				break;
			case 0: // "Unknown"
				retValue = colors.silver;
				break;
			case -1: // "NotApplicable"
				retValue = colors.black;
				break;
			default:
			// Do nothing
		}
	}
	return retValue;
}
