import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconGenerator = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M22.29 14.79L23.71 16.21L19 20.91L15 16.91L11.71 20.21L10.29 18.79L15 14.09L19 18.09L22.29 14.79ZM30
		14V21L28 23H27V25H25V27H20V25H14V27H9V25H7V21H5V18.5H2V16.5H5V14H7V7L9 5H25L27 7V12H28L30
		14ZM9 10H25V7.83L24.17 7H9.83L9 7.83V10ZM25 23V12H9V23H25Z' fill={color} />
	</svg>;
};

IconGenerator.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconGenerator;
