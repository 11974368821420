import * as actionTypes from './constants';


const initialState = {
	assetMuteSettings: {
		data: null,
		loadingStatus: null
	},
	updateAssetMuteSettingsLoadingStatus: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ASSET_MUTE_SETTINGS:
			return {
				...state,
				assetMuteSettings: {
					data: action.data,
					loadingStatus: action.loadingStatus
				}
			};
		case actionTypes.SET_UPDATE_MUTE_SETTINGS_LOADING: {
			return {
				...state,
				updateAssetMuteSettingsLoadingStatus: action.loadingStatus
			};
		}
		case actionTypes.CLEAR_STORE:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

