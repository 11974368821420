export const GET_BULK_UPLOAD_STATUS = 'hierarchy/heading/bulkUploadFileSection/GET_BULK_UPLOAD_STATUS';
export const SET_DATE_INTERVAL = 'hierarchy/heading/bulkUploadFileSection/SET_DATE_INTERVAL';

export const MAX_SELECTION_DAYS = 90;
export const TABLE_OPTIONS = {
	sizePerPage: 10,
	sizePerPageList: [],
	sortIndicator: true,
	defaultSortName: 'uploadTimeUtc',
	defaultSortOrder: 'desc'
};

export const UPLOAD_STATUS = {
	Undefined: 'Undefined',
	Queued: 'Queued',
	Validating: 'Validating',
	Analyzing: 'Analyzing',
	Done: 'Done',
	Aborted: 'Aborted'
};
