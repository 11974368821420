export const sensorMountingDetails = ['AssetMountingType', 'SensorMountingOrientation', 'TerminalBoxPosition'];

const nameplateDataSection = 'NameplateData';
export const sensorMountingDetailsSection = 'SensorMountingDetails';
const nominalDetailsSection = 'NominalDetails';
const serviceWarrantySection = 'ServiceWaranty';

export const sectionLabels = {
    [nameplateDataSection]: 'ABB.Powertrain.Frontend.assetDetailsNameplateDataTitle',
    [nominalDetailsSection]: 'ABB.Powertrain.Frontend.assetDetailsNameplateDataTitle',
    [sensorMountingDetailsSection]: 'ABB.Powertrain.Frontend.assetDetailsSensorMountingDetailsTitle',
    [serviceWarrantySection]: 'ABB.Powertrain.Frontend.assetDetailsServiceWarantyTitle',
};
