import { createSelector } from 'reselect';
import { getLoadingStatuses, getAssetsLoadingStatus, getMonitoredGeneralFilteredAssets } from 'helpers/selectors';


export const getModel = createSelector([
    getMonitoredGeneralFilteredAssets,
    getAssetsLoadingStatus,
    getLoadingStatuses
],
    (
        assets,
        assetsLoadingStatus,
        generalFilterLoadingStatuses
    ) => {
        return {
            assets,
            assetsLoadingStatus,
            generalFilterLoadingStatuses
        };
    });
