
import { groupBy, sortBy } from 'lodash';
import { overviewCardsStatusOrder } from './constants';

export const groupAndSortByOverallStatus = (fleet) => {
    const groupedFleet = groupBy(fleet, 'overallStatus');

    const fleetSortedbyStatus = overviewCardsStatusOrder.filter(status => !!groupedFleet[status]).map(status => groupedFleet[status]);
    return fleetSortedbyStatus;
};

const onlyAssetsWithGivenStatus = (components, status) => components.filter((component) => component.overallStatus === status);

const sortByStatusAndAlphabet = (components) => {
    const eachStatusOrdered = overviewCardsStatusOrder.flatMap(status =>
        sortBy(onlyAssetsWithGivenStatus(components, status), asset => asset.componentName.toLowerCase())
    );
    return eachStatusOrdered;
};

export const orderInAlphabet = (groupedPowertrains) => {
    const groupInAlphabet = groupedPowertrains.map(group => {
        const groupWithOrderedPowertrains = sortBy(group, powertrain => powertrain.systemName.toLowerCase());
        return groupWithOrderedPowertrains.map(powertrain => ({
            ...powertrain, components: sortByStatusAndAlphabet(powertrain.components)
        }));
    });
    return groupInAlphabet;
};

