import { LogApi } from 'api/index';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus, NOTIFICATION_TYPES } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { actionTypes, notificationTimeout } from './constants';


const getIssueTypesAction = getApiActionCreator(actionTypes.GET_ISSUE_TYPES);
const reportIssueAction = getApiActionCreator(actionTypes.REPORT_ISSUE);

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const getIssueTypes = () => {
    return (dispatch) => {
        dispatch(getIssueTypesAction(apiCallStatus.LOADING));
        return LogApi.getIssueTypes()
            .then((issueTypes) => {
                dispatch(getIssueTypesAction(apiCallStatus.SUCCESS, issueTypes));
            }, (err) => {
                dispatch(getIssueTypesAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const reportIssue = (
    issueObj,
    closeModal
) => {
    return (dispatch) => {
        dispatch(reportIssueAction(apiCallStatus.LOADING));
        LogApi.reportIssue(issueObj)
            .then((response) => {
                if (response.success) {
                    dispatch(reportIssueAction(apiCallStatus.SUCCESS));
                    closeModal();
                    dispatch(clearStore());
                    notify(translate(
                        'ABB.Powertrain.Frontend.reportSentSuccessfully'),
                        NOTIFICATION_TYPES.SUCCESS,
                        false,
                        notificationTimeout
                    );
                } else {
                    dispatch(reportIssueAction(apiCallStatus.ERROR));
                    notify(translate(
                        'ABB.Powertrain.Frontend.reportError'),
                        NOTIFICATION_TYPES.ERROR,
                        true
                    );
                }
            }, (err) => {
                dispatch(reportIssueAction(apiCallStatus.ERROR, null, err));
            });
    };
};
