import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import ExpandableParameter from './../ExpandableParameter';
import SelectedParameter from './SelectedParameter';
import DirtyFlagCalculation from './DirtyFlagCalculation';
import { Switch } from 'sharedComponents';
import { HealthEmailNotificationsList } from './helpers';
// import { NOTIFICATION_CHANNEL_ID } from '../constants';

const HealthParametersComponent = (props) => {
    const { actions, model: { healthParameters, selectedKpi, autoDetectionIntervals, emailNotificationPayload, emailNotificationResponse } } = props;
    const { t: translate } = useTranslation();
    const [healthEmailNotifications, setHealthEmailNotifications] = useState(HealthEmailNotificationsList);

    useEffect(() => {
        if (emailNotificationResponse) {
            const notificationList = healthEmailNotifications.map((hen) => {
                if (emailNotificationResponse[hen?.notificationTypeID]?.notificationTypeId && emailNotificationResponse[hen?.notificationTypeID]?.response) {
                    return { ...hen, value: true };
                } else {
                    return { ...hen, value: false };
                }
            });
            setHealthEmailNotifications(notificationList);
            actions.setEmailNotificationPayload(notificationList);
        }
        return () => {
            actions.setEmailNotificationPayload(null);
        };
    }, [emailNotificationResponse]);

    const handleHealthEmailNotification = (type) => {
        const updated = healthEmailNotifications.map((item) => {
            if (item.id === type.id) {
                return {
                    ...item,
                    value: !item.value
                };
            }
            return item;
        });
        setHealthEmailNotifications(updated);
        // actions.saveHealthStatusEmailNotification({
        //     notificationTypeID: type.notificationTypeID,
        //     channelID: NOTIFICATION_CHANNEL_ID.email,
        //     payload: { assetIDList: [selectedAsset?.smSeIdentfier] }
        // });
        // SET PAYLOAD FOR SAVE ALL
        actions.setEmailNotificationPayload(updated);
    };

    return <>
        <div className='d-flex mb-3'>
            <span className='health-email-notification-title'>{translate('ABB.Powertrain.Frontend.healthEmailNotification')}:</span>
            {healthEmailNotifications.map((item) =>
                <div className='ms-3 d-flex health-email-notification-item' key={item.id}>
                    <Switch
                        onChange={(e) => { handleHealthEmailNotification(item, e); }}
                        checked={item.value}
                    />
                    <span className='ms-2'>{translate(item.labelKey)}</span>
                </div>
            )}
        </div>
        {
            map(healthParameters, (kpi) => {
                return <DirtyFlagCalculation actions={actions}
                    kpi={kpi}
                    key={kpi.label}
                    healthEmailNotifications={healthEmailNotifications}
                    emailNotificationPayload={emailNotificationPayload}
                    render={(resetDirtyFlag) => {
                        return <ExpandableParameter
                            kpi={kpi}
                            selectedKpi={selectedKpi}
                            actions={actions}
                        >
                            {selectedKpi ?
                                <SelectedParameter
                                    resetDirtyFlag={resetDirtyFlag}
                                    key={selectedKpi.measurementTypeID}
                                    actions={actions}
                                    selectedKpi={selectedKpi}
                                    autoDetectionIntervals={autoDetectionIntervals} /> : null}
                        </ExpandableParameter>;
                    }}
                >
                </DirtyFlagCalculation>;
            })
        }
    </>;
};

HealthParametersComponent.propTypes = {
    actions: PropTypes.object
};


export default HealthParametersComponent;
