import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import HealthIcon from './HealthIcon';
import { SECTION_BUTTONS, KPI_BUTTONS } from './constants';
import clsx from 'clsx';
import { addTrendKpi, removeSelectedKpi } from '../../../../SingleChartView/Trend/actions';
import { Checkbox } from 'sharedComponents/Checkbox';

const ParameterSection = (props) => {
    const { t: translate } = useTranslation();
    const { section, capability, selectedTrendKPIs } = props;
    const dispatch = useDispatch();

    if (!section || !section.data || isEmpty(section.data)) {
        return null;
    }

    const { sectionId, data } = section;
    const sortedKpiList = sortBy(data, kpi => kpi.measurementTypeSequenceNo);
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            dispatch(removeSelectedKpi(kpi));
        }
        else {
            dispatch(addTrendKpi(kpi));
        }
    };

    return <Col xl lg={12} className='measurement-list'>
        <div className='measurement-list-title'>
            <div className='measurement-list-buttons'>
                <div>{translate(`ABB.Powertrain.Frontend.${sectionId}`)}</div>
                {
                    map(SECTION_BUTTONS, (SectionButton, index) => {
                        return <SectionButton section={section} capability={capability} key={index} />;
                    })
                }
            </div>
            <div className='signal-group-header-info'>{translate('ABB.Powertrain.Frontend.signalGroupHeaderInfo')}</div>
        </div>
        <div className='measurement-list-body'>
            {map(sortedKpiList, (kpi) => {
                const isSelected = !!find(selectedTrendKPIs, (selectedTrendKPI) => kpi.measurementTypeIdentifier === selectedTrendKPI);
                return <div className={clsx('measurement-list-item', isSelected && 'selected-measurement-list-item')}
                    onClick={() => kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected)}
                    key={kpi.label}>
                    <div className='data-command-checkbox'>
                        <Checkbox checked={isSelected}
                            disabled={!kpi?.isPlottableToTrendChart}
                        />
                    </div>
                    <div className='data-label'>
                        <div className='health-icon'>
                            <HealthIcon kpi={kpi} />
                        </div>
                        <div>{kpi.label}</div>
                    </div>
                    <div className='data-value'>{kpi.displayValue ?? '-'}</div>
                    <div className='data-command'>
                        {
                            map(KPI_BUTTONS, (KpiButton, index) => {
                                return <KpiButton
                                    sectionId={sectionId}
                                    kpi={kpi}
                                    capability={capability}
                                    isSelected={isSelected}
                                    key={index} />;
                            })
                        }

                    </div>
                </div>;
            })}
        </div>
    </Col>;
};

ParameterSection.propTypes = {
    section: PropTypes.shape({
        data: PropTypes.object,
        sectionId: PropTypes.any
    }),
    capability: PropTypes.object
};

export default ParameterSection;
