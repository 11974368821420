import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class ConditionIndices {
    getAssetOverview(request) {
        const endpoint = formatEndpoint(endpoints.conditionIndices.getAssetOverview);
        return RestManagerWithoutBaseUrl.post(endpoint, request);
    }

    getAssetConditionInsights(assetID, startTime, endTime) {
        const endpoint = formatEndpoint(endpoints.conditionIndices.getAssetConditionInsights, { assetID, startTime, endTime });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    getAssetGroupOverview(request) {
        const endpoint = formatEndpoint(endpoints.conditionIndices.getAssetGroupOverview);
        return RestManagerWithoutBaseUrl.post(endpoint, request);
    }

    getPowertrainOverview(request) {
        const endpoint = formatEndpoint(endpoints.conditionIndices.getPowertrainOverview);
        return RestManagerWithoutBaseUrl.post(endpoint, request);
    }
}

export default new ConditionIndices();
