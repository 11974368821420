import React from 'react';
import PropTypes from 'prop-types';
import { resolveFillValueByConditionValue } from './functions';


const IconEnvironment = ({ width = 12, height = 15, viewBox = '0 0 12 15', condition = -1, color }) => {
	const fillValue = resolveFillValueByConditionValue(condition);
	const appliedColor = color ? color : fillValue;

	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M4 3.785V10.327C4.656 10.523 5.106 11.086 5.12 11.729C5.12 12.565 4.395 13.243 3.5 13.243C2.605 13.243 1.88 12.565 1.88 11.729C1.894 11.086 2.344 10.523 3 10.327V3.785H4ZM12 7.523V8.458H9V7.523H12ZM12 4.719V5.654H9V4.719H12ZM12 1.916V2.85H9V1.916H12ZM3.51 15C2.59199 15.0068 1.70549 14.6656 1.029 14.045C0.707392 13.7507 0.45 13.3932 0.272938 12.9949C0.0958756 12.5965 0.00295716 12.1659 0 11.73C0.00195769 11.3003 0.0916655 10.8756 0.263622 10.4818C0.435579 10.088 0.68616 9.73349 1 9.44V1.757C1.428 0.536999 2.76 -0.196001 4.11 0.0469995C5.025 0.266999 5.745 0.924999 6 1.775V9.439C6.912 10.301 7.237 11.564 6.845 12.719C6.452 13.875 5.407 14.732 4.13 14.944C3.92536 14.9807 3.7179 14.9994 3.51 15ZM2 2.009V9.635L1.83 9.991C1.57233 10.2018 1.36434 10.4669 1.22087 10.7673C1.07741 11.0677 1.00199 11.3961 1 11.729C0.98 12.439 1.31 13.12 1.895 13.575C2.19083 13.8017 2.53162 13.9627 2.89462 14.0472C3.25762 14.1317 3.63447 14.1377 4 14.065C4.937 13.902 5.693 13.255 5.95 12.399C6.206 11.541 5.92 10.622 5.21 10.028L5 9.635V1.99C4.91523 1.74167 4.76899 1.51883 4.57491 1.34223C4.38082 1.16563 4.14521 1.04102 3.89 0.979999C3.079 0.859999 2.289 1.29 2 2.01V2.009Z' fill={appliedColor} />
	</svg>;
};

IconEnvironment.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	condition: PropTypes.number
};

export default IconEnvironment;
