import React from 'react';
import PropTypes from 'prop-types';

import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import Option from './Option';

import './style.scss';


const AssetGroupSelector = (props) => {

	const {
		...otherProps
	} = props;
	return <GeneralSelect
		{...otherProps}
		blurInputOnSelect={false}
		getOptionLabel={option => option.name}
		getOptionValue={option => option.id}
		isDisabled={props.isLoading || !props.assetGroupChecked}
		menuShouldScrollIntoView={false}
		closeMenuOnSelect={false}
		components={{ Option }}
		className='assetgroup-selector-container asset-group-selector'
	/>;
};

AssetGroupSelector.propTypes = {
	assetGroupChecked: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default AssetGroupSelector;
