import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralSelect, GeneralButton } from 'sharedComponents';

import { convertIntervalToDate } from './helpers';

const SuggestionRow = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedKpi,
        autoDetectionIntervals,
        getCalculatedThresholds,
        resetHealthStatuses
    } = props;

    const [selectedInterval, setSelectedInterval] = useState();


    return <>
        <div className='alert-alarm-dialog-row suggest'>
            <div className='select-time-interval'>
                <span className='aa-input-label'>{translate('ABB.Powertrain.Frontend.selectIntervalLabel2')}</span>
                <GeneralSelect
                    id='alert-alarm-suggest-time-interval'
                    placeholder={translate('ABB.Powertrain.Frontend.selectIntervalLabel')}
                    options={autoDetectionIntervals}
                    value={selectedInterval}
                    onChange={(interval) => {
                        setSelectedInterval(interval);
                    }}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.id}
                    isDisabled={false}
                    isSearchable={false}
                />
            </div>
            <div className='calculate-reset-buttons'>
                <GeneralButton
                    className='calculate-button'
                    type='primary'
                    disabled={!selectedInterval}
                    onClick={() => {
                        getCalculatedThresholds(selectedKpi, convertIntervalToDate(selectedInterval));
                    }}
                    text={translate('ABB.Powertrain.Frontend.calculateButton')}
                />
                <GeneralButton
                    className='reset-to-defaults-button'
                    type='normal'
                    onClick={() => { resetHealthStatuses(selectedKpi); }}
                    text={translate('ABB.Powertrain.Frontend.resetValuesButton')}
                />
            </div>
        </div>
        <div className='alert-alarm-dialog-row'>
            {translate('ABB.Powertrain.Frontend.alertAlarmSelectIntervalDescription')}
        </div>
    </>;
};

SuggestionRow.propTypes = {
    autoDetectionIntervals: PropTypes.any,
    getCalculatedThresholds: PropTypes.func,
    resetHealthStatuses: PropTypes.func,
    selectedKpi: PropTypes.object,
};

export default SuggestionRow;
