import React from 'react';
import { components } from 'react-select';
import MultiPointSliderComponent from 'sharedComponents/Slider/MultiPointSliderComponent';
import { getSliderValue } from './helper';


const Option = props => {
    const uniqId = crypto.randomUUID();
    return <components.Option {...props} >
        <div style={{ padding: '16px' }} id={uniqId}>
            <div>{props.label}</div>
            <MultiPointSliderComponent min={0} max={150} value={getSliderValue[props.value]} uniqId={uniqId} />
        </div>
    </components.Option>;
};


export default Option;
