import { ASSET_TYPES, dataSources } from 'helpers/constants';
import { reportTypes } from '../constants';

export const getFilterForReportType = (reportType) => {
    if (reportType?.reportTypeId === reportTypes.InstantAssetMotor) {
        return {
            dataSourceType: dataSources.SMARTSENSOR,
            typeID: ASSET_TYPES.MOTOR
        };
    }
    else if (reportType?.reportTypeId === reportTypes.Drive) {
        return { dataSourceType: dataSources.CMD4 };
    }
    return null;
};

export const getReportNotificationLabel = (reportType) => {
    return reportType.reportTypeId === reportTypes.InstantAssetMotor ?
        'ABB.Powertrain.Frontend.reportInstantMotorReportNotification' :
        '';
};

const isAssetForReportingFilterMatching = (asset, filters) => {
    const dataSourceFilterIsMatching = filters?.dataSourceType
        ? filters.dataSourceType === asset.componentType.dataSourceType : true;
    const assetTypeFilterIsMatching = filters?.typeID
        ? filters.typeID === asset.componentType.typeID : true;

    return dataSourceFilterIsMatching
        && assetTypeFilterIsMatching;
};

export const filterAssetsForReportGeneration = (assets, filters) => {
    return assets.filter(asset => isAssetForReportingFilterMatching(asset, filters));
};

export const isReportMetricOnly = ({ reportTypeId }) => reportTypeId === reportTypes.Drive || reportTypeId === reportTypes.DriveFleetReport;

export const isReportTypeFleet = reportTypeId => reportTypeId === reportTypes.DriveFleetReport || reportTypeId === reportTypes.MotorFleetReport;
