import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import colors from 'theme/_colors.scss';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import Tippy from '@tippy.js/react';
import { Switch, GeneralInput } from 'sharedComponents';
import { alarmValidation } from './helpers';


const AlarmConfigRow = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedKpi: {
            config,
            measurementTypeID,
            checkOperationStatusForEventGeneration
        },
        setAlarm,
        setAlarmNotification
    } = props;

    return <div className='alert-alarm-dialog-row'>
        <div className='notification-switch'>
            <Switch
                onChange={(value) => setAlarmNotification(measurementTypeID, value)}
                checked={config.notificationStatuses.alarmNotificationEnabled}
                className=''
            />
            <span>{translate('ABB.Powertrain.Frontend.alarmEvent')}</span>
        </div>

        <div className='alert-alarm-input-group-wrapper'>

            <div style={{ color: colors.pomegranate }} className='alert-alarm-icon d-flex align-items-end'>!</div>
            <div className='alert-alarm-info d-flex align-items-center'>
                <Tippy
                    content={
                        <>
                            <div>{translate('ABB.Powertrain.Frontend.alarmInfo')}</div>
                            {checkOperationStatusForEventGeneration && <div>{translate('ABB.Powertrain.Frontend.assetUnderOperationInfoText')}</div>}
                        </>
                    }
                    interactive={true}
                    interactiveBorder={10}
                    animation='scale'
                    theme='light-border'
                    trigger='click'
                    maxWidth={450}
                    placement='left-start'
                    offset='-85,0'>
                    <div className='info-icon'>
                        <IconInfo width={'14px'} height={'14px'} />
                    </div>
                </Tippy>
            </div>

            <div className='alert-alarm-input-wrapper d-flex align-items-end'>

                <div className='alert-alarm-input d-flex'>
                    <span className='aa-input-label'>{translate('ABB.Powertrain.Frontend.alarmFrom')}</span>
                    <GeneralInput
                        id={'alarm-input'}
                        numeric={true}
                        clearable={false}
                        isRequired={true}
                        value={config.alarm}
                        validationFunction={(value) => alarmValidation(value, config)}
                        isOutOfRange={(value) => { return value > config.max || value < config.min; }}
                        onChange={(value) => setAlarm(measurementTypeID, value)}
                        onBlur={() => { }} />
                </div>

            </div>

        </div>

    </div>;
};

AlarmConfigRow.propTypes = {
    setAlarm: PropTypes.func
};


export default AlarmConfigRow;
