import React from 'react';
import { map, indexOf, countBy, isNil } from 'lodash';
import { apiCallStatus, allAssetTypes, newConditionIndices } from 'helpers/constants';
import { toggleButtonOptions, pageSize } from './constants';
import localStorageHelper from 'helpers/localStorageHelper';
import Tippy from '@tippy.js/react';
import { translate } from 'helpers/translateHelper';
import {
    IconCondition,
} from 'svgIcons/MotionPortalIcons';


export const getToggleButtonOptions = (translate) => [

    { label: translate('ABB.Powertrain.Frontend.powertrainsLabel'), value: toggleButtonOptions.POWERTRAINS },
    { label: translate('ABB.Powertrain.Frontend.assetGroupsLabel'), value: toggleButtonOptions.ASSETGROUPS },
    { label: translate('ABB.Powertrain.Frontend.assetsLabel'), value: toggleButtonOptions.ASSETS }
];

export const setFilterVisibilityToLocalStorage = (visibility) => {
    localStorageHelper.setItemForUser('assetPowertrainOverviewFilterVisibility', visibility);
};

export const getFilterVisibilityFromLocalStorage = () => {
    const localStorageExpandedStatus = localStorageHelper.getItemForUser('assetPowertrainOverviewFilterVisibility');
    return localStorageExpandedStatus === null ? true : localStorageHelper.getItemForUser('assetPowertrainOverviewFilterVisibility');
};

export const setSelectedConditions = (selectedConditions, newCondition) => {
    const isSelected = indexOf(selectedConditions, newCondition);
    if (isSelected >= 0) {
        selectedConditions.splice(isSelected, 1);
        return selectedConditions;
    }
    return [...selectedConditions, newCondition];
};

export const addNewPageToConditionIndicesOverviewList = (items, action) => {
    const data = action.loadingStatus !== apiCallStatus.LOADING &&
        action.data?.pageNumber === 1 ? [] : [...items.data];

    return action.loadingStatus !== apiCallStatus.SUCCESS ? {
        ...items,
        loadingStatus: action.loadingStatus
    } : {
        ...items,
        loadingStatus: action.loadingStatus,
        count: action.data.count,
        ...!isNil(action.data.assetCount) && { assetCount: action.data.assetCount },
        data: action.data && [...data, ...action.data?.items ? action.data.items : []],
        pageNumber: action.data.hasMore ? action.data?.pageNumber + 1 : action.data?.pageNumber,
        hasMore: action.data.hasMore
    };
};

export const getOverviewNumber = (
    selectedTab,
    assetNumber,
    assetGroupNumber,
    assetGroupAssetNumber,
    powertrainNumber,
    powertrainAssetNumber,
    translate
) => {
    switch (selectedTab) {
        case toggleButtonOptions.ASSETS: {
            return typeof assetNumber === 'number' &&
                `${assetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')}`;
        }
        case toggleButtonOptions.ASSETGROUPS: {
            return typeof assetGroupNumber === 'number' &&
                `${assetGroupNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetGroupNumber')} | ${assetGroupAssetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')} `;
        }
        case toggleButtonOptions.POWERTRAINS: {
            return typeof powertrainNumber === 'number' &&
                `${powertrainNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewPowertrainNumber')} | ${powertrainAssetNumber} ${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewAssetNumber')} `;
        }
        default: return '';
    }
};

export const isTabContentLoading = (
    selectedTab,
    assetOverviewLoadingStatus,
    assetGroupOverviewLoadingStatus,
    powertrainOverviewLoadingStatus
) => {
    switch (selectedTab) {
        case toggleButtonOptions.ASSETS: {
            return assetOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        case toggleButtonOptions.ASSETGROUPS: {
            return assetGroupOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        case toggleButtonOptions.POWERTRAINS: {
            return powertrainOverviewLoadingStatus === apiCallStatus.LOADING;
        }
        default: return false;
    }
};

export const formatConditionIndexOverviewRequest = (
    pageNumber,
    selectedFilters,
    filter,
    conditionFilter,
    assetTypeFilter = ''
) => {
    return {
        pageNumber,
        pageSize,
        organizationIds: selectedFilters?.organizations?.length && selectedFilters?.organizations.map(organization => {
            return organization.organizationID;
        }) || [],
        siteIds: selectedFilters?.sitesChecked && selectedFilters?.sites?.length &&
            selectedFilters?.sites.map(site => {
                return site.siteID;
            }) || [],
        assetGroupIds: selectedFilters?.assetGroupChecked && selectedFilters?.assetGroup &&
            [selectedFilters.assetGroup.id] || [],
        filter,
        conditionFilter,
        assetTypeFilter: assetTypeFilter === allAssetTypes ? '' : assetTypeFilter,
        sortingCriteria: ['Threshold', 'Trend', 'Name'],
        sortingOrder: 'asc'
    };
};

const renderKPI = (kpi) => {
    return <div key={kpi.key} className='kpi-list-item'>
        <IconCondition condition={newConditionIndices[kpi.condition]?.iconID} />
        <p>{translate(kpi?.nameTextKey) || kpi?.name}</p>
    </div>;
};

const renderKPIList = (kpiList) => {
    return <Tippy
        content={<div>{<div className='kpi-list'>
            {map(kpiList, renderKPI)}
        </div>}</div>}
        interactive={true}
        interactiveBorder={10}
        animation='scale'
        theme='light-border'
        trigger='click'
        maxWidth={450}
        placement='right'
        offset='-85,0'>
        <div className='nr-of-kpis'>{`${kpiList?.length} KPIs`}</div>
    </Tippy>;
};

export const renderThreshold = (item) => {
    return <div className='condition-index threshold'>
        <IconCondition condition={newConditionIndices[item?.threshold?.condition]?.iconID} />
        <div className='kpis'>
            {item.threshold?.KPIs?.length > 1 ?
                renderKPIList(item.threshold.KPIs) :
                item.threshold?.KPIs &&
                (translate(item.threshold?.KPIs[0]?.nameTextKey) || item.threshold?.KPIs[0]?.name)}
        </div>
    </div>;
};

export const renderTrend = (item) => {
    return <div className='condition-index trend'>
        <IconCondition condition={newConditionIndices[item?.trend?.condition]?.iconID} />
        <div className='kpis'>
            {item.trend?.KPIs?.length > 1 ?
                renderKPIList(item.trend.KPIs) :
                item.trend?.KPIs &&
                (translate(item.trend?.KPIs[0]?.nameTextKey) || item.trend?.KPIs[0]?.name)}
        </div>
    </div>;
};

const renderAssetNrByCondition = (assets, conditionType) => {
    const conditions = map(assets, (asset) => { return asset[conditionType]; });
    const groupedByCondition = countBy(conditions, 'condition');
    return <div className='nr-by-condition-index'>
        {groupedByCondition?.Poor > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Poor']?.translationKey + 'Label')}: ${groupedByCondition.Poor}`}
        </div>}
        {groupedByCondition?.Tolerable > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Tolerable']?.translationKey + 'Label')}: ${groupedByCondition.Tolerable}`}
        </div>}
        {groupedByCondition?.Unknown > 0 && <div>
            {`${translate('ABB.Powertrain.Frontend.' + newConditionIndices['Unknown']?.translationKey + 'Label')}: ${groupedByCondition.Unknown}`}
        </div>}
    </div>;
};

export const renderThresholdSummary = (item) => {
    return <div className='condition-index threshold'>
        <IconCondition condition={newConditionIndices[item?.thresholdCondition]?.iconID} />
        <div className='kpis'>
            {renderAssetNrByCondition(item?.assets, 'threshold')}
        </div>
    </div>;
};

export const renderTrendSummary = (item) => {
    return <div className='condition-index trend'>
        <IconCondition condition={newConditionIndices[item?.trendCondition]?.iconID} />
        <div className='kpis'>
            {renderAssetNrByCondition(item?.assets, 'trend')}
        </div>
    </div>;
};
