import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconDataLogger = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.mineShaft }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M4 2V20H22V22H2V2H4ZM16.95 5.27L20.5 10H22V12H19.5L17.05 8.73L13.54 14H11.54L9.54 17H6V15H8.46L10.46 12H12.46L16.95 5.27V5.27Z' fill={color} />
	</svg>;
};

IconDataLogger.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};


export default IconDataLogger;
