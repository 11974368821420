import { createSelector } from 'reselect';


const getActiveTab = ({ chartView }) => {
    return chartView.activeTab;
};

const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

export const getModel = createSelector([
    getActiveTab,
    getDateSelection
],
    (
        activeTab,
        dateSelection
    ) => {
        return {
            activeTab,
            dateSelection
        };
    });
