import sortBy from 'lodash/sortBy';

export const handleSortAssetList = (assetList) => {
	if (assetList && assetList.length) {
		assetList = sortBy(assetList, [asset => asset.componentName && asset.componentName.toLowerCase()]);
	}
	return assetList;
};

export const mapSiteProps = (sites) => {
	if (sites?.length) {
		sites = sites.map((site) => {
			const { siteId: siteID, name: siteName, ...mappedSite } = site;
			return { siteID, siteName, ...mappedSite };
		});
	}
	return sites;
};
