import React from 'react';
import colors from 'theme/_colors.scss';

const IconEdit = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.black, onClick }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' onClick={onClick} width={width} height={height} viewBox={viewBox} fill='none' role='edit-icon'>
			<path d='M21 7L18 4L16 2L3 15V20H8.08L20.52 7.49L21 7ZM21 21V23H3V21H21ZM13.41 7.42L15.6 9.6L7.25 18H5V15.83L13.41 7.42Z' fill={color} />
		</svg>
	);
};

export default IconEdit;
