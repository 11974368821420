import { createSelector } from 'reselect';

const getMeasurementUnitGroups = ({ overview }) => {
    return overview.user?.data?.measurementUnitGroups;
};

const getUserDataLoadingStatus = ({ overview }) => {
    return overview.user?.loadingStatus;
};

const getChangeableUnits = createSelector(
    [getMeasurementUnitGroups],
    (allUnitGroups) => {
        const groupsWithTwoUnits = allUnitGroups?.filter(
            (unitGroup) => unitGroup.measurementUnitGroup.standard2Symbol
        );
        const groupsWithStandardsAndFormattedName = groupsWithTwoUnits.sort(
            (a, b) => a.measurementUnitGroup.name.localeCompare(b.measurementUnitGroup.name));

        return groupsWithStandardsAndFormattedName;
    }
);


export const getModel = createSelector(
    [
        getChangeableUnits,
        getUserDataLoadingStatus
    ],
    (changeableUnits, loadingStatus) => {
        return {
            changeableUnits,
            loadingStatus,
        };
    }
);
