import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconMaintenance = ({ width = 15, height = 15, color = 'none' }) => {
	return <svg width={width} height={height} viewBox='0 0 16 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='1' y='1' width='14' height='14' rx='2' fill={colors.amethyst} />
		<path d='M5.5 3.83333L5.50417 6.33333L7.16667 8L5.50417 9.67083L5.5 12.1667H10.5V9.66667L8.83333 8L10.5 6.3375V3.83333H5.5ZM9.66667 9.875V11.3333H6.33333V9.875L8 8.20833L9.66667 9.875Z' fill='white' />
	</svg>;
};

IconMaintenance.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconMaintenance;
