import { BulkUploadApi } from 'api';
import * as constants from './constants';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { getApiActionCreator } from 'helpers/actionHelper';

// Actions
const getBulkUploadStatusAction = getApiActionCreator(constants.GET_BULK_UPLOAD_STATUS);

const setDateIntervalAction = (dateInterval) => {
	return {
		type: constants.SET_DATE_INTERVAL,
		dateInterval
	};
};

// Action Creators
export const getBulkUploadStatus = (asset, { from, to }) => {
	return dispatch => {
		dispatch(getBulkUploadStatusAction(apiCallStatus.LOADING));
		BulkUploadApi.getBulkUploadStatus(asset, from, to)
			.then((data) => {
				logger.debug('Bulk upload status loaded successfully');
				dispatch(getBulkUploadStatusAction(apiCallStatus.SUCCESS, data));
			})
			.catch((err) => {
				logger.error(err && err.message);
				dispatch(getBulkUploadStatusAction(apiCallStatus.ERROR, null, err));
			});
	};
};

export const setDateInterval = dateInterval => {
	return dispatch => {
		dispatch(setDateIntervalAction(dateInterval));
	};
};
