import { actionTypes } from './constants';
import { initialState } from '../../GeneralFilterReducer';


const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SITES: {
			return {
				...state,
				sites: { ...state.sites, ...action }
			};
		}
		case actionTypes.GET_ASSET_GROUPS: {
			return {
				...state,
				assetGroups: { ...state.assetGroups, ...action }
			};
		}
		case actionTypes.SET_SELECTED_FILTERS: {
			return {
				...state,
				selectedFilters: { ...state.selectedFilters, ...action.selectedFilters }
			};
		}
		case actionTypes.POPULATE_STORE: {
			return {
				...state,
				...action
			};
		}
		case actionTypes.CLEAR_STORE: {
			return initialState;
		}
		default:
			return state;
	}
};

export default reducer;
