import React, { useEffect } from 'react';
import TrendChartsComponent from './TrendChartsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';


const TrendChartsContainer = (props) => {

    useEffect(() => {
        // check needed because multi graph KPIs are based on this, to be removed in the future when config comes from BE
        if (props.model.availableKPIs.length) {
            props.actions.getMultiChartConfig(props.model.availableKPIs);
        }
    }, [props.model.availableKPIs]);

    useEffect(() => {
        return () => {
            props.actions.clearMultiChartData();
        };
    }, []);

    return <LoadingStatusWrapper
        loadingStatus={props.model.trendKPILoadingStatus}>
        {props.model.multiChartPreselection?.length ?
            <TrendChartsComponent {...props} /> : null}
    </LoadingStatusWrapper>;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const TrendChartsContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendChartsContainer);

export default TrendChartsContainerConn;
