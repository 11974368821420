import React from 'react';
import PropTypes from 'prop-types';


const IconKebab = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = 'none', active = false }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg' role='icon-kebab'>
		<rect width={width} height={height} rx='12' fill={active ? '#848484' : 'none'} fillOpacity='0.6' />
		<path d='M14 15.3333V19.3333H10V15.3333H14ZM14 9.99999V14H10V9.99999H14ZM14 4.66666V8.66666H10V4.66666H14Z' fill='#1F1F1F' />
	</svg>;
};

IconKebab.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	viewBox: PropTypes.string
};

export default IconKebab;
