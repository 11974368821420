import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { ComponentApi } from 'api';
import { getSelectedAsset } from '../../selectors';
import { getSensorProperties } from '../../../sensorActions';

const loadMeasurementAction = getApiActionCreator(actionTypes.LOAD_MEASUREMENT);

export const loadMeasurement = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);
        const dataSource = selectedAsset.componentType.dataSourceType;
        const sensor = state.sensorProperties.sensorProperties.data;
        dispatch(loadMeasurementAction(apiCallStatus.LOADING));
        return await ComponentApi.loadMeasurement(sensor.sensorIdentifier, sensor.sensorTypeID, dataSource)
            .then((response) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestSuccess'), NOTIFICATION_TYPES.SUCCESS);
                dispatch(loadMeasurementAction(apiCallStatus.SUCCESS, response));
                dispatch(getSensorProperties(sensor.sensorIdentifier, sensor.sensorTypeID));
            })
            .catch((err) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestError'), NOTIFICATION_TYPES.ERROR);
                dispatch(loadMeasurementAction(apiCallStatus.ERROR, null, err));
            });
    };
};
