import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { find, map } from 'lodash';
import clsx from 'clsx';
import { addTrendKpi, removeSelectedKpi } from '../../actions';
import { mapMeasurementToConfigFormat } from '../../../../../../commonTrendHelpers';
import SectionInfoButton from './SectionInfoButton';
import { TRENDS } from '../constants';
import { Checkbox } from 'sharedComponents/Checkbox';

const ParameterSection = (props) => {
    const { t: translate } = useTranslation();
    const {
        section,
        selectedTrendsData,
        asset
    } = props;
    const dispatch = useDispatch();

    if (!section || !section.data || section.data.length === 0) {
        return null;
    }
    const toggleMeasurement = (kpi, isSelected) => {
        const kpiConfig = mapMeasurementToConfigFormat(kpi);
        if (isSelected) {
            dispatch(removeSelectedKpi(kpiConfig));
        }
        else {
            dispatch(addTrendKpi(kpiConfig, asset));
        }
    };
    const { sectionId, data } = section;

    return <Col xl lg={12} className='measurement-list'>
        <div className='measurement-list-title'>
            <div className='measurment-list-buttons'>
                <div>{translate(`${sectionId === TRENDS ? `ABB.Powertrain.Frontend.${sectionId}` : 'ABB.Powertrain.Frontend.additionaltrends'}`)}</div>
                <SectionInfoButton section={section} />
            </div>
            <div className='signal-group-header-info'>{translate('ABB.Powertrain.Frontend.signalGroupHeaderInfo')}</div>
        </div>
        <div className='measurement-list-body'>
            {map(data, (kpi) => {
                const isSelected = !!find(selectedTrendsData, (trendData) => kpi.measurementTypeIdentifier === trendData.config.measurementTypeIdentifier);
                return <div className={clsx('measurement-list-item', isSelected && 'selected-measurement-list-item')}
                    onClick={() => kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected)}
                    key={kpi.label}>
                    <div className='data-command-checkbox'>
                        <Checkbox checked={isSelected}
                            disabled={!kpi?.isPlottableToTrendChart}
                        />
                    </div>
                    <div className={`data-label ${isSelected ? ' data-label-selected' : ''}`}>
                        {`${kpi.label}${sectionId === TRENDS ? ` (${kpi.measurementTypeUnit})` : ''}`}
                    </div>
                </div>;
            })}
        </div>
    </Col>;
};

ParameterSection.propTypes = {
    section: PropTypes.shape({
        data: PropTypes.object,
        sectionId: PropTypes.any
    }),
    capability: PropTypes.object,
    asset: PropTypes.object
};

export default ParameterSection;
