import React from 'react';
import PropTypes from 'prop-types';

import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import Option from './Option';

import './style.scss';


const SiteSelector = (props) => {

	const {
		...otherProps
	} = props;

	return <GeneralSelect
		{...otherProps}
		components={{ Option }}
		getOptionLabel={option => option.siteName}
		getOptionValue={option => option.siteID}
		className='general-filter-selector-container site-selector'
		isMulti
		isDisabled={props.isLoading || !props.sitesChecked}
		blurInputOnSelect={false}
		closeMenuOnSelect={false}
		hideSelectedOptions={false}
	/>;
};

SiteSelector.propTypes = {
	isLoading: PropTypes.bool,
	sitesChecked: PropTypes.bool
};

export default SiteSelector;
