import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { InfoMessage } from 'sharedComponents/InfoMessage';
import { useQuerySetter } from 'sharedHooks/useQuery';
import { CardComponent } from '../CardComponent';
import { ConditionMonitoringAssetList } from '../ConditionMonitoringAssetList';
import { getContextMenuConfigForPowertrain, getHeaderTextLabel } from './helpers';
import './style.scss';

const PowertrainsOverviewComponent = ({ model: { generalFilteredPowertrainsCount, powertrains, filters } }) => {
    const [selectedId, setSelected] = useState(null);
    const queryHelper = useQuerySetter();


    const handleCardClick = (currentId, nextId) => {
        const newId = currentId === nextId ? null : nextId;
        setSelected(newId);
    };

    const handleAssetClick = useCallback((assetId, powertrainId) => queryHelper.routeToAssetDetails(assetId, powertrainId), []);

    useEffect(() => {
        setSelected(null);
    }, [filters]);

    return <>{
        generalFilteredPowertrainsCount ?
            powertrains?.length ?
                powertrains.map((status, index) =>
                    <div className='powertrains-overview__grid' key={`powertrain-row-${index}`}>
                        {status.map(powertrain =>
                            <CardComponent
                                key={powertrain.systemID}
                                condition={powertrain.overallStatus}
                                statuses={powertrain.statuses}
                                headerText={getHeaderTextLabel(powertrain.components)}
                                bodyText={powertrain.systemName}
                                contextMenuConfig={getContextMenuConfigForPowertrain(powertrain.systemID)}
                                onCardClick={() => handleCardClick(selectedId, powertrain.systemID)}
                                selected={selectedId !== null ? selectedId === powertrain.systemID : null}
                            >
                                <ConditionMonitoringAssetList
                                    assets={powertrain.components}
                                    powertrainId={powertrain.systemID}
                                    onAssetSelection={handleAssetClick}
                                />
                            </CardComponent>
                        )}
                    </div>
                )
                : <InfoMessage title='ABB.Powertrain.Frontend.noMatchesFoundLabel' />
            : <InfoMessage
                title='ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionNoPowertrainsTextTop'
                text='ABB.Powertrain.Frontend.condMonitoringOverviewPowertrainConditionNoPowertrainsTextBottom' />
    }
    </>;
};

PowertrainsOverviewComponent.propTypes = {
    model: PropTypes.shape({
        powertrains: PropTypes.array,
        filters: PropTypes.object,
    }),

};

export default PowertrainsOverviewComponent;
