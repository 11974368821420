import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { ScrollableList } from 'sharedComponents';
import AssetItem from './AssetItem';
import './style.scss';


const AssetTable = ({
    assets,
    action,
    actionType,
    loadingStatus,
    hasMore = false,
    loadMore = null
}) => {
    const { t: translate } = useTranslation();

    return <>
        <Row className='table-header'>
            <Col className='asset-name-col'>{translate('ABB.Powertrain.Frontend.assetNameLabel')}</Col>
            <Col className='action-col'>{translate('ABB.Powertrain.Frontend.actionLabel')}</Col>
        </Row>
        <ScrollableList
            emptyLabel={translate('ABB.Powertrain.Frontend.noAssetsToShow')}
            withLoading
            loadingStatus={loadingStatus}
            hasMore={hasMore}
            loadMore={() => loadMore && loadMore()}
            className='table-content scrollable-list'>
            {assets?.length ? map(assets, (asset, key) =>
                asset && <AssetItem asset={asset} actionType={actionType} action={action} key={key} />
            ) : <div className='no-asset-found'>{translate('ABB.Powertrain.Frontend.noAssetsToShow')}</div>}
        </ScrollableList>
    </>;
};

AssetTable.propTypes = {
    assets: PropTypes.array,
    actionType: PropTypes.string,
    loadingStatus: PropTypes.string,
    action: PropTypes.func,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func
};

export default AssetTable;
