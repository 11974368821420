import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ParameterBackupModalComponent from './ParameterBackupComponent';
import { getModel } from './selectors';
import * as parameterBackupActions from './actions';


const ParameterBackupContainer = (props) => {
	return <ParameterBackupModalComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(parameterBackupActions, dispatch)
	};
};

const ComponentsParameterBackupContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(ParameterBackupContainer);

export default ComponentsParameterBackupContainerConn;
