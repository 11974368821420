import logger from 'helpers/logger';
import { HeaderApi } from 'api';
import {
	apiCallStatus
} from 'helpers/constants';
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import {
	formatSiteAssetGroupRequest,
	formatAssetsRequest
} from '../helpers';


const getSitesAction = getApiActionCreator(actionTypes.GET_SITES);
const getAssetGroupsAction = getApiActionCreator(actionTypes.GET_ASSET_GROUPS);

const getSites = (organizations, dispatch) => {
	dispatch(getSitesAction(apiCallStatus.LOADING));
	return HeaderApi.getSites(formatSiteAssetGroupRequest(organizations))
		.then((sites = []) => {
			dispatch(getSitesAction(apiCallStatus.SUCCESS, sites));
		}, (err) => {
			dispatch(getSitesAction(apiCallStatus.ERROR, null, err));
		});
};

const getAssetGroups = (organizations, dispatch) => {
	dispatch(getAssetGroupsAction(apiCallStatus.LOADING));
	return HeaderApi.getAssetGroups(formatSiteAssetGroupRequest(organizations))
		.then((sites) => {
			dispatch(getAssetGroupsAction(apiCallStatus.SUCCESS, sites));
		}, (err) => {
			dispatch(getAssetGroupsAction(apiCallStatus.ERROR, [], err));
		});
};

export const getSitesAndAssetGroups = (organizations) => {
	return (dispatch) => {
		Promise.all([
			getSites(organizations, dispatch),
			getAssetGroups(organizations, dispatch)
		]);
	};
};

export const setSelectedFilters = (selectedFilters) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_SELECTED_FILTERS,
			selectedFilters
		});
	};
};

export const clearStore = () => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.CLEAR_STORE,
		});
	};
};

export const populateStore = () => {
	return (dispatch, getState) => {
		const savedFiltersStore = getState().generalFilter;
		dispatch({
			type: actionTypes.POPULATE_STORE,
			...savedFiltersStore
		});
	};
};

export const getAvailableAssets = (organizations, refreshData) => {
	return (dispatch) => {
		HeaderApi.getAvailableAssets({ ...formatAssetsRequest(organizations), refreshData })
			.then(() => {
				dispatch({
					type: 'AVAILABLE_ASSETS'
				});
				logger.debug('Asset list loaded from microservice');
			}, (err) => {
				dispatch({
					type: 'AVAILABLE_ASSETS'
				});
				logger.error(err);
			});
	};
};
