import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconDropDown = ({ width = 32, height = 32, viewBox = '16 8 32 32', color = colors.dodgerBlue, arrowColor = 'white' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g filter='url(#filter0_d_8781_23456)'>
			<rect x='16' y='8' width='32' height='32' rx='16' fill={color} />
		</g>
		<path d='M32 29L26 23.04L27.05 22L32 26.9L36.95 22L38 23.04L32 29Z' fill={arrowColor} />
		<defs>
			<filter id='filter0_d_8781_23456' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
				<feOffset dy='8' />
				<feGaussianBlur stdDeviation='8' />
				<feComposite in2='hardAlpha' operator='out' />
				<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0' />
				<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8781_23456' />
				<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_8781_23456' result='shape' />
			</filter>
		</defs>
	</svg>;


};

IconDropDown.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	viewBox: PropTypes.string,
	className: PropTypes.string
};
export default IconDropDown;
