import { apiCallStatus, filterTabs } from 'helpers/constants';
import { createSelector } from 'reselect';
import { filterAllAssets, filterPowertrainAssets } from './helpers';
import {
    getAssetsLoadingStatus,
    getGeneralFilteredAssets,
    getGeneralFilteredPowertrains,
    getPowertrainLoadingStatus,
    getLoadingStatuses
} from 'helpers/selectors';

const selectKpiLoadingStatus = state => state.crossAssetAnalysisSidepanel?.kpiList?.loadingStatus;
export const selectShouldScrollToSelection = state => state.crossAssetAnalysisSidepanel.shouldScrollToSelection;

export const selectIsKPILoaded = createSelector(selectKpiLoadingStatus, kpiLoadingStatus =>
    kpiLoadingStatus === apiCallStatus.SUCCESS
);


const getFilters = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel;
};

const getActiveTab = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.activeTab;
};

const getAssetKpiList = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel.assetKPILists;
};

const getSelectedKPIType = ({ chartView }) => {
    return chartView.selectedKPIType;
};

const getCrossAssetAnalysisActiveTab = ({ chartView }) => {
    return chartView.activeTab;
};

const getChartData = ({ chartView }) => {
    return chartView.chartData;
};


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedPowertrain = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedPowertrain;
};

const getSelectedAssetCapability = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAssetCapability;
};

const getFormattedAssets = createSelector(
    [getGeneralFilteredAssets, getGeneralFilteredPowertrains, getActiveTab, getFilters, getSelectedPowertrain],
    (assets, powertrains, activeTab, filters, selectedPowertrain) => {
        return activeTab === filterTabs.POWERTRAINS ?
            filterPowertrainAssets(powertrains, selectedPowertrain) :
            filterAllAssets(assets, filters.searchboxFilter, filters.gatewayTypeFilter);
    }
);


export const getModel = createSelector(
    [
        getGeneralFilteredPowertrains,
        getFormattedAssets,
        getSelectedAsset,
        getSelectedAssetCapability,
        getSelectedKPIType,
        getLoadingStatuses,
        getAssetsLoadingStatus,
        getPowertrainLoadingStatus,
        getChartData,
        getCrossAssetAnalysisActiveTab,
        getAssetKpiList,
        getFilters,
        getActiveTab,
        getSelectedPowertrain
    ],
    (
        powertrains,
        assets,
        selectedAsset,
        selectedAssetCapability,
        selectedKPIType,
        generalFilterLoadingStatus,
        assetsLoadingStatus,
        powertrainsLoadingStatus,
        chartData,
        crossAssetAnalysisActiveTab,
        assetKpiLists,
        filters,
        activeTab,
        selectedPowertrain
    ) => {
        return {
            powertrains,
            assets,
            selectedAsset,
            selectedAssetCapability,
            selectedKPIType,
            generalFilterLoadingStatus,
            assetsLoadingStatus,
            powertrainsLoadingStatus,
            chartData,
            crossAssetAnalysisActiveTab,
            assetKpiLists,
            filters,
            activeTab,
            selectedPowertrain
        };
    }
);
