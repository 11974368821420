import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconAnnualMaintenancePMThreshold = ({ width = 16, height = 1, viewBox = '0 0 16 1', color = colors.orangePeel }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line x1='15.5003' y1='0.5' x2='0.500305' y2='0.499999' stroke={color} strokeLinecap='round' />
    </svg>;
};

IconAnnualMaintenancePMThreshold.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconAnnualMaintenancePMThreshold;
