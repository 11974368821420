import { translate } from 'helpers/translateHelper';
import { useHistory } from 'react-router';
import { routes } from 'routes';


export const getContextMenuConfigForPowertrain = (powertrainId) => {
    const history = useHistory();

    return {
        id: `condition-monitoring-card-${powertrainId}`,
        options: [
            {
                id: `AssetDetails-${powertrainId}`,
                title: translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetDetailsLabel'),
                onOptionSelect: () => history.push({ pathname: routes.AssetDetails, search: new URLSearchParams({ powertrainId }).toString() })
            },
            {
                id: `CrossAssetDetails-${powertrainId}`,
                title: translate('ABB.Powertrain.Frontend.condMonitoringOverviewCrossAssetLabel'),
                onOptionSelect: () => history.push({ pathname: routes.CrossKpi, search: new URLSearchParams({ powertrainId }).toString() })
            }
        ]
    };
};

export const getHeaderTextLabel = (components) => {
    const assetOrAssets = components.length !== 1 ?
        translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetsLabel')
        : translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetLabel');
    return `${components.length} ${assetOrAssets}`;
};
