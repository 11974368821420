import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { apiCallStatus } from 'helpers/constants';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GeneralButton, GeneralSelect, Switch, TextArea } from 'sharedComponents';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { maxCommentSize } from './constants';
import { checkIfPreselectedCause, getEventCauseOptions } from './helpers';
import { causeOfEvents } from '../../../constants';

const SelfFaultEvaluationCommentActions = ({ model, actions, selectedAsset }) => {
    const { t: translate } = useTranslation();
    const [causeOptions] = useState(getEventCauseOptions(translate, false));
    const [cause, setCause] = useState(checkIfPreselectedCause(model.selectedEvent.rawEvent, causeOptions));
    const [preSelectedCause, setPreSelectedCause] = useState(model.selectedEvent.rawEvent.initialForFutureEvents);
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (model.postCommentStatus === apiCallStatus.SUCCESS) {
            if (!preSelectedCause) {
                setCause(causeOptions.find(c => c.index === causeOfEvents.Unclassified));
            }
            setComment('');
        }
    }, [model.postCommentStatus]);

    const postComment = () => {
        actions.postComment(selectedAsset, model.selectedEvent, cause.index, comment, preSelectedCause);
    };

    const eventCauseSelectHandler = (eventCause) => {
        setCause(eventCause);
    };

    return (
        <Col className='comments-actions' xl={5} xs={12}>
            <span className='self-evaluate-comment-label'>
                {translate('ABB.Powertrain.Frontend.commentLabelSelfEvaluate')}
            </span>
            <LoadingStatusWrapper loadingStatus={model.postCommentStatus} >
                <div className='select-cause-container'>
                    <span className='select-cause-label'>
                        {translate('ABB.Powertrain.Frontend.selectCauseLabelSelfEvaluate')}
                    </span>
                    <GeneralSelect
                        className='select-cause'
                        options={causeOptions}
                        value={cause}
                        onChange={eventCauseSelectHandler}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.index}
                        menuShouldScrollIntoView={false}
                        isSearchable={false}
                    />
                </div>
                <div className='preselection-container'>
                    <Switch
                        className='preselection-switch'
                        onChange={() => setPreSelectedCause(!preSelectedCause)}
                        checked={preSelectedCause}
                    />
                    <span className='preselection-label'>{translate('ABB.Powertrain.Frontend.preselectionLabelSelfEvaluate')}</span>
                </div>
                <div className='event-comment'>
                    <div className='comment-wrapper'>
                        <div className='comment-label'>
                            {translate('ABB.Powertrain.Frontend.textareaLabelSelfEvaluate')}
                        </div>
                        <div className='comment-length'>{`${comment.length} / 500`}</div>
                    </div>
                    <TextArea
                        className='action-textarea'
                        resizable={false}
                        maxLength={maxCommentSize}
                        onChange={(e) => setComment(e)}
                        value={comment}
                        placeholder={translate(
                            'ABB.Powertrain.Frontend.textareaPlaceholderSelfEvaluate'
                        )}
                    />
                </div>
                <div className='action-button-container'>
                    <GeneralButton
                        disabled={!comment.length}
                        type='normal'
                        className='action-button'
                        text={translate('ABB.Powertrain.Frontend.postButtonSelfEvaluate')}
                        onClick={postComment}
                    />
                </div>
            </LoadingStatusWrapper>
        </Col>
    );
};

SelfFaultEvaluationCommentActions.propTypes = {
    actions: PropTypes.shape({
        postComment: PropTypes.func,
    }),
    model: PropTypes.shape({
        selectedEvent: PropTypes.object,
        postCommentStatus: PropTypes.string,
    }),
    selectedAsset: PropTypes.object
};

export default SelfFaultEvaluationCommentActions;
