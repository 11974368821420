import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as commonActions from '../actions';
import TrendSignalChartComponent from './TrendSignalChartComponent';

import { getModel } from './selectors';
import { contentTabs } from '../../constants';


const TrendSignalChartContainer = (props) => {
    const { model, actions } = props;

    useEffect(() => {
        if (model.activeTab === contentTabs.TRENDSIGNAL && model.selectedTrendsData.length) {
            actions.reloadAllSelectedTrendKpiData(model.selectedTrendsData);
        }
    }, [props.dateSelection, model.activeTab]);


    return <TrendSignalChartComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...commonActions }, dispatch)
    };
};

const TrendSignalKPIListContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendSignalChartContainer);

export default TrendSignalKPIListContainerConn;
