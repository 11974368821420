import { statusColumns } from './constants';

function convertToLocalTime(source) {
    const date = new Date(source);
    const options = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
}

export const convertDailyAgingStressData = (chartData) => {
    const xUnit = null;
    const yUnit = null;
    const data = [];
    const categories = [];

    if (Array.isArray(chartData) && chartData.length > 0) {
        chartData.forEach(({ timestamp, value }) => {
            if (timestamp && value) {
                data.push({
                    color: statusColumns[value].color,
                    y: statusColumns[value].y,
                    label: statusColumns[value]?.label,
                    timestamp: timestamp
                });
                categories.push(convertToLocalTime(timestamp));
            }
        });
    }

    if (Array.isArray(chartData) && chartData.length < 30 && chartData.length > 0) {
        const dateLength = chartData.length;
        const numberOfExtraEmptyDays = 30 - dateLength;

        for (let i = 0; i < numberOfExtraEmptyDays; i++) {
            categories.push(null);
            data.push(null);
        }
    }

    return {
        xUnit,
        yUnit,
        data,
        categories
    };
};
