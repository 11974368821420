import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GeneralDialog, LoadingStatusWrapper } from 'sharedComponents';
import { IconBellStrikeThrough } from 'svgIcons/MotionPortalIcons';
import { EditMutedAsset } from '../../../../UserProfileSettings/NotificationSettings/MutedAssets/EditMuteSettingsPopup';
import { getMutedGroups, formatUpdatedMuteSettings } from './helpers';
import './style.scss';


const MuteAssetComponent = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedAsset,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateMuteSettingsLoadingStatus
    } = props.model;
    const { muteAsset } = props.actions;

    const [disabledForPeriod, setDisabledForPeriod] = useState(null);
    const [period, setPeriod] = useState(null);
    const [mutedGroups, setMutedGroups] = useState(null);

    const setSelectedAssetOptions = () => {
        setMutedGroups(getMutedGroups(assetMuteSettings?.notificationGroups) || null);
        setDisabledForPeriod(!!(assetMuteSettings?.startDate && assetMuteSettings?.endDate));
        setPeriod(assetMuteSettings?.startDate && assetMuteSettings?.endDate ?
            {
                from: assetMuteSettings.startDate,
                to: assetMuteSettings.endDate
            } : null);
    };

    useEffect(() => {
        setSelectedAssetOptions();
    }, [assetMuteSettingsLoadingStatus]);

    const save = () => {
        muteAsset(
            formatUpdatedMuteSettings(
                selectedAsset.id,
                assetMuteSettings.notificationGroups,
                mutedGroups,
                period
            ),
            props.close
        );
    };

    return <GeneralDialog
        show={props.show}
        close={props.close}
        title={<>
            <IconBellStrikeThrough />{`${translate('ABB.Powertrain.Frontend.muteAssetTitle')} - ${selectedAsset.componentName}`}
        </>}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.saveButton'),
            onClick: () => { save(); },
            disabled: mutedGroups?.length === 0
        }}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            onClick: () => { props.close(); }
        }}
        closeButton={true}
        modalClassName={'edit-muted-asset-popup'}
    >
        <LoadingStatusWrapper loadingStatus={[
            assetMuteSettingsLoadingStatus,
            updateMuteSettingsLoadingStatus
        ]}>
            <EditMutedAsset
                notificationGroups={assetMuteSettings?.notificationGroups}
                disabledForPeriod={disabledForPeriod}
                setDisabledForPeriod={setDisabledForPeriod}
                period={period}
                setPeriod={setPeriod}
                mutedGroups={mutedGroups}
                setMutedGroups={setMutedGroups}
            />
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

MuteAssetComponent.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    model: PropTypes.shape({
        assetMuteSettings: PropTypes.object,
        assetMuteSettingsLoadingStatus: PropTypes.string,
        updateMuteSettingsLoadingStatus: PropTypes.string,
        selectedAsset: PropTypes.object
    }),
    actions: PropTypes.shape({
        muteAsset: PropTypes.func
    })
};

export default MuteAssetComponent;
