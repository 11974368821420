import React from 'react';
import { translate } from 'helpers/translateHelper';
import { toLocaleDateTimeString } from 'helpers/dateHelper';
import './styles.scss';

const DriveStatusChartToolTipComponent = ({ timeStamp, dateConfig, value }) => {
    const style1 = {
        backgroundColor: value?.color,
    };
    return <div className='custom-drive-chart-tooltip'>
        <div className='t-header'>
            {toLocaleDateTimeString(timeStamp, dateConfig)}
        </div>
        <br />
        <div className='t-item'>
            <span className='symbol' style={style1} />
            <span>{`${translate('ABB.Powertrain.Frontend.conditionDriveStatusLabel')} ${translate(value?.label)}`}</span>
        </div>
    </div>;

};
export default DriveStatusChartToolTipComponent;
