import {
    groupBy,
    map,
    filter,
    find,
    differenceBy,
    size
} from 'lodash';

import logger from 'helpers/logger';
import { mapUnknownCountry } from 'helpers/filterHelper';
import { pageSizeSitesAndAssetGroups } from './constants';
import { commonRequestParams } from 'helpers/constants';


export const getCountriesFromRawSiteList = (sitesRaw) => {
    const groupedCountries = groupBy(sitesRaw, 'countryCode');
    const mappedCountries = map(groupedCountries, (group) => {
        const { countryCode, countryName } = group[0];
        return {
            countryCode,
            countryName
        };
    });
    return mapUnknownCountry(mappedCountries);
};

export const mapAssetGroups = (assetGroups) => {
    return assetGroups?.sort((a, b) =>
        (a.name || '').toLowerCase().localeCompare(b.name.toLowerCase())
    );
};

export const getSitesByCountry = (allSites, allCountries, filteredCountries) => {
    let countries = allCountries;
    if (filteredCountries.length > 0) {
        countries = filteredCountries;
    }
    const sitesInCountries = filter(allSites, site => !!find(countries, { countryCode: site.countryCode }));
    return sitesInCountries.sort((a, b) =>
        (a.siteName || '').toLowerCase().localeCompare(b.siteName.toLowerCase())
    );
};

export const getDefaultOrganization = (organizations) => {
    let homeOrg = null;
    if (organizations?.length) {
        organizations.sort((a, b) =>
            (a.name || '').toLowerCase().localeCompare(b.name.toLowerCase()));
        homeOrg = organizations.find(org => org.isHomeOrganization === true);

        if (!homeOrg) {
            logger.info('Home organizations not found!');
            homeOrg = organizations[0];
            logger.info('Selecting first organization as default...');
        }
    }

    return [homeOrg];
};

export const getDefaultFilters = () => {
    return {
        organizations: [],
        countries: [],
        sites: [],
        assetGroup: null,
        sitesChecked: false,
        assetGroupChecked: false
    };
};

export const isDifferentOrganizationSelection = (savedOrganizations, editedOrganization) => {
    return savedOrganizations.length !== editedOrganization.length || differenceBy(savedOrganizations, editedOrganization, 'organizationID').length;
};
const findFilterValue = (availableList, savedFilters, propertyName) => {
    return filter(availableList, (a) => !!find(savedFilters, { [propertyName]: a[propertyName] }));
};

export const validateOrgFilter = (organizations, savedFilters) => {
    if (!savedFilters || savedFilters?.length === 0) {
        return true;
    }
    const foundOrganizations = findFilterValue(organizations, savedFilters, 'organizationID');

    return foundOrganizations?.length === savedFilters?.length;
};

export const validateCountryFilter = (rawSites, savedFilters) => {
    if (!savedFilters?.length) {
        return true;
    }
    const foundCountries = findFilterValue(rawSites, savedFilters, 'countryCode');
    const foundGroups = groupBy(foundCountries, 'countryCode');
    return size(foundGroups) === savedFilters.length;
};

export const validateSiteFilter = (rawSites, savedFilters) => {
    if (!savedFilters?.length) {
        return true;
    }
    const foundSites = findFilterValue(rawSites, savedFilters, 'siteName');
    return foundSites.length === savedFilters.length;
};

export const validateAssetGroupFilter = (assetGroups, savedFilter) => {
    if (!savedFilter) {
        return true;
    }
    let ret = false;
    const foundAssetGroups = findFilterValue(assetGroups, [savedFilter], 'id');
    ret = foundAssetGroups.length === 1;
    return ret;
};

export const formatSiteAssetGroupRequest = (organizations) => {
    return {
        ...commonRequestParams,
        pageSize: pageSizeSitesAndAssetGroups,
        organizationsIds: map(organizations, org => { return org.organizationID; })
    };
};

export const formatAssetsRequest = (organizations) => {
    return {
        ...commonRequestParams,
        siteIds: [],
        assetGroupIds: [],
        organizationIds: map(organizations, org => { return org.organizationID; })
    };
};
