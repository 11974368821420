import React from 'react';
import PropTypes from 'prop-types';

const IconUnchecked = ({ width = '15px', height = '10px' }) => {
    return <svg className='unChecked-vector-icon' width={width} height={height} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.71001 1.71004L8.29001 0.290039L5.00001 3.59004L1.71001 0.290039L0.290009 1.71004L3.59001 5.00004L0.290009 8.29004L1.71001 9.71004L5.00001 6.41004L8.29001 9.71004L9.71001 8.29004L6.41001 5.00004L9.71001 1.71004Z' fillOpacity='0.8' />
        </svg>;
};

IconUnchecked.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconUnchecked;
