import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconDegradedBatteryEmpty = ({ width = 24, height = 24, viewBox = '0 0 24 24', color = colors.red }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M1.875 6.82495L1.875 17.2499L18.75 17.25L18.75 6.82495L1.875 6.82495Z' stroke={color} strokeLinejoin='bevel'/>
		<path d='M18.975 14.6251L20.925 14.6251L21.45 13.9501L21.45 11.7751L21.45 9.75013L20.925 9.07513L18.975 9.07513' stroke={color} strokeLinejoin='bevel'/>
		<path d='M4.125 8.7002L4.125 15.3002' stroke={color} strokeLinejoin='bevel'/>
	</svg>;
};

IconDegradedBatteryEmpty.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};


export default IconDegradedBatteryEmpty;
