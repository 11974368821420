
/**
 * Removes unallowed chars from the provided string. Returns null if
 * something other than a valid string is provided.
 *
 * @param {string} inputString
 * @return {string} modifiedString
 */
export const removeUnallowedCharsFromString = ( inputString ) => {
	const UNALLOWED_INPUT_CHARS = ['<', '>', '&', '@', '*', '%', '\t', '\r', '\n', '\b', '=', '$', ';', '--'];
	let retValue = null;
	if (typeof inputString === 'string') {
		retValue = inputString;
		UNALLOWED_INPUT_CHARS.forEach((inputChar) => {
		retValue = retValue.replace(inputChar, '');
		});
	}
return retValue;
};
