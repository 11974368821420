import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, SidePanel } from 'sharedComponents';
import { AssetAndPowertrainList } from './AssetAndPowertrainList';
import { Filter } from './Filter';
import { togglePowertrainAssets } from 'helpers/togglePowertrainAssetsHelper';
import { useHistory } from 'react-router';
import { useQueryParams } from 'sharedHooks/useQuery';
import { toggleTabsWhenSelectionChanges } from '../../helpers';

const AssetAndPowertrainFilterComponent = (
    {
        model,
        actions
    }
) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const query = useQueryParams();

    const handleTabSelection = (tab) => {
        actions.resetAssetAndPowertrainFilters();
        actions.setActiveTabAction(tab);
        history.replace(history.location.pathName);
    };

    useEffect(() => {
        toggleTabsWhenSelectionChanges(query, model.activeTab, actions.setActiveTabAction);
    }, [query.powertrainId, query.assetId]);

    return <SidePanel className='asset-and-powertrain-filter-container'>
        <ToggleButton
            options={togglePowertrainAssets(translate)}
            onSelected={handleTabSelection}
            selectedValue={model.activeTab}
        />
        <Filter {...actions} />
        <AssetAndPowertrainList model={model} actions={actions} />
    </SidePanel>;
};

AssetAndPowertrainFilterComponent.propTypes = {
    actions: PropTypes.shape({
        setActiveTabAction: PropTypes.func
    }),
    model: PropTypes.shape({
        activeTab: PropTypes.any
    })
};

export default React.memo(AssetAndPowertrainFilterComponent);
