import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';


class AssetLabels {

	getAssetTags(assetID) {
		const endpoint = formatEndpoint(endpoints.assetDetails.tags.getTags, { assetID });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	getSuggestedAssetTags(organizationID) {
		const endpoint = formatEndpoint(endpoints.assetDetails.tags.getSuggestedTags, { organizationID });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	addTagToAsset(assetID, tagName) {
		const endpoint = formatEndpoint(endpoints.assetDetails.tags.addTags, { assetID });
		return RestManagerWithoutBaseUrl.post(endpoint, { tagValue: tagName });
	}

	removeTagFromAsset(tagID) {
		const endpoint = formatEndpoint(endpoints.assetDetails.tags.deleteTags, { tagID });
		return RestManagerWithoutBaseUrl.delete(endpoint);
	}
}

export default new AssetLabels();
