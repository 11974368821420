import React, { useEffect, useState } from 'react';

import colors from 'theme/_colors.scss';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { translate } from 'helpers/translateHelper';
import { convertScatterPlotsData } from './helpers';
import { afterChartReDraw, chartLegendItemClick } from '../../../../helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import ScatterTooltip from './ScatterTooltip';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';

export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.BOOST];

const useChartConfig = (chartData, removeFn) => {

    const [options, setOptions] = useState({});

    useEffect(() => {
        const scatterData = convertScatterPlotsData(chartData);
        const { xUnit, yUnit, components, allSeriesLength } = scatterData;

        setOptions({
            chart: {
                type: 'scatter',
                className: 'chartstyle',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                zoomType: 'xy',
                events: {
                    displayError: function (event) {
                        // https://github.com/highcharts/highcharts/issues/14006
                        if (event.code === 12) {
                            event.preventDefault();
                        }
                    },
                    redraw: function (chartInstance) {
                        afterChartReDraw(chartInstance.target.container.id, removeFn);
                    }
                },
            },
            boost: {
                useGPUTranslations: false,
                usePreAllocated: true
            },
            title: {
                text: ''
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                title: {
                    enabled: true,
                    text: xUnit
                },
                showLastLabel: true,
                id: 'xAxis'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: yUnit,
                },
                id: 'yAxis'
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 3,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: colors.doveGray
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    boostThreshold: allSeriesLength > defaultBoostThreshold ? 1 : 0
                },
                series: {
                    events: {
                        legendItemClick: chartLegendItemClick
                    }
                }
            },
            lang: {
                noData: renderToStaticMarkup(<ChartNoDataText
                    title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                    text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />)
            },
            noData: {
                useHTML: true
            },
            series: components,
            tooltip: {
                useHTML: true,
                backgroundColor: 'white',
                formatter: function () {
                    return renderToStaticMarkup(
                        <ScatterTooltip
                            component={this.series.userOptions.component}
                            xUnit={xUnit}
                            yUnit={yUnit}
                            x={this.x}
                            y={this.y}
                            date={this.point.date}
                        />);
                }
            },
            legend: {
                useHTML: true,
                layout: 'horizontal',
                align: 'right',
                itemStyle: {
                    fontFamily: 'ABBvoice-Regular',
                    fontSize: '14px',
                },
                itemDistance: 25,
                symbolHeight: 8,
                symbolWidth: 8,
                symbolRadius: 0,
                itemMarginTop: 10,
                labelFormatter: function () {
                    return renderToStaticMarkup(<RemovableLegend
                        signalSourceName={this.userOptions.component}
                        legend={this}
                    />);
                }
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        states: {
                            hover: {
                                fill: colors.white,
                                stroke: 'none'
                            },
                            select: {
                                fill: colors.white,
                                select: 'none'
                            }
                        }
                    }
                }
            },
            exporting: {
                enabled: false,
            }
        });
    }, [chartData]);

    return options;
};

export default useChartConfig;
