import { onlineHelpLinks } from 'helpers/constants';

export const actionTypes = {
    ACCEPT_EULA: 'userProfile/ACCEPT_EULA',
};

export const mainMenuLinks = {
    onlineHelpLinks: onlineHelpLinks,
    eula: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107045A3315&LanguageCode=en&DocumentPartId=&Action=Launch',
    abbAUP: 'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107046A9718&LanguageCode=en&DocumentPartId=&Action=Launch',
    privacyPolicy: 'https://new.abb.com/privacy-policy'
};

