import { getTranslatedKPIName } from 'helpers/translateHelper';
import colors from 'theme/_colors.scss';

export const convertScatterPlotsData = (chartData) => {
    let name = '';
    let xUnit = null;
    let yUnit = null;
    let allSeriesLength = 0;
    const components = [];

    if (chartData && chartData.length && chartData.length > 0) {
        name = getTranslatedKPIName(chartData[0].kpi);
        chartData.map((cd, index) => {
            components.push({
                component: cd.component.componentName || '',
                data: cd.data && cd.data.length ? cd.data[0].values : [],
                color: cd.color || colors.lavenderMagenta,
                name,
                turboThreshold: 0,
                id: index,
                xAxis: 'xAxis',
                yAxis: 'yAxis'
            });
            if (cd.data && cd.data.length && cd.data[0]?.values?.length) {
                xUnit = cd.data[0].config.xUnit;
                yUnit = cd.data[0].config.yUnit;
            }
            if (cd.data[0]?.values?.length) {
                allSeriesLength = allSeriesLength + cd.data[0].values.length;
            }
        });
    }

    return { xUnit, yUnit, components, allSeriesLength };
};
