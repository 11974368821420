import { indexOf, map, remove } from 'lodash';
import { translate } from 'helpers/translateHelper';
import { notificationSettingsTabs } from './constants';


export const getTabOptions = () => {
    const options = [
        {
            label: translate('ABB.Powertrain.Frontend.notificationChannelsTabTitle'),
            value: notificationSettingsTabs.NOTIFICATION_CHANNELS,
        },
        {
            label: translate('ABB.Powertrain.Frontend.mutedAssetsTabTitle'),
            value: notificationSettingsTabs.MUTED_ASSETS,
        }
    ];

    return options;
};

export const updateSelectedItems = (currentSelection, newItem) => {
    if (currentSelection) {
        const foundIndex = indexOf(currentSelection, newItem);
        if (foundIndex > -1) {
            if (currentSelection?.length === 1) {
                return [];
            }
            remove(currentSelection, (item, i) => i === foundIndex);
            return currentSelection;
        } else {
            return [...currentSelection, newItem];
        }
    } else {
        return [newItem];
    }
};

export const updateAllSelection = (selectedAssets, allAssets) => {
    return selectedAssets?.length === allAssets?.length ? [] : map(allAssets, (asset) => asset.assetId);
};
