import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconTrash = ({ width = 12, height = 15, viewBox = '0 0 12 15', color = colors.codGray, style }) => {
	return (
		<svg style={style} width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M3 0.5H9V1.5H3V0.5ZM11 2.5H0V4.5H1V12.41C0.998675 12.6848 1.05183 12.9572 1.15639 13.2114C1.26096 13.4655 1.41486 13.6965 1.60919 13.8908C1.80353 14.0851 2.03446 14.239 2.28863 14.3436C2.5428 14.4482 2.81517 14.5013 3.09 14.5H8.73C9.33204 14.5 9.90942 14.2608 10.3351 13.8351C10.7608 13.4094 11 12.832 11 12.23V4.5H12V2.5H11ZM5 12.5H4V5.5H5V12.5ZM8 12.5H7V5.5H8V12.5Z' fill={color} />
		</svg>
	);
};

IconTrash.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string,
	style: PropTypes.object
};

export default IconTrash;
