import React from 'react';
import PropTypes from 'prop-types';

const IconUpload = ({ width = '14', height = '14', viewBox = '0 0 14 14', color = 'none', fill = 'black' }) =>
	<svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M1 11V13H13V11H14V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V11H1ZM7 0L11.5 4.5L10 6L8 4V12H6V4L4 6L2.5 4.5L7 0Z' fill={fill} />
	</svg>
	;

IconUpload.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fill: PropTypes.string,
};

export default IconUpload;

