
import { find, transform } from 'lodash';
import { createSelector } from 'reselect';

import { TAB_SECTIONS } from './../constants';

const getSelectedMeasurementTypeId = ({ alertAlarmOffsetConfig }) => {
    return alertAlarmOffsetConfig.selectedMeasurementTypeID;
};

const getOperationalParameters = createSelector([
    ({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.trendKPIs,
    ({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.editState
],
    (
        kpiList,
        editState
    ) => {
        const sectionGroup = find(kpiList, { sectionId: TAB_SECTIONS.OperatingParameters });
        let opParameters = [];
        if (sectionGroup) {
            opParameters = transform(sectionGroup.data, (acc, kpi) => {
                if (kpi.isCalibrationEnabled) {
                    const selectedMeasurementTypeID = kpi.measurementTypeID;
                    const config = {
                        offset: kpi.measurementCalibration,
                        precision: kpi.measurementTypePrecision
                    };
                    const selectedKpiEditState = editState[selectedMeasurementTypeID];
                    const ret = {
                        ...kpi,
                        config,
                        editState: selectedKpiEditState
                    };
                    acc.push(ret);
                }
            }, []);
        }
        return opParameters;
    });


const getSelectedKpi = createSelector([
    getOperationalParameters,
    getSelectedMeasurementTypeId,
    ({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.editState
],
    (
        opParameters,
        selectedMeasurementTypeID,
        editState
    ) => {
        let ret = null;
        const selectedKpi = find(opParameters, (kpi) => kpi.measurementTypeID === selectedMeasurementTypeID);
        if (selectedKpi) {
            const config = {
                offset: selectedKpi.measurementCalibration,
                precision: selectedKpi.measurementTypePrecision
            };
            const selectedKpiEditState = editState[selectedMeasurementTypeID];
            ret = {
                ...selectedKpi,
                config,
                isSaveDisabled: selectedKpiEditState ? !selectedKpiEditState.isDirty : false
            };
        }
        return ret;
    });


export const getModel = createSelector([
    getSelectedKpi,
    getOperationalParameters
],
    (
        selectedKpi,
        operationalParameters
    ) => {
        return {
            selectedKpi,
            operationalParameters
        };
    });
