import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TimeSelectorRow, ToggleButton } from 'sharedComponents';
import { Graph } from '../ChartView/Graph';
import { getToggleOptions } from './helpers';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';

const ChartViewComponent = ({
    model: {
        activeTab,
        dateSelection
    },
    actions
}) => {
    const { t: translate } = useTranslation();
    const [toggleOptions] = useState(getToggleOptions());

    const handleTabSelection = useCallback((tab) => {
        actions.setActiveTab(tab);
        actions.resetChart();
    }, []);

    const handleSelectDate = (from, to) => {
        if (from && to) {
            actions.setSelectedDates(from, to);
        }
    };

    return (
        <Row className='cross-asset-analysis-content'>
            <Col md={12} className='cross-asset-analysis-tab-container'>
                <Row className='button-row-container'>
                    <Col xl={10} sm='auto' md={6}>
                        <ToggleButton
                            options={toggleOptions}
                            onSelected={handleTabSelection}
                            selectedValue={activeTab}
                        />
                    </Col>

                    <Col xl={2} sm='auto' md={6}>
                        <TimeSelectorRow
                            fromDate={dateSelection.from}
                            toDate={dateSelection.to}
                            label=''
                            handleSelectDate={handleSelectDate}
                            maxDaysRange={90}
                            maxDaysBack={roleBasedCapabilityHelper.hasExtendedDateSelector() ? 365 : 90}
                            preSelectedDays={7}
                            numberOfMonths={2}
                            footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                        />
                    </Col>
                </Row>
                <Graph />
            </Col>
        </Row>
    );
};

export default ChartViewComponent;
