import { createSelector } from 'reselect';
import { groupAndSortByOverallStatus } from '../helpers';
import { filterAssets } from 'helpers/assetAndPowertrainFilterHelper';
import { getMonitoredGeneralFilteredAssets } from 'helpers/selectors';


const getFilters = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview;
};

const getFormattedAssets = createSelector(
    [getMonitoredGeneralFilteredAssets, getFilters],
    (assets, filters) => {
        return groupAndSortByOverallStatus(filterAssets(assets, filters));
    }
);

const getMonitoredGeneralFilteredAssetsCount = createSelector([getMonitoredGeneralFilteredAssets],
    (assets) => {
        return assets.length;
    }
);

export const getModel = createSelector([
    getMonitoredGeneralFilteredAssetsCount,
    getFormattedAssets,
],
    (
        generalFilteredAssetsCount,
        formattedAssets,
    ) => {
        return {
            generalFilteredAssetsCount,
            formattedAssets,
        };
    });
