import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { LoadingIndicator } from 'sharedComponents';
import logger from 'helpers/logger';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'helpers/constants';
import './style.scss';


const TokenExchangeComponent = ({ model: { userManager, redirectUrl }, actions: { loadUserInfo } }) => {
    const [tokensRetrieved, setTokensRetrieved] = useState(false);
    const [loadingVisible, setLoadingVisible] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (tokensRetrieved) {
            if (redirectUrl) {
                history.push(redirectUrl);
            } else {
                history.push('/');
            }
        }
    }, [tokensRetrieved]);

    const authenticationErrorNotify = (err) => {
        notify('An error has occurred while loading the user information, please come back later.', NOTIFICATION_TYPES.ERROR, true);
        logger.debug(err);
    };

    useEffect(() => {
        if (!tokensRetrieved && userManager) {
            userManager.signinRedirectCallback()
                .then((userCredentials) => {
                    userManager.storeUser(userCredentials);
                    loadUserInfo(
                        userCredentials.id_token,
                        userManager.settings.client_id,
                        setTokensRetrieved,
                        () => { setLoadingVisible(false); authenticationErrorNotify(); }
                    );
                });
        }
    }, [userManager]);


    return <div className='token-exchange'>
        {loadingVisible &&
            <LoadingIndicator text='Logging in...' />}
    </div>;
};

TokenExchangeComponent.propTypes = {
    model: PropTypes.shape({
        userManager: PropTypes.object,
        redirectUrl: PropTypes.string
    }),
    actions: PropTypes.shape({
        loadUserInfo: PropTypes.func
    })
};

export default TokenExchangeComponent;
