import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';

import { getMenuOptions } from './helper';
import { UserSettingsSidepanelComponent } from './UserSettingsSidepanel';
import { useUserCapability } from 'sharedHooks';

import './style.scss';

const UserProfileSettingsComponent = () => {
    const location = useLocation();
    const [selectedOption, setSelectedOption] = useState(null);
    const userCapabilityHelper = useUserCapability();
    const profileOptions = getMenuOptions(userCapabilityHelper);

    useEffect(() => {
        setSelectedOption(location ? location.pathname : profileOptions[0].route);
    }, [location]);

    const renderRoutes = () =>
        profileOptions.map((option) => !option?.hidden &&
            <Route path={option.route} key={option.id}>
                {option.component}
            </Route>);

    return <div className='user-profile-settings-wrapper'>
        <div className='user-profile-settings-container'>
            <UserSettingsSidepanelComponent
                options={profileOptions}
                selectedOption={selectedOption} />
            <Switch>
                {renderRoutes()}
                <Redirect from='*' to={profileOptions[0].route} />
            </Switch>
        </div>
    </div>;
};

export default UserProfileSettingsComponent;
