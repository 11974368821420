import React, { useEffect, useState } from 'react';
import colors from 'theme/_colors.scss';
import { getTranslatedKPIName, translate } from 'helpers/translateHelper';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { defaultBoostThreshold, firstDataColor } from 'helpers/constants';
import { renderToStaticMarkup } from 'react-dom/server';
import ScatterTooltip from './ScatterTooltip';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { afterChartReDraw } from '../../../../helpers';


export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.BOOST];

const useChartConfig = (data, scatterConfig, scatterKpis, removeFn) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        const { yUnit, xUnit } = scatterConfig || {};
        const name = scatterConfig ? getTranslatedKPIName(scatterConfig) : null;
        const id = scatterKpis.findIndex((item) => item.displayName === name);
        setOptions({
            chart: {
                type: 'scatter',
                className: 'chartstyle',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                zoomType: 'xy',
                height: null,
                events: {
                    displayError: function (event) {
                        // https://github.com/highcharts/highcharts/issues/14006
                        if (event.code === 12) {
                            event.preventDefault();
                        }
                    },
                    render: function (chartInstance) {
                        afterChartReDraw(chartInstance.target.container.id, removeFn);
                    }
                }
            },
            boost: {
                useGPUTranslations: false,
                usePreAllocated: true
            },
            title: {
                text: ''
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: xUnit
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: yUnit
                }
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 3,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: colors.doveGray
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    boostThreshold: defaultBoostThreshold
                }
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'white',
                formatter: function () {
                    return renderToStaticMarkup(
                        <ScatterTooltip
                            xUnit={xUnit}
                            yUnit={yUnit}
                            point={this.point}
                        />);
                }
            },
            lang: {
                noData: renderToStaticMarkup(<ChartNoDataText
                    title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                    text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />)

            },
            noData: {
                useHTML: true
            },
            series: data.length ? [
                {
                    data,
                    name,
                    id,
                    color: firstDataColor,
                    turboThreshold: 0
                }
            ] : [],
            legend: {
                useHTML: true,
                layout: 'horizontal',
                align: 'right',
                itemStyle: {
                    fontFamily: 'ABBvoice-Regular',
                    fontSize: '14px',
                },
                itemDistance: 25,
                symbolHeight: 8,
                symbolWidth: 8,
                symbolRadius: 0,
                itemMarginTop: 10,
                labelFormatter: function () {
                    return renderToStaticMarkup(<RemovableLegend
                        signalSourceName={this.userOptions.component}
                        legend={this}
                    />);
                }
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        states: {
                            hover: {
                                fill: colors.white,
                                stroke: 'none'
                            },
                            select: {
                                fill: colors.white,
                                select: 'none'
                            }
                        }
                    }
                }
            },
            exporting: {
                enabled: false,
            }
        });
    }, [data]);

    return options;
};

export default useChartConfig;
