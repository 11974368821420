import featureConfigHelper from 'helpers/featureConfigHelper';

const featureConfig = new featureConfigHelper();

export const menuBarItems = [
    {
        route: '/conditionmonitor',
        title: 'ABB.Powertrain.Frontend.ptConditionMonitoringMainMenu'
    },
    {
        route: '/pt-asset-details',
        title: 'ABB.Powertrain.Frontend.ptPTAndAssetDetailsMainMenu'
    },
    ...featureConfig.isReportsFeatureEnabled() ? [
        {
            route: '/reports',
            title: 'ABB.Powertrain.Frontend.ptReportsMainMenu'
        }] : [],
    {
        route: '/tools',
        title: 'ABB.Powertrain.Frontend.ptToolsMainMenu'
    },
];
