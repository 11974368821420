export const CONDITION_STATUS = {
    Poor: 3,
    Tolerable: 2,
    Good: 1,
    Unknown: 4
};

export const HIGH_ERROR_THRESHOLD = 'KpiHighErrorThreshold';
export const LOW_ERROR_THRESHOLD = 'KpiLowErrorThreshold';

export const HIGH_WARNING_THRESHOLD = 'KpiHighWarningThreshold';
export const LOW_WARNING_THRESHOLD = 'KpiLowWarningThreshold';
export const CONDITTION_INDICATOR_FILTERS = ['KpiThreshold', 'KpiShortTerm', 'KpiLongTerm'];
export const WARNING_COLOR = '#ffa20014';
export const ERROR_COLOR = '#ef3a3414';
export const ALARM_LINE_COLOR = '#EF3A34';
export const ALERT_LINE_COLOR = '#FFA200';
