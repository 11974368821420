export const actionTypes = {
    SET_ACTIVE_TAB: 'eventDetailsCMD/SET_ACTIVE_TAB',
    CLEAR_ASSISTANCE_PAGE: 'eventlist/assistance/CLEAR_ASSISTANCE_PAGE',
    GET_EVENT_LOGGERS: 'eventlist/assistance/GET_EVENT_LOGGERS',
    ERROR_STATUS_UPDATE: 'overview/ERROR_STATUS_UPDATE',
    ADD_SELECTED_LOGGER_SIGNAL: 'eventlist/assistance/ADD_SELECTED_LOGGER_SIGNAL',
    REMOVE_SELECTED_LOGGER_SIGNAL: 'eventlist/assistance/REMOVE_SELECTED_LOGGER_SIGNAL',
    CLEAR_LOGGER_FILE: 'eventList/assistance/CLEAR_LOGGER_FILE',
    DOWNLOAD_LOGGER: 'eventlist/assistance/DOWNLOAD_LOGGER',
    CLEAR_SELF_EVALUATION_STORE: 'eventDetails/selfFaultEvaluation/CLEAR_SELF_EVALUATION_STORE',
    CLEAR_TREND_SIGNAL_STORE: 'eventDetails/selfFaultEvaluation/CLEAR_TREND_SIGNAL_STORE'
};

export const contentTabs = {
    SELFEVALUATE: 'ABB.Powertrain.Frontend.SelfEvaluateLabel',
    LOGGERVISUALISATION: 'ABB.Powertrain.Frontend.LoggerVisualisationLabel',
    TRENDSIGNAL: 'ABB.Powertrain.Frontend.opTrendsTabBtn',
    LOGGERDOWNLOAD: 'ABB.Powertrain.Frontend.LoggerDownloadLabel'
};

export const MAX_LOGGER_SIGNALS = 6;
