import { actionTypes, subscriptionStatus } from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
    activeTab: subscriptionStatus.ACTIVE,
    subscriptionArr: {
        data: {
            assetSubscriptionsInfos: []
        },
        error: null,
        loadingStatus: apiCallStatus.LOADING
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.activeTab
            };
        }
        case actionTypes.ACTIVE_SUB_DATA: {
            return {
                ...state,
                subscriptionArr: action
            };
        }
        default:
            return state;
    }
};
