import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconKpiOK = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = 'none' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke={colors.orangePeel} />
		<path fillRule='evenodd' clipRule='evenodd' d='M8.00001 3.63647L11.6364 10.9092H4.36365L8.00001 3.63647Z' fill={colors.orangePeel} />
	</svg>;

};

IconKpiOK.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconKpiOK;


