import { dataSourceTypes } from 'helpers/constants';
import { getDefaultFilters } from '../helpers';


export const formatSelectedFilters = (organization) => {
    return {
        ...getDefaultFilters(),
        organizations: [{
            dataSourceType: dataSourceTypes[organization.source],
            name: organization.name,
            organizationID: organization.organizationId,
            guid: organization.guid,
        }]
    };
};
