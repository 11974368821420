const mapUnitToToggleOption = (symbol, standard) => ({
    label: symbol,
    value: standard
});

export const getUnitToggleOptions = (group) => [
    mapUnitToToggleOption(group.measurementUnitGroup.standard1Symbol, 1),
    mapUnitToToggleOption(group.measurementUnitGroup.standard2Symbol, 2),
];

const mapUnitforGrayBEPayload = ({ standardId, measurementUnitGroup }, newStandard) => {
    const standard = newStandard || standardId;
    return {
        measurementUnitGroupName: measurementUnitGroup.name,
        measurementUnitGroupID: measurementUnitGroup.id,
        measurementUnitStandard: standard,
        measurementUnitSymbol: standard === 1 ?
            measurementUnitGroup.standard1Symbol :
            measurementUnitGroup.standard2Symbol
    };
};

export const mapUnitGrayBEPayload = (userData, newStandard, updatedGroupID) => {
    const newMeasurementUnits = userData.measurementUnitGroups.map((unitGroup) => {
        if (unitGroup.measurementUnitGroup.id === updatedGroupID) {
            return mapUnitforGrayBEPayload(unitGroup, newStandard);
        } else {
            return mapUnitforGrayBEPayload(unitGroup);
        }
    });

    return { userMeasurementUnitProfile: newMeasurementUnits };
};

export const mapAllUnitsGrayBEPayload = (userData, newStandard) => {
    const newMeasurementUnits = userData.measurementUnitGroups.map((unitGroup) => {
        return unitGroup.measurementUnitGroup.standard2Symbol ?
            mapUnitforGrayBEPayload(unitGroup, newStandard) :
            mapUnitforGrayBEPayload(unitGroup);
    });

    return { userMeasurementUnitProfile: newMeasurementUnits };
};

export const mapUnitMicroservicePayload = (userData, newStandard, updatedGroupID) => {
    const newMeasurementUnits = userData.measurementUnitGroups.map((unitGroup) => {
        if (unitGroup.measurementUnitGroup.id === updatedGroupID) {
            return {
                groupId: unitGroup.measurementUnitGroup.id,
                standardId: newStandard
            };
        } else {
            return {
                groupId: unitGroup.measurementUnitGroup.id,
                standardId: unitGroup.standardId
            };
        }
    });

    return { measurementUnitGroups: newMeasurementUnits };
};
export const mapAllUnitsMicroservicePayload = (userData, newStandard) => {
    const newMeasurementUnits = userData.measurementUnitGroups.map((unitGroup) => {
        return unitGroup.measurementUnitGroup.standard2Symbol ? {
            groupId: unitGroup.measurementUnitGroup.id,
            standardId: newStandard
        } : {
            groupId: unitGroup.measurementUnitGroup.id,
            standardId: unitGroup.standardId
        };
    });

    return {
        measurementUnitGroups: newMeasurementUnits,
        languageCode: userData.languageCode,
        landingPageId: userData.landingPageId
    };
};

export const updateUnitInUserDataState = (userData, newStandard, updatedGroupID) => {
    return userData.measurementUnitGroups.map((unitGroup) => {
        if (unitGroup.measurementUnitGroup.id === updatedGroupID) {
            return {
                ...unitGroup,
                standardId: newStandard,
            };
        } else {
            return unitGroup;
        }
    });
};

export const updateAllUnitsInUserDataState = (userData, newStandard) => {
    return userData.measurementUnitGroups.map((unitGroup) => {
        return unitGroup.measurementUnitGroup.standard2Symbol ? {
            ...unitGroup,
            standardId: newStandard,
        } : unitGroup;
    });
};
