import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconMaintenanceEventIndicator = ({ width = 24, height = 24, showInnerIcon = true }) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 32.478 32.478'>
		<g id='Group_16' transform='translate(-248.424 -837.623)'>
			<g id='Group_20'>
				<path id='Rounded_Rectangle_19_copy' fill={colors.amethyst} d='M0 0H22.965V22.965H0z' transform='rotate(135 -36.39 485.108)' />
				{
					showInnerIcon && <g id='Group_13' transform='translate(256.424 849.734)'>
						<g id='Group_12'>
							<g id='Group_10'>
								<path id='Path_6' d='M89.86 34.95l1.562-1.63 2.049 1.18h6.884l1.107.3 5.88-1.739 1.524.983-.536.834-1.021-.657-5.529 3.288-1.023 3.808-7.546-.02v-2.07l-.03.025zm3.351 2.722v-2.179l-1.61-.93-.437.457z' fill={colors.white} transform='translate(-89.86 -33.06)' />
								<path id='Path_7' d='M163.954 44.931a1.442 1.442 0 1 1-2.884 0 13.523 13.523 0 0 1 1.445-3.321 13.546 13.546 0 0 1 1.439 3.321z' fill={colors.white} transform='translate(-143.507 -39.865)' />
							</g>
							<g id='Group_11'>
								<path id='Path_8' d='M96.582 34.5h-3.111l-2.049-1.18-1.562 1.63 3.32 4.3.03-.025V41.3l7.546.02 1.023-3.808 5.529-3.288 1.021.657.536-.834-1.524-.983-5.88 1.739-1.107-.3h-3.772z' fill='none' transform='translate(-89.86 -33.06)' />
								<path id='Path_9' d='M95.02 39.467l.437-.457 1.61.93v2.178z' fill='none' transform='translate(-93.716 -37.506)' />
								<path id='Path_10' d='M162.553 43.318a.742.742 0 1 1-1.483 0 6.955 6.955 0 0 1 .743-1.708 6.967 6.967 0 0 1 .74 1.708z' fill='none' transform='translate(-143.075 -39.449)' />
							</g>
						</g>
					</g>
				}
			</g>
		</g>
	</svg>;
};

IconMaintenanceEventIndicator.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showInnerIcon: PropTypes.bool
};

export default IconMaintenanceEventIndicator;
