import { createSelector } from 'reselect';
import { containsNewFile } from './helpers';

const getBulkFiles = ({ bulkUpload }) => {
	return bulkUpload.bulkFiles;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getUploadingStatus = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.isUploading;
};

const getProcessingStatus = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.isProcessing;
};

const geUploadAllowedStatus = ({ bulkUpload }) => {
	return bulkUpload &&
		bulkUpload.uploadStatus &&
		bulkUpload.uploadStatus.data &&
		bulkUpload.uploadStatus.data.uploadAllowed &&
		containsNewFile(bulkUpload.bulkFiles);
};

const geProcessAllowedStatus = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.processAllowed;
};

const getDateInterval = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.dateInterval;
};

export const getModel = createSelector([
	getBulkFiles,
	getSelectedAsset,
	getUploadingStatus,
	getProcessingStatus,
	geUploadAllowedStatus,
	geProcessAllowedStatus,
	getDateInterval,
], (
	bulkFiles,
	selectedAsset,
	isUploading,
	isProcessing,
	isUploadAllowed,
	isProcessAllowed,
	dateInterval,
) => {
	return {
		bulkFiles,
		selectedAsset,
		isUploading,
		isProcessing,
		isUploadAllowed,
		isProcessAllowed,
		dateInterval,
	};
});
