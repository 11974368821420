
export const mapEnvironmentPropertiesResponse = res => {
    const environmentProperties = {
        basePortalUrl: res['FE_PortalUrl'],
        clarityID: res['FE_ClarityID'],
        environment: res['ASPNETCORE_ENVIRONMENT'],
        enablePowertrain: res['FE_EnablePowerTrain'] === 'true',
        enablePowertrainRedirect: res['FE_EnablePowertrainRedirect'] === 'true',
        powertrainURL: `${res['FE_PowerTrainUrl']}${process.env.SPABasePath}`,
        portalUrl: `${res['FE_PortalUrl']}${process.env.SPABasePath}`,
        whatsNewLink: res['FE_WhatsNewURL'],
        openIDIssuer: res['FE_OpenIDIssuer'],
        orgMgtUrl: res['FE_CIAM_URL'],
        openIDClientID: process.env.OVERWRITE_CLIENTID || res['FE_OpenIDClientID'], //overwrites clientId to support calling of remote BE from local environment
        portalHomePage: res['portalHomePage'],
        CMDAdminPortalUrl: res['FE_CMD_AdminPortalUrl'],
        disableOrganizationManagement: res['FE_DisableOrganizationManagementTab'] === 'true'
    };


    return environmentProperties;
};
