import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconGenericMachine = ({ width = '32', height = '32', viewBox = '0 0 32 32', color = colors.nero }) => {

    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.71 22.71L6.29 21.29L11 16.59L15 20.59L18.29 17.29L19.71 18.71L15 23.41L11 19.41L7.71 22.71ZM21
        11L23 13V26C23 26.5304 22.7893 27.0391 22.4142 27.4142C22.0391 27.7893 21.5304 28 21 28V29H5V28C4.46957
        28 3.96086 27.7893 3.58579 27.4142C3.21071 27.0391 3 26.5304 3 26V13L5 11H21ZM21 13H5V26H21V13ZM28
        3H16V9H18V5H27V26H25V28H27C27.5304 28 28.0391 27.7893 28.4142 27.4142C28.7893 27.0391 29 26.5304 29 26V3H28Z' fill={color} />
    </svg>;
};

IconGenericMachine.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconGenericMachine;
