import * as constants from './constants';
import { apiCallStatus } from '../../../../helpers/constants';
import { capabilityListToDictionary } from 'helpers/formatters';
import { filterEventLogs, updateEventLogs } from './helpers';


const initialState = {
    dateKind: constants.dateKind.Local,
    selectedAssets: [],
    selectedEvent: null,
    filters: {
        groupByNames: true,
        eventStatus: constants.eventStatus.ALL,
        withCommentsOnly: false,
        withDataLoggersOnly: false,
        occuredDuringOperationOnly: false,
        eventName: '',
        causeOfEvent: constants.causeOfEvents.All,
        eventTypes: [
            constants.EventTypeIDs.ALARM,
            constants.EventTypeIDs.FAULT,
            constants.EventTypeIDs.WARNING
        ]
    },
    eventLogs: null,
    filteredEventLogs: null,
    eventClosingLoadingStatus: apiCallStatus.SUCCESS,
    eventLogLoadingStatus: apiCallStatus.LOADING,
    isTruncated: false,
    capabilitiesLoadingStatus: null,
    capabilities: null,
    isExpanded: false
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.SET_SELECTED_ASSET:
        case constants.CLEAR_STORE:
            return initialState;
        case constants.SET_EVENT_LOG_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...action.filters },
                filteredEventLogs: filterEventLogs(state.eventLogs, { ...state.filters, ...action.filters })
            };
        case constants.SET_EVENT_LOG_DATE_KIND:
            return {
                ...state,
                dateKind: action.dateKind
            };
        case constants.SET_IS_EXPAND:
            return {
                ...state,
                isExpanded: action.isExpanded
            };
        case constants.SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: action.selectedEvent
            };
        case constants.GET_EVENT_LOGS:
            return {
                ...state,
                eventLogs: action.data,
                isTruncated: action.data?.isTruncated,
                filteredEventLogs: filterEventLogs(action.data, state.filters),
                eventLogLoadingStatus: action.loadingStatus
            };
        case constants.UPDATE_EVENT_LOGS:
            return {
                ...state,
                filteredEventLogs: updateEventLogs([...state.filteredEventLogs], action.data)
            };
        case constants.CLOSE_EVENT_LOG:
            return {
                ...state,
                eventClosingLoadingStatus: action.loadingStatus
            };
        case constants.SET_SELECTED_ASSETS:
            return {
                ...state,
                selectedAssets: action.selectedAssets
            };
        case constants.LOAD_CAPABILITIES:
            return {
                ...state,
                capabilitiesLoadingStatus: action.loadingStatus,
                capabilities: capabilityListToDictionary(action.data)
            };
        default:
            return state;
    }
}
