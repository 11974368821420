import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { getModel } from './selectors';
import * as portalActions from './actions';
import PortalComponent from './PortalComponent';
import config from '../../config/appConfig';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { GlobalError } from './GlobalError';
import { defaultLanguage } from 'helpers/constants';
import { formatLanguageResource } from './helpers';
import { isDev } from 'helpers/environmentHelper';
import { loadResources } from '../../../Localization';


const PortalContainer = ({ model, actions }) => {

    const { i18n } = useTranslation();

    const tokenRefreshIntervalRef = useRef();

    const tryTokenRefresh = useCallback(() => {
        actions.tryTokenRefresh().catch(() => {
            clearInterval(tokenRefreshIntervalRef.current);
        });

    }, []);

    useEffect(() => {
        actions.loadEnvironmentProperties();
        tryTokenRefresh();
        // Also check periodically
        tokenRefreshIntervalRef.current = setInterval(tryTokenRefresh, config.tokenRefreshPeriodInSeconds);
        return () => {
            clearInterval(tokenRefreshIntervalRef.current);
        };
    }, []);

    const addResources = (res) => {
        i18n.addResourceBundle(
            model.userData?.languageCode,
            'translation',
            res,
            false,
            false);
        i18n.changeLanguage(model.userData?.languageCode);
    };

    useEffect(() => {
        if (model.userData?.languageCode) {
            loadResources(model.userData?.languageCode).then((applangaResources) => {

                const selectedLanguageResources = applangaResources;
                if (isDev() && model.userData?.languageCode === defaultLanguage) {
                    loadResources().then((localResources) => {
                        addResources(formatLanguageResource(selectedLanguageResources, localResources));
                    });
                } else {
                    addResources(formatLanguageResource(selectedLanguageResources));
                }
            });
        }
    }, [model.userData?.languageCode]);

    return <GlobalError>
        <LoadingStatusWrapper loadingStatus={model.loadingStatus}>
            <PortalComponent {...model} />
        </LoadingStatusWrapper>
    </GlobalError>;
};

PortalContainer.propTypes = {
    actions: PropTypes.shape({
        loadEnvironmentProperties: PropTypes.func
    }),
    model: PropTypes.shape({
        loadingStatus: PropTypes.any
    })
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(portalActions, dispatch)
    };
};

const PortalContainerConn = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortalContainer));


export default PortalContainerConn;
