import { createSelector } from 'reselect';

import { getDefaultUserLimits, getCustomUserLimits } from './helpers';

const getAssetTrendKpis = ({ userLimits }) => {
    return userLimits.trendKpis || [];
};

const getTrendKpisLoadingStatus = ({ userLimits }) => {
    return userLimits.trendKpis.loadingStatus;
};

const getUserLimits = ({ detailedInformationHeader }) => {
    return detailedInformationHeader.userLimits.data;
};

const getUserLimitsEtag = ({ detailedInformationHeader }) => {
    return detailedInformationHeader.userLimits.data?.eTag;
};

const getLoadUserLimitsLoadingStatus = ({ detailedInformationHeader }) => {
    return detailedInformationHeader.userLimits.loadingStatus;
};

const getSaveUserLimitsLoadingStatus = ({ userLimits }) => {
    return userLimits.saveUserLimits.loadingStatus;
};

const getUserLimitsSelector = createSelector([getUserLimits, getAssetTrendKpis], (userLimits, trendKpis) => {
    const defaultUserLimits = getDefaultUserLimits(userLimits?.visualAids);
    const customUserLimits = getCustomUserLimits(userLimits?.visualAids, trendKpis?.additionalKpis);

    return {
        defaultUserLimits,
        customUserLimits
    };
}
);

export const getModel = createSelector([
    getAssetTrendKpis,
    getTrendKpisLoadingStatus,
    getUserLimitsSelector,
    getLoadUserLimitsLoadingStatus,
    getSaveUserLimitsLoadingStatus,
    getUserLimitsEtag
],
    (
        trendKpis,
        trendKpisLoadingStatus,
        userLimitsData,
        loadUserLimitsLoadingStatus,
        saveUserLimitsLoadingStatus,
        eTag
    ) => {
        return {
            trendKpis,
            trendKpisLoadingStatus,
            userLimitsData,
            loadUserLimitsLoadingStatus,
            saveUserLimitsLoadingStatus,
            eTag
        };
    });
