import React from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GeneralSelect } from 'sharedComponents';
import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';
import { conditionStatusKeys, StateID } from 'helpers/constants';
import { getConditionFilterCategories } from 'helpers/conditionFilterCategoriesHelper';


const ConditionBasedFilter = ({
    setConditionIndexFilterAction,
    toggleConditionStatusFilterAction
}) => {
    const { t: translate } = useTranslation();
    const {
        conditionIndexFilter,
        conditionStatusFilter
    } = useSelector(({ assetAndPowetrainFilter }) => assetAndPowetrainFilter);

    const conditionIndexOptions = getConditionFilterCategories(translate);
    const getValue = () => {
        return conditionIndexOptions.find(option => option.id === conditionIndexFilter);
    };

    return <div className='condition-based-filter'>
        <GeneralSelect
            className='condition-index-filter'
            options={conditionIndexOptions}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
            value={getValue()}
            defaultValue={conditionIndexOptions && conditionIndexOptions.length && conditionIndexOptions[0]}
            menuShouldScrollIntoView={false}
            onChange={(value) => setConditionIndexFilterAction(value.id)}
            isSearchable={false}
        />

        <div className='condition-statuses'>
            {map(conditionStatusKeys, (conditionStatus) => {

                return <div
                    className={'condition-status' + (conditionStatusFilter[StateID[conditionStatus]] ? ' selected' : '')}
                    onClick={() => toggleConditionStatusFilterAction(StateID[conditionStatus])}
                    key={conditionStatus}
                >
                    <IconCondition condition={StateID[conditionStatus]} />
                </div>;
            })}
        </div>
    </div>;
};

export default ConditionBasedFilter;
