import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';

import VibrationFFTComponent from './VibrationFFTComponent';
import { opButtons } from '../constants';


const VibrationFFTContainer = (props) => {
    useEffect(() => {
        props.model.activeTab === opButtons.vibrationFFT && props.actions.getFFTData();
    }, [props.model.activeTab]);

    return <VibrationFFTComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const VibrationFFTContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(VibrationFFTContainer);


export default VibrationFFTContainerConn;
