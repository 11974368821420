import { put, call, takeLatest } from 'redux-saga/effects';
import { actionTypes } from './constants';
import { LimitConfigApi } from 'api';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from '../../../SharedComponents/GeneralNotification/constants';
import { translate } from 'helpers/translateHelper';

function* getLimitKPISaga(action) {
    try {
        const { assetId } = action.payload;
        const response = yield call(LimitConfigApi.getLimitKpi, assetId);
        const configList = response.map(group => {
            return {
                ...group,
                timeseries: group.timeseries.filter(series => series.isDefaultLimitEditable || series.isAdditionalLimitAllowed)
            };
        }).filter(group => group.timeseries.length > 0);
        yield put({ type: actionTypes.GET_LIMIT_KPI_SUCCESS, payload: configList });
    } catch (error) {
        yield put({ type: actionTypes.GET_LIMIT_KPI_FAILURE, error: error.message });
    }
}

function* getTimeSeries(action) {
    try {
        const { assetId } = action.payload;
        const response = yield call(LimitConfigApi.getTimeSeriesLimits, assetId);
        const temp = response.timeseries.map(item => ({
            ...item,
            uniqId: crypto.randomUUID(),
            displayName: item.appendCodeToTranslation ? translate(item.displayNameTextKey) ? `${item.timeseriesKey} ${translate(item.displayNameTextKey)}` : `${item.timeseriesKey} ${item.displayName}` : translate(item.displayNameTextKey) ? translate(item.displayNameTextKey) : item.displayName,
            limits: item.limits.map(limit => ({
                ...limit,
                uniqId: crypto.randomUUID()
            })
            )
        }));
        yield put({ type: actionTypes.GET_TIME_SERIES_SUCCESS, payload: { temp, assetId } });
    } catch (error) {
        yield put({ type: actionTypes.GET_TIME_SERIES_FAILURE, error: error.message });
    }
}

function* postLimitSaga(action) {
    try {
        const { assetId, configData } = action.payload;
        const response = yield call(LimitConfigApi.postLimitConfig, assetId, configData);
        yield put({ type: actionTypes.POST_LIMIT_SUCCESS, payload: response });
        yield put({ type: actionTypes.GET_TIME_SERIES, payload: { assetId } });

        notify(translate('ABB.Powertrain.Frontend.limitAdded'), NOTIFICATION_TYPES.SUCCESS);
    } catch (error) {
        const { data } = error.response || {};
        const errorMessage = (data?.timeseries || []).reduce((acc, timeseriesItem) => {
            if (timeseriesItem.validationMessage) {
                acc.push(timeseriesItem.validationMessage);
            }

            timeseriesItem.limits.forEach(limit => {
                if (limit.validationMessage) {
                    acc.push(limit.validationMessage);
                }
            });

            return acc;
        }, []);

        const combinedErrorMessage = errorMessage.join(', \n') || error.message;

        notify(combinedErrorMessage, NOTIFICATION_TYPES.ERROR);
        yield put({ type: actionTypes.POST_LIMIT_FAILURE });
    }
}

export function* limitConfigWatchers() {
    yield takeLatest(actionTypes.GET_LIMIT_KPI, getLimitKPISaga);
    yield takeLatest(actionTypes.GET_TIME_SERIES, getTimeSeries);
    yield takeLatest(actionTypes.POST_LIMIT_REQUEST, postLimitSaga);
}
