import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import CapabilityHelper from 'helpers/capabilityHelper';
import { ASSET_TYPES } from 'helpers/constants';
import { filterAssets } from 'helpers/assetAndPowertrainFilterHelper';
import { getGeneralFilteredAssets } from 'helpers/selectors';

const getEmailAlertSettingsStatus = ({ emailAlert }) => {
    return emailAlert.getEmailAlertSettingsStatus;
};

const setEmailAlertSettingsStatus = ({ emailAlert }) => {
    return emailAlert.setEmailAlertSettingsStatus;
};

const getCapabilitiesStatus = ({ emailAlert }) => {
    return emailAlert.capabilitiesLoadingStatus;
};

const getEmailAlertSettings = ({ emailAlert }) => {
    return emailAlert.settings;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getFilters = ({ assetAndPowetrainFilter }) => {
    return assetAndPowetrainFilter;
};

const getCapabilities = ({ emailAlert }) => {
    return emailAlert.capabilities || [];
};

const getFilteredAssets = createSelector(
    [getGeneralFilteredAssets, getFilters],
    (assets, filters) => {
        return sortBy(filterAssets(assets, filters), (asset) => asset.componentName.toLowerCase());
    }
);

const getAssetsWithEmailAlertCapability = createSelector([getFilteredAssets, getCapabilities], (assets, capabilities) => {
    let assetList = assets;
    assetList = assetList.filter(d => d.componentType && d.componentType.typeID === ASSET_TYPES.DRIVE);

    return assetList.map(d => {
        const hasCapability = capabilities[d.componentID] &&
            capabilities[d.componentID].subscription &&
            new CapabilityHelper({ data: capabilities[d.componentID] }, d).hasEmailAlertCapability();
        return {
            ...d,
            hasCapability
        };
    });
});

export const getModel = createSelector([
    getFilteredAssets,
    getSelectedAsset,
    getEmailAlertSettingsStatus,
    getEmailAlertSettings,
    getAssetsWithEmailAlertCapability,
    getCapabilitiesStatus,
    setEmailAlertSettingsStatus
],
    (
        filteredAssets,
        selectedAsset,
        getEmailAlertSettingsStatus,
        settings,
        assets,
        getCapabilitiesStatus,
        setEmailAlertSettingsStatus
    ) => {
        return {
            filteredAssets,
            selectedAsset,
            getEmailAlertSettingsStatus,
            settings,
            assets,
            getCapabilitiesStatus,
            setEmailAlertSettingsStatus
        };
    });
