import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SensorFeaturesList from './SensorFeaturesList';
import {
    getVisibleFeatures,
} from '../helpers';

const SensorFeaturesComponent = (props) => {
    const { t: translate } = useTranslation();
    const sensorFeatures = props.sensorFeatures;
    const visibleFeatures = getVisibleFeatures(sensorFeatures);

    return <>
        {!!visibleFeatures.length &&
            <div className='sensor-properties-list' >
                <div className='sensor-properties-list-title'>{translate('ABB.Powertrain.Frontend.sensorFeatures')}</div>
                <SensorFeaturesList
                    visibleFeatures={visibleFeatures} />
            </div>}
    </>;
};

export default SensorFeaturesComponent;

SensorFeaturesComponent.propTypes = {
    model: PropTypes.shape({
        firmwareID: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        gatewayInfo: PropTypes.object,
        sensorProperties: PropTypes.object,
        sensorFeatures: PropTypes.array,
    })
};
