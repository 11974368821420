import forEach from 'lodash/forEach';
import round from 'lodash/round';

import {
	baseToCustomUOM,
	customToBaseUOM,
	getMeasurementUnits
} from 'helpers/unitHelper';


export const handleGetUserLimitsConversion = (userLimitResp) => {
	const userMeasurementUnitGroups = getMeasurementUnits();
	const PRECISION = 3;
	forEach(userLimitResp.visualAids, (ul) => {
		const convertObj = baseToCustomUOM.convertAndFormatWithGroups(ul.unitGroupID, ul.value, userMeasurementUnitGroups);
		ul.value = round(convertObj.measurementValue, PRECISION);
		ul.unit = convertObj.measurementUnit || ul.unit;
	});
	return userLimitResp;
};

export const handleSaveUserLimits = (userLimits) => {
	const userMeasurementUnitGroups = getMeasurementUnits();
	forEach(userLimits, (ul) => {
		ul.userLimitValue = customToBaseUOM.convertWithGroups(ul.unitGroupID, ul.userLimitValue, userMeasurementUnitGroups);
	});
	return userLimits;
};
