import React from 'react';
import {
    IconFault,
    IconAlarm,
    IconWarningType,
    IconMaintenanceEventIndicator,
    IconNotification,
    IconInfoType,
    IconDiagnosticEventIndicator
} from 'svgIcons/MotionPortalIcons';

export const EventTypeAssetApplicability = {
    ALL: 0,
    DRIVE: 3,
    SMART_SENSOR: 1
};

export const dateKind = {
    UTC: 0,
    Local: 1
};

export const eventStatus = {
    ALL: 'ALL',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
};

export const EventTypeIDs = {
    FAULT: 'FAULT',
    ALARM: 'ALARM',
    WARNING: 'WARNING',
    MAINTENANCE: 'MAINTENANCE',
    NOTIFICATION: 'NOTIFICATION',
    INFO: 'INFO',
    DIAGNOSTIC: 'DIAGNOSTIC',
};

export const eventClosingReason = {
    Dismiss: 1,
    FalseAlarm: 2,
    Corrected: 3,
    Auto: 4
};

export const causeOfEvents = {
    All: -1,
    Unclassified: 0,
    Irreleveant: 1,
    Drive: 2,
    Motor: 3,
    OtherPTC: 4,
    Process: 5,
    Ambient: 6,
    Unclear: 7
};

export const EventTypes = [
    {
        typeID: EventTypeIDs.FAULT,
        icon: <IconFault />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeFault',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.ALARM,
        icon: <IconAlarm />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeAlarm',
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
    {
        typeID: EventTypeIDs.WARNING,
        icon: <IconWarningType />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeWarning',
        applicableTo: EventTypeAssetApplicability.ALL
    },
    {
        typeID: EventTypeIDs.MAINTENANCE,
        icon: <IconMaintenanceEventIndicator width={16} height={16} />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeMaintenance',
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
    {
        typeID: EventTypeIDs.NOTIFICATION,
        icon: <IconNotification />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeNotification',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.INFO,
        icon: <IconInfoType />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeInfo',
        applicableTo: EventTypeAssetApplicability.DRIVE
    },
    {
        typeID: EventTypeIDs.DIAGNOSTIC,
        icon: <IconDiagnosticEventIndicator />,
        label: 'ABB.Powertrain.Frontend.lblEventListEventTypeDiagnostic',
        applicableTo: EventTypeAssetApplicability.SMART_SENSOR
    },
];

export const SET_EVENT_LOG_FILTERS = 'eventlog/SET_EVENT_LOG_FILTERS';
export const SET_EVENT_LOG_DATE_KIND = 'eventlog/SET_EVENT_LOG_DATE_KIND';
export const SET_IS_EXPAND = 'eventlog/SET_IS_EXPAND';
export const SET_DATE_SELECTION = 'overview/SET_DATE_SELECTION';
export const CLEAR_ASSISTANCE_PAGE = 'eventlist/assistance/CLEAR_ASSISTANCE_PAGE';
export const SET_SELECTED_EVENT = 'eventlog/SET_SELECTED_EVENT';
export const GET_EVENT_LOGS = 'detailedInformation/GET_EVENT_LOGS';
export const SET_SELECTED_ASSET = 'powertrainAssetDetails/SET_SELECTED_ASSET';
export const CLOSE_EVENT_LOG = 'eventDetailsSS/CLOSE_EVENT_LOG';
export const UPDATE_EVENT_LOGS = 'eventlog/UPDATE_EVENT_LOGS';
export const CLEAR_STORE = 'eventlog/CLEAR_STORE';
export const LOAD_CAPABILITIES = 'eventlog/LOAD_CAPABILITIES';
export const SET_SELECTED_ASSETS = 'eventlog/SET_SELECTED_ASSETS';
