import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import AssetItem from './AssetItem';
import './style.scss';


const AssetOverviewTable = ({ assets }) => {
    return <>
        <div className='asset-overview-table table-content scrollable-list'>
            {assets?.length ? map(assets, (asset, key) =>
                asset && <AssetItem asset={asset} key={key} />
            ) : null}
        </div>
    </>;
};

AssetOverviewTable.propTypes = {
    assets: PropTypes.array
};

export default AssetOverviewTable;
