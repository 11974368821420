import { createSelector } from 'reselect';
import { dataSources } from 'helpers/constants';
import { getActiveTab, getMultiChart } from '../selectors';


const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const hasRawData = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFT?.data && Object.hasOwn(operationalParametersV2.FFT.data, 'dataValues')
        && operationalParametersV2.FFT.data.dataValues.length !== 0;
};

const getIsCMDAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset?.componentType?.dataSourceType === dataSources.CMD4;
};

export const getModel = createSelector([
    getDateSelection,
    hasRawData,
    getActiveTab,
    getMultiChart,
    getIsCMDAsset
],
    (
        dateSelection,
        hasRawData,
        activeTab,
        multiChart,
        isCMDAsset
    ) => {
        return {
            dateSelection,
            hasRawData,
            activeTab,
            multiChart,
            isCMDAsset
        };
    });


