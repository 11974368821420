import { comparisonTypes } from 'helpers/constants';
import { assetObjectToComponentRequest } from '../helpers';

const assembleDefaultUserLimits = (defaultUserLimits) => {
    const result = [];

    if (Array.isArray(defaultUserLimits)) {
        defaultUserLimits.forEach((userLimit) => {
            result.push({
                SignalCode: userLimit.kpiIdentifier,
                IsEnabledForEmailAlert: userLimit.enableEmailAlert
            });
        });
    }
    return result;
};

const getLimitTypeByComparisonTypeID = comparisonTypeID => {
    if (comparisonTypeID === comparisonTypes.greaterThan.label) {
        return comparisonTypes.greaterThan.value;
    }
    if (comparisonTypeID === comparisonTypes.lessThan.label) {
        return comparisonTypes.lessThan.value;
    }
    return comparisonTypeID;
};

const assembleCustomUserLimits = (customUserLimits) => {
    const result = [];

    if (Array.isArray(customUserLimits)) {
        customUserLimits.forEach((userLimit) => {
            result.push({
                SignalCode: userLimit.kpiIdentifier,
                LimitName: userLimit.userLimitName,
                Value: userLimit.userLimitValue,
                LimitType: getLimitTypeByComparisonTypeID(userLimit.comparisonTypeID),
                IsEnabledForEmailAlert: userLimit.enableEmailAlert,
            });
        });
    }
    return result;
};

export const userLimitsToRequest = (component, defaultUserLimits, customUserLimits, eTag) => {
    return {
        Component: assetObjectToComponentRequest(component),
        CustomUserLimits: assembleCustomUserLimits(customUserLimits),
        DefaultLimitAlerts: assembleDefaultUserLimits(defaultUserLimits),
        eTag: eTag
    };
};
