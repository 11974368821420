import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GeneralDialog } from 'sharedComponents';
import { wizardSteps, wizardStepStates } from './constants';
import { assemblePowertrain } from './helpers';
import PowertrainWizardStep1 from './PowertrainWizardStep1';
import PowertrainWizardStep2 from './PowertrainWizardStep2';
import PowertrainWizardStep3 from './PowertrainWizardStep3';


const PowertrainWizardComponent = (
    {
        show,
        close,
        model,
        actions
    }) => {
    const { t: translate } = useTranslation();
    const {
        sites,
        powertrainID,
        powertrainName,
        selectedOrganization,
        selectedSite,
        selectedAssetTypeAvailableSection,
        selectedAssetTypeAddedSection,
        assetsLoadingStatus,
        availableAssets,
        assetFilterAvailableSection,
        addedAssets,
        addedAssetsChangedFlag,
        powertrainDetailsChangedFlag,
        addedAssetsFiltered,
        assetFilterAddedSection,
        powertrainWizardStepStates,
        activeStep,
        isEditMode,
        sitesLoadingStatus,
        assets,
        availableAssetsCurrentPage,
        availableAssetsHasMore
    } = model;
    const {
        clearStore,
        setWizardStepState,
        setWizardActiveStep,
        setPowertrainNameAction,
        setSelectedSiteAction,
        setSelectedAssetTypeAvailableSectionAction,
        setAssetFilterAvailableSectionAction,
        addAssetAction,
        removeAssetAction,
        setSelectedAssetTypeAddedSectionAction,
        setAssetFilterAddedSectionAction,
        addPowertrainRequest,
        editPowertrainRequest,
        getAvailableAssetsRequest,
    } = actions;

    const [
        powertrainWizardStepStatesWithActions,
        setPowertrainWizardStepStatesWithActions
    ] = useState(null);

    const closeWithCleanup = () => {
        close();
        clearStore();
    };

    const acceptButtonClickStep1 = () => {
        setWizardActiveStep(wizardSteps.STEP2);
        !addedAssets || !addedAssets.length &&
            setWizardStepState(wizardSteps.STEP2, wizardStepStates.ACTIVE);
    };

    const acceptButtonClickStep2 = () => {
        setWizardActiveStep(wizardSteps.STEP3);
    };

    const acceptButtonClickStep3 = () => {
        isEditMode ?
            editPowertrainRequest(
                assemblePowertrain(powertrainID, powertrainName, selectedSite, addedAssets),
                powertrainDetailsChangedFlag,
                addedAssetsChangedFlag
            ) :
            addPowertrainRequest(
                assemblePowertrain(powertrainID, powertrainName, selectedSite, addedAssets)
            );
        closeWithCleanup();
    };

    const cancelButtonClickStep1 = () => {
        closeWithCleanup();
    };

    const cancelButtonClickStep2 = () => {
        setWizardActiveStep(wizardSteps.STEP1);
        !addedAssets || !addedAssets.length &&
            setWizardStepState(wizardSteps.STEP2, wizardStepStates.INACTIVE);
    };

    const cancelButtonClickStep3 = () => {
        setWizardActiveStep(wizardSteps.STEP2);
    };

    useEffect(() => {
        const stepsWithActions = powertrainWizardStepStates;
        stepsWithActions[wizardSteps.STEP1].acceptAction = acceptButtonClickStep1;
        stepsWithActions[wizardSteps.STEP1].cancelAction = cancelButtonClickStep1;
        stepsWithActions[wizardSteps.STEP2].acceptAction = acceptButtonClickStep2;
        stepsWithActions[wizardSteps.STEP2].cancelAction = cancelButtonClickStep2;
        stepsWithActions[wizardSteps.STEP3].acceptAction = acceptButtonClickStep3;
        stepsWithActions[wizardSteps.STEP3].cancelAction = cancelButtonClickStep3;

        setPowertrainWizardStepStatesWithActions(stepsWithActions);
    });

    const acceptButtonClick = () => {
        powertrainWizardStepStatesWithActions[activeStep].acceptAction();
    };

    const cancelButtonClick = () => {
        powertrainWizardStepStatesWithActions[activeStep].cancelAction();
    };

    const renderPowertrainWizardActiveStep = () => {
        switch (activeStep) {
            case wizardSteps.STEP1:
                return <PowertrainWizardStep1
                    model={{
                        powertrainName,
                        selectedOrganization,
                        sites,
                        sitesLoadingStatus,
                        selectedSite,
                        isEditMode
                    }}
                    actions={{
                        setWizardStepState,
                        setPowertrainNameAction,
                        setSelectedSiteAction
                    }} />;
            case wizardSteps.STEP2:
                return <PowertrainWizardStep2
                    model={{
                        selectedAssetTypeAvailableSection,
                        selectedAssetTypeAddedSection,
                        assetsLoadingStatus,
                        availableAssets,
                        assetFilterAvailableSection,
                        addedAssets,
                        addedAssetsFiltered,
                        assetFilterAddedSection,
                        activeStep,
                        assets,
                        selectedSite,
                        availableAssetsCurrentPage,
                        availableAssetsHasMore
                    }}
                    actions={{
                        setWizardStepState,
                        setSelectedAssetTypeAvailableSectionAction,
                        setAssetFilterAvailableSectionAction,
                        addAssetAction,
                        removeAssetAction,
                        setSelectedAssetTypeAddedSectionAction,
                        setAssetFilterAddedSectionAction,
                        getAvailableAssetsRequest
                    }} />;
            case wizardSteps.STEP3:
                return <PowertrainWizardStep3
                    model={{
                        powertrainName,
                        sites,
                        selectedSite,
                        addedAssets,
                        activeStep
                    }}
                    actions={{ setWizardStepState }} />;
            default:
                return '';
        }
    };

    return <GeneralDialog
        show={show}
        close={closeWithCleanup}
        title={
            <span className='powertrain-wizard-title'>
                <span className={powertrainWizardStepStates[wizardSteps.STEP1].state}>
                    {translate('ABB.Powertrain.Frontend.powertrainWizardModalTitleStep1')}
                </span>&nbsp;|&nbsp;
                <span className={powertrainWizardStepStates[wizardSteps.STEP2].state}>
                    {translate('ABB.Powertrain.Frontend.powertrainWizardModalTitleStep2')}
                </span>&nbsp;|&nbsp;
                <span className={powertrainWizardStepStates[wizardSteps.STEP3].state}>
                    {translate('ABB.Powertrain.Frontend.powertrainWizardModalTitleStep3')}
                </span>
            </span>
        }
        modalClassName='powertrain-wizard-modal'
        acceptButtonProps={{
            text: activeStep === wizardSteps.STEP3 ?
                translate('ABB.Powertrain.Frontend.saveButton') :
                translate('ABB.Powertrain.Frontend.nextButton'),
            onClick: acceptButtonClick,
            disabled: powertrainWizardStepStates[activeStep].state === wizardStepStates.ACTIVE,
        }}
        cancelButtonProps={{
            text: activeStep === wizardSteps.STEP1 ?
                translate('ABB.Powertrain.Frontend.cancelLabel') :
                translate('ABB.Powertrain.Frontend.backButton'),
            onClick: cancelButtonClick,
            keepOpenOnClick: activeStep !== wizardSteps.STEP1
        }}
        closeButton={true}
    >
        {renderPowertrainWizardActiveStep()}
    </GeneralDialog>;
};

PowertrainWizardComponent.propTypes = {
    model: PropTypes.shape({
        organizations: PropTypes.array,
        sites: PropTypes.array,
        sitesLoadingStatus: PropTypes.string,
        powertrainID: PropTypes.number,
        powertrainName: PropTypes.string,
        selectedOrganization: PropTypes.shape({
            organizationID: PropTypes.number,
            name: PropTypes.string
        }),
        selectedSite: PropTypes.shape({
            siteId: PropTypes.number,
            siteName: PropTypes.string,
        }),
        selectedAssetTypeAvailableSection: PropTypes.shape({
            id: PropTypes.number,
            assetType: PropTypes.string,
            typeID: PropTypes.number,
            typeName: PropTypes.string,
        }),
        selectedAssetTypeAddedSection: PropTypes.shape({
            id: PropTypes.number,
            assetType: PropTypes.string,
            typeID: PropTypes.number,
            typeName: PropTypes.string,
        }),
        assetsLoadingStatus: PropTypes.string,
        availableAssets: PropTypes.array,
        assetFilterAvailableSection: PropTypes.string,
        addedAssets: PropTypes.array,
        addedAssetsChangedFlag: PropTypes.bool,
        powertrainDetailsChangedFlag: PropTypes.bool,
        addedAssetsFiltered: PropTypes.array,
        assetFilterAddedSection: PropTypes.string,
        powertrainWizardStepStates: PropTypes.shape({
            STEP1: PropTypes.shape({
                state: PropTypes.string,
                acceptAction: PropTypes.func,
                cancelAction: PropTypes.func,
            }),
            STEP2: PropTypes.shape({
                state: PropTypes.string,
                acceptAction: PropTypes.func,
                cancelAction: PropTypes.func,
            }),
            STEP3: PropTypes.shape({
                state: PropTypes.string,
                acceptAction: PropTypes.func,
                cancelAction: PropTypes.func,
            }),
        }),
        activeStep: PropTypes.string,
        isEditMode: PropTypes.bool,
    }),
    actions: PropTypes.shape({
        clearStore: PropTypes.func,
        setWizardStepState: PropTypes.func,
        setWizardActiveStep: PropTypes.func,
        getAssets: PropTypes.func,
        setPowertrainNameAction: PropTypes.func,
        setSelectedSiteAction: PropTypes.func,
        setSelectedAssetTypeAvailableSectionAction: PropTypes.func,
        setAssetFilterAvailableSectionAction: PropTypes.func,
        addAssetAction: PropTypes.func,
        removeAssetAction: PropTypes.func,
        setSelectedAssetTypeAddedSectionAction: PropTypes.func,
        setAssetFilterAddedSectionAction: PropTypes.func,
        addPowertrain: PropTypes.func,
        getSites: PropTypes.func,
    })
};

export default PowertrainWizardComponent;
