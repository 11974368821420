import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { stateColors } from './constants';
import { IconCondition } from 'svgIcons/MotionPortalIcons';

const calculateTotal = (data) => {
    return {
        y: data?.reduce((a, b) => +a + +b.y, 0)
    };
};

const getChartColors = (data) => {
    const chartColors = [];
    data?.forEach(state => chartColors.push(stateColors[state.status]));

    return chartColors;
};

const createChartTitleText = (titleValue, titleText) => {
    const value = titleValue.y;
    const title = titleText ? `<span>${titleText}</span>` : '';
    const className = titleText ? 'class=has-text' : '';
    return `<span ${className}>${value}${title}</span>`;
};

const getLegend = (legend, translate) => {
    const value = legend.y;
    if (value) {
        const legendItem = document.createElement('div');
        legendItem.classList.add('condition-container-row');
        const icon = new Image();
        icon.classList.add('condition-icon');
        const svgString = encodeURIComponent(renderToStaticMarkup(<IconCondition condition={legend.status} />));
        icon.src = `data:image/svg+xml,${svgString}`;

        const legendValuelabel = document.createElement('b');
        legendValuelabel.className = 'legend-value';
        legendValuelabel.innerText = value;

        const legendNamelabel = document.createElement('span');
        legendNamelabel.innerText = translate(`ABB.Powertrain.Frontend.${legend.name}Label`) ? translate(`ABB.Powertrain.Frontend.${legend.name}Label`) : legend.desc;

        legendItem.appendChild(icon);
        legendItem.appendChild(legendValuelabel);
        legendItem.appendChild(legendNamelabel);

        return legendItem.outerHTML;

    }
    return null;
};

const useChartConfig = (
    chartData,
    id,
    titleText,
    chartClickHandler,
    legendClickHandler,
    translate) => {

    const [vmin, setVmin] = React.useState(Math.min(window.innerWidth, window.innerHeight) / 100);

    const handleResize = () => {
        setVmin(Math.min(window.innerWidth, window.innerHeight) / 100);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            credits: {
                enabled: false
            },
            chart: {
                type: 'pie',
                renderTo: id,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: 'transparent',
                className: 'overview-chart',
                events: {
                    load: function () {
                        const chart = this,
                            x = chart.plotLeft + chart.series[0].center[0],
                            y = chart.plotTop + chart.series[0].center[1] - 20;

                        chart.pieCenter = chart.renderer.text(
                            createChartTitleText(calculateTotal(chartData), titleText), x, y, true
                        ).attr({
                            class: 'pie-chart-middle-title'
                        }).add();

                        const box = chart.pieCenter.getBBox();
                        chart.pieCenter.attr({
                            x: x - box.width / 2,
                            y: y - box.height / 4
                        });
                    },
                    redraw: function () {
                        const chart = this,
                            x = chart.plotLeft + chart.series[0].center[0],
                            y = chart.plotTop + chart.series[0].center[1] - 20;
                        chart.pieCenter.textSetter(createChartTitleText(calculateTotal(chartData), titleText));
                        const box = chart.pieCenter.getBBox();
                        chart.pieCenter.attr({
                            x: x - box.width / 2,
                            y: y - box.height / 4
                        });
                    }
                }
            },
            title: {
                text: ''
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                enabled: true,
                margin: 0,
                symbolWidth: 16,
                symbolHeight: 16,
                symbolRadius: 0,
                navigation: {
                    enabled: false,
                },
                useHTML: true,
                labelFormatter: function () { // legend html
                    return getLegend(this, translate);
                }
            },
            tooltip: {
                enabled: false,
            },
            series: [{
                type: 'pie',
                size: `${vmin * 32}px`,
                innerSize: '70%',
                cursor: typeof chartClickHandler === 'function' ? 'pointer' : undefined,
                id: 'overviewPieChart',
                point: { // inner text of the donut
                    events: {
                        // Be aware of mouseOver point event bug: https://github.com/highcharts/highcharts/issues/12067
                        mouseOver: (e) => {
                            const chart = e.target && e.target.series && e.target.series.chart ? e.target.series.chart : null;
                            chart && chart.pieCenter.textSetter(createChartTitleText(e.target, titleText));
                        },
                        mouseOut: (e) => {
                            const chart = e.target && e.target.series && e.target.series.chart ? e.target.series.chart : null;
                            chart && chart.pieCenter.textSetter(
                                createChartTitleText(calculateTotal(chart.series[0].data), titleText)
                            );
                        },
                        legendItemClick: (e) => {
                            e.preventDefault();
                            if (typeof legendClickHandler === 'function' && e.target) {
                                legendClickHandler(e.target);
                            }
                        },
                        click: (e) => {
                            if (typeof chartClickHandler === 'function' && e.point) {
                                chartClickHandler(e.point);
                            }
                        }
                    }
                },
                data: chartData
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    startAngle: -90,
                    endAngle: -90,
                    borderWidth: 0,
                    states: {
                        hover: {
                            brightness: 0,
                            halo: {
                                opacity: 1,
                                size: 10,
                            }
                        }
                    },
                    colors: getChartColors(chartData),
                },
                series: {
                    animation: false
                }
            },
            lang: {
                noData: ''
            },
            exporting: {
                enabled: false
            }
        });

    }, [chartData, vmin]);

    return options;
};

export default useChartConfig;
