import { createSelector } from 'reselect';

import { apiCallStatus } from 'helpers/constants';
import { formatUploadList } from './helpers';

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getUploadStatus = ({ bulkUpload }) => {
	return bulkUpload.uploadStatus.data ? formatUploadList(bulkUpload.uploadStatus.data.uploads) : [];
};

const getIsUploadStatusLoading = ({ bulkUpload }) => {
	return bulkUpload.uploadStatus.loadingStatus === apiCallStatus.LOADING;
};

export const getModel = createSelector([
	getSelectedAsset,
	getUploadStatus,
	getIsUploadStatusLoading
], (
	selectedAsset,
	uploadStatusList,
	isUploadStatusLoading
) => {
	return {
		selectedAsset,
		uploadStatusList,
		isUploadStatusLoading
	};
});
