import moment from 'moment';
import isDate from 'lodash/isDate';
import { translate } from './translateHelper';

import { customformat } from './dateFormats';


export const compareDatesToDayPrecision = (d1, d2) => {
    return d1 && d2 &&
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
};

const formateTimezoneOffset = (offsetInMinutes, sign) => {
    const absOffsetValue = Math.abs(offsetInMinutes);
    return `${sign}${Math.trunc(absOffsetValue / 60).toString().padStart(2, '0')}:${Math.trunc(absOffsetValue % 60).toString().padStart(2, '0')}`;
};

const getFormatedTimezoneOffset = (offsetInMinutes) => {
    const sign = offsetInMinutes <= 0 ? '+' : '-';
    return formateTimezoneOffset(offsetInMinutes, sign);
};

const getDateObject = (date) => {

    if (typeof date === 'number') {
        return new Date(date);
    }
    else if (typeof date === 'string') {
        const isoDate = new Date(date); // parsing dates in JS is unreliable, it will parse also the string 'AAA-0002' sucessfully
        if (isDate(isoDate)) {
            return isoDate;
        }
    }
    else {
        return date;
    }
};

export const toLocaleDateTimeString = (date, {
    asUTC = false,
    showMs = false,
    showOffset = false,
    showTimePortion = true,
    showMonthAndYearOnly = false
} = {}) => {

    if (!date || date === '') {
        return '-';
    }

    let dateObj = getDateObject(date);

    if (isDate(dateObj)) {
        let offsetInMinutes = dateObj.getTimezoneOffset();
        if (asUTC) {
            dateObj = new Date(dateObj.getTime() + offsetInMinutes * 60 * 1000);
            offsetInMinutes = 0;
        }

        let offsetString = '';
        if (showOffset) {
            offsetString = `(${getFormatedTimezoneOffset(offsetInMinutes)})`;
        }

        const h = `${dateObj.getHours()}`.padStart(2, '0');
        const m = `${dateObj.getMinutes()}`.padStart(2, '0');
        const s = `${dateObj.getSeconds()}`.padStart(2, '0');
        const ms = showMs ? '.' + `${dateObj.getMilliseconds()}`.padStart(3, '0') : '';

        let formattedDate = `${dateObj.toLocaleDateString()}`;

        if (showMonthAndYearOnly) {
            formattedDate = `${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        }

        if (showTimePortion) {
            formattedDate = `${dateObj.toLocaleDateString()} ${h}:${m}:${s}${ms}${offsetString}`;//  hardcoded time format
        }

        return formattedDate;
    }
};

export const convertUtcToLocalDateTime = (dateTime, withoutHours = false) => {
    const formatPattern = `${customformat(navigator.language)} ${withoutHours ? '' : 'HH:mm:ss'}`;
    const localDate = moment.utc(dateTime).local();
    return localDate.format(formatPattern);
};

export const getPriorDateMinusDays = (minusDays) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - minusDays);
    return currentDate;
};

export const getUTCDateString = (date, includeMilliseconds = false) => {
    const utcFormattedDateString = date.toISOString();
    return includeMilliseconds ? utcFormattedDateString : utcFormattedDateString.split('.')[0] + 'Z';
};

export function getDateRangeString(from, to) {
    let retValue = '';
    if (from && to) {
        retValue = new Date(from).toLocaleDateString() + ' - ' + new Date(to).toLocaleDateString();
    }
    return retValue;
}

export const formatStartDate = (fromDate) => {
    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setSeconds(0);
    fromDate.setMilliseconds(0);
    return fromDate;
};


export const formatEndDate = (toDate) => {
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(59);
    toDate.setMilliseconds(999);
    return toDate;
};

//TODO : Remove if not required in future, commenting based on the PBI 368808
// const getLocalDateTime = (dateTime) => {
//     if (!dateTime) {
//         return null;
//     }
//     return moment.utc(dateTime).local();
// };
// export const dateTimeStringFormatter = (dateString) => {
//     const dateResult = new Date(getLocalDateTime(dateString));
//     return dateResult.toLocaleString();
// };


const buildValueObject = (yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff, firstDateWasLater) => {
    return {
        'years': yDiff,
        'months': mDiff,
        'days': dDiff,
        'hours': hourDiff,
        'minutes': minDiff,
        'seconds': secDiff,
        'firstDateWasLater': firstDateWasLater
    };
};

const preciseDiff = (d1, d2, returnValueObject) => {
    let m1 = moment(d1), m2 = moment(d2), firstDateWasLater;

    m1.add(m2.utcOffset() - m1.utcOffset(), 'minutes'); // shift timezone of m1 to m2

    if (m1.isSame(m2)) {
        if (returnValueObject) {
            return buildValueObject(0, 0, 0, 0, 0, 0, false);
        } else {
            return 0;
        }
    }
    if (m1.isAfter(m2)) {
        const tmp = m1;
        m1 = m2;
        m2 = tmp;
        firstDateWasLater = true;
    } else {
        firstDateWasLater = false;
    }

    let yDiff = m2.year() - m1.year();
    let mDiff = m2.month() - m1.month();
    let dDiff = m2.date() - m1.date();
    let hourDiff = m2.hour() - m1.hour();
    let minDiff = m2.minute() - m1.minute();
    let secDiff = m2.second() - m1.second();

    if (secDiff < 0) {
        secDiff = 60 + secDiff;
        minDiff--;
    }
    if (minDiff < 0) {
        minDiff = 60 + minDiff;
        hourDiff--;
    }
    if (hourDiff < 0) {
        hourDiff = 24 + hourDiff;
        dDiff--;
    }
    if (dDiff < 0) {
        const daysInLastFullMonth = moment(m2.year() + '-' + (m2.month() + 1), 'YYYY-MM').subtract(1, 'M').daysInMonth();
        if (daysInLastFullMonth < m1.date()) { // 31/01 -> 2/03
            dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
        } else {
            dDiff = daysInLastFullMonth + dDiff;
        }
        mDiff--;
    }
    if (mDiff < 0) {
        mDiff = 12 + mDiff;
        yDiff--;
    }

    if (returnValueObject) {
        return buildValueObject(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff, firstDateWasLater);
    } else {
        return `${yDiff} ${mDiff} ${dDiff} ${hourDiff} ${minDiff} ${secDiff}`;
    }
};

const getDateTimeDifference = (lastSyncDate) => {
    let lastSync = moment.utc(lastSyncDate);
    lastSync = lastSync.local();
    const diff = preciseDiff(lastSync, moment(), true);
    return diff;
};

export const getTimeSinceDateString = (date) => {
    if (date) {
        const lastSyncDate = getDateTimeDifference(date);
        const {
            seconds = 0,
            minutes = 0,
            hours = 0,
            days = 0,
            months = 0,
            years = 0
        } = lastSyncDate;

        if (years) {
            const m = months ? ` ${months} ${translate('ABB.Powertrain.Frontend.labelMonths')}` : '';
            return `${years} ${translate('ABB.Powertrain.Frontend.labelYears')}${m}`;
        }
        if (months) {
            const d = days ? ` ${days} ${translate('ABB.Powertrain.Frontend.labelDays')}` : '';
            return `${months} ${translate('ABB.Powertrain.Frontend.labelMonths')}${d}`;
        }
        if (days) {
            const h = hours ? ` ${hours} ${translate('ABB.Powertrain.Frontend.labelHours')}` : '';
            return `${days} ${translate('ABB.Powertrain.Frontend.labelDays')}${h}`;
        }
        if (hours) {
            const m = minutes ? ` ${minutes} ${translate('ABB.Powertrain.Frontend.labelMinutes')}` : '';
            return `${hours} ${translate('ABB.Powertrain.Frontend.labelHours')}${m}`;
        }
        if (minutes && minutes < 60) {
            return `${minutes} ${translate('ABB.Powertrain.Frontend.labelMinutes')}`;
        }
        if (seconds >= 10 && seconds <= 60) {
            return translate('ABB.Powertrain.Frontend.underOneMinute');
        }
        if (seconds && seconds < 10) {
            return translate('ABB.Powertrain.Frontend.justNow');
        }
    }

    return '-';
};

export const getNrOfDaysBetween = (from, to) => {
    let difference = 0;

    if (from && to) {
        difference = (to.getTime() - from.getTime()) / (1000 * 3600 * 24);
        const daysDifference = Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24));
        difference = daysDifference === 0 && difference > 0 ? 1 : daysDifference;
    }

    return difference;
};
