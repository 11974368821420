import { createSelector } from 'reselect';

const getBulkData = ({ bulkUpload }) => {
	return bulkUpload.bulkFiles;
};

const getUploadingStatus = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.isUploading;
};

const getProcessingStatus = ({ bulkUpload }) => {
	return bulkUpload && bulkUpload.isProcessing;
};

const getUploadAllowedStatus = ({ bulkUpload }) => {
	return bulkUpload &&
		bulkUpload.uploadStatus &&
		bulkUpload.uploadStatus.data &&
		bulkUpload.uploadStatus.data.uploadAllowed;
};

export const getModel = createSelector([
	getBulkData,
	getUploadAllowedStatus,
	getUploadingStatus,
	getProcessingStatus,
], (
	bulkFiles,
	isUploadAllowed,
	isUploading,
	isProcessing,
) => {
	return {
		bulkFiles,
		isUploadAllowed,
		isUploading,
		isProcessing,
	};
});
