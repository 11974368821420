import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as condtionInisightsActions from './actions';
import ConditionInsightsComponent from './ConditionInsightsComponent';
import { getDateRange } from './helpers';

const ConditionInsightsContainer = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { model, actions } = props;
    useEffect(() => {
        if (model.assetDetails?.details) {
            const dateRange = getDateRange();
            actions?.getAssetConditionIndicesRequest(model.assetDetails?.id, dateRange?.from, dateRange?.to);
        }
    }, [model.assetDetails?.details]);

    return <ConditionInsightsComponent {...props} />;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(condtionInisightsActions, dispatch)
    };
};

const ConditionInsightsContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionInsightsContainer);


export default ConditionInsightsContainerConn;
