import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { DetailedInformation } from './DetailedInformation';
import { CrossAssetAnalysis } from './CrossAssetAnalysis';
import { GeneralRouterTabs } from 'sharedComponents';
import { getTabOptions } from './helpers';
import { AssetPowertrainSelector } from './AssetPowertrainSelector';

const PowertrainAssetDetailsComponent = () => {
    const { t: translate } = useTranslation();
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));

    useEffect(() => {
        setTabOptions(getTabOptions(translate));
    }, [translate]);

    return (
        <div className='general-page-content'>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
            >
                <Switch>
                    <Route path={tabOptions.detailedInformation.route}>
                        <DetailedInformation />
                        <AssetPowertrainSelector isAssetDetailsNeeded autoSelectFirstAsset key='detailed-information' />
                    </Route>
                    <Route path={tabOptions.crossKPI.route}>
                        <CrossAssetAnalysis />
                        <AssetPowertrainSelector key='cross-asset' />
                    </Route>
                    <Route path={tabOptions.conditionBasedMaintenance.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={tabOptions.detailedInformation.route} />
                </Switch>
            </GeneralRouterTabs>
        </div>
    );
};

export default PowertrainAssetDetailsComponent;
