export const actionTypes = {
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    SET_SELECTED_POWERTRAIN: 'powertrainAssetDetails/SET_SELECTED_POWERTRAIN',
    SET_SEARCHBOX_FILTER: 'crossAssetAnalysisSidepanel/SET_SEARCHBOX_FILTER',
    SET_GATEWAY_TYPE_FILTER: 'crossAssetAnalysisSidepanel/SET_GATEWAY_TYPE_FILTER',
    SET_ACTIVE_TAB: 'powertrainAssetDetails/SET_ACTIVE_TAB',
    GET_KPIS_FOR_KPI_TYPE: 'crossAssetAnalysisSidepanel/GET_KPIS_FOR_KPI_TYPE',
    SET_SELECTED_KPI: 'crossAssetAnalysisSidepanel/SET_SELECTED_KPI',
    GET_ASSET_CAPABILITY: 'crossAssetAnalysisSidepanel/GET_ASSET_CAPABILITY',
    CLEAR_KPI_LIST: 'crossAssetAnalysisSidepanel/CLEAR_KPI_LIST',
    CLEAR_STORE: 'crossAssetAnalysisSidepanel/CLEAR_STORE',
    SET_SHOULD_SCROLL_TO_SELECTION: 'crossAssetAnalysisSidepanel/SET_SHOULD_SCROLL_TO_SELECTION',
    RESET_CHART: 'crossAssetAnalysis/RESET_CHART'
};
