import { takeLatest, call, put } from 'redux-saga/effects';
import { ConditionIndicesApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getAssetConditionIndicesAction,
    getAssetGroupConditionIndicesAction,
    getPowertrainConditionIndicesAction
} from './actions';


export function* getAssetConditionIndicesSaga({ request }) {
    yield put(getAssetConditionIndicesAction(apiCallStatus.LOADING));
    try {
        const response = yield call(
            ConditionIndicesApi.getAssetOverview,
            request
        );
        yield put(getAssetConditionIndicesAction(apiCallStatus.SUCCESS, {
            items: response.assets,
            count: response.assetCount,
            hasMore: response.hasMoreResults,
            pageNumber: request.pageNumber
        }));
        logger.debug('Asset condition overview successfully loaded for page ' + request.pageNumber);
    } catch (err) {
        yield put(getAssetConditionIndicesAction(apiCallStatus.ERROR, [], err));
    }
}

export function* getAssetGroupConditionIndicesSaga({ request }) {
    yield put(getAssetGroupConditionIndicesAction(apiCallStatus.LOADING));
    try {
        const response = yield call(
            ConditionIndicesApi.getAssetGroupOverview,
            request
        );
        yield put(getAssetGroupConditionIndicesAction(apiCallStatus.SUCCESS, {
            items: response.assetGroup,
            count: response.assetGroupCount,
            assetCount: response.assetCount,
            hasMore: response.hasMoreResults,
            pageNumber: request.pageNumber
        }));
        logger.debug('Asset group condition overview successfully loaded for page ' + request.pageNumber);
    } catch (err) {
        yield put(getAssetGroupConditionIndicesAction(apiCallStatus.ERROR, [], err));
    }
}

export function* getPowertrainConditionIndicesSaga({ request }) {
    yield put(getPowertrainConditionIndicesAction(apiCallStatus.LOADING));
    try {
        const response = yield call(
            ConditionIndicesApi.getPowertrainOverview,
            request
        );
        yield put(getPowertrainConditionIndicesAction(apiCallStatus.SUCCESS, {
            items: response.powertrains,
            count: response.powertrainCount,
            assetCount: response.assetCount,
            hasMore: response.hasMoreResults,
            pageNumber: request.pageNumber
        }));
        logger.debug('Powertrain condition overview successfully loaded for page ' + request.pageNumber);
    } catch (err) {
        yield put(getPowertrainConditionIndicesAction(apiCallStatus.ERROR, [], err));
    }
}

export function* conditionIndicesOverviewWatchers() {
    yield takeLatest(actionTypes.LOAD_ASSET_CONDITION_INDICES_REQUEST, getAssetConditionIndicesSaga);
    yield takeLatest(actionTypes.LOAD_ASSET_GROUP_CONDITION_INDICES_REQUEST, getAssetGroupConditionIndicesSaga);
    yield takeLatest(actionTypes.LOAD_POWERTRAIN_CONDITION_INDICES_REQUEST, getPowertrainConditionIndicesSaga);
}
