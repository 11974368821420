import React from 'react';
import { map, forEach, indexOf, find } from 'lodash';
import { Checkbox } from 'sharedComponents';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { IconEdit } from 'svgIcons/MotionPortalIcons';
import { translate } from 'helpers/translateHelper';


const isAssetSelected = (id, selectedAssets) => {
    return indexOf(selectedAssets, id) > -1;
};

const renderWithCheckbox = (cell, selectMutedAsset, selectedAssets) => {
    return <div className='asset-name-with-checkbox'>
        <Checkbox
            id={'countries_filter_checkbox'}
            checked={isAssetSelected(cell.assetId, selectedAssets)}
            onToggle={() => selectMutedAsset(cell.assetId)}
            width={'16px'}
            height={'16px'}
        /><div>{cell.assetName}</div>
    </div>;
};

const renderMutedGroups = (cell, groupLength) => {
    let mutedGroups = '';
    if (cell?.length < groupLength) {
        forEach(cell, (group) => {
            mutedGroups = `${mutedGroups}${mutedGroups.length ? ',' : ''} ${translate(group.notificationGroupNameTextKey) || group.notificationGroupName}`;
        });
    } else {
        mutedGroups = translate('ABB.Powertrain.Frontend.mutedAssetsAllLabel');
    }

    return mutedGroups;
};

const renderPeriod = (cell) => {
    if (cell?.from && cell?.to) {
        return `${convertUtcToLocalDateTime(cell.from, true)} - ${convertUtcToLocalDateTime(cell.to, true)}`;
    } else {
        return '';
    }
};

const renderActionButtons = (
    cell,
    setSelectedSingleAsset,
    toggleEditPopupVisibility,
    toggleDeletePopupVisibility
) => {
    return <div className='action-button-section'>
        <button onClick={() => { setSelectedSingleAsset(cell); toggleEditPopupVisibility(); }}>
            <IconEdit width={16} height={16} />
        </button>
        <button onClick={() => { setSelectedSingleAsset(cell); toggleDeletePopupVisibility(); }}>
            {translate('ABB.Powertrain.Frontend.mutedAssetsUnmuteButton')}
        </button>
    </div >;
};

// eslint-disable-next-line import/no-unused-modules
export const isAllChecked = (selectedAssets, nrOfAssets) => {
    if (Array.isArray(selectedAssets)) {
        if (selectedAssets?.length === 0) {
            return false;
        }
        if (selectedAssets?.length === nrOfAssets) {
            return true;
        }
        else { return null; }
    }
    return false;
};

export const getColumns = (
    selectMutedAsset,
    toggleEditPopupVisibility,
    toggleDeletePopupVisibility,
    setSelectedSingleAsset,
    selectedAssets,
    nrOfAssets,
    notificationGroupLength
) => [
        {
            title: <div className='muted-assets-name-column'>
                {nrOfAssets ? <Checkbox
                    id={'countries_filter_checkbox'}
                    checked={isAllChecked(selectedAssets, nrOfAssets)}
                    onToggle={() => selectMutedAsset()}
                    width={'16px'}
                    height={'16px'} /> : null}
                {translate('ABB.Powertrain.Frontend.mutedAssetsAssetColumn')}
            </div>,
            key: 'asset',
            dataIndex: 'asset',
            isSorting: false,
            width: 400,
            render: (cell) => renderWithCheckbox(cell, selectMutedAsset, selectedAssets)
        },
        {
            title: translate('ABB.Powertrain.Frontend.mutedAssetsMutedGroupsColumn'),
            key: 'mutedGroups',
            dataIndex: 'mutedGroups',
            fixed: 'right',
            isSorting: false,
            width: 400,
            render: (cell) => renderMutedGroups(cell, notificationGroupLength)
        },
        {
            title: translate('ABB.Powertrain.Frontend.mutedAssetsPeriodColumn'),
            key: 'period',
            dataIndex: 'period',
            fixed: 'right',
            isSorting: false,
            width: 400,
            render: renderPeriod
        },
        {
            title: '',
            key: 'actions',
            dataIndex: 'asset',
            fixed: 'right',
            isSorting: false,
            width: 200,
            render: (cell) => renderActionButtons(
                cell,
                setSelectedSingleAsset,
                toggleEditPopupVisibility,
                toggleDeletePopupVisibility
            )
        }
    ];

export const formatData = (mutedAssets) => {
    return mutedAssets?.assetList ?
        map(mutedAssets.assetList, (asset) => {
            return {
                id: asset.assetId,
                asset,
                mutedGroups: asset.mutedGroups,
                period: {
                    from: asset.startDate,
                    to: asset.endDate
                },
                actions: asset.assetId
            };
        }) : [];
};

export const formatMutedGroups = (notificationGroups, mutedGroups) => {
    const allMuted = notificationGroups?.length === mutedGroups?.length;

    return map(notificationGroups, (group) => {
        return {
            notificationGroupCode: group.notificationGroupCode,
            muted: allMuted ?
                true :
                !!find(mutedGroups, (mutedGroup) => mutedGroup.notificationGroupCode === group.notificationGroupCode)
        };
    });
};

export const getMutedGroupSelection = (mutedGroups, notificationChannels) => {
    if (mutedGroups?.length === 0) {
        return map(notificationChannels.notificationGroups, (group) => {
            return {
                notificationGroupCode: group.notificationGroupCode,
                notificationGroupName: group.notificationGroupName,
                notificationGroupNameTextKey: group.notificationGroupNameTextKey
            };
        });
    } else {
        return mutedGroups;
    }
};
