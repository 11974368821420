export const maxCommentSize = 2000;

export const actionTypes = {
    GET_ISSUE_TYPES: 'reportIssue/GET_ISSUE_TYPES',
    REPORT_ISSUE: 'reportIssue/REPORT_ISSUE',
    CLEAR_STORE: 'reportIssue/CLEAR_STORE',
};

export const issueTypeIds = {
    Portal: 1,
    Measurement: 2,
    Unknown: 3,
    KPI: 4,
    Outdated: 5,
    User: 6,
    Collection: 7,
    Other: 8,
};

export const notificationTimeout = 5000;
