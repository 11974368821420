import find from 'lodash/find';

import logger from 'helpers/logger';
import { measurementUnitType, MEASUREMENT_UNIT, unitSymbols } from 'helpers/constants';
import localStorageHelper from 'helpers/localStorageHelper';
import { setMaxPrecision } from './formatters';


const conversionRates = {
    '4': 1,
    '5': 0.745699872,
    '6': 24.5,
    '15': 3.78541178,
    '16': 3.280839895,
    '17': 0.0689475729,
    '20': 1.35582,
    '22': 25.4,
    '24': 25.4,
    '29': 1.35582,
    '35': 62.4279606,
    '47': 0.0015500031,
    '48': 2450
};

// Helper functions only used in this file
const internals = {

    convertCustomToBaseUOM: (measurementValue, userMeasurementUnit) => {
        let convertedValue = measurementValue;
        if (userMeasurementUnit && userMeasurementUnit.standardId !== measurementUnitType.BASE) {
            if (userMeasurementUnit.measurementUnitGroup.standard2Symbol === unitSymbols.fahrenheit) {
                convertedValue = (parseFloat(convertedValue) - 32) / 1.8;
            }
            else {
                convertedValue = parseFloat(convertedValue) * conversionRates[userMeasurementUnit.measurementUnitGroup.id];
            }
            if (isNaN(convertedValue)) {
                convertedValue = measurementValue;
                logger.warn(`Can't convert value ${measurementValue} with group id ${userMeasurementUnit.measurementUnitGroup.id}!`);
            }
        }

        return convertedValue;
    },

    convertBaseToCustomUOM: (measurementValue, userMeasurementUnit) => {
        let convertedValue = measurementValue;
        if (
            userMeasurementUnit
            && userMeasurementUnit.standardId !== measurementUnitType.BASE
            && measurementValue !== null
            && measurementValue !== undefined
        ) {
            if (userMeasurementUnit.measurementUnitGroup.standard2Symbol === unitSymbols.fahrenheit) {
                convertedValue = parseFloat(convertedValue) * 1.8 + 32;
            }
            else {
                convertedValue = parseFloat(convertedValue) / conversionRates[userMeasurementUnit.measurementUnitGroup.id];
            }

            if (convertedValue === null || isNaN(convertedValue)) {
                convertedValue = measurementValue;
                logger.warn(`Can't convert value ${measurementValue} with group id ${userMeasurementUnit.measurementUnitGroup.id}!`);
            }
        }

        return convertedValue;
    },

    convertAndFormat: (measurementValue, userMeasurementUnit) => {
        const convertedValueObj = { measurementValue, measurementUnit: null };
        if (userMeasurementUnit) {
            convertedValueObj.measurementValue = internals.convertBaseToCustomUOM(measurementValue, userMeasurementUnit);
            convertedValueObj.measurementUnit = userMeasurementUnit.standardId === MEASUREMENT_UNIT.IMPERIAL ?
                userMeasurementUnit.measurementUnitGroup.standard2Symbol :
                userMeasurementUnit.measurementUnitGroup.standard1Symbol;
        }

        return convertedValueObj;
    },

    findMeasurementUnitByGroupId: (measurementUnitGroupId, userMeasurementUnitGroups) => {
        if (!measurementUnitGroupId || !userMeasurementUnitGroups) {
            return null;
        }

        const userMeasurementUnitGroup = find(userMeasurementUnitGroups, (group) => {
            return group.measurementUnitGroup.id === measurementUnitGroupId;
        });

        if (!userMeasurementUnitGroup) {
            logger.warn(`User measurement unit with group id ${measurementUnitGroupId} not found!`);
        }

        return userMeasurementUnitGroup;
    }
};

export const getMeasurementUnits = () => {
    const user = localStorageHelper.getItem('user');
    return user?.measurementUnitGroups || null;
};

// eslint-disable-next-line import/no-unused-modules
export const getMeasurementUnitGroups = () => {
    const measurementUnitGroups = localStorageHelper.getItem('measurementUnitGroups');
    return measurementUnitGroups || null;
};

export const getMeasurementUnitSymbol = measurementUnitGroupId => {
    const userMeasurementUnit = internals.findMeasurementUnitByGroupId(measurementUnitGroupId, getMeasurementUnits());
    const unitGroupDetails = userMeasurementUnit?.measurementUnitGroup;
    const measurementGroupStandardId = userMeasurementUnit?.standardId;
    const userMeasurementUnitSymbol = measurementGroupStandardId === MEASUREMENT_UNIT.IMPERIAL ? unitGroupDetails?.standard2Symbol : unitGroupDetails?.standard1Symbol;

    return userMeasurementUnitSymbol || '';
};

export const baseToCustomUOM = {

    convertWithGroups: (measurementUnitGroupId, measurementValue, userMeasurementUnitGroups) => {
        const userMeasurementUnit = internals.findMeasurementUnitByGroupId(measurementUnitGroupId, userMeasurementUnitGroups);
        if (userMeasurementUnit) {
            return internals.convertBaseToCustomUOM(measurementValue, userMeasurementUnit);
        }

        return measurementValue;
    },

    convertAndFormatWithGroups: (measurementUnitGroupId, measurementValue, userMeasurementUnitGroups) => {
        const userMeasurementUnit = internals.findMeasurementUnitByGroupId(measurementUnitGroupId, userMeasurementUnitGroups);

        return internals.convertAndFormat(measurementValue, userMeasurementUnit);
    },

    /**
     * Create a json of key names and measurement units
     */
    getMeasurementUnitsFromIds: measurementUnitGroupIds => {
        const allMeasurementUnits = getMeasurementUnits();
        const currentMeasurementUnits = {};

        Object.keys(measurementUnitGroupIds).forEach(key => {
            const oneMeasurementUnit = internals.findMeasurementUnitByGroupId(measurementUnitGroupIds[key], allMeasurementUnits);

            if (oneMeasurementUnit || measurementUnitGroupIds[key] === null) {
                currentMeasurementUnits[key] = oneMeasurementUnit;
            }
        });

        return currentMeasurementUnits;
    },

    // Gets a list of measurement items, which is a list of JSONs (ex.: '[{},{},{}]' )
    // Returns the same structure, having the values specified in 'measurementUnitGroupIds' converted
    // 'measurementUnitGroupIds' structure: {key1: measurementUnitGroupId1, key2: measurementUnitGroupId2}
    convertMeasurementList: (measurementItems, measurementUnitGroupIds, precision = 5) => {
        const measurementUnits = baseToCustomUOM.getMeasurementUnitsFromIds(measurementUnitGroupIds);

        if (Object.keys(measurementUnits).length !== 0) {
            return measurementItems.map(measurementItem => {

                // Convert every value which is listed as a key in 'measurementUnits'
                Object.keys(measurementUnits).forEach(key => {
                    measurementItem[key] =
                        setMaxPrecision(
                            internals.convertBaseToCustomUOM(measurementItem[key], measurementUnits[key]),
                            precision
                        );
                });

                return measurementItem;
            });
        }
        return measurementItems;
    },


    convertMeasurementMultiPrecision: (measurementItems, measurementConfig) => {
        const measurementUnits = baseToCustomUOM.getMeasurementUnitsFromIds({
            magnitude: measurementConfig.magnitude.measurementUnitGroup,
            frequency: measurementConfig.frequency.measurementUnitGroup
        });

        if (Object.keys(measurementConfig).length !== 0) {
            return measurementItems.map(measurementItem => {

                // Convert every value which is listed as a key in 'measurementUnits'
                Object.keys(measurementConfig).forEach(key => {
                    measurementItem[key] =
                        setMaxPrecision(
                            internals.convertBaseToCustomUOM(measurementItem[key], measurementUnits[key]),
                            measurementConfig[key]?.precision
                        );
                });

                return measurementItem;
            });
        }
        return measurementItems;
    }

};

// eslint-disable-next-line import/no-unused-modules
export const customToBaseUOM = {
    convertWithGroups: (measurementUnitGroupId, measurementValue, userMeasurementUnitGroups) => {
        const userMeasurementUnit = internals.findMeasurementUnitByGroupId(measurementUnitGroupId, userMeasurementUnitGroups);
        return internals.convertCustomToBaseUOM(measurementValue, userMeasurementUnit);
    }
};

