import { LEVEL, LIMIT_SYMBOL } from './constants';

export const getContextMenuOptions = (isEditable, options) => {
    return options.map(item => item.id === 'removeKpi' && !isEditable ? { ...item, disabled: true } : item);
};

export const getSliderValues = (limits) => {
    return limits.map(limit => ({
        type: limit.limitLevel,
        value: limit.limitValue,
        symbol: limit.limitDirection === LEVEL.LowerLimit ? LIMIT_SYMBOL.Lower : LIMIT_SYMBOL.Greater
    }));
};

export const getUnitsGroupID = (timeseriesNameTextKey, kpiList) => {
    const timeSeriesList = kpiList.reduce((result, group) => {
        return result.concat(group.timeseries);
    }, []);

    const foundTimeseries = timeSeriesList.find(item => item.timeseriesNameTextKey === timeseriesNameTextKey);

    return foundTimeseries ? foundTimeseries.timeseriesUnitGroupID : null;
};

export const getSliderValue = {
    oneDirectionAlarmOrAlert: [
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    oneDirectionAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '<' },
    ],
    outOfRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '<' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
        { type: 'Alert', value: 100, symbol: '>' },
        { type: 'Alarm', value: 130, symbol: '<' },
    ],
    outOfRangeAlarmAndAlert: [
        { type: 'Alert', value: 80, symbol: '>' },
        { type: 'Alarm', value: 30, symbol: '<' },
        { type: 'Alarm', value: 100, symbol: '>' },
        { type: 'Alert', value: 50, symbol: '<' },
    ],
};
