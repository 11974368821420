import PropTypes from 'prop-types';
import React from 'react';

const ScatterTooltip = ({ component, xUnit, yUnit, x, y, date }) => {
    return (
        <div className='scatter-tooltip'>
            <div className='scatter-tooltip-header'>{date}</div>
            <strong>{component}</strong>
            <br />
            {x} {xUnit}
            <br />
            {y} {yUnit}<br />

        </div>
    );
};

ScatterTooltip.propTypes = {
    component: PropTypes.string,
    name: PropTypes.string,
    xUnit: PropTypes.string,
    yUnit: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    date: PropTypes.string
};

export default ScatterTooltip;
