import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from './selectors';
import * as actions from './actions';
import UserProfileComponent from './UserProfileComponent';


const UserProfileContainer = (props) => {
    useEffect(() => {
        !props.model.availableLanguages && props.actions.getAvailableLanguages();
    }, []);

    return <UserProfileComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileContainer);
