import * as actionTypes from './constants';
import logger from 'helpers/logger';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';

import {
	ParameterFilesApi,
} from 'api';

const getChangedParameterList = getApiActionCreator(actionTypes.GET_CHANGED_PARAMETER_LIST);

export const getChangedParameterFileList = (asset, from, to) => {
	return (dispatch) => {
		if (!asset || !from || !to) {
			dispatch(getChangedParameterList(apiCallStatus.SUCCESS, []));
		} else {
			// Let the UI know that data fetching is ongoing
			dispatch(getChangedParameterList(apiCallStatus.LOADING));

			ParameterFilesApi.getChangedParameterFileList(asset, from, to)
				.then((res) => {
					logger.debug('Changed parameter file list loaded successfully with following params:', asset);
					let data = res ? res : [];
					data = sortBy(data, parameter => new moment(parameter.time)).reverse();
					dispatch(getChangedParameterList(apiCallStatus.SUCCESS, data));
				})
				.catch((err) => {
					dispatch(getChangedParameterList(apiCallStatus.ERROR, null, err));
				});
		}
	};
};

export const clearChangedParameterFileData = () => {
	return (dispatch) => {
		dispatch(getChangedParameterList(apiCallStatus.SUCCESS, []));
	};
};
