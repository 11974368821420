import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';

const SectionInfoButton = () => {
    const { t: translate } = useTranslation();
    return <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.kpiInfoContent')} />;
};

export default SectionInfoButton;
