import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconFault = ({ width = 16, height = 16, color = 'none' }) => {
	return <svg width={width} height={height} viewBox='0 0 15 15' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path d='M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0V0ZM10.52 9.47L9.46 10.53L7 8.06L4.53 10.53L3.47 9.47L5.94 7L3.47 4.53L4.53 3.47L7 5.94L9.47 3.47L10.53 4.53L8.06 7L10.52 9.47Z' fill={colors.pomegranate} />
	</svg>
		;
};

IconFault.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string
};
export default IconFault;
