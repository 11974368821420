import { createSelector } from 'reselect';

const getParameterFileList = ({ parameterBackup }) => {
    return parameterBackup && parameterBackup.parameterFileList;
};

const getParameterFileListLoadingStatus = ({ parameterBackup }) => {
    return parameterBackup && parameterBackup.parameterFileList && parameterBackup.parameterFileList.loadingStatus;
};

const getParameterFileLoadingStatus = ({ parameterBackup }) => {
    return parameterBackup && parameterBackup.parameterFile && parameterBackup.parameterFile.loadingStatus;
};

const getParameterFile = ({ parameterBackup }) => {
    return parameterBackup && parameterBackup.parameterFile;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

export const getModel = createSelector([
    getParameterFileList,
    getParameterFile,
    getSelectedAsset,
    getParameterFileListLoadingStatus,
    getParameterFileLoadingStatus
],
    (
        parameterFileList,
        parameterFile,
        selectedAsset,
        parameterFileListLoadingStatus,
        parameterFileLoadingStatus
    ) => {
        return {
            parameterFileList,
            parameterFile,
            selectedAsset,
            parameterFileListLoadingStatus,
            parameterFileLoadingStatus
        };
    });
