import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'sharedComponents';
import { useTranslation } from 'react-i18next';

import useChartConfig from './useChartConfig';

import './style.scss';

const OverviewPieChartComponent = (props) => {
    const { t: translate } = useTranslation();

    const chartOptions = useChartConfig(props.chartData,
        props.id,
        props.titleText,
        props.chartClickHandler,
        props.legendClickHandler,
        translate);

    return <div className='dashboard-style'>
        <Chart
            options={chartOptions}
            isStatic={false}
            container={props.id}
        />
    </div>;
};

OverviewPieChartComponent.propTypes = {
    id: PropTypes.string.isRequired,
    chartData: PropTypes.array,
    titleText: PropTypes.string,
    chartClickHandler: PropTypes.func,
    legendClickHandler: PropTypes.func
};

export default OverviewPieChartComponent;
