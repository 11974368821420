import { map, forEach, indexOf } from 'lodash';
import { translate } from 'helpers/translateHelper';
import { healthStatuses as healthStatusValues } from 'helpers/constants';

import { TAB_SECTIONS } from './constants';
import { HealthParameters } from './HealthParameters';
import { OperationalParameters } from './OperationalParameters';

export const getToggleOptions = () => [
    {
        label: translate('ABB.Powertrain.Frontend.healthParameters'),
        value: TAB_SECTIONS.HealthParameters,
        tabComponent: HealthParameters
    },
    {
        label: translate('ABB.Powertrain.Frontend.operatingParameters'),
        value: TAB_SECTIONS.OperatingParameters,
        tabComponent: OperationalParameters
    }
];


const updateAlertValue = (healthStatuses, alert) => {
    const updatedHealthStatuses = [];

    if (healthStatuses && healthStatuses.length && healthStatuses.length === 3) {
        updatedHealthStatuses[0] = { ...healthStatuses[0], valueTo: alert };
        updatedHealthStatuses[1] = { ...healthStatuses[1], valueFrom: alert };
        updatedHealthStatuses[2] = { ...healthStatuses[2] };
    }

    return updatedHealthStatuses;
};

const updateAlarmValue = (healthStatuses, alarm) => {
    const updatedHealthStatuses = [];

    if (healthStatuses && healthStatuses.length && healthStatuses.length === 3) {
        updatedHealthStatuses[0] = { ...healthStatuses[0] };
        updatedHealthStatuses[1] = { ...healthStatuses[1], valueTo: alarm };
        updatedHealthStatuses[2] = { ...healthStatuses[2], valueFrom: alarm };
    }

    return updatedHealthStatuses;
};


const updateAlertNotification = (healthStatuses, alertNotification) => {
    return map(healthStatuses, (healthStatus) => {
        if (healthStatus.healthStatusID === healthStatusValues.WEAK) {
            return { ...healthStatus, enableNotification: alertNotification };
        }
        return healthStatus;
    });
};

const updateAlarmNotification = (healthStatuses, alarmNotification) => {
    return map(healthStatuses, (healthStatus) => {
        if (healthStatus.healthStatusID === healthStatusValues.CRITICAL) {
            return { ...healthStatus, enableNotification: alarmNotification };
        }
        return healthStatus;
    });
};

const updateValueOfMeasurementType = (originalHealthStatuses, measurementTypeID, value, updateFn) => {
    return originalHealthStatuses && map(originalHealthStatuses, (healthStatus) => {
        if (healthStatus.measurementTypeID === measurementTypeID) {
            return {
                ...healthStatus,
                healthStatuses: updateFn(healthStatus.healthStatuses, value)
            };
        }
        return healthStatus;
    });
};

export const updateAlertValueOfMeasurementType = (originalHealthStatuses, measurementTypeID, value) => {
    return updateValueOfMeasurementType(originalHealthStatuses, measurementTypeID, value, updateAlertValue);
};

export const updateAlarmValueOfMeasurementType = (originalHealthStatuses, measurementTypeID, value) => {
    return updateValueOfMeasurementType(originalHealthStatuses, measurementTypeID, value, updateAlarmValue);
};

export const updateAlertNotificationOfMeasurementType = (originalHealthStatuses, measurementTypeID, alertNotification) => {
    return originalHealthStatuses && map(originalHealthStatuses, (healthStatus) => {
        if (healthStatus.measurementTypeID === measurementTypeID) {
            return {
                ...healthStatus,
                healthStatuses: updateAlertNotification(healthStatus.healthStatuses, alertNotification)
            };
        }
        return healthStatus;
    });
};

export const updateAlarmNotificationOfMeasurementType = (originalHealthStatuses, measurementTypeID, alarmNotification) => {
    return originalHealthStatuses && map(originalHealthStatuses, (healthStatus) => {
        if (healthStatus.measurementTypeID === measurementTypeID) {
            return {
                ...healthStatus,
                healthStatuses: updateAlarmNotification(healthStatus.healthStatuses, alarmNotification)
            };
        }
        return healthStatus;
    });
};


export const isPrecisionCorrect = (value, precision) => {
    const afterDotCount = value && (value.toString().split('.')[1] || []).length;
    return afterDotCount <= precision;
};


export const updateCalibrationOffsetOfMeasurementType = (measurementTypes, measurementTypeID, offset) => {
    const updatedMeasurementTypes = measurementTypes && map(
        measurementTypes,
        (measurementType) => {
            const measurementTypeIDs = Object.keys(measurementType.data);
            if (indexOf(measurementTypeIDs, measurementTypeID.toString()) > -1) {
                return {
                    ...measurementType,
                    data: {
                        ...measurementType.data,
                        [measurementTypeID]: {
                            ...measurementType.data[measurementTypeID],
                            measurementCalibration: offset
                        }
                    }
                };
            } else {
                return { ...measurementType };
            }
        });

    return updatedMeasurementTypes ? updatedMeasurementTypes : measurementTypes;
};


export const updateHealthStatuses = (healthStatuses, newHealthStatuses, measurementTypeID) => {
    return map(healthStatuses, (healthStatus) => {
        return healthStatus.measurementTypeID === measurementTypeID ?
            {
                ...healthStatus,
                healthStatuses: newHealthStatuses
            } : healthStatus;
    });
};

export const updateHealthStatusesWithCalculatedTresholds = (healthStatuses, thresholdData) => {
    if (!thresholdData || thresholdData.isAlertHigherThanAlarm) {
        return healthStatuses;
    }
    return updateHealthStatuses(healthStatuses, thresholdData.thresholds, thresholdData.measurementTypeID);
};

export const mapToOffsetReqObj = (selectedAssetId, parameter) => {
    return {
        assetID: selectedAssetId,
        measurementTypeUnitGroupID: parameter.measurementTypeUnitGroupID,
        measurementTypeName: parameter.displayName,
        measurementTypeID: parameter.measurementTypeID,
        value: parameter.measurementCalibration
    };
};

export const getSaveOffsetRequestList = (selectedAssetId, trendKPIs) => {
    const formattedList = [];
    forEach(trendKPIs, (trendKpiGroup) => {
        forEach(Object.keys(trendKpiGroup.data), (kpiID) => {
            if (trendKpiGroup.data[kpiID].isCalibrationEnabled) {
                formattedList.push(mapToOffsetReqObj(selectedAssetId, trendKpiGroup.data[kpiID]));
            }
        });
    });
    return formattedList;
};
