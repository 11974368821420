import React from 'react';
import map from 'lodash/map';

const AssetDetailsList = ({ title, data }) => {
	return <div className={'asset-detail-list-container'}>
		{data ? <div className='asset-detail-list'>
			<div className='asset-detail-list-title'>{title}</div>
			{map(data, (assetDetail) => {
				return <div className='asset-detail-list-item' key={assetDetail.label}>
					<div className='data-label'>{assetDetail.label}</div>
					<pre className='data-value'>{assetDetail.value ?? '-'}</pre>
				</div>;
			})}
		</div> : null}
	</div>;
};

export default AssetDetailsList;
