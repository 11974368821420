export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_GONE = 410;

export const HTTP_STATUS_PRECONDITION_FAILED = 412;

export const GLOBAL_ERROR_STATUS_CODES = [
	400, //Bad request
	403, //Forbidden
	404, //Not-Found
	500, //Internal Server Error
	502, //Bad gateway
	504, //Gateway timeout
];

export const SESSION_ERROR_STATUS_CODES = [
	HTTP_STATUS_UNAUTHORIZED, //expired token
	HTTP_STATUS_GONE, //expired token
];
