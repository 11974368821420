import * as actionTypes from './constants';
import { translate } from 'helpers/translateHelper';

const closeNotificationBarAction = () => {
	return {
		type: actionTypes.CLOSE_NOTIFICATION_BAR,
	};
};

const setNotificationBarAction = (message, type, link) => {
	return {
		type: actionTypes.SET_NOTIFICATION_BAR,
		data: { message, type, link },
	};
};

export const closeNotificationBar = () => {
	return (dispatch) => {
		dispatch(closeNotificationBarAction());
	};
};

export const setContractNotification = (contractMessage) => {
	return (dispatch) => {
		const link = contractMessage.link?.description
			? { ...contractMessage.link, description: translate(contractMessage.link.description) }
			: undefined;
		dispatch(
			setNotificationBarAction(
				translate(contractMessage.message, contractMessage.messageParams),
				contractMessage.type,
				link
			)
		);
	};
};
