import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';

const SectionInfoButton = (props) => {
    const { t: translate } = useTranslation();
    const { section: { sectionId } } = props;
    return <InfoButtonWithOnlineHelp infoText={translate(`ABB.Powertrain.Frontend.${sectionId}Info`)} />;
};

export default SectionInfoButton;
