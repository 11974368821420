import { createSelector } from 'reselect';
import map from 'lodash/map';
import find from 'lodash/find';
import transform from 'lodash/transform';
import isEmpty from 'lodash/isEmpty';
import { LoggerTypes } from './constants';
import { dateKind } from '../../../constants';

const getShowUtcDates = ({ eventLog }) => {
	return eventLog.dateKind === dateKind.UTC;
};

const getEventLoggersData = ({ eventDetailsCMD }) => {
	const shownLoggerTypes = [LoggerTypes.DataLogger, LoggerTypes.FactoryLogger, LoggerTypes.TransientLogger];
	return eventDetailsCMD?.loggers?.data &&
		!isEmpty(eventDetailsCMD.loggers.data) ?
		eventDetailsCMD.loggers.data.filter(l => shownLoggerTypes.includes(l.type)) : [];
};

const geSelectedLoggerSignalsData = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.selectedLoggerSignals?.data;
};

const getEventLoggers = createSelector([
	getEventLoggersData,
	geSelectedLoggerSignalsData,
], (
	loggersData,
	selectedLoggerSignalsData,
) => {
	return map(loggersData, (logger) => {
		return {
			...logger,
			label: logger.filename,
			signals: transform(logger.signals, (acc, signal) => {
				if (!find(selectedLoggerSignalsData, { loggerID: logger.id, signalID: signal.id })) {
					acc.push({
						label: signal.value,
						value: signal.value,
						id: signal.id
					});
				}
			}, [])
		};
	});
});

const getEventLoggersLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.loggers?.loadingStatus;
};

const getEventLoggerSignalsLoadingStatus = ({ eventDetailsCMD }) => {
	return eventDetailsCMD.selectedLoggerSignals?.loadingStatus;
};

export const getModel = createSelector([
	getShowUtcDates,
	getEventLoggers,
	getEventLoggersLoadingStatus,
	getEventLoggerSignalsLoadingStatus,
	geSelectedLoggerSignalsData
], (
	showUtcDates,
	loggers,
	loggersLoadingStatus,
	signalDataLoadingStatus,
	selectedLoggerSignals
) => {
	return {
		showUtcDates,
		loggers,
		loggersLoadingStatus,
		signalDataLoadingStatus,
		selectedLoggerSignals
	};
});
