import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { routes } from 'routes';
import { OverviewPieChart } from '../OverviewPieChart';
import { InfoButtonWithOnlineHelp, GeneralSelect, InfoMessage } from 'sharedComponents';
import LoadingStatusWrapper from 'sharedComponents/WithLoadingStatus/LoadingStatusWrapper';
import { getChartData } from './helpers';
import { setOptions } from '../../helpers';
import { allAssetTypes, filterTabs } from 'helpers/constants';

import './style.scss';


const OverviewAssetsComponent = (props) => {

    const { t: translate } = useTranslation();
    const { assets } = props.model;
    const history = useHistory();

    const [chartData, setChartData] = useState(null);
    const [assetType, setAssetType] = useState(allAssetTypes);

    useEffect(() => {
        Array.isArray(assets) && setChartData(getChartData([...assets], assetType));
    }, [assets, assetType]);

    useEffect(() => {
        setAssetType(allAssetTypes);
    }, [assets.length]);

    const options = assets?.length ? setOptions(assets, translate) : null;

    return <div id='overview-condition-assets'>
        <LoadingStatusWrapper loadingStatus={[props.model.assetsLoadingStatus, ...props.model.generalFilterLoadingStatuses]}>
            {chartData?.length ?
                <>
                    <div className='asset-condition-title'>
                        {translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionTitleText')}
                        <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionInfoText')} />
                    </div>
                    <GeneralSelect
                        options={!!options?.length && options}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        defaultValue={!!options?.length && options[0]}
                        menuShouldScrollIntoView={false}
                        onChange={value => setAssetType(value.assetType)}
                        className='asset-type-filter'
                        id='asset-type-filter'
                        isSearchable={false} />
                    <OverviewPieChart chartData={chartData}
                        id='pie-chart-assets'
                        titleText={translate('ABB.Powertrain.Frontend.ttl_Assets')}
                        chartClickHandler={(e) => history.push(routes.PowertrainAssetsOverview, { assetType: e.assetType, conditionStatus: e.status, tab: filterTabs.ASSETS })}
                        legendClickHandler={(e) => history.push(routes.PowertrainAssetsOverview, { assetType: e.assetType, conditionStatus: e.status, tab: filterTabs.ASSETS })}
                    />
                </>
                : <div className='no-assets-data'>
                    <InfoMessage
                        title='ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionNoAssetsText1'
                        text='ABB.Powertrain.Frontend.condMonitoringOverviewAssetConditionNoAssetsText2'
                    />
                </div>}
        </ LoadingStatusWrapper>
    </div>;
};

OverviewAssetsComponent.propTypes = {
    model: PropTypes.shape({
        assets: PropTypes.array,
        generalFilterLoadingStatuses: PropTypes.array,
        assetsLoadingStatus: PropTypes.string
    })
};

export default OverviewAssetsComponent;
