import axios from 'axios';
import logger from 'helpers/logger';
import { errorMessages } from '../constants';


function getInstance() {
	const instance = axios.create({
		timeout: 5000
	});

	instance.interceptors.request.use(
		(request) => request,
		(error) => {
			logger.setLogToStorage(error);
			return Promise.reject(error);
		});

	instance.interceptors.response.use(
		(result) => {
			if (result && result.data && result.status === 200) {
				// We create a new blob here
				return result.data;
			} else {
				const error = {};
				// Check in what form an err may appear
				error.message = result.data.message;
				logger.setLogToStorage(error);

				return Promise.reject(error);
			}
		},
		(error) => {
			// Check what form err appear
			const status = error && error.response && error.response.status ? error.response.status : null;
			switch (status) {
				case 403://Forbidden
				case 404://Not-Found
				case 500://Internal Server Error
					error.generalErrorStatus = status;
					logger.setLogToStorage('ERROR: ' + status);
					return Promise.reject(error);
				case 405: //Method Not Allowed
					error.status = status;
					error.message = errorMessages.METHOD_NOT_ALLOWED;
					logger.setLogToStorage('Method not allowed, status ' + status);
					return Promise.reject(error);
				default:
					logger.error(error);
					logger.setLogToStorage(error);
					return Promise.reject(error);
			}
		}
	);

	return instance;
}

class DownloadManager {
	constructor(instance) {
		this.instance = instance;
	}

	async downloadBlob(url, contentType) {
		return await this.instance({
			url: url,
			method: 'get',
			responseType: 'blob'
		})
			.then(res => new Blob([res], { type: contentType }));
	}
}

export default new DownloadManager(getInstance());
