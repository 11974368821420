import { apiCallStatus } from 'helpers/constants';
import { useEffectOnlyOnce } from 'sharedHooks';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';

import {
    getDefaultOrganization,
    getDefaultFilters,
    validateOrgFilter,
    validateCountryFilter,
    validateSiteFilter,
    validateAssetGroupFilter
} from './helpers';
import { translate } from 'helpers/translateHelper';


const useValidateFilterState = ({ model, actions }) => {

    const handleValidationResult = (isValid) => {
        if (!isValid) {
            const selectedOrganizations = getDefaultOrganization(model.organizations);
            actions.setSelectedFilters({
                organizations: selectedOrganizations,
                ...getDefaultFilters()
            });
            notify(translate('ABB.Powertrain.Frontend.generalFiltersResetFiltersNotifications'), NOTIFICATION_TYPES.INFO, true);
            let refreshFlag = true;
            if (model?.isAdmin && !model?.isCIAMSyncFlag) {
                refreshFlag = false;
            }
            actions.getSitesAndAssetGroups(selectedOrganizations);
            actions.getAvailableAssets(selectedOrganizations, refreshFlag);
            if (model?.isAdmin) {
                actions.setCIAMSyncFlag(false);
            }
        }
    };

    useEffectOnlyOnce(() => {
        handleValidationResult(
            validateOrgFilter(model.organizations, model.selectedFilters.organizations) &&
            validateCountryFilter(model.rawSites, model.selectedFilters.countries) &&
            validateSiteFilter(model.rawSites, model.selectedFilters.sites) &&
            validateAssetGroupFilter(model.assetGroups, model.selectedFilters.assetGroup)
        );
    },
        [
            model.loadingData.organizationsLoadingStatus,
            model.loadingData.sitesLoadingStatus,
            model.loadingData.assetGroupsLoadingStatus
        ],
        ([organizationsLoadingStatus, sitesLoadingStatus, assetGroupsLoadingStatus]) => {
            return (organizationsLoadingStatus === apiCallStatus.SUCCESS || organizationsLoadingStatus === apiCallStatus.ERROR)
                && (sitesLoadingStatus === apiCallStatus.SUCCESS || sitesLoadingStatus === apiCallStatus.ERROR)
                && (assetGroupsLoadingStatus === apiCallStatus.SUCCESS || assetGroupsLoadingStatus === apiCallStatus.ERROR);
        });
};
export default useValidateFilterState;
