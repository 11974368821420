// eslint-disable-next-line import/no-unused-modules
import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';
//import { assetObjectToComponentRequest2 } from '../helpers';
import {
    //convertKPIsResponse,
    formatKPITrendResponse,
    // convertKPIHistogramResponse,
    //  formatKPIScatterResponse,
    handleGetMeasurementTypesConversion
} from './timeSeriesApiUoMHelper';
class TimeSeries {

    static getMeasurementTypes(assetId) {
        const endpoint = formatEndpoint(endpoints.timeSeries.MeasurementType, { assetId });
        return RestManagerWithoutBaseUrl
            .get(endpoint)
            .then(handleGetMeasurementTypesConversion);
    }

    static getTrend(componentKPITypes, from, to) {
        const endpoint = formatEndpoint(endpoints.timeSeries.Trend, { from, to });
        return RestManagerWithoutBaseUrl
            .post(endpoint, componentKPITypes)
            .then(formatKPITrendResponse);
    }
}
export default TimeSeries;
