import * as constants from './constants';
import { apiCallStatus } from 'helpers/constants';
import { removeBulkFileByIndex, updateSelectedFiles, updateFileUploadStatus, setFileBlobName } from './BulkUploadFileSection/helpers';

const initialState = {
	bulkFiles: [],
	processAllowed: false,
	uploadStatus: {
		data: undefined,
		loadingStatus: undefined
	},
	isUploading: false,
	isProcessing: false,
	processStatus: apiCallStatus.SUCCESS,
	dateInterval: {
		from: undefined,
		to: undefined,
	},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case constants.SET_SELECTED_FILES:
			return {
				...state,
				bulkFiles: updateSelectedFiles([...state.bulkFiles], action.bulkData)
			};
		case constants.REMOVE_SELECTED_FILE:
			return {
				...state,
				bulkFiles: removeBulkFileByIndex([...state.bulkFiles], action.index)
			};
		case constants.GET_BULK_UPLOAD_STATUS:
			return {
				...state,
				uploadStatus: { ...state.uploadStatus, ...action }
			};
		case constants.PROCESS_BULK_UPLOAD:
			return {
				...state,
				processStatus: action.loadingStatus
			};
		case constants.CLEAR_FILE_SECTION:
			return initialState;
		case constants.SET_UPLOAD_LOADING:
			return {
				...state,
				isUploading: action.uploading,
			};
		case constants.SET_FILE_UPLOAD_STATUS:
			return {
				...state,
				bulkFiles: updateFileUploadStatus([...state.bulkFiles], action.file, action.uploadStatus)
			};
		case constants.SET_FILE_BLOB_NAME:
			return {
				...state,
				bulkFiles: setFileBlobName([...state.bulkFiles], action.file, action.blobName)
			};
		case constants.SET_PROCESS_ALLOWED:
			return {
				...state,
				processAllowed: action.processAllowed
			};
		case constants.SET_DATE_INTERVAL:
			return {
				...state,
				dateInterval: action.dateInterval,
			};
		case constants.CLEAR_FILE_BOX:
			return {
				...state,
				bulkFiles: initialState.bulkFiles,
			};
		default:
			return state;
	}
};

export default reducer;
