
import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconMotor = ({ width = 32, height = 32, viewBox = '0 0 32 32', color = colors.nero }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M24.71 14.71L20 19.41L16 15.41L12.71 18.71L11.29 17.29L16 12.59L20 16.59L23.29 13.29L24.71
		14.71ZM30 10.13V21.87L28 24H22V25H26V27H10V25H14V24H10C9.46957 24 8.96086 23.7893 8.58579
		23.4142C8.21071 23.0391 8 22.5304 8 22V20H5V17H2V15H5V12H8V10C8 9.46957 8.21071 8.96086
		8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H12V6.5L13.43 5H22V8H28L30 10.13ZM26 22V10H10V22H26Z' fill={color} />
	</svg>;
};

IconMotor.propTypes = {
	color: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default IconMotor;
