import {
    forEach,
    sortBy,
    isNull,
    isObject
} from 'lodash';
import localStorageHelper from 'helpers/localStorageHelper';
import authHelper from 'helpers/authHelper';

// eslint-disable-next-line import/no-unused-modules
export const userLoggedIn = (userCredentials, currentRoute) => {
    return localStorageHelper.getItem('user')
        && (authHelper.doesPageNeedTokenRefresh(currentRoute) ? userCredentials : true);
};

export const updateAssetNameForComponents = (assets, renamedComponent) => {
    return assets.map(asset =>
        asset.componentID === renamedComponent.componentID ?
            {
                ...asset,
                eTag: renamedComponent.eTag,
                componentName: renamedComponent.componentName
            }
            : asset);
};

export const updateAssetNameInPwtList = (state, newAsset) => {
    const powertrains = state;
    forEach(powertrains, (powertrain) => {
        forEach(powertrain.components, (asset) => {
            if (asset.componentID === newAsset.componentID) {
                asset.componentName = newAsset.componentName;
                asset.eTag = newAsset.eTag;
            }
        });
    });
    return powertrains;
};


const getSortableValue = (value) => {
    return isNull(value) ? '' : value;
};

const getSortableContract = (contract) => {
    Object.keys(contract).forEach(key => {
        if (isObject(contract[key]) && key !== 'previousSubscription') {
            Object.keys(contract[key]).forEach(subKey => {
                contract[subKey] = getSortableValue(contract[key][subKey]);
            });
        }
        else {
            contract[key] = getSortableValue(contract[key]);
        }
    });
    return contract;
};
export const filterContracts = (subscriptionData, assets) => {
    if (!subscriptionData) {
        return;
    }
    const filteredContracts = {
        none: [],
        expired: [],
        expiring: []
    };

    const now = new Date();
    const next60Days = new Date();
    next60Days.setDate(now.getDate() + 60);
    const next30Days = new Date();
    next30Days.setDate(now.getDate() + 30);

    subscriptionData.forEach((asset) => {
        if (asset.subscription?.isLifetimeSubscription === true) {
            return;
        }
        const matchedAsset = assets?.find((item) => item.id === asset.assetId) ?? {};
        const sortableContract = getSortableContract({ ...Object.keys(matchedAsset).length > 0 && asset?.subscriptionInfos[0], ...matchedAsset });
        filteredContracts.none.push(sortableContract);
    });

    return {
        none: sortBy(filteredContracts.none, ['componentName'])
    };
};

export const isClarityIncluded = () => {
    let isClarityIncluded = false;

    forEach(document.getElementsByTagName('script'), script => {
        if (script.src.includes('clarity.js')) {
            isClarityIncluded = true;
            return false;
        }
    });
    return isClarityIncluded;
};
