import { createSelector } from 'reselect';

import { getTrendsUniqueYScaling } from './helpers';

const getEnableUniqueScaling = ({ chartView }) => {
    return getTrendsUniqueYScaling(chartView?.chartData);
};

const getIsZoomed = ({ chartView }) => {
    return chartView.trendChartZoom.isZoomed;
};


export const getModel = createSelector([
    getEnableUniqueScaling,
    getIsZoomed
], (
    enableUniqueScaling,
    isZoomed
) => {
    return {
        enableUniqueScaling,
        isZoomed
    };
});
