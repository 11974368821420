import { ComponentApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
//import { getSaveOffsetRequestList, mapToOffsetReqObj } from './helpers';

const saveOffsetAction = getApiActionCreator(actionTypes.SAVE_OFFSET);

export const saveOffset = (asset, measurementCalibrationList) => {
	return (dispatch) => {
		dispatch(saveOffsetAction(apiCallStatus.LOADING));
		return ComponentApi.saveMeasurementCalibration(asset, measurementCalibrationList)
			.then(() => {
				dispatch(saveOffsetAction(apiCallStatus.SUCCESS));
			})
			.catch((error) => {
				dispatch(saveOffsetAction(apiCallStatus.ERROR, null, error));
			});
	};
};

const saveAlertAlarmAction = getApiActionCreator(actionTypes.SAVE_ALERT_ALARM);
export const saveAlertAlarmConfig = (asset, healthStatusList) => {
	return (dispatch) => {
		dispatch(saveAlertAlarmAction(apiCallStatus.LOADING));
		return ComponentApi.saveHealthStatusGroups(asset, healthStatusList)
			.then(() => {
				dispatch(saveAlertAlarmAction(apiCallStatus.SUCCESS));
			})
			.catch((error) => {
				dispatch(saveAlertAlarmAction(apiCallStatus.ERROR, null, error));
			});
	};
};

const updateHealthStatusEmailNotificationAction = getApiActionCreator(actionTypes.UPDATE_HEALTH_STATUS_EMAIL_NOTIFICATION);
export const updateHealthStatusEmailNotification = (payload) => {
	return (dispatch) => {
		dispatch(updateHealthStatusEmailNotificationAction(apiCallStatus.LOADING));
		const notificationTypeId = payload.notificationTypeID;
		const channelID = payload.channelID;
		const body = payload.payload;
		return ComponentApi.updateHealthStatusEmailNotification(notificationTypeId, channelID, body)
			.then(() => {
				dispatch(updateHealthStatusEmailNotificationAction(apiCallStatus.SUCCESS));
			})
			.catch((error) => {
				dispatch(updateHealthStatusEmailNotificationAction(apiCallStatus.ERROR, null, error));
			});
	};
};

// GET EMAIL NOTIFICATION API
const getHealthStatusEmailNotificationAction = getApiActionCreator(actionTypes.GET_HEALTH_STATUS_EMAIL_NOTIFICATION);
export const getHealthStatusEmailNotification = (payload) => {
	return (dispatch) => {
		dispatch(getHealthStatusEmailNotificationAction(apiCallStatus.LOADING));
		const notificationTypeId = payload.notificationTypeID;
		const channelID = payload.channelID;
		const assetID = payload.assetID;
		return ComponentApi.getHealthStatusEmailNotification(notificationTypeId, channelID, assetID)
			.then((response) => {
				const responseData = {
					response,
					notificationTypeId
				};
				dispatch(getHealthStatusEmailNotificationAction(apiCallStatus.SUCCESS, responseData));
			})
			.catch((error) => {
				dispatch(getHealthStatusEmailNotificationAction(apiCallStatus.ERROR, null, error));
			});
	};
};

// DELETE EMAIL NOTIFICATION API
const deleteHealthStatusEmailNotificationAction = getApiActionCreator(actionTypes.DELETE_HEALTH_STATUS_EMAIL_NOTIFICATION);
export const deleteHealthStatusEmailNotification = (payload) => {
	return (dispatch) => {
		dispatch(deleteHealthStatusEmailNotificationAction(apiCallStatus.LOADING));
		const notificationTypeId = payload.notificationTypeID;
		const channelID = payload.channelID;
		const assetID = payload.payload;
		return ComponentApi.deleteHealthStatusEmailNotification(notificationTypeId, channelID, assetID)
			.then((response) => {
				dispatch(deleteHealthStatusEmailNotificationAction(apiCallStatus.SUCCESS, response));
			})
			.catch((error) => {
				dispatch(deleteHealthStatusEmailNotificationAction(apiCallStatus.ERROR, null, error));
			});
	};
};
