import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';


export const getAvailableAssetsAction = getApiActionCreator(actionTypes.GET_AVAILABLE_ASSETS);
export const addPowertrainAction = getApiActionCreator(actionTypes.ADD_POWERTRAIN);
export const editPowertrainAction = getApiActionCreator(actionTypes.EDIT_POWERTRAIN);

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const setWizardStepState = (step, state) => {
    return {
        type: actionTypes.SET_WIZARD_STEP_STATE,
        step,
        state,
    };
};

export const setWizardActiveStep = (step) => {
    return {
        type: actionTypes.SET_WIZARD_ACTIVE_STEP,
        step,
    };
};

export const setPowertrainNameAction = (powertrainName) => {
    return {
        type: actionTypes.SET_SELECTED_POWERTRAIN_NAME,
        powertrainName,
    };
};

export const setSelectedSiteAction = (site) => {
    return {
        type: actionTypes.SET_SELECTED_SITE,
        site,
    };
};

export const setSelectedAssetTypeAvailableSectionAction = (assetType) => {
    return {
        type: actionTypes.SET_SELECTED_ASSET_TYPE_AVAILABLE_SECTION,
        assetType,
    };
};

export const setAssetFilterAvailableSectionAction = (assetFilter) => {
    return {
        type: actionTypes.SET_ASSET_FILTER_AVAILABLE_SECTION,
        assetFilter,
    };
};

export const addAssetAction = (asset) => {
    return {
        type: actionTypes.ADD_ASSET,
        asset,
    };
};

export const removeAssetAction = (asset) => {
    return {
        type: actionTypes.REMOVE_ASSET,
        asset,
    };
};

export const setSelectedAssetTypeAddedSectionAction = (assetType) => {
    return {
        type: actionTypes.SET_SELECTED_ASSET_TYPE_ADDED_SECTION,
        assetType,
    };
};

export const setAssetFilterAddedSectionAction = (assetFilter) => {
    return {
        type: actionTypes.SET_ASSET_FILTER_ADDED_SECTION,
        assetFilter,
    };
};

export const addPowertrainRequest = (powertrain, siteIds, nameFilter) => ({
    type: actionTypes.ADD_POWERTRAIN_REQUEST,
    powertrain,
    siteIds,
    nameFilter
});

export const editPowertrainRequest = (powertrain, powertrainDetailsChangedFlag, addedAssetsChangedFlag) => ({
    type: actionTypes.EDIT_POWERTRAIN_REQUEST,
    powertrain,
    powertrainDetailsChangedFlag,
    addedAssetsChangedFlag
});

export const getAvailableAssetsRequest = (siteId, organizationIds, pageNumber, filter) => ({
    type: actionTypes.GET_AVAILABLE_ASSETS_REQUEST,
    siteId,
    organizationIds,
    pageNumber,
    filter
});
