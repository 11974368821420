import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconAnnualMaintenanceBarChartLegend = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = colors.nero }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.000488281' width={width} height={height} rx='4' fill={color} />
    </svg>;
};

IconAnnualMaintenanceBarChartLegend.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconAnnualMaintenanceBarChartLegend;
