import { actionTypes } from './constants';


export const setActiveTab = (activeTab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        activeTab
    };
};

export const toggleConditionStatusFilter = (conditionStatusID) => {
    return {
        type: actionTypes.TOGGLE_CONDITION_STATUS_FILTER,
        conditionStatusID
    };
};

export const setAssetTypeFilter = (assetTypeFilter) => {
    return {
        type: actionTypes.SET_ASSET_TYPES_FILTER,
        assetTypeFilter
    };
};

export const setSearchboxFilterAction = (searchboxFilter) => {
    return {
        type: actionTypes.SET_SEARCHBOX_FILTER,
        searchboxFilter
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const setSearchboxFilter = (searchboxFilter) => {
    return (dispatch) => {
        dispatch(setSearchboxFilterAction(searchboxFilter));
    };
};
