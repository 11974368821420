import { createSelector } from 'reselect';
import { dateKind } from '../constants';
import { getUniqueYScaling } from '../helpers';

const getTrendData = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.selectedTrendsData;
};

const getTrendDataLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trend.loadingStatus;
};

const getSelectedEvent = ({ eventLog }) => {
    return eventLog.selectedEvent;
};

const getEnableUniqueScaling = ({ eventDetailsTrendSignal }) => {
    const selectedTrends = eventDetailsTrendSignal.selectedTrendsData;

    return getUniqueYScaling(selectedTrends);
};

const getShowUtcDates = ({ eventLog }) => {
    return eventLog.dateKind === dateKind.UTC;
};

const getIsZoomed = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trendChartZoom.isZoomed;
};

export const getModel = createSelector([
    getTrendData,
    getTrendDataLoadingStatus,
    getSelectedEvent,
    getEnableUniqueScaling,
    getShowUtcDates,
    getIsZoomed
], (
    selectedTrendsData,
    trendDataLoadingStatus,
    event,
    enableUniqueScaling,
    showUtcDates,
    isZoomed

) => {
    return {
        selectedTrendsData,
        trendDataLoadingStatus,
        event,
        enableUniqueScaling,
        showUtcDates,
        isZoomed
    };
});
