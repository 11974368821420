export const actionTypes = {
    SET_ACTIVE_TAB: 'advancedAnalytics/SET_ACTIVE_TAB',
    CLEAR_STORE: 'advancedAnalytics/CLEAR_STORE',
    GET_ANNUAL_MAINTENANCE_PLAN: 'advancedAnalytics/GET_ANNUAL_MAINTENANCE_PLAN',
    GET_DAILY_AGING_STRESS: 'advancedAnalytics/GET_DAILY_AGING_STRESS',
    SET_DATE_SELECTION: 'advancedAnalytics/SET_DATE_SELECTION'
};

export const advancedAnalyticsButtons = {
    annualMaintenancePlan: 'AnnualMaintenancePlan',
    dailyAgingStress: 'DailyAgingStress'
};

export const tabContainerIds = {
    [advancedAnalyticsButtons.annualMaintenancePlan]: 'annual-maintenance-plan',
    [advancedAnalyticsButtons.dailyAgingStress]: 'daily-aging-stress'
};

export const emptyMaintenanceValue = '-';

export const rowIds = {
    maintenanceStatus: 'maintenance-status',
    nextPMKitMaintenance: 'pm-kit-maintenance',
    nextConditionBasedMaintenance: 'next-condition-based-maintenance',
    lifecycleStatus: 'lifecycle-status',
    overdueMaintenance: 'overdue-maintenance',
    executedMaintenance: 'executed-maintenance'
};
