import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import useCapability from 'sharedHooks/useCapability';

import { getUserLimits } from '../../../../Header/UserLimits/actions';
import * as commonActions from '../actions';
import TrendChartComponent from './TrendChartComponent';

import { getModel } from './selectors';


const TrendChartContainer = (props) => {
    const { model, actions } = props;

    const capability = useCapability();

    useEffect(() => {
        if (capability.isSet()) {
            if (capability.isSmartSensor() && model.trendHealthStatuses.length || !capability.isSmartSensor()) {
                actions.getUserLimits(props.asset, true);
            }
        }

    }, [capability, model.trendHealthStatuses]);

    return <TrendChartComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...commonActions, getUserLimits }, dispatch)
    };
};

const TrendKPIListContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendChartContainer);

export default TrendKPIListContainerConn;
