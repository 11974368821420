import {
    OrganizationManagementApi,
    HeaderApi,
} from 'api';
import { apiCallStatus, commonRequestParams } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes, pageSizeOrg } from './constants';
import { getExistingSiteIds } from 'helpers/sitesHelper';
import { getApiActionCreator } from 'helpers/actionHelper';
import {
    formatSiteAssetGroupRequest,
    formatAssetsRequest
} from './helpers';


// Action creators
const getOrganizationsAction = getApiActionCreator(actionTypes.GET_ORGANIZATIONS);
const getAssetsAction = getApiActionCreator(actionTypes.GET_ASSETS);
const getPowertrainsAction = getApiActionCreator(actionTypes.GET_POWERTRAINS);
export const getSitesAction = getApiActionCreator(actionTypes.GET_SITES);
export const getAssetGroupsAction = getApiActionCreator(actionTypes.GET_ASSET_GROUPS);
export const reloadContextAction = getApiActionCreator(actionTypes.SET_RELOAD_CONTEXT_LOADING_STATUS);

export const getAvailableAssets = (organizations, refreshData) => {
    return (dispatch) => {
        HeaderApi.getAvailableAssets({ ...formatAssetsRequest(organizations), refreshData })
            .then(() => {
                dispatch({
                    type: 'AVAILABLE_ASSETS'
                });
                logger.debug('Asset list loaded from microservice');
            }, (err) => {
                dispatch({
                    type: 'AVAILABLE_ASSETS'
                });
                logger.error(err);
            });
    };
};

export const setSelectedFilters = (selectedFilters) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS,
            selectedFilters
        });
    };
};

export const setCIAMSyncFlag = (refeshCIAMSync) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_CIAM_SYNC_FLAG,
            refeshCIAMSync
        });
    };
};

export const saveFilters = () => {
    return (dispatch, getState) => {
        const { sites, assetGroups, selectedFilters } = getState().generalFilterEdit;
        dispatch({
            ...sites,
            type: actionTypes.GET_SITES
        });
        dispatch({
            ...assetGroups,
            type: actionTypes.GET_ASSET_GROUPS
        });
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS,
            selectedFilters
        });
    };
};

export const loadAssets = (organizations, isRefresh, dispatch) => {
    dispatch(getAssetsAction(apiCallStatus.LOADING));
    return HeaderApi.getAssets(organizations, isRefresh).then(assets => {
        dispatch(getAssetsAction(apiCallStatus.SUCCESS, assets));
    }, (err) => {
        dispatch(getAssetsAction(apiCallStatus.ERROR, [], err));
    });

};

export const loadPowertrains = (sites, organizations, isRefresh, dispatch) => {
    dispatch(getPowertrainsAction(apiCallStatus.LOADING));
    return HeaderApi.getSystems(getExistingSiteIds(sites), organizations, isRefresh)
        .then((systems) => {
            dispatch(getPowertrainsAction(apiCallStatus.SUCCESS, systems));
        }, (err) => {
            dispatch(getPowertrainsAction(apiCallStatus.ERROR, [], err));
        });
};

export const getUserOrganizations = () => {
    const params = {
        ...commonRequestParams,
        pageSize: pageSizeOrg,
        onlyFavorites: false,
        nameFilter: ''
    };

    return (dispatch) => {
        dispatch(getOrganizationsAction(apiCallStatus.LOADING));
        OrganizationManagementApi.getUserOrganizations(params)
            .then((organizations) => {
                dispatch(getOrganizationsAction(apiCallStatus.SUCCESS, organizations));
                logger.debug('Organizations successfully loaded');
            }, (err) => {
                dispatch(getOrganizationsAction(apiCallStatus.ERROR, [], err));
            });
    };
};

const getSites = (organizations, dispatch) => {
    dispatch(getSitesAction(apiCallStatus.LOADING));
    return HeaderApi.getSites(formatSiteAssetGroupRequest(organizations))
        .then((sites = []) => {
            dispatch(getSitesAction(apiCallStatus.SUCCESS, sites));
        }, (err) => {
            dispatch(getSitesAction(apiCallStatus.ERROR, null, err));
        });
};

const getAssetGroups = (organizations, dispatch) => {
    dispatch(getAssetGroupsAction(apiCallStatus.LOADING));
    return HeaderApi.getAssetGroups(formatSiteAssetGroupRequest(organizations))
        .then((assetGroups = []) => {
            dispatch(getAssetGroupsAction(apiCallStatus.SUCCESS, assetGroups));
        }, (err) => {
            dispatch(getAssetGroupsAction(apiCallStatus.ERROR, [], err));
        });
};

export const setSites = (sites) => {
    return (dispatch) => {
        dispatch(getSitesAction(apiCallStatus.SUCCESS, sites));
    };
};

export const setAssetGroups = (assetGroups) => {
    return (dispatch) => {
        dispatch(getAssetGroupsAction(apiCallStatus.SUCCESS, assetGroups));
    };
};

export const getSitesAndAssetGroups = (organizations) => {
    return (dispatch) => {
        Promise.all([
            getSites(organizations, dispatch),
            getAssetGroups(organizations, dispatch)
        ]);
    };
};

export const loadAssetsAndPowertrainsAndContracts = (organizations, isRefresh) => {
    return (dispatch, getState) => {
        const sites = getState().generalFilter.sites.data;
        Promise.all([
            loadAssets(organizations, isRefresh, dispatch),
            loadPowertrains(sites, organizations, isRefresh, dispatch),
        ]);
    };
};

export const searchAssetRequest = (searchValue, assets, callback, clearQueryParameter, defaultOrganizations) => ({
    type: actionTypes.ASSET_SEARCH_REQUEST,
    searchValue,
    assets,
    callback,
    clearQueryParameter,
    defaultOrganizations
});

export const launchCIAMSyncForAdmin = (organizations, refreshData) => {
    return (dispatch) => {
        dispatch(setCIAMSyncFlag(true));
        dispatch(getAssetsAction(apiCallStatus.LOADING));
        dispatch(getPowertrainsAction(apiCallStatus.LOADING));
        HeaderApi.getAvailableAssets({ ...formatAssetsRequest(organizations), refreshData })
            .then(() => {
                dispatch({
                    type: 'AVAILABLE_ASSETS'
                });
                dispatch(loadAssetsAndPowertrainsAndContracts(organizations, refreshData));
                dispatch(setCIAMSyncFlag(false));
                logger.debug('Asset list loaded from microservice');
            }, (err) => {
                dispatch({
                    type: 'AVAILABLE_ASSETS'
                });
                dispatch(setCIAMSyncFlag(false));
                logger.error(err);
            });
    };
};


export const saveFiltersWithSync = () => {
    return (dispatch, getState) => {
        const { sites, assetGroups, selectedFilters } = getState().generalFilterEdit;
        dispatch({
            ...sites,
            type: actionTypes.GET_SITES
        });
        dispatch({
            ...assetGroups,
            type: actionTypes.GET_ASSET_GROUPS
        });
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS,
            selectedFilters
        });
        dispatch(launchCIAMSyncForAdmin(selectedFilters?.organizations, true));
    };
};
