import { useEffect, useRef, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import { sanitize } from 'dompurify';
import { routes } from 'routes';


const sanitizeSearchParams = (searchParams) => {
    const sanitizedParams = new URLSearchParams();
    for (const [key, value] of searchParams.entries()) {
        const sanitizedValue = sanitize(value);
        sanitizedParams.append(key, sanitizedValue);
    }
    return sanitizedParams;
};

export const useQueryParams = () => {
    const { search } = useLocation();
    return Object.fromEntries(useMemo(() => sanitizeSearchParams(new URLSearchParams(search)), [search]));
};

export const useQuerySetter = () => {

    const history = useHistory();
    const queryParams = useQueryParams();
    const ref = useRef({});

    const routeToSelectedAssetFromPowertrainList = (assetId) => {
        const newQuery = new URLSearchParams(queryParams);
        newQuery.set('assetId', assetId);
        history.push({ pathname: history.location.pathname, search: newQuery.toString() });
    };

    const routeToSelectedPowertrain = (powertrainId) => {
        const newQuery = new URLSearchParams(powertrainId ? { powertrainId } : {});
        history.push({ pathname: history.location.pathname, search: newQuery.toString() });
    };

    const routeToSelectedAssetFromAssetList = (assetId) => {
        const newQuery = new URLSearchParams(assetId ? { assetId } : {});
        history.push({ pathname: history.location.pathname, search: newQuery.toString() });
    };

    const routeToAssetDetails = (assetId, powertrainId) => {
        history.push({ pathname: routes.AssetDetails, search: new URLSearchParams({ powertrainId, assetId }).toString() });
    };

    useEffect(() => {
        const newRef = { queryParams, routeToSelectedPowertrain, routeToSelectedAssetFromPowertrainList, routeToSelectedAssetFromAssetList, routeToAssetDetails };
        Object.assign(ref.current, newRef);
    }, [queryParams]);

    return ref.current;
};
