import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router';
import { GeneralRouterTabs } from 'sharedComponents';
import { getTabOptions } from './helpers';
import { ConditionIndicesOverview } from './ConditionIndicesOverview';
import { PowertrainsAssetsOverview } from './PowertrainsAssetsOverview';
import OverviewComponent from './Overview/OverviewComponent';
import { MaintenanceOverviewLazy } from './MaintenanceOverview';
import featureConfigHelper from 'helpers/featureConfigHelper';
import { useUserCapability } from 'sharedHooks';


const PowertrainConditionMonitoringComponent = () => {
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();
    const userCapabilityHelper = useUserCapability();
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate, userCapabilityHelper));


    useEffect(() => {
        setTabOptions(getTabOptions(translate, userCapabilityHelper));
    }, [translate]);

    return (
        <div className='general-page-content'>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
                removeSearchFilter={true}
            >
                <Switch>
                    <Route path={tabOptions.overview.route}>
                        <OverviewComponent />
                    </Route>
                    <Route path={tabOptions.conditionIndexes.route}>
                        <PowertrainsAssetsOverview />
                    </Route>
                    {userCapabilityHelper.hasConditionMonitoringCapability() &&
                        <Route Route path={tabOptions.conditionOverview.route}>
                            <ConditionIndicesOverview />
                        </Route>
                    }
                    {featureConfig.isCBMFeatureEnabled() &&
                        <Route path={tabOptions.maintenanceOverview.route}>
                            <MaintenanceOverviewLazy />
                        </Route>
                    }
                    <Route path={tabOptions.cbm.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={tabOptions.overview.route} />
                </Switch>
            </GeneralRouterTabs>
        </div >
    );
};

export default PowertrainConditionMonitoringComponent;
