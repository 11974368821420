export const actionTypes = {
    CLEAR_STORE: 'powertrainWizard/CLEAR_STORE',
    SET_WIZARD_STEP_STATE: 'powertrainWizard/SET_WIZARD_STEP_STATE',
    SET_WIZARD_ACTIVE_STEP: 'powertrainWizard/SET_WIZARD_ACTIVE_STEP',
    GET_AVAILABLE_ASSETS: 'powertrainWizard/GET_AVAILABLE_ASSETS',
    SET_SELECTED_POWERTRAIN_NAME: 'powertrainWizard/SET_SELECTED_POWERTRAIN_NAME',
    SET_SELECTED_SITE: 'powertrainWizard/SET_SELECTED_SITE',
    SET_SELECTED_ASSET_TYPE_AVAILABLE_SECTION: 'powertrainWizard/SET_SELECTED_ASSET_TYPE_AVAILABLE_SECTION',
    SET_ASSET_FILTER_AVAILABLE_SECTION: 'powertrainWizard/SET_ASSET_FILTER_AVAILABLE_SECTION',
    SET_SELECTED_ASSET_TYPE_ADDED_SECTION: 'powertrainWizard/SET_SELECTED_ASSET_TYPE_ADDED_SECTION',
    SET_ASSET_FILTER_ADDED_SECTION: 'powertrainWizard/SET_ASSET_FILTER_ADDED_SECTION',
    ADD_ASSET: 'powertrainWizard/ADD_ASSET',
    REMOVE_ASSET: 'powertrainWizard/REMOVE_ASSET',
    ADD_POWERTRAIN: 'powertrainWizard/ADD_POWERTRAIN',
    SET_POWERTRAIN_FOR_EDITING: 'powertrainWizard/SET_POWERTRAIN_FOR_EDITING',
    EDIT_POWERTRAIN: 'powertrainWizard/EDIT_POWERTRAIN',
    SET_SELECTED_POWERTRAIN: 'powertrainTools/SET_SELECTED_POWERTRAIN',

    //saga
    ADD_POWERTRAIN_REQUEST: 'powertrainWizard/ADD_POWERTRAIN_REQUEST',
    EDIT_POWERTRAIN_REQUEST: 'powertrainWizard/EDIT_POWERTRAIN_REQUEST',
    GET_AVAILABLE_ASSETS_REQUEST: 'powertrainWizard/GET_AVAILABLE_ASSETS_REQUEST',
};

export const operations = {
    ADD: 'ADD',
    REMOVE: 'REMOVE'
};

export const wizardSteps = {
    STEP1: 'STEP1',
    STEP2: 'STEP2',
    STEP3: 'STEP3'
};

export const wizardStepStates = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    COMPLETED: 'completed',
};

export const MAX_LENGTH_POWERTRAIN_NAME = 100;

export const pageSize = 10000;
