import { ComponentApi } from 'api';
import { flatMap, join, transform } from 'lodash';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

import { getTrendKPIsForAsset } from '../../commonActions';
import { getFlatAvailableKPIList } from '../../commonTrendHelpers';

const exportTrendKpiAction = getApiActionCreator(actionTypes.EXPORT_EXCEL_MEASUREMENT);


export const exportExcelForMeasurementTypeGroup = (asset, params) => {
    return (dispatch) => {
        dispatch(exportTrendKpiAction(apiCallStatus.LOADING));
        return ComponentApi.exportExcelForMeasurementTypeGroup(asset, params)
            .then((data) => {
                dispatch(exportTrendKpiAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(exportTrendKpiAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const getMeasurementIds = (asset) => {

    const getMeasurementTypeIds = (trendKPIs) => {
        const idArray = transform(trendKPIs, (acc, kpi) => kpi.isVisible && acc.push(kpi.measurementTypeIdentifier), []);
        return join(idArray, ',');
    };

    return (dispatch, getState) => {
        const state = getState();

        const { loadingStatus, data } = state.operationalParameters.availableKPIs.trend;
        let getTrendKPIsPromise = Promise.resolve(data);
        if (loadingStatus !== apiCallStatus.SUCCESS) {
            getTrendKPIsPromise = dispatch(getTrendKPIsForAsset(asset));
        }
        getTrendKPIsPromise.then((trendKPIsData) => {
            let measurementTypeIds = [];

            if (!data?.length) {
                const flatTrendKPIs = flatMap(trendKPIsData, (measurementTypeGroup) => measurementTypeGroup.measurementTypes);
                measurementTypeIds = getMeasurementTypeIds(flatTrendKPIs);
            } else {
                const flatTrendKPIs = getFlatAvailableKPIList(trendKPIsData);
                measurementTypeIds = getMeasurementTypeIds(flatTrendKPIs);
            }
            dispatch({ type: actionTypes.SET_MEASUREMENT_DIALOG_PARAMS, measurementDownloadDialogParams: { measurementTypeIds } });
        });
    };
};


export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
