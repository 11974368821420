import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralButton } from 'sharedComponents';
import OffsetConfigRow from '../OffsetConfigRow';


const SelectedParameter = (props) => {
    const { t: translate } = useTranslation();
    const { actions, selectedKpi, resetDirtyFlag } = props;

    const isSaveDisabled = selectedKpi.editState ? !selectedKpi.editState.isDirty : false;

    return <>
        {
            selectedKpi ? <>
                <OffsetConfigRow
                    selectedKpi={selectedKpi}
                    isOutOfRange={() => false}
                    showCalibrationError={false}
                    setOffset={actions.setOffset}
                    justifyContentEnd={false}
                />
                <div className='alert-alarm-dialog-row justify-content-end'>
                    <GeneralButton
                        className='save-button'
                        type='primary'
                        disabled={isSaveDisabled}
                        onClick={() => {
                            actions.saveSelectedKpiOffset(selectedKpi)
                                .then(() => {
                                    resetDirtyFlag();
                                });
                        }}
                        text={translate('ABB.Powertrain.Frontend.saveButton')}
                    />
                </div>
            </> : null
        }
    </>;
};

SelectedParameter.propTypes = {
    actions: PropTypes.shape({
        saveSelectedKpiOffset: PropTypes.func,
        setOffset: PropTypes.func
    }),
    selectedKpi: PropTypes.shape({
        editState: PropTypes.shape({
            isDirty: PropTypes.bool
        })
    })
};

export default SelectedParameter;
