import PropTypes from 'prop-types';
import { filterTabs } from 'helpers/constants';
import React from 'react';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { AssetsOverview } from './AssetsOverview';
import { ConditionBasedFilter } from './ConditionBasedFilter';
import { PowertrainsOverview } from './PowertrainsOverview';
import './style.scss';

const PowertrainAssetsOverviewComponent = ({ model, actions }) => {

    return <LoadingStatusWrapper loadingStatus={[
        ...model.generalFilterLoadingStatuses,
        model.assetsLoadingStatus,
        model.powertrainsLoadingStatus
    ]}>
        <div id='powertrain-assets-overview-condition-container'>
            <div className='condition-based-filter-row'>
                <ConditionBasedFilter
                    model={model}
                    actions={actions}
                />
            </div>
            <div className='powertrain-assets-overview-container' >
                {model.activeTab === filterTabs.POWERTRAINS
                    ? <PowertrainsOverview />
                    : <AssetsOverview />
                }
            </div>

        </div>
    </LoadingStatusWrapper>;
};

PowertrainAssetsOverviewComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
        conditionStatusFilter: PropTypes.object,
        searchBoxFilter: PropTypes.string,
        powertrains: PropTypes.array,
        assets: PropTypes.array,
        generalFilterLoadingStatuses: PropTypes.array,
        assetsLoadingStatus: PropTypes.string,
        powertrainsLoadingStatus: PropTypes.string
    }),
    actions: PropTypes.shape({
        setActiveTab: PropTypes.func,
        setAssetTypeFilter: PropTypes.func,
        toggleConditionStatusFilter: PropTypes.func,
        setSearchboxFilter: PropTypes.func,
        clearStore: PropTypes.func,
    })
};


export default PowertrainAssetsOverviewComponent;
