import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { RadioButton } from 'sharedComponents';

const Option = ({ ...props }) => {
	// This is a workaround to disable mouseOver events which cause lagging even in virtual scrolling
	// eslint-disable-next-line no-unused-vars
	const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
	const newProps = Object.assign(props, { innerProps: rest });
	return (
		<components.Option
			{...newProps}
		>
			<RadioButton
				id={props.data.label}
				checked={props.isSelected}
				onClick={() => { }}
				label={props.label}
			/>
		</components.Option>
	);
};


Option.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string
	}),
	isSelected: PropTypes.bool,
	label: PropTypes.string
};

export default Option;
