import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconWeldingMachine = ({ width = 30, height = 30, viewBox = '0 0 48 48', color = colors.black }) => {
	return <svg id='Transformer' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
		<polyline points='25.34 2.5 20.71 2.5 20.71 10.21 17.63 10.21 17.63 2.5 13 2.5 13 10.21 10.43 10.21 10.43 12.78 7.35 12.78 7.35 39 15.57 39 15.57 44.65 32.02 44.65 32.02 39 39.73 39 39.73 12.78 36.65 12.78 36.65 10.21 34.08 10.21 34.08 2.5 29.45 2.5 29.45 10.21 26.37 10.21 26.37 2.5 21.74 2.5' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<path d='M12.49,32.83a.54.54,0,0,1-.52.55h-.51a.53.53,0,0,1-.51-.55V18.47a.53.53,0,0,1,.51-.55H12a.54.54,0,0,1,.52.55Z' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<path d='M20.2,32.83a.54.54,0,0,1-.52.55h-.51a.53.53,0,0,1-.51-.55V18.47a.53.53,0,0,1,.51-.55h.51a.54.54,0,0,1,.52.55Z' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<path d='M28.42,32.83a.53.53,0,0,1-.51.55h-.52a.53.53,0,0,1-.51-.55V18.47a.53.53,0,0,1,.51-.55h.52a.53.53,0,0,1,.51.55Z' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
		<path d='M36.65,32.83a.54.54,0,0,1-.52.55h-.51a.53.53,0,0,1-.51-.55V18.47a.53.53,0,0,1,.51-.55h.51a.54.54,0,0,1,.52.55Z' fill='none' stroke={color} strokeLinejoin='bevel' strokeWidth='2' />
	</svg>;
};

IconWeldingMachine.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	color: PropTypes.string
};

export default IconWeldingMachine;
