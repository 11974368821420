import React, { useEffect, useState, useCallback } from 'react';
import { Col } from 'react-bootstrap';

import { IconNameplate } from 'svgIcons/MotionPortalIcons';
import { getAssetIcon } from 'helpers/icons';
import { apiCallStatus } from 'helpers/constants';
import { PICTURE_TYPES } from './constants';
import ImageViewerModal from './ImageViewerModal';
import { getThumbnailForPictureType } from './helpers';
import ImageHolder from './ImageHolder';
import './style.scss';

const AssetNameplateImageHolderComponent = (props) => {

	const {
		asset, actions, model
	} = props;

	const [mainAssetImage, setMainAssetImage] = useState(null);
	const [mainNameplateImage, setMainNameplateImage] = useState(null);
	const [images, setImages] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const placeholderProps = { width: 45, height: 45 };

	const setThumbnails = (pictures) => {
		setMainAssetImage(getThumbnailForPictureType(pictures, PICTURE_TYPES.AssetImage));
		setMainNameplateImage(getThumbnailForPictureType(pictures, PICTURE_TYPES.NameplateImage));
	};

	useEffect(() => {
		asset && actions.getAssetPictures(asset?.id);
	}, []);

	useEffect(() => {
		if (model.pictures.data) {
			setThumbnails(model.pictures.data);
			setImages(model.pictures.data);
		}

	}, [model.pictures.data]);

	useEffect(() => {
	}, [model?.assetNameplateImage]);

	const onImageHolderClick = useCallback((selectedIndex) => {
		setModalVisible(true);
		setSelectedIndex(selectedIndex || 0);
	}, []);

	const onAssetPictureUpload = (file, assetPictureType) => {
		asset && actions.uploadAssetPictureRequest(asset?.id, file?.name, 0, assetPictureType, file);
	};
	const getLoadingStatus = () => {
		if (
			model?.assetNameplateImage?.loadingStatus === apiCallStatus.LOADING
		) {
			return model?.assetNameplateImage?.loadingStatus;
		}
		return model.pictures.loadingStatus;
	};

	return (
		<Col md='auto' className='asset-nameplate-image-container'>
			<ImageHolder imageToRender={mainAssetImage} placeholder={getAssetIcon(asset.componentType.typeName, placeholderProps)} onImageHolderClick={onImageHolderClick} />
			<ImageHolder imageToRender={mainNameplateImage} placeholder={<IconNameplate {...placeholderProps} />} selectedIndex={1} onImageHolderClick={onImageHolderClick} />
			<ImageViewerModal
				images={images}
				loadingStatus={getLoadingStatus()}
				show={modalVisible}
				selectedIndex={selectedIndex}
				closeFunction={() => { setModalVisible(false); setSelectedIndex(null); }}
				placeholder={getAssetIcon(asset.componentType.typeName, { width: 100, height: 100 })}
				uploadImageHandler={onAssetPictureUpload}
			/>
		</Col>
	);
};

export default AssetNameplateImageHolderComponent;
