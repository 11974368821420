import React from 'react';
import map from 'lodash/map';

import { UPLOAD_STATUS } from './constants';
import { toLocaleDateTimeString } from 'helpers/dateHelper';


const statusColumnFormatter = (cell, translate) => {
    const status = translate(`ABB.Powertrain.Frontend.ptHierarchyBulkUploadStatusCode_${cell.status}`);
    let statusDetails = null;
    if (cell.status === UPLOAD_STATUS.Analyzing) {
        statusDetails = <>({cell.analyticsProcessInPercent}%)</>;
    }
    else if (cell.status === UPLOAD_STATUS.Done) {
        const failedFiles = <span style={cell.failedFiles ? { color: 'red' } : {}}>{cell.failedFiles}</span>;
        statusDetails = <>
            ({cell.processedFiles} {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadStatusOK')}, {failedFiles} {translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadStatusFailed')})
        </>;
    }
    const cellValue = <>
        {status} {statusDetails}
    </>;
    return cellValue;
};

export const getColumns = (translate) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadColumnNeta'),
            dataIndex: 'gatewaySerialnumber',
            key: 'gatewaySerialnumber',
            isSorting: true,
            width: 150,
            fixed: 'left'
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadColumnFileName'),
            dataIndex: 'fileName',
            key: 'fileName',
            width: 150,
            isSorting: true
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadColumnUploadedBy'),
            dataIndex: 'initiatedBy',
            key: 'initiatedBy',
            width: 150,
            isSorting: true
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadColumnUploadedTime'),
            dataIndex: 'uploadTimeUtc',
            key: 'uploadTimeUtc',
            width: 150,
            isSorting: true,
            render: (date) => toLocaleDateTimeString(date)
        },
        {
            title: translate('ABB.Powertrain.Frontend.detailedInformationBulkUploadColumnStatus'),
            dataIndex: 'status',
            key: 'status',
            width: 100,
            isSorting: true,
            render: (status) => statusColumnFormatter(status, translate)
        }

    ];
};
export const getPredefinedRangeStart = (daysBack) => {
    const today = new Date();

    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - daysBack);
    startDate.setHours(0, 0, 0, 0);

    return startDate;
};

export const formatUploadList = (uploadList) => {
    return map(uploadList, (uploadListItem, index) => {
        return {
            id: index,
            ...uploadListItem,
            status: {
                ...uploadListItem.status,
                sortingItem: uploadListItem.status.status
            }
        };
    });
};
