import { createSelector } from 'reselect';
import { getLoadingStatuses, getGeneralFilteredPowertrains, getPowertrainLoadingStatus } from 'helpers/selectors';

export const getModel = createSelector([
    getGeneralFilteredPowertrains,
    getPowertrainLoadingStatus,
    getLoadingStatuses
],
    (
        powertrains,
        powertrainsLoadingStatus,
        generalFilterLoadingStatuses
    ) => {
        return {
            powertrains,
            powertrainsLoadingStatus,
            generalFilterLoadingStatuses
        };
    });
