import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { SidePanel } from 'sharedComponents/SidePanel';

const UserSettingsSidepanelComponent = ({
    options,
    selectedOption
}) => {
    const { t: translate } = useTranslation();

    const renderUserSettingsOptions = () =>
        options.map((option) => !option?.hidden &&
            <Nav.Link as={NavLink}
                className={option.route === selectedOption ? 'user-setting selected' : 'user-setting'}
                key={option.id}
                to={option.route} >
                {option.icon}{translate(option.title)}
            </Nav.Link>
        );

    return <SidePanel className='user-settings-sidepanel-container'>
        <div className='user-settings-label'>{translate('ABB.Powertrain.Frontend.userProfileUserSettingsLabel')}</div>
        {renderUserSettingsOptions()}
    </SidePanel>;
};

UserSettingsSidepanelComponent.propTypes = {
    options: PropTypes.array,
    selectedOption: PropTypes.string,
};

export default UserSettingsSidepanelComponent;
