import { actionTypes, toggleButtonOptions } from './constants';
import { allAssetTypes } from 'helpers/constants';
import { setSelectedConditions, addNewPageToConditionIndicesOverviewList } from './helpers';


const initialState = {
    assets: {
        data: [],
        loadingStatus: null,
        pageNumber: 1,
        hasMore: false
    },
    assetGroups: {
        data: [],
        loadingStatus: null,
        pageNumber: 1,
        hasMore: false
    },
    powertrains: {
        data: [],
        loadingStatus: null,
        pageNumber: 1,
        hasMore: false
    },
    filter: {
        assetTypeFilter: allAssetTypes,
        conditionFilter: [],
        searchBox: ''
    },
    selectedTab: toggleButtonOptions.POWERTRAINS
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_OVERVIEW: {
            return {
                ...state,
                assets: {
                    ...addNewPageToConditionIndicesOverviewList(state.assets, action)
                }
            };
        }
        case actionTypes.GET_ASSET_GROUP_OVERVIEW: {
            return {
                ...state,
                assetGroups: {
                    ...addNewPageToConditionIndicesOverviewList(state.assetGroups, action)
                }
            };
        }
        case actionTypes.GET_POWERTRAIN_OVERVIEW: {
            return {
                ...state,
                powertrains: {
                    ...addNewPageToConditionIndicesOverviewList(state.powertrains, action)
                }
            };
        }
        case actionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                selectedTab: action.tab
            };
        }
        case actionTypes.SET_ASSET_TYPES_FILTER: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    assetTypeFilter: action.assetTypeFilter === allAssetTypes ?
                        null :
                        action.assetTypeFilter
                }
            };
        }
        case actionTypes.TOGGLE_CONDITION_STATUS_FILTER: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    conditionFilter: setSelectedConditions(
                        state.filter.conditionFilter,
                        action.condition
                    )
                }
            };
        }
        case actionTypes.RESET_FILTERS: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            };
        }
        case actionTypes.SET_SEARCHBOX_FILTER: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    searchBox: action.searchboxFilter
                }
            };
        }
        case actionTypes.CLEAR_ASSET_OVERVIEW: {
            return {
                ...state,
                assets: initialState.assets,
                filter: initialState.filter
            };
        }
        case actionTypes.CLEAR_ASSET_GROUP_OVERVIEW: {
            return {
                ...state,
                assetGroups: initialState.assets,
                filter: initialState.filter
            };
        }
        case actionTypes.CLEAR_POWERTRAIN_OVERVIEW: {
            return {
                ...state,
                powertrains: initialState.assets,
                filter: initialState.filter
            };
        }
        case actionTypes.CLEAR_STORE: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
